import { t } from 'i18next'
import { renderToStaticMarkup } from 'react-dom/server'
import { getIcon } from '../../../../../common/components/icons/utils'
import styles from './DraggableBoardThumbnail.module.css'
import classNames from 'classnames'

export const getNoTasksWarningIcon = () => {
  return (
    '<div' +
    ` class='${classNames(styles.stateIconNoTasks, 'boardStateIcon')}'` +
    ` data-tooltip-content='${t(
      'game_editor.exploration_mode.boards_overview.no_tasks_tooltip',
      'No tasks on this board',
    )}'` +
    '> ' +
    `<span>${renderToStaticMarkup(getIcon('alert'))}</span> ` +
    '</div>'
  )
}

export const getNoDoorsFromWarningIcon = () => {
  return (
    '<div' +
    ` class='${classNames(styles.stateIconNoDoorsFrom, 'boardStateIcon')}'` +
    ` data-tooltip-content='${t(
      'game_editor.exploration_mode.boards_overview.no_doors_from_tooltip',
      'No doors leaving from this board',
    )}'` +
    '> ' +
    `<span>${renderToStaticMarkup(getIcon('plus'))}</span> ` +
    '</div>'
  )
}

export const getNoDoorsToWarningIcon = () => {
  return (
    '<div' +
    ` class='${classNames(styles.stateIconNoDoorsTo, 'boardStateIcon')}'` +
    ` data-tooltip-content='${t(
      'game_editor.exploration_mode.boards_overview.no_doors_to_tooltip',
      'No doors entering this board',
    )}'` +
    '> ' +
    `<span>${renderToStaticMarkup(getIcon('door'))}</span> ` +
    '</div>'
  )
}

export const getNoDoorsFromOrToWarningIcon = () => {
  return (
    '<div' +
    ` class='${classNames(styles.stateIconNoDoorsFrom, 'boardStateIcon')}'` +
    ` data-tooltip-content='${t(
      'game_editor.exploration_mode.boards_overview.no_doors_from_or_to_tooltip',
      'This board has no door connections',
    )}'` +
    '> ' +
    `<span>${renderToStaticMarkup(getIcon('plus'))}</span> ` +
    '</div>'
  )
}

export const getDoorsOKStateIcon = () => {
  return (
    '<div' +
    ` class='${classNames(styles.stateIconDoorsOK, 'boardStateIcon')}'` +
    ` data-tooltip-content='${t(
      'game_editor.exploration_mode.boards_overview.doors_ok_tooltip',
      'Board is accessible with doors',
    )}'` +
    '> ' +
    `<span>${renderToStaticMarkup(getIcon('door'))}</span> ` +
    '</div>'
  )
}
