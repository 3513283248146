import classNames from 'classnames'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DoubleBarIcon,
  ExercisePinIcon,
  TaskFlashIcon,
  TripleBarIcon,
} from '../../../../../../../common/components/icons'
import styles from './ToggleTaskContext.module.css'

enum TaskPinType {
  PINNED = 'pinned',
  FLASH = 'flash',
}

enum TaskLayoutType {
  COMPACT = 'compact',
  NOT_COMPACT = 'not-compact',
}

type RadioPinButtonProps = {
  id: TaskPinType
  label: string
  value: TaskPinType
  showActiveIndicator?: boolean
  onChange: (id: TaskPinType) => void
}

const RadioPinButton: React.FC<RadioPinButtonProps> = ({ id, label, value, showActiveIndicator, onChange }) => {
  const onChangeInternal = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.id as TaskPinType)
    },
    [onChange],
  )

  return (
    <div className={classNames(styles.radioContainer, styles.radioButtonPinContainer)}>
      <input
        type='radio'
        name='tasks'
        id={id}
        checked={value === id}
        onChange={onChangeInternal}
        className={styles.originalInput}
      />
      <label htmlFor={id} className={styles.radioButtonPinLabel}>
        {id === TaskPinType.PINNED ? <ExercisePinIcon /> : <TaskFlashIcon />}
        {label}
        {showActiveIndicator && <span className={styles.flashActiveIndicator} />}
      </label>
    </div>
  )
}

type RadioBarButtonProps = {
  id: TaskLayoutType
  label: string
  value: TaskLayoutType
  onChange: (id: TaskLayoutType) => void
}

const RadioBarButton: React.FC<RadioBarButtonProps> = ({ id, value, label, onChange }) => {
  const onChangeInternal = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.id as TaskLayoutType)
    },
    [onChange],
  )

  return (
    <div className={classNames(styles.radioContainer, styles.radioButtonBarContainer)}>
      <input
        type='radio'
        name='compact'
        id={id}
        checked={value === id}
        onChange={onChangeInternal}
        className={styles.originalInput}
      />
      <label htmlFor={id} className={styles.radioButtonBarLabel}>
        {id === TaskLayoutType.NOT_COMPACT ? (
          <span className={styles.barsContainer}>
            <DoubleBarIcon />
          </span>
        ) : (
          <span className={styles.barsContainer}>
            <TripleBarIcon />
          </span>
        )}
        <span className='sr-only'>{label}</span>
      </label>
    </div>
  )
}

type ToggleTaskContextProps = {
  isFlash: boolean
  hasActiveFlashTask?: boolean
  isCompact: boolean
  onChangeIsFlash: (isFlash: boolean) => void
  onChangeIsCompact: (isCompact: boolean) => void
  hideTaskTypeToggle?: boolean
  flashDisabled: boolean
}

export const ToggleTaskContext: React.FC<ToggleTaskContextProps> = ({
  isFlash,
  hasActiveFlashTask,
  isCompact,
  onChangeIsFlash,
  onChangeIsCompact,
  hideTaskTypeToggle,
  flashDisabled,
}) => {
  const { t } = useTranslation()

  const onChangePinType = (pinType: TaskPinType) => {
    onChangeIsFlash(pinType === TaskPinType.FLASH)
  }

  const onChangeLayoutType = (layoutType: TaskLayoutType) => {
    onChangeIsCompact(layoutType === TaskLayoutType.COMPACT)
  }

  return (
    <div className={styles.container}>
      {!hideTaskTypeToggle && (
        <div className={styles.colContainer}>
          <RadioPinButton
            id={TaskPinType.PINNED}
            label={t('game_editor.sidebar.tasks.pin_type_pinned', 'Pinned')}
            value={isFlash ? TaskPinType.FLASH : TaskPinType.PINNED}
            onChange={onChangePinType}
          />
          {!flashDisabled && (
            <RadioPinButton
              id={TaskPinType.FLASH}
              label={t('game_editor.sidebar.tasks.pin_type_flash', 'Flash')}
              value={isFlash ? TaskPinType.FLASH : TaskPinType.PINNED}
              onChange={onChangePinType}
              showActiveIndicator={hasActiveFlashTask}
            />
          )}
        </div>
      )}
      <div className={classNames(styles.colContainer, styles.grayBGWrapper)}>
        <RadioBarButton
          id={TaskLayoutType.NOT_COMPACT}
          value={isCompact ? TaskLayoutType.COMPACT : TaskLayoutType.NOT_COMPACT}
          onChange={onChangeLayoutType}
          label={t('game_editor.sidebar.tasks.layout_type_not_compact', 'Not compact')}
        />
        <RadioBarButton
          id={TaskLayoutType.COMPACT}
          value={isCompact ? TaskLayoutType.COMPACT : TaskLayoutType.NOT_COMPACT}
          onChange={onChangeLayoutType}
          label={t('game_editor.sidebar.tasks.layout_type_compact', 'Compact')}
        />
      </div>
    </div>
  )
}
