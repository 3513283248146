import { useState } from 'react'
import { FormSpy } from 'react-final-form'
import { useNotification } from '../../../../contexts/NotificationContext'
import { useTranslation } from 'react-i18next'

export const ValidationNotificationSpy: React.FC = () => {
  const { t } = useTranslation()
  const [notifyId, setNotifyId] = useState<string>()
  const { notifyError, closeNotification } = useNotification()

  return (
    <FormSpy
      subscription={{ valid: true, submitFailed: true, modifiedSinceLastSubmit: true }}
      onChange={({ valid, submitFailed, modifiedSinceLastSubmit }) => {
        if (submitFailed && !valid && !modifiedSinceLastSubmit && notifyId == null) {
          const id = notifyError({
            title: t('validation_errors.general_toast_error.title', 'Check your information'),
            content: t(
              'validation_errors.general_toast_error.description',
              'Enter appropriate data into all fields before proceeding',
            ),
            timeout: false,
            allowClose: false,
            closeOnRouteChange: true,
          })
          setNotifyId(id)
        } else if (valid && notifyId != null) {
          closeNotification(notifyId)
          setNotifyId(undefined)
        }
      }}
    />
  )
}
