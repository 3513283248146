import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderBlock } from '../../common/components/loaders/LoaderBlock/LoaderBlock'
import { GamesOverview } from '../../composites/GamesOverview/GamesOverview'
import { useGameData } from '../../contexts/OrgLibraryGamesContextProvider'
import { useUser } from '../../contexts/userContext'
import { GamesFilterSettings } from '../../types/commonTypes'
import { getEmptyContentBrowseButton } from '../../util/game'
import styles from './OrgLibrary.module.css'

const ORG_LIB_FILTERS: GamesFilterSettings = {
  age: true,
  language: true,
  subject: true,
}

export const OrgLibrary: React.FC = () => {
  const gameData = useGameData()
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <div className={styles.container}>
      {!gameData.initialFetchDone ? (
        <LoaderBlock variant='secondary' />
      ) : (
        <GamesOverview
          title={t('org_library.title', 'Organization library')}
          libraryMode
          gameData={gameData}
          emptyContent={{
            title: t('org_library.empty.title', 'There are no games in your org library yet'),
            subtitle: t(
              'org_library.empty.content',
              'Add games as templates to your organisation library from within the game editor',
            ),
            browseButton: getEmptyContentBrowseButton(user, t),
          }}
          filterFormSettings={ORG_LIB_FILTERS}
        />
      )}
    </div>
  )
}
