import { PropsWithChildren } from 'react'
import { Topbar } from '../topbar/Topbar'
import { Footer } from './Footer'
import styles from './PageWithTopbar.module.css'

export const PageWithTopbar: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.pageContainer}>
      <Topbar />
      <div className={styles.mainContainer}>{children}</div>
      <Footer />
    </div>
  )
}
