import { Outlet } from 'react-router-dom'
import { BusinessSettingsProvider } from '../../contexts/BusinessSettingsContext'

export const BusinessSettingsWrapper: React.FC = () => {
  return (
    <BusinessSettingsProvider>
      <Outlet />
    </BusinessSettingsProvider>
  )
}
