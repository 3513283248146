import { useTranslation } from 'react-i18next'
import { TaskIconType } from '../../../../../../api/gameTypes'
import styles from './PinIconSettings.module.css'
import { TFunction } from 'i18next'
import {
  IconRadioGroupField,
  IconRadioItem,
} from '../../../../../../common/components/Form/ButtonRadioGroupField/IconRadioGroupField'
import { TaskPinRawSvgMap } from '../../../Marker/TaskPinHelper'

const getTranslatedOptions = (t: TFunction): IconRadioItem[] => [
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_seppo', 'Seppo'),
    value: TaskIconType.seppo,
    rawSvg: TaskPinRawSvgMap.seppo,
  },
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_crown', 'Crown'),
    value: TaskIconType.crown,
    rawSvg: TaskPinRawSvgMap.crown,
  },
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_star', 'Star'),
    value: TaskIconType.star,
    rawSvg: TaskPinRawSvgMap.star,
  },
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_two_stars', 'Two stars'),
    value: TaskIconType.two_stars,
    rawSvg: TaskPinRawSvgMap.two_stars,
  },
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_three_stars', 'Three stars'),
    value: TaskIconType.three_stars,
    rawSvg: TaskPinRawSvgMap.three_stars,
  },
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_diamond', 'Diamond'),
    value: TaskIconType.diamond,
    rawSvg: TaskPinRawSvgMap.diamond,
  },
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_circle', 'Circle'),
    value: TaskIconType.circle,
    rawSvg: TaskPinRawSvgMap.circle,
  },
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_question', 'Question'),
    value: TaskIconType.question,
    rawSvg: TaskPinRawSvgMap.question,
  },
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_flag', 'Flag'),
    value: TaskIconType.flag,
    rawSvg: TaskPinRawSvgMap.flag,
  },
  {
    label: t('game_editor.tasks.advanced.pin_icon_option_skull', 'Skull'),
    value: TaskIconType.skull,
    rawSvg: TaskPinRawSvgMap.skull,
  },
]

type PinIconSettingsProps = {
  viewOnly: boolean
}

export const PinIconSettings: React.FC<PinIconSettingsProps> = ({ viewOnly }) => {
  const { t } = useTranslation()

  const options = getTranslatedOptions(t)

  return (
    <div className={styles.container}>
      <IconRadioGroupField
        name='advanced.iconType'
        label={t('game_editor.tasks.advanced.pin_icon_options_accessibility_label', 'Select task pin icon')}
        srOnlyLabel
        options={options}
        disabled={viewOnly}
      />
    </div>
  )
}
