import * as React from 'react'
import { SVGProps } from 'react'

const SvgPlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 21 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m8.557 1.512 11.714 9.845c.344.29.542.708.541 1.147 0 .44-.197.857-.542 1.146L8.555 23.49a5.176 5.176 0 0 1-3.318 1.198c-2.785 0-5.046-2.166-5.05-4.843V5.159a4.74 4.74 0 0 1 1.25-3.194c1.835-2.01 5.023-2.216 7.12-.453Zm-4.733 2.46c-.298.328-.463.75-.463 1.187v14.683c.001.994.84 1.799 1.876 1.799.45 0 .887-.158 1.23-.445l10.35-8.693-10.35-8.699a1.928 1.928 0 0 0-2.643.169Z'
      fill={props.color ?? '#CC3E76'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m8.557 1.512 11.714 9.845c.344.29.542.708.541 1.147 0 .44-.197.857-.542 1.146L8.555 23.49a5.176 5.176 0 0 1-3.318 1.198c-2.785 0-5.046-2.166-5.05-4.843V5.159a4.74 4.74 0 0 1 1.25-3.194c1.835-2.01 5.023-2.216 7.12-.453Zm-4.733 2.46c-.298.328-.463.75-.463 1.187v14.683c.001.994.84 1.799 1.876 1.799.45 0 .887-.158 1.23-.445l10.35-8.693-10.35-8.699a1.928 1.928 0 0 0-2.643.169Z'
      fill={props.color ?? 'url(#play_icon_svg__a)'}
      fillOpacity={props.color ? 1 : 0.25}
    />
    <defs>
      <linearGradient id='play_icon_svg__a' x1={10.5} y1={0.313} x2={10.5} y2={24.688} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgPlayIcon
