import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { getIcon } from '../../../../common/components/icons/utils'
import { Tooltip } from '../../../../common/components/tooltip/Tooltip'
import { useChat } from '../../../../contexts/ChatContextProvider'
import { useTheme } from '../../../../contexts/ThemeContext'
import { useUser } from '../../../../contexts/userContext'
import { EditorPermissions } from '../../../../types/commonTypes'
import { ADD_PEOPLE_BUTTON_ID, CHAT_BUTTON_ID, SHARE_GAME_BUTTON_ID } from '../../constants'
import { TabType } from '../../types'
import { AddPerson } from '../AddPerson/AddPerson'
import { Chat } from '../Chat/Chat'
import { areThereNewMessages } from '../Chat/helpers'
import styles from './Header.module.css'
import { UserMenu } from './UserMenu'
import { TabGroup } from './components/TabGroup'

type HeaderProps = {
  gameName?: string
  hasChat?: boolean
  activeTab: TabType
  onClickTab: (id: TabType) => void
  onClickGameSettings: (scrollToPlayerSettings?: boolean) => void
  onClickShareGame: () => void
  isShareGameOpen: boolean
  isShareAvailable: boolean
  permissions: EditorPermissions
}

export const Header: React.FC<HeaderProps> = ({
  gameName,
  hasChat,
  activeTab,
  onClickTab,
  onClickGameSettings,
  onClickShareGame,
  isShareGameOpen,
  isShareAvailable,
  permissions,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logoUrl } = useTheme()
  const [showAddPerson, setShowAddPerson] = useState(false)
  const { isChatOpen, toggleChat, chatRooms } = useChat()
  const { user } = useUser()

  const handleClickNavigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const toggleShowAddPerson = () => {
    setShowAddPerson((prev) => !prev)
  }

  const onManageAnswersClick =
    activeTab === TabType.PLAY
      ? undefined
      : () => {
          onClickTab(TabType.PLAY)
          setShowAddPerson(false)
        }

  return (
    <header className={styles.container}>
      {permissions.actions.dashboardLink && (
        <Tooltip tooltipContent={t('game_editor.header.button_tooltip_back', 'Go to previous page')} direction='right'>
          {(tooltipProps) => (
            <button onClick={handleClickNavigateBack} className={styles.backButton} {...tooltipProps}>
              {getIcon('backIcon')}
            </button>
          )}
        </Tooltip>
      )}
      <div className={styles.seppoLogoContainer}>
        {permissions.actions.dashboardLink ? (
          <Tooltip
            tooltipContent={t('game_editor.header.button_tooltip_logo_dashboard_link', 'Go to dashboard')}
            direction='down'
          >
            {(tooltipProps) => (
              <Link className={styles.linkWrapper} to='/'>
                <img className={styles.seppoLogo} src={logoUrl} alt='logo' {...tooltipProps} />
              </Link>
            )}
          </Tooltip>
        ) : (
          <img className={classNames(styles.seppoLogo, styles.seppoLogo_noBackBtn)} src={logoUrl} alt='logo' />
        )}
      </div>
      <div className={styles.gameNameAndSettings}>
        <span>{gameName || t('game_editor.header.missing_game_title', 'Please set game name')}</span>
        <Tooltip
          tooltipContent={t('game_editor.header.button_tooltip_game_settings', 'Game settings')}
          direction='down'
        >
          {(tooltipProps) => (
            <button onClick={() => onClickGameSettings(false)} {...tooltipProps}>
              {getIcon('settings')}
            </button>
          )}
        </Tooltip>
      </div>
      <div className={styles.tabOptions}>
        <TabGroup activeTab={activeTab} onClickTab={onClickTab} />
      </div>
      <div className={styles.iconsWrapper}>
        {permissions.actions.shareGame && (user?.hasCommunity || user?.hasOrgLibrary) && isShareAvailable && (
          <Tooltip
            idOverride={SHARE_GAME_BUTTON_ID}
            tooltipContent={t('game_editor.header.button_tooltip_share_game', 'Share game')}
            direction='down'
          >
            {(tooltipProps) => (
              <button
                onClick={onClickShareGame}
                className={classNames(styles.buttonDefault, isShareGameOpen && styles.buttonActive)}
                {...tooltipProps}
              >
                <span>{getIcon(user.hasCommunity ? 'community' : 'orgLibrary')}</span>
              </button>
            )}
          </Tooltip>
        )}
        {permissions.actions.accessPeopleModal && (
          <Tooltip
            idOverride={ADD_PEOPLE_BUTTON_ID}
            tooltipContent={t('game_editor.header.button_tooltip_add_people', 'Manage players and instructors')}
            direction='down'
          >
            {(tooltipProps) => (
              <button
                onClick={toggleShowAddPerson}
                className={classNames(styles.buttonDefault, showAddPerson && styles.buttonActive)}
                {...tooltipProps}
              >
                <span>{getIcon('multipleUsers')}</span>
              </button>
            )}
          </Tooltip>
        )}
        {hasChat && permissions.actions.accessChatModal && (
          <Tooltip
            idOverride={CHAT_BUTTON_ID}
            tooltipContent={t('game_editor.header.button_tooltip_chat', 'Chat')}
            direction='down'
          >
            {(tooltipProps) => (
              <button
                onClick={toggleChat}
                className={classNames(styles.buttonDefault, isChatOpen && styles.buttonActive)}
                {...tooltipProps}
              >
                <span>{getIcon(areThereNewMessages(chatRooms) ? 'chatUnread' : 'chatNormal')}</span>
              </button>
            )}
          </Tooltip>
        )}
      </div>
      <UserMenu showSettingsLink={permissions.actions.generalSettingsLink} />
      {isChatOpen && <Chat />}
      {showAddPerson && (
        <AddPerson
          onClose={toggleShowAddPerson}
          onManageAnswersClick={onManageAnswersClick}
          onOpenGameSettings={() => onClickGameSettings(true)}
        />
      )}
    </header>
  )
}
