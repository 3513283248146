import React from 'react'

import classNames from 'classnames'
import { To } from 'react-router-dom'
import styles from '../../../common/components/MenuItem/MenuItem.module.css'
import shared from '../../../common/styles/shared.module.css'
import { MENU_ITEM_TYPE_DEFAULT, MENU_ITEM_TYPE_SUBMENU, USER_MENU_ACCOUNT, USER_MENU_LANGUAGE } from '../../constants'
import { getIcon, IconName } from '../icons/utils'
import { useTranslation } from 'react-i18next'

export type MenuItemProps = {
  icon?: IconName
  text: string
  value?: string | number
  itemType?: string
  isUserMenu?: boolean
  isActiveUser?: boolean
  activeBusiness?: string
  menuItemName?: string
  activeLanguage?: string
  linkToUrl?: To
  fontSize: 'tiny' | 'normal' | 'medium'
  menuItemStyle?: React.CSSProperties
  onMenuItemClick: (menuType: string, value: string) => void
}

export const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  text,
  value,
  itemType = { MENU_ITEM_TYPE_DEFAULT },
  linkToUrl,
  isUserMenu,
  isActiveUser,
  activeBusiness,
  fontSize,
  menuItemName = '',
  activeLanguage = 'English',
  onMenuItemClick,
}) => {
  const { t } = useTranslation()

  const iconSelector = React.useMemo(() => {
    if (icon == null) {
      return null
    } else {
      return getIcon(icon)
    }
  }, [icon])

  const onMenuItemClickInternal = React.useCallback(
    (e: React.SyntheticEvent<HTMLButtonElement>) => {
      const { value } = e.currentTarget
      onMenuItemClick(menuItemName, value)
    },
    [onMenuItemClick, menuItemName],
  )

  return (
    <button
      onClick={onMenuItemClickInternal}
      className={classNames(shared.row, isUserMenu ? styles.menuContainerUserMenu : styles.menuContainerContextMenu)}
      value={value}
      name={menuItemName.charAt(0).toUpperCase() + menuItemName.slice(1)}
    >
      {iconSelector && <span className={styles.iconContainer}>{iconSelector}</span>}
      <span className={styles.menuItemLabelWrapper}>
        <p className={classNames(styles.menuLink, `${fontSize}`)}>{text}</p>
        {isActiveUser && (
          <p className={styles.subtext}>{t('user_menu.account_menu.active_account_indication', 'Currently in use')}</p>
        )}
        {menuItemName === USER_MENU_LANGUAGE && <p className={styles.subtext}>{activeLanguage}</p>}
        {menuItemName === USER_MENU_ACCOUNT && <p className={styles.subtext}>{activeBusiness}</p>}
      </span>
      {itemType === MENU_ITEM_TYPE_SUBMENU && (
        <span className={classNames(styles.submenuIcon)} style={{ fontSize: 18 }}>
          {getIcon('chevron')}
        </span>
      )}
    </button>
  )
}
