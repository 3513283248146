import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLevelsAddGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <linearGradient id='levels_add_gradient_icon_svg__a' x1={16} x2={16} y1={1} y2={31} gradientUnits='userSpaceOnUse'>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g fillRule='evenodd' clipRule='evenodd'>
      <path
        fill='currentColor'
        d='M15.475 1C9.138 1 4 6.1 4 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C26.95 6.1 21.812 1 15.475 1zm-1.108 16.352a1 1 0 0 0 1 1h.272a1 1 0 0 0 1-1v-3.88h3.896a1 1 0 0 0 1-1v-.208a1 1 0 0 0-1-1h-3.896v-3.88a1 1 0 0 0-1-1h-.272a1 1 0 0 0-1 1v3.88H10.47a1 1 0 0 0-1 1v.208a1 1 0 0 0 1 1h3.896zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
      />
      <path
        fill='url(#levels_add_gradient_icon_svg__a)'
        fillOpacity={0.25}
        d='M15.475 1C9.138 1 4 6.1 4 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C26.95 6.1 21.812 1 15.475 1zm-1.108 16.352a1 1 0 0 0 1 1h.272a1 1 0 0 0 1-1v-3.88h3.896a1 1 0 0 0 1-1v-.208a1 1 0 0 0-1-1h-3.896v-3.88a1 1 0 0 0-1-1h-.272a1 1 0 0 0-1 1v3.88H10.47a1 1 0 0 0-1 1v.208a1 1 0 0 0 1 1h3.896zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
      />
    </g>
  </svg>
)
export default SvgLevelsAddGradientIcon
