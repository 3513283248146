import { useNavigate, useParams } from 'react-router-dom'
import { GamePackageGamesDataProvider } from '../../contexts/GamePackageGamesContextProvider'
import { GamePackage } from './GamePackage'
import { useUser } from '../../contexts/userContext'
import { routes } from '../../routes'
import { useEffect } from 'react'

export const GamePackagesPage: React.FC = () => {
  const { packageId: packageIdParam } = useParams()
  const navigate = useNavigate()
  const { user } = useUser()

  useEffect(() => {
    if (!packageIdParam) {
      navigate(routes.createGamePackageWithId(user?.gamePackages?.[0].id ?? 0))
    }
  })

  const packageId = parseInt(packageIdParam ?? '0')
  return (
    <GamePackageGamesDataProvider packageId={packageId}>
      <GamePackage packageId={packageId} />
    </GamePackageGamesDataProvider>
  )
}
