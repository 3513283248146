import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { ExerciseType } from '../../../../../api/gameTypes'
import { useUser } from '../../../../../contexts/userContext'
import { AllowedOption } from '../../../../../types/commonTypes'
import { hasPermission } from '../../../../../util/permissions'
import { AddSubtaskItem } from './AddSubtaskItem'
import styles from './TaskModal.module.css'
import { getIconNameForTaskType, getTitleForTaskType } from './helpers'

type AddSubtaskSectionProps = {
  onClick: (type: ExerciseType) => void
}

export const AddSubtaskSection: React.FC<AddSubtaskSectionProps> = ({ onClick }) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const onClickInternal = (type: ExerciseType) => () => {
    onClick(type)
  }

  return (
    <>
      <h5 className={classNames('detailBold', styles.addSubtaskTitle)}>
        {t('game_editor.tasks.add_tasks_title', 'Add tasks')}
      </h5>
      <div className={styles.addSubtaskSection}>
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.CreativeExercise, t)}
          description={t('game_editor.tasks.add_subtask_button.creative_description', 'Selection description')}
          onClick={onClickInternal(ExerciseType.CreativeExercise)}
          icon={getIconNameForTaskType(ExerciseType.CreativeExercise)}
          tooltipContent={t(
            'game_editor.tasks.add_subtask_button.creative_tooltip',
            'Descriptive info about creative task',
          )}
        />
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.MultichoiceExercise, t)}
          description={t('game_editor.tasks.add_subtask_button.multiple_choice_description', 'Selection description')}
          onClick={onClickInternal(ExerciseType.MultichoiceExercise)}
          icon={getIconNameForTaskType(ExerciseType.MultichoiceExercise)}
          tooltipContent={t(
            'game_editor.tasks.add_subtask_button.multiple_choice_tooltip',
            'Descriptive info about multiple choice task',
          )}
        />
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.PollExercise, t)}
          description={t('game_editor.tasks.add_subtask_button.checkbox_description', 'Selection description')}
          onClick={onClickInternal(ExerciseType.PollExercise)}
          icon={getIconNameForTaskType(ExerciseType.PollExercise)}
          tooltipContent={t(
            'game_editor.tasks.add_subtask_button.checkbox_tooltip',
            'Descriptive info about checkbox task',
          )}
        />
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.MissingWordExercise, t)}
          description={t('game_editor.tasks.add_subtask_button.missing_word_description', 'Selection description')}
          onClick={onClickInternal(ExerciseType.MissingWordExercise)}
          icon={getIconNameForTaskType(ExerciseType.MissingWordExercise)}
          tooltipContent={t(
            'game_editor.tasks.add_subtask_button.missing_word_tooltip',
            'Descriptive info about missing word task',
          )}
        />
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.CombineExercise, t)}
          description={t('game_editor.tasks.add_subtask_button.match_pairs_description', 'Selection description')}
          onClick={onClickInternal(ExerciseType.CombineExercise)}
          icon={getIconNameForTaskType(ExerciseType.CombineExercise)}
          tooltipContent={t(
            'game_editor.tasks.add_subtask_button.match_pairs_tooltip',
            'Descriptive info about match pairs task',
          )}
        />
        {hasPermission(user, AllowedOption.COLLABORATION) && process.env.REACT_APP_ENV === 'development' && (
          <AddSubtaskItem
            className='hide-outscoped-feature'
            title={getTitleForTaskType(ExerciseType.CollaborationExercise, t)}
            description={t(
              'game_editor.tasks.add_subtask_button.create_and_comment_description',
              'Selection description',
            )}
            onClick={onClickInternal(ExerciseType.CollaborationExercise)}
            icon={getIconNameForTaskType(ExerciseType.CollaborationExercise)}
            tooltipContent={t(
              'game_editor.tasks.add_subtask_button.create_and_comment_tooltip',
              'Descriptive info about create and comment task',
            )}
          />
        )}
      </div>
    </>
  )
}
