import classNames from 'classnames'
import {
  AnswerStatus,
  AnswerStatusIcon,
} from '../../../pages/GameEditor/components/EditorSidebar/Play/components/GradeAnswersPanel/components/AnswerStatusIcon'
import { PointsView } from '../../../pages/GameEditor/components/EditorSidebar/Play/components/GradeAnswersPanel/components/PointsView'
import { MultipleChoiceSubtask } from '../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../util/string'
import styles from './SubtaskPreview.module.css'

type MultipleChoicePreviewProps = {
  subtask: MultipleChoiceSubtask
  noPointsGame?: boolean
}

export const MultipleChoicePreview: React.FC<MultipleChoicePreviewProps> = ({ subtask, noPointsGame }) => {
  return (
    <div className={classNames(styles.subtaskPreviewContainer, noPointsGame && styles.choiceOptionNoPointsPadding)}>
      {subtask.data.answers?.map((answer, index) => {
        const isCorrect = noPointsGame ? answer.isCorrectAnswer : answer.points === subtask.maxPoints
        return (
          <div key={`${subtask.id}_mc_${index}`} className={styles.answerOptionRow}>
            <div
              className={classNames(
                styles.choiceAnswerOptionContainer,
                isCorrect && styles.choiceAnswerOptionContainer_correct,
              )}
            >
              <div className={styles.choiceAnswerOptionImageAndText}>
                {!safeIsNullOrEmpty(answer.imageUrl) && <img width={86} src={answer.imageUrl} alt='' />}
                <span>{answer.text}</span>
              </div>
              {isCorrect && <AnswerStatusIcon status={AnswerStatus.SUCCESS} />}
            </div>
            {!noPointsGame && (
              <div>
                <PointsView points={answer.points ?? 0} />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
