import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../common/components/button/Button'
import { getIcon } from '../../../../../common/components/icons/utils'
import { Badge } from '../../../../../types/commonTypes'
import { BadgeList } from '../../Badges/BadgeList/BadgeList'
import styles from './Badges.module.css'

type BadgesProps = {
  badges: Badge[]
  viewOnly: boolean
  onClickManageBadges: () => void
}

export const Badges: React.FC<BadgesProps> = ({ badges, viewOnly, onClickManageBadges }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.badgesContainer}>
      <BadgeList badges={badges} />
      {!viewOnly && (
        <Button className={styles.badgeManagerButton} onClick={onClickManageBadges} variant='outline-tiny'>
          <span className='iconWrapperMedium'>{getIcon('badgeGradient')}</span>
          <span>{t('game_editor.game_settings.advanced.badges_open_badge_manager_button', 'Open badge manager')}</span>
        </Button>
      )}
    </div>
  )
}
