import * as React from 'react'
import type { SVGProps } from 'react'
const SvgScoreGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <path
      fill='currentColor'
      fillOpacity={1}
      fillRule='evenodd'
      d='M28 16c0 6.627-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4s12 5.373 12 12Zm-6.3-1.98a.71.71 0 0 1 .624.492.706.706 0 0 1-.228.78l-2.748 2.376.828 3.54a.698.698 0 0 1-.276.744.756.756 0 0 1-.42.132.703.703 0 0 1-.372-.108L16 20.104l-3.12 1.872a.73.73 0 0 1-.792-.036.74.74 0 0 1-.276-.744l.828-3.54-2.748-2.376a.74.74 0 0 1-.216-.768.71.71 0 0 1 .624-.492l3.624-.312 1.404-3.348c.228-.528 1.104-.528 1.332 0l1.416 3.348 3.624.312Z'
      clipRule='evenodd'
    />
    <defs>
      <linearGradient id='score_gradient_icon_svg__a' x1={16} x2={16} y1={4} y2={28} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgScoreGradientIcon
