import * as React from 'react'
import { SVGProps } from 'react'
const SvgChatNormal = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 6.667A2.667 2.667 0 0 1 5.667 4h16a2.667 2.667 0 0 1 2.666 2.667v5.16H27a2.667 2.667 0 0 1 2.667 2.666v6.667A2.667 2.667 0 0 1 27 23.827h-1.333v2.837a.667.667 0 0 1-1.055.543l-4.732-3.38h-3.547a2.667 2.667 0 0 1-2.666-2.667V20h1.6v.893c0 .737.597 1.334 1.333 1.334h3.808l3.659 2.613v-2.613h2.666c.737 0 1.334-.597 1.334-1.334V14.76c0-.736-.597-1.333-1.334-1.333h-2.4V16a2.667 2.667 0 0 1-2.666 2.667H13.72l-5.666 4.047A.667.667 0 0 1 7 22.17v-3.504H5.667A2.667 2.667 0 0 1 3 16V6.667ZM5.933 5.6c-.736 0-1.333.597-1.333 1.333v8.8c0 .737.597 1.334 1.333 1.334H8.6v3.28l4.592-3.28H21.4c.736 0 1.333-.597 1.333-1.334v-8.8c0-.736-.597-1.333-1.333-1.333H5.933Z'
      fill={props.color ?? '#6F6F6F'}
    />
  </svg>
)
export default SvgChatNormal
