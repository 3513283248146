import { useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/userContext'
import { ShareLoginInfo } from './ShareLoginInfo'

export const ShareLoginInfoPage: React.FC = () => {
  const { user } = useUser()
  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  const pin = params.get('pin')
  const title = params.get('title')
  const is3D = params.get('is3D')

  if (user == null) {
    return null
  }

  return <ShareLoginInfo pin={pin} title={title} is3D={is3D === 'true'} />
}
