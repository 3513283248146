import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../common/components/Form/InputFormField/InputFormField'
import {
  CreatableMultiSelectFormField,
  MultiSelectFormField,
  SelectFormField,
} from '../../../../common/components/Form/SelectFormField/SelectFormField'
import { TextAreaFormField } from '../../../../common/components/Form/TextAreaFormField/TextAreaFormField'
import { getAgeOptions, getLanguageOptions, getSubjectOptions } from '../../../search/components/constants'
import { GAME_DESCRIPTION_MAX_LENGTH } from '../QuickStartWizard/steps/GameInfoStep'
import styles from './ShareGameModal.module.css'
import classNames from 'classnames'

export const GeneralShareSettings: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.generalSection}>
      <InputFormField
        fieldContainerClassName={styles.fieldContainer}
        inputContainerClassName={styles.field}
        className={styles.field}
        inline
        name='name'
        label={t('game_editor.game_settings.general.game_name_label', 'Game name')}
      />
      <TextAreaFormField
        inline
        name='description'
        label={t('game_editor.game_settings.general.description_label', 'Description')}
        placeholder={t(
          'game_editor.game_settings.general.description_placeholder',
          'Write a general description of the game',
        )}
        fieldContainerClassName={styles.fieldContainer}
        className={styles.field}
        maxLength={GAME_DESCRIPTION_MAX_LENGTH}
      />
      <MultiSelectFormField
        inline
        fieldContainerClassName={styles.fieldContainer}
        className={styles.field}
        name='topics'
        label={t('game_editor.game_settings.general.topics_label', 'Topics')}
        placeholder={t('game_editor.game_settings.general.topics_placeholder', 'Select topics')}
        options={getSubjectOptions(t)}
        isSearchable
      />
      <SelectFormField
        inline
        fieldContainerClassName={styles.fieldContainer}
        className={styles.field}
        name='language'
        label={t('game_editor.game_settings.general.language_label', 'Language')}
        placeholder={t('game_editor.game_settings.general.language_placeholder', 'Select language')}
        options={getLanguageOptions(t)}
        isSearchable
      />
      <MultiSelectFormField
        inline
        fieldContainerClassName={styles.fieldContainer}
        className={styles.field}
        name='ages'
        label={t('game_editor.game_settings.general.ages_label', 'Ages')}
        placeholder={t('game_editor.game_settings.general.ages_placeholder', 'Select age')}
        options={getAgeOptions(t)}
      />
      <CreatableMultiSelectFormField
        inline
        fieldContainerClassName={styles.fieldContainer}
        className={classNames(styles.field, styles.keywordField)}
        label={t('game_editor.game_settings.general.keywords_label', 'Keywords')}
        name='keywords'
        placeholder={t('game_editor.game_settings.general.keywords_placeholder', 'Enter keywords')}
      />
    </div>
  )
}
