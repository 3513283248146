import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { IconName, getIcon } from '../icons/utils'
import styles from './PopupActionMenu.module.css'
import { PopupMenu, PopupMenuProps } from './PopupMenu'

export const PopupActionMenu: React.FC<PropsWithChildren<PopupMenuProps>> = ({ children, ...rest }) => {
  return (
    <PopupMenu {...rest}>
      <div className={styles.popupActionMenu}>{children}</div>
    </PopupMenu>
  )
}

export type PopupActionMenuItemProps = {
  text: string
  icon?: IconName
  onClick: () => void
  closeOnClick?: boolean
  disabled?: boolean
}

export const PopupActionMenuItem: React.FC<PopupActionMenuItemProps> = ({
  text,
  icon,
  onClick,
  closeOnClick = true,
  disabled,
}) => {
  return (
    <button
      className={classNames(styles.popupActionMenuItem, disabled && styles.popupActionMenuItem_disabled)}
      onClick={onClick}
      type='button'
      data-close-menu={closeOnClick}
      disabled={disabled}
    >
      {icon != null && <span className={styles.popupActionMenuItemIcon}>{getIcon(icon)}</span>} {text}
    </button>
  )
}

type PopupActionMenuChangeItemProps = PopupActionMenuItemProps & {
  subText: string
}

export const PopupActionMenuChangeItem: React.FC<PopupActionMenuChangeItemProps> = ({
  text,
  subText,
  icon,
  onClick,
  closeOnClick = false,
}) => {
  return (
    <button className={styles.popupActionMenuItem} onClick={onClick} type='button' data-close-menu={closeOnClick}>
      {icon != null && <span className={styles.popupActionMenuItemIcon}>{getIcon(icon)}</span>}
      <div className={styles.menuChangeItemTextContainer}>
        <span>{text}</span>
        <span className={styles.menuChangeItemSubtext}>{subText}</span>
      </div>
      <span className={styles.menuItemChevronIcon}>{getIcon('arrowRight')}</span>
    </button>
  )
}

export type PopupActionMenuBackItemProps = {
  onClick: () => void
  closeOnClick?: boolean
}

export const PopupActionMenuBackItem: React.FC<PopupActionMenuBackItemProps> = ({ onClick, closeOnClick = false }) => {
  const { t } = useTranslation()

  return (
    <button className={styles.popupActionMenuItem} onClick={onClick} type='button' data-close-menu={closeOnClick}>
      <span className={styles.menuItemChevronIcon}>{getIcon('arrowLeft')}</span> {t('common.back', 'Back')}
    </button>
  )
}
