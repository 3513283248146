import React, { createContext, useContext } from 'react'

export const HELP_MENU_CONTROL_ID = 'help_menu_control'

export type HelpMenuContextType = {
  showHelpMenu: boolean
  toggleDisplayMenu: () => void
}

export const HelpMenuContext = createContext<HelpMenuContextType>({
  showHelpMenu: false,
  toggleDisplayMenu: () => {},
})

interface Props {
  children?: React.ReactNode
}

export const HelpMenuProvider: React.FC<Props> = ({ children }) => {
  const [showHelpMenu, setShowHelpMenu] = React.useState(false)

  const toggleDisplayMenu = React.useCallback(() => {
    setShowHelpMenu((prevState) => !prevState)
  }, [])

  return <HelpMenuContext.Provider value={{ showHelpMenu, toggleDisplayMenu }}>{children}</HelpMenuContext.Provider>
}

export const useHelpMenu = () => {
  const context = useContext(HelpMenuContext)
  if (!context) throw new Error('Expected to be wrapped in a HelpMenuContextProvider!')
  return context
}
