import React from 'react'
import { PlayerScore } from '../../../../../../../../types/commonTypes'
import styles from '../../Scoreboard/Scoreboard.module.css'
import { ListItem } from './ListItem'

type PlayerListProps = {
  scoreTable: PlayerScore[]
}

export const PlayerList: React.FC<PlayerListProps> = ({ scoreTable }) => {
  return (
    <div className={styles.playersListContainer}>
      {scoreTable.map((p) => {
        return (
          <div key={`scoreboardRest_${p.playerId}`}>
            <ListItem playerScore={p} />
          </div>
        )
      })}
    </div>
  )
}
