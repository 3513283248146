import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../AddPerson.module.css'
import { Game } from '../../../../../types/commonTypes'
import { getIcon } from '../../../../../common/components/icons/utils'
import { GameSettingsToggle } from '../../GameSettings/components/GameSettingsToggle'
import { Form } from 'react-final-form'
import { Button } from '../../../../../common/components/button/Button'

type ShareButtonProps = {
  game: Game
}

type ScormForm = {
  sendAnswersNormally: boolean
}

export const ScormDownload: React.FC<ShareButtonProps> = ({ game }) => {
  const { t } = useTranslation()

  const initialValues: ScormForm = {
    sendAnswersNormally: true,
  }
  const handleSubmit = (values: ScormForm) => {
    window.location.href = `${process.env.REACT_APP_OLD_UI_BASE_URL}/admin/games/${game.gameId}/get_offline_package?send_answers_normally=${values.sendAnswersNormally}`
  }

  return (
    <div className={classNames(styles.scormArea)}>
      <div className={styles.scormTitle}>{t('game_editor.add_people.scorm_option.title', 'LMS Scorm integration')}</div>
      <Form<ScormForm> initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, submitting, initialValues, values }) => (
          <form className={styles.scormForm} onSubmit={handleSubmit}>
            <GameSettingsToggle
              name='sendAnswersNormally'
              label={t('game_editor.add_people.scorm_option.send_answers_toggle', 'Send answers to teacher')}
              description={t(
                'game_editor.add_people.scorm_option.send_answers_toggle_disabled',
                'Game is played and result is stored only locally to LMS',
              )}
              descriptionChecked={t(
                'game_editor.add_people.scorm_option.send_answers_toggle_enabled',
                'Game sends the answers normally also to the Seppo instructor.',
              )}
            />
            <Button type='submit' disabled={submitting} className={styles.addPlayerByEmailButton}>
              {getIcon('download')}
              {t('game_editor.add_people.download_scorm', 'Download Scorm package')}
            </Button>
          </form>
        )}
      </Form>
    </div>
  )
}
