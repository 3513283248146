import * as React from 'react'
import { SVGProps } from 'react'
const SvgLanguageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#language_icon_svg__a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.717 1.855A1 1 0 0 0 25.688.14l-5 3a1 1 0 0 0-.343 1.372l3 5a1 1 0 1 0 1.715-1.03l-1.448-2.414a8.002 8.002 0 0 1 6.449 5.19 1 1 0 0 0 1.878-.684 10.002 10.002 0 0 0-8.82-6.56l3.598-2.16ZM7.283 22.14a1 1 0 0 1 1.372.343l2.379 3.965.005.01.616 1.025a1 1 0 0 1-.343 1.372l-5 3a1 1 0 1 1-1.03-1.715l3.6-2.16A10.002 10.002 0 0 1 .06 21.42a1 1 0 1 1 1.88-.685 8.002 8.002 0 0 0 6.448 5.19L6.94 23.512a1 1 0 0 1 .343-1.372ZM3 1.997a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-12a1 1 0 0 0-1-1H3Zm-3 1a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-12Zm21 11a1 1 0 1 0 0 2h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H17a1 1 0 0 1-1-1v-8a1 1 0 1 0-2 0v8a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-12a3 3 0 0 0-3-3h-8Zm2 6a1 1 0 0 0-1 1v3h2v-3a1 1 0 0 0-1-1Zm1 6v1a1 1 0 1 0 2 0v-6a3 3 0 0 0-6 0v6a1 1 0 1 0 2 0v-1h2Zm-15-22a1 1 0 0 1 1 1v1h3a1 1 0 1 1 0 2h-1.313a15.095 15.095 0 0 1-1.43 2.668A4.247 4.247 0 0 0 13.066 12a1 1 0 0 1-.136 1.995 6.249 6.249 0 0 1-3.957-1.78C7.965 13.2 6.645 13.996 5 13.996a1 1 0 1 1 0-2c1.226 0 2.291-.776 3.168-1.87a.993.993 0 0 1 .094-.118c.14-.182.276-.371.406-.566.334-.5.609-1.005.824-1.446H5a1 1 0 1 1 0-2h3v-1a1 1 0 0 1 1-1Z'
        fill={props.color ?? '#6F6F6F'}
      />
    </g>
    <defs>
      <clipPath id='language_icon_svg__a'>
        <path fill={props.color ?? '#6F6F6F'} d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgLanguageIcon
