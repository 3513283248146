import classNames from 'classnames'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../../../../../common/components/icons/utils'
import { dateTimeToLocalDateTimeString, safeIsNullOrEmpty } from '../../../../../../../../util/string'
import styles from './AnswerContainer.module.css'
import collapsibleStyles from './CollapsibleCardSection.module.css'
import { PointsView } from './PointsView'

//Comments are for translations generator to find these keys
// t('game_editor.sidebar.evaluate_answer_panel.players_previous_answer_first', "Player's first answer")
// t('game_editor.sidebar.evaluate_answer_panel.players_previous_answer_second', "Player's second answer")
// t('game_editor.sidebar.evaluate_answer_panel.players_previous_answer_third', "Player's third answer")
// t('game_editor.sidebar.evaluate_answer_panel.players_previous_answer_four_plus', "Player's answer %{answer_count}")
const PREVIOUS_ANSWER_KEY_NAMES = [
  'game_editor.sidebar.evaluate_answer_panel.players_previous_answer_first',
  'game_editor.sidebar.evaluate_answer_panel.players_previous_answer_second',
  'game_editor.sidebar.evaluate_answer_panel.players_previous_answer_third',
  'game_editor.sidebar.evaluate_answer_panel.players_previous_answer_four_plus',
]

type AnswerContainerProps = {
  time?: string
  points?: number
  feedbackMessage?: string
  isPreviousAnswer?: boolean
  previousAnswerIndex?: number
}

export const AnswerContainer: React.FC<PropsWithChildren<AnswerContainerProps>> = ({
  time,
  points,
  feedbackMessage,
  isPreviousAnswer = false,
  previousAnswerIndex = 0,
  children,
}) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(!isPreviousAnswer)
  useEffect(() => {
    setIsExpanded(!isPreviousAnswer)
  }, [isPreviousAnswer])

  return (
    <div className={classNames(styles.answerContainer, isPreviousAnswer && styles.previousAnswerContainer)}>
      <div className={classNames(styles.answerHeader, 'tiny')}>
        <span
          className={classNames('bold', isPreviousAnswer && styles.clickable)}
          onClick={() => (isPreviousAnswer ? setIsExpanded((prev) => !prev) : undefined)}
        >
          {isPreviousAnswer
            ? t(PREVIOUS_ANSWER_KEY_NAMES[Math.min(previousAnswerIndex, 3)], "Player's previous answer", {
                answer_count: previousAnswerIndex + 1,
              })
            : t('game_editor.sidebar.evaluate_answer_panel.players_current_answer', "Player's current answer")}
        </span>
        {isPreviousAnswer && (
          <button
            className={classNames(collapsibleStyles.toggleCollapseButton, isExpanded && collapsibleStyles.iconExpanded)}
            type='button'
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            {getIcon('arrowDown')}
          </button>
        )}
        {!safeIsNullOrEmpty(time) && (
          <span className={styles.answerTimestamp}>{dateTimeToLocalDateTimeString(time)}</span>
        )}
      </div>
      {(points != null || !!feedbackMessage) && (
        <div className={styles.headerSecondRow}>
          {isPreviousAnswer && !isExpanded && feedbackMessage != null && (
            <div className={classNames('tiny', styles.previousAnswerFeedbackPreview)}>"{feedbackMessage}"</div>
          )}
          <PointsView active={!isPreviousAnswer} points={points ?? 0} />
        </div>
      )}
      {isExpanded && (
        <>
          <div className={styles.divider} />
          {children}
        </>
      )}
      {isExpanded && feedbackMessage != null && (
        <>
          <div className={styles.divider} />
          <div className='tiny'>{feedbackMessage}</div>
        </>
      )}
    </div>
  )
}
