import classNames from 'classnames'
import { Fragment } from 'react'
import { PointsView } from '../../../pages/GameEditor/components/EditorSidebar/Play/components/GradeAnswersPanel/components/PointsView'
import { MatchPairsAnswerSide, MatchPairsSubtask } from '../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../util/string'
import styles from './SubtaskPreview.module.css'

type MatchPairsPreviewProps = {
  subtask: MatchPairsSubtask
  noPointsGame?: boolean
}

const MatchPairsSide: React.FC<{ side: MatchPairsAnswerSide }> = ({ side }) => (
  <div className={classNames(styles.matchPairsSide, safeIsNullOrEmpty(side.imageUrl) && styles.matchPairsSide_noImage)}>
    {!safeIsNullOrEmpty(side.imageUrl) && <img width={86} src={side.imageUrl} alt='' />}
    {side.text && <span>{side.text}</span>}
  </div>
)

export const MatchPairsPreview: React.FC<MatchPairsPreviewProps> = ({ subtask, noPointsGame }) => {
  return (
    <div className={styles.subtaskPreviewContainer}>
      {subtask.data.answers?.map((answer, index) => (
        <Fragment key={`${subtask.id}_mp_${index}`}>
          {index > 0 && (
            <div className={classNames(styles.answerOptionRow)}>
              <div className={styles.divider} />
              {!noPointsGame && <div className={styles.divider} />}
            </div>
          )}
          <div className={classNames(styles.answerOptionRow, styles.matchPairsOption)}>
            <div>
              <div className={styles.matchPairsSidesContainer}>
                <MatchPairsSide side={answer.left} />
                <MatchPairsSide side={answer.right} />
              </div>
            </div>
            {!noPointsGame && (
              <div>
                <PointsView points={answer.points ?? 0} />
              </div>
            )}
          </div>
        </Fragment>
      ))}
    </div>
  )
}
