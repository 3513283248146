import classNames from 'classnames'
import { useCallback, useEffect, useId, useRef, useState } from 'react'
import { TooltipPortal } from '../Portal/Portal'
import styles from './Tooltip.module.css'

type TooltipChildrenProps = {
  id: string
  onMouseEnter: (evt: any) => void
  onMouseLeave: () => void
}

type TooltipDirection = 'up' | 'down' | 'right' | 'left'

export type TooltipProps = {
  tooltipContent: string | JSX.Element
  tooltipClassName?: string
  children: (props: TooltipChildrenProps) => React.ReactNode
  closeOnScroll?: boolean
  idOverride?: string
  timeoutMs?: number
  direction?: TooltipDirection
  overflowOnly?: boolean
  requireExplicitClose?: boolean
  keepOpen?: boolean
  avoidModals?: boolean
}

type Position = {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

const DEFAULT_POSITION: Position = {
  bottom: -100,
  left: -100,
}

const DEFAULT_TOOLTIP_TIMEOUT = 1_000
export const TOOLTIP_EXPLICIT_CLOSE_CLASS_ID = 'TOOLTIP_EXPLICIT_CLOSE_CLASS_ID'

const getPositionForDirection = (direction: TooltipDirection, anchorElement: HTMLElement) => {
  const rect = anchorElement.getBoundingClientRect()
  switch (direction) {
    case 'down':
      return {
        top: rect.y + rect.height + window.scrollY + 5,
        right: undefined,
        bottom: undefined,
        left: rect.x + rect.width / 2,
      }
    case 'right':
      return {
        top: rect.y + rect.height / 2,
        right: undefined,
        bottom: undefined,
        left: rect.x + rect.width + 5,
      }
    case 'left':
      return {
        top: rect.y + rect.height / 2,
        right: window.innerWidth - rect.x + 5,
        bottom: undefined,
        left: undefined,
      }
    default:
      return {
        top: undefined,
        right: undefined,
        bottom: window.innerHeight - rect.y - window.scrollY,
        left: rect.x + rect.width / 2,
      }
  }
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  tooltipContent,
  tooltipClassName,
  closeOnScroll,
  idOverride,
  timeoutMs,
  direction = 'up',
  overflowOnly,
  requireExplicitClose = false,
  keepOpen = false,
  avoidModals = false,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [position, setPosition] = useState<Position>(DEFAULT_POSITION)
  const timeout = useRef<NodeJS.Timeout>()
  const id = useId()

  const onMouseEnter = useCallback(
    (evt?: any) => {
      //Don't activate if button(s) pressend
      if (
        evt?.buttons ||
        (avoidModals && (document.getElementById('MODALS_ROOT') as HTMLElement)?.children?.length > 1)
      )
        return
      timeout.current = setTimeout(() => {
        const element = document.querySelector<HTMLElement>(`#${idOverride ?? CSS.escape(id)}`)
        if (element != null) {
          if (!overflowOnly || (overflowOnly && element.offsetWidth >= (element.parentElement?.offsetWidth ?? 0))) {
            setPosition(getPositionForDirection(direction, element))
            setShow(true)
          }
        }
      }, timeoutMs ?? DEFAULT_TOOLTIP_TIMEOUT)
    },
    [avoidModals, direction, id, idOverride, overflowOnly, timeoutMs],
  )

  useEffect(() => {
    if (keepOpen) onMouseEnter({})
  }, [keepOpen, onMouseEnter])

  const handleClose = () => {
    setShow(false)
    setPosition(DEFAULT_POSITION)
    clearTimeout(timeout.current)
  }

  const onMouseLeave = () => {
    if (!requireExplicitClose) {
      handleClose()
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  useEffect(() => {
    const onScroll = () => {
      setShow(false)
      setPosition(DEFAULT_POSITION)
      clearTimeout(timeout.current)
    }
    if (closeOnScroll) {
      document.addEventListener('scroll', onScroll, true)
    }
    return () => {
      document.removeEventListener('scroll', onScroll, true)
    }
  }, [closeOnScroll])

  return (
    <>
      {children({
        id: idOverride ?? id,
        onMouseEnter,
        onMouseLeave,
      })}
      {show && (
        <TooltipPortal>
          <div
            className={classNames(styles.tooltipContent, 'tiny', styles[`tooltip_${direction}`], tooltipClassName)}
            style={position}
          >
            {tooltipContent}
            {requireExplicitClose && !keepOpen && (
              <div
                onClick={handleClose}
                className={classNames(styles.explicitClose, TOOLTIP_EXPLICIT_CLOSE_CLASS_ID)}
              />
            )}
          </div>
        </TooltipPortal>
      )}
    </>
  )
}
