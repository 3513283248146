import { useTranslation } from 'react-i18next'
import { Button } from '../../../../common/components/button/Button'
import { getIcon } from '../../../../common/components/icons/utils'
import { NewBusinessInfo } from '../../../../types/commonTypes'
import sharedStyles from '../../Settings.module.css'
import tableStyles from './MembersTable.module.css'
import styles from './Players.module.css'

type PlayersProps = {
  organizationName: string
  onViewAllPlayerAccounts?: () => void
  newBusinessInfo: NewBusinessInfo | undefined
  onViewAllLicenseHolders?: () => void
  isShowingAllLicenseHolders?: boolean
}

type LicenceHolder = {
  name: string
  type: string
}

export const Players: React.FC<PlayersProps> = ({
  organizationName,
  onViewAllPlayerAccounts,
  newBusinessInfo,
  onViewAllLicenseHolders,
  isShowingAllLicenseHolders = false,
}) => {
  const { t } = useTranslation()
  const { pinSeatHolders, emailSeatHolders, licenceCount } = newBusinessInfo || {}

  const emailLicenseHolders: LicenceHolder[] = (emailSeatHolders || '')
    .split(',')
    .filter((item) => item.length)
    .map((seatHolder): LicenceHolder => {
      return {
        name: seatHolder,
        type: t('settings_organization.players.email_type', 'eMail/Player account'),
      }
    })

  const pinLicenseHolders: LicenceHolder[] = (pinSeatHolders || '')
    .split(',')
    .filter((item) => item.length)
    .map((seatHolder): LicenceHolder => {
      return {
        name: seatHolder,
        type: t('settings_organization.players.qr_pin_type', 'QR/PIN'),
      }
    })

  const allLicenseHolders = isShowingAllLicenseHolders
    ? emailLicenseHolders.concat(pinLicenseHolders)
    : emailLicenseHolders.concat(pinLicenseHolders).slice(0, 20)

  let seatRow = 1
  return (
    <div className={sharedStyles.section}>
      <h1 className={sharedStyles.sectionTitle}>
        {t('settings_organization.players.title', {
          organization_name: organizationName,
          defaultValue: '%{organization_name} players',
        })}
      </h1>
      {licenceCount && (
        <div style={{ gap: 0 }} className={styles.tableContainer}>
          <h2>{t('settings_organization.players.license_holders_title', 'Player license holders')}</h2>
          {allLicenseHolders.length > 0 && (
            <table style={{ width: '100%' }} className={tableStyles.membersTable}>
              <colgroup>
                <col style={{ width: '3%' }} />
                <col style={{ width: '37%' }} />
                <col style={{ width: '60%' }} />
              </colgroup>
              <thead>
                <th>{''}</th>
                <th>{t('settings_organization.players.table_headers.license_holder', 'License Holder')}</th>
                <th>{t('settings_organization.players.table_headers.login_type', 'Login type')}</th>
              </thead>
              <tbody>
                {allLicenseHolders.map((seatHolder) => {
                  return (
                    <tr>
                      <td>{seatRow++}</td>
                      <td>{seatHolder.name}</td>
                      <td>{seatHolder.type}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
          {!isShowingAllLicenseHolders && (
            <div className={styles.viewAllLicenseHolders}>
              <Button variant='outline-tiny' onClick={onViewAllLicenseHolders}>
                <span className='iconWrapperBig'>{getIcon('view')}</span>
                {t('settings_organization.players.view_all_button_text', 'View all')}
              </Button>
            </div>
          )}
        </div>
      )}
      {!isShowingAllLicenseHolders && (
        <div className={styles.container}>
          {licenceCount && (
            <h2 style={{ alignSelf: 'flex-start' }}>
              {t('settings_organization.players.player_accounts_title', 'All Player accounts')}
            </h2>
          )}
          <span className={styles.viewPlayersMessage}>
            {t(
              'settings_organization.players.view_all_message',
              'To view all players with an account, click on the link below',
            )}
          </span>
          <div>
            <Button variant='outline-tiny' onClick={onViewAllPlayerAccounts}>
              <span className='iconWrapperBig'>{getIcon('view')}</span>
              {t('settings_organization.players.view_all_button_text', 'View all')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
