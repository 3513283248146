import { SortableContext } from '@dnd-kit/sortable'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { Task } from '../../../../../../../types/commonTypes'
import { TaskActionFn } from '../../types'
import { BoardHeader } from '../BoardsList/BoardHeader'
import { SortableItemPlaceholder } from '../TaskCard/SortableItemPlaceholder'
import { SortableTask } from '../TaskCard/SortableTask'
import styles from './LevelTasks.module.css'
import { NoTasksPlaceholder } from './NoTasksPlaceholder'

type LevelBoardTasksProps = {
  tasks: Task[]
  isActive: boolean
  levelIndex: number
  maxLevelIndex: number
  boardIndex: number
  boardUrl: string
  maxBoardIndex: number
  title: string
  compact?: boolean
  noPointsGame?: boolean
  orderingEnabled?: boolean
  isDragging: boolean
  isDefaultBoard?: boolean
  showHigherLevelWarning: boolean
  viewOnly: boolean
  onSetActiveBoard: (id: number) => void
  onTaskAction: TaskActionFn
}

export const LevelBoardTasks: React.FC<LevelBoardTasksProps> = ({
  tasks,
  isActive,
  levelIndex,
  maxLevelIndex,
  boardIndex,
  title,
  boardUrl,
  maxBoardIndex,
  isDragging,
  compact,
  noPointsGame,
  orderingEnabled,
  isDefaultBoard,
  showHigherLevelWarning,
  viewOnly,
  onSetActiveBoard,
  onTaskAction,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const onToggleCollapsed = useCallback(() => setIsCollapsed((prev) => !prev), [])

  const collapseProps = useMemo(
    () => (tasks.length > 0 ? { isCollapsed, onToggleCollapsed } : undefined),
    [tasks, isCollapsed, onToggleCollapsed],
  )

  return (
    <li className={styles.levelContentContainer}>
      <BoardHeader
        boardUrl={boardUrl}
        boardIndex={boardIndex}
        isActive={isActive}
        onSetActiveBoard={onSetActiveBoard}
        title={title}
        collapseProps={collapseProps}
        isDefaultBoard={isDefaultBoard}
      />
      {tasks.length === 0 ? (
        <>
          {isDragging && <SortableItemPlaceholder levelIndex={levelIndex} boardIndex={boardIndex} />}
          {isDefaultBoard && (
            <NoTasksPlaceholder
              showDefaultBoardWarning={isDefaultBoard}
              showHigherLevelWarning={showHigherLevelWarning}
            />
          )}
        </>
      ) : (
        <SortableContext items={tasks} id={`level-${levelIndex}_board-${boardIndex}`} disabled={viewOnly}>
          {(!isCollapsed || isDragging) && (
            <ul className={classNames(styles.levelTasksList, tasks.length > 0 && styles.listBottomPadding)}>
              {tasks.map((task, index) => {
                return (
                  <SortableTask
                    key={`taskCard_${task.id}`}
                    task={task}
                    compact={compact}
                    noPointsGame={noPointsGame}
                    showLevel
                    maxBoardIndex={maxBoardIndex}
                    maxLevelIndex={maxLevelIndex}
                    onAction={onTaskAction}
                    index={index}
                    maxIndex={tasks.length - 1}
                    showOrder={orderingEnabled}
                    viewOnly={viewOnly}
                  />
                )
              })}
            </ul>
          )}
        </SortableContext>
      )}
    </li>
  )
}
