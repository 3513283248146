import { getIcon } from '../../../common/components/icons/utils'

import styles from '../Search.module.css'

type RemoveFilterButtonProps = {
  onClick: () => void
}

export const RemoveFilterButton: React.FC<RemoveFilterButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.removeFilterButton} onClick={onClick}>
      {getIcon('close')}
    </button>
  )
}
