import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { createNewEmptyGame } from '../api/gameApiService'
import { useDebug } from '../contexts/DebugContext'
import { useNotification } from '../contexts/NotificationContext'
import { useUser } from '../contexts/userContext'
import { routes } from '../routes'
import { prefersOldEditor } from '../util/editorPreference'
import { preventUserCreateGame } from '../util/game'

export const useGameEditorNavigation = () => {
  const { allowUseNewEditor } = useDebug()
  const navigate = useNavigate()
  const { notifyError } = useNotification()
  const { t } = useTranslation()
  const { user } = useUser()

  const goToCreateGame = useCallback(async () => {
    if (preventUserCreateGame(user)) {
      notifyError({
        title: t('dashboard.notifications.trial_expired.title', 'Creating the game failed'),
        content: t(
          'dashboard.notifications.trial_expired.content',
          'You need a valid licence to create a game. Update your licence or contact us for support',
        ),
      })
    } else {
      if (!prefersOldEditor() && !user?.preferLegacyEditor && (allowUseNewEditor || user?.allowUseNewEditor)) {
        navigate(routes.gameEditor)
      } else {
        try {
          const response = await createNewEmptyGame()
          if (response.success && response.value != null) {
            navigate(routes.createGameEditorWithId(response.value.toString(), true, true))
          } else {
            throw response.success ? new Error('new game id not defined') : response.error
          }
        } catch (error) {
          console.error(error)
          notifyError({
            title: t('game_editor.create_game_error_notification.title', 'Failed to create game'),
            content: t(
              'game_editor.create_game_error_notification.content',
              'An error occurred while creating a new game. Please try again or contact us for support.',
            ),
          })
        }
      }
    }
  }, [user, notifyError, t, allowUseNewEditor, navigate])

  return { goToCreateGame }
}
