import React from 'react'
import { useUser } from '../../contexts/userContext'
import { isNullOrZero } from '../../util/number'
import { ReportsPreview } from './components/ReportsPreview'
import { EmptyContent } from '../../composites/GamesOverview/components/EmptyContent'
import { useTranslation } from 'react-i18next'

export const Reports: React.FC = () => {
  const { user } = useUser()
  const { t } = useTranslation()

  if (user == null || isNullOrZero(user.reports.length)) {
    return (
      <EmptyContent
        title={t('reports.empty.title', 'You have no reports yet')}
        subtitle={t(
          'reports.empty.content',
          "Play your first game to see your instructor report. If your report doesn't show up here, try refreshing the reports.",
        )}
        refreshButton={{ label: t('reports.empty.refresh_reports_button', 'Refresh reports') }}
      />
    )
  }

  return <ReportsPreview user={user} />
}
