import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../common/components/icons/utils'
import { EMPTY_CONTENT_FALLBACK } from '../../../common/constants'
import { TGameCard } from '../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../util/string'
import { InfoPill } from '../InfoPill'
import styles from './GamePreviewModalSidebar.module.css'

type SidebarShowMoreProps = Pick<TGameCard, 'subjects' | 'description' | 'ages' | 'language' | 'tags'> & {
  isLibrary: boolean
}

// NOTE: animating showing more/less for variable height is tricky
// this component transitions max-height, which is initially set to an awkward big enough number
// once the section is expanded for the first time, max height is updated to the actual size
// unlikely to happen, but this will bug out if the actual height exceeds INITIAL_HEIGHT_MAX
const INITIAL_HEIGHT_MAX = 2222.22
const HEIGHT_TRANSITION_TIMEOUT_MS = 300

export const SidebarShowMore: React.FC<SidebarShowMoreProps> = ({
  subjects,
  description,
  language,
  ages,
  tags,
  isLibrary,
}) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState<boolean>(false)

  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)

  const hiddenContentRef = useRef<HTMLDivElement>(null)
  const [hiddenContentHeight, setHiddenContentHeight] = useState<number>(INITIAL_HEIGHT_MAX)

  const hasSetHeight = useMemo(() => hiddenContentHeight !== INITIAL_HEIGHT_MAX, [hiddenContentHeight])

  useEffect(() => {
    if (!hasSetHeight && showMore) {
      setTimeout(() => {
        setHiddenContentHeight(hiddenContentRef.current?.getBoundingClientRect().height ?? INITIAL_HEIGHT_MAX)
      }, HEIGHT_TRANSITION_TIMEOUT_MS)
    }
  }, [hasSetHeight, showMore])

  const toggleShowMore = useCallback(() => {
    setShowMore((prev) => !prev)
    setIsTransitioning(true)
    setTimeout(() => setIsTransitioning(false), HEIGHT_TRANSITION_TIMEOUT_MS)
  }, [])

  return (
    <div className={styles.sidebarShowMoreContainer}>
      <div
        ref={hiddenContentRef}
        style={{
          maxHeight: showMore ? hiddenContentHeight : 0,
          opacity: showMore ? 1 : 0,
          transition: `max-height ${HEIGHT_TRANSITION_TIMEOUT_MS}ms ease-in, opacity ${
            showMore ? '700' : '200'
          }ms linear`,
        }}
      >
        <div className={styles.showMoreSection}>
          <div className={classNames('tiny bold', styles.sidebarShowMoreTitle)}>
            {t('game_preview_modal.side_bar_show_more.topics', 'Topics')}
          </div>
          {subjects?.length ? (
            <ul className={styles.sidebarTopicList}>
              {subjects.map((topic, index) => (
                <li key={`${topic}${index}`} className='normal regular'>
                  {topic}
                </li>
              ))}
            </ul>
          ) : (
            EMPTY_CONTENT_FALLBACK
          )}
        </div>

        <div className={styles.showMoreSectionSplit}>
          <div>
            <div className={classNames('tiny bold', styles.sidebarShowMoreTitle)}>
              {t('game_preview_modal.side_bar_show_more.language', 'Language')}
            </div>
            <div className='normal'>{language || EMPTY_CONTENT_FALLBACK}</div>
          </div>
          <div>
            <div className={classNames('tiny bold', styles.sidebarShowMoreTitle)}>
              {t('game_preview_modal.side_bar_show_more.ages', 'Ages')}
            </div>
            {ages?.length ? (
              <ul className={styles.sidebarAgesList}>
                {ages.map((age, index) => (
                  <li key={`${age}${index}`} className='normal'>
                    {age}
                  </li>
                ))}
              </ul>
            ) : (
              EMPTY_CONTENT_FALLBACK
            )}
          </div>
        </div>

        <div className={styles.showMoreSection}>
          <div className={classNames('tiny bold', styles.sidebarShowMoreTitle)}>
            {t('game_preview_modal.side_bar_show_more.tags', 'Tags')}
          </div>
          {tags?.length ? (
            <ul className={styles.sidebarTagsList} tabIndex={0}>
              {tags.map((tag, index) => (
                <li key={`${tag}${index}`}>
                  <InfoPill isLibrary={isLibrary}>{tag}</InfoPill>
                </li>
              ))}
            </ul>
          ) : (
            EMPTY_CONTENT_FALLBACK
          )}
        </div>

        {!safeIsNullOrEmpty(description) && (
          <div className={styles.showMoreSection}>
            <div className={classNames('tiny bold', styles.sidebarShowMoreTitle)}>
              {t('game_preview_modal.side_bar_show_more.description', 'Description')}
            </div>
            <div className='normal'>{description}</div>
          </div>
        )}
      </div>

      <button className={styles.showMoreButton} disabled={isTransitioning} onClick={toggleShowMore}>
        <span
          style={{
            rotate: showMore ? '180deg' : '0deg',
            transition: `all ${HEIGHT_TRANSITION_TIMEOUT_MS}ms ease-in`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {getIcon('arrowDown')}
        </span>
        {showMore
          ? t('game_preview_modal.side_bar_show_more.show_less', 'Show less')
          : t('game_preview_modal.side_bar_show_more.show_more', 'Show more')}
      </button>
    </div>
  )
}
