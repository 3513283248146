import * as React from 'react'
import { SVGProps } from 'react'

const SvgTextIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.406 1.938a.47.47 0 0 0-.469.468V12.72c0 .258.21.469.47.469h3.749a.469.469 0 0 1 0 .937h-3.75c-.776 0-1.406-.63-1.406-1.406V2.406C1 1.63 1.63 1 2.406 1h9.374c.777 0 1.406.63 1.406 1.406v4.219a.469.469 0 1 1-.937 0V2.406a.47.47 0 0 0-.469-.469H2.406Z'
      fill={props.color ?? '#6F6F6F'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.461 8.078a1.79 1.79 0 0 0-2.522.01L8.17 12.855a.469.469 0 0 0-.128.24l-.469 2.343a.469.469 0 0 0 .552.552l2.344-.469a.468.468 0 0 0 .24-.128l4.765-4.768a1.792 1.792 0 0 0-.013-2.548Zm-.654.67a.852.852 0 0 0-1.205.002l-4.668 4.669-.303 1.515 1.514-.303 4.667-4.67a.854.854 0 0 0-.003-1.21l-.002-.002Z'
      fill={props.color ?? '#6F6F6F'}
    />
    <path
      d='M6.156 3.813a.469.469 0 0 0 0 .937h3.75a.469.469 0 0 0 0-.938h-3.75ZM3.812 7.094c0-.26.21-.469.469-.469h5.624a.469.469 0 0 1 0 .938H4.281a.469.469 0 0 1-.469-.47ZM4.28 9.437a.469.469 0 0 0 0 .938h4.688a.469.469 0 0 0 0-.938H4.281Z'
      fill={props.color ?? '#6F6F6F'}
    />
  </svg>
)

export default SvgTextIcon
