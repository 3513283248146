import { useTranslation } from 'react-i18next'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../common/components/PopupMenu/PopupActionMenu'
import { Badge } from '../../../../../types/commonTypes'
import { BadgeImage } from './BadgeImage'
import styles from './BadgeView.module.css'
import { getIcon } from '../../../../../common/components/icons/utils'

type BadgeViewProps = {
  badge: Badge
  points?: number
  onClickEdit?: (badgeId: number) => void
  onClickDelete?: (badge: Badge) => void
}

export const BadgeView: React.FC<BadgeViewProps> = ({ badge, points, onClickDelete, onClickEdit }) => {
  const { t } = useTranslation()

  const showMoreMenu = badge.id != null && (onClickEdit != null || onClickDelete != null)

  return (
    <div className={styles.badgeViewContainer}>
      <BadgeImage url={badge.imageUrl} />
      <div className={styles.badgeTitleAndMoreMenu}>
        <span className={styles.badgeTitle}>{badge.name}</span>
        {showMoreMenu && (
          <PopupActionMenu
            id={`badgeMoreMenu_${badge.id ?? 0}`}
            buttonClassName={styles.badgeMoreMenuButton}
            position='inline-down'
          >
            {onClickEdit != null && (
              <PopupActionMenuItem icon='text' text={t('common.edit', 'Edit')} onClick={() => onClickEdit(badge.id!)} />
            )}
            {onClickDelete != null && (
              <PopupActionMenuItem
                icon='trash'
                text={t('common.delete', 'Delete')}
                onClick={() => onClickDelete(badge)}
              />
            )}
          </PopupActionMenu>
        )}
      </div>
      {points != null && (
        <div className={styles.pointsContainer}>
          <span>{getIcon('scoreGradient')}</span>
          <span className='uppercase'>
            {t('game_editor.badges.min_points', { defaultValue: 'min. %{points}', points })}
          </span>
        </div>
      )}
    </div>
  )
}
