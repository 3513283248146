import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../common/components/button/Button'
import { getIcon } from '../../../common/components/icons/utils'
import { useGameEditorNavigation } from '../../../hooks/useGameEditorNavigation'
import styles from '../GamesOverview.module.css'
import { useUser } from '../../../contexts/userContext'
import { SEPPO_GROUPING_BUSINESS } from '../../../api/typeConverters'

export type EmptyContentBrowseButton = {
  label: string
  route: string
}

type RefreshButton = {
  label: string
}

export type EmptyContentProps = {
  title: string
  subtitle: string
  browseButton?: EmptyContentBrowseButton
  refreshButton?: RefreshButton
}

export const EmptyContent: React.FC<EmptyContentProps> = ({ title, subtitle, browseButton, refreshButton }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { goToCreateGame } = useGameEditorNavigation()
  const { user } = useUser()

  const handleClickCreateGame = async () => {
    await goToCreateGame()
  }

  const handleClickBrowse = (route: string) => () => {
    navigate(route)
  }

  const handleClickRefresh = () => {
    window.location.reload()
  }

  return (
    <>
      <div className={styles.EmptyContent}>
        <h2 className={styles.noGamesHeading}>{title}</h2>
        <p className={styles.noGamesText}>{subtitle}</p>
      </div>
      <div className={styles.noContentButtons}>
        {!user?.isSponsoredUser && user?.activeBusiness.industry !== SEPPO_GROUPING_BUSINESS && (
          <Button onClick={handleClickCreateGame}>
            <span className='iconWrapperMedium'>{getIcon('plus')}</span>
            {t('dashboard.empty.new_game_button', 'New game')}
          </Button>
        )}
        {browseButton != null && (
          <Button variant='outline-normal' onClick={handleClickBrowse(browseButton.route)}>
            <span className='iconWrapperBig'>{getIcon('community')}</span>
            {browseButton.label}
          </Button>
        )}
        {refreshButton != null && (
          <Button variant='outline-normal' onClick={handleClickRefresh}>
            {refreshButton.label}
          </Button>
        )}
      </div>
    </>
  )
}
