import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { useCallback } from 'react'
import { LevelTasksOrderData } from '../../../../../../../api/gameTypes'
import { useGame } from '../../../../../../../contexts/GameContext'
import { Task } from '../../../../../../../types/commonTypes'
import { TaskActionFn } from '../../types'
import { SimpleTaskCard } from '../TaskCard/SimpleTaskCard'
import { SortableTask } from '../TaskCard/SortableTask'
import styles from './TasksList.module.css'
import { useSimpleTaskOrdering } from './useSimpleTaskOrdering'

type PinnedListProps = {
  tasks: Task[]
  isCompact: boolean
  noPointsGame?: boolean
  orderingEnabled?: boolean
  viewOnly: boolean
  onTaskAction: TaskActionFn
  onSetShowDragCancel: (show: boolean) => void
}

const tasksSort = (taskA: Task, taskB: Task): number => {
  if (taskA.order === taskB.order) {
    return taskA.id - taskB.id
  }
  return taskA.order == null ? 1 : taskB.order == null ? -1 : taskA.order - taskB.order
}

const getTaskDataForTasks = (tasks: Task[]): LevelTasksOrderData => {
  return {
    1: tasks.map((task) => ({ mapId: (task.mapIndex ?? 0) - 1, taskId: task.id })),
  }
}

export const PinnedList: React.FC<PinnedListProps> = ({
  tasks,
  noPointsGame,
  orderingEnabled,
  isCompact,
  viewOnly,
  onTaskAction,
  onSetShowDragCancel,
}) => {
  const { updateTasksOrder } = useGame()

  const handleTaskOrdering = useCallback(
    (tasksOrdered: Task[]) => updateTasksOrder(getTaskDataForTasks(tasksOrdered)),
    [updateTasksOrder],
  )

  const {
    orderedTasks,
    onTaskActionInternal,
    isDragging,
    draggingTask,
    handleDragStart,
    handleDragCancel,
    handleDragEnd,
  } = useSimpleTaskOrdering(tasks, tasksSort, onTaskAction, handleTaskOrdering, onSetShowDragCancel)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <SortableContext id='pinned-tasks' items={orderedTasks} disabled={viewOnly}>
        <ul className={styles.tasksList}>
          {orderedTasks.map((task, index) => {
            return (
              <SortableTask
                key={`taskCard_${task.id}`}
                task={task}
                compact={isCompact}
                onAction={onTaskActionInternal}
                noPointsGame={noPointsGame}
                index={index}
                maxIndex={orderedTasks.length - 1}
                showOrder={orderingEnabled}
                viewOnly={viewOnly}
              />
            )
          })}
        </ul>
      </SortableContext>
      <DragOverlay>
        {isDragging && draggingTask.current ? (
          <SimpleTaskCard
            task={draggingTask.current}
            compact={isCompact}
            noPointsGame={noPointsGame}
            showOrder={orderingEnabled}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}
