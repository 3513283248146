import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { AddEntityFieldButton } from '../../../../../../../common/components/Form/AddEntityFieldButton/AddEntityFieldButton'
import { CheckboxSubtask, MatchPairsSubtask, MultipleChoiceSubtask } from '../../../../../../../types/commonTypes'
import { AllAnswersSamePoints } from '../components/AllAnswersSamePoints'
import styles from './AnswerOptionsList.module.css'

type SubtasksWithAnswers = MultipleChoiceSubtask | CheckboxSubtask | MatchPairsSubtask

type AnswerOptionsListProps<T extends SubtasksWithAnswers> = {
  name: string
  task: T
  noPoints?: boolean
  viewOnly: boolean
  mapFieldToAnswerOption: (
    name: string,
    index: number,
    fields: FieldArrayRenderProps<any, any>['fields'],
    task: T,
    viewOnly: boolean,
    noPoints?: boolean,
  ) => JSX.Element
}

export function AnswerOptionsList<T extends SubtasksWithAnswers>({
  name,
  task,
  noPoints,
  viewOnly,
  mapFieldToAnswerOption,
}: AnswerOptionsListProps<T>) {
  const { t } = useTranslation()

  return (
    <>
      <h5>{t('game_editor.tasks.answer_options.title', 'Answer options')}</h5>
      {!noPoints && (
        <AllAnswersSamePoints viewOnly={viewOnly} name={name} isChecked={task.data.allAnswersHaveSamePoints} />
      )}
      <FieldArray name={`${name}.data.answers`}>
        {({ fields }) => (
          <div className={styles.answersContainer}>
            {fields.map((name, index) => mapFieldToAnswerOption(name, index, fields, task, viewOnly, noPoints))}
            {!viewOnly && (
              <AddEntityFieldButton onClick={() => fields.push({})}>
                {t('game_editor.tasks.answer_options.add_answer_button', 'Add answer option')}
              </AddEntityFieldButton>
            )}
          </div>
        )}
      </FieldArray>
    </>
  )
}
