import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDoubleBarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M10.118 6a4.118 4.118 0 1 0 0 8.236h11.764a4.118 4.118 0 0 0 0-8.236H10.118ZM10 18a4 4 0 0 0 0 8h12a4 4 0 0 0 0-8H10Z'
      clipRule='evenodd'
    />
    <path
      fill='url(#double_bar_icon_svg__a)'
      fillOpacity={0.25}
      fillRule='evenodd'
      d='M10.118 6a4.118 4.118 0 1 0 0 8.236h11.764a4.118 4.118 0 0 0 0-8.236H10.118ZM10 18a4 4 0 0 0 0 8h12a4 4 0 0 0 0-8H10Z'
      clipRule='evenodd'
    />
    <defs>
      <linearGradient id='double_bar_icon_svg__a' x1={16} x2={16} y1={6} y2={26} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgDoubleBarIcon
