import { useTranslation } from 'react-i18next'
import styles from './LoginMethod.module.css'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { useLayoutEffect } from 'react'
import { useGoogleSignIn } from '../../../util/googleSignIn'
import { useFacebookSignIn } from '../../../util/facebookSignIn'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import classNames from 'classnames'
import { routes } from '../../../routes'

// TODO: switch between trial and normal login here instead of creating different form steps component?
export const LoginMethod: React.FC = () => {
  const { t } = useTranslation()
  const { bindToElement } = useGoogleSignIn()
  const { renderFacebookButton } = useFacebookSignIn()
  const { fadeTransition } = useFadeAnimation()

  useLayoutEffect(() => {
    const GSIEelement = document.getElementById('google-sign-in-root')
    if (GSIEelement != null) {
      bindToElement(GSIEelement)
    }
  }, [bindToElement])

  return (
    <div className={classNames(styles.mainContentWrapper, fadeTransition)}>
      <h1 className={styles.title}>{t('login.instructor.right.normal.title', "Let's start our engines")}</h1>
      <div className={styles.paragraphContainer}>
        <p>
          {t(
            'login.instructor.right.normal.body',
            'Start by choosing your preferred way of securely authenticating. This will save your work and allow you to share your games to your players.',
          )}
        </p>
      </div>
      <div className={styles.loginOptionsRow}>
        <div id='google-sign-in-root' />
        <div className={styles.marginForButtons}>{renderFacebookButton()}</div>
      </div>
      <div className={styles.separatorWrapper}>
        <span className={styles.separator} />
        <p className='detailBold'>{t('login.instructor.right.normal.or', 'Or')}</p>
        <span className={styles.separator} />
      </div>
      <InputFormField
        label='Email'
        placeholder={t('login.instructor.right.normal.email_placeholder', 'Enter your e-mail address')}
        srOnlyLabel
        name='email'
        type='email'
        big
      />
      <br />
      <InputFormField
        label='Password'
        placeholder={t('login.instructor.right.normal.password_placeholder', 'Code from e-mail or your password')}
        srOnlyLabel
        name='password'
        type='password'
        big
      />
      <p className={styles.resetPasswordText}>
        {t('login.instructor.right.normal.lost_password_instructions', "If you can't sign in,")}{' '}
        <a href={routes.passwordChange}>
          {t('login.instructor.right.normal.lost_password_reset_link', 'reset your password here')}
        </a>
      </p>
    </div>
  )
}
