import * as React from 'react'
import { SVGProps } from 'react'

const SvgBellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 27' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.112 4.837a3.333 3.333 0 1 0-6.223 0 9.337 9.337 0 0 0-6.222 8.802v4L0 22.973h8.733a3.333 3.333 0 1 0 6.533 0H24l-2.667-5.334v-4a9.337 9.337 0 0 0-6.221-8.802Zm-1.57-.404a1.733 1.733 0 1 0-3.083 0 9.398 9.398 0 0 1 3.082 0Zm-3.143 18.54a1.733 1.733 0 1 0 3.201 0h-3.2Zm9.334-4.96 1.68 3.36H2.587l1.68-3.36v-4.374a7.733 7.733 0 0 1 15.466 0v4.374Z'
      fill={props.color ?? '#CC3E76'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.112 4.837a3.333 3.333 0 1 0-6.223 0 9.337 9.337 0 0 0-6.222 8.802v4L0 22.973h8.733a3.333 3.333 0 1 0 6.533 0H24l-2.667-5.334v-4a9.337 9.337 0 0 0-6.221-8.802Zm-1.57-.404a1.733 1.733 0 1 0-3.083 0 9.398 9.398 0 0 1 3.082 0Zm-3.143 18.54a1.733 1.733 0 1 0 3.201 0h-3.2Zm9.334-4.96 1.68 3.36H2.587l1.68-3.36v-4.374a7.733 7.733 0 0 1 15.466 0v4.374Z'
      fill={props.color ?? 'url(#bell_icon_svg__a)'}
      fillOpacity={props.color ? 1 : 0.25}
    />
    <defs>
      <linearGradient id='bell_icon_svg__a' x1={12} y1={0.306} x2={12} y2={26.973} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgBellIcon
