import { useTranslation } from 'react-i18next'
import { ModalPortal } from '../../../../common/components/Portal/Portal'
import { CloseButton } from '../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../common/components/icons/utils'
import { useFadeAnimation } from '../../../../hooks/FadeAnimation/useFadeAnimation'
import { useDisableBodyScroll } from '../../../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../../../hooks/useTrapFocus'
import { MemberRole } from '../../../../types/commonTypes'
import styles from './AddMembersModal.module.css'
import { AddMembers } from './AddMembers'
import { AddMembersPreview } from './AddMembersPreview'
import { useState } from 'react'
import { ThreeDots } from '../../../../common/components/loaders/ThreeDots/ThreeDots'
import { LoaderWithOverlay } from '../../../../composites/GamesOverview/components/LoaderWithOverlay'

export interface Member {
  name: string
  email: string
  role: MemberRole
  orgId: string
}

type AddMembersModalProps = {
  organizationName: string
  onSubmit: (values: AddMembersFormValues) => Promise<boolean>
  onClose: () => void
  memberCount: number
  maxMembers: number
  limitMembers: boolean
}

export type AddMembersFormValues = {
  members: Member[]
  message: string
}

export const AddMembersModal: React.FC<AddMembersModalProps> = ({
  onSubmit,
  organizationName,
  onClose,
  memberCount,
  maxMembers,
  limitMembers,
}) => {
  const { t } = useTranslation()
  const trapFocusRef = useTrapFocus<HTMLDivElement>()
  useDisableBodyScroll()
  const [values, setValues] = useState<AddMembersFormValues | null>(null)
  const [showPreview, setShowPreview] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { fadeTransition, closeWithDelay } = useFadeAnimation()

  const onSubmitInternal = async (message: string) => {
    setSubmitting(true)
    if (values) {
      const newValues: AddMembersFormValues = {
        members: values?.members,
        message: message,
      }
      const isSuccess = await onSubmit(newValues)
      if (isSuccess) {
        onCloseInternal()
      }
    }
    setSubmitting(false)
  }

  const onCloseInternal = () => {
    closeWithDelay(350, onClose)
  }

  const handleSetValues = (member: Member) => {
    setValues((prevValues) =>
      prevValues
        ? { message: prevValues.message, members: [...prevValues.members, member] }
        : { message: '', members: [member] },
    )
  }

  const onPreview = () => {
    setShowPreview(true)
  }

  const onBack = () => {
    setShowPreview(false)
  }

  return (
    <ModalPortal>
      <div className={fadeTransition}>
        <div className={styles.backdrop}>
          <div className={styles.container} ref={trapFocusRef}>
            <div className={styles.content}>
              {submitting && (
                <LoaderWithOverlay>
                  <ThreeDots />
                </LoaderWithOverlay>
              )}
              <div className={styles.header}>
                <div className={styles.headerTitle}>
                  <span>{getIcon('settingsGradient')}</span>
                  <span className='medium'>
                    {t('settings_organization.members.add_members.title', {
                      organization_name: organizationName,
                      defaultValue: "Add members to '%{organization_name}' organization",
                    })}
                  </span>
                </div>
                <CloseButton autoFocus onClick={onCloseInternal} />
              </div>
              {!showPreview && (
                <AddMembers
                  onPreview={onPreview}
                  onClose={onCloseInternal}
                  values={values}
                  setAllValues={setValues}
                  setValue={handleSetValues}
                  memberCount={memberCount + (values ? values.members.length : 0)}
                  maxMembers={maxMembers}
                  limitMembers={limitMembers}
                />
              )}
              {showPreview && (
                <AddMembersPreview onSubmit={onSubmitInternal} onClose={onCloseInternal} onBack={onBack} />
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
