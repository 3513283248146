import { SortableContext } from '@dnd-kit/sortable'
import classNames from 'classnames'
import { DeepPartial, Game, Task } from '../../../../../../../types/commonTypes'
import { TaskActionFn } from '../../types'
import { SortableItemPlaceholder } from '../TaskCard/SortableItemPlaceholder'
import { SortableTask } from '../TaskCard/SortableTask'
import { LevelHeader } from './LevelHeader'
import styles from './LevelTasks.module.css'
import { NoTasksPlaceholder } from './NoTasksPlaceholder'

type LevelZeroTasksProps = {
  tasks: Task[]
  game: Game
  compact: boolean
  maxLevelIndex: number
  viewOnly: boolean
  onTaskAction: TaskActionFn
  onAddTask: (initialTask?: DeepPartial<Task>) => void
}

export const LevelZeroTasks: React.FC<LevelZeroTasksProps> = ({
  tasks,
  game,
  compact,
  maxLevelIndex,
  viewOnly,
  onTaskAction,
  onAddTask,
}) => {
  return (
    <div className={styles.levelTasks}>
      <LevelHeader index={0} onAddTask={onAddTask} viewOnly={viewOnly} />
      {tasks.length === 0 ? (
        <div className={styles.levelContentContainer}>
          <NoTasksPlaceholder />
          <SortableItemPlaceholder boardIndex={0} levelIndex={0} />
        </div>
      ) : (
        <SortableContext items={tasks} id='level-0' disabled={viewOnly}>
          <ul className={classNames(styles.levelTasksList, styles.listVerticalPadding)}>
            {tasks.map((task, index) => {
              return (
                <SortableTask
                  task={task}
                  onAction={onTaskAction}
                  compact={compact}
                  key={`taskCard_${task.id}`}
                  noPointsGame={game.advancedSettings.noPointsGame}
                  showLevel
                  index={index}
                  maxIndex={tasks.length - 1}
                  maxLevelIndex={maxLevelIndex}
                  viewOnly={viewOnly}
                />
              )
            })}
          </ul>
        </SortableContext>
      )}
    </div>
  )
}
