import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { Button } from '../../../common/components/button/Button'
import { getIcon } from '../../../common/components/icons/utils'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import styles from './LoginMethod.module.css'

type TrialPasswordProps = {
  email: string | undefined
  disabled: boolean
  onClickBackToEmail: () => void
  onClickResendEmail: () => void
  shouldMirror?: boolean
}

export const TrialPassword: React.FC<TrialPasswordProps> = ({
  email,
  disabled,
  onClickBackToEmail,
  onClickResendEmail,
  shouldMirror = false,
}) => {
  const { t } = useTranslation()
  const { fadeTransition } = useFadeAnimation()
  const { change } = useForm()

  const onClickBackToEmailInternal = () => {
    // deletes the key so we can go back to this step after resend
    change('password', undefined)
    onClickBackToEmail()
  }

  return (
    <div className={styles.mainContentWrapper}>
      <Button variant='borderless-tiny' onClick={onClickBackToEmailInternal} className={styles.trialPasswordBackButton}>
        <span style={{ rotate: shouldMirror ? 'unset' : '180deg' }}>{getIcon('arrowRight')}</span>
        {t('login.instructor.right.normal.back_button', 'Back')}
      </Button>
      <h1 className={styles.title}>{t('login.instructor.right.trial.title2', 'Enter code to continue')}</h1>
      <div className={fadeTransition}>
        <div className={styles.paragraphContainer}>
          <p>
            {t('login.instructor.right.trial.body2', 'Enter the password you received at')}{' '}
            <span className={styles.email}>{email != null && email}</span>.
          </p>
          <p>
            {t(
              'login.instructor.right.trial.body3',
              "Haven't received an email? Try resending by clicking the button below.",
            )}
          </p>
          <Button variant='outline-tiny' onClick={onClickResendEmail} disabled={disabled}>
            {t('login.instructor.right.trial.resend_button', 'Resend email')}
          </Button>
        </div>
        <InputFormField
          label='Password'
          placeholder={t('login.instructor.right.trial.password_placeholder', 'Enter received password')}
          srOnlyLabel
          name='password'
          type='password'
          big
        />
      </div>
    </div>
  )
}
