import { t } from 'i18next'
import { isEmpty } from '../../../../util/functional'
import { ShareGameForm } from '../../types'
import { FormErrorType } from '../../../../types/commonTypes'

export const validateShareGameForm = (
  initialValues: Partial<ShareGameForm>,
  values: Partial<ShareGameForm>,
): FormErrorType<ShareGameForm> => {
  const errors: { [key: string]: string } = {}
  const optionalFields = ['keywords', 'userPhone', 'togglerError', 'shareToPrivate', 'shareToPublic']

  Object.keys(initialValues).forEach((key) => {
    if (
      (isEmpty(values[key as keyof ShareGameForm]) || !values[key as keyof ShareGameForm]?.toString().trim().length) &&
      !optionalFields.includes(key)
    ) {
      errors[key] = t('validation_errors.required', 'Required')
    }
  })
  return errors
}

const SELECT_FIELD_X_CLASSNAME = 'indicatorContainer'
//Needed to not close view on select box X click (X that removes all selected items)
//Maybe possible but highly unlikely that any element that actually should close the view having this classname should get clicked while view open
export const clickedElementShouldNotClose = (element: HTMLElement): boolean => {
  let me: HTMLElement | null = element
  const meAndParents = []
  while (me) {
    meAndParents.push(me)
    me = me.parentElement
  }
  return !!meAndParents.find(
    (el) => typeof el.className === 'string' && el.className?.includes(SELECT_FIELD_X_CLASSNAME),
  )
}
