import * as React from 'react'
import { SVGProps } from 'react'

const SvgStarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.692.826 8.646 7.058l-6.888.984C.54 8.229.071 9.729.962 10.619l4.92 4.826L4.71 22.24c-.187 1.218 1.125 2.155 2.202 1.593l6.139-3.233 6.091 3.233c1.078.562 2.39-.375 2.203-1.593l-1.172-6.795 4.92-4.826c.89-.89.422-2.39-.797-2.577l-6.84-.984L14.362.826c-.516-1.078-2.109-1.125-2.671 0Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)

export default SvgStarIcon
