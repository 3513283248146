import { PopupActionMenu, PopupActionMenuItem } from '../../../../common/components/PopupMenu/PopupActionMenu'
import { useTranslation } from 'react-i18next'
import { SelectFormField } from '../../../../common/components/Form/SelectFormField/SelectFormField'
import { SelectElement, SelectVariant } from '../../../../common/components/Select/Select'
import { Form, FormSpy } from 'react-final-form'
import { useNotification } from '../../../../contexts/NotificationContext'
import { useConfirmation } from '../../../../contexts/ConfirmationContext'
import { MODALS_ROOT_ID } from '../../../../common/constants'
import styles from './DoorPopup.module.css'
import { Door } from '../../types'
import { useGame } from '../../../../contexts/GameContext'

const BOARD_SELECT_ICON: SelectElement = { type: 'icon', icon: { name: 'photo' } }

export const DoorPopup: React.FC<{ door: Door }> = ({ door }) => {
  const { t } = useTranslation()
  const { gameData, updateExplorationDoor, deleteExplorationDoor } = useGame()
  const { requestConfirmation } = useConfirmation()
  const { notifyError } = useNotification()

  const boardOptions = gameData?.gameBoardSettings.gameBoards
    .filter((board) => board.mapIndex !== door.boardIndex)
    .map((board) => ({
      label: t('game_editor.game_boards.board', 'Board') + ' ' + ((board.mapIndex ?? 0) + 1),
      value: board.mapIndex ?? 0,
    }))

  const handleChange = (form: any) => {
    const { leadsToBoardIndex } = form.values
    if (leadsToBoardIndex !== door.leadsToBoardIndex) {
      const result = updateExplorationDoor(
        gameData?.gameBoardSettings.gameBoards.find((b) => b.mapIndex === door.boardIndex),
        { ...door, leadsToBoardIndex },
      )
      if (!result) {
        notifyError({
          title: t('game_editor.exploration_mode.modify_door_failed.title', 'Failed to save the changes'),
          content: t(
            'game_editor.exploration_mode.modify_door_failed.content',
            'You can try again or contact us for support.',
          ),
        })
      }
    }
  }

  const handleDeleteDoor = () => {
    const el = document.getElementById(MODALS_ROOT_ID)
    //Temporarily force notification in front of tooltip
    if (el) {
      el.style['position'] = 'absolute'
      el.style['zIndex'] = '9999'
    }
    requestConfirmation({
      title: t('game_editor.exploration_mode.delete_door.title', 'Are you sure you want to delete?'),
      text: t('game_editor.exploration_mode.delete_door.text', 'This action is irreversible'),
    }).then((response) => {
      if (el) {
        el.style['position'] = 'initial'
        el.style['zIndex'] = 'initial'
      }
      if (response) {
        const result = deleteExplorationDoor(door)
        if (!result) {
          notifyError({
            title: t('game_editor.exploration_mode.delete_door_failed.title', 'Failed to delete door'),
            content: t(
              'game_editor.exploration_mode.delete_door_failed.content',
              'You can try again or contact us for support.',
            ),
          })
        }
      }
    })
  }

  const initialValues = { leadsToBoardIndex: door.leadsToBoardIndex }

  return (
    <div className={styles.doorPopup}>
      <div className={styles.doorPopupFirstRow}>
        <span>{t('game_editor.exploration_mode.door_popup.door_leads_to', 'This door leads to')}</span>
        <PopupActionMenu id={'wrwrhwrh'} buttonClassName={styles.doorMenuButton} menuClassName={styles.doorMenu}>
          <PopupActionMenuItem
            text={t('game_editor.exploration_mode.door_popup.delete_door', 'Delete door')}
            onClick={handleDeleteDoor}
          />
        </PopupActionMenu>
      </div>
      {boardOptions && (
        <Form initialValues={initialValues} onSubmit={() => {}}>
          {() => (
            <>
              <SelectFormField<number>
                name='leadsToBoardIndex'
                label={t('game_editor.tasks.task_information.board_label', 'Board')}
                options={boardOptions}
                variant={SelectVariant.XLarge}
                prefixElement={BOARD_SELECT_ICON}
                onChange={handleChange}
                srOnlyLabel
              />
              <FormSpy onChange={handleChange} />
            </>
          )}
        </Form>
      )}
      <div className={styles.doorPopupBottomText}>
        {t(
          'game_editor.exploration_mode.door_popup.door_info',
          'It will appear when players unlock tasks to complete on the board',
        )}
      </div>
    </div>
  )
}
