import { useEffect } from 'react'
import { Field, useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../../../common/components/Form/InputFormField/InputFormField'
import { PointsInput } from '../../../../Points/PointsInput'
import { AnswerOption, AnswerOptionRowWithMenu } from '../components/AnswerOption'
import styles from './MissingWordSubtaskFields.module.css'

export type MissingWordAnswerOptionProps = {
  name: string
  index: number
  onDelete: () => void
  hasFixedPoints?: boolean
  fixedPoints?: number
  noPoints?: boolean
  viewOnly: boolean
}

export const MissingWordAnswerOption: React.FC<MissingWordAnswerOptionProps> = ({
  name,
  index,
  onDelete,
  hasFixedPoints,
  fixedPoints,
  noPoints,
  viewOnly,
}) => {
  const { t } = useTranslation()
  const { input } = useField(`${name}.points`)

  useEffect(() => {
    if (hasFixedPoints && input.value !== fixedPoints) {
      input.onChange(fixedPoints)
    }
  }, [hasFixedPoints, fixedPoints, input])

  return (
    <AnswerOption>
      <AnswerOptionRowWithMenu onDelete={viewOnly ? onDelete : undefined} actionMenuId={`${name}_actionMenu`}>
        <Field component='input' type='hidden' name={`${name}.id`} />
        <InputFormField
          label={t('game_editor.tasks.missing_word.word_with_index_title', {
            word_index: index + 1,
            defaultValue: 'Word %{word_index}',
          })}
          name={`${name}.word`}
          inline
          fieldContainerClassName={styles.inputContainer}
          inputContainerClassName={styles.inputContainer}
          disabled={viewOnly}
        />
        {!noPoints && <PointsInput name={`${name}.points`} disabled={viewOnly || hasFixedPoints} />}
      </AnswerOptionRowWithMenu>
    </AnswerOption>
  )
}
