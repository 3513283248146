import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../../../common/components/PopupMenu/PopupActionMenu'
import { ScoreGradientIcon, TaskFlashIcon } from '../../../../../../../common/components/icons'
import { getTaskCardFocusableElementId } from '../../../../../helpers'
import { getTitleForTask } from '../../../../Tasks/TaskModal/helpers'
import { TaskSummary } from '../../types'
import styles from './FlashTaskCard.module.css'
import { TaskAnswerCount } from './TaskAnswerCount'

type FlashTaskCardProps = {
  taskSummary: TaskSummary
  isSelected: boolean
  onSelect: (taskId: number) => void
  onUpdateOpenStatus: (taskId: number, isOpen: boolean) => void
  compact: boolean
  noPointsGame?: boolean
}

export const FlashTaskCard: React.FC<FlashTaskCardProps> = ({
  taskSummary,
  isSelected,
  onSelect,
  onUpdateOpenStatus,
  compact,
  noPointsGame,
}) => {
  const { gradedAnswers, needsReview, task, totalAnswers } = taskSummary
  const { t } = useTranslation()

  const onUpdateOpenStatusInternal = () => onUpdateOpenStatus(task.id, !task.isOpen)

  const handleFlashButtonClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onUpdateOpenStatusInternal()
  }

  const onSelectInternal = () => onSelect(task.id)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter' && (e.target as any)?.id === getTaskCardFocusableElementId(task.id)) {
      onSelect(task.id)
    }
  }

  return (
    <li
      className={classNames(
        styles.flashTaskContainer,
        isSelected && styles.flashTaskContainer_selected,
        task.isOpen && styles.flashTaskContainer_open,
        needsReview && styles.flashTaskContainer_review,
        compact && styles.flashTaskContainer_compact,
      )}
      tabIndex={0}
      id={getTaskCardFocusableElementId(task.id)}
      onClick={onSelectInternal}
      onKeyDown={handleKeyDown}
    >
      <button className={styles.flashButton} onClick={handleFlashButtonClick}>
        <TaskFlashIcon />
      </button>
      <div className={styles.flashContent}>
        <div className={styles.flashInfo}>
          <h5 className={styles.flashTaskTitle}>{task.name}</h5>
          <div className={styles.flashTaskDetails}>
            <div className={styles.flashTaskPill}>
              {task.isOpen
                ? t('common.active', 'Active')
                : needsReview
                ? t('game_editor.sidebar.tasks.status_needs_review', 'Review')
                : getTitleForTask(task, t)}
            </div>
            <TaskAnswerCount total={totalAnswers} graded={gradedAnswers} className={styles.answerCount} />
            {!noPointsGame && (
              <div className={styles.flashTaskPoints}>
                <ScoreGradientIcon />
                {task.pointsSum}
              </div>
            )}
          </div>
        </div>
        <PopupActionMenu id={`${task.id}_actionsMenu`} buttonClassName={styles.moreMenuButton} position='inline-down'>
          <PopupActionMenuItem
            icon='taskFlash'
            text={task.isOpen ? t('common.stop', 'Stop') : t('common.activate', 'Activate')}
            onClick={onUpdateOpenStatusInternal}
          />
        </PopupActionMenu>
      </div>
    </li>
  )
}
