import classNames from 'classnames'
import arrayMutators from 'final-form-arrays'
import i18next from 'i18next'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '../../../../common/components/button/Button'
import { ThreeDots } from '../../../../common/components/loaders/ThreeDots/ThreeDots'
import { DeepPartial, FormErrorType, UserDataField } from '../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../util/string'
import styles from './AddMembersModal.module.css'
import { useCallback, useEffect, useState } from 'react'
import { TextAreaFormField } from '../../../../common/components/Form/TextAreaFormField/TextAreaFormField'
import { getIcon } from '../../../../common/components/icons/utils'
import { useUser } from '../../../../contexts/userContext'
import { useNotification } from '../../../../contexts/NotificationContext'

type AddMembersPreviewProps = {
  onSubmit: (message: string) => void
  onClose: () => void
  onBack: () => void
}

export type AddMembersPreviewFormValues = {
  message1: string
  message2: string
}

const validate = (data: DeepPartial<AddMembersPreviewFormValues>): FormErrorType<AddMembersPreviewFormValues> => {
  return {
    message1: safeIsNullOrEmpty(data.message1) ? i18next.t('validation_errors.required', 'Required') : undefined,
    message2: safeIsNullOrEmpty(data.message2) ? i18next.t('validation_errors.required', 'Required') : undefined,
  }
}

export const AddMembersPreview: React.FC<AddMembersPreviewProps> = ({ onClose, onSubmit, onBack }) => {
  const { t } = useTranslation()
  const { user, updateUser } = useUser()
  const { notifySuccess } = useNotification()
  const originalMessage1 = t(
    'businesses.members.invite_email.default.message_beginning',
    'Welcome to use the Seppo gamification platform. \n\nTo start using Seppo, go to https://play.seppo.io -> Create games.\n\n',
  )
  const originalMessage2 = t(
    'businesses.members.invite_email.default.message_end',
    'We recommend using Chrome, Safari or Firefox. \n\nOnce you have logged in you can\n- Get started with creating Seppo games\n- Find support material and contact our customer support from Help\n- Reset your password from settings\n\nEnjoy the magic of games!\n\nBest,\n\nSeppo team',
  )
  const userPasswordString = '\nUsername: [email]\nPassword: [password]\n\n'
  const [messageChanged, setMessageChanged] = useState(false)
  const [initialValues, setInitialValues] = useState<AddMembersPreviewFormValues>({ message1: '', message2: '' })

  const onSubmitInternal = useCallback(
    (values: AddMembersPreviewFormValues) => {
      onSubmit(values.message1 + userPasswordString + values.message2)
    },
    [onSubmit],
  )

  useEffect(() => {
    const cutString = '--CUT--'
    if (
      user &&
      user.custom_invite &&
      user.custom_invite.indexOf(cutString) &&
      user.custom_invite.indexOf(cutString) !== user.custom_invite.lastIndexOf(cutString)
    ) {
      setInitialValues({
        message1: user.custom_invite.substring(0, user.custom_invite.indexOf(cutString)),
        message2: user.custom_invite.substring(user.custom_invite.lastIndexOf(cutString) + cutString.length),
      })
    } else {
      setInitialValues({
        message1: originalMessage1,
        message2: originalMessage2,
      })
    }
  }, [originalMessage1, originalMessage2, user])

  const onInternalSaveMessage = (message1: string, message2: string) => {
    setMessageChanged(false)
    setInitialValues({ message1, message2 })
    const cutString = '--CUT--'
    const newValue = {
      key: 'custom_invite' as UserDataField,
      value: message1 + cutString + userPasswordString + cutString + message2,
    }
    updateUser(newValue, false, false, true)
    notifySuccess({
      title: t('user.update_response.success.title', 'Custom invitation saved'),
      content: t('user.update_response.success.content', 'Data updated succesfully'),
    })
  }

  return (
    <>
      <div className={classNames(styles.description, 'medium')}>
        <Trans i18nKey='settings_organization.members.add_members_preview.description' components={{ 1: <br /> }}>
          {
            'Adding members to your organisation allows them to create and share content. <br/>They will receive an invitation email after you have added them.'
          }
        </Trans>
      </div>
      <div className={classNames(styles.iconRow)} onClick={onBack}>
        <span className={classNames(styles.icon)}>{getIcon('arrowLeft')}</span>
        <span>{t('common.back')}</span>
      </div>
      <Form<AddMembersPreviewFormValues>
        onSubmit={onSubmitInternal}
        validate={validate}
        mutators={{
          ...arrayMutators,
          setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
            changeValue(state, fieldName, () => fieldVal)
          },
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, values, form, submitting }) => (
          <>
            <div className={styles.previewHeader}>
              <div className={styles.previewHeaderLeft}>
                <p className={styles.previewHeaderTitle}>
                  {t('settings_organization.members.add_members_preview.header.title', 'Welcome message')}
                </p>
                <p className={styles.previewHeaderDescription}>
                  {t(
                    'settings_organization.members.add_members_preview.header.description',
                    'Write a custom message to be send with the invitation. You can also use Seppo´s default message. ',
                  )}
                </p>
              </div>
              <div className={styles.previewHeaderRight}>
                <Button
                  variant='normal'
                  onClick={(e) => {
                    onInternalSaveMessage(values.message1, values.message2)
                  }}
                  disabled={!messageChanged}
                >
                  {t('settings_organization.members.add_members.button_text.save_as_default', 'Save as default')}
                </Button>
                <Button
                  variant='outline-normal'
                  onClick={() => {
                    form.mutators.setFormAttribute('message1', originalMessage1)
                    form.mutators.setFormAttribute('message2', originalMessage2)
                    notifySuccess({
                      title: t(
                        'settings_organization.members.add_members.reset_message_notification.title',
                        'Invite message reset!',
                      ),
                      content: t(
                        'settings_organization.members.add_members.reset_message_notification.content',
                        'Invite message reset to the last saved version.',
                      ),
                    })
                  }}
                  name='reset'
                >
                  {t('settings_organization.members.add_members.button_text.reset', 'Reset')}
                </Button>
              </div>
            </div>
            <form onSubmit={handleSubmit} onChange={() => setMessageChanged(true)}>
              {submitting && (
                <>
                  <div className={styles.loaderBackgroundOverlay} />
                  <div className={styles.loaderContainer}>
                    <ThreeDots />
                  </div>
                </>
              )}
              <div className={styles.mainFormContainerpreview}>
                <TextAreaFormField
                  name={'message1'}
                  type='text'
                  label={t('settings_organization.members.add_members.labels.message', 'Message')}
                  srOnlyLabel={true}
                  className={styles.noVisibleOutline1}
                />
                <div className={styles.userInfoContainer}>
                  Username: %&#123;email&#125;
                  <br />
                  Password: %&#123;password&#125;
                </div>
                <TextAreaFormField
                  name={'message2'}
                  type='text'
                  label={t('settings_organization.members.add_members.labels.message', 'Message')}
                  srOnlyLabel={true}
                  className={styles.noVisibleOutline2}
                />
              </div>
              <div className={styles.footer}>
                <Button variant='outline-normal' onClick={onClose} disabled={submitting}>
                  {t('settings_organization.members.add_members.button_text.cancel', 'Cancel')}
                </Button>
                <Button type='submit' disabled={submitting}>
                  {getIcon('email')}
                  {t('settings_organization.members.add_members.button_text.send_invites', 'Send invites')}
                </Button>
              </div>
            </form>
          </>
        )}
      </Form>
    </>
  )
}
