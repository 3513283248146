import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Select, SelectOption, SelectVariant } from '../../../../../../../../../common/components/Select/Select'
import { DEFAULT_EVAL_SORT_BY_PLAYERS, EvaluationSortBy, PlayerSummary } from '../../../../types'
import styles from './PlayerEvaluationInfoAndSort.module.css'

type PlayerEvaluationInfoAndSortProps = {
  playerSummary: PlayerSummary
  tasksCount: number
  sortBy: EvaluationSortBy
  onSetSortBy: (sortBy: EvaluationSortBy) => void
}

const getSortByOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('game_editor.sidebar.evaluation_list_panel.evaluation_sort_evaluation', 'Evaluation'),
    value: EvaluationSortBy.Evaluation_asc,
  },
  {
    label: t('game_editor.sidebar.evaluation_list_panel.evaluation_sort_answer_oldest_top', 'Answers: Oldest on top'),
    value: EvaluationSortBy.AnswerTimestamp_asc,
  },
  {
    label: t('game_editor.sidebar.evaluation_list_panel.evaluation_sort_answer_newest_top', 'Answers: Newest on top'),
    value: EvaluationSortBy.AnswerTimestamp_desc,
  },
  {
    label: t('game_editor.sidebar.evaluation_list_panel.evaluation_sort_task_name_a_z', 'Task name: A - Z'),
    value: EvaluationSortBy.TaskName_asc,
  },
  {
    label: t('game_editor.sidebar.evaluation_list_panel.evaluation_sort_task_name_z_a', 'Task name: Z - A'),
    value: EvaluationSortBy.TaskName_desc,
  },
]

export const PlayerEvaluationInfoAndSort: React.FC<PlayerEvaluationInfoAndSortProps> = ({
  playerSummary,
  tasksCount,
  sortBy,
  onSetSortBy,
}) => {
  const { t } = useTranslation()

  const options = getSortByOptions(t)

  const selectedOption = options.find((o) => o.value === sortBy)

  const onChange = (option: SelectOption | null) => {
    if (option == null) {
      onSetSortBy(DEFAULT_EVAL_SORT_BY_PLAYERS)
    } else {
      onSetSortBy(option.value as EvaluationSortBy)
    }
  }

  return (
    <div className={styles.evaluationInfoAndSort}>
      <div className={styles.evaluationInfo}>
        <span className='bold medium'>
          {t('game_editor.sidebar.evaluation_list_panel.player_answers_count_answered_and_total', {
            defaultValue: 'Answered: %{answered}/%{total}',
            answered: playerSummary.tasksAnswered,
            total: tasksCount,
          })}
        </span>
        <span className='tiny'>
          {t('game_editor.sidebar.evaluation_list_panel.player_answers_count_ungraded', {
            defaultValue: 'Ungraded: %{ungraded}',
            ungraded: playerSummary.tasksUngraded,
          })}
        </span>
      </div>
      <div className={styles.evaluationSort}>
        <span className={'tiny grey-600'}>{t('common.sort_by', 'Sort by')}</span>
        <Select
          className={styles.evaluationSortInput}
          variant={SelectVariant.Small}
          options={options}
          value={selectedOption}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
