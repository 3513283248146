import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { ExerciseType, LibrarySource } from '../../../../../../api/gameTypes'
import { InputFormField } from '../../../../../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../../../../../common/components/Form/SelectFormField/SelectFormField'
import { SelectElement, SelectOption, SelectVariant } from '../../../../../../common/components/Select/Select'
import { useDebounce } from '../../../../../../hooks/useDebounce'
import { areObjectsEqual } from '../../../../../../util/functional'
import { getAgeOptions, getLanguageOptions, getSubjectOptions } from '../../../../../search/components/constants'
import { getTitleForTaskType } from '../helpers'
import styles from './TaskLibrarySearch.module.css'
import { TaskLibrarySearchParams } from './types'
import classNames from 'classnames'

type TaskLibrarySearchProps = {
  onSearchChange: (searchValues: TaskLibrarySearchParams) => void
  availableLibs: LibrarySource[]
}

const selectFieldIcons: { [fieldName: string]: SelectElement } = {
  subject: { type: 'icon', icon: { name: 'textFile' } },
  language: { type: 'icon', icon: { name: 'language' } },
  age: { type: 'icon', icon: { name: 'filter' } },
  taskType: { type: 'icon', icon: { name: 'exercisePin' } },
  librarySource: { type: 'icon', icon: { name: 'community' } },
}

const getTaskTypeOptions = (t: TFunction): SelectOption[] => {
  return [
    { label: getTitleForTaskType(ExerciseType.CreativeExercise, t), value: ExerciseType.CreativeExercise },
    { label: getTitleForTaskType(ExerciseType.MultichoiceExercise, t), value: ExerciseType.MultichoiceExercise },
    { label: getTitleForTaskType(ExerciseType.PollExercise, t), value: ExerciseType.PollExercise },
    { label: getTitleForTaskType(ExerciseType.MissingWordExercise, t), value: ExerciseType.MissingWordExercise },
    { label: getTitleForTaskType(ExerciseType.CombineExercise, t), value: ExerciseType.CombineExercise },
  ]
}

const getLibrarySourceOptions = (t: TFunction, availableLibs: LibrarySource[]): SelectOption[] => {
  if (availableLibs.includes(LibrarySource.ORG) && availableLibs.length > 1) {
    return [
      { label: t('', 'Organization library'), value: 'private' },
      {
        label: `${availableLibs.includes(LibrarySource.COMMUNITY) ? t('', 'Community') : ''}${
          availableLibs.length === 3 ? '/' : ''
        }${availableLibs.includes(LibrarySource.TEMPLATE) ? t('', 'Templates') : ''}`,
        value: 'public',
      },
    ]
  }
  return []
}

export const TaskLibrarySearch: React.FC<TaskLibrarySearchProps> = ({ onSearchChange, availableLibs }) => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useState<TaskLibrarySearchParams>()
  const debouncedSearchParams = useDebounce(searchParams, 1_000)

  useEffect(() => {
    if (debouncedSearchParams) {
      onSearchChange(debouncedSearchParams)
    }
  }, [onSearchChange, debouncedSearchParams])

  const showLibFilter = availableLibs.includes(LibrarySource.ORG) && availableLibs.length > 1

  return (
    <Form<TaskLibrarySearchParams> onSubmit={onSearchChange}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={styles.taskLibrarySearchContainer}>
          <span className='grey-900'>{t('game_editor.tasks.library_modal.search_title', 'Search and filter')}</span>
          <div className={styles.searchRowUnequalCols}>
            <div className={classNames(showLibFilter && styles.searchRowEqualCols)}>
              <InputFormField
                name='search'
                label={t('game_editor.tasks.library_modal.search_name_creator_keyword', 'Name, creator, keyword')}
                placeholder={t('game_editor.tasks.library_modal.search_name_creator_keyword', 'Name, creator, keyword')}
                srOnlyLabel
              />
              {showLibFilter && (
                <SelectFormField
                  name='librarySource'
                  options={getLibrarySourceOptions(t, availableLibs)}
                  label={t('game_editor.tasks.library_modal.search_library_source', 'Library')}
                  placeholder={t('game_editor.tasks.library_modal.search_library_source', 'Library')}
                  srOnlyLabel
                  prefixElement={selectFieldIcons.librarySource}
                  isClearable
                  variant={SelectVariant.XLarge}
                />
              )}
            </div>
            <SelectFormField
              name='subject'
              options={getSubjectOptions(t)}
              label={t('game_editor.tasks.library_modal.search_subject', 'Subject')}
              placeholder={t('game_editor.tasks.library_modal.search_subject', 'Subject')}
              srOnlyLabel
              prefixElement={selectFieldIcons.subject}
              isSearchable
              isClearable
              variant={SelectVariant.XLarge}
            />
          </div>
          <div className={styles.searchRowUnequalCols}>
            <div className={styles.searchRowEqualCols}>
              <SelectFormField
                name='taskType'
                options={getTaskTypeOptions(t)}
                label={t('game_editor.tasks.library_modal.search_task_type', 'Task type')}
                placeholder={t('game_editor.tasks.library_modal.search_task_type', 'Task type')}
                srOnlyLabel
                prefixElement={selectFieldIcons.taskType}
                isClearable
                variant={SelectVariant.XLarge}
              />

              <SelectFormField
                name='language'
                options={getLanguageOptions(t)}
                label={t('game_editor.tasks.library_modal.search_language', 'Language')}
                placeholder={t('game_editor.tasks.library_modal.search_language', 'Language')}
                srOnlyLabel
                prefixElement={selectFieldIcons.language}
                isSearchable
                isClearable
                variant={SelectVariant.XLarge}
              />
            </div>
            <SelectFormField
              name='age'
              options={getAgeOptions(t)}
              label={t('game_editor.tasks.library_modal.search_age', 'Age')}
              placeholder={t('game_editor.tasks.library_modal.search_age', 'Age')}
              srOnlyLabel
              prefixElement={selectFieldIcons.age}
              isClearable
              variant={SelectVariant.XLarge}
            />
          </div>
          <FormSpy
            onChange={(props) =>
              setSearchParams((prev) => (areObjectsEqual(prev ?? {}, props.values) ? prev : props.values))
            }
          />
        </form>
      )}
    </Form>
  )
}
