import { TFunction } from 'i18next'
import mapboxgl from 'mapbox-gl'
import { MapType } from '../../api/gameTypes'
import { MORE_MENU_BUTTON_ID_PREFIX } from '../../common/components/PopupMenu/PopupMenu'
import { FormErrorType } from '../../types/commonTypes'
import { requiredValidation } from '../../util/validate'
import { GameBoard, GameForm, MapOptions } from './types'

export const validateGameForm = (values: Partial<GameForm>, t: TFunction): FormErrorType<GameForm> => {
  return {
    name: requiredValidation(values.name, t),
  }
}

const PANORAMA_MAP_OPTIONS_DEFAULTS: MapOptions = { center: [0, 0], zoom: 100 }

export const extendGameDataWithAdditionalMapSettings = (data: GameForm, map: mapboxgl.Map): GameForm => {
  if (data.gameBoardSettings.gameBoardType === MapType.LIVE) {
    const mapCenter = map.getCenter()
    const zoom = map.getZoom()
    return {
      ...data,
      gameBoardSettings: {
        ...data.gameBoardSettings,
        mapOptions: {
          center: [mapCenter.lng, mapCenter.lat],
          zoom,
        },
        gameBoards: [
          {
            url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${mapCenter.lng},${mapCenter.lat},${zoom},0/600x400?logo=false&access_token=${process.env.REACT_APP_MAPBOX_DEV_ACCESS_TOKEN}`,
            fileName: '',
            name: '',
          },
        ],
      },
    }
  } else if (data.gameBoardSettings.gameBoardType === MapType.PANORAMA) {
    return {
      ...data,
      gameBoardSettings: {
        ...data.gameBoardSettings,
        gameBoards: [
          ...data.gameBoardSettings.gameBoards.map((board) => {
            return {
              ...board,
              mapOptions: board.mapOptions ?? PANORAMA_MAP_OPTIONS_DEFAULTS,
            }
          }),
        ],
      },
    }
  }
  return data
}

export const getTranslatedStaticBoardGames = (t: TFunction): GameBoard[] => {
  return [
    {
      fileName: 'city.png',
      name: t('game_editor.game_boards.static_board_game.city', 'City'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/city.png',
    },
    {
      fileName: 'City_road.jpg',
      name: t('game_editor.game_boards.static_board_game.city_road', 'City road'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/City_road.jpg',
    },
    {
      fileName: 'Travel_game.jpg',
      name: t('game_editor.game_boards.static_board_game.travel_game', 'Travel game'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Travel_game.jpg',
    },
    {
      fileName: 'City_net.jpg',
      name: t('game_editor.game_boards.static_board_game.city_net', 'City net'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/City_net.jpg',
    },
    {
      fileName: 'City_dream.jpg',
      name: t('game_editor.game_boards.static_board_game.city_dream', 'City dream'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/City_dream.gif',
    },
    {
      fileName: 'lobby.png',
      name: t('game_editor.game_boards.static_board_game.lobby', 'Lobby'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/lobby.png',
    },
    {
      fileName: 'office.png',
      name: t('game_editor.game_boards.static_board_game.office', 'Office'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/office.png',
    },
    {
      fileName: 'recreation.png',
      name: t('game_editor.game_boards.static_board_game.recreation', 'Recreation'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/recreation.png',
    },
    {
      fileName: 'warehouse.png',
      name: t('game_editor.game_boards.static_board_game.warehouse', 'Warehouse'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/warehouse.png',
    },
    {
      fileName: 'dockyard.gif',
      name: t('game_editor.game_boards.static_board_game.dockyard', 'Dockyard'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/dockyard.gif',
    },
    {
      fileName: 'Factory_Mystery.jpg',
      name: t('game_editor.game_boards.static_board_game.factory_mystery', 'Factory mystery'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Factory_Mystery.jpg',
    },
    {
      fileName: 'Bakery.gif',
      name: t('game_editor.game_boards.static_board_game.bakery', 'Bakery'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Bakery.gif',
    },
    {
      fileName: 'Circle_of_Idea.jpg',
      name: t('game_editor.game_boards.static_board_game.circle_of_idea', 'Circle of idea'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Circle_of_Idea.jpg',
    },
    {
      fileName: 'Neon_Idea.jpg',
      name: t('game_editor.game_boards.static_board_game.neon_idea', 'Neon idea'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Neon_Idea.jpg',
    },
    {
      fileName: 'business.jpg',
      name: t('game_editor.game_boards.static_board_game.business', 'Business'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/business.jpg',
    },
    {
      fileName: 'ESG_Market.jpg',
      name: t('game_editor.game_boards.static_board_game.esg_market', 'ESG market'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/ESG_Market.jpg',
    },
    {
      fileName: 'Circular_Economy_Island.gif',
      name: t('game_editor.game_boards.static_board_game.circular_economy_island', 'Circular economy island'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Circular_Economy_Island.gif',
    },
    {
      fileName: 'DEI_Cafe.jpg',
      name: t('game_editor.game_boards.static_board_game.dei_cafe', 'DEI Cafe'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/DEI_Cafe.jpg',
    },
    {
      fileName: 'naturepath.jpg',
      name: t('game_editor.game_boards.static_board_game.nature_path', 'Nature path'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/naturepath.jpg',
    },
    {
      fileName: 'endagered_animals.jpg',
      name: t('game_editor.game_boards.static_board_game.endangered_animals', 'Endangered animals'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/endagered_animals.jpg',
    },
    {
      fileName: 'school.jpg',
      name: t('game_editor.game_boards.static_board_game.school', 'School'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/school.jpg',
    },
    {
      fileName: 'Night_Town_AI.jpg',
      name: t('game_editor.game_boards.static_board_game.night_town', 'Night town AI'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Night_Town_AI.jpg',
    },
    {
      fileName: 'Tired_Office_AI.jpg',
      name: t('game_editor.game_boards.static_board_game.tired_office', 'Tired office AI'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Tired_Office_AI.jpg',
    },
    {
      fileName: 'Invention_Island_AI.jpg',
      name: t('game_editor.game_boards.static_board_game.invention_island', 'Invention island AI'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Invention_Island_AI.jpg',
    },
    {
      fileName: 'Mystery_Island_AI.jpg',
      name: t('game_editor.game_boards.static_board_game.mystery_island', 'Mystery island AI'),
      url: 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_maps/Mystery_Island_AI.jpg',
    },
  ]
}

export const getTranslated360BoardGames = (t: TFunction): GameBoard[] => {
  return [
    {
      fileName: 'Pyramids',
      name: t('game_editor.game_boards.360_board_game.pyramids', 'Pyramids'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/Pyramids.jpg',
    },
    {
      fileName: 'Restaurant',
      name: t('game_editor.game_boards.360_board_game.restaurant', 'Restaurant'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/Restaurant.jpg',
    },
    {
      fileName: 'Park',
      name: t('game_editor.game_boards.360_board_game.park', 'Park'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/Park.jpg',
    },
    {
      fileName: 'Laboratory',
      name: t('game_editor.game_boards.360_board_game.laboratory', 'Laboratory'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/Lab.jpg',
    },
    {
      fileName: 'FutureCity',
      name: t('game_editor.game_boards.360_board_game.future_city', 'Future city'),
      url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/FutureCity.jpg',
    },
  ]
}

export const getBoardFileNameFromUrl = (url: string): string => {
  const segments = url.split('/')
  return segments[segments.length - 1].replace(/\??\d*$/g, '')
}

export const getTaskCardFocusableElementId = (taskId: number) => {
  return `task_card_${taskId}`
}

export const getPlayerCardFocusableElementId = (playerId: number) => {
  return `player_card_${playerId}`
}

export const getTaskMoreMenuSelector = (taskId: number) => {
  return `#${MORE_MENU_BUTTON_ID_PREFIX}${getTaskCardFocusableElementId(taskId)}`
}
