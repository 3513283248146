import classNames from 'classnames'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PaymentMethod } from '../../../api/businessSettingsTypes'
import { useBusinessSettings } from '../../../contexts/BusinessSettingsContext'
import { useUser } from '../../../contexts/userContext'
import { routes } from '../../../routes'
import sharedStyles from '../Settings.module.css'
import styles from './BillingSettings.module.css'
import { BillingAddress } from './components/BillingAddress'
import { CreditCard } from './components/CreditCard'
import { CurrentPlan } from './components/CurrentPlan'
import { LicenceUsage } from './components/LicenceUsage'
import { OrganisationDetails } from './components/OrganisationDetails'
import { SubscriptionActions } from './components/SubscriptionActions'

export const BillingSettings: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const navigate = useNavigate()
  const { data } = useBusinessSettings()

  useEffect(() => {
    if (user != null && (!user.isBusinessAdmin || !user.hasNewPaymentInfo)) {
      navigate(routes.homepage)
    }
  }, [user, navigate])

  if (user == null || data == null || data.businessInfo.newBusinessInfo == null) {
    return null
  }

  const businessInfo = data.businessInfo.newBusinessInfo

  return (
    <div className={sharedStyles.sectionsContainer}>
      <div className={sharedStyles.section}>
        <h1 className={sharedStyles.sectionTitle}>{t('settings_billing.subscription_title', 'Subscription Plan')}</h1>
        <div className={classNames(sharedStyles.sectionCardContainer, styles.container)}>
          <CurrentPlan
            autoRenew={businessInfo.autoRenew}
            licenceCount={businessInfo.licenceCount}
            nextInvoiceDate={businessInfo.nextInvoiceDate}
            nextInvoicePrice={businessInfo.nextInvoicePrice}
            renewCycle={businessInfo.renewCycle}
          />
          <LicenceUsage
            remainingUnusedSeats={businessInfo.remainingUnusedSeats}
            usedPinSeats={businessInfo.usedPinSeats}
            usedPlayerAccountSeats={businessInfo.usedPlayerAccountSeats}
            nextDateWhenLicencesFreed={businessInfo.nextDateWhenLicencesFreed}
          />
          <OrganisationDetails contactPerson={businessInfo.contactPerson} vat={businessInfo.vat} />
          {businessInfo.paymentInfo?.paymentType === PaymentMethod.CARD && (
            <CreditCard paymentInfo={businessInfo.paymentInfo} />
          )}
          {businessInfo.paymentInfo?.paymentType === PaymentMethod.INVOICE && (
            <BillingAddress paymentInfo={businessInfo.paymentInfo} />
          )}
          {businessInfo.paymentInfo?.email === user.email && <SubscriptionActions />}
        </div>
      </div>
    </div>
  )
}
