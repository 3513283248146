import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../../../common/components/Form/InputFormField/InputFormField'
import { AnswerImage } from '../components/AnswerImage'
import styles from './MatchPairsAnswerOption.module.css'

export type MatchPairsAnswerSideProps = {
  name: string
  viewOnly: boolean
}

export const MatchPairsAnswerSide: React.FC<MatchPairsAnswerSideProps> = ({ name, viewOnly }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.sideContainer}>
      <InputFormField
        placeholder={t('game_editor.tasks.answer_options.answer_text_placeholder', 'Type answer')}
        label={t('game_editor.tasks.answer_options.answer_text_accessibility_label', 'Type answer')}
        srOnlyLabel
        name={`${name}.text`}
        fieldContainerClassName={styles.answerTextContainer}
        disabled={viewOnly}
      />
      <AnswerImage name={name} viewOnly={viewOnly} />
    </div>
  )
}
