import classNames from 'classnames'
import { MatchPairsAnswerSide } from '../../../../../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../../../../../util/string'
import styles from './MatchPairsAnswer.module.css'

type MatchPairsSideProps = {
  side: MatchPairsAnswerSide
  isSmall?: boolean
}

export const MatchPairsSide: React.FC<MatchPairsSideProps> = ({ side, isSmall }) => {
  const hasImage = !safeIsNullOrEmpty(side.imageUrl)
  const hasText = !safeIsNullOrEmpty(side.text)
  return (
    <div
      className={classNames(
        styles.matchPairsCell,
        isSmall && styles.smallMatchPairsCell,
        hasText ? (hasImage ? styles.imageAndText : styles.textOnly) : hasImage ? styles.imageOnly : null,
      )}
    >
      {hasImage && <img src={side.imageUrl} alt='' width={isSmall ? 60 : 86} />}
      {hasText && <span>{side.text}</span>}
    </div>
  )
}
