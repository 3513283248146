import { DragCancelEvent, DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfirmation } from '../../../../../../../contexts/ConfirmationContext'
import { useGame } from '../../../../../../../contexts/GameContext'
import { useDebounce } from '../../../../../../../hooks/useDebounce'
import { Task } from '../../../../../../../types/commonTypes'
import { addPinHighlight, removePinHighlight } from '../../../../Marker/TaskPinHelper'
import { TaskAction, TaskActionData, TaskActionFn } from '../../types'

export const useSimpleTaskOrdering = (
  tasks: Task[],
  tasksSort: (taskA: Task, taskB: Task) => number,
  onTaskAction: TaskActionFn,
  reorderingCallback: (orderedTasks: Task[]) => Promise<boolean>,
  onSetShowDragCancel: (show: boolean) => void,
) => {
  const { t } = useTranslation()
  const { requestConfirmation } = useConfirmation()
  const { gameUpdateMeta } = useGame()

  const [isDragging, setIsDragging] = useState<boolean>(false)
  const draggingTask = useRef<Task>()
  const [orderedTasks, setOrderedTasks] = useState<Task[]>(tasks.sort(tasksSort))
  const debouncedOrderedTasks = useDebounce<Task[]>(orderedTasks, 1_000)
  const orderedTasksRef = useRef<Task[]>(orderedTasks)
  const shouldSubmitOrderUpdate = useRef<boolean>(false)
  const didMount = useRef<boolean>(false)

  useEffect(() => {
    if (didMount.current && gameUpdateMeta?.lastUpdateType !== 'manual-reordering') {
      const newOrderedTasks = tasks.sort(tasksSort)
      setOrderedTasks(newOrderedTasks)
      orderedTasksRef.current = newOrderedTasks
    }
  }, [tasks, tasksSort, gameUpdateMeta])

  useEffect(() => {
    didMount.current = true
  }, [])

  useEffect(() => {
    if (shouldSubmitOrderUpdate.current) {
      shouldSubmitOrderUpdate.current = false

      reorderingCallback(debouncedOrderedTasks).then((success) => {
        if (!success) {
          requestConfirmation({
            title: t('game_editor.ordering.order_failed_confirmation.title', 'Reordering failed'),
            text: t(
              'game_editor.ordering.order_failed_confirmation.text',
              'An error occurred while reordering tasks. You can try setting the new order again by clicking retry, or revert to old order by clicking revert.',
            ),
            cancelActionText: t('game_editor.ordering.order_failed_confirmation.action_revert', 'Revert'),
            confirmActionText: t('game_editor.ordering.order_failed_confirmation.action_retry', 'Retry'),
          }).then((shouldRetry) => {
            if (shouldRetry) {
              shouldSubmitOrderUpdate.current = true
              setOrderedTasks((prev) => [...prev])
            } else {
              setOrderedTasks(orderedTasksRef.current)
            }
          })
        }
      })
    }
  }, [reorderingCallback, debouncedOrderedTasks, requestConfirmation, t])

  const onTaskActionInternal = useCallback(
    (actionData: TaskActionData) => {
      const task = actionData.task
      switch (actionData.action) {
        case TaskAction.MOVE_ORDER:
          shouldSubmitOrderUpdate.current = true
          setOrderedTasks((prev) => {
            const oldIndex = prev.findIndex((aTask) => aTask.id === task.id)
            const newIndex = actionData.moveIndex ?? 0
            return arrayMove(prev, oldIndex, newIndex)
          })
          break
        default:
          onTaskAction(actionData)
      }
    },
    [onTaskAction],
  )

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setIsDragging(true)
      onSetShowDragCancel(true)
      draggingTask.current = event.active.data.current?.task as Task
      setTimeout(() => addPinHighlight(parseInt(event.active.id.toString())), 0)
    },
    [onSetShowDragCancel],
  )

  const handleDragCancel = useCallback(
    (event: DragCancelEvent) => {
      setIsDragging(false)
      onSetShowDragCancel(false)
      draggingTask.current = undefined
      removePinHighlight(parseInt(event.active.id.toString()))
    },
    [onSetShowDragCancel],
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (event.over != null && draggingTask.current != null) {
        const oldIndex = event.active.data.current?.sortable.index
        const newIndex = event.over.data.current?.sortable.index
        if (newIndex != null && newIndex !== oldIndex) {
          shouldSubmitOrderUpdate.current = true
          // place the task on correct position inside the new group
          setOrderedTasks((prev) => arrayMove(prev, oldIndex, newIndex))
        }
      }
      setIsDragging(false)
      onSetShowDragCancel(false)
      removePinHighlight(parseInt(event.active.id.toString()))
    },
    [onSetShowDragCancel],
  )

  return {
    orderedTasks,
    onTaskActionInternal,
    handleDragStart,
    handleDragCancel,
    handleDragEnd,
    isDragging,
    draggingTask,
  }
}
