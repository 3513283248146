import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../common/components/button/Button'
import { getIcon } from '../../../common/components/icons/utils'
import { useNotification } from '../../../contexts/NotificationContext'
import { useTheme } from '../../../contexts/ThemeContext'
import { useUser } from '../../../contexts/userContext'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import { routes } from '../../../routes'
import sharedStyles from '../Login.module.css'
import { guestInstructorCodeValidate, loginValidate } from '../helpers'
import { GuestInstructor } from './GuestInstructor'
import { LoginMethod } from './LoginMethod'
import styles from './LoginMethod.module.css'

export type LoginFormValues = {
  email: string
  password: string
}

type LoginFormProps = {
  onClickBack: (shouldAutofocusEnterCode?: boolean) => void
  shouldMirror?: boolean
}

export const LoginForm: React.FC<LoginFormProps> = ({ onClickBack, shouldMirror }) => {
  const { t } = useTranslation()
  const { logoUrl } = useTheme()
  const { user, doLogin } = useUser()
  const { fadeTransition } = useFadeAnimation()
  const navigate = useNavigate()
  const [isGuestInstructor, setIsGuestInstructor] = useState(false)
  const { notifyError } = useNotification()

  useEffect(() => {
    if (!user) {
      navigate(routes.login)
    } else {
      if (user.termsAccepted) {
        navigate(routes.homepage)
      } else {
        navigate(routes.onboardingWizard)
      }
    }
  }, [navigate, user])

  const onSubmit = useCallback(
    async (values: LoginFormValues) => {
      const result = isGuestInstructor
        ? await doLogin('N/A', 'N/A', undefined, undefined, values.password)
        : await doLogin(values.email, values.password)
      if (!result) {
        notifyError({
          title: t('login.login_failed_error_notification.title', 'Login failed'),
          content: t(
            'login.login_failed_error_notification.content',
            'Please check that you are entering correct values and try again or contact us for support',
          ),
        })
      }
    },
    [doLogin, isGuestInstructor, notifyError, t],
  )

  const validate = useCallback(
    (values: Partial<LoginFormValues>) => {
      if (isGuestInstructor) {
        return guestInstructorCodeValidate(t)(values)
      } else {
        return loginValidate(t)(values)
      }
    },
    [isGuestInstructor, t],
  )

  const onClickBackInternal = useCallback(
    (shouldAutofocusEnterCode?: boolean) => () => {
      if (shouldAutofocusEnterCode != null) {
        onClickBack(true)
      } else {
        onClickBack()
      }
    },
    [onClickBack],
  )

  const goToGuestInstructor = () => {
    setIsGuestInstructor((prev) => !prev)
  }

  return (
    <div className={classNames(styles.container, fadeTransition)}>
      <div className={classNames(styles.leftSideContainer, shouldMirror && styles.leftSideContainerMirroring)}>
        <div className={styles.firstRow}>
          <button className={sharedStyles.backButton} onClick={onClickBackInternal()}>
            <span>{getIcon('arrowRight')}</span>
            {t('login.instructor.left.back_button', 'Back')}
          </button>
        </div>
        <div className={styles.secondRow}>
          <h1>{t('login.instructor.left.title', 'Join an existing game')}</h1>
          <p>
            {t(
              'login.instructor.left.body',
              'Return to the previous page to enter a game code if you want to join a game.',
            )}
          </p>
          <Button
            variant='outline-tiny'
            onClick={onClickBackInternal(true)}
            style={{
              color: 'var(--primary-normal)',
              maxWidth: 'fit-content',
              padding: '0.5rem 1.25rem',
              lineHeight: '1rem',
            }}
          >
            {t('login.instructor.left.enter_code_button', 'Enter game code')}
          </Button>
        </div>
      </div>
      <div className={styles.rightSideContainer}>
        <div className={styles.logoContainer}>
          <img src={logoUrl} alt='Seppo' height={26} className={styles.seppoLogo} />
        </div>
        <Form<LoginFormValues> onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, submitting, submitError, modifiedSinceLastSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              {isGuestInstructor ? <GuestInstructor /> : <LoginMethod />}
              <div className={styles.formLabelAndButtonContainer}>
                <Button type='submit' disabled={submitting} className={sharedStyles.bigBlockButton}>
                  {submitting
                    ? t('loading', 'Loading...')
                    : !isGuestInstructor
                    ? t('login.instructor.right.normal.login_button', 'Login with email')
                    : t('login.instructor.right.guest.login_button', 'Login with code')}
                </Button>
              </div>
              {submitError && !modifiedSinceLastSubmit && <div className={styles.submitError}>{submitError}</div>}
            </form>
          )}
        </Form>
        <div className={styles.loginOrTrialContainer}>
          <Button variant='outline-tiny' onClick={goToGuestInstructor}>
            {isGuestInstructor
              ? t('login.instructor.right.guest.switch_button', 'Normal instructor login')
              : t('login.instructor.right.normal.switch_button', 'Guest instructor login')}
          </Button>
        </div>
      </div>
    </div>
  )
}
