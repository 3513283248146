import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { parseGamePackage, parseUserInfoResponseToTUser } from '../api/typeConverters'
import { getUserInfo, login, orderPasswordResetLink, updateUserInfo } from '../api/userApiService'
import { TRIAL_RESPONSE_MESSAGES } from '../pages/login/TrialLogin'
import { getOldUIRoutes } from '../routes'
import { GamePackage, TUser, UserDataField } from '../types/commonTypes'
import {
  EditorPreference,
  LegacyEditorVersion,
  setEditorPreference,
  setLegacyEditorVersion,
  setShouldOpenWizardOnGameOpen,
} from '../util/editorPreference'
import { getGamePackage } from '../api/gameApiService'
import { useGoogleSignIn } from '../util/googleSignIn'
import { DEFAULT_THEME_SETTINGS, useTheme } from './ThemeContext'
import { useNotification } from './NotificationContext'

export type UserUpdate = {
  key: UserDataField
  value: any
}

type LoginState = 'PENDING' | 'LOGGED_IN' | 'LOGGED_OUT'
export const sessionCheckIgnoreUriKeywords = ['/login', '/password-change', '/engine']

export type UserContextType = {
  loginState: LoginState
  user: TUser | null
  updateUser: (
    newValue: UserUpdate,
    doRefresh?: boolean,
    forceReload?: boolean,
    messageUpdateConfirmation?: boolean,
  ) => Promise<boolean>
  updateEditorPreference: (editorPreference: EditorPreference) => void
  refreshUser: () => void
  doLogin: (
    email: string,
    password: string,
    gToken?: string,
    fbToken?: string,
    teacherCode?: string,
  ) => Promise<boolean>
  doLogout: () => void
  doSilentLogout: () => void
  requestPassword: (email: string, language: string) => Promise<string | boolean>
  resendEmail: (email: string, resend: boolean) => Promise<string | boolean>
  startOnboardingProcess: (email: string, password: string, trialConfirm?: boolean) => Promise<string | boolean>
  requestPasswordReset: () => Promise<boolean>
  DEVupdateUser: (debugValues: Partial<TUser>) => void
}

export const UserContext = createContext<UserContextType | null>(null)

interface Props {
  children?: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = React.useState<TUser | null>(null)
  const [loginState, setLoginState] = React.useState<LoginState>('PENDING')
  const { i18n, t } = useTranslation()
  const loginTimeChecker = useRef<number>(0)
  // Key exists if user is redirected from old dashboard with a readily generated session key
  const queryParams = new URLSearchParams(window.location.search)
  const key = queryParams.get('key')
  const directGameId = queryParams.get('game_id')
  const openWizard = queryParams.get('wizard')
  const { notifySuccess, notifyError } = useNotification()

  const { userCredentials, rawToken, clearState } = useGoogleSignIn()

  const { updateThemeSettings } = useTheme()
  useEffect(() => {
    if (user?.theme != null) {
      updateThemeSettings({
        logoUrl: user.theme?.themeLogoUrl ?? DEFAULT_THEME_SETTINGS.logoUrl,
        faviconUrl: user.theme?.themeFaviconUrl ?? DEFAULT_THEME_SETTINGS.faviconUrl,
        colorPrimary: user.theme?.themeMainColor ?? DEFAULT_THEME_SETTINGS.colorPrimary,
        colorSecondary: user.theme?.themeSecondaryColor ?? DEFAULT_THEME_SETTINGS.colorSecondary,
        tabName: user.theme?.themeTabName ?? DEFAULT_THEME_SETTINGS.tabName,
      })
    }
  }, [user?.theme, updateThemeSettings])

  useEffect(() => {
    if (user?.language && i18n.language !== user.language) {
      i18n.changeLanguage(user.language)
    }
  }, [i18n, user?.language])

  useEffect(() => {
    async function run() {
      if (user && user.id && user.gamePackages?.[0].name === '') {
        const packageDataWithNames: GamePackage[] = []
        user.gamePackages?.forEach((gamePackage) => {
          getGamePackage({ packageId: gamePackage.id }).then((gamePackageResponse) => {
            if (gamePackageResponse.success) {
              const gamePackageTemp = parseGamePackage(gamePackageResponse.value)
              packageDataWithNames.push(gamePackageTemp)
              if (packageDataWithNames.length === user.gamePackages?.length) {
                setUser((prev) => {
                  if (prev)
                    return {
                      ...prev,
                      gamePackages: packageDataWithNames,
                    }
                  else return null
                })
              }
            }
          })
        })
      }
    }
    run()
  }, [user])

  const updateEditorPreference = useCallback((editorPreference: EditorPreference) => {
    setEditorPreference(editorPreference)
    //Simply remove the legacy=true from url for now if moving to new editor
    //Needed in situation that user has edited game that was (before edit) forced to legacy so that moving to new becomes possible
    if (editorPreference === EditorPreference.NEW) {
      setTimeout(() => {
        if (window.location.href.includes('legacy'))
          window.location.href = window.location.href.replace('?legacy=true', '').replace('&legacy=true', '')
      }, 10)
    }
    setUser((prev) => {
      if (prev) {
        return {
          ...prev,
          preferLegacyEditor: editorPreference === 'LEGACY',
        }
      } else {
        return null
      }
    })
  }, [])

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data?.includes?.('preferLegacyEditor')) {
        updateEditorPreference(event.data.split('|')[1] === 'true' ? EditorPreference.LEGACY : EditorPreference.NEW)
        setShouldOpenWizardOnGameOpen(event.data.split('|')[2])
      }
    }

    window.addEventListener('message', handleMessage)

    return () => window.removeEventListener('message', handleMessage)
  }, [updateEditorPreference])

  const refreshUser = useCallback(() => {
    if (window.location.href.indexOf('password') > -1) return
    getUserInfo({}).then((userResponse) => {
      if (userResponse.success) {
        setUser(parseUserInfoResponseToTUser(userResponse.value))
        setLoginState('LOGGED_IN')
      } else {
        setLoginState('LOGGED_OUT')
        setUser(null)
      }
    })
  }, [])

  const doLogin = useCallback(
    (email?: string, password?: string, gToken?: string, fbToken?: string, teacherCode?: string) => {
      return login({
        userName: email,
        password: password,
        gToken: gToken,
        fbToken: fbToken,
        teacherCode: teacherCode,
      }).then((userResponse) => {
        if (userResponse.success && userResponse.value.name) {
          localStorage.setItem('sessionKey', userResponse.value.session_id)
          if (
            userResponse.value.use_dashboard2
            // TODO: see if user checks below can be removed when we deploy changes that allow more user groups to ux3
            // !userResponse.value.is_sponsored_user &&
            // !userResponse.value.is_grading_instructor &&
            // !userResponse.value.is_student_teacher
          ) {
            setTimeout(() => {
              localStorage.removeItem('sessionKey')
            }, 20)
            window.location.href = getOldUIRoutes.oldDashboard()
          } else {
            setLoginState('LOGGED_IN')
            setUser(parseUserInfoResponseToTUser(userResponse.value))
          }
          return true
        }
        if (userResponse.success && userResponse.value.status === 'REDIRECT') {
          // a Helsinki user has logged in with ux3 google login
          // We need to redirect them to authentication proxy, which will redirect them again with needed profile info
          // This is a very special edge case, thus a trick to take the to from 'User' where it doesn't exist
          const { to } = JSON.parse(JSON.stringify(userResponse.value))
          window.location.href = to
          return true
        } else {
          setLoginState('LOGGED_OUT')
          return false
        }
      })
    },
    [],
  )

  const requestPassword = useCallback((email: string, language: string) => {
    return login({
      userName: email,
      password: 'NEWTRIAL',
      language: language,
    }).then((res) => {
      if (res.success && res.value.status === TRIAL_RESPONSE_MESSAGES.Pending) {
        setLoginState('LOGGED_IN')
        return res.value.status
      } else if (res.success && res.value.status === TRIAL_RESPONSE_MESSAGES.Reserved) {
        return res.value.status
      } else {
        return false
      }
    })
  }, [])

  const resendEmail = useCallback((email: string, resendPassword?: boolean) => {
    return login({
      userName: email,
      password: 'NEWTRIAL',
      resendPassword,
    }).then((res) => {
      if (res.success && res.value.status === TRIAL_RESPONSE_MESSAGES.Pending) {
        setLoginState('LOGGED_IN')
        return true
      } else {
        return false
      }
    })
  }, [])

  const startOnboardingProcess = useCallback(
    (email: string, password: string, trialConfirm?: boolean) => {
      return login({
        userName: email,
        password,
        trialConfirm,
      }).then((res) => {
        if (res.success && res.value.name) {
          setLoginState('LOGGED_IN')
          localStorage.setItem('sessionKey', res.value.session_id)
          setUser(parseUserInfoResponseToTUser(res.value))
          updateEditorPreference(EditorPreference.NEW)
          return true
        } else {
          return false
        }
      })
    },
    [updateEditorPreference],
  )

  useEffect(() => {
    if (key) {
      localStorage.setItem('sessionKey', key)
      if (directGameId) {
        setLegacyEditorVersion(LegacyEditorVersion.LEGACY_FULL)
        updateEditorPreference(EditorPreference.NEW)
        setShouldOpenWizardOnGameOpen(!!openWizard && directGameId ? directGameId : '')
        window.location.href = '/game-editor/' + directGameId
      } else {
        window.location.href = '/'
      }
      return
    }
    if (loginState === 'LOGGED_IN') return
    if (userCredentials != null) {
      doLogin('', '', rawToken || undefined, undefined)
    } else if ((localStorage.getItem('sessionKey') || '').length > 0) {
      if (sessionCheckIgnoreUriKeywords.some((item) => window.location.href.includes(item))) return
      refreshUser()
    }
  }, [
    directGameId,
    doLogin,
    key,
    loginState,
    openWizard,
    rawToken,
    refreshUser,
    updateEditorPreference,
    userCredentials,
  ])

  const doLogout = useCallback(() => {
    clearState()
    const id = user?.id
    const sessionId = localStorage.getItem('sessionKey')
    localStorage.removeItem('sessionKey')
    setUser(null)
    setLoginState('LOGGED_OUT')
    // Note! Redirect to old UI root as it decides where the next login should happen (if something changed it)
    window.location.href = `${process.env.REACT_APP_OLD_UI_LOGIN_URL}/users/logout?id=${id}&token=${sessionId}`
  }, [clearState, user?.id])

  const doSilentLogout = useCallback(() => {
    clearState()
    const id = user?.id
    const sessionId = localStorage.getItem('sessionKey')
    localStorage.removeItem('sessionKey')
    setUser(null)
    setLoginState('LOGGED_OUT')
    const iframe = document.createElement('iframe')
    iframe.style.width = '1px'
    iframe.style.height = '1px'
    iframe.src = `${process.env.REACT_APP_OLD_UI_LOGIN_URL}/users/logout?id=${id}&token=${sessionId}`
    document.body.appendChild(iframe)
  }, [clearState, user?.id])

  useEffect(() => {
    if (loginTimeChecker.current > 0) {
      window.clearInterval(loginTimeChecker.current)
    }
    if (user) {
      //Refresh user once in an hour
      //If user session has gotten interrupted before that, axios error handler will redirect to login page
      loginTimeChecker.current = window.setInterval(() => {
        refreshUser()
      }, 3600000)
    }
  }, [refreshUser, user])

  const updateUser = useCallback(
    async (newValue: UserUpdate, doRefresh = false, forceReload = false, messageUpdateConfirmation = false) => {
      if (!user) {
        return false
      }
      const oldUser = user
      setUser({
        ...user,
        [newValue.key]: newValue.key === 'dashboardMessage' ? undefined : newValue.value,
      })

      const response = await updateUserInfo({ userId: user.id, updateData: newValue })
      if (response.success && messageUpdateConfirmation) {
        notifySuccess({
          title: t('user.update_response.success.title', 'Custom invitation saved'),
          content: t('user.update_response.success.content', 'Data updated succesfully'),
        })
      }
      if (response.success && doRefresh) {
        refreshUser()
      } else if (response.success && forceReload) {
        window.location.reload()
      } else if (!response.success) {
        console.error(response.error)
        //Should we just ignore failure or rollback. For now rollback
        setUser(oldUser)
        if (messageUpdateConfirmation) {
          notifyError({
            title: t('user.update_response.error.title', 'Custom invitation not saved'),
            content: t(
              'user.update_response.error.content',
              'Something went wrong and your custom invite was not saved',
            ),
          })
        }
      }
      return response.success
    },
    [user, refreshUser, notifySuccess, t, notifyError],
  )

  const requestPasswordReset = useCallback(async () => {
    if (user?.email == null) {
      console.warn('Cannot request password reset, user email is not set')
      return false
    } else {
      const response = await orderPasswordResetLink({ email: user.email, language: user.language || 'en' })
      return response.success
    }
  }, [user?.email, user?.language])

  const DEVupdateUser = useCallback((debugValues: Partial<TUser>) => {
    if (process.env.REACT_APP_ENV === 'development') {
      setUser((prev) =>
        prev == null
          ? null
          : {
              ...prev,
              ...debugValues,
            },
      )
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        loginState,
        updateUser,
        updateEditorPreference,
        refreshUser,
        doLogin,
        doLogout,
        doSilentLogout,
        requestPassword,
        resendEmail,
        startOnboardingProcess,
        requestPasswordReset,
        DEVupdateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('Expected to be wrapped in UserContext!')
  }
  return context
}
