import * as React from 'react'
import { SVGProps } from 'react'

const SvgCommentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.625 3.75a.875.875 0 0 0-.875.875v17.5a.875.875 0 0 0 .875.875h3.5c.483 0 .875.392.875.875v3.5l5.6-4.2a.875.875 0 0 1 .525-.175h12.25a.875.875 0 0 0 .875-.875v-17.5a.875.875 0 0 0-.875-.875H4.625Zm-1.856-.981A2.625 2.625 0 0 1 4.625 2h22.75A2.625 2.625 0 0 1 30 4.625v17.5a2.625 2.625 0 0 1-2.625 2.625H15.417L8.65 29.825a.875.875 0 0 1-1.4-.7V24.75H4.625A2.625 2.625 0 0 1 2 22.125v-17.5c0-.696.277-1.364.769-1.856ZM7.25 10.75c0-.483.392-.875.875-.875h15.75a.875.875 0 1 1 0 1.75H8.125a.875.875 0 0 1-.875-.875Zm.875 4.375a.875.875 0 0 0 0 1.75h12.25a.875.875 0 0 0 0-1.75H8.125Z'
      fill={props.color ?? '#A3A3A3'}
    />
  </svg>
)

export default SvgCommentIcon
