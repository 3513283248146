import React from 'react'
import { getIcon, IconName } from '../../../common/components/icons/utils'
import { EMPTY_CONTENT_FALLBACK } from '../../../common/constants'
import styles from './GamePreviewModalSidebar.module.css'

type SidebarInfoListProps = {
  infoList: InfoWithIcon[]
  isLibrary: boolean
}

export type InfoWithIcon = {
  icon: IconName
  info: string
}

export const SidebarInfoList: React.FC<SidebarInfoListProps> = ({ infoList, isLibrary }) => {
  return (
    <ul className={styles.sidebarInfoList}>
      {infoList.map((item, index) => (
        <li key={`${item.info}${index}`} className='normal regular'>
          <span style={{ color: isLibrary ? 'var(--secondary-dark)' : 'var(--grey-600)', marginRight: 12 }}>
            {getIcon(item.icon)}
          </span>
          {item.info || EMPTY_CONTENT_FALLBACK}
        </li>
      ))}
    </ul>
  )
}
