import { useTranslation } from 'react-i18next'
import { ThreeDots } from '../../../../../../common/components/loaders/ThreeDots/ThreeDots'
import styles from './LibraryTasksEmpty.module.css'

type LibraryTasksEmptyProps = {
  isLoading: boolean
  hasAnySearchParams: boolean
}

export const LibraryTasksEmpty: React.FC<LibraryTasksEmptyProps> = ({ isLoading, hasAnySearchParams }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.noLibraryTasksContainer}>
      {isLoading ? (
        <ThreeDots />
      ) : (
        <div>
          {hasAnySearchParams
            ? t('game_editor.tasks.library_modal.no_results_after_entering_search_params', 'No tasks for search')
            : t(
                'game_editor.tasks.library_modal.no_results_before_entering_search_params',
                'Search tasks by defining any of the search and filter parameters',
              )}
        </div>
      )}
    </div>
  )
}
