import React, { useCallback, useState } from 'react'

import styles from './DirectChat.module.css'
import { PersonChatCard } from '../PersonChatCard/PersonChatCard'
import { useUser } from '../../../../../../contexts/userContext'
import { getIcon } from '../../../../../../common/components/icons/utils'
import { MessageSubtypeEnum } from '../../../../../../api/messagingTypes'
import { DirectChat } from './DirectChat'
import classNames from 'classnames'
import { TMessage } from '../../../../../../types/commonTypes'

type Person = {
  id: number
  name: string
  nickName: string
}

type DirectChatProps = {
  people: Person[]
  messageText: string | undefined
  messages: TMessage[]
  onSubmit: (messageSubtype: MessageSubtypeEnum, personId: number) => void
  onSubmitAudio: (messageSubtype: MessageSubtypeEnum, personId: number, audioUrl: string) => void
  onChangeMessage: (message: string, receiverId: number) => void
  onDeleteMessage: (messageId: number) => void
}

export const DirectChatInboxes: React.FC<DirectChatProps> = ({
  people,
  messageText,
  messages,
  onChangeMessage,
  onSubmit,
  onSubmitAudio,
  onDeleteMessage,
}) => {
  const [selectedPerson, setSelectedPerson] = useState<{ id: number; name: string | number } | undefined>()
  const { user } = useUser()

  const handleClickChatCard = useCallback((personId: number, name: string | number) => {
    setSelectedPerson({ id: personId, name })
  }, [])

  if (user == null) {
    return null
  }

  return (
    <>
      {selectedPerson != null && (
        <div className={classNames(styles.chatNameContainer, styles.isolationShadow)}>
          <button className={styles.backButton} onClick={() => setSelectedPerson(undefined)}>
            {getIcon('arrowLeft')}
          </button>
          <h4>{selectedPerson.name}</h4>
        </div>
      )}
      <div className={styles.container}>
        {selectedPerson == null ? (
          <ul className={styles.peopleListContainer}>
            {people.map(({ id, nickName }, index) => {
              return (
                <PersonChatCard
                  key={`direct_chat_person_${id}:${index}`}
                  name={nickName}
                  personId={id}
                  onClickCard={handleClickChatCard}
                />
              )
            })}
          </ul>
        ) : (
          <DirectChat
            messageText={messageText}
            messages={messages}
            onChangeMessage={onChangeMessage}
            onSubmit={onSubmit}
            onSubmitAudio={onSubmitAudio}
            selectedPersonId={selectedPerson.id}
            onDeleteMessage={onDeleteMessage}
          />
        )}
      </div>
    </>
  )
}
