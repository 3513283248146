import classNames from 'classnames'
import { t } from 'i18next'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import styles from './LoginMethod.module.css'

export const PasswordResetSetPassword: React.FC = () => {
  const { fadeTransition } = useFadeAnimation()

  return (
    <div className={classNames(styles.mainContentWrapper, fadeTransition)}>
      <h1 className={styles.title}>
        {t('login.instructor.password_change.set_password_form.title', 'Reset your password')}
      </h1>
      <div className={styles.paragraphContainer}>
        <p>
          {t(
            'login.instructor.password_change.set_password_form.body',
            'Enter your new password. The password must be at least 10 characters long with both upper and lower case letters between a to z and numbers',
          )}
        </p>
      </div>
      <div className={styles.passwordResetFieldsContainer}>
        <InputFormField
          label='Password'
          placeholder={t('login.instructor.password_change.order.password_placeholder', 'Your new password')}
          srOnlyLabel
          name='password'
          type='password'
          big
        />
        <InputFormField
          label='Confirm Password'
          placeholder={t('login.instructor.password_change.order.password_confirm_placeholder', 'Confirm new password')}
          srOnlyLabel
          name='passwordConfirmation'
          type='password'
          big
        />
      </div>
    </div>
  )
}
