import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getHoursMinutesSecondsFromSeconds, safeParseInt } from '../../../../util/number'
import { safeIsNullOrEmpty } from '../../../../util/string'
import styles from './AddTime.module.css'

type TimeLimit = {
  hours: number
  minutes: number
  seconds: number
}

export type AddTimeProps = {
  onChange: (seconds: number) => void
  error?: string
  className?: string
  initialTimeInSeconds?: number
  disabled?: boolean
}

const valueOrZero = (value: number) => {
  return isNaN(value) ? 0 : value
}

export const AddTime: React.FC<AddTimeProps> = ({ onChange, error, className, initialTimeInSeconds, disabled }) => {
  const { t } = useTranslation()

  const [timeLimit, setTimeLimit] = useState<TimeLimit>(() => {
    const [hours, minutes, seconds] = getHoursMinutesSecondsFromSeconds(initialTimeInSeconds ?? 0)
    return {
      hours,
      minutes,
      seconds,
    }
  })

  useEffect(() => {
    const secondsTotal =
      valueOrZero(timeLimit.seconds) + valueOrZero(timeLimit.minutes) * 60 + valueOrZero(timeLimit.hours) * 60 * 60
    onChange(secondsTotal)
  }, [onChange, timeLimit])

  const updateTimeCreator = (key: keyof TimeLimit) => (e: any) => {
    setTimeLimit((prev) => ({ ...prev, [key]: safeParseInt(e.target.value) }))
  }

  return (
    <div
      className={classNames(
        styles.addTimeContainer,
        !safeIsNullOrEmpty(error) && styles.addTimeContainer_withError,
        className,
      )}
    >
      <label className={styles.label}>
        <input
          className={styles.input}
          min={0}
          value={timeLimit.hours}
          type='number'
          onChange={updateTimeCreator('hours')}
          disabled={disabled}
        />
        <span className='tiny'>{t('game_editor.add_time.hours', 'hours')}</span>
      </label>
      <label className={styles.label}>
        <input
          className={styles.input}
          min={0}
          value={timeLimit.minutes}
          type='number'
          onChange={updateTimeCreator('minutes')}
          disabled={disabled}
        />
        <span className='tiny'>{t('game_editor.add_time.minutes', 'minutes')}</span>
      </label>
      <label className={styles.label}>
        <input
          className={styles.input}
          min={0}
          value={timeLimit.seconds}
          type='number'
          onChange={updateTimeCreator('seconds')}
          disabled={disabled}
        />
        <span className='tiny'>{t('game_editor.add_time.seconds', 'seconds')}</span>
      </label>
      {!safeIsNullOrEmpty(error) && <span className={styles.addTimeErrorMessage}>{error}</span>}
    </div>
  )
}
