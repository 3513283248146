import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { useField } from 'react-final-form'
import { getFieldError } from '../../../../util/form'
import { safeIsNullOrEmpty } from '../../../../util/string'
import styles from './FormError.module.css'

export const ERROR_OUTLINE_CLASS_NAME = 'form-field-with-outline-error'
export const ERROR_ROUNDED_OUTLINE_CLASS_NAME = 'form-field-with-rounded-outline-error'

export type FormFieldWithErrorProps = {
  name: string
  validateOnlyIfDirty?: boolean
  wrapperClassName?: string
  errorClassName?: string
}

export const FormFieldWithError: React.FC<PropsWithChildren<FormFieldWithErrorProps>> = ({
  children,
  name,
  validateOnlyIfDirty,
  wrapperClassName,
  errorClassName,
}) => {
  const { meta } = useField(name)
  const error = getFieldError(meta, validateOnlyIfDirty)
  const hasError = !safeIsNullOrEmpty(error)

  return (
    <div className={classNames(styles.fieldWithErrorWrapper, hasError && styles.hasError, wrapperClassName)}>
      {children}
      {hasError && <div className={classNames(styles.errorContainer, errorClassName)}>{error}</div>}
    </div>
  )
}
