import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { PopupActionMenu } from '../../../../../../../common/components/PopupMenu/PopupActionMenu'
import { ScoreGradientIcon } from '../../../../../../../common/components/icons'
import { Task } from '../../../../../../../types/commonTypes'
import { getTaskIcon } from '../../../../../../../util/game'
import { getTaskCardFocusableElementId } from '../../../../../helpers'
import { getTitleForTask } from '../../../../Tasks/TaskModal/helpers'
import styles from './TaskCard.module.css'

export type SimpleTaskCardProps = {
  task: Task
  compact?: boolean
  noPointsGame?: boolean
  showLevel?: boolean
  showOrder?: boolean
}

export const SimpleTaskCard: React.FC<SimpleTaskCardProps> = ({
  task,
  compact,
  noPointsGame,
  showLevel,
  showOrder,
}) => {
  const { t } = useTranslation()

  return (
    <li className={classNames(styles.container, compact && styles.containerCompact, styles.simpleTaskCardTilt)}>
      <div className={styles.leftContainer}>
        {showOrder && task.level !== 0 ? (
          <span className={classNames(styles.taskOrder, compact && styles.taskOrder_compact)}>{task.order ?? '?'}</span>
        ) : (
          getTaskIcon(task, showLevel)
        )}
      </div>
      <div className={classNames(styles.middleContainer, compact && styles.middleContainerCompact)}>
        <h5 className={styles.title}>
          <span className={styles.titleInnerText}>{task.name}</span>
        </h5>
        <div className={styles.middleBottomRow}>
          <div className={classNames(styles.statusAndTypePill, compact && styles.hideIfCompact)}>
            {getTitleForTask(task, t)}
          </div>
          {!noPointsGame && (
            <div className={styles.iconAndPointsContainer}>
              <ScoreGradientIcon />
              {task.pointsSum}
            </div>
          )}
        </div>
      </div>
      <div className={styles.rightContainer}>
        <PopupActionMenu
          id={getTaskCardFocusableElementId(task.id)}
          position='inline-down'
          buttonClassName={styles.moreMenuButton}
        />
      </div>
    </li>
  )
}
