import React from 'react'
import styles from './Spinner.module.css'

type SpinnerProps = {
  spinnerVariant?: 'primary' | 'secondary'
  remSize?: number
}

export const Spinner: React.FC<SpinnerProps> = ({ spinnerVariant = 'primary', remSize = 5 }) => {
  return (
    <>
      <div
        className={styles.spinner}
        style={{
          width: `${remSize}rem`,
          height: `${remSize}rem`,
          border: `${remSize * 0.125}rem solid #f3f3f3`,
          borderTop: `${remSize * 0.125}rem solid ${
            spinnerVariant === 'secondary' ? 'var(--secondary-normal)' : 'var(--primary-normal)'
          }`,
        }}
      />
    </>
  )
}
