import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useGame } from '../../../../../../../contexts/GameContext'
import sharedSidebarStyles from '../../../EditorSidebar.module.css'
import { TaskSummary } from '../../types'
import { FlashTaskCard } from '../Task/FlashTaskCard'
import { SelectableTaskCard } from '../Task/SelectableTaskCard'
import styles from './ManageGamePanel.module.css'

type TasksListProps = {
  hasLevels?: boolean
  isCompact: boolean
  isFlash: boolean
  noPointsGame?: boolean
  onClickTask: (taskId: number) => void
  selectedTaskId?: number
  taskSummaries: TaskSummary[]
}

export const TasksList: React.FC<TasksListProps> = ({
  hasLevels,
  isCompact,
  isFlash,
  noPointsGame,
  onClickTask,
  selectedTaskId,
  taskSummaries,
}) => {
  const { t } = useTranslation()
  const { updateTaskOpenStatus } = useGame()

  return (
    <>
      {taskSummaries.length === 0 && (
        <p className={sharedSidebarStyles.emptyStateInfo}>
          {t('game_editor.sidebar.manage_game_panel.no_tasks_info', 'Add tasks in Build tab')}
        </p>
      )}
      <ul className={styles.itemsList}>
        {taskSummaries.map((taskSummary) => {
          return taskSummary.task.advanced.isFlash ? (
            <FlashTaskCard
              compact={isCompact}
              isSelected={selectedTaskId === taskSummary.task.id}
              key={`selectable_task_${taskSummary.task.id}`}
              noPointsGame={noPointsGame}
              onSelect={onClickTask}
              onUpdateOpenStatus={updateTaskOpenStatus}
              taskSummary={taskSummary}
            />
          ) : (
            <SelectableTaskCard
              compact={isCompact}
              isSelected={selectedTaskId === taskSummary.task.id}
              key={`selectable_task_${taskSummary.task.id}`}
              noPointsGame={noPointsGame}
              onClickTask={onClickTask}
              showLevel={hasLevels}
              taskSummary={taskSummary}
            />
          )
        })}
      </ul>
      {isFlash && taskSummaries.length > 0 && (
        <p className={classNames('tiny', styles.flashTaskInstructions)}>
          {t(
            'game_editor.sidebar.manage_game_panel.activate_flash_task_instructions',
            'You can enable flash tasks at any point of the game by pressing the flash icon',
          )}
        </p>
      )}
    </>
  )
}
