import * as React from 'react'
import { SVGProps } from 'react'

const SvgPeopleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.045 1.591a3.25 3.25 0 1 1 4.596 4.596 3.25 3.25 0 0 1-4.596-4.596ZM5.343 7.14a5 5 0 0 1 5 5 .5.5 0 0 1-1 0 4 4 0 1 0-8 0 .5.5 0 0 1-1 0 5 5 0 0 1 5-5ZM6.934 2.3c.255.254.443.565.551.902a4.071 4.071 0 0 1-3.852-.774 2.256 2.256 0 0 1 1.71-.788 2.25 2.25 0 0 1 1.591.66Zm-3.84 1.59c0-.19.023-.376.07-.556a5.071 5.071 0 0 0 4.405.885 2.25 2.25 0 0 1-4.476-.329Zm8.16-2.248a2.25 2.25 0 0 1 2.23 1.56 4.062 4.062 0 0 1-1.224.188 4.038 4.038 0 0 1-2.152-.617.5.5 0 1 0-.532.847 5.062 5.062 0 0 0 3.992.6 2.25 2.25 0 0 1-3.455 1.555.5.5 0 0 0-.546.837 3.25 3.25 0 1 0 .005-5.447.5.5 0 1 0 .544.839 2.25 2.25 0 0 1 1.138-.362Zm-.8 6.597a.5.5 0 1 1-.221-.975 5.004 5.004 0 0 1 6.11 4.876.5.5 0 1 1-1 0 4.004 4.004 0 0 0-4.89-3.9Z'
      fill={props.color ?? '#6F6F6F'}
    />
  </svg>
)

export default SvgPeopleIcon
