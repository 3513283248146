import * as React from 'react'
import { SVGProps } from 'react'

const SvgFolderMoveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 0a3 3 0 0 0-3 3v19.747a3.267 3.267 0 0 0 2.964 3.208c.094.03.195.045.299.045H11a1 1 0 1 0 0-2H6.264c.057-.132.106-.268.146-.408v-.003l3.133-10.866a1 1 0 0 1 .962-.723H29a1 1 0 0 1 .966 1.257 1 1 0 0 0 1.933.515 3 3 0 0 0-2.9-3.772h-1V7a3 3 0 0 0-3-3H12V3a3 3 0 0 0-3-3H3Zm23 7v3H10.506a3 3 0 0 0-2.884 2.168L4.487 23.04l-.001.006A1.267 1.267 0 0 1 2 22.726V3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h14a1 1 0 0 1 1 1Z'
      fill={props.color ?? 'var(--grey-700)'}
    />
    <path
      d='M18.833 23.833h5.919l-1.293 1.293a1 1 0 0 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 0 0-1.414 1.414l1.293 1.293h-5.919a1 1 0 1 0 0 2Z'
      fill={props.color ?? 'var(--grey-700)'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 23a9 9 0 1 1 9 9 9 9 0 0 1-9-9Zm9-7a7 7 0 1 0 0 14 7 7 0 0 0 0-14Z'
      fill={props.color ?? 'var(--grey-700)'}
    />
  </svg>
)

export default SvgFolderMoveIcon
