import * as React from 'react'
import { SVGProps } from 'react'
const SvgTransparentStarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 2.006a.092.092 0 0 0-.081.048v.002l-4.453 8.828a1 1 0 0 1-.794.544l-8.587.851-.015.002a.074.074 0 0 0-.066.089c.003.013.01.026.02.036l7.072 7.003a1 1 0 0 1 .26.976l-2.618 9.51a.084.084 0 0 0 .12.096l.005-.003 8.693-4.305a1 1 0 0 1 .888 0l8.71 4.313a.084.084 0 0 0 .12-.095l-2.618-9.51a1 1 0 0 1 .26-.976l7.067-7.004a.073.073 0 0 0-.046-.125l-.016-.002-8.586-.85a1 1 0 0 1-.794-.545l-4.46-8.833v-.001a.092.092 0 0 0-.081-.05Zm.972-.406.89-.456a2.092 2.092 0 0 0-3.724 0l-4.21 8.348-8.033.796a2.073 2.073 0 0 0-1.279 3.538l6.657 6.593-2.465 8.953-.002.009a2.084 2.084 0 0 0 2.947 2.398l.003-.002L16 27.695l8.256 4.088.003.001a2.083 2.083 0 0 0 2.947-2.398l-2.467-8.962 6.652-6.592a2.073 2.073 0 0 0-1.279-3.539l-8.033-.795-4.214-8.349-.893.45Z'
      fill={props.color ?? '#6F6F6F'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 2.006a.092.092 0 0 0-.081.048v.002l-4.453 8.828a1 1 0 0 1-.794.544l-8.587.851-.015.002a.074.074 0 0 0-.066.089c.003.013.01.026.02.036l7.072 7.003a1 1 0 0 1 .26.976l-2.618 9.51a.084.084 0 0 0 .12.096l.005-.003 8.693-4.305a1 1 0 0 1 .888 0l8.71 4.313a.084.084 0 0 0 .12-.095l-2.618-9.51a1 1 0 0 1 .26-.976l7.067-7.004a.073.073 0 0 0-.046-.125l-.016-.002-8.586-.85a1 1 0 0 1-.794-.545l-4.46-8.833v-.001a.092.092 0 0 0-.081-.05Zm.972-.406.89-.456a2.092 2.092 0 0 0-3.724 0l-4.21 8.348-8.033.796a2.073 2.073 0 0 0-1.279 3.538l6.657 6.593-2.465 8.953-.002.009a2.084 2.084 0 0 0 2.947 2.398l.003-.002L16 27.695l8.256 4.088.003.001a2.083 2.083 0 0 0 2.947-2.398l-2.467-8.962 6.652-6.592a2.073 2.073 0 0 0-1.279-3.539l-8.033-.795-4.214-8.349-.893.45Z'
      fill={props.color ?? '#6F6F6F'}
      fillOpacity={0.25}
    />
    <defs>
      <linearGradient
        id='transparent_star_icon_svg__a'
        x1={16.003}
        y1={0.006}
        x2={16.003}
        y2={32.005}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgTransparentStarIcon
