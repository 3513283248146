import classNames from 'classnames'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { useRef, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { ExerciseType } from '../../../../../api/gameTypes'
import { PopupActionMenuItemProps } from '../../../../../common/components/PopupMenu/PopupActionMenu'
import { Button } from '../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../common/components/icons/utils'
import { useUser } from '../../../../../contexts/userContext'
import { useFormSubmitFailureListener } from '../../../../../hooks/useFormSubmitFailureListener'
import { FnAny, FormErrorType, Game, Task } from '../../../../../types/commonTypes'
import { areObjectsEqual, isEmpty } from '../../../../../util/functional'
import { getTotalPointsForTask } from '../../../../../util/game'
import { isNullOrZero } from '../../../../../util/number'
import { AddSubtaskSection } from './AddSubtaskSection'
import { TaskAdvancedSettings } from './AdvancedSettings/TaskAdvancedSettings'
import { SubtaskField } from './SubtaskField'
import { TaskDevPrefill } from './TaskDevPrefill'
import { TaskFormSection } from './TaskFormSection'
import { TaskInformation } from './TaskInformation/TaskInformation'
import { TaskLibraryModal } from './TaskLibrary/TaskLibraryModal'
import styles from './TaskModal.module.css'
import { getInitialValuesForTaskType } from './helpers'

const FORM_ID = 'task_form'

const getSubtaskElementId = (id: number) => `subtask_${id}`

type TaskFormProps = {
  game: Game
  initialValues: Partial<Task>
  onSubmit: (values: Task) => void
  onCancel: (hasChanges: boolean) => void
  validate: (values: Partial<Task>) => FormErrorType<Task>
  onDelete?: () => void
  onCopy?: (hasChanges: boolean) => void
  showTaskLibraryModal: boolean
  onSetShowTaskLibraryModal: (show: boolean) => void
  showAutomaticBadgeModal: boolean
  onSetShowAutomaticBadgeModal: (show: boolean) => void
  viewOnly: boolean
}

export const TaskForm: React.FC<TaskFormProps> = ({
  game,
  initialValues,
  onSubmit,
  onCancel,
  onDelete,
  onCopy,
  validate,
  showTaskLibraryModal,
  onSetShowTaskLibraryModal,
  showAutomaticBadgeModal,
  onSetShowAutomaticBadgeModal,
  viewOnly,
}) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [hasChanges, setHasChanges] = useState(false)

  const createHandleAddSubtaskClick = (push: FnAny) => (type: ExerciseType) => {
    push('subtasks', getInitialValuesForTaskType(type))
  }

  const scrollableContainer = useRef<HTMLDivElement>(null)
  const addLibTaskButtonRef = useRef<HTMLButtonElement>(null)

  const onShowTaskLibraryModal = () => onSetShowTaskLibraryModal(true)

  const onCloseTaskLibraryModal = (subtaskId?: number) => {
    onSetShowTaskLibraryModal(false)
    addLibTaskButtonRef.current?.focus()
    if (subtaskId) {
      setTimeout(() => {
        const child = document.querySelector(`#${getSubtaskElementId(subtaskId)}`)
        const parent = scrollableContainer.current
        if (child != null && parent != null) {
          const top = parent.scrollTop + child.getBoundingClientRect().top - parent.getBoundingClientRect().top - 50
          parent.scrollTo({ top: Math.max(0, top), behavior: 'smooth' })
        }
      }, 0)
    }
  }

  const hasAnyLibrary = user?.hasCommunity || user?.hasOrgLibrary || user?.hasTemplates

  const taskInfoMoreMenuItems: undefined | PopupActionMenuItemProps[] =
    viewOnly || onDelete == null || onCopy == null
      ? undefined
      : [
          { onClick: () => onCopy(hasChanges), text: t('common.duplicate', 'Duplicate'), icon: 'duplicate' },
          { onClick: onDelete, text: t('common.delete', 'Delete'), icon: 'trash' },
        ]

  const { submitFailedToken, submitListener } = useFormSubmitFailureListener()

  return (
    <Form<Task>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      decorators={[submitListener]}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({
        handleSubmit,
        values,
        form: {
          mutators: { push },
        },
        submitting,
        errors,
      }) => {
        return (
          <>
            <div className={classNames(styles.taskModalHeader, styles.taskModalSection)}>
              <div className={styles.taskModalHeaderTitle}>
                <span className={styles.taskModalHeaderIcon}>{getIcon('exercisePin')}</span>
                <span>{t('game_editor.tasks.task_settings_title', 'Task settings')}</span>
              </div>
              <div className={styles.taskModalHeaderButtons}>
                {process.env.REACT_APP_ENV === 'development' && initialValues.id == null && <TaskDevPrefill />}
                <CloseButton autoFocus onClick={() => onCancel(!areObjectsEqual(values, initialValues))} />
              </div>
            </div>

            <form onSubmit={handleSubmit} id={FORM_ID}>
              <FormSpy
                onChange={(props) => {
                  setHasChanges(props.dirty)
                }}
              />
              <div className={classNames(styles.taskForm, styles.taskModalMainSection)} ref={scrollableContainer}>
                <TaskFormSection
                  title={t('game_editor.tasks.task_information_title', 'Task information')}
                  moreMenuItems={taskInfoMoreMenuItems}
                  initialIsExpanded
                  hasAnyErrors={errors?.name != null}
                  forceExpandToken={submitFailedToken}
                >
                  <TaskInformation game={game} level={values.level} viewOnly={viewOnly} />
                </TaskFormSection>

                <TaskFormSection
                  title={t('game_editor.tasks.tasks_list_title', 'Tasks')}
                  containerClassName={styles.taskFormTasksContainer}
                  initialIsExpanded
                  hasAnyErrors={!isEmpty(errors?.subtasks)}
                  forceExpandToken={submitFailedToken}
                >
                  <FieldArray name='subtasks'>
                    {({ fields }) => {
                      return !isNullOrZero(fields?.length) ? (
                        fields.map((name, index) => {
                          return (
                            <SubtaskField
                              key={name}
                              id={getSubtaskElementId(values.subtasks[index].tempId ?? index)}
                              game={game}
                              name={name}
                              value={values.subtasks[index]}
                              onDelete={viewOnly ? undefined : () => fields.remove(index)}
                              onMoveDown={
                                !viewOnly && index < (fields?.length ?? 0) - 1
                                  ? () => fields.move(index, index + 1)
                                  : undefined
                              }
                              onMoveUp={!viewOnly && index > 0 ? () => fields.move(index, index - 1) : undefined}
                              hasAnyErrors={!isEmpty(errors?.subtasks?.[index])}
                              forceExpandToken={submitFailedToken}
                              viewOnly={viewOnly}
                            />
                          )
                        })
                      ) : (
                        <div className={styles.noTasks}>
                          {t('game_editor.tasks.no_tasks_placeholder', 'No tasks yet')}
                        </div>
                      )
                    }}
                  </FieldArray>
                  {!viewOnly && (
                    <>
                      <AddSubtaskSection onClick={createHandleAddSubtaskClick(push)} />
                      {hasAnyLibrary && (
                        <div className={styles.addFromLibraryContainer}>
                          <Button
                            type='button'
                            variant='outline-normal'
                            onClick={onShowTaskLibraryModal}
                            ref={addLibTaskButtonRef}
                          >
                            {getIcon('search')}
                            {t('game_editor.tasks.add_task_from_library_button', 'Add from library')}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </TaskFormSection>

                <TaskFormSection
                  title={t('game_editor.tasks.advanced_title', 'Advanced')}
                  hasAnyErrors={!isEmpty(errors?.advanced)}
                  forceExpandToken={submitFailedToken}
                >
                  <TaskAdvancedSettings
                    game={game}
                    maxPoints={getTotalPointsForTask(values) ?? 0}
                    showAutomaticBadgeModal={showAutomaticBadgeModal}
                    onSetShowAutomaticBadgeModal={onSetShowAutomaticBadgeModal}
                    viewOnly={viewOnly}
                  />
                </TaskFormSection>
              </div>

              {errors?.[FORM_ERROR] && submitFailedToken && (
                <div className={classNames(styles.globalFormError, 'small')}>{errors[FORM_ERROR]}</div>
              )}
            </form>

            <div className={classNames(styles.taskModalFooter, styles.taskModalSection)}>
              <Button variant='outline-normal' onClick={() => onCancel(!areObjectsEqual(values, initialValues))}>
                {t('common.cancel', 'Cancel')}
              </Button>
              {!viewOnly && (
                <Button type='submit' disabled={submitting} form={FORM_ID}>
                  {t('common.save', 'Save')}
                </Button>
              )}
            </div>
            {showTaskLibraryModal && <TaskLibraryModal onClose={onCloseTaskLibraryModal} />}
          </>
        )
      }}
    </Form>
  )
}
