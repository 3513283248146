import { useTranslation } from 'react-i18next'
import { CheckboxAnswerOption, CheckboxSubtask } from '../../../../../../../types/commonTypes'
import { AnswerOptionsList } from '../components/AnswerOptionsList'
import { AutomatedFeedback } from '../components/AutomatedFeedback'
import { ChoiceAnswerOption } from '../components/ChoiceAnswerOption'
import { FieldArrayRenderProps } from 'react-final-form-arrays'

type CheckboxSubtaskProps = {
  value: CheckboxSubtask
  name: string
  noPoints?: boolean
  viewOnly: boolean
}

const mapFieldToAnswerOption = (
  name: string,
  index: number,
  fields: FieldArrayRenderProps<CheckboxAnswerOption, any>['fields'],
  task: CheckboxSubtask,
  viewOnly: boolean,
  noPoints?: boolean,
): JSX.Element => {
  return (
    <ChoiceAnswerOption
      key={name}
      name={name}
      hasFixedPoints={task.data.allAnswersHaveSamePoints}
      fixedPoints={task.data.allAnswersPoints}
      onDelete={() => fields.remove(index)}
      noPoints={noPoints}
      viewOnly={viewOnly}
    />
  )
}

export const CheckboxSubtaskFields: React.FC<CheckboxSubtaskProps> = ({ value, name, noPoints, viewOnly }) => {
  const { t } = useTranslation()

  return (
    <>
      <AnswerOptionsList<CheckboxSubtask>
        name={name}
        task={value}
        noPoints={noPoints}
        mapFieldToAnswerOption={mapFieldToAnswerOption}
        viewOnly={viewOnly}
      />
      <h5 style={{ marginTop: 16 }}>{t('game_editor.tasks.automated_feedback.title', 'Automated feedback')}</h5>
      <AutomatedFeedback name={name} viewOnly={viewOnly} />
    </>
  )
}
