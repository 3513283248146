import { useTranslation } from 'react-i18next'
import { IconTooltip } from '../../../../../../common/components/tooltip/IconTooltip'
import { SelectableTaskPreview } from '../../../../../../composites/TaskPreview/TaskPreview'
import { boldSubstrings, safeIsNullOrEmpty } from '../../../../../../util/string'
import styles from './LibraryTasksList.module.css'
import { LibTaskMap, LibTaskSelectedMap } from './types'

type LibraryTasksListProps = {
  count: number
  taskMap: LibTaskMap
  onSelectTask: (selectableTaskId: string) => void
  selectedTaskIds: LibTaskSelectedMap
  noPointsGame?: boolean
  searchTerm?: string
}

export const LibraryTasksList: React.FC<LibraryTasksListProps> = ({
  count,
  taskMap,
  selectedTaskIds,
  onSelectTask,
  noPointsGame,
  searchTerm,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.libraryTasksContainer}>
      <div className={styles.libraryTasksCount}>
        <h3>{t('game_editor.tasks.library_modal.search_result_title', '%{count} results', { count })}</h3>
        <IconTooltip
          tooltipClassName={styles.searchTooltip}
          iconColor='var(--grey-600)'
          tooltipContent={t(
            'game_editor.tasks.library_modal.search_count_tooltip',
            'Total count of tasks (tasks and all their subtasks)',
          )}
        />
      </div>
      <ul className={styles.searchList}>
        {Object.entries(taskMap).map(([taskIndexAndId, task]) => {
          const authorHtml = safeIsNullOrEmpty(searchTerm) ? task.author : boldSubstrings(task.author, searchTerm!)
          const nameHtml = safeIsNullOrEmpty(searchTerm) ? task.name : boldSubstrings(task.name, searchTerm!)
          return (
            <SelectableTaskPreview
              task={task}
              key={`libTask_${taskIndexAndId}`}
              selectableTaskId={taskIndexAndId}
              isSelected={selectedTaskIds[taskIndexAndId] ?? false}
              onSelect={onSelectTask}
              noPointsGame={noPointsGame}
              authorHtml={authorHtml}
              taskNameHtml={nameHtml}
            />
          )
        })}
      </ul>
    </div>
  )
}
