import { Field, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { safeIsNullOrEmpty } from '../../../../util/string'

// inspired by: https://github.com/final-form/react-final-form/issues/348 & https://erikras.com/blog/declarative-form-rules
export type FormFieldSpyProps = {
  spyFieldName: string
  dependantFieldName: string
  getShouldUpdate?: (fieldValue?: any, formValues?: any) => boolean
  getUpdateToValue?: (fieldValue?: any, formValues?: any) => any
}

export const FormFieldSpy: React.FC<FormFieldSpyProps> = ({
  spyFieldName,
  dependantFieldName,
  getShouldUpdate = (value) => !safeIsNullOrEmpty(value),
  getUpdateToValue = () => null,
}) => {
  return (
    <Field name={dependantFieldName} subscription={{}}>
      {({ input: { onChange } }) => (
        <FormSpy subscription={{}}>
          {({ form }) => (
            <OnChange name={spyFieldName}>
              {(value) => {
                const formValues = form.getState().values
                if (getShouldUpdate(value, formValues)) {
                  onChange(getUpdateToValue(value, formValues))
                }
              }}
            </OnChange>
          )}
        </FormSpy>
      )}
    </Field>
  )
}
