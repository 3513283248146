import { useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../common/components/button/Button'
import { Wizard, WizardHeaderButton } from '../../../../composites/wizard/Wizard'
import { FormErrorType } from '../../../../types/commonTypes'
import { MapInstanceID, useMapApi } from '../../../../util/map'
import { extendGameDataWithAdditionalMapSettings, validateGameForm } from '../../helpers'
import { CreateGameOption, GameForm } from '../../types'
import styles from './QuickStartWizard.module.css'
import { GameBoardStep } from './steps/GameBoardStep'
import { GameInfoStep } from './steps/GameInfoStep'
import { NewGameStep } from './steps/NewGameStep'

type QuickStartWizardProps = {
  onSubmit: (data: GameForm) => Promise<void>
  onOpenGameSettings: () => void
  onClose: () => void
}

const TOTAL_STEPS_COUNT = 3

export const QuickStartWizard: React.FC<QuickStartWizardProps> = ({ onClose, onOpenGameSettings, onSubmit }) => {
  const { t } = useTranslation()
  const { map } = useMapApi(MapInstanceID.MODAL)

  const [stepIndex, setStepIndex] = useState(1)
  const [createOption, setCreateOption] = useState<CreateGameOption>(CreateGameOption.QUICK_START)

  const headerButton: WizardHeaderButton =
    stepIndex === 1
      ? { label: t('common.skip', 'Skip'), onClick: onClose }
      : {
          label: t('common.back', 'Back'),
          onClick: () => {
            setStepIndex((prev) => Math.max(prev - 1, 0))
          },
        }

  const validate = (values: Partial<GameForm>): FormErrorType<GameForm> => {
    return stepIndex === 2 ? validateGameForm(values, t) : {}
  }

  const onSubmitInternal = async (values: GameForm) => {
    if (stepIndex === 1 && createOption === CreateGameOption.GAME_SETTINGS) {
      onOpenGameSettings()
    } else if (stepIndex === TOTAL_STEPS_COUNT) {
      const submitValues = extendGameDataWithAdditionalMapSettings(values, map)
      await onSubmit(submitValues)
    } else {
      setStepIndex((prev) => Math.min(prev + 1, TOTAL_STEPS_COUNT))
    }
  }

  return (
    <Wizard stepIndex={stepIndex} stepsCount={TOTAL_STEPS_COUNT} headerButton={headerButton}>
      <Form<GameForm> onSubmit={onSubmitInternal} validate={validate}>
        {({ handleSubmit, submitting }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            {stepIndex === 1 && <NewGameStep createGameOption={createOption} onChangeOption={setCreateOption} />}
            {stepIndex === 2 && <GameInfoStep />}
            {stepIndex === 3 && <GameBoardStep />}
            <Button type='submit' variant='big' disabled={submitting}>
              {t('common.continue', 'Continue')}
            </Button>
          </form>
        )}
      </Form>
    </Wizard>
  )
}
