import { Instructor, Maybe, Player } from '../types/commonTypes'

type RawPerson = {
  email: string
  gamer_accounts: []
  has_gamer_account: boolean
  id: number
  name: string
  player_email: Maybe<string>
  real_name: string
  session_id: Maybe<string>
  user_status: Maybe<string>
}

export type Person = Player | Instructor

export interface GetMessagingResponse
  extends Readonly<{
    /* eslint-disable camelcase */
    me: Instructor
    is_teacher: boolean
    messages: RawMessage[]
    players: Player[]
    instructors: Instructor[]
    shoutbox_open: boolean
    /* eslint-enable camelcase */
  }> {}

export interface RawMessage
  extends Readonly<{
    text: string
    from: RawPerson
    id: number
    created_at: Date
    type: MessageTypeEnum
    info: MessageSubtypeEnum
    to: Maybe<RawPerson>
    tmp_id: Maybe<number>
    msg_type: Maybe<MessageTypeEnum>
    points: Maybe<number>
    max_points: Maybe<number>
    subtype: Maybe<string>
    exercise_id: Maybe<number>
    exercise_name: Maybe<string>
    is_read: Maybe<boolean>
  }> {}

export enum MessageTypeEnum {
  CHAT = 1,
  NOTIFICATION = 2,
  SHOUTBOX_ENABLED = 3,
  SHOUTBOX_DISABLED = 4,
}

export enum MessageSubtypeEnum {
  GRADED_EXERCISE = 'graded-exercise',
  RETURNED_EXERCISE = 'returned-exercise',
  FLASH_OPENED = 'flash-opened',
  SHOUTBOX_MESSAGE = 'shoutbox-message',
  PRIVATE_MESSAGE = 'private-message',
  ALL_INSTRUCTORS_MESSAGE = 'all-instructors-message',
}

export interface SendChatMessageResponse
  extends Readonly<{
    // eslint-disable-next-line camelcase
    chat_update: Maybe<RawMessage>
  }> {}

export interface GetMessagingParams
  extends Readonly<{
    authorization?: string | null
    gameId: number
  }> {}

export interface SendChatMessageParams
  extends Readonly<{
    authorization?: string | null
    gameId: number
    message: string
    to: string
  }> {}

export interface DeleteChatMessageParams
  extends Readonly<{
    authorization?: string | null
    gameId: number
    messageId: number
  }> {}
