import { useTranslation } from 'react-i18next'
import { WelcomeBlockData } from '../helpers'
import { WelcomeBanner } from './WelcomeBanner'
import SvgWelcomeDay04Icon from '../../../common/components/icons/WelcomeDay04Icon'
import { helpButtonProps } from '../constants'

type NewDashboardWelcomeBannerProps = {
  onClose: () => void
}

export const NewDashboardWelcomeBanner: React.FC<NewDashboardWelcomeBannerProps> = ({ onClose }) => {
  const { t } = useTranslation()

  const newDashboardWelcomeData: Omit<WelcomeBlockData, 'id'> = {
    title: t('dashboard_message.welcome_to_new_dashboard.title', 'Welcome to the renewed dashboard!'),
    body: t(
      'dashboard_message.welcome_to_new_dashboard.body',
      'Below you will find your games. Use the navigation on the left to move between the pages, and find games with the search on the top. Need support? Check the help menu.',
    ),
    primaryButton: {
      label: t('dashboard_message.welcome_to_new_dashboard.primary_button', 'Open help'),
      ...helpButtonProps,
    },
  }

  return <WelcomeBanner onClose={onClose} icon={<SvgWelcomeDay04Icon />} data={newDashboardWelcomeData} />
}
