import { PropsWithChildren } from 'react'
import styles from '../GamesOverview.module.css'

export const LoaderWithOverlay: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className={styles.loaderBackgroundOverlay} />
      {/* TODO: if we decide to use multiple different loaders we can explicitly add them
          here instead of the children prop
      */}
      <div className={styles.loaderContainer}>{children}</div>
    </>
  )
}
