import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './AudioHandler.module.css'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { getIcon } from '../../../../../../common/components/icons/utils'
import { v4 as uuid } from 'uuid'
import { RoundButton } from '../../../../../../common/components/button/RoundButton'
import { uploadMedia } from '../../../../../../api/fileUploadApiService'
import { useNotification } from '../../../../../../contexts/NotificationContext'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Tooltip } from '../../../../../../common/components/tooltip/Tooltip'

type AudioHandlerProps = {
  onSubmitAudio: (audioUrl: string) => void
  onCancel: () => void
}

export const AudioHandler: React.FC<AudioHandlerProps> = ({ onSubmitAudio, onCancel }) => {
  const [audioBlob, setAudioBlob] = useState<Blob>()
  const { notifyError } = useNotification()
  const recorderControls = useAudioRecorder()
  const { t } = useTranslation()
  const recordingStarted = useRef(false)
  //Need isClosing to hide some things during closing delay, which is needed fot audiorecored to have time to unmount
  const isClosing = useRef(false)

  const handleSubmitAudioMessage = useCallback(async () => {
    const uniqueFileName = `${uuid()}.webm`
    if (audioBlob) {
      const response = await uploadMedia({ fileName: uniqueFileName, inputFile: audioBlob })
      if (response.success) {
        setAudioBlob(undefined)
        onSubmitAudio(response.value.url)
      } else {
        notifyError({
          title: t('game_editor.chat.audio_message_upload_failed.title', 'Sending failed'),
          content: t('game_editor.chat.audio_message_upload_failed.content', 'Failed to upload audio file for sending'),
        })
      }
    }
  }, [audioBlob, notifyError, onSubmitAudio, t])

  useEffect(() => {
    if (!recordingStarted.current) {
      recordingStarted.current = true
      recorderControls.startRecording()
    }
  }, [recorderControls])

  useEffect(() => {
    setAudioBlob(recorderControls.recordingBlob)
  }, [recorderControls.recordingBlob])

  const handleCloseAndCancelRecording = useCallback(() => {
    isClosing.current = true
    recorderControls.stopRecording()
    setTimeout(() => {
      onCancel()
    }, 250)
  }, [onCancel, recorderControls])

  return (
    <div className={classnames(styles.audioArea, !recorderControls.isRecording && styles.audioNotRecording)}>
      {!audioBlob && (
        <div className={styles.recorderAndInfoContainer}>
          <div className={styles.recordingText}>{t('game_editor.chat.audio_recording', 'Recording')}</div>
          <div className={styles.recorder}>
            <AudioRecorder
              audioTrackConstraints={{
                noiseSuppression: true,
                echoCancellation: true,
              }}
              downloadFileExtension='webm'
              showVisualizer={true}
              recorderControls={recorderControls}
            />
            {recorderControls.isRecording && (
              <Tooltip tooltipContent={t('game_editor.chat.audio_recording_stop_review', 'Stop to review')}>
                {(tooltipProps) => (
                  <div onClick={recorderControls.stopRecording} className={styles.audioStopButton} {...tooltipProps} />
                )}
              </Tooltip>
            )}
          </div>
        </div>
      )}
      {!isClosing.current && (
        <Tooltip tooltipContent={t('game_editor.chat.audio_recording_close_discard', 'Close to discard')}>
          {(tooltipProps) => (
            <div className={styles.closeAudioArea} onClick={handleCloseAndCancelRecording} {...tooltipProps}>
              {getIcon('close')}
            </div>
          )}
        </Tooltip>
      )}

      {audioBlob && !isClosing.current && (
        <>
          <audio src={URL.createObjectURL(audioBlob)} controls />
          <RoundButton
            style={{ height: '2.5rem' }}
            icon={'arrowRight'}
            variant='tiny'
            onClick={handleSubmitAudioMessage}
          />
        </>
      )}
    </div>
  )
}
