import classNames from 'classnames'
import React from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { SelectFormField } from '../../../common/components/Form/SelectFormField/SelectFormField'
import { SelectVariant } from '../../../common/components/Select/Select'
import shared from '../../../common/styles/shared.module.css'
import styles from '../OnboardingWizard.module.css'
import { COUNTRY_SELECT_OPTIONS } from '../constants'

export const CountryStep: React.FC = () => {
  const { t } = useTranslation()
  const { input } = useField('firstName')

  return (
    <>
      <div className={classNames(shared.flex, shared.col, shared.sAxisC)}>
        <h2>
          {t('onboarding_wizard.language_step.title', {
            first_name: input.value,
            defaultValue: 'Nice to meet you %{first_name}!',
          })}
        </h2>
        <div style={{ textAlign: 'center', padding: '1rem', width: '60%' }}>
          <h5 style={{ color: 'grey' }}>
            {t('onboarding_wizard.language_step.subtitle', 'Help us customise Seppo to suit your preferences')}
          </h5>
        </div>
      </div>
      <div className={styles.countryInput}>
        <h4>{t('onboarding_wizard.language_step.location', 'Where do you live?')}</h4>
        <SelectFormField
          autoFocus
          name='country'
          isSearchable
          options={COUNTRY_SELECT_OPTIONS}
          placeholder={t('onboarding_wizard.language_step.select_placeholder', 'Type or select country')}
          label={t('onboarding_wizard.language_step.location', 'Where do you live?')}
          srOnlyLabel
          variant={SelectVariant.XLarge}
        />
      </div>
    </>
  )
}
