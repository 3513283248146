import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapType } from '../../../../../../../api/gameTypes'
import { Button } from '../../../../../../../common/components/button/Button'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { useConfirmation } from '../../../../../../../contexts/ConfirmationContext'
import { useGame } from '../../../../../../../contexts/GameContext'
import { useNotification } from '../../../../../../../contexts/NotificationContext'
import { useDebounce } from '../../../../../../../hooks/useDebounce'
import { DeepPartial, Game, LevelCriteria, Task } from '../../../../../../../types/commonTypes'
import { addPinHighlight, removePinHighlight } from '../../../../Marker/TaskPinHelper'
import commonStyles from '../../../EditorSidebar.module.css'
import { LevelTasksMap, TaskAction, TaskActionData, TaskActionFn } from '../../types'
import { SimpleTaskCard } from '../TaskCard/SimpleTaskCard'
import { EmptyBoards } from './EmptyBoards'
import { LevelModal } from './LevelModal'
import { LevelTasks } from './LevelTasks'
import { LevelZeroTasks } from './LevelZeroTasks'
import styles from './LevelsList.module.css'
import {
  changeTaskBoard,
  changeTaskLevelAndBoard,
  changeTaskOrderInGroup,
  getLevelsTaskData,
  getMaxLevel,
  getPreviousLevelsDataMap,
  getTasksMap,
} from './helpers'

type LevelsListProps = {
  game: Game
  isCompact: boolean
  activeBoardIndex: number
  showAllBoards: boolean
  viewOnly: boolean
  onSetActiveBoard: (id: number) => void
  onTaskAction: TaskActionFn
  onSetShowDragCancel: (show: boolean) => void
  onAddTask: (initialTask?: DeepPartial<Task>) => void
}

const doesTaskBelongToBoard = (task: Task, boardIndex: number) => {
  return task.mapIndex === boardIndex && !task.advanced.isFlash && task.level !== 0
}

export const LevelsList: React.FC<LevelsListProps> = ({
  game,
  activeBoardIndex,
  isCompact,
  showAllBoards,
  viewOnly,
  onTaskAction,
  onSetActiveBoard,
  onSetShowDragCancel,
  onAddTask,
}) => {
  const { t } = useTranslation()

  const { notifyError, notifySuccess, notifyWarning } = useNotification()
  const { requestConfirmation } = useConfirmation()
  const { updateLevels, updateTasksOrder, gameUpdateMeta } = useGame()

  const [editLevelIndex, setEditLevelIndex] = useState<number>()
  const [isLevelModalOpen, setIsLevelModalOpen] = useState<boolean>(false)

  const [tasksMap, setTasksMap] = useState<LevelTasksMap>(getTasksMap(game))
  const tasksMapOriginal = useRef<LevelTasksMap>(tasksMap)
  const debouncedTasksMap = useDebounce(tasksMap, 1_000)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const tasksMapDragStartCopy = useRef<LevelTasksMap>()
  const draggingTask = useRef<Task>()
  const [dragStartTask, setDragStartTask] = useState<Task>()
  const shouldSubmitOrderUpdate = useRef<boolean>(false)
  const didMount = useRef<boolean>(false)

  const gameBoards = useMemo(
    () => (game.gameBoardSettings.gameBoardType === MapType.LIVE ? [] : game.gameBoardSettings.gameBoards),
    [game.gameBoardSettings],
  )

  const activeLevelsCriteria = useMemo(
    () => (game.levelsCriteria || [])?.filter((criteria) => criteria.isActive),
    [game.levelsCriteria],
  )

  const previousLevelsDataMap = useMemo(() => getPreviousLevelsDataMap(game), [game])

  const emptyBoards = useMemo(() => {
    return !showAllBoards && gameBoards.length > 1
      ? gameBoards.filter(
          (gb) =>
            !(
              game.tasks.some((t) => doesTaskBelongToBoard(t, gb.mapIndex ?? 0)) ||
              // default level boards will be shown in respective level(s), even without tasks - no need to include in empty boards
              activeLevelsCriteria.some((lc) => lc.defaultBoardIndex === gb.mapIndex)
            ),
        )
      : null
  }, [showAllBoards, gameBoards, game.tasks, activeLevelsCriteria])

  useEffect(() => {
    if (didMount.current && gameUpdateMeta?.lastUpdateType !== 'manual-reordering') {
      const newMap = getTasksMap(game)
      setTasksMap(newMap)
      tasksMapOriginal.current = newMap
    }
  }, [game, gameUpdateMeta])

  useEffect(() => {
    didMount.current = true
  }, [])

  const onClickAddLevel = () => setIsLevelModalOpen(true)

  const onClickEditLevel = useCallback((levelIndex: number) => {
    setEditLevelIndex(levelIndex)
    setIsLevelModalOpen(true)
  }, [])

  const updateLevelsCriteria = useCallback(
    async (criteria: LevelCriteria[]) => {
      const result = await updateLevels(criteria)
      if (result) {
        notifySuccess({
          title: t('game_editor.levels.update_settings_success_notification.title', 'Level updated'),
          content: t(
            'game_editor.levels.update_settings_success_notification.content',
            'Level settings updated successfully.',
          ),
        })
        setIsLevelModalOpen(false)
        setEditLevelIndex(undefined)
      } else {
        notifyError({
          title: t('game_editor.levels.update_settings_failure_notification.title', 'Failed to update level'),
          content: t(
            'game_editor.levels.update_settings_failure_notification.content',
            'An error occurred while updating level settings. Please try again or contact us for support.',
          ),
        })
      }
    },
    [notifySuccess, notifyError, t, updateLevels],
  )

  const onCloseLevelModal = useCallback(
    async (hasUnsavedChanges: boolean) => {
      if (hasUnsavedChanges) {
        const closingConfirmed = await requestConfirmation({
          title: t('game_editor.levels.unsaved_changes_confirmation_title', 'Unsaved changes'),
          text: t(
            'game_editor.levels.unsaved_changes_confirmation_text',
            'Are you sure you want to exit? Unsaved changes will be lost.',
          ),
        })
        if (!closingConfirmed) {
          return
        }
      }
      setIsLevelModalOpen(false)
      setEditLevelIndex(undefined)
    },
    [requestConfirmation, t],
  )

  const onClickDeleteLevel = useCallback(
    async (levelIndex: number) => {
      if (levelIndex > 1 && game.levelsCriteria) {
        const hasHigherLevel = game.levelsCriteria.some((lc, index) => index > levelIndex && lc.isActive)
        if (hasHigherLevel || game.tasks.some((task) => !task.advanced.isFlash && task.level === levelIndex)) {
          notifyWarning({
            title: t('game_editor.levels.remove_level_warning_notification.title', 'Cannot remove level'),
            content: hasHigherLevel
              ? t(
                  'game_editor.levels.remove_level_warning_notification.content_has_higher_level',
                  'Level can not be removed because a higher level was added already. Remove any higher levels before removing the current one.',
                )
              : t(
                  'game_editor.levels.remove_level_warning_notification.content_has_tasks',
                  'Level can not be removed because it has tasks. Move tasks to a previous level first.',
                ),
          })
        } else {
          const newCriteria = [...game.levelsCriteria]
          // we cannot remove level criteria, but we treat a level as inactive if it has no tasks and name is empty
          newCriteria[levelIndex] = { ...newCriteria[levelIndex], name: '' }
          const result = await updateLevels(newCriteria)
          if (result) {
            notifySuccess({
              title: t('game_editor.levels.remove_level_success_notification.title', 'Level removed'),
              content: t(
                'game_editor.levels.remove_level_success_notification.content',
                'Level was removed successfully.',
              ),
            })
          } else {
            notifyError({
              title: t('game_editor.levels.remove_level_failure_notification.title', 'Failed to remove level'),
              content: t(
                'game_editor.levels.remove_level_failure_notification.content',
                'An error occurred while removing level. Please try again or contact us for support.',
              ),
            })
          }
        }
      }
    },
    [notifyWarning, notifyError, notifySuccess, t, game, updateLevels],
  )

  useEffect(() => {
    if (shouldSubmitOrderUpdate.current) {
      shouldSubmitOrderUpdate.current = false
      updateTasksOrder(getLevelsTaskData(debouncedTasksMap)).then((success) => {
        if (!success) {
          requestConfirmation({
            title: t('game_editor.ordering.order_failed_confirmation.title', 'Reordering failed'),
            text: t(
              'game_editor.ordering.order_failed_confirmation.text',
              'An error occurred while reordering tasks. You can try setting the new order again by clicking retry, or revert to old order by clicking revert.',
            ),
            cancelActionText: t('game_editor.ordering.order_failed_confirmation.action_revert', 'Revert'),
            confirmActionText: t('game_editor.ordering.order_failed_confirmation.action_retry', 'Retry'),
          }).then((shouldRetry) => {
            if (shouldRetry) {
              shouldSubmitOrderUpdate.current = true
              setTasksMap((prev) => ({ ...prev }))
            } else {
              setTasksMap(tasksMapOriginal.current)
            }
          })
        }
      })
    }
  }, [updateTasksOrder, debouncedTasksMap, requestConfirmation, t])

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      draggingTask.current = event.active.data.current?.task as Task
      setDragStartTask(event.active.data.current?.task as Task)
      tasksMapDragStartCopy.current = { ...tasksMap }
      setIsDragging(true)
      onSetShowDragCancel(true)
      setTimeout(() => addPinHighlight(parseInt(event.active.id.toString())), 0)
    },
    [tasksMap, onSetShowDragCancel],
  )

  const handleDragCancel = useCallback(
    (event: any) => {
      setIsDragging(false)
      onSetShowDragCancel(false)
      tasksMapDragStartCopy.current = undefined
      draggingTask.current = undefined
      setDragStartTask(undefined)
      removePinHighlight(parseInt(event.active.id.toString()))
    },
    [onSetShowDragCancel],
  )

  // move dragged task to the level & board group it's currently being dragged over
  const handleDragOver = useCallback((event: DragOverEvent) => {
    if (event.over != null && draggingTask.current) {
      const activeTask = draggingTask.current
      const overTask = event.over.data.current?.task as Task
      if (activeTask.level !== overTask.level) {
        setTasksMap((prev) => changeTaskLevelAndBoard(prev, activeTask, overTask.level ?? 1, overTask.mapIndex ?? 0))
        draggingTask.current = {
          ...activeTask,
          level: overTask.level,
          mapIndex: overTask.mapIndex,
        }
      } else if (activeTask.mapIndex !== overTask.mapIndex) {
        setTasksMap((prev) => changeTaskBoard(prev, activeTask, overTask.mapIndex ?? 0))
        draggingTask.current = {
          ...activeTask,
          mapIndex: overTask.mapIndex,
        }
      }
    }
  }, [])

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (event.over == null && tasksMapDragStartCopy.current != null) {
        // revert to state when drag started, if task is dropped outside
        setTasksMap(tasksMapDragStartCopy.current)
      } else if (event.over != null && draggingTask.current != null) {
        shouldSubmitOrderUpdate.current = true
        const oldIndex = event.active.data.current?.sortable?.index
        const newIndex = event.over.data.current?.sortable?.index
        if (newIndex != null && newIndex !== oldIndex) {
          // place the task on correct position inside the new group
          setTasksMap((prev) =>
            draggingTask.current == null
              ? prev
              : changeTaskOrderInGroup(prev, draggingTask.current, oldIndex, newIndex),
          )
        } else {
          // trigger an update to submit moving the task to the bottom of a new group
          setTasksMap((prev) => ({ ...prev }))
        }
      }
      setIsDragging(false)
      onSetShowDragCancel(false)
      tasksMapDragStartCopy.current = undefined
      draggingTask.current = undefined
      setDragStartTask(undefined)
      removePinHighlight(parseInt(event.active.id.toString()))
    },
    [onSetShowDragCancel],
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )

  const onTaskActionInternal = useCallback(
    (actionData: TaskActionData) => {
      const task = actionData.task
      // moving level, board or order reorders all tasks when we have levels
      switch (actionData.action) {
        case TaskAction.MOVE_BOARD:
          shouldSubmitOrderUpdate.current = true
          const newMapIndex = actionData.moveIndex ?? 0
          setTasksMap((prev) => changeTaskBoard(prev, task, newMapIndex))
          break
        case TaskAction.MOVE_LEVEL:
          shouldSubmitOrderUpdate.current = true
          const newLevelIndex = actionData.moveIndex ?? 1
          setTasksMap((prev) => changeTaskLevelAndBoard(prev, task, newLevelIndex, task.mapIndex ?? 0))
          break
        case TaskAction.MOVE_ORDER:
          shouldSubmitOrderUpdate.current = true
          setTasksMap((prev) => {
            const oldIndex = prev[task.level ?? 1][task.mapIndex ?? 0].findIndex((aTask) => aTask.id === task.id)
            const newIndex = actionData.moveIndex ?? 0
            return changeTaskOrderInGroup(prev, task, oldIndex, newIndex)
          })
          break
        default:
          onTaskAction(actionData)
      }
    },
    [onTaskAction],
  )

  return (
    <>
      {isLevelModalOpen && (
        <LevelModal
          game={game}
          levelIndex={editLevelIndex ?? activeLevelsCriteria.length}
          isEdit={editLevelIndex != null}
          previousLevelsData={previousLevelsDataMap?.[editLevelIndex ?? activeLevelsCriteria.length]}
          onSubmit={updateLevelsCriteria}
          onClose={onCloseLevelModal}
        />
      )}
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragCancel={handleDragCancel}
        sensors={sensors}
      >
        <div className={styles.levelsList}>
          {activeLevelsCriteria.map((levelCriteria, levelIndex) => {
            return levelIndex === 0 ? (
              <LevelZeroTasks
                key={`levelTasks_${levelIndex}`}
                tasks={tasksMap[0][0]}
                compact={isCompact}
                game={game}
                onTaskAction={onTaskActionInternal}
                maxLevelIndex={activeLevelsCriteria.length - 1}
                onAddTask={onAddTask}
                viewOnly={viewOnly}
              />
            ) : (
              <LevelTasks
                key={`levelTasks_${levelIndex}`}
                levelIndex={levelIndex}
                maxLevelIndex={activeLevelsCriteria.length - 1}
                levelCriteria={levelCriteria}
                activeBoardIndex={activeBoardIndex}
                isCompact={isCompact}
                noPointsGame={game.advancedSettings.noPointsGame}
                gameBoards={gameBoards}
                onTaskAction={onTaskActionInternal}
                onSetActiveBoard={onSetActiveBoard}
                tasksMap={tasksMap}
                isDragging={isDragging}
                onClickEdit={onClickEditLevel}
                onClickRemove={onClickDeleteLevel}
                previousLevelsData={previousLevelsDataMap?.[levelIndex]}
                orderingEnabled={game.advancedSettings.orderingEnabled}
                explorationMode={game.advancedSettings.explorationMode}
                showAllBoards={showAllBoards}
                dragStartTask={dragStartTask}
                onAddTask={onAddTask}
                viewOnly={viewOnly}
              />
            )
          })}
        </div>
        <DragOverlay>
          {isDragging && draggingTask.current ? (
            <SimpleTaskCard
              task={draggingTask.current}
              compact={isCompact}
              showLevel
              noPointsGame={game.advancedSettings.noPointsGame}
              showOrder={game.advancedSettings.orderingEnabled}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
      {emptyBoards && emptyBoards.length > 0 && (
        <div>
          <EmptyBoards boards={emptyBoards} onSetActiveBoard={onSetActiveBoard} activeBoardIndex={activeBoardIndex} />
        </div>
      )}
      {activeLevelsCriteria.length < getMaxLevel(game) && (
        <Button variant='outline-normal' onClick={onClickAddLevel} className={commonStyles.sidebarCentredButton}>
          <span className='iconWrapperBig'>{getIcon('levelAdd')}</span>
          {t('game_editor.sidebar.build.tasks.add_level_button', 'Add level')}
        </Button>
      )}
    </>
  )
}
