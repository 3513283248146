import * as React from 'react'
import { SVGProps } from 'react'
const SvgLogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27 24.001a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3h-6a1 1 0 1 1 0-2h6a1 1 0 0 0 1-1v-2a1 1 0 0 1 1-1ZM18 5.001a1 1 0 0 1 1-1h6a3 3 0 0 1 3 3v2a1 1 0 1 1-2 0v-2a1 1 0 0 0-1-1h-6a1 1 0 0 1-1-1ZM14 2v-.002V2Zm0-.003v.001Zm0 .004V30L2 28.287V4.962l.03-.007L14 2ZM13.58.045A1.998 1.998 0 0 1 16 2v28a2 2 0 0 1-2.283 1.98L1.72 30.265A1.998 1.998 0 0 1 0 28.286V4.962A2 2 0 0 1 1.564 3.01L13.55.052l.03-.007Z'
      fill={props.color ?? '#6F6F6F'}
    />
    <path
      d='M24.707 13.707a1 1 0 0 0-1.414-1.414l-4 4-.008.008a.996.996 0 0 0-.043 1.351c.018.021.037.041.056.06l3.995 3.995a1 1 0 0 0 1.414-1.414L22.414 18H31a1 1 0 1 0 0-2h-8.586l2.293-2.293ZM10.322 14.512c.058-.01.117-.016.178-.016h.008c.821.004 1.494.67 1.492 1.502V16a1.5 1.5 0 1 1-1.678-1.49Z'
      fill={props.color ?? '#6F6F6F'}
    />
  </svg>
)
export default SvgLogoutIcon
