import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../common/components/PopupMenu/PopupActionMenu'
import { Button } from '../../../../../common/components/button/Button'
import { getIcon } from '../../../../../common/components/icons/utils'
import { EditorPermissions, Player, Task } from '../../../../../types/commonTypes'
import { isNumeric } from '../../../../../util/number'
import { safeIsNullOrEmpty } from '../../../../../util/string'
import styles from '../AddPerson.module.css'

type PlayersListProps = {
  players: Player[]
  tasks: Task[]
  isOpen: boolean
  isOrdered: boolean
  permissions: EditorPermissions
  onManageAnswersClick?: () => void
  onEditPlayerDetails: (player: Player) => void
  onDefineStartingTask: (player: Player) => void
  onDelete: (playerId: number, nickname: string) => void
}

export const PLAYER_LIST_ID = 'scrollable_player_list_container'

export const PlayersList: React.FC<PlayersListProps> = ({
  players,
  tasks,
  isOpen,
  isOrdered,
  permissions,
  onManageAnswersClick,
  onEditPlayerDetails,
  onDefineStartingTask,
  onDelete,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        permissions.actions.addPlayers ? styles.playersContainerColumn : styles.playersContainerFull,
        styles.playersListContainer,
      )}
      id={PLAYER_LIST_ID}
    >
      {!permissions.actions.addPlayers && (
        <div className={styles.addPlayersInfo}>
          {t(
            'game_editor.add_people.only_main_instructor_can_add_players_info',
            'Main instructor can add new players to the game',
          )}
        </div>
      )}
      <div className={classNames(styles.addPeopleSubtitle)}>
        {t('game_editor.add_people.players_list_title', 'Players currently added')}
      </div>
      {players.length > 0 && onManageAnswersClick != null && (
        <Button variant='outline-tiny' className={styles.manageButton} onClick={onManageAnswersClick}>
          {t('game_editor.add_people.manage_players', 'Manage players and task answers')}
        </Button>
      )}
      {!isOpen && (
        <div className={styles.gameNotStartedInfo}>
          {t('game_editor.add_people.game_not_started', 'Game not started')}
        </div>
      )}
      {players.length > 0 ? (
        <ul className={styles.playersList}>
          {players.map((player, index) => {
            const startingTaskName = !safeIsNullOrEmpty(player.startingTask)
              ? tasks.find((t) => t.id === player.startingTask)?.name
              : null
            const doNotShowDuplicateInfo = player.nickName.trim() === player.teamMemberNames.trim()
            return (
              <li key={`${player}${index}`} className={styles.listItem}>
                <span className={classNames(styles.indexStyle, styles.playerIndex)}>{index + 1}</span>
                <div className={styles.listContent}>
                  <span className={styles.listItemTitle}>
                    {!safeIsNullOrEmpty(player.nickName) ? player.nickName : player.name}
                  </span>
                  {!safeIsNullOrEmpty(player.playerCode) &&
                    (!player.isEmailTrusted || isNumeric(player.playerCode)) && (
                      <span className={styles.listItemMidtitle}>{player.playerCode}</span>
                    )}
                  <span className={player.isEmailTrusted ? styles.trustedEmail : styles.notTrustedEmail}>
                    {!safeIsNullOrEmpty(player.email) && player.email}
                  </span>
                  {!safeIsNullOrEmpty(player.teamMemberNames) && !doNotShowDuplicateInfo && (
                    <span className={styles.listItemSubtitle}>{player.teamMemberNames}</span>
                  )}
                  {startingTaskName && (
                    <div className={styles.startingTaskRow}>
                      <span>{getIcon('pinPlain')}</span>
                      <span className={styles.listItemSubtitle}>{startingTaskName}</span>
                    </div>
                  )}
                </div>
                {permissions.actions.modifyPlayers && (
                  <PopupActionMenu id={`${player.id}_actionsMenu`} position='inline-down'>
                    <PopupActionMenuItem
                      icon='copy'
                      text={t('game_editor.add_people.edit_player', 'Edit player details')}
                      onClick={() => onEditPlayerDetails(player)}
                    />
                    {isOrdered && (
                      <PopupActionMenuItem
                        icon='pinPlain'
                        text={t('game_editor.add_people.define_starting_task', 'Define starting task')}
                        onClick={() => onDefineStartingTask(player)}
                      />
                    )}
                    <PopupActionMenuItem
                      icon='trash'
                      text={t('common.remove', 'Remove')}
                      onClick={() => onDelete(player.id, player.nickName)}
                    />
                  </PopupActionMenu>
                )}
              </li>
            )
          })}
        </ul>
      ) : (
        <span className={styles.emptyList}>{t('game_editor.add_people.no_players', 'No players added yet')}</span>
      )}
    </div>
  )
}
