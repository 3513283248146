import { getConvertedMediaUrl } from '../../../../common/components/Form/EditorFormField/helpers'
import { ChatRoom, ChatRoomDictionary } from '../../../../contexts/ChatContextProvider'
import { TMessage } from '../../../../types/commonTypes'
import { ChatTypeEnum } from './components/Header/Header'

export const getAudioChatMessage = (audioUrl: string) => {
  const convertedUrl = getConvertedMediaUrl(audioUrl)
  return (
    "<div style='display: inline-flex'><audio controls controlsList='nodownload' class='answer-audio-area' width='240' height='180'>" +
    `<source src='${convertedUrl}' type='audio/mp4'/>` +
    `<source src='${audioUrl}' type='audio/webm'/>` +
    "</audio><div class='audio-reload-button'><img src='https://d29yn363rv6obi.cloudfront.net/assets/reload-f21b86496c9e644eb3d21bc6f83fac2af2f08b23233ee13f7258cc6c73dc4a5c.png'/></div></div>"
  )
}

export const getInitialsFromName = (name: string = '') => {
  const nameStrings: string[] = name.split(' ')
  if (nameStrings.length > 1) {
    return `${nameStrings[0].substring(0, 1)}${nameStrings[nameStrings.length - 1].substring(0, 1)}`
  }
  return `${name.substring(0, 2)}`
}

export const getDirectChat = (userId: number, selectedPersonId: number, messages: TMessage[]) => {
  const directChat = messages.reduce((acc: TMessage[], current: TMessage) => {
    if (current.to != null) {
      if (
        (current.to.id === selectedPersonId && current.from.id === userId) ||
        (current.to.id === userId && current.from.id === selectedPersonId)
      ) {
        return [...acc, current]
      }
    }
    return acc
  }, [])
  return directChat
}

export const areThereNewMessages = (chatRooms: ChatRoomDictionary, chatType?: ChatTypeEnum) => {
  if (chatType === ChatTypeEnum.GENERAL) {
    return chatRooms[0].hasUnreadMessages ?? false
  }
  if (chatType === ChatTypeEnum.DIRECT) {
    const { 0: _, ...directChatRooms } = chatRooms
    return Object.keys(directChatRooms).some((roomId) => directChatRooms[parseInt(roomId)].hasUnreadMessages) ?? false
  }
  return Object.keys(chatRooms).some((roomId) => chatRooms[parseInt(roomId)].hasUnreadMessages) ?? false
}

const isNewMessage = (lastSeen: string | undefined, dateToCheck: Date) => {
  if (lastSeen != null) {
    return new Date(dateToCheck).getTime() - new Date(lastSeen).getTime() > 0
  }
  return true
}

export const newMessagesCount = (chatRoom: ChatRoom) => {
  const { lastSeen, roomMessages } = chatRoom
  if (lastSeen != null) {
    const newMessages = roomMessages.filter(({ createdAt }) => isNewMessage(lastSeen, createdAt))
    return newMessages.length > 0 ? newMessages.length : undefined
  }
}

export const newCombinedDMCount = (chatRooms: ChatRoomDictionary) => {
  const { 0: _, ...directChatRooms } = chatRooms
  const newMessages = Object.keys(chatRooms)
    .map((roomId) => {
      if (directChatRooms[parseInt(roomId)] != null) {
        return directChatRooms[parseInt(roomId)].roomMessages.filter(({ createdAt }) =>
          isNewMessage(directChatRooms[parseInt(roomId)].lastSeen, createdAt),
        )
      }
      return []
    })
    .flat()
  return newMessages.length > 0 ? newMessages.length : undefined
}
