import classNames from 'classnames'
import { getIcon } from '../../../../../../../../common/components/icons/utils'
import styles from './AnswerStatusIcon.module.css'

export enum AnswerStatus {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  NEUTRAL = 'NEUTRAL',
}

type AnswerStatusIconProps = {
  status: AnswerStatus
}

const getPillContentForStatus = (status: AnswerStatus): JSX.Element | string | null => {
  switch (status) {
    case AnswerStatus.SUCCESS:
      return getIcon('circleCheckmark')
    case AnswerStatus.WARNING:
      return '!'
    case AnswerStatus.ERROR:
      return getIcon('close')
    default:
      return null
  }
}

export const AnswerStatusIcon: React.FC<AnswerStatusIconProps> = ({ status }) => {
  return status === AnswerStatus.NEUTRAL ? null : (
    <span className={classNames(styles.answerStatusIcon, styles[`answerStatusIcon_${status}`])}>
      {getPillContentForStatus(status)}
    </span>
  )
}
