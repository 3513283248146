import classNames from 'classnames'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { MapType } from '../../../../../../api/gameTypes'
import { InputFormField } from '../../../../../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../../../../../common/components/Form/SelectFormField/SelectFormField'
import { SelectElement, SelectVariant } from '../../../../../../common/components/Select/Select'
import { Game, LevelCriteria } from '../../../../../../types/commonTypes'
import styles from './TaskInformation.module.css'

type TaskInformationProps = {
  game: Game
  viewOnly: boolean
  level?: number
}

const LEVEL_SELECT_ICON: SelectElement = { type: 'icon', icon: { name: 'levelGeneric' } }
const BOARD_SELECT_ICON: SelectElement = { type: 'icon', icon: { name: 'photo' } }

const getLevelOptions = (levelsCriteria: LevelCriteria[], t: TFunction) => {
  return levelsCriteria
    .filter((lc) => lc.isActive)
    .map((_, index) => {
      return index === 0
        ? { label: t('game_editor.tasks.level_option_first_tasks', 'First tasks'), value: 0 }
        : {
            label: t('game_editor.levels.level_with_number', {
              defaultValue: 'Level %{level_number}',
              level_number: index,
            }),
            value: index,
          }
    })
}

export const TaskInformation: React.FC<TaskInformationProps> = ({ game, viewOnly, level }) => {
  const { t } = useTranslation()

  const levelOptions =
    game.advancedSettings.levelsEnabled && game.levelsCriteria != null ? getLevelOptions(game.levelsCriteria, t) : []

  const showBoardsSelect =
    game.gameBoardSettings.gameBoardType !== MapType.LIVE && game.gameBoardSettings.gameBoards.length > 1
  const boardOptions = showBoardsSelect
    ? game.gameBoardSettings.gameBoards.map((board) => ({
        label:
          level === 0
            ? t('game_editor.game_boards.no_board', 'No board')
            : t('game_editor.game_boards.board', 'Board') + ' ' + ((board.mapIndex ?? 0) + 1),
        value: board.mapIndex ?? 0,
      }))
    : []

  return (
    <div
      className={classNames(
        styles.taskInformation,
        game.advancedSettings.levelsEnabled && showBoardsSelect && styles.taskInformation_withBoardsLevels,
      )}
    >
      <InputFormField
        name='name'
        label={t('game_editor.tasks.task_information.name_label', 'Name')}
        disabled={viewOnly}
      />
      {game.advancedSettings.levelsEnabled && (
        <SelectFormField<number>
          name='level'
          label={t('game_editor.tasks.task_information.level_label', 'Level')}
          options={levelOptions}
          variant={SelectVariant.XLarge}
          prefixElement={LEVEL_SELECT_ICON}
          disabled={viewOnly}
        />
      )}
      {showBoardsSelect && (
        <SelectFormField<number>
          name='mapIndex'
          label={t('game_editor.tasks.task_information.board_label', 'Board')}
          options={boardOptions}
          variant={SelectVariant.XLarge}
          prefixElement={BOARD_SELECT_ICON}
          disabled={viewOnly || level === 0}
        />
      )}
    </div>
  )
}
