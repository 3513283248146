import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from 'use-onclickoutside'
import { getIcon } from '../../../../common/components/icons/utils'
import { SelectableOption } from '../../../../common/components/selectable/SelectableOption'
import { CONFIRMATION_DIALOG_ID_PREFIX } from '../../../../composites/confirmationDialog/constants'
import { useGame } from '../../../../contexts/GameContext'
import { NOTIFICATION_DISMISS_BUTTON_ID_PREFIX } from '../../../../contexts/NotificationContext'
import { ADD_PEOPLE_BUTTON_ID } from '../../constants'
import { StartGameButton } from '../StartGameButton/StartGameButton'
import styles from './AddPerson.module.css'
import { InstructorsSelected } from './components/InstructorsSelected'
import { PlayersSelected } from './components/PlayersSelected'

type AddPersonProps = {
  onClose: () => void
  onManageAnswersClick?: () => void
  onOpenGameSettings: () => void
}

export const AddPerson: React.FC<AddPersonProps> = ({ onClose, onManageAnswersClick, onOpenGameSettings }) => {
  const { t } = useTranslation()
  const { isEdit, editorPermissions } = useGame()
  const [isPlayerOrInst, setIsPlayerOrInst] = useState('player')
  const updateSelectedOption = (selectedOption: string) => {
    setIsPlayerOrInst(selectedOption)
  }
  const addPeopleRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(addPeopleRef, (e: any) => {
    const targetId = (e.target as any)?.getAttribute?.('id')
    if (
      targetId?.startsWith(NOTIFICATION_DISMISS_BUTTON_ID_PREFIX) ||
      targetId?.startsWith(CONFIRMATION_DIALOG_ID_PREFIX) ||
      clickedElementShouldNotClose(e.target) ||
      targetId === ADD_PEOPLE_BUTTON_ID
    ) {
      return
    }
    onClose()
  })

  if (!isEdit) {
    return (
      <div ref={addPeopleRef} className={classNames(styles.container, styles.noGameContainer)}>
        {t(
          'game_editor.add_people.create_game_first',
          'To manage players and instructors, make sure the game is set up in the settings',
        )}
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.container, !editorPermissions.actions.addPlayers && styles.container_singleCol)}
      ref={addPeopleRef}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <span className={styles.userIcon}>{getIcon('multipleUsers')}</span>
          <span className={styles.headerText}>{t('game_editor.add_people.title', 'Add people')}</span>
        </div>
        <StartGameButton />
      </div>

      {editorPermissions.actions.instructors && (
        <>
          <div className={styles.selectableContainer}>
            <SelectableOption
              text={t('game_editor.add_people.players', 'Players')}
              selectedOption={isPlayerOrInst}
              id={'player'}
              groupName={'playersOrInstructors'}
              onChange={updateSelectedOption}
            />
            <SelectableOption
              text={t('game_editor.add_people.instructors', 'Instructors')}
              selectedOption={isPlayerOrInst}
              id={'instructor'}
              groupName={'playersOrInstructors'}
              onChange={updateSelectedOption}
            />
          </div>
        </>
      )}
      <div className={styles.divider} />
      {isPlayerOrInst === 'player' && (
        <PlayersSelected onManageAnswersClick={onManageAnswersClick} onOpenGameSettings={onOpenGameSettings} />
      )}
      {isPlayerOrInst === 'instructor' && <InstructorsSelected />}
    </div>
  )
}

const ADD_MODAL_NO_CLOSE1 = 'PlayersAddManuallyModal'
const ADD_MODAL_NO_CLOSE2 = 'PlayersAddByPlayerAccountModal'
const ADD_MODAL_NO_CLOSE3 = 'placeholder'
const ADD_MODAL_NO_CLOSE4 = 'ValueContainer'
const SELECT_FIELD_X_CLASSNAME = 'indicatorContainer'
const clickedElementShouldNotClose = (element: HTMLElement): boolean => {
  let me: HTMLElement | null = element
  const meAndParents = []
  while (me) {
    meAndParents.push(me)
    me = me.parentElement
  }

  return !!meAndParents.find(
    (el) =>
      typeof el.className === 'string' &&
      (el.className?.includes(ADD_MODAL_NO_CLOSE1) ||
        el.className?.includes(ADD_MODAL_NO_CLOSE2) ||
        el.className?.includes(ADD_MODAL_NO_CLOSE3) ||
        el.className?.includes(ADD_MODAL_NO_CLOSE4) ||
        el.className?.includes(SELECT_FIELD_X_CLASSNAME)),
  )
}
