import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { SingleValue } from 'react-select'
import { RoundButton } from '../../../common/components/button/RoundButton'
import { PAGE_SIZE_OPTIONS } from '../../../contexts/OwnGamesContextProvider'
import styles from '../GamesOverview.module.css'

export type PaginationSelectOption = {
  value: number
  label: string
}

type PaginationControlsProps = {
  currentPage: number
  pageSize: number
  lastPage: number
  allPageOptions: PaginationSelectOption[]
  loading: boolean
  onChangeCurrentPage: (option: SingleValue<PaginationSelectOption>) => void
  onChangePageSize: (option: SingleValue<PaginationSelectOption>) => void
  onClickNext: () => void
  onClickPrev: () => void
}

export const PaginationControls: React.FC<PaginationControlsProps> = ({
  currentPage,
  pageSize,
  lastPage,
  allPageOptions,
  loading,
  onChangeCurrentPage,
  onChangePageSize,
  onClickNext,
  onClickPrev,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.paginationControlsContainer}>
      <div className={styles.leftColumnWrapper}>
        <span>{t('games_overview.pagination.show', 'Show')}</span>
        <ReactSelect
          isSearchable={false}
          options={PAGE_SIZE_OPTIONS}
          onChange={onChangePageSize}
          defaultValue={{ value: pageSize, label: `${pageSize}` } ?? PAGE_SIZE_OPTIONS[1]}
          menuPlacement='top'
          maxMenuHeight={200}
        />
        <span>{t('games_overview.pagination.games_per_page', 'games per page')}</span>
      </div>
      <div className={styles.rightColumnWrapper}>
        <RoundButton
          disabled={loading || currentPage === 1}
          icon={'arrowRight'}
          iconDirection={'left'}
          onClick={onClickPrev}
          className={styles.paginationButton}
        />
        <span style={{ color: currentPage === 1 ? 'var(--grey-600)' : 'var(--primary-color)' }}>
          {t('games_overview.pagination.previous', 'Previous')}
        </span>
        <span>{t('games_overview.pagination.page', 'Page:')}</span>
        <ReactSelect
          isSearchable={false}
          options={allPageOptions}
          onChange={onChangeCurrentPage}
          value={{ value: currentPage, label: `${currentPage}` }}
          defaultValue={{ value: currentPage, label: `${currentPage}` } ?? { value: 1, label: '1' }}
          menuPlacement='top'
          maxMenuHeight={200}
        />
        <span>
          {t('games_overview.pagination.of_last_page', { defaultValue: 'of %{last_page}', last_page: lastPage })}
        </span>
        <span style={{ color: currentPage !== lastPage ? 'var(--primary-color)' : 'var(--grey-600)' }}>
          {t('games_overview.pagination.next', 'Next')}
        </span>
        <RoundButton
          disabled={loading || lastPage === currentPage}
          icon={'arrowRight'}
          onClick={onClickNext}
          className={styles.paginationButton}
        />
      </div>
    </div>
  )
}
