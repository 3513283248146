import classNames from 'classnames'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { MapType } from '../../../../../../../api/gameTypes'
import { InputFormField } from '../../../../../../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../../../../../../common/components/Form/SelectFormField/SelectFormField'
import { Modal } from '../../../../../../../common/components/Modal/Modal'
import { SelectElement } from '../../../../../../../common/components/Select/Select'
import { Button } from '../../../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { useTrapFocus } from '../../../../../../../hooks/useTrapFocus'
import { DeepPartial, FormErrorType, Game, LevelCriteria } from '../../../../../../../types/commonTypes'
import { areObjectsEqual } from '../../../../../../../util/functional'
import { rangeValidation, requiredValidation } from '../../../../../../../util/validate'
import { PointsInput } from '../../../../Points/PointsInput'
import { LevelCriteriaForm, PreviousLevelsData } from '../../types'
import styles from './LevelModal.module.css'
import { getMaxLevel } from './helpers'

type LevelModalProps = {
  game: Game
  levelIndex: number
  isEdit: boolean
  previousLevelsData?: PreviousLevelsData
  onClose: (hasChanges: boolean) => void
  onSubmit: (levelsCriteria: LevelCriteria[]) => Promise<void>
}

const checkCriteriaWarning = (formValues: LevelCriteriaForm, levelIndex: number, levelsCriteria: LevelCriteria[]) => {
  return levelsCriteria.some(
    (lc, index) =>
      index > levelIndex &&
      lc.isActive &&
      (lc.completedTasks < formValues.completedTasks || lc.points < formValues.points),
  )
}

const getInitialValues = (
  levelsCriteria: LevelCriteria[],
  levelIndex: number,
  isEdit: boolean,
  explorationMode: boolean,
  t: TFunction,
): LevelCriteriaForm => {
  const curr = levelsCriteria?.[levelIndex]
  const prev = levelsCriteria?.[levelIndex - 1]
  return {
    name: isEdit
      ? curr?.name
      : t('game_editor.levels.level_with_number', {
          defaultValue: 'Level %{level_number}',
          level_number: levelIndex,
        }),
    points: (isEdit ? curr?.points : prev?.points) ?? 0,
    completedTasks: (isEdit ? curr?.completedTasks : prev?.completedTasks) ?? 0,
    defaultBoardIndex: explorationMode ? undefined : (isEdit ? curr?.defaultBoardIndex : 0) ?? 0,
  }
}

export const LevelModal: React.FC<LevelModalProps> = ({
  game,
  levelIndex,
  isEdit,
  previousLevelsData,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const ref = useTrapFocus<HTMLFormElement>()
  const [showWarning, setShowWarning] = useState<boolean>(false)

  const levelsCriteria = game.levelsCriteria || []

  const initialValues = getInitialValues(levelsCriteria, levelIndex, isEdit, game.advancedSettings.explorationMode, t)

  const onSubmitInternal = async (values: LevelCriteriaForm) => {
    const newValues = [...levelsCriteria]
    newValues[levelIndex] = { ...levelsCriteria[levelIndex], ...values, isActive: true }
    newValues.forEach((lc, index) => {
      if (index > levelIndex) {
        if (lc.points < values.points) {
          newValues[index].points = values.points
        }
        if (lc.completedTasks < values.completedTasks) {
          newValues[index].completedTasks = values.completedTasks
        }
      }
    })
    await onSubmit(newValues)
  }

  const validate = (values: DeepPartial<LevelCriteriaForm>): FormErrorType<LevelCriteriaForm> => {
    if (levelIndex === 0 || levelIndex > getMaxLevel(game) - 1) {
      return {}
    } else if (levelIndex === 1) {
      return {
        name: requiredValidation(values.name, t),
      }
    } else {
      const pointsMin = levelsCriteria[levelIndex - 1]?.points ?? 0
      const pointsMax = previousLevelsData?.pointsSum ?? 0
      const tasksMin = levelsCriteria[levelIndex - 1]?.completedTasks ?? 0
      const tasksMax = previousLevelsData?.tasksCount ?? 0
      return {
        name: requiredValidation(values.name, t),
        completedTasks: rangeValidation(values.completedTasks, tasksMin, tasksMax, t),
        ...(!game.advancedSettings.noPointsGame && { points: rangeValidation(values.points, pointsMin, pointsMax, t) }),
      }
    }
  }

  const BOARD_SELECT_ICON: SelectElement = { type: 'icon', icon: { name: 'photo' } }
  const showDefaultBoardSelect = !(
    game.advancedSettings.explorationMode ||
    game.gameBoardSettings.gameBoardType === MapType.LIVE ||
    game.gameBoardSettings.gameBoards.length <= 1
  )
  const defaultBoardOptions = showDefaultBoardSelect
    ? game.gameBoardSettings.gameBoards.map((board) => ({
        label: t('game_editor.game_boards.board', 'Board') + ' ' + ((board.mapIndex ?? 0) + 1),
        value: board.mapIndex ?? 0,
      }))
    : []

  return (
    <Modal>
      <Form<LevelCriteriaForm> initialValues={initialValues} onSubmit={onSubmitInternal} validate={validate}>
        {({ handleSubmit, submitting, initialValues, values }) => (
          <form className={styles.levelModal} ref={ref} onSubmit={handleSubmit}>
            <div className={styles.levelHeader}>
              <div className={styles.levelHeaderTitle}>
                <span>{getIcon('levelAdd')}</span>
                <span>
                  {isEdit
                    ? t('game_editor.levels.level_modal_title_edit', {
                        defaultValue: 'Edit level %{level_number} settings',
                        level_number: levelIndex,
                      })
                    : t('game_editor.levels.level_modal_title_add', {
                        defaultValue: 'Add level %{level_number}',
                        level_number: levelIndex,
                      })}
                </span>
              </div>
              <CloseButton autoFocus onClick={() => onClose(!areObjectsEqual(values, initialValues))} />
            </div>
            <div
              className={classNames(
                styles.levelMainSection,
                previousLevelsData != null && !showDefaultBoardSelect && styles.levelMainSection_morePadding,
              )}
            >
              <InputFormField
                name='name'
                label={t('game_editor.levels.name_placeholder', 'Level name')}
                placeholder={t('game_editor.levels.name_placeholder', 'Level name')}
                icon='levelGeneric'
                type='text'
                srOnlyLabel
                fieldContainerClassName={styles.nameInputContainer}
              />
              {previousLevelsData != null && (
                <>
                  <div className={styles.unlockRequirementsInfo}>
                    <h3 className='medium grey-900'>
                      {t('game_editor.levels.unlocking_requirements_title', 'Unlocking requirements')}
                    </h3>
                    <p className='small grey-700'>
                      {t(
                        'game_editor.levels.unlocking_requirements_info',
                        'Define what parameters need to be met for players to have access to this level',
                      )}
                      <br />
                      {game.advancedSettings.noPointsGame
                        ? t('game_editor.levels.previous_levels_tasks_only_info', {
                            defaultValue: 'Previous levels contain %{tasks_count} tasks',
                            tasks_count: previousLevelsData.tasksCount,
                          })
                        : t('game_editor.levels.previous_levels_tasks_and_points_info', {
                            defaultValue: 'Previous levels contain %{tasks_count} tasks and %{points_count} points',
                            tasks_count: previousLevelsData.tasksCount,
                            points_count: previousLevelsData.pointsSum,
                          })}
                    </p>
                  </div>
                  <div className={styles.tasksAndPointsContainer}>
                    <InputFormField
                      type='number'
                      label={t('game_editor.levels.required_tasks_accessibility_label', 'Number of required tasks')}
                      placeholder={t('game_editor.levels.required_tasks_placeholder', 'Required tasks')}
                      srOnlyLabel
                      name='completedTasks'
                      min={0}
                      max={previousLevelsData.tasksCount}
                      icon='exercisePin'
                      fieldContainerClassName={styles.tasksInputContainer}
                      errorClassName={styles.tasksInputError}
                    />
                    {!game.advancedSettings.noPointsGame && (
                      <>
                        <span>{t('common.and', 'and')}</span>
                        <PointsInput name='points' max={previousLevelsData.pointsSum} inputMinWidthOverride={130} />
                        <span>{t('common.points', 'points')}</span>
                      </>
                    )}
                  </div>
                  {showWarning && (
                    <p className={styles.requirementsWarning}>
                      <Trans
                        i18nKey='game_editor.levels.requirements_warning_higher_levels_update'
                        components={{ newline: <br /> }}
                      >
                        {
                          'Requirements are higher than next level requirements.<newline/>By clicking save, next level requirements with lower values will be updated as well.'
                        }
                      </Trans>
                    </p>
                  )}
                  <FormSpy<LevelCriteriaForm>
                    onChange={(props) =>
                      setShowWarning(props.dirty && checkCriteriaWarning(props.values, levelIndex, levelsCriteria))
                    }
                  />
                </>
              )}
              {showDefaultBoardSelect && (
                <>
                  <div>
                    <h3 className='medium grey-900'>
                      {t('game_editor.levels.default_board_title', 'Link level to board')}
                    </h3>
                    <p className='small grey-700'>
                      {t(
                        'game_editor.levels.default_board_info',
                        'Assign a specific board to show players when the level is unlocked',
                      )}
                    </p>
                  </div>
                  <SelectFormField<number>
                    name='defaultBoardIndex'
                    srOnlyLabel
                    label={t('game_editor.levels.default_board_label', 'Default board')}
                    options={defaultBoardOptions}
                    prefixElement={BOARD_SELECT_ICON}
                    fieldContainerClassName={styles.defaultBoardInputContainer}
                  />
                </>
              )}
            </div>
            <div className={styles.levelFooter}>
              <Button
                variant='outline-normal'
                disabled={submitting}
                onClick={() => onClose(!areObjectsEqual(values, initialValues))}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button type='submit' disabled={submitting}>
                {t('common.save', 'Save')}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Modal>
  )
}
