import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTaskFlashGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <g clipPath='url(#task_flash_gradient_icon_svg__a)'>
      <path
        fill='currentColor'
        fillOpacity={1}
        fillRule='evenodd'
        d='M18.272.034A1 1 0 0 1 19 .996v11h3.636a2.364 2.364 0 0 1 2.013 3.603L14.852 31.52A1 1 0 0 1 13 30.996v-11H9.364a2.364 2.364 0 0 1-2.013-3.601L17.148.472a1 1 0 0 1 1.124-.438ZM17 4.529 9.054 17.443a.363.363 0 0 0 .31.553H14a1 1 0 0 1 1 1v8.467l7.946-12.912a.364.364 0 0 0-.31-.555H18a1 1 0 0 1-1-1V4.529Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <linearGradient
        id='task_flash_gradient_icon_svg__b'
        x1={16}
        x2={16}
        y1={-0.004}
        y2={31.996}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <clipPath id='task_flash_gradient_icon_svg__a'>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgTaskFlashGradientIcon
