import { useTranslation } from 'react-i18next'
import { GameSortBy } from '../../../../contexts/OwnGamesContextProvider'
import { TFunction } from 'i18next'
import { Select, SelectOption, SelectVariant } from '../../../../common/components/Select/Select'
import { SingleValue } from 'react-select'
import styles from './GamesSort.module.css'

type GamesSortProps = {
  sortBy: GameSortBy
  onChange: (sortBy: GameSortBy) => void
}

const getSortByOptions = (t: TFunction): SelectOption[] => {
  return [
    {
      value: GameSortBy.UPDATED_AT_DESC,
      label: t('dashboard.sort_games_option.updated_at_desc', 'Updated at (newest first)'),
    },
    {
      value: GameSortBy.UPDATED_AT_ASC,
      label: t('dashboard.sort_games_option.updated_at_asc', 'Updated at (oldest first)'),
    },
    { value: GameSortBy.NAME_ASC, label: t('dashboard.sort_games_option.name_asc', 'Game name (A-Z)') },
    { value: GameSortBy.NAME_DESC, label: t('dashboard.sort_games_option.name_desc', 'Game name (Z-A)') },
  ]
}

export const GamesSort: React.FC<GamesSortProps> = ({ sortBy, onChange }) => {
  const { t } = useTranslation()
  const options = getSortByOptions(t)

  const selectedOption = options.find((o) => o.value === sortBy)
  const onChangeInternal = (sortByOption: SingleValue<SelectOption>) => {
    onChange(sortByOption?.value as GameSortBy)
  }

  return (
    <div className={styles.gamesSortContainer}>
      <span className='small'>{t('dashboard.sort_games_label', 'Sort games by:')}</span>
      <Select
        options={options}
        value={selectedOption}
        onChange={onChangeInternal}
        className={styles.gamesSortInput}
        variant={SelectVariant.Small}
      />
    </div>
  )
}
