import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { postEvaluator, postInstructor, postStudentInstructor } from '../../../../../api/gameApiService'
import { useConfirmation } from '../../../../../contexts/ConfirmationContext'
import { useGame } from '../../../../../contexts/GameContext'
import { useNotification } from '../../../../../contexts/NotificationContext'
import { useUser } from '../../../../../contexts/userContext'
import { AllowedOption } from '../../../../../types/commonTypes'
import { hasPermission } from '../../../../../util/permissions'
import { InstructorMenu } from './InstructorMenu'

export const InstructorsSelected: React.FC = () => {
  const { requestConfirmation } = useConfirmation()
  const { t } = useTranslation()
  const { people, gameData, removeInstructor } = useGame()
  const { user } = useUser()
  const instructors = people.instructors
  const evaluators = people.evaluators
  const studentInstructors = people.studentInstructors
  const gameId = gameData?.gameId ?? null
  const { notifyError } = useNotification()

  const notifyGenericError = (instructorType: string) => {
    notifyError({
      title: t(
        'game_editor.add_people.add_instructor_error_notification_generic_title',
        'Error while adding instructor',
      ),
      content: t('game_editor.add_people.add_instructor_error_notification_generic_content', {
        defaultValue: 'Failed to add %{instructor_type}. Please try again later or contact support',
        instructor_type: instructorType,
      }),
      timeout: 3_000,
    })
  }

  const handleDeleteInstructor = useCallback(
    async (name: string, displayName?: string) => {
      const confirmed = await requestConfirmation({
        title: t('game_editor.add_people.delete_instructor_confirmation_title', 'Deleting an instructor'),
        text: (
          <Trans
            i18nKey='game_editor.add_people.delete_person_confirmation_text'
            values={{ name: displayName ?? name }}
            components={{ bold: <b /> }}
          >
            {'Are you sure you want to remove <bold>%{name}</bold>?'}
          </Trans>
        ),
      })
      if (confirmed) {
        const result = await removeInstructor(name)
        if (!result.success) {
          notifyError({
            title: t(
              'game_editor.add_people.delete_instructor_error_notification_title',
              'Failed to remove instructor',
            ),
            content:
              result.errorMessage ??
              t(
                'game_editor.add_people.delete_instructor_error_notification_default_content',
                'An error occurred while deleting the instructor. Please try again or contact us for support.',
              ),
          })
        }
      }
    },
    [removeInstructor, requestConfirmation, notifyError, t],
  )

  const handleAddInstructor = async (userName: string) => {
    const response = await postInstructor({ gameId, userName })
    if (!response.success) {
      console.error(response.error)
      notifyGenericError('instructor')
      return false
    } else if (response.value.error !== undefined) {
      if (gameData?.editRestricted) {
        notifyError({
          title: t('game_editor.add_people.add_instructor_error_notification_restricted_title', 'Failed to add user'),
          content: response.value.msg,
          timeout: 3_000,
        })
      } else {
        notifyError({
          title: t('game_editor.add_people.add_instructor_error_notification_no_user_title', 'User not found'),
          content: t(
            'game_editor.add_people.add_instructor_error_notification_no_user_content',
            'Check the email address and try again',
          ),
          timeout: 3_000,
        })
      }
      return false
    }
    return true
  }

  const handleAddEvaluator = async (userName: string) => {
    const response = await postEvaluator({ gameId, userName, isGrader: true, isStudent: false })
    if (!response.success) {
      console.error(response.error)
      notifyGenericError('evaluator')
    }
    return response.success
  }

  const handleAddStudentInstructor = async (userName: string) => {
    const response = await postStudentInstructor({ gameId, userName, isStudent: true })
    if (!response.success) {
      console.error(response.error)
      notifyGenericError('student instructor')
    }
    return response.success
  }

  return (
    <div>
      <InstructorMenu
        label={t('game_editor.add_people.add_instructor', 'Add instructor')}
        menuLabel={t('game_editor.add_people.main_instructors', 'Main instructors')}
        placeholder={t('game_editor.add_people.email_field_placeholder', 'Type e-mail here')}
        icon={'email'}
        instructors={instructors}
        onAdd={handleAddInstructor}
        onDelete={handleDeleteInstructor}
        initialIsOpen={true}
      />
      {hasPermission(user, AllowedOption.GRADINGLICENCE) && (
        <InstructorMenu
          label={t('game_editor.add_people.add_evaluator', 'Add evaluator')}
          menuLabel={t('game_editor.add_people.evaluators', 'Evaluators')}
          placeholder={t('game_editor.add_people.name_field_placeholder', 'Type name')}
          icon={'copy'}
          instructors={evaluators}
          onAdd={handleAddEvaluator}
          onDelete={handleDeleteInstructor}
          initialIsOpen={false}
        />
      )}
      {hasPermission(user, AllowedOption.STUDENTLICENCE) && (
        <InstructorMenu
          label={t('game_editor.add_people.add_student_instructor', 'Add student instructor')}
          menuLabel={t('game_editor.add_people.student_instructors', 'Student instructors')}
          placeholder={t('game_editor.add_people.name_field_placeholder', 'Type name')}
          icon={'email'}
          instructors={studentInstructors}
          onAdd={handleAddStudentInstructor}
          onDelete={handleDeleteInstructor}
          initialIsOpen={false}
        />
      )}
    </div>
  )
}
