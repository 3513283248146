import { Exercise, LibrarySource } from '../../../../../../api/gameTypes'
import { parseTaskResponseToTaskVm } from '../../../../../../api/typeConverters'
import { Subtask, TUser } from '../../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../../util/string'
import { LibTaskMap, LibTaskSelectedMap, TaskLibrarySearchParams } from './types'

export const getTasksMapFromLibrarySearch = (exercises: Exercise[]): LibTaskMap => {
  const processedMap = exercises.reduce(
    (idMap: LibTaskSelectedMap, ex) => ({ ...idMap, [ex.id.toString()]: false }),
    {},
  )
  const parsedTasks = exercises.reduce((libTaskMap: LibTaskMap, currEx: Exercise, index) => {
    if (safeIsNullOrEmpty(currEx.parent_id)) {
      try {
        processedMap[currEx.id.toString()] = true
        const children = exercises.filter((ex) => ex.parent_id === currEx.id).sort((exA, exB) => exA.id - exB.id)
        children.forEach((ex) => (processedMap[ex.id.toString()] = true))
        // adding index to key to preserve order of exercises as they arrived in search results
        libTaskMap[`${index}-${currEx.id}`] = {
          ...parseTaskResponseToTaskVm({
            ...currEx,
            children,
          }),
          author: currEx.creator ?? '',
        }
      } catch (error) {
        console.warn('Failed to parse exercise in task search, skipping it (will not be visible in search results)')
        console.warn(error)
        console.warn(currEx)
      }
    }
    return libTaskMap
  }, {})
  const tasksKeys = Object.keys(parsedTasks)
  const nextIndex = tasksKeys.length > 0 ? parseInt(tasksKeys[tasksKeys.length - 1].split('-')[0]) + 1 : 0
  Object.entries(processedMap).forEach(([exKey, wasProcessed], index) => {
    if (!wasProcessed) {
      try {
        const exercise = exercises.find((ex) => ex.id.toString() === exKey)
        if (exercise != null) {
          const orphanTask = { ...parseTaskResponseToTaskVm(exercise), author: exercise.creator ?? '' }
          parsedTasks[`${nextIndex + index}-${orphanTask.id}`] = orphanTask
        }
      } catch (error) {
        console.warn('Failed to parse an exercise in task search, skipping it (will not be visible in search results)')
        console.warn(error)
      }
    }
  })
  return parsedTasks
}

export const parseSearchValues = (searchParams: TaskLibrarySearchParams): TaskLibrarySearchParams => {
  return Object.fromEntries(Object.entries(searchParams).filter(([_, value]) => !safeIsNullOrEmpty(value)))
}

export const getLibsForUser = (user: TUser | null) => {
  const libs = []
  if (user?.hasOrgLibrary) {
    libs.push(LibrarySource.ORG)
  }
  if (user?.hasCommunity) {
    libs.push(LibrarySource.COMMUNITY)
  }
  if (user?.hasTemplates) {
    libs.push(LibrarySource.TEMPLATE)
  }
  return libs
}

export const removeSubtaskId = (subtask: Subtask): Subtask => {
  const { id: _, ...rest } = subtask
  return rest
}

export const getSelectedSubtasks = (taskMap: LibTaskMap, selectedIdMap: LibTaskSelectedMap) => {
  return Object.entries(selectedIdMap)
    .filter(([_, isSelected]) => isSelected)
    .reduce((subtasks: Subtask[], [taskKey]) => [...subtasks, ...taskMap[taskKey].subtasks], [])
    .map(removeSubtaskId)
}
