import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { SelectFormField } from '../../../../../common/components/Form/SelectFormField/SelectFormField'
import { SelectElement, SelectOption } from '../../../../../common/components/Select/Select'
import { Task } from '../../../../../types/commonTypes'
import { GameSettingsToggle } from '../../GameSettings/components/GameSettingsToggle'
import styles from './StartingTaskSelection.module.css'

type StartingTaskFormValues = {
  showStartingTaskSelection: boolean
  startingTask?: number
}

type StartingTaskSelectionProps = {
  selectOnly?: boolean
  values: StartingTaskFormValues
  tasks: Task[]
}

const prefixElement = {
  type: 'icon',
  icon: {
    name: 'pinFilled',
  },
  styles: { height: '32px', width: '32px' },
} as SelectElement

export const StartingTaskSelection: React.FC<StartingTaskSelectionProps> = ({ selectOnly = false, values, tasks }) => {
  const { t } = useTranslation()

  const startingTaskOptions: SelectOption<number>[] = tasks
    .filter((t) => (t.level ?? 1) === 1)
    .map((task) => {
      return {
        label: task.name,
        value: task.id,
      }
    })

  return (
    <>
      {selectOnly ? (
        <SelectFormField
          inline
          srOnlyLabel
          prefixElement={prefixElement}
          className={classNames(styles.startingTaskField, styles.startingTaskOnlyMode)}
          name='startingTask'
          label={t('game_editor.add_people.add_manually.first_task_placeholder', 'Select first task')}
          placeholder={t('game_editor.add_people.add_manually.first_task_placeholder', 'Select first task')}
          options={startingTaskOptions}
          isSearchable
        />
      ) : (
        <div className={classNames(values.showStartingTaskSelection && styles.startingTaskArea)}>
          <GameSettingsToggle
            name='showStartingTaskSelection'
            label={t(
              'game_editor.add_people.add_manually.first_tasks_toggle_label',
              'Define first task for added player/team',
            )}
            description={t(
              'game_editor.add_people.add_manually.first_tasks_toggle_disabled',
              'Select which task player will first need to answer.',
            )}
            descriptionChecked={t(
              'game_editor.add_people.add_manually.first_tasks_toggle_enabled',
              'Select which task player will first need to answer.',
            )}
          />
          {values.showStartingTaskSelection && (
            <SelectFormField
              inline
              srOnlyLabel
              prefixElement={prefixElement}
              fieldContainerClassName={styles.startingTaskFieldContainer}
              className={styles.startingTaskField}
              name='startingTask'
              label={t('game_editor.add_people.add_manually.first_task_placeholder', 'Select first task')}
              placeholder={t('game_editor.add_people.add_manually.first_task_placeholder', 'Select first task')}
              options={startingTaskOptions}
              isSearchable
            />
          )}
        </div>
      )}
    </>
  )
}
