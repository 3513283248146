import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../../../common/components/PopupMenu/PopupActionMenu'
import styles from './AnswerOption.module.css'

export const AnswerOption: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className={styles.answerOption}>{children}</div>
}

export type AnswerOptionTopRowProps = {
  actionMenuId: string
  onDelete?: () => void
}

export const AnswerOptionRowWithMenu: React.FC<PropsWithChildren<AnswerOptionTopRowProps>> = ({
  actionMenuId,
  onDelete,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.answerOptionRowWithMenu}>
      {children}
      {onDelete != null && (
        <PopupActionMenu id={actionMenuId}>
          <PopupActionMenuItem
            icon='trash'
            onClick={onDelete}
            text={t('game_editor.tasks.answer_options.delete_button', 'delete')}
          />
        </PopupActionMenu>
      )}
    </div>
  )
}

export const AnswerOptionRow: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className={styles.answerOptionRow}>{children}</div>
}
