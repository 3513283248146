import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { MultipleChoiceAnswerOption, MultipleChoiceSubtask } from '../../../../../../../types/commonTypes'
import { AnswerOptionsList } from '../components/AnswerOptionsList'
import { ChoiceAnswerOption } from '../components/ChoiceAnswerOption'
import { noop } from '../../../../../../../util/functional'

type MultipleChoiceSubtaskProps = {
  value: MultipleChoiceSubtask
  name: string
  noPoints?: boolean
  viewOnly: boolean
}

const mapFieldToAnswerOption = (
  name: string,
  index: number,
  fields: FieldArrayRenderProps<MultipleChoiceAnswerOption, any>['fields'],
  task: MultipleChoiceSubtask,
  viewOnly: boolean,
  noPoints?: boolean,
): JSX.Element => {
  return (
    <ChoiceAnswerOption
      key={name}
      taskId={task.id}
      nextTaskId={task.data.answers[index].nextTaskId ?? '0'}
      name={name}
      hasFixedPoints={task.data.allAnswersHaveSamePoints}
      fixedPoints={task.data.allAnswersPoints}
      onDelete={viewOnly ? noop : () => fields.remove(index)}
      noPoints={noPoints}
      allowCustomFeedback
      hasFeedback={fields.value[index].hasFeedback}
      onIsCorrectAnswer={() => {
        fields.forEach((_, fieldIndex) => {
          if (fieldIndex !== index && fields.value[fieldIndex].isCorrectAnswer) {
            fields.update(fieldIndex, { ...fields.value[fieldIndex], isCorrectAnswer: false })
          }
        })
      }}
      viewOnly={viewOnly}
    />
  )
}

export const MultipleChoiceSubtaskFields: React.FC<MultipleChoiceSubtaskProps> = ({
  value,
  name,
  noPoints,
  viewOnly,
}) => {
  return (
    <AnswerOptionsList<MultipleChoiceSubtask>
      name={name}
      task={value}
      noPoints={noPoints}
      mapFieldToAnswerOption={mapFieldToAnswerOption}
      viewOnly={viewOnly}
    />
  )
}
