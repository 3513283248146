import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapType } from '../../../../../api/gameTypes'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../common/components/PopupMenu/PopupActionMenu'
import { SelectableOption } from '../../../../../common/components/selectable/SelectableOption'
import { EditorPermissions, Game } from '../../../../../types/commonTypes'
import sharedSidebarStyles from '../EditorSidebar.module.css'
import styles from './Build.module.css'
import { StoryAndRules } from './components/StoryAndRules'
import { Tasks, TasksActionProps } from './components/Tasks'
import { ToggleTaskContext } from './components/ToggleTaskContext/ToggleTaskContext'

enum TABS {
  STORY_AND_RULES = 'storyAndRules',
  TASKS = 'tasks',
}

type BuildProps = TasksActionProps & {
  game: Game
  activeBoardIndex: number
  permissions: EditorPermissions
  onSetActiveBoard: (id: number) => void
  onOpenGameSettings: () => void
  onAddDoor: () => void
}

export const Build: React.FC<BuildProps> = ({
  game,
  activeBoardIndex,
  permissions,
  onSetActiveBoard,
  onAddTask,
  onEditTask,
  onCopyTask,
  onDeleteTask,
  onOpenGameSettings,
  onAddDoor,
}) => {
  const { t } = useTranslation()

  const [showAllLevelBoards, setShowAllLevelBoards] = useState<boolean>(false)
  const onToggleShowAllLevelBoards = () => setShowAllLevelBoards((prev) => !prev)

  const [activeTab, setActiveTab] = useState(TABS.TASKS)

  const [showFlash, setShowFlash] = useState<boolean>(false)
  const [isCompact, setIsCompact] = useState<boolean>(game.advancedSettings.levelsEnabled)

  const [showDragCancel, setShowDragCancel] = useState<boolean>(false)

  if (game == null) {
    return null
  }

  const showMoreMenu =
    game.advancedSettings.levelsEnabled &&
    game.gameBoardSettings.gameBoardType !== MapType.LIVE &&
    game.gameBoardSettings.gameBoards.length > 1

  return (
    <>
      <div className={classNames(sharedSidebarStyles.editorSidebarPanelContainer, sharedSidebarStyles.outerContainer)}>
        <div className={sharedSidebarStyles.sidebarTopContainer}>
          <div className={sharedSidebarStyles.sidebarHeader}>
            <h3>{t('game_editor.sidebar.build.title', 'Create game')}</h3>
            {showMoreMenu && (
              <PopupActionMenu id='moreMenu_buildSidebar' direction='right'>
                <PopupActionMenuItem
                  text={
                    showAllLevelBoards
                      ? t('game_editor.sidebar.build.board_view_option_hide_without_tasks', 'Hide boards without tasks')
                      : t('game_editor.sidebar.build.board_view_option_show all', 'Show all boards')
                  }
                  icon={showAllLevelBoards ? 'eyeCrossed' : 'eye'}
                  onClick={onToggleShowAllLevelBoards}
                />
              </PopupActionMenu>
            )}
          </div>
          <div className={sharedSidebarStyles.sidebarTabSwitchContainer}>
            <SelectableOption
              groupName='tabSwitch'
              id={TABS.STORY_AND_RULES}
              onChange={() => setActiveTab(TABS.STORY_AND_RULES)}
              selectedOption={activeTab}
              text={t('game_editor.sidebar.build.story_and_rules.switch_button', 'Story and rules')}
            />
            <SelectableOption
              groupName='tabSwitch'
              id={TABS.TASKS}
              onChange={() => setActiveTab(TABS.TASKS)}
              selectedOption={activeTab}
              text={t('game_editor.sidebar.build.tasks.switch_button', 'Tasks')}
            />
          </div>
          {activeTab === TABS.TASKS && (
            <ToggleTaskContext
              isFlash={showFlash}
              isCompact={isCompact}
              onChangeIsFlash={setShowFlash}
              onChangeIsCompact={setIsCompact}
              flashDisabled={!permissions.actions.flashTasks}
            />
          )}
        </div>
        {activeTab === TABS.STORY_AND_RULES ? (
          <StoryAndRules game={game} viewOnly={!permissions.actions.storyAndRules} />
        ) : (
          <Tasks
            activeBoardIndex={activeBoardIndex}
            onSetActiveBoard={onSetActiveBoard}
            game={game}
            showFlash={showFlash}
            isCompact={isCompact}
            onAddTask={onAddTask}
            onEditTask={onEditTask}
            onCopyTask={onCopyTask}
            onDeleteTask={onDeleteTask}
            onAddDoor={onAddDoor}
            onOpenGameSettings={onOpenGameSettings}
            showAllLevelBoards={showAllLevelBoards}
            onSetShowDragCancel={setShowDragCancel}
            viewOnly={!permissions.actions.tasks}
          />
        )}
      </div>
      {showDragCancel && (
        <div className={classNames(sharedSidebarStyles.editorSidebarPanelContainer, styles.dragCancelContainer)}>
          {t('game_editor.sidebar.build.drag_here_to_cancel', 'Drag here to cancel')}
        </div>
      )}
    </>
  )
}
