import { PropsWithChildren } from 'react'
import { useField } from 'react-final-form'
import { FormFieldWithError } from '../../../../../../../common/components/Form/FormFieldError/FormFieldWithError'
import { ToggleSwitch } from '../../../../../../../common/components/ToggleSwitch/ToggleSwitch'
import styles from './ExpandableToggle.module.css'

type ExpandableToggleProps = {
  title: string
  description?: string | JSX.Element
  descriptionChecked?: string | JSX.Element
  name: string
  disabled?: boolean
}

export const ExpandableToggle: React.FC<PropsWithChildren<ExpandableToggleProps>> = ({
  title,
  description,
  descriptionChecked,
  children,
  name,
  disabled,
}) => {
  const { input } = useField(name, { type: 'checkbox' })

  return (
    <FormFieldWithError name={name}>
      <div className={styles.container}>
        <ToggleSwitch {...input} id={name} disabled={disabled} />
        <label htmlFor={name} className={styles.titleAndDescription}>
          <span className={input.checked ? styles.checkedTitle : styles.uncheckedTitle}>{title}</span>
          <span className={styles.description}>{input.checked ? descriptionChecked ?? description : description}</span>
        </label>
      </div>
      {input.checked && children}
    </FormFieldWithError>
  )
}
