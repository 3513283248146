import classNames from 'classnames'
import { FormFieldProps } from '../../../../types/commonTypes'
import { ERROR_ROUNDED_OUTLINE_CLASS_NAME, FormFieldWithError } from '../FormFieldError/FormFieldWithError'
import commonFieldStyles from '../FormFields.module.css'
import { ButtonRadioField } from './ButtonRadioField'
import styles from './ButtonRadioGroupField.module.css'

export type ButtonRadioItem = {
  value: string
  label: string
  disabled?: boolean
}

type ButtonRadioGroupFieldProps = {
  options: ButtonRadioItem[]
  disabled?: boolean
}

export const ButtonRadioGroupField: React.FC<FormFieldProps<ButtonRadioGroupFieldProps>> = ({
  options,
  name,
  label,
  srOnlyLabel,
  validateOnlyIfDirty,
  disabled,
}) => {
  return (
    <FormFieldWithError name={name} validateOnlyIfDirty={validateOnlyIfDirty}>
      <label className={classNames(srOnlyLabel && 'sr-only', commonFieldStyles.label)}>{label}</label>
      <div className={classNames(styles.radioGroup, ERROR_ROUNDED_OUTLINE_CLASS_NAME)}>
        {options.map((option, index) => (
          <ButtonRadioField
            key={`buttonRadioGroup_${index}_${option.value}`}
            name={name}
            label={option.label}
            disabled={disabled ?? option.disabled}
            value={option.value}
          />
        ))}
      </div>
    </FormFieldWithError>
  )
}
