import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { EditorFormField } from '../../../../../../../common/components/Form/EditorFormField/EditorFormField'
import { TextAreaFormField } from '../../../../../../../common/components/Form/TextAreaFormField/TextAreaFormField'
import styles from './AutomatedFeedback.module.css'
import { ExpandableToggle } from './ExpandableToggle'

type AutomatedFeedbackProps = {
  name: string
  allowRichText?: boolean
  viewOnly: boolean
}

export const AutomatedFeedback: React.FC<AutomatedFeedbackProps> = ({ name, allowRichText, viewOnly }) => {
  const { t } = useTranslation()

  return (
    <ExpandableToggle
      name={`${name}.data.hasAutomatedFeedback`}
      title={t('game_editor.tasks.automated_feedback.title', 'Automated feedback')}
      description={t(
        'game_editor.tasks.automated_feedback.description_disabled',
        'Define automated feedback to be given to players immediately after answering',
      )}
      descriptionChecked={t(
        'game_editor.tasks.automated_feedback.description_enabled',
        'Define automated feedback to be given to players immediately after answering',
      )}
      disabled={viewOnly}
    >
      {allowRichText ? (
        <EditorFormField
          name={`${name}.data.automatedFeedback`}
          label={t('game_editor.tasks.automated_feedback.title', 'Automated feedback')}
          srOnlyLabel
          onlyBasicOptions
          initProps={{ height: 200 }}
          fieldContainerClassName={styles.fieldContainer}
          disabled={viewOnly}
        />
      ) : (
        <TextAreaFormField
          name={`${name}.data.automatedFeedback`}
          placeholder={t('game_editor.tasks.automated_feedback.title', 'Automated feedback')}
          label={t('game_editor.tasks.automated_feedback.title', 'Automated feedback')}
          srOnlyLabel
          fieldContainerClassName={classNames(styles.textAreaField, styles.fieldContainer)}
          className={styles.textAreaField}
          rows={2}
          disabled={viewOnly}
        />
      )}
    </ExpandableToggle>
  )
}
