import { useBusinessSettings } from '../../../contexts/BusinessSettingsContext'
import { useUser } from '../../../contexts/userContext'
import styles from '../Settings.module.css'
import { Players } from './components/Players'

export const LicenseHolderSettings: React.FC = () => {
  const { data } = useBusinessSettings()
  const { user } = useUser()

  return (
    <div className={styles.sectionsContainer}>
      <Players
        newBusinessInfo={data?.businessInfo.newBusinessInfo}
        organizationName={user?.activeBusiness.name || ''}
        isShowingAllLicenseHolders={true}
      />
    </div>
  )
}
