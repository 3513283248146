import { useState } from 'react'
import { DatePicker } from '../../../common/components/DatePicker/DatePicker'
import styles from '../Search.module.css'
import { DateFilterProps } from './types'

export const DateFilter: React.FC<DateFilterProps> = ({ onChange, initialValue, label }) => {
  const [value, setValue] = useState<Date | null>(initialValue != null ? new Date(initialValue) : null)

  const onChangeInternal = (newTimeStamp: string | null) => {
    onChange(newTimeStamp)
    setValue(newTimeStamp != null ? new Date(newTimeStamp) : null)
  }

  return (
    <div className={styles.singleFilterContainer}>
      <DatePicker date={value} onChangeDate={onChangeInternal} placeholderText={label} isClearable />
    </div>
  )
}
