import React, { useCallback, useState } from 'react'
import styles from './GamePreviewModalSidebar.module.css'

export type InfoTab = {
  title: string
  content: string | JSX.Element
}

export type SidebarInfoTabsProps = {
  tabs: InfoTab[]
  isLibrary: boolean
}

export const SidebarInfoTabs: React.FC<SidebarInfoTabsProps> = ({ tabs, isLibrary }) => {
  const [activeTab, setActiveTab] = useState<InfoTab>(tabs[0])
  const handleTabClick = useCallback((tab: InfoTab) => () => setActiveTab(tab), [])
  const activeTextColorVar = isLibrary ? 'var(--secondary-dark)' : 'var(--primary-normal)'
  const activeBorderColorVar = isLibrary ? 'var(--secondary-normal)' : 'var(--primary-normal)'

  return (
    <div>
      <div className={styles.sidebarTabList} tabIndex={0}>
        {tabs.map((tab, index) => (
          <button
            key={`${tab.title}${index}`}
            className={styles.sidebarTabButton}
            style={{
              color: tab.title === activeTab.title ? activeTextColorVar : 'var(--grey-600)',
              borderBottomColor: tab.title === activeTab.title ? activeBorderColorVar : 'transparent',
              minWidth: tabs.length === 1 ? '98%' : '',
            }}
            onClick={handleTabClick(tab)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className={styles.sidebarTabContainer}>{activeTab.content}</div>
    </div>
  )
}
