import React from 'react'
import { BackMenuItem } from '../../../../common/components/MenuItem/components/BackMenuItem'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '../../../../common/components/MenuItem/MenuItem'
import { LANGUAGE_FLAGS, LANGUAGE_LABELS, USER_MENU_INITIAL } from '../../../../common/constants'

type LanguageMenuProps = {
  onMenuItemClick: (menuItemName: string, value: string) => void
  onMenuBackClick: (menuType: string) => void
}

export const LanguageMenu: React.FC<LanguageMenuProps> = ({ onMenuItemClick, onMenuBackClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <BackMenuItem
        fontSize='normal'
        icon='backIcon'
        text={t('user_menu.back', 'Back')}
        itemType={USER_MENU_INITIAL}
        onMenuItemClick={onMenuBackClick}
      />
      <MenuItem
        fontSize='normal'
        text={`${LANGUAGE_FLAGS.fi} ${LANGUAGE_LABELS.fi}`}
        menuItemName='Suomi'
        value='fi'
        isUserMenu
        onMenuItemClick={onMenuItemClick}
      />
      <MenuItem
        fontSize='normal'
        text={`${LANGUAGE_FLAGS.en} ${LANGUAGE_LABELS.en}`}
        menuItemName='English'
        value='en'
        isUserMenu
        onMenuItemClick={onMenuItemClick}
      />
      <MenuItem
        fontSize='normal'
        text={`${LANGUAGE_FLAGS.sv} ${LANGUAGE_LABELS.sv}`}
        menuItemName='Svenska'
        value='sv'
        isUserMenu
        onMenuItemClick={onMenuItemClick}
      />
      <MenuItem
        fontSize='normal'
        text={`${LANGUAGE_FLAGS.nl} ${LANGUAGE_LABELS.nl}`}
        menuItemName='Nederlands'
        value='nl'
        isUserMenu
        onMenuItemClick={onMenuItemClick}
      />
      <MenuItem
        fontSize='normal'
        text={`${LANGUAGE_FLAGS.pt} ${LANGUAGE_LABELS.pt}`}
        menuItemName='Portuges'
        value='pt'
        isUserMenu
        onMenuItemClick={onMenuItemClick}
      />
      <MenuItem
        fontSize='normal'
        text={`${LANGUAGE_FLAGS.es} ${LANGUAGE_LABELS.es}`}
        menuItemName='Espanol'
        value='es'
        isUserMenu
        onMenuItemClick={onMenuItemClick}
      />
      <MenuItem
        fontSize='normal'
        text={`${LANGUAGE_FLAGS.de} ${LANGUAGE_LABELS.de}`}
        menuItemName='Deutsch'
        value='de'
        isUserMenu
        onMenuItemClick={onMenuItemClick}
      />
      <MenuItem
        fontSize='normal'
        text={`${LANGUAGE_FLAGS.nb} ${LANGUAGE_LABELS.nb}`}
        menuItemName='Norsk'
        value='nb'
        isUserMenu
        onMenuItemClick={onMenuItemClick}
      />
    </>
  )
}
