import classNames from 'classnames'
import styles from './BadgeView.module.css'

type BadgeImageProps = {
  url: string
  className?: string
  variant?: 'normal' | 'tiny'
}

export const BadgeImage: React.FC<BadgeImageProps> = ({ url, className, variant = 'normal' }) => {
  return (
    <div
      className={classNames(
        styles.badgeImageOuterContainer,
        variant === 'tiny' && styles.badgeImageOuterContainer_tiny,
        className,
      )}
    >
      <div className={styles.badgeImageInnerContainer}>
        <div
          className={styles.badgeImageInnerContainer}
          style={{ background: `url('${url}') no-repeat center/100%` }}
        />
      </div>
    </div>
  )
}
