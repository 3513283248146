import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import { MODALS_ROOT_ID, TOOLTIP_ROOT_ID } from '../../constants'

type PortalProps = {
  id: string
}

export const Portal: React.FC<PropsWithChildren<PortalProps>> = ({ id, children }) => {
  const containerElement = document.querySelector(`#${id}`)
  return containerElement != null ? createPortal(children, containerElement) : null
}

export const ModalPortal: React.FC<PropsWithChildren> = ({ children }) => {
  return <Portal id={MODALS_ROOT_ID}>{children}</Portal>
}

export const TooltipPortal: React.FC<PropsWithChildren> = ({ children }) => {
  return <Portal id={TOOLTIP_ROOT_ID}>{children}</Portal>
}
