import classNames from 'classnames'
import React from 'react'
import { TabType } from '../../../types'
import styles from './Tab.module.css'

type TabProps = {
  id: TabType
  title: string
  label: string
  isActive: boolean
  onClickTab: (tab: TabType) => void
}

export const Tab: React.FC<TabProps> = ({ id, title, label, isActive, onClickTab }) => {
  const onClickInternal = () => {
    onClickTab(id)
  }

  return (
    <div className={styles.tab}>
      <label className={styles.label}>
        <input
          name='headerTabs'
          id={id}
          className={styles.originalRadio}
          type='radio'
          checked={isActive}
          onChange={onClickInternal}
        />
        <div className={styles.tabContent}>
          <p className={styles.title}>{title}</p>
          {process.env.REACT_APP_ENV === 'development' && (
            <p className={classNames('hide-outscoped-feature', styles.label)}>{label}</p>
          )}
        </div>
      </label>
    </div>
  )
}
