import classNames from 'classnames'
import React from 'react'
import styles from './Button.module.css'

type ButtonVariant =
  | 'big'
  | 'normal'
  | 'tiny'
  | 'outline-normal'
  | 'outline-tiny'
  | 'borderless-normal'
  | 'borderless-tiny'

export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
  variant?: ButtonVariant
  secondary?: boolean
  className?: string
}

const getVariantClassNames = (variant: ButtonVariant): string[] => {
  return variant.split('-').map((part) => `button_${part}`)
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'normal', type = 'button', className, secondary, children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          styles.button,
          getVariantClassNames(variant).map((part) => styles[part]),
          secondary && styles.button_secondary,
          className,
        )}
        type={type as 'button' | 'reset' | 'submit' | undefined}
        {...props}
      >
        {children}
      </button>
    )
  },
)
