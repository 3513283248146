import React, { useCallback, useRef, useState } from 'react'

import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { ModalPortal } from '../../../../common/components/Portal/Portal'
import { Button } from '../../../../common/components/button/Button'
import { useConfirmation } from '../../../../contexts/ConfirmationContext'
import { useFormSubmitFailureListener } from '../../../../hooks/useFormSubmitFailureListener'
import { Game } from '../../../../types/commonTypes'
import { areObjectsEqual } from '../../../../util/functional'
import { clickedElementShouldNotClose, validateShareGameForm } from './ShareGameHelper'
import { ShareGameForm } from '../../types'
import styles from './ShareGameModal.module.css'
import { useUser } from '../../../../contexts/userContext'
import { useOnEscPress } from '../../../../hooks/useOnEscPress'
import { InputFormField } from '../../../../common/components/Form/InputFormField/InputFormField'
import { GeneralShareSettings } from './GeneralShareSettings'
import { useGame } from '../../../../contexts/GameContext'
import { ShareType } from '../../../../api/gameTypes'
import { LibraryShareSettingsAndMenu } from './LibraryShareSettingsAndMenu'
import useOnClickOutside from 'use-onclickoutside'
import { NOTIFICATION_DISMISS_BUTTON_ID_PREFIX } from '../../../../contexts/NotificationContext'
import { CONFIRMATION_DIALOG_ID_PREFIX } from '../../../../composites/confirmationDialog/constants'
import { SHARE_GAME_BUTTON_ID } from '../../constants'
import classNames from 'classnames'

type ShareGameProps = {
  gameData?: Game
  onClose: () => void
  onSubmit: (data: ShareGameForm) => Promise<void>
  onDelete: () => void
}

const FORM_ID = 'game_share_modal_form'

export const ShareGameModal: React.FC<ShareGameProps> = ({ gameData, onClose, onSubmit, onDelete }) => {
  const { t } = useTranslation()
  const { requestConfirmation } = useConfirmation()
  const { user } = useUser()
  const shareModalRef = useRef<HTMLDivElement>(null)
  const { libraryGameDetails } = useGame()

  const [initialValues] = useState<Partial<ShareGameForm>>({
    ages: gameData?.ages,
    description: gameData?.description,
    keywords: gameData?.keywords,
    language: gameData?.language,
    name: gameData?.name,
    topics: gameData?.topics,
    shareToPrivate: libraryGameDetails ? libraryGameDetails.shareType !== ShareType.PUBLIC : undefined,
    shareToPublic: libraryGameDetails ? libraryGameDetails.shareType !== ShareType.PRIVATE : undefined,
    togglerError: undefined,
    userName: libraryGameDetails?.creator ?? user?.email ?? undefined,
    userEmail: libraryGameDetails?.email ?? user?.email ?? undefined,
    userPhone: libraryGameDetails?.phone ?? undefined,
  })

  useOnClickOutside(shareModalRef, (e: any) => {
    const targetId = (e.target as any)?.getAttribute?.('id')
    if (
      targetId?.startsWith(NOTIFICATION_DISMISS_BUTTON_ID_PREFIX) ||
      targetId?.startsWith(CONFIRMATION_DIALOG_ID_PREFIX) ||
      clickedElementShouldNotClose(e.target) ||
      targetId === SHARE_GAME_BUTTON_ID
    ) {
      return
    }
    onClose()
  })

  const onCloseInternal = useCallback(
    (hasChanges: boolean) => {
      if (hasChanges) {
        requestConfirmation({
          title: t('game_editor.game_settings.confirm_closing_title', 'Unsaved changes'),
          text: t(
            'game_editor.game_settings.confirm_closing_text',
            'Are you sure you want to exit? Any unsaved changes will be lost.',
          ),
        }).then((response) => {
          if (response) {
            onClose()
          }
        })
      } else {
        onClose()
      }
    },
    [requestConfirmation, onClose, t],
  )

  useOnEscPress(() => {
    onCloseInternal(true)
  })

  const handleDelete = async () => {
    const shouldProceed = await requestConfirmation({
      title: t('game_editor.share_game.delete_confirm_title', 'Confirm removing the game from library'),
      text: t(
        'game_editor.share_game.delete_confirm_text',
        "The game will be removed from the library and other users can't download it anymore.",
      ),
    })
    if (shouldProceed) {
      onDelete()
    }
  }

  const { submitListener } = useFormSubmitFailureListener()

  if (!user) return null

  const validate = (values: Partial<ShareGameForm>) => {
    return validateShareGameForm(initialValues, values)
  }

  const handleSubmit = async (submitValues: ShareGameForm) => {
    if (!submitValues['shareToPrivate'] && !submitValues['shareToPublic']) {
      return {
        togglerError:
          user.hasCommunity && user.hasOrgLibrary
            ? t('validation_errors.one_or_both_required', 'One or both required')
            : t('validation_errors.required', 'Required'),
      }
    }
    await onSubmit(submitValues)
  }

  return (
    <ModalPortal>
      <div ref={shareModalRef} className={styles.container}>
        <Form<ShareGameForm>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validate}
          decorators={[submitListener]}
        >
          {({ handleSubmit, submitting, values, submitErrors, dirtySinceLastSubmit }) => (
            <>
              <div className={styles.header}>
                <span>
                  <h4>{t('game_editor.share_game.title', 'Share your game with others')}</h4>
                </span>
                <div className={styles.info}>
                  {t(
                    'game_editor.share_game.info',
                    'Using these settings you will make your game available to others.',
                  )}
                </div>
              </div>

              <div className={styles.content}>
                <form onSubmit={handleSubmit} id={FORM_ID}>
                  <LibraryShareSettingsAndMenu
                    libraryGameDetails={libraryGameDetails}
                    user={user}
                    errors={dirtySinceLastSubmit ? {} : submitErrors}
                    onDelete={handleDelete}
                  />
                  <div className={styles.separator} />
                  <GeneralShareSettings />
                  <div className={classNames(styles.separator, styles.separatorWithMargins)} />
                  <InputFormField
                    fieldContainerClassName={styles.fieldContainer}
                    inputContainerClassName={styles.field}
                    className={styles.field}
                    inline
                    name='userName'
                    label={t('game_editor.share_game.user_name', 'User name')}
                  />
                  <InputFormField
                    fieldContainerClassName={styles.fieldContainer}
                    inputContainerClassName={styles.field}
                    className={styles.field}
                    inline
                    name='userEmail'
                    label={t('game_editor.share_game.user_email', 'Email')}
                  />
                  <InputFormField
                    fieldContainerClassName={styles.fieldContainer}
                    inputContainerClassName={styles.field}
                    className={styles.field}
                    inline
                    name='userPhone'
                    label={t('game_editor.share_game.user_phone', 'Phone number')}
                  />
                </form>
              </div>
              <div className={styles.footer}>
                <Button
                  variant='outline-normal'
                  onClick={() => onCloseInternal(!areObjectsEqual(values, initialValues))}
                >
                  {t('common.cancel', 'Cancel')}
                </Button>
                <Button form={FORM_ID} disabled={submitting} type='submit'>
                  {libraryGameDetails
                    ? t('game_editor.share_game.sync', 'Sync changes')
                    : t('game_editor.share_game.publish', 'Publish')}
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    </ModalPortal>
  )
}
