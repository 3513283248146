import * as React from 'react'
import { SVGProps } from 'react'
const SvgPodiumIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='17em' height='6em' viewBox='0 0 278 99' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M2.097 37.855a4.033 4.033 0 0 1 3.928-4.949h111.888v65.745H16.27L2.097 37.855Z' fill='#D4D4D4' />
    <path
      d='M2.097 37.855a4.033 4.033 0 0 1 3.928-4.949h111.888v65.745H16.27L2.097 37.855Z'
      fill='url(#podium_icon_svg__a)'
      fillOpacity={0.25}
    />
    <path d='M276.395 51.224c.631-2.544-1.294-5.005-3.915-5.005H166.314v52.434h98.31l11.771-47.43Z' fill='#C7C7C7' />
    <path
      d='M276.395 51.224c.631-2.544-1.294-5.005-3.915-5.005H166.314v52.434h98.31l11.771-47.43Z'
      fill='url(#podium_icon_svg__b)'
      fillOpacity={0.25}
    />
    <path d='M84.94 11.793a4.034 4.034 0 0 1 3.384-5.107L140.5.234V98.65h-30.861l-24.7-86.858Z' fill='#C7C7C7' />
    <path
      d='M84.94 11.793a4.034 4.034 0 0 1 3.384-5.107L140.5.234V98.65h-30.861l-24.7-86.858Z'
      fill='url(#podium_icon_svg__c)'
      fillOpacity={0.25}
    />
    <path d='m140.5.234 52.176 6.452a4.033 4.033 0 0 1 3.385 5.107l-24.7 86.857H140.5V.234Z' fill='#F2F2F2' />
    <path
      d='m140.5.234 52.176 6.452a4.033 4.033 0 0 1 3.385 5.107l-24.7 86.857H140.5V.234Z'
      fill='url(#podium_icon_svg__d)'
      fillOpacity={0.1}
    />
    <defs>
      <linearGradient
        id='podium_icon_svg__a'
        x1={68.705}
        y1={29.776}
        x2={68.705}
        y2={98.651}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='podium_icon_svg__b'
        x1={232.462}
        y1={46.219}
        x2={232.462}
        y2={98.653}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='podium_icon_svg__c'
        x1={112.076}
        y1={0.234}
        x2={112.076}
        y2={98.65}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='podium_icon_svg__d'
        x1={168.924}
        y1={0.234}
        x2={168.924}
        y2={98.65}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgPodiumIcon
