import { TFunction } from 'i18next'
import { getOldUIRoutes, routes } from '../../routes'
import { WelcomeBlockData, WelcomeBlockButton } from './helpers'

type EntityWelcomeBlockDataIndex = { [key: number]: WelcomeBlockData }

export const HIDDEN_MESSAGES = 'hidden-messages'
export const HIDDEN_END_MESSAGES = 'hidden-end-messages'
export const TRIAL_PERIOD_LENGTH = 14

const browseTemplatesButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'link-internal',
  icon: 'community',
  link: routes.templates,
}

const browsePackagesButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'link-internal',
  icon: 'community',
  link: routes.gamePackages,
}

const browseCommunityButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'link-internal',
  icon: 'community',
  link: routes.community,
}

export const helpButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'toggle-help',
  icon: 'help',
}

const readBlogButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'link-external',
  icon: 'receipt',
  link: 'https://seppo.io/blog/giving-feedback/',
  linkFinnish: 'https://seppo.io/fi/blogi/miten-antaa-palautetta-oppimispeleissa/',
}

const requestMeetingBlogButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'link-external',
  icon: 'multipleUsers',
  link: 'https://seppo.pipedrive.com/scheduler/mJll1cE/seppo-demo',
}

const ninchatFormButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'ninchat-form',
  icon: 'comment',
}

const customerStoriesButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'link-external',
  icon: 'receipt',
  link: 'https://seppo.io/customer-references/',
  linkFinnish: 'https://seppo.io/fi/asiakkaiden-tarinat/',
}

const upgradeAccountButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'link-external',
  icon: 'starOutline',
  link: getOldUIRoutes.manageLicence() /* https://play.seppo.io/shop */,
}

const browseLibraryButtonProps: Omit<WelcomeBlockButton, 'label'> = {
  type: 'link-internal',
  icon: 'community',
  link: routes.gamePackages,
}

export const getCorporateTrial = (t: TFunction): EntityWelcomeBlockDataIndex => ({
  1: {
    id: 'trial:1',
    title: t('onboarding_message.corporate.trial.day1.title'),
    body: t('onboarding_message.corporate.trial.day1_3d.body'),
    injectRawBody: true,
    primaryButton: {
      label: t('onboarding_message.corporate.trial.day1.primary_button'),
      ...browseTemplatesButtonProps,
    }, secondaryButton: {
      label: t('onboarding_message.corporate.trial.day1.secondary_button'),
      ...browsePackagesButtonProps,
    },
  },
  2: {
    id: 'trial:2',
    title: t('onboarding_message.corporate.trial.day2.title'),
    body: t('onboarding_message.corporate.trial.day2.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.trial.day2.primary_button'),
      ...helpButtonProps,
    },
  },
  4: {
    id: 'trial:4',
    title: t('onboarding_message.corporate.trial.day4.title'),
    body: t('onboarding_message.corporate.trial.day4.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.trial.day4.primary_button'),
      ...readBlogButtonProps,
    },
  },
  6: {
    id: 'trial:6',
    title: t('onboarding_message.corporate.trial.day6.title'),
    body: t('onboarding_message.corporate.trial.day6.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.trial.day6.primary_button'),
      ...requestMeetingBlogButtonProps,
    },
    secondaryButton: {
      label: t('onboarding_message.corporate.trial.day6.secondary_button'),
      ...ninchatFormButtonProps,
    },
  },
  8: {
    id: 'trial:8',
    title: t('onboarding_message.corporate.trial.day8.title'),
    body: t('onboarding_message.corporate.trial.day8.body'),
  },
  10: {
    id: 'trial:10',
    title: t('onboarding_message.corporate.trial.day10.title'),
    body: t('onboarding_message.corporate.trial.day10.body'),
  },
  12: {
    id: 'trial:12',
    title: t('onboarding_message.corporate.trial.day12.title'),
    body: t('onboarding_message.corporate.trial.day12.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.trial.day12.primary_button'),
      ...customerStoriesButtonProps,
    },
  },
  14: {
    id: 'trial:14',
    title: t('onboarding_message.corporate.trial.day14.title'),
    body: t('onboarding_message.corporate.trial.day14.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.trial.day14.primary_button'),
      ...upgradeAccountButtonProps,
    },
    secondaryButton: {
      label: t('onboarding_message.corporate.trial.day14.secondary_button'),
      ...ninchatFormButtonProps,
    },
  },
  15: {
    id: 'trial:15',
    title: t('onboarding_message.corporate.trial.day15.title'),
    body: t('onboarding_message.corporate.trial.day15.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.trial.day15.primary_button'),
      ...upgradeAccountButtonProps,
    },
    secondaryButton: {
      label: t('onboarding_message.corporate.trial.day15.secondary_button'),
      ...ninchatFormButtonProps,
    },
  },
})

export const getCorporatePaying = (t: TFunction): EntityWelcomeBlockDataIndex => ({
  1: {
    id: 'paying:1',
    title: t('onboarding_message.corporate.paying.day1.title'),
    body: t('onboarding_message.corporate.paying.day1.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.paying.day1.primary_button'),
      ...browseTemplatesButtonProps,
    },
  },
  2: {
    id: 'paying:2',
    title: t('onboarding_message.corporate.paying.day2.title'),
    body: t('onboarding_message.corporate.paying.day2.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.paying.day2.primary_button'),
      ...helpButtonProps,
    },
  },
  4: {
    id: 'paying:4',
    title: t('onboarding_message.corporate.paying.day4.title'),
    body: t('onboarding_message.corporate.paying.day4.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.paying.day4.primary_button'),
      ...readBlogButtonProps,
    },
  },
  6: {
    id: 'paying:6',
    title: t('onboarding_message.corporate.paying.day6.title'),
    body: t('onboarding_message.corporate.paying.day6.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.paying.day6.primary_button'),
      ...browseTemplatesButtonProps,
    },
  },
  8: {
    id: 'paying:8',
    title: t('onboarding_message.corporate.paying.day8.title'),
    body: t('onboarding_message.corporate.paying.day8.body'),
  },
  10: {
    id: 'paying:10',
    title: t('onboarding_message.corporate.paying.day10.title'),
    body: t('onboarding_message.corporate.paying.day10.body'),
  },
  12: {
    id: 'paying:12',
    title: t('onboarding_message.corporate.paying.day12.title'),
    body: t('onboarding_message.corporate.paying.day12.body'),
    primaryButton: {
      label: t('onboarding_message.corporate.paying.day12.primary_button'),
      ...customerStoriesButtonProps,
    },
  },
})

export const getEducationTrial = (t: TFunction): EntityWelcomeBlockDataIndex => ({
  1: {
    id: 'trial:1',
    title: t('onboarding_message.education.trial.day1.title'),
    body: t('onboarding_message.education.trial.day1_3d.body'),
    injectRawBody: true,
    primaryButton: {
      label: t('onboarding_message.education.trial.day1.primary_button'),
      ...browseCommunityButtonProps,
    }, secondaryButton: {
      label: t('onboarding_message.education.trial.day1.secondary_button'),
      ...browsePackagesButtonProps,
    },
  },
  2: {
    id: 'trial:2',
    title: t('onboarding_message.education.trial.day2.title'),
    body: t('onboarding_message.education.trial.day2.body'),
    primaryButton: {
      label: t('onboarding_message.education.trial.day2.primary_button'),
      ...helpButtonProps,
    },
  },
  4: {
    id: 'trial:4',
    title: t('onboarding_message.education.trial.day4.title'),
    body: t('onboarding_message.education.trial.day4.body'),
    primaryButton: {
      label: t('onboarding_message.education.trial.day4.primary_button'),
      ...readBlogButtonProps,
    },
  },
  6: {
    id: 'trial:6',
    title: t('onboarding_message.education.trial.day6.title'),
    body: t('onboarding_message.education.trial.day6.body'),
    primaryButton: {
      label: t('onboarding_message.education.trial.day6.primary_button'),
      ...browseCommunityButtonProps,
    },
  },
  8: {
    id: 'trial:8',
    title: t('onboarding_message.education.trial.day8.title'),
    body: t('onboarding_message.education.trial.day8.body'),
  },
  10: {
    id: 'trial:10',
    title: t('onboarding_message.education.trial.day10.title'),
    body: t('onboarding_message.education.trial.day10.body'),
  },
  12: {
    id: 'trial:12',
    title: t('onboarding_message.education.trial.day12.title'),
    body: t('onboarding_message.education.trial.day12.body'),
    primaryButton: {
      label: t('onboarding_message.education.trial.day12.primary_button'),
      ...customerStoriesButtonProps,
    },
  },
  14: {
    id: 'trial:14',
    title: t('onboarding_message.education.trial.day14.title'),
    body: t('onboarding_message.education.trial.day14.body'),
    primaryButton: {
      label: t('onboarding_message.education.trial.day14.primary_button'),
      ...upgradeAccountButtonProps,
    },
  },
  15: {
    id: 'trial:15',
    title: t('onboarding_message.education.trial.day15.title'),
    body: t('onboarding_message.education.trial.day15.body'),
    primaryButton: {
      label: t('onboarding_message.education.trial.day15.primary_button'),
      ...upgradeAccountButtonProps,
    },
  },
})

export const getEducationPaying = (t: TFunction): EntityWelcomeBlockDataIndex => ({
  1: {
    id: 'paying:1',
    title: t('onboarding_message.education.paying.day1.title'),
    body: t('onboarding_message.education.paying.day1.body'),
    primaryButton: {
      label: t('onboarding_message.education.paying.day1.primary_button'),
      ...browseCommunityButtonProps,
    },
  },
  2: {
    id: 'paying:2',
    title: t('onboarding_message.education.paying.day2.title'),
    body: t('onboarding_message.education.paying.day2.body'),
    primaryButton: {
      label: t('onboarding_message.education.paying.day2.primary_button'),
      ...helpButtonProps,
    },
  },
  4: {
    id: 'paying:4',
    title: t('onboarding_message.education.paying.day4.title'),
    body: t('onboarding_message.education.paying.day4.body'),
    primaryButton: {
      label: t('onboarding_message.education.paying.day4.primary_button'),
      ...readBlogButtonProps,
    },
  },
  6: {
    id: 'paying:6',
    title: t('onboarding_message.education.paying.day6.title'),
    body: t('onboarding_message.education.paying.day6.body'),
    primaryButton: {
      label: t('onboarding_message.education.paying.day6.primary_button'),
      ...browseCommunityButtonProps,
    },
  },
  8: {
    id: 'paying:8',
    title: t('onboarding_message.education.paying.day8.title'),
    body: t('onboarding_message.education.paying.day8.body'),
  },
  10: {
    id: 'paying:10',
    title: t('onboarding_message.education.paying.day10.title'),
    body: t('onboarding_message.education.paying.day10.body'),
  },
  12: {
    id: 'paying:12',
    title: t('onboarding_message.education.paying.day12.title'),
    body: t('onboarding_message.education.paying.day12.body'),
    primaryButton: {
      label: t('onboarding_message.education.paying.day12.primary_button'),
      ...customerStoriesButtonProps,
    },
  },
})

export const getSponsoredGamesWelcomeBlock = (t: TFunction) => {
  return {
    id: 'sponsored-games-welcome-msg',
    title: t('onboarding_message.sponsored_games.title', 'Welcome to Seppo'),
    body: t(
      'onboarding_message.sponsored_games.body',
      'Your sponsored games are listed below. To find more sponsored games or starting making your own games with a Seppo account, visit our webshop.',
    ),
    primaryButton: {
      label: t('onboarding_message.sponsored_games.primary_button', 'Visit shop'),
      ...upgradeAccountButtonProps,
    },
  }
}

export const getGamePackagesWelcomeBlock = (t: TFunction) => {
  return {
    id: 'game-packages-welcome-msg',
    title: t('onboarding_message.game_packages.title', 'Welcome to Seppo'),
    body: t(
      'onboarding_message.game_packages.body',
      'Available game packages and the games can be found from the Library section.',
    ),
    primaryButton: {
      label: t('onboarding_message.game_packages.primary_button', 'Browse Library'),
      ...browseLibraryButtonProps,
    },
  }
}
