import React from 'react'
import { PlayerScore } from '../../../../../../../../types/commonTypes'
import styles from '../../Scoreboard/Scoreboard.module.css'
import { TopThreeColumn } from './TopThreeColumn'

type TopThreeRowProps = {
  topThree: PlayerScore[]
}

export const TopThreeRow: React.FC<TopThreeRowProps> = ({ topThree }) => {
  return (
    <div className={styles.podiumContainer}>
      <div className={styles.podiumRow}>
        {topThree.length > 1 ? <TopThreeColumn playerScore={topThree[1]} /> : <div className={styles.podiumColumn} />}
        {topThree.length > 0 ? <TopThreeColumn playerScore={topThree[0]} /> : <div className={styles.podiumColumn} />}
        {topThree.length > 2 ? <TopThreeColumn playerScore={topThree[2]} /> : <div className={styles.podiumColumn} />}
      </div>
    </div>
  )
}
