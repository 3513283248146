import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { TaskCard, TaskCardProps } from './TaskCard'

type SortableTaskProps = Omit<TaskCardProps, 'sortableProps' | 'isBeingDragged'>

export const SortableTask: React.FC<SortableTaskProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: props.task.id,
    data: { task: { ...props.task, order: (props.index ?? 0) + 1 } },
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const sortableProps = {
    ...attributes,
    ...listeners,
  }

  return (
    <TaskCard
      ref={setNodeRef}
      {...props}
      style={style}
      sortableProps={sortableProps}
      isBeingDragged={active?.id === props.task.id}
    />
  )
}
