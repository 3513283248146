import * as React from 'react'
import { SVGProps } from 'react'

const SvgDuplicateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#duplicate_icon_svg__a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 1.995a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1v-22a1 1 0 0 0-1-1H7Zm-3 1a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-22Zm-3 1a1 1 0 0 1 1 1v24a1 1 0 0 0 1 1h24a1 1 0 1 1 0 2H3a3 3 0 0 1-3-3v-24a1 1 0 0 1 1-1Zm10 4a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H11Zm-1 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H11a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2H11Z'
        fill={props.color ?? 'var(--grey-700)'}
      />
    </g>
    <defs>
      <clipPath id='duplicate_icon_svg__a'>
        <path fill={props.color ?? 'var(--grey-700)'} d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)

export default SvgDuplicateIcon
