import { useEffect } from 'react'
import { useField } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../../../../../../common/components/Form/SelectFormField/SelectFormField'
import { ToggleFormField } from '../../../../../../../common/components/Form/ToggleFormField/ToggleFormField'
import { SelectOption } from '../../../../../../../common/components/Select/Select'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { IconTooltip } from '../../../../../../../common/components/tooltip/IconTooltip'
import { useGame } from '../../../../../../../contexts/GameContext'
import { PointsInput } from '../../../../Points/PointsInput'
import { AnswerImage } from '../components/AnswerImage'
import { AnswerOption, AnswerOptionRow, AnswerOptionRowWithMenu } from '../components/AnswerOption'
import styles from './ChoiceAnswerOption.module.css'

export type ChoiceAnswerOptionProps = {
  taskId?: number
  name: string
  onDelete: () => void
  hasFixedPoints?: boolean
  fixedPoints?: number
  noPoints?: boolean
  onIsCorrectAnswer?: () => void
  allowCustomFeedback?: boolean
  hasFeedback?: boolean
  nextTaskId?: string
  viewOnly: boolean
}

export const ChoiceAnswerOption: React.FC<ChoiceAnswerOptionProps> = ({
  taskId,
  name,
  onDelete,
  hasFixedPoints,
  fixedPoints,
  noPoints,
  onIsCorrectAnswer,
  allowCustomFeedback,
  hasFeedback,
  nextTaskId,
  viewOnly,
}) => {
  const { t } = useTranslation()
  const { input } = useField(`${name}.points`)
  const { tasks, taskConnections, gameData } = useGame()
  const isBranching = gameData?.gameBoardSettings.isBranching

  useEffect(() => {
    if (hasFixedPoints && input.value !== fixedPoints) {
      input.onChange(fixedPoints)
    }
  }, [hasFixedPoints, fixedPoints, input])

  const taskLinkOptions: SelectOption<string>[] = [
    { value: '0', label: t('game_editor.tasks.answer_options.not_linked', 'Not linked') },
  ]
  if (isBranching && taskId) {
    taskConnections
      .filter((tc) => tc.fromId === taskId)
      .forEach((conn) => {
        taskLinkOptions.push({
          value: conn.toId.toString(),
          label: tasks.find((t) => t.id === conn.toId)?.name ?? '',
        })
      })
  }

  return (
    <AnswerOption>
      <AnswerOptionRowWithMenu onDelete={viewOnly ? undefined : onDelete} actionMenuId={`${name}_actionMenu`}>
        <InputFormField
          placeholder={t('game_editor.tasks.answer_options.answer_text_placeholder', 'Type answer')}
          label={t('game_editor.tasks.answer_options.answer_text_accessibility_label', 'Type answer')}
          srOnlyLabel
          name={`${name}.text`}
          fieldContainerClassName={styles.answerTextContainer}
          disabled={viewOnly}
        />
        {!noPoints && <PointsInput name={`${name}.points`} disabled={viewOnly || hasFixedPoints} />}
      </AnswerOptionRowWithMenu>
      <AnswerOptionRow>
        <AnswerImage name={name} viewOnly={viewOnly} />
        <div className={styles.correctAndTaskLinkColumn}>
          {noPoints && (
            <>
              <ToggleFormField
                name={`${name}.isCorrectAnswer`}
                label={t('game_editor.tasks.answer_options.correct_answer_toggle_label', 'Correct answer')}
                description={t(
                  'game_editor.tasks.answer_options.correct_answer_toggle_description',
                  'Shown as feedback',
                )}
                controlWithLabelClassName={styles.choiceAnswerToggle}
                disabled={viewOnly}
              />
              {onIsCorrectAnswer != null && (
                <OnChange name={`${name}.isCorrectAnswer`}>
                  {(value) => {
                    if (value) {
                      onIsCorrectAnswer()
                    }
                  }}
                </OnChange>
              )}
            </>
          )}
          {isBranching && taskLinkOptions.length > 2 && (
            <div className={styles.branchingSelection}>
              <span className={styles.branchingIcon}>{getIcon('branching')}</span>
              <SelectFormField
                fieldContainerClassName={styles.fieldContainer}
                className={styles.field}
                defaultValue={taskLinkOptions.find((option) => option.value === nextTaskId)}
                name={`${name}.nextTaskId`}
                label={t('game_editor.tasks.answer_options.link_to_task', 'Link answer to other task')}
                placeholder={t('game_editor.tasks.answer_options.not_linked', 'Not linked')}
                options={taskLinkOptions}
                labelClassName={styles.branchingLabel}
                disabled={viewOnly}
              />
              <div>
                <IconTooltip
                  tooltipContent={t(
                    'game_editor.tasks.answer_options.link_to_task_tooltip',
                    'Selected task will become available next if player chooses this answer option',
                  )}
                />
              </div>
            </div>
          )}
        </div>
        {allowCustomFeedback && (
          <ToggleFormField
            name={`${name}.hasFeedback`}
            label={t('game_editor.tasks.answer_options.feedback_toggle_label', 'Feedback')}
            description={t('game_editor.tasks.answer_options.feedback_toggle_description', 'Custom')}
            controlWithLabelClassName={styles.choiceAnswerToggle}
            disabled={viewOnly}
          />
        )}
      </AnswerOptionRow>
      {hasFeedback && (
        <AnswerOptionRow>
          <InputFormField
            placeholder={t('game_editor.tasks.answer_options.custom_feedback_placeholder', 'Enter feedback')}
            label={t('game_editor.tasks.answer_options.custom_feedback_accessibility_label', 'Enter feedback')}
            srOnlyLabel
            name={`${name}.feedback`}
            fieldContainerClassName={styles.feedbackField}
            disabled={viewOnly}
          />
        </AnswerOptionRow>
      )}
    </AnswerOption>
  )
}
