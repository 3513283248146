import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLevelGenericIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 30 30' {...props}>
    <g fill='currentColor'>
      <path
        fillRule='evenodd'
        d='M15 0C8.663 0 3.525 5.1 3.525 11.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C26.475 5.1 21.337 0 15 0zm-.644 8.457-4.125 4.095a.927.927 0 0 0 0 1.312c.363.359.96.359 1.322 0l3.464-3.428 3.464 3.438a.937.937 0 0 0 1.599-.656.916.916 0 0 0-.277-.655l-4.125-4.106a.944.944 0 0 0-1.322 0z'
        clipRule='evenodd'
      />
      <path d='m3.342 18.15-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.682.202l3.3-5.206a13.517 13.517 0 0 1-8.251-6.21zM18.407 24.359l3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z' />
    </g>
  </svg>
)
export default SvgLevelGenericIcon
