import * as React from 'react'
import { SVGProps } from 'react'

const SvgTwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M21.516 5.125c.937-.703 1.78-1.547 2.437-2.531a9.225 9.225 0 0 1-2.812.75A4.809 4.809 0 0 0 23.297.625c-.938.563-2.016.984-3.094 1.219a4.922 4.922 0 0 0-3.61-1.547 4.921 4.921 0 0 0-4.921 4.922c0 .375.047.75.14 1.125A14.28 14.28 0 0 1 1.642 1.187c-.422.704-.657 1.547-.657 2.485 0 1.687.844 3.187 2.204 4.078-.797-.047-1.594-.234-2.25-.61v.048c0 2.39 1.687 4.359 3.937 4.828-.375.093-.844.187-1.266.187-.328 0-.609-.047-.937-.094.61 1.97 2.437 3.375 4.594 3.422a9.895 9.895 0 0 1-6.094 2.11c-.422 0-.797-.047-1.172-.094 2.156 1.406 4.734 2.203 7.547 2.203 9.047 0 13.969-7.453 13.969-13.969v-.656Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)

export default SvgTwitterIcon
