import { useField } from 'react-final-form'
import { FormFieldProps } from '../../../../types/commonTypes'
import { DatePicker, DatePickerProps } from '../../DatePicker/DatePicker'
import { ERROR_ROUNDED_OUTLINE_CLASS_NAME, FormFieldWithError } from '../FormFieldError/FormFieldWithError'
import classNames from 'classnames'
import fieldStyles from '../FormFields.module.css'
import { safeIsNullOrEmpty } from '../../../../util/string'

export const DatePickerFormField: React.FC<
  FormFieldProps<
    Omit<DatePickerProps, 'onChangeDate' | 'initialValue' | 'placeholderText' | 'onChangeDate' | 'onChange' | 'date'>
  >
> = ({
  name,
  label,
  srOnlyLabel,
  id,
  inline,
  fieldContainerClassName,
  validateOnlyIfDirty,
  labelClassName,
  className,
  errorClassName,
  placeholder,
  ...rest
}) => {
  const { input } = useField(name)
  const onChangeDate = (newDate: string | null) => {
    input.onChange(newDate)
  }
  const date = safeIsNullOrEmpty(input.value) ? null : new Date(input.value)

  return (
    <FormFieldWithError
      name={name}
      wrapperClassName={classNames(inline && fieldStyles.inlineField, fieldContainerClassName)}
      validateOnlyIfDirty={validateOnlyIfDirty}
      errorClassName={errorClassName}
    >
      <label htmlFor={id ?? name} className={classNames(srOnlyLabel && 'sr-only', fieldStyles.label, labelClassName)}>
        {label}
      </label>
      <DatePicker
        {...rest}
        date={date}
        onChangeDate={onChangeDate}
        className={classNames(ERROR_ROUNDED_OUTLINE_CLASS_NAME, className)}
        placeholderText={placeholder}
      />
    </FormFieldWithError>
  )
}
