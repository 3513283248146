import classNames from 'classnames'
import {
  AnswerStateEnum,
  MultipleChoiceAnswerOption,
  ReceivedAnswer,
} from '../../../../../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../../../../../util/string'
import styles from '../AnswerOptionLayout.module.css'
import { TimeExceededInfo } from '../TimeExceededInfo'
import { ChoiceAnswer } from './ChoiceAnswer'

type MultipleChoiceAnswerProps = {
  answer: ReceivedAnswer
  answerOptions: MultipleChoiceAnswerOption[]
  maxPoints: number
  noPointsGame?: boolean
}

export const MultipleChoiceAnswer: React.FC<MultipleChoiceAnswerProps> = ({
  answer,
  answerOptions,
  maxPoints,
  noPointsGame,
}) => {
  if (answer.answerIndex === -1 || safeIsNullOrEmpty(answer.answerIndex)) {
    return <TimeExceededInfo />
  }

  return (
    <div
      className={classNames(
        styles.answerOptionsTable,
        answer.state === AnswerStateEnum.TIME_FINISHED && styles.timeExceededFadeout,
      )}
    >
      {answerOptions.map((option, index) => {
        const isCorrect = noPointsGame
          ? option.isCorrectAnswer
          : (index === answer.answerIndex && (answer.points ?? 0) > 0) ||
            (option.points === maxPoints && answer.points !== maxPoints)
        return (
          <ChoiceAnswer
            key={`${answer.id}_${index}`}
            text={option.text}
            imageUrl={option.imageUrl}
            points={option.points}
            isCorrectAnswer={isCorrect}
            wasSelected={answer.answerIndex === index}
            hidePoints={noPointsGame || answer.state === AnswerStateEnum.TIME_FINISHED}
          />
        )
      })}
    </div>
  )
}
