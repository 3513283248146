import classNames from 'classnames'
import { forwardRef } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { safeIsNullOrEmpty } from '../../../util/string'
import { getIcon } from '../icons/utils'
import styles from './DatePicker.module.css'

export type DatePickerProps = Partial<ReactDatePickerProps> & {
  date: Date | null
  onChangeDate: (newDateString: string | null) => void
  isClearable?: boolean
  allowFuture?: boolean
}

const dateToString = (date: Date): string => {
  return date.toISOString().split('T')[0]
}

const onCustomInputKeyDownCreator = (onClick: () => void) => (e: any) => {
  if (e.key === 'Enter') {
    onClick()
  }
}

export const DatePicker: React.FC<DatePickerProps> = ({
  date,
  onChangeDate,
  isClearable,
  allowFuture = false,
  maxDate,
  ...rest
}) => {
  const onChangeInternal = (newValue: Date | null) => {
    if (newValue instanceof Date) {
      newValue.setHours(12, 0)
      newValue.setMilliseconds(0)
      newValue.setSeconds(0)
      onChangeDate(dateToString(newValue))
    } else {
      onChangeDate(null)
    }
  }

  const handleClearClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    onChangeInternal(null)
  }

  const CustomInput = forwardRef<any, any>((props, ref) => {
    return (
      <div
        tabIndex={0}
        onKeyDown={onCustomInputKeyDownCreator(props.onClick)}
        onClick={props.onClick}
        ref={ref}
        className={styles.datePickerControlContainer}
      >
        <div className={styles.valueContainer}>
          <span
            className={classNames(styles.calendarIcon, !safeIsNullOrEmpty(props.value) && styles.calendarIconWithValue)}
          >
            {getIcon('calendar')}
          </span>
          <span className={safeIsNullOrEmpty(props.value) ? styles.placeholder : styles.value}>
            {safeIsNullOrEmpty(props.value) ? props.placeholder : props.value}
          </span>
        </div>
        {isClearable && !safeIsNullOrEmpty(props.value) && (
          <button tabIndex={0} className={styles.clearButton} onClick={handleClearClick}>
            {getIcon('close')}
          </button>
        )}
      </div>
    )
  })

  return (
    <ReactDatePicker
      selected={date}
      onChange={onChangeInternal}
      maxDate={maxDate || (allowFuture ? undefined : new Date())}
      customInput={<CustomInput />}
      {...rest}
    />
  )
}
