import classNames from 'classnames'
import styles from './AnswerOptionLayout.module.css'
import { PointsView } from './PointsView'

type AnswerPointsProps = {
  points: number
  active?: boolean
  hidePoints?: boolean
}

export const AnswerPoints: React.FC<AnswerPointsProps> = ({ points, active, hidePoints }) => {
  return (
    <div
      className={classNames(styles.answerOptionRightColumn, hidePoints && styles.answerOptionRightColumn_lessPadding)}
    >
      {!hidePoints && <PointsView points={points} active={active} />}
    </div>
  )
}
