import classNames from 'classnames'

import styles from '../Search.module.css'

export type LibrarySourceFilterItemProps = {
  label: string
  isActive: boolean
  onClick: () => void
}

export const LibrarySourceFilterItem: React.FC<LibrarySourceFilterItemProps> = ({ label, isActive, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.librarySourceButton, isActive && styles.librarySourceButtonActive)}
    >
      {label}
    </button>
  )
}
