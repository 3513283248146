import { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FormSubmitError } from '../../../../../common/components/Form/FormFieldError/FormSubmitError'
import { InputFormField } from '../../../../../common/components/Form/InputFormField/InputFormField'
import { Modal } from '../../../../../common/components/Modal/Modal'
import { Button } from '../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../common/components/icons/utils'
import { useDisableBodyScroll } from '../../../../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../../../../hooks/useTrapFocus'
import { Badge } from '../../../../../types/commonTypes'
import { areObjectsEqual } from '../../../../../util/functional'
import { safeIsNullOrEmpty } from '../../../../../util/string'
import { requiredValidation } from '../../../../../util/validate'
import { BadgeForm } from '../../../types'
import { SelectableBadge } from '../BadgeView/SelectableBadge'
import { READY_BADGE_URLS } from '../constants'
import styles from './BadgeModal.module.css'

type BadgeModalProps = {
  editBadge?: Badge
  onSubmit: (badgeForm: BadgeForm) => Promise<void>
  onClose: (hasChanges: boolean) => void
}

export const BadgeModal: React.FC<BadgeModalProps> = ({ editBadge, onSubmit, onClose }) => {
  const { t } = useTranslation()

  const modalRef = useTrapFocus<HTMLFormElement>()
  useDisableBodyScroll()

  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [imageBlob, setImageBlob] = useState<Blob>()
  const blobUrlRef = useRef<string>()

  const [initialValues] = useState<Partial<BadgeForm>>(() =>
    editBadge == null ? {} : { id: editBadge.id, badgeName: editBadge.name, imageUrl: editBadge.imageUrl },
  )

  const [customImageUrl, setCustomImageUrl] = useState<string | undefined>(() => {
    return editBadge?.imageUrl == null || READY_BADGE_URLS.includes(editBadge?.imageUrl)
      ? undefined
      : editBadge?.imageUrl
  })
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | undefined>(editBadge?.imageUrl)

  const handleAddImageClick = () => hiddenFileInput.current?.click()

  const handleFileInputChange = useCallback(() => {
    if (hiddenFileInput.current?.files?.[0] != null) {
      setImageBlob(hiddenFileInput.current.files[0])
    }
  }, [])

  useEffect(() => {
    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    if (imageBlob) {
      blobUrlRef.current = URL.createObjectURL(imageBlob)
      setCustomImageUrl(blobUrlRef.current)
      setSelectedImageUrl(blobUrlRef.current)
      setShowImageRequired(false)
    }
    return () => {
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
    }
  }, [imageBlob])

  const validate = (values: Partial<BadgeForm>) => {
    return { badgeName: requiredValidation(values.badgeName, t) }
  }

  const [showImageRequired, setShowImageRequired] = useState<boolean>(false)

  const onSubmitInternal = async (values: Partial<BadgeForm>) => {
    if (safeIsNullOrEmpty(selectedImageUrl)) {
      setShowImageRequired(true)
      return
    }
    return onSubmit({
      id: editBadge?.id,
      badgeName: values.badgeName!,
      imageFile: selectedImageUrl === blobUrlRef.current ? imageBlob : undefined,
      imageUrl: selectedImageUrl,
    })
  }

  const onClickBadge = (badge: Pick<Badge, 'imageUrl'>) => {
    setSelectedImageUrl((prev) => (prev === badge.imageUrl ? undefined : badge.imageUrl))
    setShowImageRequired(false)
  }

  return (
    <Modal>
      <Form<BadgeForm> initialValues={initialValues} onSubmit={onSubmitInternal} validate={validate}>
        {({ handleSubmit, submitting, values, initialValues }) => (
          <form className={styles.badgeModal} onSubmit={handleSubmit} ref={modalRef}>
            <div className={styles.badgeHeader}>
              <div className={styles.badgeHeaderTitle}>
                <span>{getIcon('badgeGradient')}</span>
                <span>
                  {initialValues.id == null
                    ? t('game_editor.badges.modal_title_add', 'Add badge')
                    : t('game_editor.badges.modal_title_edit', 'Edit badge')}
                </span>
              </div>
              <CloseButton autoFocus onClick={() => onClose(!areObjectsEqual(values, initialValues))} />
            </div>
            <div className={styles.badgeMainSection}>
              <InputFormField
                label={t('game_editor.badges.badge_name_label', 'Badge name')}
                placeholder={t('game_editor.badges.badge_name_placeholder', 'Name your badge')}
                srOnlyLabel
                name='badgeName'
                maxLength={40}
                showCharsCount
                fieldContainerClassName={styles.nameInput}
              />
              {customImageUrl && (
                <SelectableBadge
                  onSelect={onClickBadge}
                  badge={{ imageUrl: customImageUrl }}
                  isSelected={customImageUrl === selectedImageUrl}
                  otherSelected={selectedImageUrl != null && customImageUrl !== selectedImageUrl}
                  isBig={false}
                />
              )}
              <Button
                variant='outline-normal'
                disabled={submitting}
                onClick={handleAddImageClick}
                className={styles.addImageButton}
              >
                {getIcon('photo')}
                {customImageUrl
                  ? t('game_editor.badges.image_button_change', 'Change image')
                  : t('game_editor.badges.image_button_upload', 'Upload image')}
              </Button>
              <input
                type='file'
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                accept='image/*'
              />
              <span className='detailGrey700'>
                {t('game_editor.badges.select_badge_image', 'Or select a badge image from below:')}
              </span>
              <ul className={styles.readyBadgesList}>
                {READY_BADGE_URLS.map((url, index) => (
                  <li key={`readyBadgeView_${index}`} className={styles.readyBadgeListItem}>
                    <SelectableBadge
                      onSelect={onClickBadge}
                      badge={{ imageUrl: url }}
                      isSelected={url === selectedImageUrl}
                      otherSelected={selectedImageUrl != null && url !== selectedImageUrl}
                      isBig={false}
                    />
                  </li>
                ))}
              </ul>
              {showImageRequired && (
                <FormSubmitError
                  error={t(
                    'game_editor.badges.badge_image_required_validation',
                    'You must select a badge image, upload your own or pick from the list',
                  )}
                  modifiedSinceSubmit={false}
                />
              )}
            </div>
            <div className={styles.badgeFooter}>
              <Button
                variant='outline-normal'
                disabled={submitting}
                onClick={() => onClose(!areObjectsEqual(values, initialValues))}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button type='submit' disabled={submitting}>
                {t('common.save', 'Save')}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Modal>
  )
}
