import React from 'react'
import { getIcon } from '../../../../../common/components/icons/utils'
import QRCode from 'qrcode.react'
import styles from '../AddPerson.module.css'
import i18n from '../../../../../i18n'

type PinCodeAreProps = {
  pinCode?: string
  gameName: string
  is3D?: boolean
}

export const PinCodeArea: React.FC<PinCodeAreProps> = ({ pinCode, gameName, is3D = false }) => {
  return (
    <div className={styles.pinContainer}>
      <div className={styles.pinTitle}>
        <span>{gameName}</span>
      </div>
      <div className={styles.pinContent}>
        <div className={styles.qrCode}>
          <div className={styles.pinCode}>{pinCode}</div>
          <div className={styles.playSeppo}>
            <span className={styles.pinIcon}>{getIcon('exercisePin')}</span> play.seppo.io
          </div>
        </div>
        {!is3D && (
          <span className={styles.qr}>
            <QRCode
              value={`${window.location.protocol}//${window.location.host}/login?pin=${pinCode}&l=${i18n.language}`}
              renderAs='canvas'
              size={62}
            />
          </span>
        )}
      </div>
    </div>
  )
}
