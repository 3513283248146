import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../common/components/button/Button'
import { getIcon } from '../../../../../common/components/icons/utils'
import { IconTooltip } from '../../../../../common/components/tooltip/IconTooltip'
import { TUser } from '../../../../../types/commonTypes'
import sharedStyles from '../../../Settings.module.css'
import { EditablePreviewItem } from './EditablePreviewItem'
import styles from './Profile.module.css'

// NOTE: name attribute is displayed as email, chatName as in-game-alias, contactName as name
export type UserProfile = Pick<TUser, 'contactName' | 'chatName' | 'name'>

type ProfileSectionProps = {
  userProfile: UserProfile
  onUpdateField: (key: keyof Omit<UserProfile, 'name'>, value: string, label: string) => Promise<boolean>
  onResetPasswordClick: () => void
  onLogOutClick: () => void
  isResetPasswordLoading: boolean
}

export const ProfileSection: React.FC<ProfileSectionProps> = ({
  userProfile,
  onUpdateField,
  onResetPasswordClick,
  onLogOutClick,
  isResetPasswordLoading,
}) => {
  const { t } = useTranslation()

  return (
    <div className={sharedStyles.section}>
      <h1 className={sharedStyles.sectionTitle}>{t('settings_general.profile.title', 'Profile Settings')}</h1>
      <div className={classNames(sharedStyles.sectionCardContainer, styles.profileContainer)}>
        <EditablePreviewItem
          label={t('settings_general.profile.labels.name', 'Name')}
          initialValue={userProfile.contactName}
          onUpdate={(value: string) =>
            onUpdateField('contactName', value, t('settings_general.profile.labels.name', 'Name'))
          }
        />
        <div className={sharedStyles.divider} />
        <EditablePreviewItem
          label={t('settings_general.profile.labels.game_alias', 'In-game alias')}
          initialValue={userProfile.chatName}
          onUpdate={(value: string) =>
            onUpdateField('chatName', value, t('settings_general.profile.labels.game_alias', 'In-game alias'))
          }
        />
        <div className={sharedStyles.divider} />
        <div className={styles.rowContainer}>
          <label className={styles.firstColumn}>{t('settings_general.profile.labels.email', 'Email')}</label>
          <span className={classNames(styles.secondColumn, styles.textContent)}>{userProfile.name}</span>
          <IconTooltip
            tooltipContent={t(
              'settings_general.profile.change_email_tooltip',
              'To change your email, contact support.',
            )}
            iconClassName={styles.emailTooltipIcon}
            tooltipClassName={styles.emailTooltip}
          />
        </div>
        <div className={sharedStyles.divider} />
        <div className={styles.rowContainer}>
          <label className={styles.firstColumn}>{t('settings_general.profile.labels.password', 'Password')}</label>
          <span className={classNames(styles.secondColumn, styles.textContent)}>••••••••</span>
          <Button variant='outline-tiny' onClick={onResetPasswordClick} disabled={isResetPasswordLoading}>
            {t('settings_general.profile.buttons_text.reset_password', 'Reset')}
          </Button>
        </div>
        <div className={styles.logOutButtonContainer}>
          <Button variant='outline-tiny' onClick={onLogOutClick}>
            <span className='iconWrapperMedium'>{getIcon('logout')}</span>
            {t('settings_general.profile.buttons_text.log_out', 'Log out of this account')}
          </Button>
        </div>
      </div>
    </div>
  )
}
