import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../common/components/Form/InputFormField/InputFormField'
import { TextAreaFormField } from '../../../../../common/components/Form/TextAreaFormField/TextAreaFormField'
import styles from './Steps.module.css'

export const GAME_DESCRIPTION_MAX_LENGTH = 300

export const GameInfoStep: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <h1>{t('game_editor.wizard.game_info_step.title', 'Name the game')}</h1>
      <h4>{t('game_editor.wizard.game_info_step.subtitle', 'What will we call it?')}</h4>
      <InputFormField
        name='name'
        placeholder={t('game_editor.wizard.game_info_step.name_placeholder', 'Game name')}
        label={t('game_editor.wizard.game_info_step.name_label', 'Name')}
        inline
        fieldContainerClassName={styles.fieldContainer}
        inputContainerClassName={styles.field}
        className={styles.field}
      />
      <TextAreaFormField
        name='description'
        placeholder={t(
          'game_editor.wizard.game_info_step.description_placeholder',
          'Write a general description of the game',
        )}
        label={t('game_editor.wizard.game_info_step.description_label', 'Description')}
        inline
        fieldContainerClassName={styles.fieldContainer}
        className={styles.field}
        maxLength={GAME_DESCRIPTION_MAX_LENGTH}
      />
    </div>
  )
}
