import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Select, SelectOption, SelectVariant } from '../../../../../../../../../common/components/Select/Select'
import { DEFAULT_EVAL_SORT_BY_TASKS, EvaluationSortBy } from '../../../../types'
import styles from './TaskEvaluationSort.module.css'

type TaskEvaluationSortProps = {
  sortBy: EvaluationSortBy
  onSetSortBy: (sortBy: EvaluationSortBy) => void
}

const getSortByOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('game_editor.sidebar.evaluation_list_panel.evaluation_sort_answer_oldest_top', 'Answers: Oldest on top'),
    value: EvaluationSortBy.AnswerTimestamp_asc,
  },
  {
    label: t('game_editor.sidebar.evaluation_list_panel.evaluation_sort_answer_newest_top', 'Answers: Newest on top'),
    value: EvaluationSortBy.AnswerTimestamp_desc,
  },
  {
    label: t('game_editor.sidebar.evaluation_list_panel.evaluation_sort_player_name_a_z', 'Player name: A - Z'),
    value: EvaluationSortBy.PlayerName_asc,
  },
  {
    label: t('game_editor.sidebar.evaluation_list_panel.evaluation_sort_player_name_z_a', 'Player name: Z - A'),
    value: EvaluationSortBy.PlayerName_desc,
  },
]

export const TaskEvaluationSort: React.FC<TaskEvaluationSortProps> = ({ sortBy, onSetSortBy }) => {
  const { t } = useTranslation()

  const options = getSortByOptions(t)

  const selectedOption = options.find((o) => o.value === sortBy)

  const onChange = (option: SelectOption | null) => {
    if (option == null) {
      onSetSortBy(DEFAULT_EVAL_SORT_BY_TASKS)
    } else {
      onSetSortBy(option.value as EvaluationSortBy)
    }
  }

  return (
    <div className={styles.taskEvaluationSort}>
      <span className={'tiny grey-600'}>{t('common.sort_by', 'Sort by')}</span>
      <Select
        className={styles.taskEvaluationSortInput}
        variant={SelectVariant.Small}
        options={options}
        value={selectedOption}
        onChange={onChange}
      />
    </div>
  )
}
