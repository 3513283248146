import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Task } from '../../../types/commonTypes'
import { TaskPreview } from '../../TaskPreview/TaskPreview'
import styles from './GamePreview.module.css'

type ExercisesPreviewProps = {
  tasks: Task[]
  noPointsGame: boolean
}

export const ExercisesPreview: React.FC<ExercisesPreviewProps> = ({ tasks, noPointsGame }) => {
  const { t } = useTranslation()

  if (tasks.length === 0) {
    return (
      <div className={classNames(styles.exercisePreviewContainer, styles.centeredContainer)}>
        {t('game_preview_modal.no_tasks_text', 'No tasks added')}
      </div>
    )
  }

  return (
    <div className={styles.exercisePreviewContainer}>
      <ul className={styles.exercisesList}>
        {tasks.map((task) => (
          <TaskPreview key={`task_${task.id}`} task={task} noPointsGame={noPointsGame} />
        ))}
      </ul>
    </div>
  )
}
