import { useNavigate } from 'react-router-dom'
import { useTheme } from '../../contexts/ThemeContext'
import { routes } from '../../routes'
import styles from './Footer.module.css'

export const Footer: React.FC = () => {
  const navigate = useNavigate()
  const { logoUrl } = useTheme()

  return (
    <div className={styles.footer}>
      <img width={90} src={logoUrl} alt='logo' onClick={() => navigate(routes.homepage)} />
    </div>
  )
}
