import { useEffect, useState } from 'react'
import { ImageOverlay, useMap } from 'react-leaflet'
import { MapDimensions } from '../../../../api/gameTypes'
import { EDITOR_SIDEBAR_ID } from '../EditorSidebar/EditorSidebar'
import styles from './BoardSection.module.css'

type BoardImageType = {
  boardUrl: string
  boardDimensions?: MapDimensions
  onCoordsReady: (boardCoords: BoardCoords) => void
}

export type BoardCoords = {
  xZero: number
  yZero: number
  xMax: number
  yMax: number
  xMultiplier: number
  yMultiplier: number
  imageLeftOffset: number
}

export const BoardImage: React.FC<BoardImageType> = ({ boardUrl, boardDimensions, onCoordsReady }) => {
  const map = useMap()
  const [imageReady, setImageReady] = useState<boolean>(false)
  const [boardBounds, setBoardBounds] = useState<[number, number][]>([
    [0, 0],
    [1000, 1000],
  ])
  useEffect(() => {
    const sidebar = document.querySelector('#' + EDITOR_SIDEBAR_ID)
    const imageLeftOffset =
      sidebar != null ? sidebar.getBoundingClientRect().x + sidebar.getBoundingClientRect().width + 50 : 0
    onCoordsReady({
      xZero: boardBounds[0][1],
      yZero: boardBounds[0][0],
      xMax: boardBounds[1][1],
      yMax: boardBounds[1][0],
      xMultiplier: (boardBounds[1][1] - boardBounds[0][1]) / 100,
      yMultiplier: (boardBounds[1][0] - boardBounds[0][0]) / 100,
      imageLeftOffset,
    })
  }, [boardBounds, onCoordsReady])

  useEffect(() => {
    try {
      const newBoundsSouthWest: [number, number] = [map.getBounds().getSouth() + 50, map.getBounds().getWest()]
      const newBoundsNorthEast: [number, number] = [
        map.getBounds().getSouth() + 50 + (boardDimensions?.y ?? 1000),
        map.getBounds().getWest() + (boardDimensions?.x ?? 1000),
      ]
      setBoardBounds([newBoundsSouthWest, newBoundsNorthEast])
      setImageReady(true)
    } catch (e) {
      console.error('Map was not ready yet ' + e)
    }
  }, [map, boardDimensions])
  return imageReady ? <ImageOverlay url={boardUrl} bounds={boardBounds} className={styles.leafletBackground} /> : null
}
