import { useEffect, useState } from 'react'
import styles from './FadeAnimations.module.css'

export const useFadeAnimation = (fetchingData?: boolean, partialFade?: boolean) => {
  const [componentMounted, setComponentMounted] = useState<boolean>(false)
  useEffect(() => {
    setComponentMounted(true)
    return () => {
      setComponentMounted(false)
    }
  }, [])

  const fade: string = componentMounted ? styles.fadeIn : styles.fadeOut
  const fetching: string = fetchingData ? (partialFade ? styles.fadeOutPartial : styles.fadeOut) : ''
  const fadeTransition = `${styles.initial} ${fade} ${fetching}`

  const closeWithDelay = (delay: number, cb: () => void) => {
    setComponentMounted(false)
    setTimeout(() => {
      cb()
    }, delay)
  }

  return { fadeTransition, closeWithDelay }
}
