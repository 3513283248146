import * as React from 'react'
import { SVGProps } from 'react'

const SvgIgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.188 21V6.984H.827V21h4.36ZM2.983 5.11c1.407 0 2.532-1.173 2.532-2.579 0-1.36-1.125-2.484-2.532-2.484A2.5 2.5 0 0 0 .5 2.53C.5 3.937 1.625 5.11 2.984 5.11ZM21.454 21h.046v-7.688c0-3.75-.844-6.656-5.25-6.656-2.11 0-3.516 1.172-4.125 2.25h-.047V6.984H7.906V21h4.36v-6.938c0-1.828.328-3.562 2.578-3.562s2.297 2.063 2.297 3.703V21h4.312Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)

export default SvgIgIcon
