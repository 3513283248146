import { useTranslation } from 'react-i18next'
import { CardPaymentInfo } from '../../../../types/commonTypes'
import styles from '../BillingSettings.module.css'

type CreditCardProps = {
  paymentInfo: CardPaymentInfo
}

export const CreditCard: React.FC<CreditCardProps> = ({ paymentInfo: { maskedNumber, validUntil } }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.subSection}>
      <h3 className={styles.subSectionTitle}>{t('settings_billing.credit_card_title', 'Credit card')}</h3>
      <div className={styles.cardExpireInfo}>
        <span>{maskedNumber}</span>
        <span>
          {t('settings_billing.card_card_expire_info', {
            defaultValue: 'Expires: %{card_expiration}',
            card_expiration: validUntil,
          })}
        </span>
      </div>
    </div>
  )
}
