import { ScoreGradientIcon } from '../../../common/components/icons'
import styles from './TaskHeaderDetails.module.css'

type TaskHeaderDetailsProps = {
  typeTitle: string
  points: number
  noPointsGame?: boolean
}

export const TaskHeaderDetails: React.FC<TaskHeaderDetailsProps> = ({ typeTitle, points, noPointsGame }) => {
  return (
    <div className={styles.taskPreviewHeaderDetails}>
      <div className={styles.tag}>{typeTitle}</div>
      {!noPointsGame && (
        <div className={styles.iconAndPointsContainer}>
          <ScoreGradientIcon />
          {points}
        </div>
      )}
    </div>
  )
}
