import { useBusinessSettings } from '../../../contexts/BusinessSettingsContext'
import { useUser } from '../../../contexts/userContext'
import styles from '../Settings.module.css'
import { Members } from './components/Members'

export const MembersSettings: React.FC = () => {
  const { user } = useUser()
  const { addMembers, removeMember, isFetchingData, changeMemberRole, data } = useBusinessSettings()

  if (user == null) {
    return null
  }

  return (
    <div className={styles.sectionsContainer}>
      <Members
        members={data?.members ?? []}
        organizationName={user.activeBusiness.name}
        isLoading={isFetchingData}
        onRemoveMember={removeMember}
        onChangeMemberRole={changeMemberRole}
        onAddMembers={addMembers}
        isShowingAll
      />
    </div>
  )
}
