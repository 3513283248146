import { ObjectGeneric } from '../../types/commonTypes'

export const ORGANISATION_TYPE_OPTIONS = [
  // t('onboarding_wizard.organisation_types.business', 'Business')
  { value: 'Business', labelKey: 'onboarding_wizard.organisation_types.business' },
  // t('onboarding_wizard.organisation_types.education', 'Education')
  { value: 'Education', labelKey: 'onboarding_wizard.organisation_types.education' },
  // t('onboarding_wizard.organisation_types.non_profit', 'Non-profit')
  { value: 'Non-profit', labelKey: 'onboarding_wizard.organisation_types.non_profit' },
]

export const ALL_ORG_TYPES = ORGANISATION_TYPE_OPTIONS.map((option) => option.value)

export const ORGANISATION_SUBTYPE_OPTIONS: ObjectGeneric<{ value: string; labelKey: string }[]> = {
  Business: [
    // t('onboarding_wizard.business_types.company', 'Company')
    { value: 'Company', labelKey: 'onboarding_wizard.business_types.company' },
    // t('onboarding_wizard.business_types.training_company', 'Training Company')
    { value: 'Training Company', labelKey: 'onboarding_wizard.business_types.training_company' },
    // t('onboarding_wizard.business_types.consulting', 'Consulting')
    { value: 'Consulting', labelKey: 'onboarding_wizard.business_types.consulting' },
    // t('onboarding_wizard.business_types.event_organiser', 'Event Organiser')
    { value: 'Event Organizer', labelKey: 'onboarding_wizard.business_types.event_organiser' },
  ],
  Education: [
    // t('onboarding_wizard.education_types.primary', 'Primary')
    { value: 'Primary', labelKey: 'onboarding_wizard.education_types.primary' },
    // t('onboarding_wizard.education_types.secondary', 'Secondary')
    { value: 'Secondary', labelKey: 'onboarding_wizard.education_types.secondary' },
    // t('onboarding_wizard.education_types.high_school', 'High School')
    { value: 'High School', labelKey: 'onboarding_wizard.education_types.high_school' },
    // t('onboarding_wizard.education_types.vocational_school', 'Vocational School')
    { value: 'Vocational School', labelKey: 'onboarding_wizard.education_types.vocational_school' },
    // t('onboarding_wizard.education_types.university', 'University')
    { value: 'University', labelKey: 'onboarding_wizard.education_types.university' },
  ],
  'Non-profit': [
    // t('onboarding_wizard.non_profit_types.library', 'Library')
    { value: 'Library', labelKey: 'onboarding_wizard.non_profit_types.library' },
    // t('onboarding_wizard.non_profit_types.museum', 'Museum')
    { value: 'Museum', labelKey: 'onboarding_wizard.non_profit_types.museum' },
    // t('onboarding_wizard.non_profit_types.ngo', 'Non-Governmental Organisation')
    { value: 'NGO', labelKey: 'onboarding_wizard.non_profit_types.ngo' },
    // t('onboarding_wizard.non_profit_types.public_sector', 'Public sector')
    { value: 'Public sector', labelKey: 'onboarding_wizard.non_profit_types.public_sector' },
  ],
}

export const BUSINESS_TOPICS_OPTIONS = [
  // t('onboarding_wizard.business_topics.onboarding', 'Help onboard a new employee')
  { value: 'Onboarding', labelKey: 'onboarding_wizard.business_topics.onboarding' },
  // t('onboarding_wizard.business_topics.strategy_and_values_engagement', 'Get engagement on our strategy and values')
  { value: 'Engagement', labelKey: 'onboarding_wizard.business_topics.strategy_and_values_engagement' },
  // t('onboarding_wizard.business_topics.build_team_spirit', 'Build team spirit')
  { value: 'TeamSpirit', labelKey: 'onboarding_wizard.business_topics.build_team_spirit' },
  // t('onboarding_wizard.business_topics.safety_training', 'Make safety training engaging')
  { value: 'SafetyTraining', labelKey: 'onboarding_wizard.business_topics.safety_training' },
  // t('onboarding_wizard.business_topics.customer_service_training', 'Customer service training')
  { value: 'CustomerService', labelKey: 'onboarding_wizard.business_topics.customer_service_training' },
  // t('onboarding_wizard.business_topics.recruitment', 'Recruit in a fresh way')
  { value: 'Recruitment', labelKey: 'onboarding_wizard.business_topics.recruitment' },
]

export const SCHOOL_SUBJECTS_OPTIONS = [
  // t('onboarding_wizard.school_subjects.arts', 'Arts')
  { value: 'Arts', labelKey: 'onboarding_wizard.school_subjects.arts' },
  // t('onboarding_wizard.school_subjects.biology', 'Biology')
  { value: 'Biology', labelKey: 'onboarding_wizard.school_subjects.biology' },
  // t('onboarding_wizard.school_subjects.environmental_studies', 'Environmental studies')
  { value: 'Environmental-studies', labelKey: 'onboarding_wizard.school_subjects.environmental_studies' },
  // t('onboarding_wizard.school_subjects.geography', 'Geography')
  { value: 'Geography', labelKey: 'onboarding_wizard.school_subjects.geography' },
  // t('onboarding_wizard.school_subjects.history', 'History')
  { value: 'History', labelKey: 'onboarding_wizard.school_subjects.history' },
  // t('onboarding_wizard.school_subjects.languages', 'Languages')
  { value: 'Languages', labelKey: 'onboarding_wizard.school_subjects.languages' },
  // t('onboarding_wizard.school_subjects.math', 'Math')
  { value: 'Math', labelKey: 'onboarding_wizard.school_subjects.math' },
  // t('onboarding_wizard.school_subjects.physical_education', 'Physical education')
  { value: 'PE', labelKey: 'onboarding_wizard.school_subjects.physical_education' },
  // t('onboarding_wizard.school_subjects.social_studies', 'Social studies')
  { value: 'Social-studies', labelKey: 'onboarding_wizard.school_subjects.social_studies' },
]

export const UNIVERSITY_SUBJECTS_OPTIONS = [
  // t('onboarding_wizard.university_subjects.arts', 'Arts')
  { value: 'Arts', labelKey: 'onboarding_wizard.university_subjects.arts' },
  // t('onboarding_wizard.university_subjects.economics', 'Economics')
  { value: 'Economics', labelKey: 'onboarding_wizard.university_subjects.economics' },
  // t('onboarding_wizard.university_subjects.education', 'Education')
  { value: 'Education', labelKey: 'onboarding_wizard.university_subjects.education' },
  // t('onboarding_wizard.university_subjects.engineering_and_science', 'Engineering & Science')
  { value: 'Engineering-Science', labelKey: 'onboarding_wizard.university_subjects.engineering_and_science' },
  // t('onboarding_wizard.university_subjects.health_and_social', 'Health & Social')
  { value: 'Health-Social', labelKey: 'onboarding_wizard.university_subjects.health_and_social' },
  // t('onboarding_wizard.university_subjects.languages', 'Languages')
  { value: 'Languages', labelKey: 'onboarding_wizard.university_subjects.languages' },
  // t('onboarding_wizard.university_subjects.orientation_and_team_building', 'Orientation & Team building')
  {
    value: 'Orientation-Team-building',
    labelKey: 'onboarding_wizard.university_subjects.orientation_and_team_building',
  },
  // t('onboarding_wizard.university_subjects.services', 'Services')
  { value: 'Services', labelKey: 'onboarding_wizard.university_subjects.services' },
]

export const PLAYER_OPTIONS = [
  // t('onboarding_wizard.player_options.0_50', '0-50')
  { value: '1', labelKey: 'onboarding_wizard.player_options.0_50' },
  // t('onboarding_wizard.player_options.51_100', '51-100')
  { value: '2', labelKey: 'onboarding_wizard.player_options.51_100' },
  // t('onboarding_wizard.player_options.101_300', '101-300')
  { value: '3', labelKey: 'onboarding_wizard.player_options.101_300' },
  // t('onboarding_wizard.player_options.301_1000', '301-1000')
  { value: '4', labelKey: 'onboarding_wizard.player_options.301_1000' },
  // t('onboarding_wizard.player_options.1000+', '1000+')
  { value: '5', labelKey: 'onboarding_wizard.player_options.1000+' },
  // t('onboarding_wizard.player_options.unknown', `I don't know yet`)
  { value: '6', labelKey: 'onboarding_wizard.player_options.unknown' },
]

export const OTHER_OPTION_VALUE = 'Other'

export const COUNTRY_SELECT_OPTIONS = [
  { value: 'Albania', label: 'Albania' },
  { value: 'AndorrA', label: 'Andorra' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hong Kong', label: 'Hong Kong' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'New Zeland', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Saudi-Arabia', label: 'Saudi Arabia' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'Holland', label: 'The Netherlands' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'UAE', label: 'United Arab Emirates' },
  { value: 'UK', label: 'United Kingdom' },
  { value: 'USA', label: 'United States of America' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Other', label: 'Other' },
]

export const COUNTRY_SELECT_DEFAULT_OPTION = { value: 'Finland', label: 'Findland' }
