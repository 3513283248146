import classNames from 'classnames'
import React from 'react'

import { getIcon, IconName } from '../icons/utils'
import styles from './Radio.module.css'
import { isFirefox } from '../../../util/browserSpecifics'

type RadioItemProps = {
  name: string
  id: string
  label: string
  iconName: IconName
  centered?: boolean
  isChecked?: string
  showBadge?: boolean
  newMessagesCount?: number
  onSelect?: (e: React.SyntheticEvent<HTMLInputElement>) => void
}

export const RadioItem: React.FC<RadioItemProps> = ({
  name,
  id,
  label,
  iconName,
  isChecked,
  centered = false,
  showBadge = false,
  newMessagesCount,
  onSelect,
}) => {
  return (
    <label
      className={classNames(
        styles.radioItem,
        styles.wrapper,
        isChecked === id && styles.wrapperShadow,
        centered && styles.contentCentered,
      )}
    >
      <span className={classNames(styles.iconContainer, isChecked === id && styles.iconContainerChecked)}>
        {getIcon(iconName)}
      </span>
      <input type='radio' name={name} id={id} onChange={onSelect} checked={isChecked === id} />
      {isFirefox() && <div className={styles.firefoxOutlineWorkaround} />}
      {showBadge && <span className={styles.notificationBadge} />}
      <span className={styles.labelText}>
        {label}
        {newMessagesCount != null && ` (${newMessagesCount})`}
      </span>
    </label>
  )
}
