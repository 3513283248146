import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { PAGE_SIZE, cloneLibraryGame, getGamePackage } from '../api/gameApiService'
import { LibrarySource } from '../api/gameTypes'
import { parseGamePackage, parseLibraryGamesResponseToGameCards } from '../api/typeConverters'
import { PaginationSelectOption } from '../composites/GamesOverview/components/PaginationControls'
import { useGameNotifications } from '../hooks/useGameNotifications'
import { GamePackage, TGameCard } from '../types/commonTypes'
import { noop } from '../util/functional'
import { GameDataProviderInterface, useGameData as useOwnGameData } from './OwnGamesContextProvider'
import { UserContext, UserContextType } from './userContext'

interface Props {
  packageId: number | null
  children?: React.ReactNode
}

const GamePackageGamesDataContext = createContext<GameDataProviderInterface & { gamePackage: GamePackage | undefined }>(
  {
    currentPage: 1,
    pageSize: PAGE_SIZE,
    pageData: undefined,
    loading: true,
    lastPage: 1,
    allPageOptions: [] as PaginationSelectOption[],
    selectPageSize: noop,
    prev: noop,
    next: noop,
    setPage: noop,
    copyGame: noop,
    deleteGame: noop,
    importGame: noop,
    refreshGames: noop,
    gamePackage: undefined,
  },
)

export const GamePackageGamesDataProvider: React.FC<Props> = ({ packageId, children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [gamePackage, setGamePackage] = useState<GamePackage>()
  const [pageData, setPageData] = useState<TGameCard[]>()
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)
  const [loading, setLoading] = useState(true)
  const [lastPage] = useState<number>(1)
  const [allPageOptions] = useState<PaginationSelectOption[]>([{ value: 1, label: '1' }])
  const ownGameData = useOwnGameData()
  const { user } = React.useContext(UserContext) as UserContextType

  const { notifyGameImportStart, notifyGameImportFinish, notifyFetchingGamesFailed } = useGameNotifications()

  const next = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState + 1)
  }, [loading])

  const prev = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState - 1)
  }, [loading])

  const selectPageSize = useCallback(
    (newPageSize: number) => {
      if (loading) {
        return
      }

      setCurrentPage(1)
      setPageSize(newPageSize)
    },
    [loading],
  )

  const setPage = useCallback(
    (newPage: number) => {
      if (loading) {
        return
      }

      setCurrentPage(newPage)
    },
    [loading],
  )

  useEffect(() => {
    const abortController = new AbortController()
    if (!packageId || !user?.gamePackages?.find((gamePackage) => gamePackage.id === packageId)) return
    setLoading(true)
    getGamePackage({
      packageId: packageId,
    })
      .then((gamePackageResponse) => {
        if (gamePackageResponse.success) {
          const gamePackageTemp = parseGamePackage(gamePackageResponse.value)
          setGamePackage(gamePackageTemp)
          setPageData(
            parseLibraryGamesResponseToGameCards(gamePackageResponse.value, LibrarySource.PACKAGE, gamePackageTemp),
          )
        } else {
          notifyFetchingGamesFailed()
          console.error(gamePackageResponse.error)
        }
      })
      .finally(() => setLoading(false))
    return () => abortController.abort()
  }, [notifyFetchingGamesFailed, packageId, user?.gamePackages])

  const refreshGames = useCallback(() => {
    if (!packageId || !user?.gamePackages?.find((gamePackage) => gamePackage.id === packageId)) return
    setLoading(true)
    getGamePackage({
      packageId: packageId,
    }).then((gamePackageResponse) => {
      if (gamePackageResponse.success) {
        const gamePackageTemp = parseGamePackage(gamePackageResponse.value)
        setPageData(
          parseLibraryGamesResponseToGameCards(gamePackageResponse.value, LibrarySource.PACKAGE, gamePackageTemp),
        )
      } else {
        notifyFetchingGamesFailed()
        console.error(gamePackageResponse.error)
      }
    })
  }, [notifyFetchingGamesFailed, packageId, user?.gamePackages])

  const importGame = useCallback(
    async (gameId: number, gameName: string) => {
      const notifyId = notifyGameImportStart(gameName)
      const cloneResponse = await cloneLibraryGame({ gameId })
      if (cloneResponse.success) {
        ownGameData.refreshGames()
      } else {
        console.error(cloneResponse.error)
      }
      notifyGameImportFinish(notifyId, gameName, cloneResponse.success)
    },
    [notifyGameImportStart, notifyGameImportFinish, ownGameData],
  )

  return (
    <GamePackageGamesDataContext.Provider
      value={{
        currentPage,
        pageSize,
        pageData,
        lastPage,
        allPageOptions,
        loading,
        setPage,
        selectPageSize,
        next,
        prev,
        copyGame: noop,
        deleteGame: noop,
        importGame,
        refreshGames,
        gamePackage,
      }}
    >
      {children}
    </GamePackageGamesDataContext.Provider>
  )
}

export const useGameData = () => {
  const context = useContext(GamePackageGamesDataContext)

  return context
}
