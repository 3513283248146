import classNames from 'classnames'
import { t } from 'i18next'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import styles from './LoginMethod.module.css'

export const GuestInstructor: React.FC = () => {
  const { fadeTransition } = useFadeAnimation()

  return (
    <div className={classNames(styles.mainContentWrapper, fadeTransition)}>
      <h1 className={styles.title}>{t('login.instructor.right.guest.title', "Let's start our engines")}</h1>
      <div className={styles.paragraphContainer}>
        <p>{t('login.instructor.right.guest.body', 'Please enter your instructor login code below.')}</p>
      </div>
      <InputFormField
        label='Password'
        placeholder={t('login.instructor.right.guest.placeholder', 'Instructor login code')}
        srOnlyLabel
        name='password'
        type='password'
        big
      />
    </div>
  )
}
