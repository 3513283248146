import { useDroppable } from '@dnd-kit/core'
import styles from './SortableItemPlaceholder.module.css'

type SortableItemPlaceholderProps = {
  levelIndex: number
  boardIndex: number
}

export const SortableItemPlaceholder: React.FC<SortableItemPlaceholderProps> = ({ levelIndex, boardIndex }) => {
  const { setNodeRef } = useDroppable({
    id: `placeholder-${levelIndex}_${boardIndex}`,
    data: { task: { level: levelIndex, mapIndex: boardIndex, order: 1 } },
  })

  return <div ref={setNodeRef} className={styles.sortablePlaceholder} />
}
