import DOMPurify from 'dompurify'
import { TFunction } from 'i18next'
import { LibrarySource } from '../../../api/gameTypes'
import { EMPTY_CONTENT_FALLBACK } from '../../../common/constants'
import { TGameCard } from '../../../types/commonTypes'
import { getDisplayExercisesAndMaps, getDisplayGameOwnersString } from '../../../util/game'
import styles from './GamePreviewModalSidebar.module.css'
import { InfoWithIcon } from './SidebarInfoList'
import { InfoTab } from './SidebarInfoTabs'

export const getInfoListForGame = (game: TGameCard, t: TFunction): InfoWithIcon[] => {
  switch (game.librarySource) {
    case LibrarySource.OWN:
      const ownInfos: InfoWithIcon[] = [
        {
          icon: 'profileCircled',
          info: getDisplayGameOwnersString(game.gameOwners),
        },
        {
          icon: 'exercises',
          info: getDisplayExercisesAndMaps(game.exercisesCount ?? 0, game.mapsCount ?? 0, t),
        },
        {
          icon: 'people',
          info:
            game.playersCount != null
              ? t('game_info.players_count', { count: game.playersCount, defaultValue: '%{count} players' })
              : EMPTY_CONTENT_FALLBACK,
        },
      ]
      if (game.validUntil) {
        ownInfos.push({
          icon: 'calendar',
          info: t('game_info.expires', {
            defaultValue: 'Expires: %{validUntil}',
            validUntil: game.validUntil.split('T')[0],
          }),
        })
      }
      return ownInfos
    default:
      const otherInfos: InfoWithIcon[] = [
        {
          icon: 'profileCircled',
          info: getDisplayGameOwnersString(game.gameOwners),
        },
        {
          icon: 'starOutline',
          info: t('game_info.rating_average', {
            defaultValue: '%{game_rating} / 5, (%{reviews_count} reviews)',
            game_rating: game.rating?.average ?? 0,
            reviews_count: game.rating?.count ?? 0,
            count: game.rating?.count ?? 0,
          }),
        },
        {
          icon: 'downloadsOutline',
          info: t('game_info.downloads', {
            defaultValue: '%{downloads_count} downloads',
            downloads_count: game.downloaded ?? 0,
          }),
        },
      ]
      if (game.validUntil) {
        otherInfos.push({
          icon: 'calendar',
          info: t('game_info.expires', {
            defaultValue: 'Expires: %{validUntil}',
            validUntil: game.validUntil.split('T')[0],
          }),
        })
      }
      return otherInfos
  }
}

export const getInfoTabsForGame = (game: TGameCard, t: TFunction): InfoTab[] => {
  const infoTab: InfoTab = {
    title: t('game_preview_modal.side_bar_tabs.info.tab_title', 'Info'),
    content: (
      <div>
        <div>
          <h3 className={styles.sidebarTabTitle}>
            {t('game_preview_modal.side_bar_tabs.info.instructions', 'Instructions')}
          </h3>
          <div
            className={styles.sidebarTabContent}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(game.instructionsForTeacherHtml || EMPTY_CONTENT_FALLBACK),
            }}
          />
        </div>
        <div>
          <h3 className={styles.sidebarTabTitle}>{t('game_preview_modal.side_bar_tabs.info.story', 'Story')}</h3>
          <div
            className={styles.sidebarTabContent}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(game.storyHtml || EMPTY_CONTENT_FALLBACK) }}
          />
        </div>
        <div>
          <h3 className={styles.sidebarTabTitle}>{t('game_preview_modal.side_bar_tabs.info.rules', 'Rules')}</h3>
          <div
            className={styles.sidebarTabContent}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(game.rulesHtml || EMPTY_CONTENT_FALLBACK) }}
          />
        </div>
      </div>
    ),
  }
  switch (game.librarySource) {
    case LibrarySource.OWN:
      //TODO: outscoped for now, but own games will have messages and players tabs as well.
      return [infoTab]
    default:
      return [infoTab]
  }
}
