import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { safeIsNullOrEmpty } from '../../../../../../../../../util/string'
import layoutStyles from '../AnswerOptionLayout.module.css'
import { AnswerStatus, AnswerStatusIcon } from '../AnswerStatusIcon'
import styles from './MissingWordAnswer.module.css'

type MissingWordPropsIncorrect = {
  word: string
  correct: string
  timeExceeded: boolean
}

export const MissingWordIncorrect: React.FC<MissingWordPropsIncorrect> = ({ word, correct, timeExceeded }) => {
  const { t } = useTranslation()
  const noAnswer = timeExceeded && safeIsNullOrEmpty(word)

  return (
    <div className={layoutStyles.answerOptionLeftColumn}>
      <div className={styles.missingWordIncorrectContent}>
        <div
          className={classNames(
            styles.missingWordIncorrectColumn,
            styles.missingWordContainer,
            styles.missingWordError,
            noAnswer && styles.missingWordNoAnswer,
          )}
        >
          {noAnswer ? t('game_editor.sidebar.evaluate_answer_panel.time_exceeded_no_answer', 'No answer') : word}
          <AnswerStatusIcon status={AnswerStatus.ERROR} />
        </div>
        <div
          className={classNames(
            styles.missingWordIncorrectColumn,
            styles.missingWordContainer,
            styles.missingWordWarning,
          )}
        >
          {correct}
          <AnswerStatusIcon status={AnswerStatus.WARNING} />
        </div>
      </div>
    </div>
  )
}
