import * as React from 'react'
import { SVGProps } from 'react'

const SvgHelpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M19.375 25.625a1.875 1.875 0 1 1 3.75 0 1.875 1.875 0 0 1-3.75 0Z' fill='#CC3E76' />
    <path
      d='M19.375 25.625a1.875 1.875 0 1 1 3.75 0 1.875 1.875 0 0 1-3.75 0Z'
      fill={props.color ?? 'url(#help_icon_svg__a)'}
      fillOpacity={props.color ? 1 : 0.25}
    />
    <path
      d='M17.5 16.25A3.75 3.75 0 1 1 21.25 20a1.25 1.25 0 0 0 0 2.5A6.25 6.25 0 1 0 15 16.25a1.25 1.25 0 0 0 2.5 0Z'
      fill='#CC3E76'
    />
    <path
      d='M17.5 16.25A3.75 3.75 0 1 1 21.25 20a1.25 1.25 0 0 0 0 2.5A6.25 6.25 0 1 0 15 16.25a1.25 1.25 0 0 0 2.5 0Z'
      fill={props.color ?? 'url(#help_icon_svg__b)'}
      fillOpacity={props.color ? 1 : 0.25}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.25 0a18.749 18.749 0 0 1 9.937 2.829c8.793 5.47 11.488 17.032 6.018 25.825-5.347 8.596-16.518 11.363-25.231 6.372L1.788 39.878A1.25 1.25 0 0 1 .12 38.214l4.851-10.189A18.728 18.728 0 0 1 2.5 18.698C2.514 8.357 10.909-.013 21.25 0Zm8.616 4.951A16.25 16.25 0 0 0 21.253 2.5h-.005C12.287 2.488 5.012 9.74 5 18.702a16.23 16.23 0 0 0 2.45 8.596c.225.362.25.813.067 1.198l-3.624 7.61 7.61-3.625a1.25 1.25 0 0 1 1.197.068c7.62 4.74 17.642 2.405 22.382-5.216 4.74-7.62 2.405-17.641-5.216-22.382Z'
      fill='#CC3E76'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.25 0a18.749 18.749 0 0 1 9.937 2.829c8.793 5.47 11.488 17.032 6.018 25.825-5.347 8.596-16.518 11.363-25.231 6.372L1.788 39.878A1.25 1.25 0 0 1 .12 38.214l4.851-10.189A18.728 18.728 0 0 1 2.5 18.698C2.514 8.357 10.909-.013 21.25 0Zm8.616 4.951A16.25 16.25 0 0 0 21.253 2.5h-.005C12.287 2.488 5.012 9.74 5 18.702a16.23 16.23 0 0 0 2.45 8.596c.225.362.25.813.067 1.198l-3.624 7.61 7.61-3.625a1.25 1.25 0 0 1 1.197.068c7.62 4.74 17.642 2.405 22.382-5.216 4.74-7.62 2.405-17.641-5.216-22.382Z'
      fill={props.color ?? 'url(#help_icon_svg__c)'}
      fillOpacity={props.color ? 1 : 0.25}
    />
    <defs>
      <linearGradient id='help_icon_svg__a' x1={20.018} y1={0} x2={20.018} y2={40} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='help_icon_svg__b' x1={20.018} y1={0} x2={20.018} y2={40} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient id='help_icon_svg__c' x1={20.018} y1={0} x2={20.018} y2={40} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgHelpIcon
