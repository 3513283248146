import * as React from 'react'
import { SVGProps } from 'react'

const SvgArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.159 1.933A1.111 1.111 0 1 1 2.73.362l7.071 7.07 1.572 1.572L9.8 10.575 2.73 17.647a1.111 1.111 0 0 1-1.571-1.572l7.07-7.07-7.07-7.072Z'
      fill={props.color ?? '#F2F2F2'}
    />
  </svg>
)

export default SvgArrowRightIcon
