import * as React from 'react'
import { SVGProps } from 'react'

const SvgTrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 3a3 3 0 0 0-3 3v1H3a1 1 0 0 0 0 2h3v17a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V9h3a1 1 0 1 0 0-2h-7V6a3 3 0 0 0-3-3h-6Zm7 4V6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v1h8Zm-9 2H8v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9H11Zm2 4a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0v-8a1 1 0 0 1 1-1Zm7 9v-8a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0Z'
      fill={props.color ?? 'var(--grey-700)'}
    />
  </svg>
)

export default SvgTrashIcon
