import { useState } from 'react'
import { Field, useField } from 'react-final-form'
import { getFieldError } from '../../../../../../util/form'
import { AddTime } from '../../../AddTime/AddTime'
import styles from './AddTimeSettings.module.css'

type AddTimeSettingsProps = {
  viewOnly: boolean
}

export const AddTimeSettings: React.FC<AddTimeSettingsProps> = ({ viewOnly }) => {
  const { input, meta } = useField('advanced.timeLimitSeconds', { type: 'number' })
  const [initialTime] = useState<number>(input.value)

  const onAddTimeChange = (seconds: number) => {
    input.onChange(seconds)
  }

  const error = getFieldError(meta)

  return (
    <>
      <Field component='input' name='advanced.timeLimitSeconds' type='hidden' />
      <AddTime
        onChange={onAddTimeChange}
        className={styles.addTimeContainer}
        error={error}
        initialTimeInSeconds={initialTime}
        disabled={viewOnly}
      />
    </>
  )
}
