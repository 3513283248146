import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../../../../../common/components/icons/utils'
import styles from './SubtaskDescription.module.css'

type SubtaskDescriptionProps = {
  description: string
}

export const SubtaskDescription: React.FC<SubtaskDescriptionProps> = ({ description }) => {
  const { t } = useTranslation()

  const [showDescription, setShowDescription] = useState<boolean>(false)
  const toggleShowDescription = () => setShowDescription((prev) => !prev)

  return (
    <div className={classNames(styles.subtaskDescriptionContainer, 'tiny')}>
      <div className={styles.subtaskDescriptionHeader}>
        <span onClick={toggleShowDescription}>
          {t('game_editor.sidebar.evaluate_answer_panel.show_task_info', 'Show task info')}
        </span>
        <button
          className={classNames(showDescription && styles.iconExpanded)}
          type='button'
          onClick={toggleShowDescription}
        >
          {getIcon('arrowDown')}
        </button>
      </div>
      {showDescription && (
        <div
          className={styles.subtaskDescriptionContent}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description ?? '', {
              FORBID_TAGS: ['button'],
              KEEP_CONTENT: false,
            }),
          }}
        />
      )}
    </div>
  )
}
