import classNames from 'classnames'
import { PropsWithChildren, useEffect, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { useOnEscPress } from '../../../hooks/useOnEscPress'
import { useTrapFocus } from '../../../hooks/useTrapFocus'
import { IconName, getIcon } from '../icons/utils'
import { Tooltip } from '../tooltip/Tooltip'
import styles from './PopupMenu.module.css'

export const MORE_MENU_BUTTON_ID_PREFIX = 'menu_button_'
const MORE_MENU_ID_PREFIX = 'more_menu'

export type PopupMenuProps = {
  id: string
  direction?: 'left' | 'right'
  position?: 'above-up' | 'inline-up' | 'below-down' | 'inline-down' | 'inline-center'
  buttonIcon?: IconName
  closeOnMenuClick?: boolean
  buttonTooltip?: string | JSX.Element
  buttonClassName?: string
  menuClassName?: string
}

const preventDefault = (e: any) => {
  if (
    e.target?.getAttribute?.('id')?.startsWith(MORE_MENU_ID_PREFIX) ||
    document.querySelector(`[id^=${MORE_MENU_ID_PREFIX}]`)?.contains(e.target)
  ) {
    return
  }
  e.preventDefault()
}

type MenuOutside = {
  outTop: boolean
  outBottom: boolean
}

const offset = 1.25 * parseFloat(getComputedStyle(document.documentElement).fontSize) + 16 + 8

const isMenuOutside = (rect: DOMRect, position: PopupMenuProps['position']): MenuOutside => {
  switch (position) {
    case 'above-up':
      return {
        outTop: rect.y - offset < 0,
        outBottom: rect.height + 16 > window.innerHeight,
      }
    case 'inline-up':
      return {
        outTop: rect.y < 0,
        outBottom: rect.height + 16 > window.innerHeight,
      }
    case 'inline-down':
      return {
        outTop: rect.height + 16 > window.innerHeight,
        outBottom: rect.y + rect.height > window.innerHeight,
      }
    case 'inline-center':
      return {
        outTop: rect.y < 0,
        outBottom: rect.y + rect.height > window.innerHeight,
      }
    default:
      return {
        outTop: rect.height + 16 > window.innerHeight,
        outBottom: rect.y + rect.height + offset > window.innerHeight,
      }
  }
}

export const PopupMenu: React.FC<PropsWithChildren<PopupMenuProps>> = ({
  children,
  id,
  direction = 'left',
  position = 'below-down',
  buttonIcon = 'more',
  closeOnMenuClick = true,
  buttonTooltip,
  buttonClassName,
  menuClassName,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const menuRef = useTrapFocus<HTMLDivElement>(isMenuVisible)

  useEffect(() => {
    if (isMenuVisible) {
      document.body.addEventListener('wheel', preventDefault, { passive: false })
    } else {
      document.body.removeEventListener('wheel', preventDefault)
    }
    return () => {
      document.body.removeEventListener('wheel', preventDefault)
    }
  }, [isMenuVisible])

  const handleVisibilityToggle = () => setIsMenuVisible((prev) => !prev)

  useOnClickOutside(menuRef, (e: any) => {
    if (e?.target?.id !== `${MORE_MENU_BUTTON_ID_PREFIX}${id}`) {
      handleVisibilityToggle()
    }
  })

  useOnEscPress(() => {
    if (isMenuVisible) {
      handleVisibilityToggle()
    }
  })

  const onMenuClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (closeOnMenuClick || (e.target as any).getAttribute('data-close-menu') === 'true') {
      handleVisibilityToggle()
    }
  }

  const onToggleMenuButtonClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleVisibilityToggle()
  }

  useEffect(() => {
    if (menuRef.current && isMenuVisible) {
      const rect = menuRef.current.getBoundingClientRect()
      menuRef.current.style.position = 'fixed'
      menuRef.current.style.left = rect.x + 'px'
      menuRef.current.style.top = rect.y + 'px'
      menuRef.current.style.bottom = 'initial'
      menuRef.current.style.transform = 'unset'
      const { outTop, outBottom } = isMenuOutside(rect, position)
      if (outTop && outBottom) {
        menuRef.current.style.top = '8px'
        menuRef.current.style.bottom = '8px'
        menuRef.current.style.maxHeight = 'calc(100vh - 16px)'
        menuRef.current.style.overflow = 'auto'
      } else if (outTop) {
        menuRef.current.style.top = '8px'
        menuRef.current.style.bottom = 'initial'
      } else if (outBottom) {
        menuRef.current.style.top = 'initial'
        menuRef.current.style.bottom = '8px'
      }
    }
  }, [menuRef, isMenuVisible, position])

  return (
    <>
      <div className={styles.relativeContainer}>
        {buttonTooltip != null ? (
          <Tooltip idOverride={`${MORE_MENU_BUTTON_ID_PREFIX}${id}`} tooltipContent={buttonTooltip}>
            {(tooltipProps) => (
              <button
                onClick={onToggleMenuButtonClick}
                className={classNames(styles.popupButton, buttonClassName)}
                type='button'
                {...tooltipProps}
              >
                {getIcon(buttonIcon)}
              </button>
            )}
          </Tooltip>
        ) : (
          <button
            id={`${MORE_MENU_BUTTON_ID_PREFIX}${id}`}
            onClick={onToggleMenuButtonClick}
            className={classNames(styles.popupButton, buttonClassName)}
            type='button'
          >
            {getIcon(buttonIcon)}
          </button>
        )}
        {isMenuVisible && (
          <div
            ref={menuRef}
            className={classNames(
              styles.popup,
              styles[`direction_${direction}`],
              styles[`position_${position}`],
              menuClassName,
            )}
            onClick={onMenuClick}
            id={`${MORE_MENU_ID_PREFIX}${id}`}
          >
            {children}
          </div>
        )}
      </div>
    </>
  )
}
