import classNames from 'classnames'
import { t } from 'i18next'
import mapboxgl from 'mapbox-gl'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPannellum, { getAllScenes, getViewer } from 'react-pannellum'
import { MapType } from '../../../../api/gameTypes'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../common/components/PopupMenu/PopupActionMenu'
import { SelectableIcon } from '../../../../common/components/SelectableIcon/SelectableIcon'
import { Button } from '../../../../common/components/button/Button'
import { RoundButton } from '../../../../common/components/button/RoundButton'
import { getIcon } from '../../../../common/components/icons/utils'
import { DEFAULT_MAP_OPTIONS } from '../../../../common/constants'
import { useConfirmation } from '../../../../contexts/ConfirmationContext'
import { areObjectsEqual } from '../../../../util/functional'
import { MapInstanceID, useMapApi } from '../../../../util/map'
import { isNullOrZero } from '../../../../util/number'
import { getBoardFileNameFromUrl, getTranslated360BoardGames, getTranslatedStaticBoardGames } from '../../helpers'
import { GameBoard, GameBoardSettings } from '../../types'
import { PANORAMA_DEFAULTS } from '../BoardSection/PanoramaBoard'
import { MapboxContainer } from '../MapboxContainer/MapboxContainer'
import { AddGameBoardsModal } from './AddGameBoardsModal'
import styles from './GameBoardsPicker.module.css'

type GameBoardPickerProps = {
  initialValues: GameBoardSettings
  onChange: (settings: GameBoardSettings) => void
  isBranching?: boolean
  isExploration?: boolean
  moveTasks?: boolean
  onToggleMoveTasks?: () => void
  viewOnly?: boolean
}

const hasMapDataForType = (currentState: GameBoardSettings, map: mapboxgl.Map): boolean => {
  switch (currentState.gameBoardType) {
    case MapType.LIVE:
      return (
        map.getZoom() !== DEFAULT_MAP_OPTIONS.zoom ||
        map.getCenter().lng !== DEFAULT_MAP_OPTIONS.center[0] ||
        map.getCenter().lat !== DEFAULT_MAP_OPTIONS.center[1]
      )
    default:
      return !isNullOrZero(currentState.gameBoards?.length)
  }
}

const shouldRequestConfirmation = (
  newBoardType: string,
  currentState: GameBoardSettings,
  map: mapboxgl.Map,
): boolean => {
  return (
    currentState.gameBoardType != null &&
    currentState.gameBoardType !== newBoardType &&
    hasMapDataForType(currentState, map)
  )
}

const getInitialUploadedGameBoards = (gameBoardSettings: GameBoardSettings): GameBoard[] => {
  if ([MapType.PANORAMA, MapType.STATIC].includes(gameBoardSettings.gameBoardType)) {
    const readyBoardFileNames = (
      gameBoardSettings.gameBoardType === MapType.STATIC
        ? getTranslatedStaticBoardGames(t)
        : getTranslated360BoardGames(t)
    ).map((board) => getBoardFileNameFromUrl(board.url))
    return gameBoardSettings.gameBoards.filter(
      (board) => !readyBoardFileNames.includes(getBoardFileNameFromUrl(board.url)),
    )
  }
  return []
}

//Needed when switching selected panorama
const EMPTY_PANORAMA: GameBoard = { name: '', url: '', fileName: '' }

export const GameBoardPicker: React.FC<GameBoardPickerProps> = ({
  initialValues,
  onChange,
  isBranching = false,
  isExploration = false,
  moveTasks,
  onToggleMoveTasks,
  viewOnly,
}) => {
  const { t } = useTranslation()
  const [gameBoardsState, setGameBoardsState] = useState<GameBoardSettings>(initialValues)
  const [uploadedGameBoards, setUploadedGameBoards] = useState<GameBoard[]>(getInitialUploadedGameBoards(initialValues))
  const [showAddGameBoards, setShowAddGameBoards] = useState(false)
  const { requestConfirmation } = useConfirmation()
  const { map } = useMapApi(MapInstanceID.MODAL)
  const [activePanoramaBoard, setActivePanoramaBoard] = useState<GameBoard | null>(null)
  const [activePanoramaIndex, setActivePanoramaIndex] = useState<number | null>(null)
  const [lockLiveMapEdit, setLockLiveMapEdit] = useState<boolean>(initialValues.gameBoardType === MapType.LIVE)

  const unlockLiveMapEdit = () => setLockLiveMapEdit(false)

  useEffect(() => {
    setGameBoardsState((prev) => (areObjectsEqual(prev, initialValues) ? prev : initialValues))
  }, [initialValues])

  //Panorama check happens often, but added shorthands for others also
  const isPanorama = useMemo(() => {
    return gameBoardsState.gameBoardType === MapType.PANORAMA
  }, [gameBoardsState.gameBoardType])
  const isStatic = useMemo(() => {
    return gameBoardsState.gameBoardType === MapType.STATIC
  }, [gameBoardsState.gameBoardType])
  const isLive = useMemo(() => {
    return gameBoardsState.gameBoardType === MapType.LIVE
  }, [gameBoardsState.gameBoardType])
  const disabledByBranching = useMemo(() => {
    return isBranching && gameBoardsState.gameBoards.length > 0
  }, [gameBoardsState.gameBoards.length, isBranching])

  const toggleAddGameBoardsModalVisibility = useCallback(() => {
    setShowAddGameBoards((prev) => !prev)
  }, [])

  const internalSetActivePanoramaBoard = useCallback((board: GameBoard, index: number) => {
    //Dismounts the Pannellum renderer before switching to the real new panorama board
    setActivePanoramaBoard(EMPTY_PANORAMA)
    setTimeout(() => {
      setActivePanoramaBoard(board)
      setActivePanoramaIndex(index)
    }, 20)
  }, [])

  const handleActivePanoramaMapReady = useCallback(() => {
    const currentScene = getAllScenes()[0] //Really returns only one, latest added
    const currentViewer = getViewer() //Returns latest viewer instance
    const panoramaId = Object.keys(currentScene)[0]

    const mapElement = document.getElementById(panoramaId)
    if (mapElement) {
      mapElement.onmouseout = (evt) => {
        setGameBoardsState((prev) => {
          if (prev && activePanoramaBoard && activePanoramaIndex !== null) {
            return {
              ...prev,
              gameBoards: prev.gameBoards
                .slice(0, activePanoramaIndex)
                .concat({
                  ...activePanoramaBoard,
                  mapOptions: {
                    center: [currentViewer.getYaw(), currentViewer.getPitch()],
                    zoom: currentViewer.getHfov(),
                  },
                })
                .concat(prev.gameBoards.slice(activePanoramaIndex + 1)),
            }
          } else {
            return initialValues
          }
        })
      }
    }
  }, [activePanoramaBoard, activePanoramaIndex, initialValues])

  const handleGameBoardSelectionChange = useCallback(
    (boards: GameBoard[]) => {
      setGameBoardsState((prev) => {
        //If adding panorama board after fist having none, should open the first for large preview
        if (isPanorama && prev.gameBoards.length === 0 && boards.length > 0)
          internalSetActivePanoramaBoard(boards[0], 0)
        return {
          ...prev,
          gameBoards: boards,
        }
      })
      setShowAddGameBoards(false)
    },
    [isPanorama, internalSetActivePanoramaBoard],
  )

  const onChangeBoardTypeInternal = useCallback(
    (id: string) => {
      if (!shouldRequestConfirmation(id, gameBoardsState, map)) {
        setGameBoardsState({
          gameBoardType: id as MapType,
          mapOptions: DEFAULT_MAP_OPTIONS,
          gameBoards: [],
        })
      } else {
        requestConfirmation({
          title: t('game_editor.game_boards.change_board_type_confirmation.title', 'Changing the board type'),
          text: isLive
            ? t(
                'game_editor.game_boards.change_board_type_confirmation.text.live_map',
                'You already set a position for live map. Do you want to reset it and choose different game boards instead?',
              )
            : t(
                'game_editor.game_boards.change_board_type_confirmation.text.static_or_360',
                'You already have boards of one type selected. Do you want to remove them and select another type?',
              ),
        }).then((response) => {
          if (response) {
            setGameBoardsState({
              gameBoardType: id as MapType,
              mapOptions: DEFAULT_MAP_OPTIONS,
              gameBoards: [],
            })
          }
        })
      }
    },
    [gameBoardsState, map, requestConfirmation, t, isLive],
  )

  useEffect(() => {
    onChange(gameBoardsState)
    if (!isPanorama && activePanoramaBoard) setActivePanoramaBoard(null)
  }, [onChange, gameBoardsState, activePanoramaBoard, isPanorama])

  const handleDeleteGameBoard = useCallback(
    (url: string) => () => {
      setGameBoardsState((prev) => {
        return {
          ...prev,
          gameBoards: prev.gameBoards.filter((map) => map.url !== url),
        }
      })
    },
    [],
  )

  const handleUploadGameBoard = useCallback((newBoard: GameBoard) => {
    setUploadedGameBoards((prev) => [...prev, newBoard])
    setGameBoardsState((prev) => {
      return {
        ...prev,
        gameBoards: [...(prev.gameBoards || []), newBoard],
      }
    })
    setShowAddGameBoards(false)
  }, [])

  const allGameBoards = useMemo(() => {
    return [...uploadedGameBoards, ...(isPanorama ? getTranslated360BoardGames(t) : getTranslatedStaticBoardGames(t))]
  }, [uploadedGameBoards, isPanorama, t])

  const handleMoveBoard = useCallback(
    (index: number, direction: 'left' | 'right') => () => {
      setGameBoardsState((prev) => {
        if (direction === 'left' && index > 0) {
          return {
            ...prev,
            gameBoards: [
              ...prev.gameBoards.slice(0, index - 1),
              prev.gameBoards[index],
              prev.gameBoards[index - 1],
              ...prev.gameBoards.slice(index + 1),
            ],
          }
        } else if (direction === 'right' && index + 1 < prev.gameBoards.length && prev.gameBoards.length > 1) {
          return {
            ...prev,
            gameBoards: [
              ...prev.gameBoards.slice(0, index),
              prev.gameBoards[index + 1],
              prev.gameBoards[index],
              ...prev.gameBoards.slice(index + 2),
            ],
          }
        } else {
          return prev
        }
      })
    },
    [],
  )

  return (
    <>
      {showAddGameBoards && (
        <AddGameBoardsModal
          allGameBoards={allGameBoards}
          previouslySelectedBoards={gameBoardsState.gameBoards}
          onClickBack={toggleAddGameBoardsModalVisibility}
          onClickAddSelection={handleGameBoardSelectionChange}
          onUploadGameBoard={handleUploadGameBoard}
          isPanorama={isPanorama}
          isBranching={isBranching}
          isExploration={isExploration}
        />
      )}
      <div className={styles.row} {...{ inert: viewOnly ? 'true' : undefined }}>
        <label className='normal'>{t('game_editor.game_boards.board_type_label', 'Game board type')}</label>
        <div className={styles.gameBoardTypeContainer}>
          <div className={styles.buttonsWrapper}>
            <SelectableIcon
              id={MapType.LIVE}
              checked={isLive}
              text={t('game_editor.game_boards.live_map_text', 'Live map')}
              iconName='selectMap'
              size='Medium'
              tooltipText={t('game_editor.game_boards.live_map_tooltip', 'Real world map relative to a geo-location.')}
              onChange={onChangeBoardTypeInternal}
            />
            <SelectableIcon
              id={MapType.STATIC}
              checked={isStatic}
              text={t('game_editor.game_boards.image_text', 'Image')}
              iconName='photo'
              size='Medium'
              tooltipText={t(
                'game_editor.game_boards.image_tooltip',
                'An image from our default selection, or an uploaded image from your computer.',
              )}
              onChange={onChangeBoardTypeInternal}
            />
            <SelectableIcon
              id={MapType.PANORAMA}
              checked={isPanorama}
              text={t('game_editor.game_boards.360_text', '360° image')}
              iconName='radar'
              size='Medium'
              disabled={isBranching}
              tooltipText={
                isBranching
                  ? t('game_editor.branching.only_one_board', '(Branching game)')
                  : t('game_editor.game_boards.360_tooltip', 'A panorama type image.')
              }
              onChange={onChangeBoardTypeInternal}
            />
          </div>
          {(isStatic || isPanorama) && (
            <div className={classNames(styles.boardsWrapper)}>
              {gameBoardsState.gameBoards.map((board, i) => {
                return (
                  <div
                    key={`${board.url}_${i}`}
                    className={classNames(
                      styles.selectedStaticBoardThumbnail,
                      isPanorama && styles.selectedPanoramaBoardThumbnail,
                    )}
                    onClick={isPanorama ? () => internalSetActivePanoramaBoard(board, i) : undefined}
                  >
                    <div className={styles.thumbnailImageContainer}>
                      {!activePanoramaBoard && (
                        <div className={styles.gradientOverlay}>
                          <PopupActionMenu
                            id={`${board.name}_${i}_actionsMenu`}
                            position='inline-down'
                            buttonClassName={styles.moreMenuButton}
                          >
                            {i > 0 && (
                              <PopupActionMenuItem
                                icon='arrowLeft'
                                text={t('common.move_left', 'Move left')}
                                onClick={handleMoveBoard(i, 'left')}
                              />
                            )}
                            {gameBoardsState.gameBoards.length > 1 && i < gameBoardsState.gameBoards.length - 1 && (
                              <PopupActionMenuItem
                                icon='arrowRight'
                                text={t('common.move_right', 'Move right')}
                                onClick={handleMoveBoard(i, 'right')}
                              />
                            )}
                            <PopupActionMenuItem
                              icon='trash'
                              text={t('common.remove', 'Remove')}
                              onClick={handleDeleteGameBoard(board.url)}
                            />
                          </PopupActionMenu>
                        </div>
                      )}
                      <img
                        className={classNames(styles.thumbnailImage, isPanorama && styles.thumbnailImagePanorama)}
                        alt={board.name}
                        src={board.url}
                      />
                    </div>
                    <span className={styles.thumbnailBoardName}>
                      <label>
                        {t('game_editor.game_boards.board', 'Board')} {i + 1}
                      </label>
                    </span>
                  </div>
                )
              })}
              <div
                className={classNames(
                  styles.addButton,
                  gameBoardsState.gameBoards.length ? styles.withoutLabel : styles.withLabel,
                )}
              >
                <RoundButton
                  style={{
                    backgroundColor: disabledByBranching ? 'var(--grey-150)' : 'white',
                    color: disabledByBranching ? 'var(--grey-500)' : 'var(--primary-normal)',
                    fontSize: '1.375rem',
                  }}
                  icon='plus'
                  disabled={disabledByBranching}
                  onClick={toggleAddGameBoardsModalVisibility}
                />
                {!gameBoardsState.gameBoards.length && (
                  <label className='medium' style={{ color: 'var(--primary-normal)' }}>
                    {t('game_editor.game_boards.add_board_label', 'Add game board')}
                  </label>
                )}
                {disabledByBranching && (
                  <label className='medium' style={{ color: 'var(--grey-500l)' }}>
                    {t('game_editor.branching.only_one_board', '(Branching game)')}
                  </label>
                )}
              </div>
              {activePanoramaBoard && (
                <div className={styles.editablePanoramaArea}>
                  <div className={styles.panoramaMoreMenuWrapper}>
                    <PopupActionMenu
                      id={'activePanorama_actionsMenu'}
                      position='inline-down'
                      buttonClassName={styles.moreMenuButton}
                    >
                      <PopupActionMenuItem
                        icon='close'
                        text={t('common.close', 'Close')}
                        onClick={() => setActivePanoramaBoard(null)}
                      />
                    </PopupActionMenu>
                  </div>
                  {activePanoramaBoard !== EMPTY_PANORAMA && (
                    <ReactPannellum
                      id={'0_active_map'}
                      style={{
                        width: '100%',
                        height: '100%',
                        background: '#000000',
                        borderRadius: '5px',
                      }}
                      sceneId={'0_active_map'}
                      imageSource={'https://seppo-proxy.herokuapp.com/' + activePanoramaBoard.url}
                      config={{
                        ...PANORAMA_DEFAULTS,
                        showZoomCtrl: false,
                        yaw: activePanoramaBoard.mapOptions?.center[0] ?? 0,
                        pitch: activePanoramaBoard.mapOptions?.center[1] ?? 0,
                        hfov: activePanoramaBoard.mapOptions?.zoom ?? 100,
                      }}
                      onPanoramaLoaded={handleActivePanoramaMapReady}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {isLive && (
            <>
              <div className={styles.mapboxContainer}>
                {lockLiveMapEdit && (
                  <div className={styles.mapLockOverlay}>
                    <Button variant='outline-normal' onClick={unlockLiveMapEdit}>
                      <span className={styles.editMapButtonIcon}>{getIcon('lockGradient')}</span>
                      {t('game_editor.game_boards.unlock_map_edit_button', 'Edit map')}
                    </Button>
                  </div>
                )}
                {moveTasks && (
                  <div className={styles.mapLockOverlay}>
                    <span>
                      {t(
                        'game_editor.game_boards.move_tasks_to_view_info',
                        'Tasks will be moved here when the settings are saved',
                      )}
                    </span>
                    <Button variant='outline-normal' onClick={onToggleMoveTasks}>
                      {t('common.cancel', 'Cancel')}
                    </Button>
                  </div>
                )}
                <MapboxContainer
                  locked={lockLiveMapEdit}
                  mapInstanceId={MapInstanceID.MODAL}
                  mapOptions={gameBoardsState.mapOptions}
                  boardType={gameBoardsState.gameBoardType}
                  showMapbox={isLive}
                />
              </div>
              {!(onToggleMoveTasks == null || lockLiveMapEdit || moveTasks) && (
                <Button variant='outline-normal' onClick={onToggleMoveTasks} className={styles.moveTasksButton}>
                  <span className={styles.editMapButtonIcon}>{getIcon('exercises')}</span>
                  {t('game_editor.game_boards.move_tasks_to_view_button', 'Move tasks to view')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
