import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Report } from '../../../types/commonTypes'
import styles from './ReportsPreview.module.css'

type ReportsTabsProps = {
  allowedReports: Report[]
  selectedReport: Report
  onSelectReport: (report: Report) => void
}

export const ReportsTabs: React.FC<ReportsTabsProps> = ({ allowedReports, selectedReport, onSelectReport }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.buttonsContainer}>
      {allowedReports.includes(Report.BUSINESS) && (
        <button
          className={classNames(styles.reportButton, selectedReport === Report.BUSINESS && styles.reportButtonActive)}
          onClick={() => onSelectReport(Report.BUSINESS)}
        >
          {t('reports.business', 'Business reporting')}
        </button>
      )}
      {allowedReports.includes(Report.GROUP) && (
        <button
          className={classNames(styles.reportButton, selectedReport === Report.GROUP && styles.reportButtonActive)}
          onClick={() => onSelectReport(Report.GROUP)}
        >
          {t('reports.group', 'Group reporting')}
        </button>
      )}
      {allowedReports.includes(Report.TEACHER) && (
        <button
          className={classNames(styles.reportButton, selectedReport === Report.TEACHER && styles.reportButtonActive)}
          onClick={() => onSelectReport(Report.TEACHER)}
        >
          {t('reports.teacher', 'Instructor reporting')}
        </button>
      )}
      {allowedReports.includes(Report.SPONSORED_GAMES) && (
        <button
          className={classNames(
            styles.reportButton,
            selectedReport === Report.SPONSORED_GAMES && styles.reportButtonActive,
          )}
          onClick={() => onSelectReport(Report.SPONSORED_GAMES)}
        >
          {t('reports.sponsored_games', 'Sponsored games reporting')}
        </button>
      )}
      {allowedReports.includes(Report.PLAYER) && (
        <button
          className={classNames(styles.reportButton, selectedReport === Report.PLAYER && styles.reportButtonActive)}
          onClick={() => onSelectReport(Report.PLAYER)}
        >
          {t('reports.player', 'Player related reporting')}
        </button>
      )}
    </div>
  )
}
