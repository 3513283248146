import i18next, { TFunction } from 'i18next'
import { safeIsNullOrEmpty } from './string'
import { safeParseInt } from './number'
import { DeepPartial } from '../types/commonTypes'
import { AddMembersFormValues } from '../pages/settings/organization/components/AddMembersModal'

export const requiredValidation = (value: unknown, t: TFunction): string | undefined => {
  return safeIsNullOrEmpty(value) ? t('validation_errors.required', 'Required') : undefined
}

export const aboveZeroValidation = (value: number | undefined, t: TFunction): string | undefined => {
  const parsedValue = safeParseInt(value)
  return parsedValue == null
    ? t('validation_errors.required', 'Required')
    : parsedValue <= 0
    ? t('validation_errors.must_be_positive_number', 'Must be greater than 0')
    : undefined
}

export const zeroOrAboveValidation = (value: number | undefined, t: TFunction): string | undefined => {
  const parsedValue = safeParseInt(value)
  return parsedValue == null
    ? t('validation_errors.required', 'Required')
    : parsedValue < 0
    ? t('validation_errors.must_be_0_or_higher', 'Must be 0 or greater than 0')
    : undefined
}

export const lowerThanOrEqualToValidation = (
  value: number | undefined,
  max: number,
  t: TFunction,
  validationText?: string,
): string | undefined => {
  const parsedValue = safeParseInt(value)
  return parsedValue == null
    ? t('validation_errors.required', 'Required')
    : parsedValue > max
    ? validationText ??
      t('validation_errors.below_or_equal_to_max_number', {
        defaultValue: 'Must be lower than or equal to %{max_number}',
        max_number: max,
      })
    : undefined
}

export const rangeValidation = (
  value: number | undefined,
  min: number,
  max: number,
  t: TFunction,
  required: boolean = true,
): string | undefined => {
  const parsedValue = safeParseInt(value)
  if (parsedValue == null) {
    return required ? t('validation_errors.required', 'Required') : undefined
  }
  if (parsedValue < min) {
    return t('validation_errors.greater_than_or_equal_to_min_number', {
      defaultValue: 'Must be equal to or greater than %{min_number}',
      min_number: min,
    })
  }
  if (parsedValue > max) {
    return t('validation_errors.below_or_equal_to_max_number', {
      defaultValue: 'Must be lower than or equal to %{max_number}',
      max_number: max,
    })
  }
}
const re =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const emailValidation = (email: string | undefined, allEmails: DeepPartial<AddMembersFormValues>) => {
  if (email === undefined || safeIsNullOrEmpty(email)) return i18next.t('validation_errors.required', 'Required')
  if (!re.test(email.trim())) {
    return i18next.t('validation_errors.invalid_email', 'Invalid email')
  }
  if (
    allEmails &&
    allEmails.members &&
    allEmails.members.filter((element) => {
      if (!element) return false
      return element.email === email
    }).length > 1
  ) {
    return i18next.t('validation_errors.duplicate_email', 'Duplicate email')
  }
}
