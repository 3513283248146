import { TFunction } from 'i18next'
import { SelectOption } from '../../../../../../common/components/Select/Select'
import { useTranslation } from 'react-i18next'
import { SelectFormField } from '../../../../../../common/components/Form/SelectFormField/SelectFormField'
import styles from './ProximitySettings.module.css'

const getTranslatedGpsProximityOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('game_editor.tasks.advanced.gps_location_locked_option', {
      defaultValue: '< %{meters} meters',
      meters: 10,
    }),
    value: '10',
  },
  {
    label: t('game_editor.tasks.advanced.gps_location_locked_option', {
      defaultValue: '< %{meters} meters',
      meters: 20,
    }),
    value: '20',
  },
  {
    label: t('game_editor.tasks.advanced.gps_location_locked_option', {
      defaultValue: '< %{meters} meters',
      meters: 50,
    }),
    value: '50',
  },
]

type ProximitySettingsProps = {
  viewOnly: boolean
}

export const ProximitySettings: React.FC<ProximitySettingsProps> = ({ viewOnly }) => {
  const { t } = useTranslation()
  const options = getTranslatedGpsProximityOptions(t)

  return (
    <SelectFormField
      options={options}
      name='advanced.requiredProximity'
      label={t(
        'game_editor.tasks.advanced.gps_location_proximity_label',
        'Make the task open when the player reaches a defined minimum distance.',
      )}
      placeholder={t('game_editor.tasks.advanced.gps_location_proximity_placeholder', 'Required proximity to answer')}
      labelClassName={styles.inputLabel}
      fieldContainerClassName={styles.inputContainer}
      className={styles.gpsProximitySelect}
      errorClassName={styles.proximityError}
      disabled={viewOnly}
    />
  )
}
