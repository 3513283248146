import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../../common/components/icons/utils'
import { getOldUIRoutes } from '../../../../../routes'
import { copyToClipboard } from '../../../../../util/clipboard'
import styles from './PublicGameLink.module.css'

type PublicGameLinkProps = {
  secret?: string
  gameId?: number
}

export const PublicGameLink: React.FC<PublicGameLinkProps> = ({ gameId, secret }) => {
  const { t, i18n } = useTranslation()

  const link = gameId == null || secret == null ? null : getOldUIRoutes.publicGameSummary(gameId, secret, i18n.language)
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const handleCopy = async () => {
    const copied = await copyToClipboard(link ?? '')
    if (copied) {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
  }

  return (
    <div className={styles.publicGameLinkContainer}>
      {link == null ? (
        <span>
          {t(
            'game_editor.game_settings.advanced.public_game_summary_link_description_before_game_created',
            'Link to game summary will appear here once you finish creating the game',
          )}
        </span>
      ) : (
        <>
          <span className={styles.link}>{link}</span>
          <button type='button' onClick={handleCopy} className={styles.copyButton}>
            {getIcon('link')}
            {isCopied ? t('common.copied', 'Copied!') : t('common.copy', 'Copy')}
          </button>
        </>
      )}
    </div>
  )
}
