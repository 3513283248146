export const isNullOrZero = (value: number | undefined | null) => value == null || value === 0

export const safeParseInt = (value: unknown, fallback?: number) => {
  if (typeof value === 'number') {
    return value
  }
  if (value == null || typeof value !== 'string') {
    return fallback
  }
  const parsedValue = Number.parseInt(value)
  return Number.isNaN(parsedValue) ? fallback : parsedValue
}

export const getHoursMinutesSecondsFromSeconds = (timeInSeconds: number): [number, number, number] => {
  const seconds = timeInSeconds % 60
  const minutes = ((timeInSeconds - seconds) / 60) % 60
  const hours = (timeInSeconds - seconds - minutes * 60) / 60 / 60
  return [hours, minutes, seconds]
}

export const isNumeric = (value: unknown) => {
  var regexOnlyNumbers = /^\d+$/
  return typeof value !== 'object' && regexOnlyNumbers.test(value + '')
}
