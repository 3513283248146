import classNames from 'classnames'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { PointsPill } from '../../../../../../../common/components/PointsPill/PointsPill'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../../../common/components/PopupMenu/PopupActionMenu'
import { MORE_MENU_BUTTON_ID_PREFIX } from '../../../../../../../common/components/PopupMenu/PopupMenu'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { getPlayerCardFocusableElementId } from '../../../../../helpers'
import { PlayerSummary } from '../../types'
import { PlayerSummaryBadges } from './PlayerSummaryBadges'
import styles from './PlayerSummaryCard.module.css'
import { getInitials } from './helpers'

type PlayerSummaryCardProps = {
  isCompact: boolean
  noPointsGame?: boolean
  onClick: (playerId: number) => void
  playerSummary: PlayerSummary
  positionIndex?: number
  isSelected?: boolean
  tasksTotal: number
  badgesEnabled: boolean
  onClickAwardBadge?: (playerId: number) => void
}

export const PlayerSummaryCard: React.FC<PlayerSummaryCardProps> = ({
  isCompact,
  noPointsGame,
  onClick,
  playerSummary,
  positionIndex,
  isSelected,
  tasksTotal,
  badgesEnabled,
  onClickAwardBadge,
}) => {
  const { t } = useTranslation()

  const onClickInternal = useCallback(() => {
    onClick(playerSummary.player.id)
  }, [onClick, playerSummary.player.id])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>) => {
      if (e.key === 'Enter' && !(e.target as any)?.getAttribute?.('id')?.startsWith?.(MORE_MENU_BUTTON_ID_PREFIX)) {
        onClickInternal()
      }
    },
    [onClickInternal],
  )

  return (
    <li
      className={classNames(
        styles.playerSummaryContainer,
        isSelected && styles.playerSummaryContainer_selected,
        isCompact && styles.playerSummaryContainer_compact,
        playerSummary.needsReview && styles.playerSummaryContainer_review,
      )}
      onClick={onClickInternal}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      id={getPlayerCardFocusableElementId(playerSummary.player.id)}
    >
      {positionIndex != null && <span className={styles.playerSummaryPosition}>{positionIndex}</span>}
      <span className={styles.playerInitials}>{getInitials(playerSummary.player.nickName)}</span>
      <div className={styles.playerSummaryNameAndDetails}>
        <h5 className={styles.playerSummaryName}>{playerSummary.player.nickName}</h5>
        <div className={styles.playerSummaryDetails}>
          {!noPointsGame && isCompact ? (
            <div className={styles.compactIconAndPoints}>
              <span className={styles.compactPointsIcon}>{getIcon('scoreGradient')}</span>
              <span className={styles.compactPoints}>{playerSummary.totalScore}</span>
            </div>
          ) : (
            <PointsPill label={playerSummary.totalScore.toString()} noIcon size='small' />
          )}
          <span className={styles.playerSummaryPinIcon}>{getIcon('exercisePin')}</span>
          <span className={classNames('tiny bold', styles.details)}>
            {playerSummary.tasksAnswered}/{tasksTotal}
          </span>
          {badgesEnabled && <PlayerSummaryBadges badges={playerSummary.player.badges} />}
        </div>
      </div>
      {badgesEnabled && onClickAwardBadge != null && (
        <PopupActionMenu id={`playerSummaryMoreMenu_${playerSummary.player.id}`}>
          <PopupActionMenuItem
            onClick={() => onClickAwardBadge(playerSummary.player.id)}
            icon='badgeGradient'
            text={t('game_editor.sidebar.award_badge.open_award_badge_panel_button', 'Award badge')}
          />
        </PopupActionMenu>
      )}
    </li>
  )
}
