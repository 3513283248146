import classNames from 'classnames'
import { ModalPortal } from '../../common/components/Portal/Portal'
import styles from './Notification.module.css'
import { NotificationItem, NotificationItemProps } from './NotificationItem'

type NotificationsWrapperProps = {
  notifications: NotificationItemProps[]
}

export const NotificationsWrapper: React.FC<NotificationsWrapperProps> = ({ notifications }) => {
  return (
    <ModalPortal>
      <div
        className={classNames(
          styles.notificationsWrapper,
          notifications.length && styles.notificationsWrapper_withContent,
        )}
      >
        {notifications.map((notification) => (
          <NotificationItem key={`notification_${notification.id}`} {...notification} />
        ))}
      </div>
    </ModalPortal>
  )
}
