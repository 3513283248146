import { useTranslation } from 'react-i18next'
import { ToggleFormField } from '../../../../../../../common/components/Form/ToggleFormField/ToggleFormField'
import { PointsInput } from '../../../../Points/PointsInput'
import { PointsInputPreview } from '../../../../Points/PointsPreview'
import styles from './AllAnswersSamePoints.module.css'

type AllAnswersSamePointsProps = {
  name: string
  isChecked?: boolean
  viewOnly: boolean
}

export const AllAnswersSamePoints: React.FC<AllAnswersSamePointsProps> = ({ name, isChecked, viewOnly }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.allAnswersPointsContainer}>
      <ToggleFormField
        name={`${name}.data.allAnswersHaveSamePoints`}
        label={t('game_editor.tasks.answer_options.all_answers_same_points_label', 'All answers have same points')}
        description={t(
          'game_editor.tasks.answer_options.all_answers_same_points_description_disabled',
          'This disables customising score for each answer option',
        )}
        descriptionChecked={t(
          'game_editor.tasks.answer_options.all_answers_same_points_description_enabled',
          'This disables customising score for each answer option',
        )}
        disabled={viewOnly}
      />
      <div>
        {isChecked ? (
          <PointsInput name={`${name}.data.allAnswersPoints`} disabled={viewOnly} />
        ) : (
          <PointsInputPreview disabled />
        )}
      </div>
    </div>
  )
}
