import * as React from 'react'
import { SVGProps } from 'react'
const SvgBackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      data-name='chevron-left-Filled'
      d='M16.707 20.293a1 1 0 1 1-1.414 1.414l-9-9a1 1 0 0 1 0-1.414l9-9a1 1 0 1 1 1.414 1.414L8.414 12Z'
      fill={props.color ?? 'var(--primary-normal)'}
    />
  </svg>
)
export default SvgBackIcon
