import classNames from 'classnames'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { Tooltip } from '../../../../../../../common/components/tooltip/Tooltip'
import { Badge } from '../../../../../../../types/commonTypes'
import { BadgeImage } from '../../../../Badges/BadgeView/BadgeImage'
import styles from './PlayerSummaryCard.module.css'

type PlayerSummaryBadgesProps = {
  badges: Badge[]
  className?: string
}

export const PlayerSummaryBadges: React.FC<PlayerSummaryBadgesProps> = ({ badges, className }) => {
  return badges.length > 0 ? (
    <Tooltip tooltipContent={<BadgesTooltip badges={badges} />}>
      {(tooltipProps) => (
        <div {...tooltipProps} className={classNames(styles.badgesContainer, className)}>
          <span className={styles.playerSummaryPinIcon}>{getIcon('badgeGradient')}</span>
          <span className={classNames('tiny bold', styles.details)}>{badges.length}</span>
        </div>
      )}
    </Tooltip>
  ) : (
    <div className={classNames(styles.badgesContainer, className)}>
      <span className={styles.playerSummaryPinIcon}>{getIcon('badgeGradient')}</span>
      <span className={classNames('tiny bold', styles.details)}>{badges.length}</span>
    </div>
  )
}

const BadgesTooltip: React.FC<PlayerSummaryBadgesProps> = ({ badges }) => {
  return (
    <div className={styles.tooltipBadgesList}>
      {badges.slice(0, 3).map((badge) => (
        <BadgeImage variant='tiny' url={badge.imageUrl} key={`badge_tooltip_${badge.id}`} />
      ))}
      {badges.length > 3 && <span className='grey-600 medium'>...</span>}
    </div>
  )
}
