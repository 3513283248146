import * as React from 'react'
import { SVGProps } from 'react'
const SvgShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.025 4.225 11.95 7.3c-.375.375-.9.375-1.275 0a.95.95 0 0 1 0-1.35L15.25 1.3c.075-.075.15-.15.3-.225.225-.075.525-.075.75 0 .037.038.094.075.15.113.056.037.113.075.15.112l4.65 4.65a.95.95 0 0 1 0 1.35c-.225.225-.45.3-.675.3-.225 0-.525-.15-.675-.3l-3.075-3.075V15.1c0 .45-.45.9-.9.9s-.9-.375-.9-.9V4.225Zm7.5 6.15H24.4c1.575 0 2.85 1.275 2.925 2.775v15a2.849 2.849 0 0 1-2.85 2.85H7.6c-1.575 0-2.85-1.275-2.85-2.775v-15a2.849 2.849 0 0 1 2.85-2.85h1.875c.45 0 .9.45.9.9s-.375.9-.9.9H7.6c-.45 0-.9.375-.9.9v15c0 .45.375.9.9.9h16.8c.45 0 .9-.375.9-.9v-15c0-.45-.375-.9-.9-.9h-1.875c-.45 0-.9-.45-.9-.9s.375-.9.9-.9Z'
      fill={props.color ?? '#CC3E76'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.025 4.225 11.95 7.3c-.375.375-.9.375-1.275 0a.95.95 0 0 1 0-1.35L15.25 1.3c.075-.075.15-.15.3-.225.225-.075.525-.075.75 0 .037.038.094.075.15.113.056.037.113.075.15.112l4.65 4.65a.95.95 0 0 1 0 1.35c-.225.225-.45.3-.675.3-.225 0-.525-.15-.675-.3l-3.075-3.075V15.1c0 .45-.45.9-.9.9s-.9-.375-.9-.9V4.225Zm7.5 6.15H24.4c1.575 0 2.85 1.275 2.925 2.775v15a2.849 2.849 0 0 1-2.85 2.85H7.6c-1.575 0-2.85-1.275-2.85-2.775v-15a2.849 2.849 0 0 1 2.85-2.85h1.875c.45 0 .9.45.9.9s-.375.9-.9.9H7.6c-.45 0-.9.375-.9.9v15c0 .45.375.9.9.9h16.8c.45 0 .9-.375.9-.9v-15c0-.45-.375-.9-.9-.9h-1.875c-.45 0-.9-.45-.9-.9s.375-.9.9-.9Z'
      fill='url(#share_icon_svg__a)'
      fillOpacity={0.25}
    />
    <defs>
      <linearGradient id='share_icon_svg__a' x1={16.038} y1={1.019} x2={16.038} y2={31} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgShareIcon
