import { Button } from '../../../common/components/button/Button'
import { LinkButton, NavLinkButton } from '../../../common/components/button/LinkButton'
import { getIcon } from '../../../common/components/icons/utils'
import { HELP_MENU_CONTROL_ID, useHelpMenu } from '../../../contexts/HelpMenuContextProvider'
import { openNinchatForm } from '../../../util/ninchat'
import { WelcomeBlockButton } from '../helpers'

type BannerButtonProps = {
  button: WelcomeBlockButton
  language?: string
  isSecondary?: boolean
}

export const BannerButton: React.FC<BannerButtonProps> = ({ button, language, isSecondary }) => {
  const { toggleDisplayMenu } = useHelpMenu()

  switch (button.type) {
    case 'link-external':
      return (
        <LinkButton
          iconName={button.icon}
          href={language === 'fi' ? button.linkFinnish || button.link : button.link}
          label={button.label}
          target='_blank'
          rel='noreferrer'
        />
      )
    case 'link-internal':
      return <NavLinkButton label={button.label} iconName={button.icon} route={button.link!} />
    case 'toggle-help':
      return (
        <Button
          style={{
            letterSpacing: '0.06em',
            width: 'fit-content',
          }}
          variant={isSecondary ? 'outline-normal' : 'normal'}
          onClick={toggleDisplayMenu}
          id={`${HELP_MENU_CONTROL_ID}__trialMessage${isSecondary ? 'Secondary' : 'Primary'}Button`}
        >
          {button.icon && <span className='iconWrapperBig'>{getIcon(button.icon)}</span>}
          {button.label}
        </Button>
      )
    case 'ninchat-form':
      return (
        <Button
          style={{
            letterSpacing: '0.06em',
            width: 'fit-content',
          }}
          variant={isSecondary ? 'outline-normal' : 'normal'}
          onClick={openNinchatForm}
          id={`${HELP_MENU_CONTROL_ID}__trialMessage${isSecondary ? 'Secondary' : 'Primary'}Button`}
        >
          {button.icon && <span className='iconWrapperBig'>{getIcon(button.icon)}</span>}
          {button.label}
        </Button>
      )
    default:
      return null
  }
}
