import * as React from 'react'
import { SVGProps } from 'react'

const SvgExercisesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m8.784 12.34 3.374-3.375c1.843-1.843 1.843-4.859-.024-6.726a4.767 4.767 0 0 0-6.749 0 4.767 4.767 0 0 0 0 6.75l3.399 3.35Zm-1.46-8.162c.79-.79 2.082-.79 2.872 0s.79 2.082 0 2.872a.937.937 0 0 1-.24.191c0-.287-.143-.574-.359-.79a1.2 1.2 0 0 0-1.675 0 1.328 1.328 0 0 0-.359.79c-.036-.035-.078-.065-.12-.095a.933.933 0 0 1-.12-.096 2.037 2.037 0 0 1 0-2.872ZM4.593 1.024a5.062 5.062 0 0 0-2.658 1.315c-.95.9-1.45 2.1-1.45 3.35 0 1.3.5 2.5 1.45 3.4l3.55 3.35.89-.839a.4.4 0 1 0-.552-.582l-.338.321-2.95-2.8c-.8-.8-1.25-1.8-1.25-2.85s.45-2.05 1.2-2.8a4.288 4.288 0 0 1 2.296-1.105l.074-.075c.267-.267.11-.747-.262-.685Z'
      fill={props.color ?? '#6F6F6F'}
    />
  </svg>
)

export default SvgExercisesIcon
