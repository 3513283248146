import { useTranslation } from 'react-i18next'
import { Tooltip } from '../../../../common/components/tooltip/Tooltip'
import { useGame } from '../../../../contexts/GameContext'
import { useNotification } from '../../../../contexts/NotificationContext'
import { useUser } from '../../../../contexts/userContext'
import { preventUserStartGame } from '../../../../util/game'
import styles from './StartGameButton.module.css'

export const StartGameButton: React.FC = () => {
  const { t } = useTranslation()
  const { isOpen, gameData, handleGameOpen, editorPermissions } = useGame()
  const { user } = useUser()
  const { notifyError } = useNotification()

  const onClick = () => {
    if (!isOpen && preventUserStartGame(user, gameData)) {
      notifyError({
        title: t('game_editor.starting_game_error_licence_expired.title', 'Cannot start game'),
        content: t(
          'game_editor.starting_game_error_licence_expired.content',
          'You need a valid licence to start a game. Update your licence or contact us for support',
        ),
      })
    } else {
      handleGameOpen()
    }
  }

  return editorPermissions.actions.startGame ? (
    <button onClick={onClick} className={styles.startGameButton}>
      {isOpen
        ? t('game_editor.sidebar.manage_game_panel.pause_game_button', 'Pause game')
        : t('game_editor.sidebar.manage_game_panel.start_game_button', 'Start game')}
    </button>
  ) : (
    <Tooltip
      direction='left'
      tooltipClassName={styles.noPermissionTooltip}
      tooltipContent={t(
        'game_editor.starting_game_no_permission_info',
        'Only the main instructor can start or stop the game',
      )}
    >
      {(tooltipProps) => (
        <span className={styles.startGameButton} {...tooltipProps}>
          {isOpen
            ? t('game_editor.sidebar.manage_game_panel.pause_game_button', 'Pause game')
            : t('game_editor.sidebar.manage_game_panel.start_game_button', 'Start game')}
        </span>
      )}
    </Tooltip>
  )
}
