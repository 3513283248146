import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TabType } from '../../../types'
import { Tab } from './Tab'
import styles from './Tab.module.css'

type TabGroupProps = {
  activeTab: TabType
  onClickTab: (tab: TabType) => void
}

const getTranslatedTabList = (t: TFunction) => [
  {
    id: TabType.BUILD,
    title: t('game_editor.header.tab_build_title', 'Build'),
    label: 'Draft',
  },
  {
    id: TabType.PLAY,
    title: t('game_editor.header.tab_play_title', 'Play'),
    label: t('game_editor.header.tab_play_subtitle', 'Add players'),
  },
  {
    id: TabType.REPORT,
    title: t('game_editor.header.tab_report_title', 'Report'),
    label: '0% done',
  },
]

export const TabGroup: React.FC<TabGroupProps> = ({ activeTab, onClickTab }) => {
  const { t } = useTranslation()
  const tabList = getTranslatedTabList(t)

  return (
    <div className={styles.tabGroup}>
      {tabList.map((tab, i) => {
        return (
          <Tab
            id={tab.id}
            key={`${tab.title}-${i}`}
            title={tab.title}
            label={tab.label}
            isActive={tab.id === activeTab}
            onClickTab={onClickTab}
          />
        )
      })}
    </div>
  )
}
