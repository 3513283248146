import classNames from 'classnames'
import { PropsWithChildren, useState } from 'react'
import SvgArrowDownIcon from '../../../../../../../../../common/components/icons/ArrowDownIcon'
import { IconName, getIcon } from '../../../../../../../../../common/components/icons/utils'
import { AnswerStateEnum } from '../../../../../../../../../types/commonTypes'
import styles from './TaskStateAccordion.module.css'

type TaskStateAccordionProps = {
  taskStateIcon: IconName
  taskStateTitle: string
  state: AnswerStateEnum
  numberOfPlayers?: number
}

export const TaskStateAccordion: React.FC<PropsWithChildren<TaskStateAccordionProps>> = ({
  taskStateIcon,
  taskStateTitle,
  state,
  numberOfPlayers,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <div className={styles.container}>
      <button className={styles.headerButtonContainer} onClick={() => setIsOpen((prev) => !prev)}>
        <span className={classNames(styles.stateIcon, state === AnswerStateEnum.READY && styles.stateIcon_ready)}>
          {getIcon(taskStateIcon)}
        </span>
        <span className={styles.stateTitle}>
          {taskStateTitle}
          {numberOfPlayers != null && ` (${numberOfPlayers})`}
        </span>
        <span className={classNames(styles.chevron, !isOpen && styles.rotateChevron)}>
          <SvgArrowDownIcon />
        </span>
      </button>
      {isOpen && <ul className={styles.taskGroupContainer}>{children}</ul>}
    </div>
  )
}
