import { useTranslation } from 'react-i18next'
import { IconTooltip } from '../../../../../../../common/components/tooltip/IconTooltip'
import styles from './NoTasksPlaceholder.module.css'

type NoTasksPlaceholderProps = {
  showDefaultBoardWarning?: boolean
  showHigherLevelWarning?: boolean
}

export const NoTasksPlaceholder: React.FC<NoTasksPlaceholderProps> = ({
  showDefaultBoardWarning,
  showHigherLevelWarning,
}) => {
  const { t } = useTranslation()
  return (
    <div className={styles.noTasksPlaceholder}>
      <span>{t('game_editor.levels.no_tasks_text', 'No tasks')}</span>
      {(showHigherLevelWarning || showDefaultBoardWarning) && (
        <IconTooltip
          iconName='alert'
          tooltipContent={
            showHigherLevelWarning
              ? t(
                  'game_editor.levels.no_tasks_warning_higher_level_has_tasks',
                  'Add tasks to this empty level to ensure uninterrupted gameplay.',
                )
              : t(
                  'game_editor.levels.no_tasks_warning_default_board',
                  'Add tasks to this empty default board to ensure uninterrupted gameplay.',
                )
          }
          iconColor='var(--error-normal)'
          tooltipClassName={styles.noTasksWarningTooltip}
        />
      )}
    </div>
  )
}
