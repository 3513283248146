import { useTranslation } from 'react-i18next'
import { CloseButton } from '../../../../../../../../common/components/button/CloseButton'
import styles from './../Scoreboard.module.css'

type ScoreboardHeaderProps = {
  onClose: () => void
}

export const ScoreboardHeader: React.FC<ScoreboardHeaderProps> = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.header}>
      <CloseButton
        onClick={onClose}
        className={styles.closeButton}
        aria-label={t('game_editor.scoreboard.close_scoreboard_accessibility_label', 'Close scoreboard')}
      />
    </div>
  )
}
