import classNames from 'classnames'
import React from 'react'
import { Button } from '../../../common/components/button/Button'
import sharedStyles from '../Login.module.css'
import styles from './InstructorOrPlayer.module.css'
import { useTranslation } from 'react-i18next'
import { LoginData } from './InstructorOrPlayer'

type continueGameBlockSmallVariant = 'normal' | 'small'

type continueGameBlockSmallProps = {
  variant: continueGameBlockSmallVariant
  previousLogin: LoginData
  handleContinuePrevious: () => void
  handleResetPreviousLogin: () => void
}

export const ContinueGameBlockSmall: React.FC<continueGameBlockSmallProps> = ({
  variant = 'normal',
  previousLogin,
  handleContinuePrevious,
  handleResetPreviousLogin,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.enterCodeColumn}>
        <p className={classNames(styles.cardBodyText, styles.showContinueGameBlockSmallcardBodyText)}>
          {t('login.continue_existing_game.continue_game.body_text', 'Pick up where you left off')}
        </p>
        <div className={styles.previousGameCardWithBorder}>
          <div
            className={styles.previousGameCardTop}
            style={{ backgroundImage: `url('${previousLogin.gameMapUrl}')` }}
          />

          <div className={styles.previousGameCardBottom}>
            <h5 className={styles.gameTitle}>{previousLogin.gameName}</h5>
            <p style={{ marginBottom: '10px' }}>
              {t('login.continue_player', 'Player:') + ' ' + previousLogin.playerName}
            </p>
          </div>
        </div>
        <Button
          variant={variant === 'normal' ? 'outline-tiny' : 'normal'}
          type='button'
          //className={styles.loginWithIdButton}
          onClick={handleContinuePrevious}
          className={variant === 'normal' ? '' : sharedStyles.bigBlockButton}
        >
          {variant === 'normal'
            ? t('login.continue.enter_previous_button', 'Continue this game')
            : t('login.continue_existing_game.continue_game.button_text', 'Continue game')}
        </Button>
        <Button
          variant='borderless-tiny'
          type='button'
          //className={styles.loginWithIdButton}
          onClick={handleResetPreviousLogin}
        >
          {t('login.reset_previous_login', 'Reset previous login')}
        </Button>
      </div>
    </>
  )
}
