import { Tag } from '../../../../../../types/commonTypes'
import styles from './PlayersAddByPlayerAccountModal.module.css'
import { ListLineTag } from './PlayerAccountModalListLines'
import { MAX_TAGS_COUNT } from './PlayersAddByPlayerAccountModal'
import { useTranslation } from 'react-i18next'

type TagSearchResultsProps = {
  onAddActiveTag: (tag: Tag) => void
  onDoTagSubSearch: (tag: Tag, isCenterColumn: boolean) => void
  onTagSelected: (tag: Tag, toSelected: boolean) => void
  searchInputValue: string
  searchMatchingTags: Tag[]
  toAddTags: Tag[]
}

export const TagSearchResults: React.FC<TagSearchResultsProps> = ({
  onAddActiveTag,
  onDoTagSubSearch,
  onTagSelected,
  searchInputValue,
  searchMatchingTags,
  toAddTags,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.titleLine}>
        {t('game_editor.add_people.player_account.results.tags_title', 'Tags (%{tags_count})', {
          tags_count: searchMatchingTags.length,
        })}
      </div>
      {searchMatchingTags.slice(0, MAX_TAGS_COUNT).map((tag, index) => {
        return (
          <ListLineTag
            key={index}
            isCenterColumn={true}
            isSelected={!!toAddTags.find((t) => t.id === tag.id)}
            onClick={onAddActiveTag}
            onSelect={onTagSelected}
            onShowAll={onDoTagSubSearch}
            tag={tag}
            searchTerm={searchInputValue}
          />
        )
      })}
    </>
  )
}
