import classNames from 'classnames'
import layoutStyles from '../AnswerOptionLayout.module.css'
import { AnswerStatus, AnswerStatusIcon } from '../AnswerStatusIcon'
import styles from './MissingWordAnswer.module.css'

type MissingWordCorrectProps = {
  word: string
}

export const MissingWordCorrect: React.FC<MissingWordCorrectProps> = ({ word }) => {
  return (
    <div
      className={classNames(
        layoutStyles.answerOptionLeftColumn,
        styles.missingWordContainer,
        styles.missingWordSuccess,
      )}
    >
      {word}
      <AnswerStatusIcon status={AnswerStatus.SUCCESS} />
    </div>
  )
}
