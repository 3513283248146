import { useEffect } from 'react'

export const useDisableBodyScroll = (isActive: boolean = true) => {
  useEffect(() => {
    if (isActive) {
      const scrollbarWidth = window.innerWidth - document.body.clientWidth
      document.body.style.overflow = 'hidden'
      if (scrollbarWidth) {
        document.body.style.marginRight = `${scrollbarWidth}px`
      }
    } else {
      document.body.style.overflow = 'unset'
      document.body.style.marginRight = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
      document.body.style.marginRight = 'unset'
    }
  }, [isActive])
}
