import classNames from 'classnames'
import DOMPurify from 'dompurify'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from '../../../../../../contexts/userContext'
import { getInitialsFromName } from '../../helpers'
import styles from './Message.module.css'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../../common/components/PopupMenu/PopupActionMenu'

type MessageProps = {
  id: number
  fromId: number
  name: string
  text: string
  onDelete: () => void
  style?: CSSProperties
}

export const Message = React.forwardRef<HTMLLIElement, MessageProps>(
  ({ id, fromId, name, text, onDelete, style }, ref) => {
    const { user } = useUser()
    const { t } = useTranslation()

    if (user == null) {
      return null
    }

    return (
      <li ref={ref} style={style} className={styles.container}>
        <div className={classNames(styles.initialsAndMessageContainer, user?.id === fromId && styles.reverse)}>
          <div
            className={classNames(styles.initialsContainer, user?.id === fromId ? styles.primary : styles.secondary)}
          >
            <p className={styles.initials}>{getInitialsFromName(name + '').toUpperCase()}</p>
          </div>
          <div className={styles.messageAndMenuContainer}>
            <div className={styles.messageBodyContainer}>
              <div className={classNames(styles.nameRow, user?.id === fromId && styles.myMessageColor)}>
                {user?.id === fromId ? t('game_editor.chat.message_your_name', 'Your message') : name}
              </div>
              <div
                className={styles.textRow}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text),
                }}
              />
            </div>
          </div>
        </div>
        <PopupActionMenu id={`${id}_actionsMenu`} position='inline-center'>
          <PopupActionMenuItem
            icon='copy'
            text={t('game_editor.chat.delete message', 'Delete message')}
            onClick={onDelete}
          />
        </PopupActionMenu>
      </li>
    )
  },
)
