import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { SingleValue } from 'react-select'
import { GameMap } from '../../../types/commonTypes'
import styles from './GamePreview.module.css'

type MapsPreviewProps = {
  maps: GameMap[]
  isLibrary?: boolean
}

type MapOption = {
  value: number
  label: string
}

export const MapsPreview: React.FC<MapsPreviewProps> = ({ maps, isLibrary }) => {
  const { t } = useTranslation()

  const [currentMapIndex, setCurrentMapIndex] = useState<number>(0)

  const mapOptions = useMemo<MapOption[]>(() => {
    return maps.map((_, index) => ({ value: index, label: (index + 1).toString() }))
  }, [maps])

  const handleSelectCurrentMapIndex = useCallback(
    (option: SingleValue<{ value: number; label: string }>) => {
      setCurrentMapIndex(option?.value ?? 0)
    },
    [setCurrentMapIndex],
  )

  const handleClickCurrentMapIndex = useCallback(
    (option: SingleValue<{ value: number; label: string }>) => () => {
      setCurrentMapIndex(option?.value ?? 0)
    },
    [setCurrentMapIndex],
  )

  return (
    <div className={styles.mapPreviewContainer}>
      {maps.length > 1 && (
        <div className={styles.mapSelectContainer}>
          <span className='normal'>{t('game_preview_modal.pick_game_board_label', 'Game boards:')}</span>
          <div>
            {maps?.length > 3 ? (
              <ReactSelect
                isSearchable={false}
                options={mapOptions}
                onChange={handleSelectCurrentMapIndex}
                defaultValue={mapOptions[0]}
              />
            ) : (
              <div className={styles.mapOptionButtonsGroup}>
                {mapOptions.map((option) => (
                  <button
                    key={`mapButton_${option.value}`}
                    onClick={handleClickCurrentMapIndex(option)}
                    className={classNames(
                      styles.gameBoardOptionButton,
                      currentMapIndex === option.value &&
                        (isLibrary ? styles.gameBoardOptionButtonActiveLibrary : styles.gameBoardOptionButtonActiveOwn),
                    )}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <div className={styles.outerMapContainer}>
        <div
          style={{
            backgroundImage: `url('${maps[currentMapIndex]?.url}')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </div>
  )
}
