import { TFunction } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { DatePickerFormField } from '../../../../common/components/Form/DatePickerFormField/DatePickerFormField'
import { SelectFormField } from '../../../../common/components/Form/SelectFormField/SelectFormField'
import { ToggleFormField } from '../../../../common/components/Form/ToggleFormField/ToggleFormField'
import { SelectElement, SelectOption } from '../../../../common/components/Select/Select'
import { GameFilters } from '../../../../contexts/OwnGamesContextProvider'
import { useDebounce } from '../../../../hooks/useDebounce'
import { getAgeOptions, getLanguageOptions, getSubjectOptions } from '../../../../pages/search/components/constants'
import { GameCreatorFilterOption, GamesFilterSettings } from '../../../../types/commonTypes'
import { areObjectsEqual } from '../../../../util/functional'
import styles from './GameFiltersForm.module.css'

const creatorPrefixEl: SelectElement = {
  type: 'icon',
  icon: { name: 'multipleUsers' },
}

const statusPrefixEl: SelectElement = {
  type: 'icon',
  icon: { name: 'settings' },
}

const subjectPrefixEl: SelectElement = {
  type: 'icon',
  icon: { name: 'textFile' },
}

const agePrefixEl: SelectElement = {
  type: 'icon',
  icon: { name: 'filter' },
}

const languagePrefixEl: SelectElement = {
  type: 'icon',
  icon: { name: 'language' },
}

const getStatusOptions = (t: TFunction) => {
  return [
    { value: 'IN_PROGRESS', label: t('game_status_label.in_progress', 'Game on') },
    { value: 'PAUSED', label: t('game_status_label.paused', 'Paused') },
    { value: 'DRAFT', label: t('game_status_label.draft', 'Draft') },
    { value: 'IMPORTED', label: t('game_status_label.downloaded', 'Downloaded') },
    { value: 'EXPORTED', label: t('game_status_label.published', 'Published') },
  ]
}

type GameFiltersProps = {
  filterSettings: GamesFilterSettings
  creators?: GameCreatorFilterOption[]
  onChange: (filters: Partial<GameFilters>) => void
}

export const GameFiltersForm: React.FC<GameFiltersProps> = ({ filterSettings, onChange, creators }) => {
  const { t } = useTranslation()

  const [filters, setFilters] = useState<GameFilters>({})
  const debouncedFilters = useDebounce(filters, 1_000)

  useEffect(() => {
    onChange(debouncedFilters)
  }, [onChange, debouncedFilters])

  const creatorOptions = useMemo<Array<SelectOption<number>>>(() => {
    return creators?.map((creator) => ({ value: creator.id, label: creator.name })) ?? []
  }, [creators])

  return (
    <Form<GameFilters> onSubmit={onChange}>
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit} className={styles.filtersContainer}>
          {filterSettings.creator && creators != null && (
            <SelectFormField<number>
              name='creator'
              options={creatorOptions}
              label={t('dashboard.filter_label.creator', 'Creator')}
              placeholder={t('dashboard.filter_label.creator', 'Creator')}
              srOnlyLabel
              prefixElement={creatorPrefixEl}
              isClearable
              isSearchable
            />
          )}
          {filterSettings.status && (
            <SelectFormField
              name='status'
              options={getStatusOptions(t)}
              label={t('dashboard.filter_label.game_status', 'Game status')}
              placeholder={t('dashboard.filter_label.game_status', 'Game status')}
              srOnlyLabel
              prefixElement={statusPrefixEl}
              isClearable
            />
          )}
          {filterSettings.subject && (
            <SelectFormField
              name='subject'
              options={getSubjectOptions(t)}
              label={t('search_page.filter_label.subject', 'Subject')}
              placeholder={t('search_page.filter_label.subject', 'Subject')}
              srOnlyLabel
              prefixElement={subjectPrefixEl}
              isClearable
              isSearchable
            />
          )}
          {filterSettings.updatedAtFrom && (
            <DatePickerFormField
              name='updatedAtFrom'
              label={t('dashboard.filter_label.updated_at_from', 'Updated at (from)')}
              placeholder={t('dashboard.filter_label.updated_at_from', 'Updated at (from)')}
              srOnlyLabel
              isClearable
              maxDate={values.updatedAtUntil == null ? undefined : new Date(values.updatedAtUntil)}
            />
          )}
          {filterSettings.updatedAtUntil && (
            <DatePickerFormField
              name='updatedAtUntil'
              label={t('dashboard.filter_label.updated_at_until', 'Updated at (until)')}
              placeholder={t('dashboard.filter_label.updated_at_until', 'Updated at (until)')}
              srOnlyLabel
              isClearable
              minDate={values.updatedAtFrom == null ? undefined : new Date(values.updatedAtFrom)}
            />
          )}
          {filterSettings.age && (
            <SelectFormField
              name='age'
              options={getAgeOptions(t)}
              label={t('search_page.filter_label.age', 'Age')}
              placeholder={t('search_page.filter_label.age', 'Age')}
              srOnlyLabel
              prefixElement={agePrefixEl}
              isClearable
            />
          )}
          {filterSettings.language && (
            <SelectFormField
              name='language'
              options={getLanguageOptions(t)}
              label={t('search_page.filter_label.language', 'Language')}
              placeholder={t('search_page.filter_label.language', 'Language')}
              srOnlyLabel
              prefixElement={languagePrefixEl}
              isClearable
              isSearchable
            />
          )}
          {filterSettings.approvedBySeppo && (
            <ToggleFormField
              name='approvedBySeppo'
              label={t('search_page.filter_label.approved_by_seppo', 'Approved by Seppo')}
              controlWithLabelClassName={styles.toggleInput}
            />
          )}
          <FormSpy
            onChange={(props) =>
              setFilters((prev) => (areObjectsEqual(prev ?? {}, props.values) ? prev : props.values))
            }
          />
        </form>
      )}
    </Form>
  )
}
