import { useCallback, useEffect, useRef, useState } from 'react'
import { Tooltip } from '../../../../../common/components/tooltip/Tooltip'
import styles from './Tooltipper.module.css'
import classNames from 'classnames'

type TooltipperProps = {
  classIdentifier: string
  tooltipContentAttribute?: string
  tooltipElement?: JSX.Element
  large?: boolean
  center?: boolean
  sticky?: boolean
  autoActivate?: boolean
  ignoreEvents?: boolean
}

const ACTIVATION_SIZE_LARGE = 36
const ACTIVATION_SIZE_SMALL = 20

export const Tooltipper: React.FC<TooltipperProps> = ({
  classIdentifier,
  tooltipContentAttribute,
  tooltipElement,
  large,
  center,
  sticky,
  autoActivate,
  ignoreEvents,
}) => {
  const [hoverAreaStyle, setHoverAreaStyle] = useState<React.CSSProperties>()
  const [activeElement, setActiveElement] = useState<HTMLElement>()
  const clearTimeout = useRef(0)
  const size = large ? ACTIVATION_SIZE_LARGE : ACTIVATION_SIZE_SMALL

  const getTooltipContent = useCallback(() => {
    return tooltipContentAttribute ? activeElement?.getAttribute(tooltipContentAttribute) ?? '' : tooltipElement ?? ''
  }, [activeElement, tooltipContentAttribute, tooltipElement])

  useEffect(() => {
    if (autoActivate) {
      setTimeout(
        () => {
          const element = document.querySelectorAll(`[class*="${classIdentifier}"]`)[0] as HTMLElement
          if (!element) return
          setActiveElement(element)
          const coords = element.getBoundingClientRect()
          const left = center ? (coords.left + coords.right) / 2 - size / 2 : coords.left
          const top = center ? (coords.top + coords.bottom) / 2 - size / 2 : coords.top
          setHoverAreaStyle((prev) => {
            return {
              ...prev,
              left: left,
              top: top,
            }
          })
        },
        classIdentifier.includes('door') ? 100 : 0,
      ) //Delay needed for doors as element might not have rendered initially
    }
  }, [autoActivate, center, classIdentifier, size])

  useEffect(() => {
    function mouseEnter(evt: any) {
      if (activeElement && evt.target?.className.includes?.('Tooltipper')) {
        window.clearTimeout(clearTimeout.current)
      } else if (evt.target?.className.includes?.(classIdentifier) && !evt.buttons) {
        const element = evt.target as HTMLElement
        window.clearTimeout(clearTimeout.current)
        setActiveElement(element)
        const coords = element.getBoundingClientRect()
        const left = center ? (coords.left + coords.right) / 2 - size / 2 : coords.left
        const top = center ? (coords.top + coords.bottom) / 2 - size / 2 : coords.top
        setHoverAreaStyle((prev) => {
          return {
            ...prev,
            left: left,
            top: top,
          }
        })
      }
    }

    function mouseLeave(evt: any) {
      if (!sticky) {
        clearTimeout.current = window.setTimeout(() => {
          setActiveElement(undefined)
        }, 100)
      }
    }

    function externalClose(data: any) {
      if (data.detail === classIdentifier) {
        setActiveElement(undefined)
      }
    }

    document.addEventListener('mouseover', mouseEnter)
    document.addEventListener('mouseout', mouseLeave)
    window.addEventListener('externalCloseTooltip', externalClose)

    return () => {
      document.removeEventListener('mouseover', mouseEnter)
      document.removeEventListener('mouseout', mouseLeave)
      window.removeEventListener('externalCloseTooltip', externalClose)
    }
  }, [activeElement, center, classIdentifier, size, sticky])

  return (
    <>
      {activeElement && (
        <Tooltip
          requireExplicitClose
          closeOnScroll
          tooltipClassName='Tooltipper'
          tooltipContent={getTooltipContent()}
          timeoutMs={10}
          keepOpen={autoActivate}
          avoidModals={sticky}
        >
          {(tooltipProps) => (
            <div
              className={classNames(
                styles.toolTipArea,
                large && styles.toolTipAreaLarge,
                ignoreEvents && styles.toolTipNoEvents,
              )}
              style={hoverAreaStyle}
              {...tooltipProps}
            />
          )}
        </Tooltip>
      )}
    </>
  )
}
