import * as React from 'react'
import { SVGProps } from 'react'
const SvgChevronIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M24.48 11.39a1.333 1.333 0 1 1 1.886 1.886l-8.485 8.486-1.886 1.885-1.885-1.885-8.486-8.486A1.333 1.333 0 1 1 7.51 11.39l8.485 8.486 8.485-8.486Z'
      fill={props.color ?? '#6F6F6F'}
    />
  </svg>
)
export default SvgChevronIcon
