import classNames from 'classnames'
import arrayMutators from 'final-form-arrays'
import i18next from 'i18next'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Trans, useTranslation } from 'react-i18next'
import { AddEntityFieldButton } from '../../../../common/components/Form/AddEntityFieldButton/AddEntityFieldButton'
import { InputFormField } from '../../../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../../../common/components/Form/SelectFormField/SelectFormField'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../common/components/PopupMenu/PopupActionMenu'
import { SelectVariant } from '../../../../common/components/Select/Select'
import { Button } from '../../../../common/components/button/Button'
import { useDisableBodyScroll } from '../../../../hooks/useDisableBodyScroll'
import { DeepPartial, FormErrorType, MemberRole } from '../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { getMemberRoleOptions } from '../helpers'
import styles from './AddMembersModal.module.css'
import Papa from 'papaparse'
import { useCallback, useState } from 'react'
import { ShowAllMembersButton } from '../../../../common/components/Form/ShowAllMembersButton/ShowAllMembersButton'
import { AddMembersFormValues, Member } from './AddMembersModal'
import { useNotification } from '../../../../contexts/NotificationContext'
import { AddMemberError } from '../../../../common/components/Form/AddMemberError/AddMemberError'
import { emailValidation } from '../../../../util/validate'
import { getIcon } from '../../../../common/components/icons/utils'

type AddMembersProps = {
  onPreview: () => void
  onClose: () => void
  values: AddMembersFormValues | null
  setValue: (member: Member) => void
  setAllValues: (values: AddMembersFormValues) => void
  memberCount: number
  maxMembers: number
  limitMembers: boolean
}

const validate = (values: DeepPartial<AddMembersFormValues>): FormErrorType<AddMembersFormValues> => {
  const returnableMembers = (values.members || []).map((member) => ({
    name: safeIsNullOrEmpty(member?.name) ? i18next.t('validation_errors.required', 'Required') : undefined,
    email: emailValidation(member?.email, values),
  }))
  return { members: returnableMembers }
}

const initialValues = { members: [{ name: '', email: '', orgId: '', role: MemberRole.INSTRUCTOR }] }

export const AddMembers: React.FC<AddMembersProps> = ({
  onClose,
  onPreview,
  values,
  setValue,
  setAllValues,
  memberCount,
  maxMembers,
  limitMembers,
}) => {
  const { t } = useTranslation()
  useDisableBodyScroll()
  const [showAllMembers, setShowAllMembers] = useState(true)
  const { notifyError } = useNotification()
  const [updatedMemberCount, setUpdatedMemberCount] = useState(memberCount + 1)

  const validateAndAddCSVData = useCallback(
    (data: any) => {
      const memberKeys = ['name', 'email', 'role']
      if (data.length > 9) {
        setShowAllMembers(false)
      }
      if (data instanceof Array) {
        const hasKeys = data.every((member, index) => {
          if (memberKeys.every((memberKey) => member.hasOwnProperty(memberKey))) {
            if (typeof member.role === 'string') {
              const roleUpperCase = member.role.trim().toUpperCase()
              if (
                roleUpperCase === 'ADMIN' ||
                roleUpperCase === 'INSTRUCTOR' ||
                roleUpperCase === 'SUPERUSER' ||
                roleUpperCase === 'SUPERUSER_ADMIN'
              ) {
                const newMember: Member = {
                  name: member.name,
                  email: member.email,
                  role: roleUpperCase,
                  orgId: member.orgId ? member.orgId : '',
                }
                setUpdatedMemberCount((prev) => prev + 1)
                setValue(newMember)
                return true
              }
              notifyError({
                title: t(
                  'settings_organization.members.add_members.errors.downloading_csv.title',
                  'Error downloading .csv file',
                ),
                content: t(
                  'settings_organization.members.add_members.errors.downloading_csv.content',
                  'Error on line %{line}',
                  { line: index + 2 },
                ),
              })
              notifyError({
                title: t(
                  'settings_organization.members.add_members.errors.downloading_csv.title',
                  'Error downloading .csv file',
                ),
                content: t(
                  'settings_organization.members.add_members.errors.downloading_csv.content',
                  'Error on line %{line}',
                  { line: index + 2 },
                ),
              })
              return false
            }
          }
          notifyError({
            title: t(
              'settings_organization.members.add_members.errors.downloading_csv.title',
              'Error downloading .csv file',
            ),
            content: t(
              'settings_organization.members.add_members.errors.downloading_csv.content',
              'Error on line %{line}',
              { line: index + 2 },
            ),
          })
          return false
        })
        if (hasKeys) return true
      }
      notifyError({
        title: t(
          'settings_organization.members.add_members.errors.downloading_csv.title',
          'Error downloading .csv file',
        ),
        content: t(
          'settings_organization.members.add_members.errors.downloading_csv_no_headers.content',
          'Something went wrong when adding .csv file. This is most likely because some data in the file was not what we expected.',
        ),
      })
      return false
    },
    [notifyError, setValue, t],
  )

  const headerTransform = (header: string) => {
    return header.toLocaleLowerCase().replace('-', '')
  }

  const parseMemberCSV = useCallback(
    (data: any): Member[] | void => {
      const parsed = Papa.parse<Member>(data, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: 'greedy',
        transformHeader: headerTransform,
        complete: (results) => {
          if (results.errors.length) {
            notifyError({
              title: t('settings_organization.members.add_members.errors.parsing_csv.title', 'Error parsing .csv file'),
              content:
                t(
                  'settings_organization.members.add_members.errors.parsing_csv.content',
                  'Could not parse the .csv file',
                ) +
                '\n' +
                results.errors[0].message +
                ', row: ' +
                results.errors[0].row,
            })
          } else if (results.data) {
            validateAndAddCSVData(results.data)
          } else {
            notifyError({
              title: t('settings_organization.members.add_members.errors.parsing_csv.title', 'Error parsing .csv file'),
              content: t(
                'settings_organization.members.add_members.errors.parsing_csv.content',
                'Could not parse the .csv file',
              ),
            })
          }
        },
      })
      return parsed
    },
    [notifyError, t, validateAndAddCSVData],
  )

  const onAddCSV = useCallback(
    (e: any) => {
      e.preventDefault()

      parseMemberCSV(e.target.files[0])
    },
    [parseMemberCSV],
  )

  const handleSetAllValues = useCallback(
    (values: AddMembersFormValues) => {
      const newValues = {
        message: '',
        members: values.members,
      }
      setAllValues(newValues)
    },
    [setAllValues],
  )

  const onPreviewInternal = (values: AddMembersFormValues) => {
    handleSetAllValues(values)
    onPreview()
  }

  const handleDeleteRows = useCallback((rows: number | undefined) => {
    setUpdatedMemberCount((prev) => prev - 1)
    if (rows !== undefined && rows < 9) {
      setShowAllMembers(true)
    }
  }, [])

  return (
    <>
      <div className={classNames(styles.description, 'medium')}>
        <Trans i18nKey='settings_organization.members.add_members.description' components={{ 1: <br /> }}>
          {
            'Adding members to your organisation allows them to create and share content. <br/> <br/>When uploading an Excel or CSV file, ensure the first column has member names, the second has emails, the third has member IDs (leave this column empty if you do not wish to use it), and the fourth contains roles.'
          }
        </Trans>
        <div style={{ marginTop: '20px' }}>
          {t('settings_organization.members.add_members.CSV_example', 'Example of correct CSV file:')}
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={classNames(styles.tr, styles.trFirst)}>
              <th className={classNames(styles.th, styles.thLT)}>Name</th>
              <th className={styles.th}>Email</th>
              <th className={styles.th}>ID</th>
              <th className={classNames(styles.th, styles.thRT)}>Role</th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.tr}>
              <td className={styles.td}> </td>
              <td className={styles.td}> </td>
              <td className={styles.td}> </td>
              <td className={styles.td}> </td>
            </tr>
            <tr className={styles.tr}>
              <td className={styles.td}> </td>
              <td className={styles.td}> </td>
              <td className={styles.td}> </td>
              <td className={styles.td}> </td>
            </tr>
            <tr className={classNames(styles.tr, styles.trLast)}>
              <td className={classNames(styles.td, styles.tdLB)}> </td>
              <td className={styles.td}> </td>
              <td className={styles.td}> </td>
              <td className={classNames(styles.td, styles.tdRB)}> </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.csvButtonContainer}>
        <form>
          <label className={styles.csvButton}>
            <input type={'file'} id={'csvFileInput'} accept={'.csv'} onChange={onAddCSV} style={{ display: 'none' }} />
            {getIcon('textFile')}
            {t('settings_organization.members.add_members.button_text.upload_csv', 'Upload CSV')}
          </label>
        </form>
      </div>
      <Form<AddMembersFormValues>
        onSubmit={onPreviewInternal}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={values ? values : initialValues}
      >
        {({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.mainFormContainer}>
              <div className={styles.rowsContainer}>
                <FieldArray name='members'>
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div
                        key={`member_${index}`}
                        className={classNames(styles.addMemberRow, index > 9 && !showAllMembers && styles.extraRow)}
                      >
                        <InputFormField
                          name={`${name}.name`}
                          label={t('settings_organization.members.add_members.labels.name', 'Name')}
                          placeholder={t('settings_organization.members.add_members.placeholders.name', 'Type name')}
                        />
                        <InputFormField
                          name={`${name}.email`}
                          type='email'
                          label={t('settings_organization.members.add_members.labels.email', 'Email')}
                          placeholder={t(
                            'settings_organization.members.add_members.placeholders.email',
                            'Enter E-mail',
                          )}
                        />
                        <InputFormField
                          name={`${name}.orgId`}
                          label={t('settings_organization.members.add_members.labels.optional_id', 'ID (optional)')}
                          placeholder={t(
                            'settings_organization.members.add_members.placeholders.optional_id',
                            'Other information',
                          )}
                        />
                        <div className={styles.lastColumn}>
                          <SelectFormField
                            name={`${name}.role`}
                            label={t('settings_organization.members.add_members.labels.role', 'Role')}
                            options={getMemberRoleOptions(t)}
                            variant={SelectVariant.XLarge}
                          />
                          {fields?.length && fields.length > 1 && (
                            <div className={styles.actionMenu}>
                              <PopupActionMenu id='add-members-action-menu'>
                                <PopupActionMenuItem
                                  icon='trash'
                                  text={t('common.delete', 'Delete')}
                                  onClick={() => {
                                    fields.remove(index)
                                    handleDeleteRows(fields?.length)
                                  }}
                                />
                              </PopupActionMenu>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              </div>
              <div className={styles.buttonContainer}>
                {(!limitMembers || updatedMemberCount < maxMembers) && showAllMembers && (
                  <AddEntityFieldButton
                    onClick={() => {
                      form.mutators.push('members', { role: MemberRole.INSTRUCTOR })
                      setUpdatedMemberCount((prev) => prev + 1)
                    }}
                    disabled={submitting}
                  >
                    {t('settings_organization.members.add_members.button_text.add_member', 'Add member')}
                  </AddEntityFieldButton>
                )}
                {!showAllMembers && (
                  <ShowAllMembersButton onClick={() => setShowAllMembers(true)}>
                    {t('settings_organization.members.add_members.button_text.view_all', 'View all')}
                  </ShowAllMembersButton>
                )}
                {limitMembers && updatedMemberCount >= maxMembers && (
                  <AddMemberError>
                    {t(
                      'settings_organization.members.add_members.errors.add_member_button_error',
                      'Remove members or upgrade your licence to add more.',
                    )}
                  </AddMemberError>
                )}
              </div>
            </div>
            <div className={styles.footer}>
              <Button variant='outline-normal' onClick={onClose} disabled={submitting}>
                {t('settings_organization.members.add_members.button_text.cancel', 'Cancel')}
              </Button>
              <div className={styles.footerRight}>
                {limitMembers && (
                  <div className={classNames(updatedMemberCount > maxMembers && styles.errorRed)}>
                    {updatedMemberCount}/{maxMembers}
                    {t('settings_organization.members.add_members.member_count', 'members')}
                  </div>
                )}
                <Button type='submit' disabled={submitting || (limitMembers && updatedMemberCount > maxMembers)}>
                  {t('settings_organization.members.add_members.button_text.preview_and_send', 'Preview and send')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </>
  )
}
