import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNotification } from '../contexts/NotificationContext'
import { createDecorator } from '../util/submitDecorator'

export const useFormSubmitFailureListener = () => {
  const { t } = useTranslation()
  const { notifyError } = useNotification()

  const [submitFailedToken, setSubmitFailedToken] = useState<number>()

  useEffect(() => {
    if (submitFailedToken) {
      notifyError({
        title: t('validation_errors.general_toast_error.title', 'Check your information'),
        content: t(
          'validation_errors.general_toast_error.description',
          'Enter appropriate data into all fields before proceeding',
        ),
        timeout: 3_000,
      })
    }
  }, [submitFailedToken, notifyError, t])

  const submitListener = useMemo(
    () =>
      createDecorator<any>({
        afterSubmitFailed: () => {
          setSubmitFailedToken((prev) => (prev == null ? 1 : prev + 1))
        },
      }),
    [],
  )

  return { submitFailedToken, submitListener }
}
