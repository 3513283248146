import classNames from 'classnames'
import styles from './CreativeAnswer.module.css'

type PointPercentageButtonsProps = {
  name: string
  activePercentage?: number
  disabled: boolean
  onChange: (percentage: number) => void
}

export const PERCENTAGES = [0, 25, 50, 75, 100]

export const PointPercentageButtons: React.FC<PointPercentageButtonsProps> = ({
  name,
  activePercentage,
  disabled,
  onChange,
}) => {
  const onClickCreator = (percentage: number) => () => {
    onChange(percentage)
  }

  return (
    <div className={styles.percentageButtonsContainer}>
      {PERCENTAGES.map((percentage) => (
        <button
          key={`${name}_${percentage}`}
          type='button'
          className={classNames(
            styles.percentageButton,
            activePercentage === percentage && styles.percentageButton_active,
            disabled && styles.percentageButton_disabled,
            'tiny',
          )}
          onClick={onClickCreator(percentage)}
          disabled={disabled}
        >
          <span>{percentage}</span>
          <span>%</span>
        </button>
      ))}
    </div>
  )
}
