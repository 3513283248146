import classNames from 'classnames'
import { useField } from 'react-final-form'
import { FormFieldProps } from '../../../../types/commonTypes'
import { noop } from '../../../../util/functional'
import { SelectableIcon } from '../../SelectableIcon/SelectableIcon'
import { IconName } from '../../icons/utils'
import { ERROR_ROUNDED_OUTLINE_CLASS_NAME, FormFieldWithError } from '../FormFieldError/FormFieldWithError'
import commonFieldStyles from '../FormFields.module.css'
import styles from './SelectableIconFormField.module.css'

export type SelectableIconItem = {
  id: string
  iconName?: IconName
  disabled?: boolean
  text: string
  tooltipText?: string
  iconStyle?: React.CSSProperties
  alwaysSelected?: boolean
}

type SelectableIconFormFieldProps = {
  options: SelectableIconItem[]
  size?: 'Small' | 'Medium' | 'Large'
  iconClassName?: string
  disabled?: boolean
}

export const SelectableIconFormField: React.FC<FormFieldProps<SelectableIconFormFieldProps>> = ({
  options,
  name,
  label,
  srOnlyLabel,
  size,
  iconClassName,
  validateOnlyIfDirty,
  disabled,
}) => {
  const { input } = useField<string[]>(name)

  const handleChange = (id: string) => {
    if ((input.value || []).includes(id)) {
      input.onChange((input.value || []).filter((item) => item !== id))
    } else {
      input.onChange([...(input.value || []), id])
    }
  }

  return (
    <FormFieldWithError name={name} validateOnlyIfDirty={validateOnlyIfDirty}>
      <label className={classNames(srOnlyLabel && 'sr-only', commonFieldStyles.label)}>{label}</label>
      <div className={classNames(styles.inputsWrapper, ERROR_ROUNDED_OUTLINE_CLASS_NAME)}>
        {options.map((option) => (
          <SelectableIcon
            key={`${name}:${option.id}`}
            id={option.id}
            checked={(input.value || []).includes(option.id)}
            onChange={option.alwaysSelected ? noop : handleChange}
            text={option.text}
            tooltipText={option.tooltipText}
            iconName={option.iconName}
            iconStyle={option.iconStyle}
            disabled={disabled ?? option.disabled}
            size={size}
            className={iconClassName}
          />
        ))}
      </div>
    </FormFieldWithError>
  )
}
