import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../common/components/button/Button'
import { RoundButton } from '../../../../../common/components/button/RoundButton'
import { getIcon } from '../../../../../common/components/icons/utils'
import { Game } from '../../../../../types/commonTypes'
import styles from '../AddPerson.module.css'
import { PinCodeArea } from './PinCodeArea'

//This is only for development since we need https for handleCopy to work.
const unsecuredCopyToClipboard = (copyText: string) => {
  const textArea = document.createElement('textarea')
  textArea.value = copyText
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    return document.execCommand('copy')
  } catch (err) {
    throw err
  } finally {
    document.body.removeChild(textArea)
  }
}
const copyToClipboard = async (copyText: string) => {
  try {
    if (window.isSecureContext && navigator.clipboard) {
      await navigator.clipboard.writeText(copyText)
      return true
    } else {
      return unsecuredCopyToClipboard(copyText)
    }
  } catch (error) {
    console.error('Unable to copy to clipboard', error)
  }
}

const isDev = process.env.REACT_APP_ENV === 'development'

const fabButtonStyle = {
  fontSize: '1.5rem',
  width: '3.25rem',
  height: '3.25rem',
  background: 'var(--white-gradient)',
  color: 'var(--primary-normal)',
  marginLeft: '0.5rem',
}

type InvitePlayerProps = {
  game: Game
}

export const InvitePlayer: React.FC<InvitePlayerProps> = ({ game }) => {
  const { t, i18n } = useTranslation()
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const pinUrl = `${window.location.protocol}//${window.location.host}/login?pin=${game.pinCode}&l=${i18n.language}`
  const emailRequired = game.advancedSettings.emailRequired
  const teamMemberNamesRequired = game.advancedSettings.teamMemberNamesRequired

  const handleCopy = async () => {
    const copied = await copyToClipboard(pinUrl)
    if (copied) {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
  }

  const handleExportInfo = () => {
    window.open(
      `${window.location.protocol}//${window.location.host}/share-game?title=${game.name}&pin=${game.pinCode}&is3D=${!!game.gameBoardSettings.is3D}&mapUrl=${game.gameBoardSettings.gameBoards[0].url}`,
      '_blank',
    )
  }

  return (
    <div>
      <div className={styles.divider} />
      <div className={styles.pinAreaTitle}>{t('game_editor.add_people.share_game', 'Share game login to players')}</div>
      <div className={styles.linkArea}>
        <Button onClick={handleCopy} variant='borderless-normal'>
          {getIcon('link')}
          {isCopied
            ? t('game_editor.add_people.copy_link_copied', 'Copied!')
            : t('game_editor.add_people.copy_link', 'Copy link')}
        </Button>
      </div>
      <div className={styles.pinArea}>
        <div className={styles.pinCodeContainer}>
          <PinCodeArea pinCode={game.pinCode} gameName={game.name} is3D={game.gameBoardSettings.is3D}/>
          {isDev && <RoundButton className='hide-outscoped-feature' icon='share' style={fabButtonStyle} />}
        </div>

        <RoundButton
          onClick={handleExportInfo}
          variant='normal'
          icon='open'
          color='white'
          style={{ fontSize: '1.25rem' }}
        />
      </div>
      {(emailRequired || teamMemberNamesRequired) && (
        <div className={styles.extraInfoRow}>
          {emailRequired && !teamMemberNamesRequired && (
            <span className={classNames(styles.pinAreaText)}>
              {t('game_editor.add_people.players_provide_email', 'Players are asked to provide their e-mail address.')}
            </span>
          )}
          {teamMemberNamesRequired && !emailRequired && (
            <span className={classNames(styles.pinAreaText)}>
              {t(
                'game_editor.add_people.players_provide_team_member_names',
                'Players will be asked to provide team member names.',
              )}
            </span>
          )}
          {teamMemberNamesRequired && emailRequired && (
            <span className={classNames(styles.pinAreaText)}>
              {t(
                'game_editor.add_people.players_provide_team_member_names_and_email',
                'Players will be asked to provide their e-mail address and team member names.',
              )}
            </span>
          )}
        </div>
      )}
    </div>
  )
}
