import classNames from 'classnames'
import { useMemo } from 'react'
import { Evaluation, Game } from '../../../../../../../types/commonTypes'
import sidebarStyles from '../../../EditorSidebar.module.css'
import { EvaluationSortBy, PlayerSummary } from '../../types'
import { EvaluationHeaderPlayer } from './components/PlayersEvaluation/EvaluationHeaderPlayer'
import { PlayerEvaluationInfoAndSort } from './components/PlayersEvaluation/PlayerEvaluationInfoAndSort'
import { PlayerEvaluationList } from './components/PlayersEvaluation/PlayerEvaluationList'

type PlayersEvaluationPanelProps = {
  evaluations: Evaluation[]
  game: Game
  isCompact: boolean
  onClose: () => void
  onSelectTask: (taskId: number) => void
  onSetSortBy: (sortBy: EvaluationSortBy) => void
  playerSummary: PlayerSummary
  selectedTaskId?: number
  sortBy: EvaluationSortBy
  awardingBadge: boolean
  onClickAwardBadge: (playerId: number) => void
}

export const PlayersEvaluationPanel: React.FC<PlayersEvaluationPanelProps> = ({
  evaluations,
  game,
  isCompact,
  onClose,
  onSelectTask,
  onSetSortBy,
  playerSummary,
  selectedTaskId,
  sortBy,
  awardingBadge,
  onClickAwardBadge,
}) => {
  const onClickAwardBadgeInternal = useMemo(() => {
    return game.advancedSettings.badgesEnabled ? () => onClickAwardBadge(playerSummary.player.id) : undefined
  }, [game.advancedSettings.badgesEnabled, playerSummary.player.id, onClickAwardBadge])

  return (
    <>
      <div
        className={classNames(
          sidebarStyles.editorSidebarPanelContainer,
          (selectedTaskId != null || awardingBadge) && sidebarStyles.secondPanelWithThirdOpen,
        )}
      >
        <EvaluationHeaderPlayer
          onClose={onClose}
          playerSummary={playerSummary}
          noPointsGame={game.advancedSettings.noPointsGame}
          tasksCount={game.tasks.length}
          badgesEnabled={game.advancedSettings.badgesEnabled}
        />
        <PlayerEvaluationInfoAndSort
          playerSummary={playerSummary}
          tasksCount={game.tasks.length}
          sortBy={sortBy}
          onSetSortBy={onSetSortBy}
        />
        <PlayerEvaluationList
          evaluations={evaluations}
          onSelectTask={onSelectTask}
          noPointsGame={game.advancedSettings.noPointsGame}
          selectedTaskId={selectedTaskId}
          hasLevels={game.advancedSettings.levelsEnabled}
          isCompact={isCompact}
          sortBy={sortBy}
          onClickAwardBadge={onClickAwardBadgeInternal}
        />
      </div>
    </>
  )
}
