import classNames from 'classnames'
import React from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { DEFAULT_GAME_BOARD_SETTINGS } from '../../../types'
import { GameBoardPicker } from '../../GameBoardsPicker/GameBoardsPicker'
import styles from './Steps.module.css'

export const GameBoardStep: React.FC = () => {
  const { input } = useField('gameBoardSettings')
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.container)}>
      <h1>{t('game_editor.wizard.game_board_step.title', 'How will it look?')}</h1>
      <h4>{t('game_editor.wizard.game_board_step.subtitle', 'Choose the game board, you can edit this later.')}</h4>
      <GameBoardPicker initialValues={DEFAULT_GAME_BOARD_SETTINGS} onChange={input.onChange} />
    </div>
  )
}
