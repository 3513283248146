import classNames from 'classnames'
import { CSSProperties, forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ScoreGradientIcon } from '../../../../../../../common/components/icons'
import { Tooltip } from '../../../../../../../common/components/tooltip/Tooltip'
import { Task } from '../../../../../../../types/commonTypes'
import { getTaskIcon } from '../../../../../../../util/game'
import { addPinHighlight, removePinHighlight } from '../../../../Marker/TaskPinHelper'
import { getTitleForTask } from '../../../../Tasks/TaskModal/helpers'
import { TaskAction, TaskActionFn } from '../../types'
import styles from './TaskCard.module.css'
import { TaskMoreMenu } from './TaskMoreMenu'

export type TaskCardProps = {
  task: Task
  maxBoardIndex?: number
  maxLevelIndex?: number
  index?: number
  maxIndex?: number
  compact?: boolean
  noPointsGame?: boolean
  showLevel?: boolean
  showOrder?: boolean
  onAction: TaskActionFn
  isBeingDragged?: boolean
  style?: CSSProperties | undefined
  sortableProps?: any
  viewOnly: boolean
}

export const TaskCard = forwardRef<HTMLLIElement, TaskCardProps>(
  (
    {
      task,
      index = 0,
      maxIndex = 0,
      maxBoardIndex = 0,
      maxLevelIndex = 0,
      compact,
      noPointsGame,
      showOrder,
      showLevel,
      style,
      isBeingDragged,
      sortableProps,
      viewOnly,
      onAction,
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const onEdit = useCallback(() => onAction({ task, action: TaskAction.EDIT }), [task, onAction])

    const handleMouseEnter = useCallback(() => addPinHighlight(task.id), [task.id])
    const handleMouseLeave = useCallback(() => removePinHighlight(task.id), [task.id])

    return (
      <li
        className={classNames(
          styles.container,
          compact && styles.containerCompact,
          isBeingDragged && styles.containerDragged,
        )}
        onClick={onEdit}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={style}
        ref={ref}
        {...sortableProps}
      >
        <div className={styles.leftContainer}>
          {showOrder ? (
            <span className={classNames(styles.taskOrder, compact && styles.taskOrder_compact)}>
              {/* NOTE: order should match index, and showing index works better for drag and drop reordering */}
              {index + 1}
            </span>
          ) : (
            getTaskIcon(task, showLevel)
          )}
        </div>
        <div className={classNames(styles.middleContainer, compact && styles.middleContainerCompact)}>
          <h5 className={styles.title}>
            <Tooltip tooltipContent={task.name} overflowOnly tooltipClassName={styles.taskNameTooltip}>
              {(tooltipProps) => (
                <span className={styles.titleInnerText} {...tooltipProps}>
                  {task.name}
                </span>
              )}
            </Tooltip>
          </h5>
          <div className={styles.middleBottomRow}>
            <div className={classNames(styles.statusAndTypePill, compact && styles.hideIfCompact)}>
              {getTitleForTask(task, t)}
            </div>
            {!noPointsGame && (
              <div className={styles.iconAndPointsContainer}>
                <ScoreGradientIcon />
                {task.pointsSum}
              </div>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <TaskMoreMenu
            task={task}
            onAction={onAction}
            index={index}
            maxBoardIndex={maxBoardIndex}
            maxIndex={maxIndex}
            maxLevelIndex={maxLevelIndex}
            viewOnly={viewOnly}
          />
        </div>
      </li>
    )
  },
)
