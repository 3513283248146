import classNames from 'classnames'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FormFieldProps } from '../../../../types/commonTypes'
import { ERROR_OUTLINE_CLASS_NAME, FormFieldWithError } from '../FormFieldError/FormFieldWithError'
import sharedFieldStyles from '../FormFields.module.css'
import styles from './TextAreaFormField.module.css'

export type TextAreaFormFieldProps = FormFieldProps<React.HTMLProps<HTMLTextAreaElement>>

export const TextAreaFormField: React.FC<TextAreaFormFieldProps> = ({
  name,
  validateOnlyIfDirty,
  inline,
  fieldContainerClassName,
  errorClassName,
  id,
  srOnlyLabel,
  label,
  className,
  rows = 4,
  maxLength,
  ...rest
}) => {
  const { input } = useField(name)
  const { t } = useTranslation()

  return (
    <FormFieldWithError
      name={name}
      validateOnlyIfDirty={validateOnlyIfDirty}
      wrapperClassName={classNames(inline && sharedFieldStyles.multilineInlineField, fieldContainerClassName)}
      errorClassName={classNames(styles.textAreaError, errorClassName)}
    >
      <label
        htmlFor={id ?? name}
        className={classNames(
          srOnlyLabel && 'sr-only',
          sharedFieldStyles.label,
          !inline && sharedFieldStyles.labelWithMargin,
        )}
      >
        {label}
      </label>
      <div className={styles.textAreaWithLabelAfter}>
        <textarea
          {...rest}
          {...input}
          id={id ?? name}
          rows={rows}
          {...(maxLength && { maxLength: maxLength })}
          className={classNames(styles.field, ERROR_OUTLINE_CLASS_NAME, className)}
        />
        {maxLength && (
          <div className={styles.remainingCharsLabel}>
            {t('common.x_characters_left', 'You have %{remaining} characters remaining', {
              remaining: maxLength - input.value.length,
            })}
          </div>
        )}
      </div>
    </FormFieldWithError>
  )
}
