// TODO: Extract these constants to enum

import { LibraryLanguage } from '../api/gameTypes'
import { MapOptions } from '../pages/GameEditor/types'
import { DeepMappedType } from '../types/commonTypes'
import { SelectOption } from './components/Select/Select'

// UserMenu related constants
export const USER_MENU_INITIAL = 'initialMenu'
export const USER_MENU_LANGUAGE = 'languageMenu'
export const USER_MENU_ACCOUNT = 'accountMenu'

// MenuItemTypes
export const MENU_ITEM_TYPE_DEFAULT = 'defaultItem'
export const MENU_ITEM_TYPE_SUBMENU = 'submenuItem'
export const MENU_ITEM_TYPE_BACK = 'backItem'

export const MODALS_ROOT_ID = 'MODALS_ROOT'
export const TOOLTIP_ROOT_ID = 'TOOLTIP_ROOT'

export const LANGUAGE_FLAGS: DeepMappedType<typeof LibraryLanguage, string> = {
  en: '🇬🇧',
  fi: '🇫🇮',
  pt: '🇵🇹',
  sv: '🇸🇪',
  de: '🇩🇪',
  nl: '🇳🇱',
  nb: '🇳🇴',
  es: '🇪🇸',
}

export const LANGUAGE_LABELS: DeepMappedType<typeof LibraryLanguage, string> = {
  en: 'English',
  fi: 'Suomi',
  pt: 'Português',
  sv: 'Svenska',
  de: 'Deutsch',
  nl: 'Nederlands',
  nb: 'Norsk Bokmål',
  es: 'Español',
}

export const LANGUAGE_SELECT_OPTIONS: SelectOption[] = Object.keys(LibraryLanguage).map(
  (key): SelectOption => ({
    value: key,
    label: LANGUAGE_LABELS[key as keyof typeof LibraryLanguage],
    prefixElement: {
      type: 'rawContent',
      rawContent: LANGUAGE_FLAGS[key as keyof typeof LibraryLanguage],
    },
  }),
)

export const LANGUAGE_SELECT_DEFAULT_OPTION: SelectOption =
  LANGUAGE_SELECT_OPTIONS.find((option) => option.value === navigator.language.split('-')[0]) ||
  LANGUAGE_SELECT_OPTIONS.find((option) => option.value === 'en')!

export const DEFAULT_MAP_OPTIONS: MapOptions = {
  // Helsinki coordinates
  center: [24.9384, 60.1699],
  zoom: 9,
}

export const BILLING_EDUCATION_PRICE = 0.41
export const BILLING_CORPORATE_PRICE = 1.5
export const EMPTY_CONTENT_FALLBACK = '-'

export const LEGACY_EXPLORATION_OVERVIEW_SIZE_X = 400
export const LEGACY_EXPLORATION_OVERVIEW_SIZE_Y = 200
