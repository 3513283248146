import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { Fragment } from 'react'
import { PointsView } from '../../../pages/GameEditor/components/EditorSidebar/Play/components/GradeAnswersPanel/components/PointsView'
import { MissingWordSubtask } from '../../../types/commonTypes'
import { anyMissingWordFieldRegex } from '../../../util/missingWord'
import { isHtmlTextEmpty } from '../../../util/string'
import styles from './SubtaskPreview.module.css'

type MissingWordPreviewProps = {
  subtask: MissingWordSubtask
  noPointsGame?: boolean
}

const MissingWordHtmlPart: React.FC<{ html: string }> = ({ html }) => {
  if (!isHtmlTextEmpty(html) || html.includes('<img') || html.includes('<video')) {
    return (
      <div
        className={classNames(styles.answerOptionRow_singleCol, 'tiny')}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(html, {
            FORBID_TAGS: ['button'],
            KEEP_CONTENT: false,
          }),
        }}
      />
    )
  }
  return null
}

export const MissingWordPreview: React.FC<MissingWordPreviewProps> = ({ subtask, noPointsGame }) => {
  const htmlParts = subtask.description.replaceAll(anyMissingWordFieldRegex, ';|;').split(';|;')

  return (
    <div className={styles.subtaskPreviewContainer}>
      {subtask.data.answers?.map((answer, index) => {
        return (
          <Fragment key={`${subtask.id}_mw_${index}`}>
            <MissingWordHtmlPart html={htmlParts[index]} />
            <div className={styles.answerOptionRow}>
              <div className={styles.missingWordPreview}>{answer.word}</div>
              {!noPointsGame && (
                <div>
                  <PointsView points={answer.points ?? 0} />
                </div>
              )}
            </div>
          </Fragment>
        )
      })}
      <MissingWordHtmlPart html={htmlParts[htmlParts.length - 1]} />
    </div>
  )
}
