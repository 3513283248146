import React from 'react'
import { USER_MENU_ACCOUNT, USER_MENU_INITIAL, USER_MENU_LANGUAGE } from '../../../../common/constants'
import { Business } from '../../../../types/commonTypes'
import { AccountMenu } from '../AccountMenu/AccountMenu'
import { InitialMenu } from '../InitialMenu/InitialMenu'
import { LanguageMenu } from '../LanguageMenu/LanguageMenu'

export const MenuSelector: React.FC<{
  menuType: string
  activeLanguage: string
  activeBusiness?: Business
  userBelongsToSeveralBusinesses: boolean
  showSettingsLink: boolean
  onInitialMenuItemClick: (itemType: string) => void
  onLanguageMenuItemClick: (menuType: string, selectedLanguage: string) => void
  onAccountMenuItemClick: (menuType: string, selectedAccount: string) => void
  onMenuBackClick: (itemType: string) => void
}> = ({
  menuType,
  activeLanguage,
  activeBusiness,
  userBelongsToSeveralBusinesses,
  showSettingsLink,
  onInitialMenuItemClick,
  onLanguageMenuItemClick,
  onAccountMenuItemClick,
  onMenuBackClick,
}) => {
  switch (menuType) {
    case USER_MENU_LANGUAGE:
      return <LanguageMenu onMenuItemClick={onLanguageMenuItemClick} onMenuBackClick={onMenuBackClick} />
    case USER_MENU_ACCOUNT:
      return <AccountMenu onMenuItemClick={onAccountMenuItemClick} onMenuBackClick={onMenuBackClick} />
    case USER_MENU_INITIAL:
      return (
        <InitialMenu
          onMenuItemClick={onInitialMenuItemClick}
          userBelongsToSeveralBusinesses={userBelongsToSeveralBusinesses}
          activeLanguage={activeLanguage}
          activeBusiness={activeBusiness}
          showSettingsLink={showSettingsLink}
        />
      )
    default:
      return null
  }
}
