import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useUser } from '../../contexts/userContext'
import { useGameEditorNavigation } from '../../hooks/useGameEditorNavigation'
import { getOldUIRoutes } from '../../routes'
import { EditorPreference } from '../../util/editorPreference'
import styles from './GlobalNotification.module.css'

type GlobalNotificationProps = {
  isEditor?: boolean
  gameId?: string | null
}

export const GlobalNotification: React.FC<GlobalNotificationProps> = ({ isEditor = false, gameId = null }) => {
  const { t } = useTranslation()
  const { updateEditorPreference } = useUser()
  const { gameId: gameIdParam } = useParams()
  const { goToCreateGame } = useGameEditorNavigation()

  const handleGoToLegacyEditor = useCallback(async () => {
    updateEditorPreference(EditorPreference.LEGACY)
    if (!gameIdParam) {
      await goToCreateGame()
    }
  }, [gameIdParam, goToCreateGame, updateEditorPreference])

  return (
    <div className={styles.container}>
      {isEditor ? (
        <Trans i18nKey={'global_notification.new_editor_info.message'}>
          New game editor (BETA).
          <button
            className={styles.goToOldVersion}
            onClick={handleGoToLegacyEditor}
            aria-label={t('global_notification.new_ui_info.link_to_old_ui_aria_label', 'Go to the old version')}
          >
            Go to the old version
          </button>
        </Trans>
      ) : (
        <Trans i18nKey={'global_notification.new_ui_info.message'}>
          This is the new version of Seppo for instructors (BETA).
          <a
            aria-label={t('global_notification.new_ui_info.link_to_old_ui_aria_label', 'Go to the old version')}
            href={getOldUIRoutes.oldDashboard()}
          >
            Go to the old version.
          </a>
        </Trans>
      )}
    </div>
  )
}
