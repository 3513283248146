import classNames from 'classnames'
import { useRef } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import {
  ERROR_OUTLINE_CLASS_NAME,
  FormFieldWithError,
} from '../../../../common/components/Form/FormFieldError/FormFieldWithError'
import { getIcon } from '../../../../common/components/icons/utils'
import { ObjectAny } from '../../../../types/commonTypes'
import { safeParseInt } from '../../../../util/number'
import styles from './Points.module.css'

export type PointsWidgetSizeVariant = 'big' | 'medium' | 'small'

export const pointsWidthByLng: ObjectAny = {
  fi: 124,
  en: 120,
  sv: 146,
  nl: 168,
  pt: 162,
  es: 140,
  de: 170,
  nb: 138,
}

type PointsInputProps = {
  label?: string
  name: string
  validateOnlyIfDirty?: boolean
  sizeVariant?: PointsWidgetSizeVariant
  disabled?: boolean
  max?: number
  min?: number
  inputMinWidthOverride?: number
}

export const PointsInput: React.FC<PointsInputProps> = ({
  name,
  label,
  validateOnlyIfDirty,
  sizeVariant = 'big',
  disabled,
  max,
  min,
  inputMinWidthOverride,
}) => {
  const { t, i18n } = useTranslation()
  const { input } = useField<number | undefined>(name, {
    type: 'number',
    parse: (value: number | undefined) => safeParseInt(value),
  })
  const inputRef = useRef<HTMLInputElement>(null)
  const onIconClick = () => {
    inputRef.current?.focus()
  }

  return (
    <FormFieldWithError
      name={name}
      validateOnlyIfDirty={validateOnlyIfDirty}
      wrapperClassName={styles.errorWrapper}
      errorClassName={styles.pointsError}
    >
      <label htmlFor={name} className='sr-only'>
        {label ?? t('game_editor.points.default_input_label', 'Points to award')}
      </label>
      <div
        className={classNames(
          ERROR_OUTLINE_CLASS_NAME,
          styles[`pointsWidget_${sizeVariant}`],
          styles.pointsInputContainer,
          disabled && styles.pointsInputContainer_disabled,
        )}
      >
        <div onClick={onIconClick} className={styles.pointsIcon}>
          {getIcon('scoreHeader')}
        </div>
        <input
          ref={inputRef}
          {...input}
          id={name}
          className={classNames('tiny', styles.pointsInput)}
          min={min}
          max={max}
          placeholder={t('game_editor.points.default_input_placeholder', 'Add points')}
          disabled={disabled}
          style={{ width: Math.max(pointsWidthByLng?.[i18n.language] ?? 120, inputMinWidthOverride ?? 0) }}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </div>
    </FormFieldWithError>
  )
}
