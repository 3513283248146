import React from 'react'

import classNames from 'classnames'
import { To } from 'react-router-dom'
import styles from '../MenuItem.module.css'
import shared from '../../../../common/styles/shared.module.css'
import { getIcon, IconName } from '../../icons/utils'

export type MenuItemProps = {
  icon?: IconName
  text: string
  itemType?: string
  onMenuItemClick: (menuType: string) => void
  linkToUrl?: To
  fontSize: 'tiny' | 'normal' | 'medium'
  menuItemStyle?: React.CSSProperties
}

export const BackMenuItem: React.FC<MenuItemProps> = ({
  icon,
  text,
  linkToUrl,
  fontSize,
  itemType = '',
  onMenuItemClick,
}) => {
  const iconSelector = React.useMemo(() => {
    if (icon == null) {
      return null
    } else {
      return getIcon(icon)
    }
  }, [icon])

  const onMenuItemClickInternal = React.useCallback(() => {
    onMenuItemClick(itemType)
  }, [onMenuItemClick, itemType])

  return (
    <button onClick={onMenuItemClickInternal} className={classNames(shared.row, styles.menuContainerUserMenu)}>
      {iconSelector && <span className={classNames(styles.backIcon)}>{iconSelector}</span>}
      <p className={classNames(styles.menuLink, styles.backText, `${fontSize}`)}>{text}</p>
    </button>
  )
}
