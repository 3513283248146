import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useOnClickOutside from 'use-onclickoutside'
import { Button } from '../../common/components/button/Button'
import { RoundButton } from '../../common/components/button/RoundButton'
import { InputField } from '../../common/components/input/InputField'
import { BILLING_CORPORATE_PRICE, BILLING_EDUCATION_PRICE } from '../../common/constants'
import { useUser } from '../../contexts/userContext'
import { routes } from '../../routes'
import { Currencies } from '../../types/commonTypes'
import { LicenseModal } from '../LicenseModal/LicenseModal'
import { UserMenuContainer } from '../UserMenu/UserMenu'
import { TRIAL_PERIOD_LENGTH } from '../WelcomeBlock/constants'
import { getUserActiveDays } from '../WelcomeBlock/helpers'
import styles from './Topbar.module.css'

export const Topbar: React.FC = () => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = React.useState('')
  const [isUserMenuVisible, setIsUserMenuVisible] = React.useState(false)
  const [showLicenseModal, setShowLicenseModal] = React.useState(false)
  const [trialDaysLeft, setTrialDaysLeft] = useState(TRIAL_PERIOD_LENGTH)
  const [showErrorMessage, setShowErrorMessage] = React.useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { user } = useUser()
  const location = useLocation()
  const [_, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (user && user.createdAt) {
      const activeDays = Math.floor(getUserActiveDays(user?.createdAt))
      setTrialDaysLeft(Math.max(TRIAL_PERIOD_LENGTH - activeDays, 0))
    }
  }, [user])

  const onChangeSearchValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
    if (e.currentTarget.value.length === 0) {
      setShowErrorMessage(false)
    }
    if (showErrorMessage && e.currentTarget.value.length > 2) {
      setShowErrorMessage(false)
    }
  }

  const applySearch = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (searchValue.trim().length > 2) {
      if (location.pathname === routes.search) {
        setSearchParams((prev) => {
          prev.set('search', searchValue.trim())
          return prev
        })
      } else {
        navigate({
          pathname: routes.search,
          search: `?${createSearchParams({ search: searchValue.trim() })}`,
        })
      }
      setSearchValue('')
    } else if (searchValue.length > 0 && searchValue.length < 3) {
      setShowErrorMessage(true)
    }
  }

  useOnClickOutside(menuRef, () => {
    setIsUserMenuVisible(false)
  })

  const handleClosingMenu = () => {
    setIsUserMenuVisible(false)
  }

  const handleClickTrialButton = () => setShowLicenseModal(true)

  const handleClickClose = () => setShowLicenseModal(false)

  return (
    <div className={styles.topBar}>
      {showLicenseModal && (
        <LicenseModal
          trialDaysLeft={trialDaysLeft}
          startingPricePerMonth={user?.isEducation ? BILLING_EDUCATION_PRICE : BILLING_CORPORATE_PRICE}
          currency={Currencies.Euro}
          onClickClose={handleClickClose}
        />
      )}
      <form
        className={classNames(styles.searchForm, styles.relativeParent)}
        onSubmit={applySearch}
        onBlur={() => setShowErrorMessage(false)}
      >
        <InputField
          placeholder={t('top_bar.search', 'Search')}
          onChange={onChangeSearchValue}
          value={searchValue}
          icon='search'
          button={
            searchValue.length === 0
              ? undefined
              : {
                  type: 'submit',
                }
          }
          reserveButtonSpace
          inputContainerClassName={styles.searchInput}
          className={classNames(showErrorMessage && styles.overrideDefaultOutline)}
        />
        {showErrorMessage && (
          <p className={styles.warningBadge}>
            {t('top_bar.search_input.error_message', 'Please write at least 3 characters')}
          </p>
        )}
      </form>
      {user?.isTrial && (
        <Button variant='big' className={styles.trialButton} onClick={handleClickTrialButton}>
          {t('top_bar.license_modal.trial_button.trial_days', {
            count: trialDaysLeft,
            defaultValue: '%{count} days left in trial',
          })}
        </Button>
      )}
      <div onClick={() => setIsUserMenuVisible(true)} className={styles.userMenuDropdown}>
        <span className={styles.userInitials}>{user?.userInitials ?? ''}</span>
        <UserMenuContainer ref={menuRef} isUserMenuOpen={isUserMenuVisible} onMenuClose={handleClosingMenu} />
        <RoundButton
          style={{
            fontSize: '1.125rem',
            background: 'transparent',
            boxShadow: 'none',
            color: 'var(--grey-600)',
          }}
          icon={'arrowDown'}
          aria-label={
            isUserMenuVisible
              ? t('accessibility.user_menu_button.close', 'Close user menu')
              : t('accessibility.user_menu_button.open', 'Open user menu')
          }
        />
      </div>
    </div>
  )
}
