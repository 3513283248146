import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getOldUIRoutes } from '../../../routes'
import { Report, TUser } from '../../../types/commonTypes'
import styles from './ReportsPreview.module.css'
import { ReportsTabs } from './ReportsTabs'

type ReportsPreviewProps = {
  user: TUser
}

const getSelectedReportUrl = (selectedReport: Report, user: TUser): string => {
  switch (selectedReport) {
    case Report.BUSINESS:
      return getOldUIRoutes.businessReporting(user.businessReportUrl)
    case Report.GROUP:
      return getOldUIRoutes.businessGroupReporting()
    case Report.PLAYER:
      return getOldUIRoutes.playerReporting()
    case Report.SPONSORED_GAMES:
      return getOldUIRoutes.sponsoredGamesReporting()
    case Report.TEACHER:
      return getOldUIRoutes.teacherReporting()
  }
}

export const ReportsPreview: React.FC<ReportsPreviewProps> = ({ user }) => {
  const { t } = useTranslation()
  const { reports } = user
  const [selectedReport, setSelectedReport] = useState<Report>(user.reports[0])
  const selectedReportUrl = getSelectedReportUrl(selectedReport, user)

  return (
    <div className={styles.mainContainer}>
      <ReportsTabs allowedReports={reports} selectedReport={selectedReport} onSelectReport={setSelectedReport} />
      <div className={styles.reportsView}>
        <a className={styles.reportLink} href={selectedReportUrl} target='_blank' rel='noreferrer'>
          {t('reports_page.view_in_new_tab_link', 'View report in new tab')}
        </a>
        <iframe title='Reports view' src={selectedReportUrl} className={styles.reportContainer} />
      </div>
    </div>
  )
}
