import { arrayMove } from '@dnd-kit/sortable'
import { TaskOrderData } from '../../../../../../../api/gameTypes'
import { Game, Task } from '../../../../../../../types/commonTypes'
import { orderTasksSort } from '../../../../../../../util/game'
import { BoardTasksMap } from '../../types'

export const getTasksMap = (game: Game): BoardTasksMap => {
  const tasksMap: BoardTasksMap = { 0: [] }
  game.gameBoardSettings.gameBoards.forEach((board) => {
    const boardIndex = board.mapIndex ?? 0
    tasksMap[boardIndex] = game.tasks
      .filter((task) => !task.advanced.isFlash && (task.mapIndex ?? 0) === boardIndex)
      .sort(orderTasksSort)
  })
  return tasksMap
}

export const getLevelsTaskData = (tasksMap: BoardTasksMap) => {
  const flatTasks: TaskOrderData[] = []
  Object.keys(tasksMap).forEach((boardIndexKey) => {
    const boardIndex = parseInt(boardIndexKey)
    flatTasks.push(...tasksMap[boardIndex].map((t) => ({ mapId: boardIndex - 1, taskId: t.id })))
  })
  return {
    1: flatTasks,
  }
}

export const changeTaskBoard = (tasksMap: BoardTasksMap, task: Task, newBoardIndex: number) => {
  return {
    ...tasksMap,
    // remove task from it's current board group
    [task.mapIndex ?? 0]: tasksMap[task.mapIndex ?? 0].filter((aTask) => aTask.id !== task.id),
    // add task to the new board group
    [newBoardIndex]: [
      ...tasksMap[newBoardIndex],
      {
        ...task,
        mapIndex: newBoardIndex,
      },
    ],
  }
}

export const changeTaskOrderInGroup = (tasksMap: BoardTasksMap, task: Task, oldIndex: number, newIndex: number) => {
  return {
    ...tasksMap,
    [task.mapIndex ?? 0]: arrayMove(tasksMap[task.mapIndex ?? 0], oldIndex, newIndex),
  }
}
