import React from 'react'

import { ToggleFormField } from '../../../../../common/components/Form/ToggleFormField/ToggleFormField'
import styles from './GameSettingsComponents.module.css'
import { useField } from 'react-final-form'
import classNames from 'classnames'

type GameSettingsToggleProps = {
  name: string
  label: string
  description: string
  descriptionChecked: string
  disabled?: boolean
  toggleContainerClassName?: string
}

export const GameSettingsToggle: React.FC<GameSettingsToggleProps> = ({
  name,
  label,
  description,
  descriptionChecked,
  disabled,
  toggleContainerClassName,
}) => {
  const { input } = useField(name, { type: 'checkbox' })

  return (
    <div className={styles.toggleSwitchContainer}>
      <ToggleFormField
        name={name}
        disabled={disabled}
        fieldContainerClassName={toggleContainerClassName}
        label={
          <div>
            <span
              className={
                input.checked
                  ? classNames(styles.toggleSwitchLabel, styles.toggleSwitchLabelEnabled)
                  : styles.toggleSwitchLabel
              }
            >
              {label}
            </span>
            <span className={styles.toggleSwitchDescription}>
              {input.checked ? descriptionChecked ?? description : description}
            </span>
          </div>
        }
      />
    </div>
  )
}
