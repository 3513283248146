import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTimerGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <path
      fill='currentColor'
      fillOpacity={1}
      d='M4.8 1.5h22c.4 0 .8-.2.8-.7 0-.5-.4-.8-.8-.8h-22c-.5 0-.8.4-.8.8s.3.7.8.7Z'
    />
    <path
      fill='currentColor'
      fillOpacity={1}
      fillRule='evenodd'
      d='M7.1 7.2c.692 3.98 2.956 6.836 6.66 8.57C9.932 17.498 7.7 20.375 7 24.4v.4c0 .7.3 1.4.9 1.9.5.5 1.2.8 1.9.8h12.4c1.5-.2 2.5-1.6 2.3-3.1-.705-4.053-3.04-6.942-6.87-8.666 3.835-1.728 6.07-4.606 6.77-8.634.1-.7-.1-1.4-.5-2-.4-.6-1.1-1-1.8-1.1H9.8c-.8 0-1.4.3-2 .8C7.3 5.3 7 6 7 6.7c0 .2 0 .3.1.5Zm8.6 7.7c-4.1-1.4-6.5-4-7.2-8v-.2c0-.3.2-.6.4-.8.2-.3.6-.4.9-.4h12.1c.3.1.6.2.8.5.2.3.2.6.2.9-.7 4-3 6.6-7.2 8ZM9.8 26h12.1c.7-.1 1.2-.7 1-1.4-.7-4-3-6.6-7.2-8-4.2 1.4-6.5 4-7.2 8v.2c0 .3.1.6.4.9.2.2.5.3.9.3Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillOpacity={1}
      d='M4.8 31.5h22c.4 0 .8-.2.8-.7 0-.5-.4-.8-.8-.8h-22c-.5 0-.8.2-.8.7 0 .5.4.8.8.8Z'
    />
    <defs>
      <linearGradient
        id='timer_gradient_icon_svg__a'
        x1={15.8}
        x2={15.8}
        y1={0}
        y2={31.5}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='timer_gradient_icon_svg__b'
        x1={15.8}
        x2={15.8}
        y1={0}
        y2={31.5}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='timer_gradient_icon_svg__c'
        x1={15.8}
        x2={15.8}
        y1={0}
        y2={31.5}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgTimerGradientIcon
