import { useEffect } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { BusinessSettingsProvider } from '../../contexts/BusinessSettingsContext'
import { ChatContextProvider } from '../../contexts/ChatContextProvider'
import { useGameData as useCommunityGameData } from '../../contexts/CommunityGamesContextProvider'
import { useDebug } from '../../contexts/DebugContext'
import { GameProvider } from '../../contexts/GameContext'
import { useGameData as useOrgLibraryGameData } from '../../contexts/OrgLibraryGamesContextProvider'
import { useGameData } from '../../contexts/OwnGamesContextProvider'
import { useUser } from '../../contexts/userContext'
import { getOldUIRoutes, routes } from '../../routes'
import { isLegacyEditorFullVersion } from '../../util/editorPreference'
import { preventUserCreateGame } from '../../util/game'
import { safeParseInt } from '../../util/number'
import { GameEditor } from './GameEditor'
import { LegacyGameEditor } from './legacy/LegacyGameEditor'

export const GameEditorPage: React.FC = () => {
  const { allowUseNewEditor } = useDebug()
  const { gameId: gameIdParam } = useParams()
  const [searchParams] = useSearchParams()
  const legacy = searchParams.get('legacy')
  const isNew = searchParams.get('is_new')
  const { user } = useUser()
  const gameId = safeParseInt(gameIdParam) ?? null
  const { state } = useLocation()
  const navigate = useNavigate()

  const refreshOwnGames = useGameData().refreshGames
  const refreshCommunityGames = useCommunityGameData().refreshGames
  const refreshOrgLibraryGames = useOrgLibraryGameData().refreshGames

  useEffect(() => {
    return () => {
      refreshOwnGames()
      refreshCommunityGames()
      refreshOrgLibraryGames()
    }
  }, [refreshCommunityGames, refreshOwnGames, refreshOrgLibraryGames])

  useEffect(() => {
    if (isLegacyEditorFullVersion() && user?.preferLegacyEditor && gameId) {
      window.location.href = getOldUIRoutes.gameEditor(gameId.toString(), false, false)
    }
  }, [gameId, user?.preferLegacyEditor])

  useEffect(() => {
    if ((gameId == null || searchParams.get('is_new')) && preventUserCreateGame(user)) {
      navigate(routes.homepage)
    }
  }, [gameId, searchParams, user, navigate])

  if (user == null) {
    return null
  }

  return (!legacy || isNew) && !user.preferLegacyEditor && (allowUseNewEditor || user.allowUseNewEditor) ? (
    <BusinessSettingsProvider>
      <GameProvider gameId={gameId} user={user}>
        <ChatContextProvider gameId={gameId} userId={user.id}>
          <GameEditor initialGameId={gameId} initialIsGameOpen={state?.isOpen} />
        </ChatContextProvider>
      </GameProvider>
    </BusinessSettingsProvider>
  ) : (
    <GameProvider gameId={gameId} user={user}>
      <LegacyGameEditor />
    </GameProvider>
  )
}
