import React from 'react'
import classNames from 'classnames'
import styles from './InfoPill.module.css'

type InfoPillProps = {
  isLibrary: boolean
  children: React.ReactNode
}

export const InfoPill: React.FC<InfoPillProps> = ({ isLibrary, children }) => {
  return (
    <span
      className={classNames('tiny', styles.infoPill, isLibrary ? styles.infoPill_community : styles.infoPill_primary)}
    >
      {children}
    </span>
  )
}
