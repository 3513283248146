import classNames from 'classnames'
import { Badge } from '../../../../../types/commonTypes'
import { BadgeImage } from './BadgeImage'
import styles from './BadgeView.module.css'

type PartialBadge = Omit<Partial<Badge>, 'imageUrl'> & Pick<Badge, 'imageUrl'>

type SelectableBadgeProps = {
  badge: Badge | PartialBadge
  isSelected: boolean
  otherSelected: boolean
  showName?: boolean
  requiredPoints?: number
  onSelect: (badge: Badge | PartialBadge) => void
  isBig?: boolean
  onDarkBackground?: boolean
}

export const SelectableBadge: React.FC<SelectableBadgeProps> = ({
  badge,
  isSelected,
  otherSelected,
  showName,
  requiredPoints,
  onSelect,
  isBig = true,
  onDarkBackground = false,
}) => {
  const onClick = () => onSelect(badge)
  return (
    <button
      type='button'
      onClick={onClick}
      className={classNames(
        styles.selectableBadge,
        isSelected && (onDarkBackground ? styles.selectableBadge_selectedOnDark : styles.selectableBadge_selected),
        otherSelected && styles.selectableBadge_otherSelected,
        isBig && styles.selectableBadge_morePadding,
      )}
    >
      <BadgeImage className={styles.selectableBadgeImage} url={badge.imageUrl} />
      {showName && <span className={styles.badgeTitle}>{badge.name}</span>}
      {requiredPoints != null && <span>{requiredPoints}</span>}
    </button>
  )
}
