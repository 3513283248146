import { useTranslation } from 'react-i18next'
import styles from '../BillingSettings.module.css'

type LicenceUsageProps = {
  usedPinSeats: number
  usedPlayerAccountSeats: number
  remainingUnusedSeats: number
  nextDateWhenLicencesFreed?: string
}

export const LicenceUsage: React.FC<LicenceUsageProps> = ({
  remainingUnusedSeats,
  usedPinSeats,
  usedPlayerAccountSeats,
  nextDateWhenLicencesFreed,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.subSection}>
      <h3 className={styles.subSectionTitle}>{t('settings_billing.licences_info_title', 'Licence usage')}</h3>
      <div className={styles.detailsList}>
        <p className='bold'>{t('settings_billing.licences_info_used_subtitle', 'Used licences')}</p>
        <p>
          {t('settings_billing.licences_info_used_pin_qr_count', {
            defaultValue: 'PIN/QR code: %{used_licences}',
            used_licences: usedPinSeats,
          })}
        </p>
        <p>
          {t('settings_billing.licences_info_used_player_account_count', {
            defaultValue: 'Email/Player account: %{used_licences}',
            used_licences: usedPlayerAccountSeats,
          })}
        </p>
      </div>
      <p className='bold'>
        {t('settings_billing.licences_info_remaining_licences_count', {
          defaultValue: 'Licences available: %{remaining_licences}',
          remaining_licences: remainingUnusedSeats,
        })}
      </p>
      {nextDateWhenLicencesFreed && (
        <p>
          {t('settings_billing.licences_info_next_date_when_licences_freed', {
            defaultValue: 'Next date when licences will be freed: %{next_date_when_licences_freed}',
            next_date_when_licences_freed: nextDateWhenLicencesFreed,
          })}
        </p>
      )}
    </div>
  )
}
