import classNames from 'classnames'
import { PointsPill } from '../../../../../../../../../common/components/PointsPill/PointsPill'
import { CloseButton } from '../../../../../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../../../../../common/components/icons/utils'
import { PlayerSummary } from '../../../../types'
import { PlayerSummaryBadges } from '../../../Player/PlayerSummaryBadges'
import { getInitials } from '../../../Player/helpers'
import styles from './EvaluationHeaderPlayer.module.css'

type EvaluationListHeaderPlayerProps = {
  playerSummary: PlayerSummary
  noPointsGame?: boolean
  onClose: () => void
  tasksCount: number
  badgesEnabled: boolean
}

export const EvaluationHeaderPlayer: React.FC<EvaluationListHeaderPlayerProps> = ({
  playerSummary,
  noPointsGame,
  onClose,
  tasksCount,
  badgesEnabled,
}) => {
  return (
    <div className={styles.playerTasksHeader}>
      <span className={styles.playerInitials}>{getInitials(playerSummary.player.nickName)}</span>
      <div className={styles.playerNameAndDetails}>
        <span className={classNames('medium bold', styles.playerName)}>{playerSummary.player.nickName}</span>
        <div className={styles.playerDetails}>
          {!noPointsGame && <PointsPill noIcon size='small' label={playerSummary.totalScore.toString()} />}
          <span className={styles.pinIcon}>{getIcon('exercisePin')}</span>
          <span className='tiny bold'>
            {playerSummary.tasksAnswered}/{tasksCount}
          </span>
          {badgesEnabled && <PlayerSummaryBadges badges={playerSummary.player.badges} className={styles.badges} />}
        </div>
      </div>
      <CloseButton className={styles.closeButton} onClick={onClose} autoFocus />
    </div>
  )
}
