export const setItem = <T>(key: string, value: T) => {
  const serialized = JSON.stringify(value)
  window.localStorage.setItem(key, serialized)
}

export const getItem = <T>(key: string): T | null => {
  const stored = window.localStorage.getItem(key)
  return stored != null ? (JSON.parse(stored) as T) : null
}

export const deleteItem = (key: string) => {
  window.localStorage.removeItem(key)
}
