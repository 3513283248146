import React from 'react'
import { CONFIRMATION_DIALOG_ID_PREFIX } from '../composites/confirmationDialog/constants'

type FocusableElement = HTMLLinkElement | HTMLButtonElement | HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement

const FOCUSABLE_SELECTORS = [
  'a[href]',
  'button:not([disabled])',
  'textarea:not([disabled])',
  'input:not([disabled])',
  'select:not([disabled])',
]

const NAV_KEYS = ['Tab', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp']

export const useTrapFocus = <T extends HTMLElement>(isActive: boolean = true): React.RefObject<T> => {
  const focusContainerRef = React.useRef<T>(null)

  React.useEffect(() => {
    if (!isActive || focusContainerRef.current == null) {
      return
    }

    const keyDownHandler = (e: KeyboardEvent) => {
      if (!isActive || focusContainerRef.current == null || !NAV_KEYS.includes(e.key)) {
        return
      }

      const focusableElements = focusContainerRef.current.querySelectorAll(FOCUSABLE_SELECTORS.join(', '))
      const firstElement = focusableElements[0] as FocusableElement
      const lastElement = focusableElements[focusableElements.length - 1] as FocusableElement

      if (
        e.target &&
        !focusContainerRef.current.contains(e.target as any) &&
        !(e.target as any)?.id?.startsWith?.(CONFIRMATION_DIALOG_ID_PREFIX)
      ) {
        firstElement.focus()
        e.preventDefault()
      } else if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus()
        e.preventDefault()
      } else if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus()
        e.preventDefault()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [isActive])

  return focusContainerRef
}
