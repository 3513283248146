import classNames from 'classnames'
import sharedStyles from '../../Settings.module.css'
import { getIcon } from '../../../../common/components/icons/utils'
import styles from './OrganizationInfo.module.css'
import { useTranslation } from 'react-i18next'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { NewBusinessInfo } from '../../../../types/commonTypes'

type OrganizationInfoProps = {
  name: string
  membersCount?: number
  licenseValidUntilDate?: string
  maxMembersCount: number | null
  businessInfo: NewBusinessInfo | null
}

export const OrganizationInfo: React.FC<OrganizationInfoProps> = ({
  name,
  membersCount,
  licenseValidUntilDate,
  maxMembersCount,
  businessInfo,
}) => {
  const { t } = useTranslation()
  const playerLicenseCount = businessInfo?.licenceCount
  const usedPlayerLicenseCount = (businessInfo?.usedPinSeats || 0) + (businessInfo?.usedPlayerAccountSeats || 0)

  return (
    <div className={classNames(sharedStyles.sectionCardContainer, styles.container)}>
      <div className={styles.organizationIconContainer}>
        <span>{getIcon('organizationGradient')}</span>
      </div>
      <div className={styles.orgInfoList}>
        <h1>{name}</h1>
        {membersCount != null && (
          <span className='bold normal'>
            {!maxMembersCount
              ? t('settings_organization.org_info.members_count', {
                  count: membersCount,
                  defaultValue: '%{count} members',
                })
              : t('settings_organization.org_info.members_count_with_max', {
                  members_count: membersCount,
                  max_count: maxMembersCount,
                  defaultValue: '%{members_count}/%{max_count} members',
                })}
          </span>
        )}
        {playerLicenseCount && (
          <span className='bold normal'>
            {t('settings_organization.org_info.players_count', {
              players_count: usedPlayerLicenseCount,
              max_count: playerLicenseCount,
              defaultValue: '%{players_count}/%{max_count} players',
            })}
          </span>
        )}
        {!safeIsNullOrEmpty(licenseValidUntilDate) && (
          <span className='normal'>
            {t('settings_organization.org_info.license_valid_until', {
              next_invoice: licenseValidUntilDate,
              defaultValue: 'License valid until: %{next_invoice}',
            })}
          </span>
        )}
      </div>
    </div>
  )
}
