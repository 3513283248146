import React from 'react'

import styles from './PersonChatCard.module.css'
import { getInitialsFromName, newMessagesCount } from '../../helpers'
import { useChat } from '../../../../../../contexts/ChatContextProvider'

type PersonChatCardProps = {
  personId: number
  name: string
  onClickCard: (personId: number, name: string | number) => void
}

export const PersonChatCard: React.FC<PersonChatCardProps> = ({ name, personId, onClickCard }) => {
  const { chatRooms } = useChat()

  const onClickCardInternal = () => {
    onClickCard(personId, name)
  }

  return (
    <li className={styles.container} onClick={onClickCardInternal}>
      {chatRooms[personId] != null && chatRooms[personId].hasUnreadMessages && (
        <>
          <div className={styles.newMessageBadge} />
          <span className={styles.newMessagesCount}>({newMessagesCount(chatRooms[personId])})</span>
        </>
      )}
      <div className={styles.initialsContainer}>{getInitialsFromName(name + '').toUpperCase()}</div>
      <p className={styles.nameContainer}>{name}</p>
    </li>
  )
}
