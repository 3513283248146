import classNames from 'classnames'
import { ArrowRightIcon } from '../../../../../../common/components/icons'
import { PlayerAccount, Tag } from '../../../../../../types/commonTypes'
import styles from './PlayerAccountModalListLines.module.css'
import { getIcon } from '../../../../../../common/components/icons/utils'
import { boldSubstrings } from '../../../../../../util/string'
import { Tooltip } from '../../../../../../common/components/tooltip/Tooltip'
import { useTranslation } from 'react-i18next'

type ListLineBase = {
  isSelected?: boolean
  searchTerm?: string
}

type ListLineTagProps = ListLineBase & {
  tag: Tag
  isCenterColumn: boolean
  onClick: (tag: Tag) => void
  onSelect: (tag: Tag, toSelected: boolean) => void
  onShowAll: (tag: Tag, isCenterColumn: boolean) => void
}

type ListLineActiveTagProps = ListLineBase & {
  tag: Tag
  onSelect: (tag: Tag, toSelected: boolean) => void
  onDelete: (tag: Tag) => void
}

type ListLinePlayerAccountProps = ListLineBase & {
  showSecondLine?: boolean
  playerAccount: PlayerAccount
  onSelect?: (playerAccount: PlayerAccount, toSelected: boolean) => void
}

const CbElement: React.FC<{
  isSelected: boolean
  data: any
  onSelect: (data: any, isSelected: boolean) => void
}> = ({ isSelected, data, onSelect }) => {
  return (
    <div className={styles.ListLineCheckboxContainer}>
      <input
        className={styles.checkbox}
        checked={isSelected}
        type='checkbox'
        onChange={(evt: any) => onSelect(data, evt.nativeEvent.target.checked)}
      />
    </div>
  )
}

export const HighlightedLabel: React.FC<{ label: string; searchTerm?: string }> = ({ label, searchTerm }) => {
  return <div dangerouslySetInnerHTML={{ __html: searchTerm ? boldSubstrings(label, searchTerm) : label }} />
}

export const ListLineTag: React.FC<ListLineTagProps> = ({
  tag,
  isCenterColumn,
  isSelected = false,
  searchTerm,
  onClick,
  onSelect,
  onShowAll,
}) => {
  const { t } = useTranslation()
  return (
    <div className={styles.listLine}>
      <CbElement isSelected={isSelected} data={tag} onSelect={onSelect} />
      <div
        onClick={() => onClick(tag)}
        className={classNames(styles.listContentRow, isCenterColumn ? styles.clickableTag : styles.baseTag)}
      >
        <HighlightedLabel label={tag.label} searchTerm={searchTerm} />
        {tag.playerCount && (
          <Tooltip
            tooltipContent={t(
              'game_editor.add_people.player_account_modal.tag_number_info_tooltip',
              '%{players_count} player accounts',
              { players_count: tag.playerCount },
            )}
          >
            {(tooltipProps) => <span {...tooltipProps}>{` (${tag.playerCount})`}</span>}
          </Tooltip>
        )}
        {isCenterColumn && <span style={{ marginLeft: 'auto', cursor: 'pointer' }}>{getIcon('plus')}</span>}
      </div>
      {tag.playerCount && (
        <div onClick={() => onShowAll(tag, isCenterColumn)} className={styles.expandTagChevronContainer}>
          <span className={classNames(styles.chevronWrapper, styles.chevronOpen)}>
            <ArrowRightIcon color={'currentColor'} />
          </span>
        </div>
      )}
    </div>
  )
}

export const ListLineActiveTag: React.FC<ListLineActiveTagProps> = ({
  tag,
  isSelected = false,
  searchTerm,
  onSelect,
  onDelete,
}) => {
  return (
    <div className={styles.listLine}>
      <CbElement isSelected={isSelected} data={tag} onSelect={onSelect} />
      <div className={classNames(styles.listContentRow, styles.activeTag)}>
        <HighlightedLabel label={tag.label} searchTerm={searchTerm} />
        <span onClick={() => onDelete(tag)}>{getIcon('close')}</span>
      </div>
    </div>
  )
}

export const ListLinePlayerAccount: React.FC<ListLinePlayerAccountProps> = ({
  playerAccount,
  showSecondLine,
  isSelected = false,
  searchTerm,
  onSelect,
}) => {
  return (
    <div className={styles.listLine}>
      {onSelect && <CbElement isSelected={isSelected} data={playerAccount} onSelect={onSelect} />}
      <div className={classNames(styles.listContentColumn, styles.resultPlayerAccount)}>
        <HighlightedLabel label={playerAccount.nickName} searchTerm={searchTerm} />
        {showSecondLine && (
          <div className={styles.listContentSecondLine}>
            <span>{getIcon('email')}</span>
            <div title={playerAccount.email}>{playerAccount.email}</div>
          </div>
        )}
      </div>
    </div>
  )
}
