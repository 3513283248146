import { useRef, useState } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { uploadMedia } from '../../../../../../../api/fileUploadApiService'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../../../common/components/PopupMenu/PopupActionMenu'
import { Button } from '../../../../../../../common/components/button/Button'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { useNotification } from '../../../../../../../contexts/NotificationContext'
import { safeIsNullOrEmpty } from '../../../../../../../util/string'
import styles from './AnswerImage.module.css'

export type AnswerImageProps = {
  name: string
  viewOnly: boolean
}

export const AnswerImage: React.FC<AnswerImageProps> = ({ name, viewOnly }) => {
  const { t } = useTranslation()
  const { notifyError } = useNotification()

  const { input: imageUrlInput } = useField(`${name}.imageUrl`)

  const [loading, setLoading] = useState<boolean>(false)

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleAddImageClick = () => hiddenFileInput.current?.click()

  const handleDeleteImageClick = () => {
    imageUrlInput.onChange(undefined)
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.value = ''
    }
  }

  const handleFileInputChange = async () => {
    if (hiddenFileInput.current?.files?.[0] == null) {
      return
    }
    setLoading(true)
    try {
      const inputFile = hiddenFileInput.current.files[0]
      const uniqueFileName = `${uuid()}-${inputFile.name}`
      const result = await uploadMedia({ fileName: uniqueFileName, inputFile })
      if (!result.success) {
        throw result.error
      }
      imageUrlInput.onChange(result.value.url)
    } catch (error) {
      console.error(error)
      notifyError({
        title: t('game_editor.tasks.answer_options.image_upload_failed_notification.title', 'Failed to add image'),
        content: t(
          'game_editor.tasks.answer_options.image_upload_failed_notification.content',
          'An error occurred while uploading the image. Please try again or contact us for support.',
        ),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!safeIsNullOrEmpty(imageUrlInput.value) ? (
        <div className={styles.imagePreview} style={{ backgroundImage: `url('${imageUrlInput.value}')` }}>
          <div className={styles.imagePreviewOverlay}>
            {!viewOnly && (
              <PopupActionMenu id={`${name}.actions_menu`} direction='right' buttonClassName={styles.moreMenuButton}>
                <PopupActionMenuItem
                  onClick={handleAddImageClick}
                  icon='photo'
                  text={t('game_editor.tasks.answer_options.image_change_button', 'Change')}
                />
                <PopupActionMenuItem
                  onClick={handleDeleteImageClick}
                  icon='trash'
                  text={t('game_editor.tasks.answer_options.image_delete_button', 'Delete')}
                />
              </PopupActionMenu>
            )}
          </div>
        </div>
      ) : viewOnly ? null : (
        <Button variant='outline-normal' onClick={handleAddImageClick}>
          {getIcon('photo')}
          {loading
            ? t('common.loading', 'Loading...')
            : t('game_editor.tasks.answer_options.image_add_button', 'Add image')}
        </Button>
      )}
      <input
        type='file'
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        accept='image/*'
      />
    </>
  )
}
