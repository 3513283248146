import * as React from 'react'
import { SVGProps } from 'react'
const SvgOrgLibraryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 25 29' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.38 23.115H3.52V8.025h18.11v5.67c.54.12 1.053.344 1.51.66v-6.33h.76a.75.75 0 0 0 .75-.76.74.74 0 0 0-.75-.75h-.92l-1.08-4.33a2.29 2.29 0 0 0-.81-1.24 2.22 2.22 0 0 0-1.39-.47H5.45a2.22 2.22 0 0 0-1.39.47 2.29 2.29 0 0 0-.81 1.24l-1.1 4.33h-.9a.74.74 0 0 0-.75.75.75.75 0 0 0 .75.76h.76v15.85a.74.74 0 0 0 .75.75h6.81a4.39 4.39 0 0 1-1.19-1.51ZM4.72 2.555a.74.74 0 0 1 .73-.57H19.7a.74.74 0 0 1 .73.57l1 4H3.73l.99-4Zm15.43 7.73v1.51a.741.741 0 0 1-.75.75.75.75 0 0 1-.76-.75v-1.51a.75.75 0 0 1 .76-.75.74.74 0 0 1 .75.75Zm-.709 4.81a3.15 3.15 0 0 1 1.208-.24l-.04.03a3.16 3.16 0 1 1-2.52 5.05l-2.64 1c.05.301.05.609 0 .91l2.91 1.43a3.15 3.15 0 1 1-.81 2.12c-.016-.2-.016-.4 0-.6l-2.87-1.47a3.15 3.15 0 1 1 .19-4l2.63-1v-.31a3.15 3.15 0 0 1 1.942-2.92Zm0 2.105a1.46 1.46 0 0 0-.242.815 1.45 1.45 0 0 0 1.45 1.45 1.46 1.46 0 1 0-1.208-2.265Zm-8.31 3.231a1.46 1.46 0 1 0 2.436 1.607 1.46 1.46 0 0 0-2.437-1.607Zm8.31 4.15a1.46 1.46 0 0 0-.242.814 1.45 1.45 0 0 0 1.45 1.45 1.46 1.46 0 1 0-1.208-2.265Zm-5.352-8.256a.75.75 0 1 0 1.5 0v-1.51a.75.75 0 0 0-1.5 0v1.51Zm-4.476.289a.74.74 0 0 1-.054-.289v-1.51a.75.75 0 0 1 1.04-.702.76.76 0 0 1 .47.702v1.51a.75.75 0 0 1-.76.75.741.741 0 0 1-.696-.461Zm.696-4.069a.75.75 0 0 0 .76-.75v-1.51a.75.75 0 0 0-.76-.75.74.74 0 0 0-.75.75v1.51a.74.74 0 0 0 .75.75Zm4.242-2.955a.74.74 0 0 1 .288-.055.74.74 0 0 1 .75.75v1.51a.75.75 0 1 1-1.5 0v-1.51a.74.74 0 0 1 .461-.695Zm-8.772 8.995a.75.75 0 0 0-.75.76v1.51a.74.74 0 0 0 .75.75.75.75 0 0 0 .76-.75v-1.51a.76.76 0 0 0-.76-.76Zm.702-8.59a.75.75 0 0 1 .058.29v1.51a.75.75 0 0 1-.76.75.74.74 0 0 1-.75-.75v-1.51a.74.74 0 0 1 .75-.75.75.75 0 0 1 .702.46Zm0 4.53a.76.76 0 0 1 .058.29v1.51a.75.75 0 0 1-.76.75.741.741 0 0 1-.75-.75v-1.51a.75.75 0 0 1 1.04-.702.76.76 0 0 1 .412.411Z'
      fill={props.color ?? '#6F6F6F'}
    />
  </svg>
)
export default SvgOrgLibraryIcon
