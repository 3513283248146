import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfirmation } from '../../../../../contexts/ConfirmationContext'
import { AnswerEvaluation } from '../../../../../contexts/GameContextHelper'
import { EditorPermissions, Evaluation, Game, Player, ReceivedAnswer, Task } from '../../../../../types/commonTypes'
import { getPlayerCardFocusableElementId, getTaskCardFocusableElementId } from '../../../helpers'
import { AwardBadgePanel } from './components/AwardBadgePanel/AwardBadgePanel'
import { PlayersEvaluationPanel } from './components/EvaluationListPanel/PlayersEvaluationPanel'
import { TasksEvaluationPanel } from './components/EvaluationListPanel/TasksEvaluationPanel'
import { GradeAnswersPanel } from './components/GradeAnswersPanel/GradeAnswersPanel'
import { ManageGamePanel } from './components/ManageGamePanel/ManageGamePanel'
import { getEvaluationsForPlayer, getEvaluationsForTask, getPlayerSummaries, getTaskSummaries } from './helpers'
import {
  AWARD_BADGE_BUTTON_ID,
  DEFAULT_EVAL_SORT_BY_PLAYERS,
  DEFAULT_EVAL_SORT_BY_TASKS,
  EvaluationSortBy,
  PlayTab,
  PlayerSummary,
  TaskSummary,
} from './types'

type PlayProps = {
  tasks: Task[]
  players: Player[]
  game: Game
  permissions: EditorPermissions
  onTriggerAnswerRefresh: (answer: ReceivedAnswer) => void
  onGradeAnswer: (gameId: number, answerEvaluation: AnswerEvaluation[]) => Promise<string>
  onAskForRevisedAnswer: (
    gameId: number,
    answerEvaluation: AnswerEvaluation[],
    moreTimeSeconds: number,
  ) => Promise<string>
}

export const Play: React.FC<PlayProps> = ({
  tasks,
  game,
  players,
  permissions,
  onTriggerAnswerRefresh,
  onGradeAnswer,
  onAskForRevisedAnswer,
}) => {
  const { t } = useTranslation()
  const { requestConfirmation } = useConfirmation()

  const [collapseGradedAnswers, setCollapseGradedAnswers] = useState<boolean>(false)
  const toggleCollapseGradedAnswers = () => setCollapseGradedAnswers((prev) => !prev)

  const [playTab, setPlayTab] = useState<PlayTab>(PlayTab.TASKS)
  const playTabRef = useRef<PlayTab>(PlayTab.TASKS)
  const handleSetPlayTab = useCallback((tab: PlayTab) => {
    setPlayTab(tab)
    playTabRef.current = tab
  }, [])

  const [isCompact, setIsCompact] = useState<boolean>(false)

  const [evaluationSortBy, setEvaluationSortBy] = useState<EvaluationSortBy>(DEFAULT_EVAL_SORT_BY_TASKS)

  const [taskSummaries, setTaskSummaries] = useState<TaskSummary[]>([])
  const [playerSummaries, setPlayerSummaries] = useState<PlayerSummary[]>([])

  useEffect(() => {
    setTaskSummaries(getTaskSummaries(tasks))
  }, [tasks])

  useEffect(() => {
    setPlayerSummaries(getPlayerSummaries(players, tasks))
  }, [players, tasks])

  const [selectedTaskId, setSelectedTaskId] = useState<number>()
  const [selectedPlayerId, setSelectedPlayerId] = useState<number>()

  const [selectedTaskSummary, setSelectedTaskSummary] = useState<TaskSummary>()
  const [selectedPlayerSummary, setSelectedPlayerSummary] = useState<PlayerSummary>()

  const [evaluations, setEvaluations] = useState<Evaluation[]>([])
  const [selectedEvaluation, setSelectedEvaluation] = useState<Evaluation>()

  const [awardBadgePlayerId, setAwardBadgePlayerId] = useState<number>()
  const [awardBadgePlayerSummary, setAwardBadgePlayerSummary] = useState<PlayerSummary>()

  useEffect(() => {
    setAwardBadgePlayerSummary(() => {
      if (awardBadgePlayerId == null) {
        return undefined
      }
      return playerSummaries.find((p) => p.player.id === awardBadgePlayerId)
    })
  }, [awardBadgePlayerId, playerSummaries])

  const taskCardSelector = useRef<string>()
  const playerCardSelector = useRef<string>()
  const awardBadgeSelector = useRef<string>()

  const handleClickTask = useCallback((id: number) => {
    setSelectedTaskId(id)
    if (playTabRef.current === PlayTab.TASKS) {
      taskCardSelector.current = `#${getTaskCardFocusableElementId(id)}`
      setSelectedPlayerId(undefined)
    } else {
      setAwardBadgePlayerId(undefined)
    }
  }, [])

  const handleClickPlayer = useCallback((id: number) => {
    setSelectedPlayerId(id)
    if (playTabRef.current === PlayTab.PLAYERS) {
      setAwardBadgePlayerId(undefined)
      setSelectedTaskId(undefined)
    } else {
      playerCardSelector.current = `#${getPlayerCardFocusableElementId(id)}`
    }
  }, [])

  useEffect(() => {
    if (playTabRef.current === PlayTab.TASKS) {
      const currTaskSummary = taskSummaries.find((taskSummary) => taskSummary.task.id === selectedTaskId)
      setSelectedTaskSummary(currTaskSummary)
      setEvaluations(currTaskSummary == null ? [] : getEvaluationsForTask(currTaskSummary.task, players))
    }
  }, [selectedTaskId, taskSummaries, players])

  useEffect(() => {
    if (playTabRef.current === PlayTab.TASKS) {
      setSelectedEvaluation((prev) => {
        if (selectedPlayerId != null) {
          const foundEvaluation = evaluations.find((playerEval) => playerEval.player.id === selectedPlayerId)
          const parentAnswer = foundEvaluation?.answers[0].answer
          if (parentAnswer?.hasMorePreviousAnswers && !parentAnswer.previousAnswers.length)
            onTriggerAnswerRefresh(parentAnswer)
          return foundEvaluation
        } else if (selectedPlayerId == null && prev != null) {
          return undefined
        }
        return prev
      })
    }
  }, [selectedPlayerId, evaluations, onTriggerAnswerRefresh])

  useEffect(() => {
    if (playTabRef.current === PlayTab.PLAYERS) {
      const currPlayerSummary = playerSummaries.find(({ player }) => player.id === selectedPlayerId)
      setSelectedPlayerSummary(currPlayerSummary)
      setEvaluations(currPlayerSummary == null ? [] : getEvaluationsForPlayer(currPlayerSummary.player, tasks))
    }
  }, [selectedPlayerId, playerSummaries, tasks])

  useEffect(() => {
    if (playTabRef.current === PlayTab.PLAYERS) {
      setSelectedEvaluation((prev) => {
        if (selectedTaskId != null) {
          const foundEvaluation = evaluations.find((playerEval) => playerEval.task.id === selectedTaskId)
          const parentAnswer = foundEvaluation?.answers[0].answer
          if (parentAnswer?.hasMorePreviousAnswers && !parentAnswer.previousAnswers.length) {
            onTriggerAnswerRefresh(parentAnswer)
          }
          return foundEvaluation
        } else if (selectedTaskId == null && prev != null) {
          return undefined
        }
        return prev
      })
    }
  }, [selectedTaskId, evaluations, onTriggerAnswerRefresh])

  const closeGradeAnswerPanel = useCallback(() => {
    if (playTabRef.current === PlayTab.TASKS) {
      setSelectedPlayerId(undefined)
      if (playerCardSelector.current) {
        document.querySelector<HTMLLIElement>(playerCardSelector.current)?.focus?.()
        playerCardSelector.current = undefined
      }
    } else {
      setSelectedTaskId(undefined)
      if (taskCardSelector.current) {
        document.querySelector<HTMLLIElement>(taskCardSelector.current)?.focus?.()
        taskCardSelector.current = undefined
      }
    }
  }, [])

  const onCloseGradeAnswerPanel = useCallback(
    (hasUnsavedChanges?: boolean) => {
      if (hasUnsavedChanges) {
        requestConfirmation({
          title: t(
            'game_editor.sidebar.evaluate_answer_panel.close_confirmation_title',
            'Are you sure you want to exit?',
          ),
          text: t(
            'game_editor.sidebar.evaluate_answer_panel.close_confirmation_text',
            'Any unsaved changes will be lost',
          ),
        }).then((response) => {
          if (response) {
            closeGradeAnswerPanel()
          }
        })
      } else {
        closeGradeAnswerPanel()
      }
    },
    [closeGradeAnswerPanel, requestConfirmation, t],
  )

  const onCloseEvaluationList = useCallback(() => {
    if (playTabRef.current === PlayTab.TASKS) {
      setSelectedTaskId(undefined)
      setSelectedTaskSummary(undefined)
      if (taskCardSelector.current) {
        document.querySelector<HTMLLIElement>(taskCardSelector.current)?.focus?.()
        taskCardSelector.current = undefined
      }
    } else {
      setSelectedPlayerId(undefined)
      setSelectedPlayerSummary(undefined)
      if (playerCardSelector.current) {
        document.querySelector<HTMLLIElement>(playerCardSelector.current)?.focus?.()
        playerCardSelector.current = undefined
      }
    }
  }, [])

  const onClickAwardBadgeFromFirstPanel = useCallback((playerId: number) => {
    awardBadgeSelector.current = `#${getPlayerCardFocusableElementId(playerId)}`
    setSelectedPlayerId(undefined)
    setAwardBadgePlayerId(playerId)
  }, [])

  const onClickAwardBadgeFromMiddlePanel = useCallback((playerId: number) => {
    awardBadgeSelector.current = `#${AWARD_BADGE_BUTTON_ID}`
    setSelectedTaskId(undefined)
    setAwardBadgePlayerId(playerId)
  }, [])

  const closeAwardBadge = useCallback(() => {
    setAwardBadgePlayerId(undefined)
    if (awardBadgeSelector.current) {
      document.querySelector<HTMLLIElement | HTMLButtonElement>(awardBadgeSelector.current)?.focus?.()
      awardBadgeSelector.current = undefined
    }
  }, [])

  useEffect(() => {
    setSelectedEvaluation(undefined)
    setSelectedPlayerSummary(undefined)
    setSelectedTaskSummary(undefined)
    setSelectedPlayerId(undefined)
    setSelectedTaskId(undefined)
    setAwardBadgePlayerId(undefined)
    setEvaluationSortBy(() => {
      return playTabRef.current === PlayTab.TASKS ? DEFAULT_EVAL_SORT_BY_TASKS : DEFAULT_EVAL_SORT_BY_PLAYERS
    })
  }, [playTab])

  return (
    <>
      <ManageGamePanel
        game={game}
        onChangePlayTab={handleSetPlayTab}
        onClickTask={handleClickTask}
        onSelectPlayer={handleClickPlayer}
        playerSummaries={playerSummaries}
        playTab={playTab}
        selectedTaskId={selectedTaskId}
        selectedPlayerId={selectedPlayerId ?? awardBadgePlayerId}
        taskSummaries={taskSummaries}
        isCompact={isCompact}
        setIsCompact={setIsCompact}
        onClickAwardBadge={onClickAwardBadgeFromFirstPanel}
        flashDisabled={!permissions.actions.flashTasks}
      />
      {selectedTaskSummary != null && (
        <TasksEvaluationPanel
          evaluations={evaluations}
          game={game}
          isCompact={isCompact}
          onClose={onCloseEvaluationList}
          onSelectPlayer={handleClickPlayer}
          onSetSortBy={setEvaluationSortBy}
          selectedPlayerId={selectedPlayerId}
          sortBy={evaluationSortBy}
          task={selectedTaskSummary.task}
        />
      )}
      {selectedPlayerSummary != null && (
        <PlayersEvaluationPanel
          evaluations={evaluations}
          game={game}
          isCompact={isCompact}
          onClose={onCloseEvaluationList}
          onSelectTask={handleClickTask}
          onSetSortBy={setEvaluationSortBy}
          playerSummary={selectedPlayerSummary}
          selectedTaskId={selectedTaskId}
          sortBy={evaluationSortBy}
          awardingBadge={awardBadgePlayerSummary != null}
          onClickAwardBadge={onClickAwardBadgeFromMiddlePanel}
        />
      )}
      {awardBadgePlayerSummary != null && (
        <AwardBadgePanel
          badges={game.badges}
          onClose={closeAwardBadge}
          playerSummary={awardBadgePlayerSummary}
          tasksCount={game.tasks.length}
          noPointsGame={game.advancedSettings.noPointsGame}
        />
      )}
      {selectedEvaluation != null && (
        <GradeAnswersPanel
          key={`grade_answers_panel_${selectedEvaluation.player.id}_${selectedEvaluation.task.id}`}
          playTab={playTab}
          evaluation={selectedEvaluation}
          game={game}
          collapseGradedAnswers={collapseGradedAnswers}
          onToggleCollapseGradedAnswers={toggleCollapseGradedAnswers}
          onAskForRevisedAnswer={onAskForRevisedAnswer}
          onGradeAnswer={onGradeAnswer}
          onClose={onCloseGradeAnswerPanel}
        />
      )}
    </>
  )
}
