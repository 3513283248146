import classNames from 'classnames'
import styles from './PointsView.module.css'
import SvgScoreCircledIcon from '../../../../../../../../common/components/icons/ScoreCircledIcon'

type PointsViewProps = {
  points: number
  active?: boolean
}

export const PointsView: React.FC<PointsViewProps> = ({ points, active }) => {
  const isActive = active ?? points !== 0
  return (
    <div className={classNames(styles.pointsView, 'tiny', isActive && styles.pointsActive)}>
      <SvgScoreCircledIcon className={styles.scoreIcon} width={14} height={14} />
      <span className='bold'>{points}</span>
    </div>
  )
}
