import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../../../common/components/button/CloseButton'
import { useGame } from '../../../../../../../contexts/GameContext'
import { useNotification } from '../../../../../../../contexts/NotificationContext'
import { Badge } from '../../../../../../../types/commonTypes'
import { noop } from '../../../../../../../util/functional'
import { BadgeList } from '../../../../Badges/BadgeList/BadgeList'
import sharedEditorPanelStyles from '../../../EditorSidebar.module.css'
import { PlayerSummary } from '../../types'
import { PlayerSummaryCard } from '../Player/PlayerSummaryCard'
import { AwardBadge } from './AwardBadge'
import styles from './AwardBadgePanel.module.css'

type AwardBadgePanelProps = {
  playerSummary: PlayerSummary
  tasksCount: number
  badges: Badge[]
  automaticallyAwardedBadgeIds?: Badge[]
  noPointsGame?: boolean
  onClose: () => void
}

export const AwardBadgePanel: React.FC<AwardBadgePanelProps> = ({
  playerSummary,
  tasksCount,
  badges,
  noPointsGame,
  onClose,
}) => {
  const { t } = useTranslation()
  const [selectedBadgeId, setSelectedBadgeId] = useState<number>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { awardBadge } = useGame()
  const { notifyWarning, notifyError } = useNotification()

  const onAward = useCallback(async () => {
    if (!selectedBadgeId) {
      notifyWarning({
        title: '',
        content: t('game_editor.sidebar.award_badge.select_badge_to_award', 'Select a badge to award'),
      })
    } else {
      setSubmitting(true)
      const response = await awardBadge(playerSummary.player.id, selectedBadgeId)
      if (!response) {
        notifyError({
          title: t('game_editor.sidebar.award_badge.failed_to_award_badge_notification_title', 'Failed to award badge'),
          content: t(
            'game_editor.sidebar.award_badge.failed_to_award_badge_notification_content',
            'An error occurred while awarding badge. Please try again or contact us for support.',
          ),
        })
      } else {
        setSelectedBadgeId(undefined)
      }
      setSubmitting(false)
    }
  }, [playerSummary, selectedBadgeId, awardBadge, t, notifyWarning, notifyError])

  const handleClickBadge = (badgeId: number) => setSelectedBadgeId((prev) => (badgeId === prev ? undefined : badgeId))

  const unawardedBadges = useMemo(() => {
    return badges.filter((b) => !playerSummary.player.badges.some((otherB) => otherB.id === b.id))
  }, [badges, playerSummary.player.badges])

  return (
    <div className={classNames(sharedEditorPanelStyles.editorSidebarPanelContainer, styles.awardBadgePanel)}>
      <div className={styles.awardBadgeHeader}>
        <h1>{t('game_editor.sidebar.award_badge.award_to_player_panel_title', 'Award badge')}</h1>
        <CloseButton
          onClick={onClose}
          aria-label={t(
            'game_editor.sidebar.award_badge.award_to_player_panel_close_button_sr_label',
            'Close award badge',
          )}
          disabled={submitting}
          autoFocus
        />
      </div>
      <div className={styles.awardBadgeMain}>
        <PlayerSummaryCard
          key={`playerTask_${playerSummary.player.id}`}
          playerSummary={playerSummary}
          tasksTotal={tasksCount}
          onClick={noop}
          isCompact={false}
          isSelected
          badgesEnabled
          noPointsGame={noPointsGame}
        />
        <h2 className={styles.playerBadgesTitle}>
          {t('game_editor.sidebar.award_badge.players_current_badges', {
            defaultValue: "Player's current badges (%{badges_count})",
            badges_count: playerSummary.player.badges.length,
          })}
        </h2>
        <BadgeList badges={playerSummary.player.badges} />
        <AwardBadge
          badges={unawardedBadges}
          selectedBadgeId={selectedBadgeId}
          setSelectedBadgeId={handleClickBadge}
          title={t('game_editor.sidebar.award_badge.award_new_badge', 'Award a new badge')}
        />
        {unawardedBadges.length > 0 && (
          <Button onClick={onAward} className={styles.centredButton} variant='tiny' disabled={submitting}>
            {t('game_editor.sidebar.award_badge.award_button', 'Award')}
          </Button>
        )}
        <button className={classNames(styles.textButton, styles.centredButton)} disabled={submitting} onClick={onClose}>
          {t('common.cancel', 'Cancel')}
        </button>
      </div>
    </div>
  )
}
