import React from 'react'
import { Outlet } from 'react-router-dom'
import { HelpMenu } from '../composites/HelpMenu/HelpMenu'
import { GlobalNotification } from '../composites/layout/GlobalNotification'
import { PageWithTopbar } from '../composites/layout/PageWithTopbar'
import { Sidebar } from '../composites/sidebar/Sidebar'
import styles from './RootLayout.module.css'

export const RootLayout: React.FC<{}> = () => {
  return (
    <div>
      <GlobalNotification />
      <div className={styles.container}>
        <Sidebar />
        <PageWithTopbar>
          <Outlet />
        </PageWithTopbar>
        <HelpMenu />
      </div>
    </div>
  )
}
