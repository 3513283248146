import * as React from 'react'
import { SVGProps } from 'react'

const SvgPinFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <path
      d='m15.597 28 6.75-6.75c3.685-3.685 3.685-9.716-.048-13.45A9.534 9.534 0 0 0 8.8 7.8a9.534 9.534 0 0 0 0 13.499L15.597 28Zm-2.92-16.323a4.074 4.074 0 0 1 5.744 0 4.074 4.074 0 0 1 0 5.744c-.143.144-.287.288-.478.383 0-.574-.287-1.148-.718-1.58-.91-.909-2.441-.909-3.35 0-.432.432-.671 1.054-.719 1.58-.144-.143-.335-.239-.479-.383a4.074 4.074 0 0 1 0-5.744Z'
      fill={props.color ?? '#6F6F6F'}
    />
  </svg>
)
export default SvgPinFilledIcon
