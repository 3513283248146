import { useCallback, useState } from 'react'
import { getItem, setItem } from '../util/storage'
import { safeIsNullOrEmpty } from '../util/string'

const getInitialState = <T>(key: string, initialValue: T = null as T): T | null => {
  try {
    const stored = getItem<T>(key)
    return safeIsNullOrEmpty(stored) ? (initialValue as T) : (stored as T)
  } catch (err) {
    console.warn(err)
    return null
  }
}

export const useStorage = <T>(
  key: string,
  initialValue?: T,
): { storedValue: T | null; storeValue: (value: T) => void } => {
  const [storedValue, setStoredValue] = useState<T | null>(getInitialState(key, initialValue))

  const storeValue = useCallback(
    (value: T) => {
      setItem<T>(key, value)
      setStoredValue(value)
    },
    [key],
  )

  return { storedValue, storeValue }
}
