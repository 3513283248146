import { useTranslation } from 'react-i18next'
import sharedStyles from '../../../Settings.module.css'
import { Select } from '../../../../../common/components/Select/Select'
import { LANGUAGE_SELECT_OPTIONS } from '../../../../../common/constants'
import { LANGUAGE_SELECT_DEFAULT_OPTION } from '../../../../../common/constants'
import styles from './GeneralSection.module.css'

type GeneralSectionProps = {
  userLanguage: string
  onLanguageChange: (language?: string) => void
}

export const GeneralSection: React.FC<GeneralSectionProps> = ({ userLanguage, onLanguageChange }) => {
  const { t } = useTranslation()

  const language =
    LANGUAGE_SELECT_OPTIONS.find((option) => option.value === userLanguage) ?? LANGUAGE_SELECT_DEFAULT_OPTION

  return (
    <div className={sharedStyles.section}>
      <h1 className={sharedStyles.sectionTitle}>{t('settings_general.general.title', 'General Settings')}</h1>
      <div className={sharedStyles.sectionCardContainer}>
        <div className={styles.languageSelectContainer}>
          <label>{t('settings_general.general.language.label', 'Language:')}</label>
          <Select
            options={LANGUAGE_SELECT_OPTIONS}
            value={language}
            onChange={(option) => onLanguageChange(option?.value)}
            className={styles.languageSelect}
          />
        </div>
      </div>
    </div>
  )
}
