import * as React from 'react'
import { SVGProps } from 'react'

const SvgSearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.887.258C5.216.258.619 4.855.619 10.526s4.597 10.268 10.268 10.268c2.441 0 4.684-.852 6.446-2.275l6.269 6.269a1.094 1.094 0 1 0 1.546-1.547l-6.268-6.269a10.226 10.226 0 0 0 2.275-6.446c0-5.67-4.597-10.268-10.268-10.268ZM2.449 10.526a8.438 8.438 0 1 1 16.875 0 8.438 8.438 0 0 1-16.875 0Z'
      fill={props.color ?? '#CC3E76'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.887.258C5.216.258.619 4.855.619 10.526s4.597 10.268 10.268 10.268c2.441 0 4.684-.852 6.446-2.275l6.269 6.269a1.094 1.094 0 1 0 1.546-1.547l-6.268-6.269a10.226 10.226 0 0 0 2.275-6.446c0-5.67-4.597-10.268-10.268-10.268ZM2.449 10.526a8.438 8.438 0 1 1 16.875 0 8.438 8.438 0 0 1-16.875 0Z'
      fill={props.color ?? 'url(#filter_icon_svg__a)'}
      fillOpacity={props.color ? 1 : 0.25}
    />
    <defs>
      <linearGradient
        id='filter_icon_svg__a'
        x1={13.044}
        y1={0.258}
        x2={13.044}
        y2={25.108}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgSearchIcon
