import classNames from 'classnames'
import styles from './ThreeDots.module.css'

type ThreeDotsProps = {
  variant?: 'primary' | 'secondary'
  remSize?: number
}

export const ThreeDots: React.FC<ThreeDotsProps> = ({ variant = 'primary', remSize = 1.25 }) => {
  return (
    <div className={classNames(styles.dotLoader)}>
      <div className={styles.dotWrapper} style={{ width: `${remSize}rem`, height: `${remSize}rem` }}>
        <div
          className={styles.dotOdd}
          style={{ background: variant === 'secondary' ? 'var(--secondary-normal)' : 'var(--primary-normal)' }}
        />
      </div>
      <div className={styles.dotWrapper} style={{ width: `${remSize}rem`, height: `${remSize}rem` }}>
        <div
          className={styles.dotEven}
          style={{ background: variant === 'secondary' ? 'var(--secondary-normal)' : 'var(--primary-normal)' }}
        />
      </div>
      <div className={styles.dotWrapper} style={{ width: `${remSize}rem`, height: `${remSize}rem` }}>
        <div
          className={styles.dotOdd}
          style={{ background: variant === 'secondary' ? 'var(--secondary-normal)' : 'var(--primary-normal)' }}
        />
      </div>
    </div>
  )
}
