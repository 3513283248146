import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { useGame } from '../../../../../../../contexts/GameContext'
import { Task } from '../../../../../../../types/commonTypes'
import { TaskActionFn } from '../../types'
import { SimpleTaskCard } from '../TaskCard/SimpleTaskCard'
import { SortableTask } from '../TaskCard/SortableTask'
import styles from './TasksList.module.css'
import { useSimpleTaskOrdering } from './useSimpleTaskOrdering'

type FlashListProps = {
  tasks: Task[]
  isCompact: boolean
  noPointsGame?: boolean
  viewOnly: boolean
  onTaskAction: TaskActionFn
  onSetShowDragCancel: (show: boolean) => void
}

const tasksSort = (taskA: Task, taskB: Task): number => {
  if (taskA.flashOrder === taskB.flashOrder) {
    return taskA.id - taskB.id
  }
  return taskA.flashOrder == null ? 1 : taskB.flashOrder == null ? -1 : taskA.flashOrder - taskB.flashOrder
}

export const FlashList: React.FC<FlashListProps> = ({
  tasks,
  isCompact,
  noPointsGame,
  viewOnly,
  onTaskAction,
  onSetShowDragCancel,
}) => {
  const { updateFlashTasksOrder } = useGame()

  const {
    orderedTasks,
    onTaskActionInternal,
    isDragging,
    draggingTask,
    handleDragStart,
    handleDragCancel,
    handleDragEnd,
  } = useSimpleTaskOrdering(tasks, tasksSort, onTaskAction, updateFlashTasksOrder, onSetShowDragCancel)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <SortableContext id='flash-tasks' items={orderedTasks} disabled={viewOnly}>
        <ul className={styles.tasksList}>
          {orderedTasks.map((task, index) => {
            return (
              <SortableTask
                key={`taskCard_${task.id}`}
                task={task}
                compact={isCompact}
                onAction={onTaskActionInternal}
                noPointsGame={noPointsGame}
                index={index}
                maxIndex={orderedTasks.length - 1}
                viewOnly={viewOnly}
              />
            )
          })}
        </ul>
      </SortableContext>
      <DragOverlay>
        {isDragging && draggingTask.current ? (
          <SimpleTaskCard task={draggingTask.current} compact={isCompact} noPointsGame={noPointsGame} />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}
