import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { submitTrialFeedback } from '../../../api/userApiService'
import { Button } from '../../../common/components/button/Button'
import { CloseButton } from '../../../common/components/button/CloseButton'
import { useNotification } from '../../../contexts/NotificationContext'
import { useStorage } from '../../../hooks/useStorage'
import { HIDDEN_END_MESSAGES } from '../constants'
import { WelcomeBlockData, hideDistinctMessage, isMessageHidden } from '../helpers'
import { BannerButton } from './BannerButton'
import styles from './TrialEndBanner.module.css'

type TrialEndProps = {
  data: WelcomeBlockData
}

export const TrialEndBanner: React.FC<TrialEndProps> = ({
  data: { id, title, body, primaryButton, secondaryButton },
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { t } = useTranslation()
  const { storedValue, storeValue } = useStorage<string[] | null>(HIDDEN_END_MESSAGES, null)
  const { notifySuccess, notifyError } = useNotification()

  const handleClickClose = () => {
    hideDistinctMessage(id, storedValue, storeValue)
  }

  if (storedValue != null && isMessageHidden(id, storedValue)) {
    return null
  }

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const feedback = formData.get('feedback')

    if (feedback != null) {
      setIsSubmitting(true)
      submitTrialFeedback({ feedbackMessage: feedback as string })
        .then((res) => {
          if (res.success) {
            notifySuccess({
              title: t('trial_end.feedback_response.success.title', 'Feedback sent'),
              content: t('trial_end.feedback_response.success.content', 'Your feedback message was sent successfully'),
            })
          } else {
            notifyError({
              title: t('trial_end.feedback_response.error.title', 'Error'),
              content: t(
                'trial_end.feedback_response.error.content',
                'Failed to send feedback, please try again later or contact support.',
              ),
            })
          }
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <div className={styles.container}>
      <CloseButton
        onClick={handleClickClose}
        className={styles.closeButton}
        aria-label={t('accessibility.close_banner_button', 'Dismiss banner')}
      />

      <div className={styles.textAndActions}>
        <div className={styles.title}>{title}</div>
        <p className={classNames('normal', styles.text)}>{body}</p>
        <div className={styles.buttonsContainer}>
          {primaryButton != null && <BannerButton button={primaryButton} />}
          {secondaryButton != null && <BannerButton button={secondaryButton} isSecondary />}
        </div>
      </div>

      <div className={styles.feedbackContainer}>
        <div className={styles.text}>
          {t('trial_end.feedback', 'If you did not sign up with Seppo at this time, could you please tell us why?')}
        </div>

        <form className={styles.feedbackForm} onSubmit={handleSubmit}>
          <textarea
            required
            name='feedback'
            className={styles.feedbackInput}
            placeholder={t('trial_end.feedback_input_placeholder', 'Type here')}
          />
          <Button
            style={{
              letterSpacing: '0.06em',
              width: '6.1875rem',
            }}
            type='submit'
            disabled={isSubmitting}
          >
            {t('trial_end.send_button', 'Send')}
          </Button>
        </form>
      </div>
    </div>
  )
}
