import * as React from 'react'
import { SVGProps } from 'react'
const SvgScoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g filter='url(#score_icon_svg__a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.25 6A5.25 5.25 0 1 1 .75 6a5.25 5.25 0 0 1 10.5 0Zm-2.756-.866a.31.31 0 0 1 .273.215.309.309 0 0 1-.1.341L7.465 6.73l.362 1.548a.305.305 0 0 1-.12.326.33.33 0 0 1-.185.058.308.308 0 0 1-.162-.048L6 7.795l-1.365.82a.32.32 0 0 1-.347-.016.324.324 0 0 1-.12-.326l.362-1.548-1.202-1.04c-.095-.084-.132-.215-.095-.336a.31.31 0 0 1 .273-.215l1.586-.137.614-1.465c.1-.23.483-.23.583 0l.62 1.465 1.585.137Z'
        fill='#fff'
      />
    </g>
    <defs>
      <filter
        id='score_icon_svg__a'
        x={0.75}
        y={0.75}
        width={11.5}
        height={11.5}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dx={1} dy={1} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
        <feBlend mode='soft-light' in2='BackgroundImageFix' result='effect1_dropShadow_3952_334' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_3952_334' result='shape' />
      </filter>
    </defs>
  </svg>
)
export default SvgScoreIcon
