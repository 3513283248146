import { TFunction } from 'i18next'
import { FormErrorType } from '../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../util/string'
import { LoginFormValues } from './components/LoginForm'

export const emailStepValidate = (t: TFunction) => (values: Partial<LoginFormValues>) => {
  const errors = {} as FormErrorType<LoginFormValues>
  if (safeIsNullOrEmpty(values.email)) {
    errors.email = t('validation_errors.required', 'Required')
  }
  return errors
}

export const passwordStepValidate = (t: TFunction) => (values: Partial<LoginFormValues>) => {
  const errors = {} as FormErrorType<LoginFormValues>
  if (safeIsNullOrEmpty(values.password)) {
    errors.password = t('validation_errors.required', 'Required')
  }
  return errors
}

export const loginValidate = (t: TFunction) => (values: Partial<LoginFormValues>) => {
  const errors = {} as FormErrorType<LoginFormValues>
  if (safeIsNullOrEmpty(values.email)) {
    errors.email = t('validation_errors.required', 'Required')
  }
  if (safeIsNullOrEmpty(values.password)) {
    errors.password = t('validation_errors.required', 'Required')
  }
  return errors
}

export const guestInstructorCodeValidate = (t: TFunction) => (values: Partial<LoginFormValues>) => {
  const errors = {} as FormErrorType<LoginFormValues>
  if (safeIsNullOrEmpty(values.password)) {
    errors.password = t('validation_errors.required', 'Required')
  }
  return errors
}
