import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 14 22' {...props}>
    <path
      fill='currentColor'
      d='M12.933 19.485a1.333 1.333 0 1 1-1.886 1.886l-8.485-8.485L.676 11l1.886-1.886L11.047.63a1.333 1.333 0 1 1 1.886 1.886L4.448 11l8.485 8.485Z'
    />
    <path
      fill='url(#arrow_left_icon_svg__a)'
      fillOpacity={0.25}
      d='M12.933 19.485a1.333 1.333 0 1 1-1.886 1.886l-8.485-8.485L.676 11l1.886-1.886L11.047.63a1.333 1.333 0 1 1 1.886 1.886L4.448 11l8.485 8.485Z'
    />
    <defs>
      <linearGradient id='arrow_left_icon_svg__a' x1={13.323} x2={0.676} y1={11} y2={11} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgArrowLeftIcon
