import * as React from 'react'
import { SVGProps } from 'react'
const SvgMessageFailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#message-fail_icon_svg__a)' fill={props.color ?? '#CC3E76'}>
      <path d='M2 3a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v14a1 1 0 1 0 2 0V3a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v20a3 3 0 0 0 3 3h1v5a1 1 0 0 0 1.6.8l5.963-4.466a1 1 0 1 0-1.199-1.601L6 29.002V25a1 1 0 0 0-1-1H3a1 1 0 0 1-1-1V3ZM23 26a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z' />
      <path d='M23 19a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.504 14.355a3.366 3.366 0 0 0-4.514 1.506l-5.635 11.267A3.365 3.365 0 0 0 17.365 32h11.27c.525 0 1.04-.124 1.505-.355l.002-.001a3.365 3.365 0 0 0 1.503-4.516L26.01 15.861a3.365 3.365 0 0 0-1.506-1.506Zm-2.726 2.4a1.366 1.366 0 0 1 2.444 0l5.635 11.27A1.364 1.364 0 0 1 28.635 30h-11.27a1.367 1.367 0 0 1-1.222-1.975l5.635-11.27Z'
        fill={props.color ?? '#CC3E76'}
      />
    </g>
    <defs>
      <clipPath id='message-fail_icon_svg__a'>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgMessageFailIcon
