import { useTranslation } from 'react-i18next'
import {
  CreatableMultiSelectFormField,
  SelectFormField,
} from '../../../../../common/components/Form/SelectFormField/SelectFormField'
import { getIcon } from '../../../../../common/components/icons/utils'
import { EditorPermissions, NotificationSettings } from '../../../../../types/commonTypes'
import { padNumWithZero } from '../../../../../util/string'
import styles from './GameSettingsComponents.module.css'
import { GameSettingsToggle } from './GameSettingsToggle'

const shouldDisableField = (permissions: EditorPermissions, notificationField: keyof Partial<NotificationSettings>) => {
  if (typeof permissions.gameSettings === 'boolean') {
    return !permissions.gameSettings
  }
  if (typeof permissions.gameSettings.notificationSettings === 'boolean') {
    return !permissions.gameSettings.notificationSettings
  }
  return !permissions.gameSettings?.notificationSettings?.[notificationField]
}

type NotificationsProps = {
  summaryEnabled?: boolean
  permissions: EditorPermissions
}

const SUMMARY_TIME_OPTIONS = new Array(24).fill('').map((_, index) => ({
  value: `${padNumWithZero(index, 2)}:00`,
  label: `${padNumWithZero(index, 2)}:00 (UTC)`,
}))

export const Notifications: React.FC<NotificationsProps> = ({ summaryEnabled, permissions }) => {
  const { t } = useTranslation()

  const CHAT_MESSAGES_OPTIONS = [
    { value: '0', label: t('game_editor.game_settings.notifications.messages_no_notifications', 'No notifications') },
    {
      value: '1',
      label: t('game_editor.game_settings.notifications.messages_notify_instructors', 'Messages sent to instructors'),
    },
    { value: '2', label: t('game_editor.game_settings.notifications.messages_all_messages', 'All messages') },
  ]

  const TASKS_NOTIFICATIONS_OPTIONS = [
    { value: '0', label: t('game_editor.game_settings.notifications.tasks_no_notifications', 'No notifications') },
    { value: '1', label: t('game_editor.game_settings.notifications.tasks_waiting_grading', 'Tasks waiting grading') },
    { value: '2', label: t('game_editor.game_settings.notifications.tasks_all_tasks', 'All tasks') },
  ]

  return (
    <>
      <span className={styles.toggleSwitchDescription}>
        {t(
          'game_editor.game_settings.notifications.description',
          'Enable email notifications. You and your collaborators will be notified about events occurring in the game.',
        )}
      </span>
      <div className={styles.dropdownContainer}>
        <SelectFormField
          name='notificationSettings.messages'
          label={
            <label className={styles.dropdownLabel}>
              <span className={styles.dropdownLabelIcon}>{getIcon('chatUnread')}</span>
              {t('game_editor.game_settings.notifications.chat_messages', 'Chat messages')}
            </label>
          }
          placeholder={t('game_editor.game_settings.notifications.set_chat_notifications', 'Set chat notifications')}
          options={CHAT_MESSAGES_OPTIONS}
          fieldContainerClassName={styles.dropdownField}
          disabled={shouldDisableField(permissions, 'messages')}
        />

        <SelectFormField
          name='notificationSettings.answers'
          label={
            <label className={styles.dropdownLabel}>
              <span className={styles.dropdownLabelIcon}>{getIcon('pinFilled')}</span>
              {t('game_editor.game_settings.notifications.tasks', 'Tasks')}
            </label>
          }
          placeholder={t('game_editor.game_settings.notifications.set_task_notifications', 'Set task notifications')}
          options={TASKS_NOTIFICATIONS_OPTIONS}
          fieldContainerClassName={styles.dropdownField}
          disabled={shouldDisableField(permissions, 'answers')}
        />
      </div>
      <div className={styles.sectionContainer}>
        <span className={styles.sectionTitle}>
          {t('game_editor.game_settings.notifications.frequency', 'Frequency')}
        </span>
        <GameSettingsToggle
          name='notificationSettings.instantEnabled'
          label={t(
            'game_editor.game_settings.notifications.all_messages_and_answers_label',
            'Send notification for every message and answer',
          )}
          description={t(
            'game_editor.game_settings.notifications.all_messages_and_answers_description_disabled',
            'You will be notified of every submission. This might cause a lot of emails.',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.notifications.all_messages_and_answers_description_enabled',
            'You will be notified of every submission. This might cause a lot of emails.',
          )}
          disabled={shouldDisableField(permissions, 'instantEnabled')}
        />
        <GameSettingsToggle
          name='notificationSettings.summaryEnabled'
          label={t('game_editor.game_settings.notifications.daily_summary_label', 'Send a daily summary')}
          description={t(
            'game_editor.game_settings.notifications.daily_summary_description_disabled',
            'This will generate a daily email that reports what players have submitted.',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.notifications.daily_summary_description_enabled',
            'This will generate a daily email that reports what players have submitted. Select the time when daily summary should be sent.',
          )}
          disabled={shouldDisableField(permissions, 'summaryEnabled')}
        />
        {summaryEnabled && (
          <SelectFormField
            name='notificationSettings.summarySendTime'
            label={t('game_editor.game_settings.notifications.daily_summary_send_time_label', 'Send daily summary at')}
            srOnlyLabel
            options={SUMMARY_TIME_OPTIONS}
            fieldContainerClassName={styles.sendSummarySelectWrapper}
            disabled={shouldDisableField(permissions, 'summarySendTime')}
          />
        )}
      </div>
      <div className={styles.inputContainer}>
        <CreatableMultiSelectFormField
          label={
            <>
              <span className={styles.sectionTitle}>
                {t('game_editor.game_settings.notifications.recipients', 'Recipients')}
              </span>
              <span className={styles.sectionDescription}>
                {t('game_editor.game_settings.notifications.recipients_desc', 'Separate addresses with a coma')}
              </span>
            </>
          }
          name='notificationSettings.emails'
          placeholder={t('game_editor.game_settings.notifications.type_email_here', 'Type email addresses here')}
          disabled={shouldDisableField(permissions, 'emails')}
        />
      </div>
    </>
  )
}
