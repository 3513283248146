import classNames from 'classnames'
import { t } from 'i18next'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import styles from './LoginMethod.module.css'

export const PasswordResetOrder: React.FC = () => {
  const { fadeTransition } = useFadeAnimation()

  return (
    <div className={classNames(styles.mainContentWrapper, fadeTransition)}>
      <h1 className={styles.title}>{t('login.instructor.password_change.order_form.title', 'Reset your password')}</h1>
      <div className={styles.paragraphContainer}>
        <p>
          {t(
            'login.instructor.password_change.order_form.body',
            'Enter your email to reset your password. You will receive an email with instructions.',
          )}
        </p>
      </div>
      <InputFormField
        label='Email'
        placeholder={t('login.instructor.password_change.order.email_placeholder', 'Enter your email address')}
        srOnlyLabel
        name='email'
        type='email'
        big
      />
    </div>
  )
}
