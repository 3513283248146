import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSubmitError } from '../../../../../common/components/Form/FormFieldError/FormSubmitError'
import { Modal } from '../../../../../common/components/Modal/Modal'
import { Button } from '../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../common/components/icons/utils'
import { useDisableBodyScroll } from '../../../../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../../../../hooks/useTrapFocus'
import { Badge } from '../../../../../types/commonTypes'
import { safeParseInt } from '../../../../../util/number'
import { SelectableBadge } from '../BadgeView/SelectableBadge'
import styles from './BadgePicker.module.css'

type BadgePickerProps = {
  badges: Badge[]
  selectedBadgeId?: number
  onSelectBadge: (badgeId: number) => void
  onClose: () => void
}

export const BadgePicker: React.FC<BadgePickerProps> = ({ badges, selectedBadgeId, onSelectBadge, onClose }) => {
  const { t } = useTranslation()

  const modalRef = useTrapFocus<HTMLDivElement>()
  useDisableBodyScroll()

  const [selectedId, setSelectedId] = useState<number | undefined>(safeParseInt(selectedBadgeId))
  const [showError, setShowError] = useState<boolean>()

  const onSave = () => {
    if (!selectedId) {
      setShowError(true)
    } else if (selectedId === selectedBadgeId) {
      onClose()
    } else {
      onSelectBadge(selectedId)
    }
  }

  const onClickBadge = (badge: Partial<Badge>) => {
    setShowError(false)
    setSelectedId((prev) => (prev === badge.id ? undefined : badge.id))
  }

  return (
    <Modal>
      <div ref={modalRef} className={styles.badgePickerModal}>
        <div className={styles.badgePickerHeader}>
          <div className={styles.badgePickerHeaderTitle}>
            <span>{getIcon('badgeGradient')}</span>
            <span>{t('game_editor.badges.badge_picker_title', 'Choose badge')}</span>
          </div>
          <CloseButton autoFocus onClick={onClose} />
        </div>
        <div className={styles.badgePickerMainSection}>
          <ul className={styles.badgesList}>
            {badges.map((badge) => (
              <li className={styles.badgeListItem} key={`badgeView_${badge.id}`}>
                <SelectableBadge
                  badge={badge}
                  showName
                  onSelect={onClickBadge}
                  isSelected={badge.id === selectedId}
                  otherSelected={selectedId != null && badge.id !== selectedId}
                />
              </li>
            ))}
          </ul>
          {showError && (
            <FormSubmitError
              error={t('game_editor.badges.badge_picker_select_badge_warning', 'Select badge before adding it')}
              modifiedSinceSubmit={false}
            />
          )}
        </div>
        <div className={styles.badgePickerFooter}>
          <Button variant='outline-normal' onClick={onClose}>
            {t('common.cancel', 'Cancel')}
          </Button>
          <Button onClick={onSave}>{t('common.add', 'Add')}</Button>
        </div>
      </div>
    </Modal>
  )
}
