import { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '../../../../../../common/components/button/Button'
import { getIcon } from '../../../../../../common/components/icons/utils'
import { DeepPartial, Game, Task } from '../../../../../../types/commonTypes'
import { hasSeveralBoards } from '../../../../../../util/game'
import commonStyles from '../../EditorSidebar.module.css'
import { TaskAction, TaskActionData } from '../types'
import { BoardsList } from './BoardsList/BoardsList'
import { CardButton } from './CardButton/CardButton'
import { LevelsList } from './LevelsList/LevelsList'
import { FlashList } from './TasksList/FlashList'
import { PinnedList } from './TasksList/PinnedList'

export type TasksActionProps = {
  onAddTask: (initialTask?: DeepPartial<Task>) => void
  onEditTask: (id: number) => void
  onCopyTask: (id: number) => void
  onDeleteTask: (id: number) => void
}

type TasksProps = TasksActionProps & {
  game: Game
  activeBoardIndex: number
  showFlash: boolean
  isCompact: boolean
  showAllLevelBoards: boolean
  viewOnly: boolean
  onSetShowDragCancel: (show: boolean) => void
  onSetActiveBoard: (id: number) => void
  onOpenGameSettings: () => void
  onAddDoor: () => void
}

export const Tasks: React.FC<TasksProps> = ({
  game,
  activeBoardIndex,
  showFlash,
  isCompact,
  showAllLevelBoards,
  viewOnly,
  onSetActiveBoard,
  onAddTask,
  onEditTask,
  onCopyTask,
  onDeleteTask,
  onOpenGameSettings,
  onSetShowDragCancel,
  onAddDoor,
}) => {
  const { t } = useTranslation()

  const filteredTasks = useMemo(() => {
    return game.tasks.filter((task) => (showFlash ? task.advanced.isFlash : !task.advanced.isFlash))
  }, [game.tasks, showFlash])

  const handleTaskAction = useCallback(
    async ({ task, action }: TaskActionData) => {
      switch (action) {
        case TaskAction.DELETE:
          onDeleteTask(task.id)
          break
        case TaskAction.COPY:
          onCopyTask(task.id)
          break
        case TaskAction.EDIT:
          onEditTask(task.id)
          break
        default:
          console.warn(`Did not process task action - ${action}. Add implementation here or on some nested level.`)
      }
    },
    [onDeleteTask, onCopyTask, onEditTask],
  )

  const onClickAddTask = () => onAddTask()
  const onClickAddFlashTask = () => onAddTask({ advanced: { isFlash: true } })
  const onClickAddDoor = () => onAddDoor()

  return (
    <>
      {filteredTasks.length === 0 && (
        <p className={commonStyles.emptyStateInfo}>
          {showFlash ? (
            t('game_editor.sidebar.build.no_flash_tasks_text', 'No flash tasks currently')
          ) : (
            <Trans i18nKey='game_editor.sidebar.build.no_tasks_text' components={{ bold: <b /> }}>
              {'No tasks currently, press <bold>Add task</bold> to create the first one'}
            </Trans>
          )}
        </p>
      )}
      <div className={commonStyles.sidebarMainContainer}>
        {showFlash ? (
          <FlashList
            tasks={filteredTasks}
            isCompact={isCompact}
            onTaskAction={handleTaskAction}
            noPointsGame={game.advancedSettings.noPointsGame}
            onSetShowDragCancel={onSetShowDragCancel}
            viewOnly={viewOnly}
          />
        ) : game.advancedSettings.levelsEnabled ? (
          <LevelsList
            activeBoardIndex={activeBoardIndex}
            game={game}
            isCompact={isCompact}
            onTaskAction={handleTaskAction}
            onSetActiveBoard={onSetActiveBoard}
            showAllBoards={showAllLevelBoards}
            onSetShowDragCancel={onSetShowDragCancel}
            onAddTask={onAddTask}
            viewOnly={viewOnly}
          />
        ) : hasSeveralBoards(game) ? (
          <BoardsList
            activeBoardIndex={activeBoardIndex}
            game={game}
            isCompact={isCompact}
            onTaskAction={handleTaskAction}
            onSetActiveBoard={onSetActiveBoard}
            onSetShowDragCancel={onSetShowDragCancel}
            viewOnly={viewOnly}
          />
        ) : (
          <PinnedList
            tasks={filteredTasks}
            isCompact={isCompact}
            onTaskAction={handleTaskAction}
            noPointsGame={game.advancedSettings.noPointsGame}
            orderingEnabled={game.advancedSettings.orderingEnabled}
            onSetShowDragCancel={onSetShowDragCancel}
            viewOnly={viewOnly}
          />
        )}
        {showFlash && !viewOnly && (
          <CardButton
            icon={'taskFlashGradient'}
            label={t('game_editor.sidebar.build.tasks.add_flash_task', 'Add flash task')}
            onClick={onClickAddFlashTask}
          />
        )}
        {!showFlash && (
          <>
            {!viewOnly && game.advancedSettings.explorationMode && (
              <Button variant='outline-normal' onClick={onClickAddDoor} className={commonStyles.sidebarCentredButton}>
                <span className='iconWrapperBig'>{getIcon('door')}</span>
                <span>{t('game_editor.sidebar.build.tasks.add_door_button', 'Add door')}</span>
              </Button>
            )}
            <Button variant='outline-normal' onClick={onOpenGameSettings} className={commonStyles.sidebarCentredButton}>
              {t('game_editor.sidebar.build.tasks.define_boards_and_settings_button', 'Define boards and settings')}
            </Button>
          </>
        )}
      </div>
      {!viewOnly && (
        <div className={commonStyles.sidebarBottomContainer}>
          <Button onClick={onClickAddTask}>
            <span className='iconWrapperBig'>{getIcon('pinAdd')}</span>
            <span>{t('game_editor.sidebar.build.tasks.add_task_button', 'Add task')}</span>
          </Button>
        </div>
      )}
    </>
  )
}
