import classNames from 'classnames'
import { safeIsNullOrEmpty } from '../../../../../../../../../util/string'
import layoutStyles from '../AnswerOptionLayout.module.css'
import { AnswerPoints } from '../AnswerPoints'
import { AnswerStatus, AnswerStatusIcon } from '../AnswerStatusIcon'
import styles from './ChoiceAnswer.module.css'

type ChoiceAnswerProps = {
  text: string
  points?: number
  imageUrl?: string
  isCorrectAnswer?: boolean
  wasSelected: boolean
  hidePoints: boolean
}

const getStatusForChoice = (isCorrect: boolean, wasSelected: boolean): AnswerStatus => {
  if (isCorrect) {
    return wasSelected ? AnswerStatus.SUCCESS : AnswerStatus.WARNING
  } else {
    return wasSelected ? AnswerStatus.ERROR : AnswerStatus.NEUTRAL
  }
}

export const ChoiceAnswer: React.FC<ChoiceAnswerProps> = ({
  isCorrectAnswer,
  points,
  text,
  wasSelected,
  imageUrl,
  hidePoints,
}) => {
  const answerStatus = getStatusForChoice(isCorrectAnswer ?? points != null, wasSelected)

  return (
    <div className={layoutStyles.answerOptionRow}>
      <div
        className={classNames(
          layoutStyles.answerOptionLeftColumn,
          styles.choiceAnswerContainer,
          styles[`choiceAnswerContainer_${answerStatus}`],
        )}
      >
        <div className={styles.answerTextAndImage}>
          {!safeIsNullOrEmpty(imageUrl) && <img width={86} src={imageUrl} alt='' />}
          <span>{text}</span>
        </div>
        <AnswerStatusIcon status={answerStatus} />
      </div>
      <AnswerPoints points={wasSelected ? points ?? 0 : 0} active={wasSelected} hidePoints={hidePoints} />
    </div>
  )
}
