//Adapted from thread: https://stackoverflow.com/questions/51504506/too-many-react-context-providers
import React from 'react'

interface Props {
  components: Array<React.JSXElementConstructor<React.PropsWithChildren<unknown>>>
  children: React.ReactNode
}

export function CombineComponents(props: Props) {
  const { components = [], children } = props

  return (
    <>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>
      }, children)}
    </>
  )
}
