import classNames from 'classnames'
import { FormFieldProps } from '../../../../types/commonTypes'
import { ERROR_ROUNDED_OUTLINE_CLASS_NAME, FormFieldWithError } from '../FormFieldError/FormFieldWithError'
import commonFieldStyles from '../FormFields.module.css'
import styles from './IconRadioGroupField.module.css'
import { IconName, getIcon } from '../../icons/utils'
import { useField } from 'react-final-form'

export type IconRadioItem = {
  value: string
  icon?: IconName
  rawSvg?: string
  label: string
  disabled?: boolean
}

type IconRadioGroupFieldProps = {
  options: IconRadioItem[]
  disabled?: boolean
}

type IconRadioFieldProps = IconRadioItem & {
  name: string
}

export const IconRadioField: React.FC<IconRadioFieldProps> = ({ name, value, icon, rawSvg, label, disabled }) => {
  const { input } = useField(name, { type: 'radio', value })

  return (
    <label>
      <input className={styles.radioInput} {...input} disabled={disabled} />
      <span className={styles.radioContent}>
        {icon != null && getIcon(icon)}
        {rawSvg != null && <span className={styles.rawSvg} dangerouslySetInnerHTML={{ __html: rawSvg }} />}
        <span className='sr-only'>{label}</span>
      </span>
    </label>
  )
}

export const IconRadioGroupField: React.FC<FormFieldProps<IconRadioGroupFieldProps>> = ({
  options,
  name,
  label,
  srOnlyLabel,
  validateOnlyIfDirty,
  disabled,
}) => {
  return (
    <FormFieldWithError name={name} validateOnlyIfDirty={validateOnlyIfDirty}>
      <label className={classNames(srOnlyLabel && 'sr-only', commonFieldStyles.label)}>{label}</label>
      <div className={classNames(styles.radioGroup, ERROR_ROUNDED_OUTLINE_CLASS_NAME)}>
        {options.map((option, index) => (
          <IconRadioField
            key={`iconRadioGroup_${index}_${option.value}`}
            name={name}
            {...option}
            disabled={disabled ?? option.disabled}
          />
        ))}
      </div>
    </FormFieldWithError>
  )
}
