import classNames from 'classnames'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { Tooltip } from '../../../../../../../common/components/tooltip/Tooltip'
import styles from './BoardContainer.module.css'

type BoardCollapseProps = {
  isCollapsed: boolean
  onToggleCollapsed: () => void
}

type BoardHeaderProps = {
  isActive: boolean
  boardIndex: number
  boardUrl: string
  title: string
  compact?: boolean
  onSetActiveBoard: (id: number) => void
  collapseProps?: BoardCollapseProps
  isDefaultBoard?: boolean
}

export const BoardHeader: React.FC<BoardHeaderProps> = ({
  isActive,
  title,
  boardIndex,
  boardUrl,
  compact = false,
  onSetActiveBoard,
  collapseProps,
  isDefaultBoard,
}) => {
  const { t } = useTranslation()

  const onSetActiveBoardInternal = useCallback(() => {
    onSetActiveBoard(boardIndex)
  }, [boardIndex, onSetActiveBoard])

  const onCollapseBoardInternal = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    collapseProps?.onToggleCollapsed()
  }

  const background = `linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, rgba(255, 255, 255, ${
    isActive ? 0.25 : 0.65
  }) 0%, rgba(255, 255, 255, ${isActive ? 0.25 : 0.65}) 100%), url('${boardUrl}') no-repeat center/100%`

  return (
    <div style={{ background }} className={styles.boardHeader} onClick={onSetActiveBoardInternal}>
      <button className={classNames(styles.leftContainer, isActive && styles.leftContainerActive)}>
        {getIcon('photo')}
      </button>
      <div className={classNames(styles.middleContainer, compact && styles.middleContainerCompact)}>
        <h5 className={classNames(isActive && styles.activeTitle)}>{title}</h5>
        {isDefaultBoard && (
          <Tooltip
            tooltipContent={t(
              'game_editor.levels.default_board_icon_tooltip',
              'Players see this board when they unlock the level',
            )}
            tooltipClassName={styles.defaultBoardTooltip}
          >
            {(tooltipProps) => (
              <span className={styles.defaultBoardIcon} {...tooltipProps}>
                {getIcon('levelGeneric')}
              </span>
            )}
          </Tooltip>
        )}
        {isActive && <div className={styles.activePill}>{t('common.active', 'Active')}</div>}
        {collapseProps != null && (
          <button
            className={classNames(styles.collapseButton, !collapseProps.isCollapsed && styles.collapseButton_expanded)}
            onClick={onCollapseBoardInternal}
          >
            {getIcon('arrowDown')}
          </button>
        )}
      </div>
    </div>
  )
}
