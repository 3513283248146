import * as React from 'react'
import { SVGProps } from 'react'

const SvgFbIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='m12.578 13.5.656-4.313H9.063V6.376c0-1.219.562-2.344 2.437-2.344h1.922V.328S11.687 0 10.047 0C6.625 0 4.375 2.11 4.375 5.86v3.327H.531V13.5h3.844V24h4.688V13.5h3.515Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)

export default SvgFbIcon
