import { useTranslation } from 'react-i18next'
import SvgWandIcon from '../icons/WandIcon'
import styles from './ErrorPage.module.css'

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.errorPage}>
      <div className={styles.content}>
        <SvgWandIcon className={styles.image} />
        <h1 className={styles.title}>{t('error_page.title', "Oops! Something didn't go as planned.")}</h1>
        <p className={styles.description}>
          {t(
            'error_page.description',
            'Use the back or reload buttons to continue where you left off. If this issue continues, reach out to our support team for help.',
          )}
        </p>
      </div>
    </div>
  )
}
