import * as React from 'react'
import { SVGProps } from 'react'
const SvgSelectMapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#select_map_icon_svg__a)' fillRule='evenodd' clipRule='evenodd' fill='#000'>
      <path
        d='M16.173 0a7 7 0 0 0-7 7c0 .842.286 1.775.666 2.666.387.911.913 1.871 1.477 2.794 1.127 1.845 2.456 3.617 3.28 4.671.106.137.224.25.34.342l.006.005a2 2 0 0 0 2.807-.345c.824-1.053 2.154-2.827 3.282-4.673.564-.923 1.09-1.883 1.477-2.794.38-.891.665-1.824.665-2.666a7 7 0 0 0-7-7Zm-5 7a5 5 0 0 1 5-5v3.5a1.5 1.5 0 0 0 0 3v7.401c-.804-1.028-2.08-2.732-3.15-4.484-.537-.876-1.009-1.745-1.344-2.533-.344-.809-.506-1.445-.506-1.884Zm5.004 8.897c.805-1.03 2.079-2.732 3.148-4.48.535-.877 1.007-1.745 1.343-2.534.343-.808.505-1.444.505-1.883a5 5 0 0 0-5-5v3.5a1.5 1.5 0 0 1 .002 3l.002 7.397Zm0 .007v-.007l-.003.004.003.003Zm0 0v.002h.001v-.002Z'
        fill={props.color ?? '#CC3E76'}
      />
      <path
        d='M23.175 12.84a1 1 0 0 1 1.179-.781A3.001 3.001 0 0 1 26.587 14l.007.022 1.522 4.643.015.047 1.948 5.942.022.069 1.078 3.289A2.998 2.998 0 0 1 28.347 32H4c-.34 0-.676-.058-.994-.17h-.003a3 3 0 0 1-1.835-3.816l3.044-9.29a.993.993 0 0 1 .023-.071l1.517-4.63.007-.02a2.997 2.997 0 0 1 2.217-1.94 1 1 0 0 1 .408 1.957.997.997 0 0 0-.735.638L6.554 18h5.596a.998.998 0 0 1 .815.389.997.997 0 0 1 .191.795L12.354 24h7.639l-.803-4.817a1.009 1.009 0 0 1 .18-.778.999.999 0 0 1 .827-.405h5.596l-1.095-3.342c-.12-.328-.4-.57-.742-.64a1 1 0 0 1-.781-1.178ZM26.449 20h-5.095l.667 4h5.738l-1.31-4ZM4.588 24l1.31-4h5.095l-.667 4H4.588Zm23.827 2h-6.061l.667 4h5.326a1 1 0 0 0 .942-1.333l-.007-.022L28.415 26Zm-8.089 0H12.02l-.666 4h9.64l-.668-4ZM9.993 26h-6.06l-.868 2.646-.007.022A1 1 0 0 0 4 30h5.326l.667-4Z'
        fill={props.color ?? 'url(#selectMap_icon_svg__a)'}
        fillOpacity={props.color ? 1 : 0.25}
      />
    </g>
    <defs>
      <clipPath id='select_map_icon_svg__a'>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgSelectMapIcon
