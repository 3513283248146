import * as React from 'react'
import { SVGProps } from 'react'
const SvgScoreHeaderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28 16c0 6.627-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4s12 5.373 12 12Zm-6.3-1.98a.71.71 0 0 1 .624.492.706.706 0 0 1-.228.78l-2.748 2.376.828 3.54a.698.698 0 0 1-.276.744.756.756 0 0 1-.42.132.703.703 0 0 1-.372-.108L16 20.104l-3.12 1.872a.73.73 0 0 1-.792-.036.74.74 0 0 1-.276-.744l.828-3.54-2.748-2.376a.74.74 0 0 1-.216-.768.71.71 0 0 1 .624-.492l3.624-.312 1.404-3.348c.228-.528 1.104-.528 1.332 0l1.416 3.348 3.624.312Z'
      fill={props.color ?? '#C94B7D'}
    />
  </svg>
)
export default SvgScoreHeaderIcon
