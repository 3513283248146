import { Tag } from '../../../../../../types/commonTypes'
import styles from './PlayersAddByPlayerAccountModal.module.css'
import { ListLineActiveTag } from './PlayerAccountModalListLines'
import { useTranslation } from 'react-i18next'

type ActiveTagsAreaProps = {
  activeFilterTags: Tag[]
  onSetActiveFilterTags: (tags: Tag[]) => void
  onRemoveActiveTag: (tag: Tag) => void
  onTagSelected: (tag: Tag, toSelected: boolean) => void
  toAddTags: Tag[]
}

export const ActiveTagsArea: React.FC<ActiveTagsAreaProps> = ({
  activeFilterTags,
  onSetActiveFilterTags,
  onRemoveActiveTag,
  onTagSelected,
  toAddTags,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.activeTagsTitleRow}>
        <div className={styles.activeTagsTitle}>
          {t('game_editor.add_people.player_account.active_tags', 'Active tags')}
        </div>
        <div onClick={() => onSetActiveFilterTags([])} className={styles.activeTagsClearAll}>
          {t('game_editor.add_people.player_account.active_tags_clear_all', 'Clear all')}
        </div>
      </div>
      <div className={styles.activeTagsArea}>
        {activeFilterTags.map((tag, index) => {
          return (
            <ListLineActiveTag
              key={index}
              isSelected={!!toAddTags.find((t) => t.id === tag.id)}
              onDelete={onRemoveActiveTag}
              onSelect={onTagSelected}
              tag={tag}
            />
          )
        })}
      </div>
    </>
  )
}
