import * as React from 'react'
import { SVGProps } from 'react'
const SvgCopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 1.333A2.667 2.667 0 0 0 9.333 4H8a4 4 0 0 0-4 4v18.667a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4h-1.333A2.667 2.667 0 0 0 20 1.333h-8Zm10.667 5.334A2.667 2.667 0 0 1 20 9.333h-8a2.667 2.667 0 0 1-2.667-2.666H8A1.333 1.333 0 0 0 6.667 8v18.667A1.333 1.333 0 0 0 8 28h16a1.333 1.333 0 0 0 1.333-1.333V8A1.333 1.333 0 0 0 24 6.667h-1.333ZM12 6.667h8V4h-8v2.667Z'
      fill={props.color ?? '#CC3E76'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 1.333A2.667 2.667 0 0 0 9.333 4H8a4 4 0 0 0-4 4v18.667a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4h-1.333A2.667 2.667 0 0 0 20 1.333h-8Zm10.667 5.334A2.667 2.667 0 0 1 20 9.333h-8a2.667 2.667 0 0 1-2.667-2.666H8A1.333 1.333 0 0 0 6.667 8v18.667A1.333 1.333 0 0 0 8 28h16a1.333 1.333 0 0 0 1.333-1.333V8A1.333 1.333 0 0 0 24 6.667h-1.333ZM12 6.667h8V4h-8v2.667Z'
      fill='url(#copy_icon_svg__a)'
      fillOpacity={0.25}
    />
    <defs>
      <linearGradient id='copy_icon_svg__a' x1={16} y1={1.333} x2={16} y2={30.667} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgCopyIcon
