import { FieldMetaState } from 'react-final-form'

export const getFieldError = (fieldMeta: FieldMetaState<any>, validateOnlyIfDirty?: boolean): string | undefined => {
  return fieldMeta.error &&
    typeof fieldMeta.error === 'string' &&
    !fieldMeta.active &&
    (fieldMeta.submitFailed || (validateOnlyIfDirty ? fieldMeta.dirty : true)) &&
    fieldMeta.touched
    ? fieldMeta.error
    : fieldMeta.submitError && !fieldMeta.modifiedSinceLastSubmit
    ? fieldMeta.submitError
    : undefined
}
