import * as React from 'react'
import { SVGProps } from 'react'

const SvgLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#link_icon_svg__a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25-.008a7 7 0 0 0-4.95 2.05l-3 3-.002.002a6.966 6.966 0 0 0-2.016 5.32 1 1 0 1 0 1.997-.117 4.967 4.967 0 0 1 1.436-3.791l.001-.001 2.998-2.998a5 5 0 0 1 7.072 7.07l-3 3a4.963 4.963 0 0 1-3.792 1.443 1 1 0 1 0-.115 1.997 6.964 6.964 0 0 0 5.32-2.025l3-3A7 7 0 0 0 25-.008Zm-1.293 8.3a1 1 0 0 1 0 1.414l-14 14a1 1 0 0 1-1.414-1.414l14-14a1 1 0 0 1 1.414 0ZM7.495 15.473a6.962 6.962 0 0 1 2.876-.443 1 1 0 1 1-.115 1.997 4.961 4.961 0 0 0-3.792 1.442l-3 3a5 5 0 1 0 7.072 7.07l3-2.999a4.962 4.962 0 0 0 1.442-3.792 1 1 0 1 1 1.996-.115 6.963 6.963 0 0 1-2.024 5.32l-3 3.001a7 7 0 0 1-9.9-9.9l3-3a6.966 6.966 0 0 1 2.445-1.58Z'
        fill={props.color ?? 'var(--grey-700)'}
      />
    </g>
    <defs>
      <clipPath id='link_icon_svg__a'>
        <path fill={props.color ?? 'var(--grey-700)'} d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)

export default SvgLinkIcon
