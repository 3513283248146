import * as React from 'react'
import { SVGProps } from 'react'

const SvgRecentsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#recents_icon_svg__a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.39 3.666A14 14 0 0 1 16.015 2a1 1 0 0 0 0-2 16 16 0 1 0 11.494 27.13 1 1 0 1 0-1.437-1.391A14 14 0 1 1 9.39 3.666Zm7.625 7a1 1 0 1 0-2 0v6a1 1 0 0 0 .327.74l6.637 6.034a1 1 0 1 0 1.346-1.48l-6.31-5.736v-5.557ZM19.172 1.3a1 1 0 0 1 1.236-.687c.914.26 1.804.603 2.657 1.022a1 1 0 0 1-.882 1.795 13.956 13.956 0 0 0-2.324-.894 1 1 0 0 1-.687-1.236Zm8.346 3.573a1 1 0 0 0-1.436 1.392 13.869 13.869 0 0 1 1.564 1.935 1 1 0 1 0 1.662-1.113 15.876 15.876 0 0 0-1.79-2.214Zm2.81 6.515a1 1 0 0 1 1.213.727c.231.923.38 1.864.442 2.813a1 1 0 0 1-1.995.133 13.888 13.888 0 0 0-.387-2.46 1 1 0 0 1 .726-1.213Zm1.075 9.01a1 1 0 1 0-1.923-.548c-.228.799-.527 1.576-.895 2.321a1 1 0 0 0 1.795.884c.42-.853.762-1.743 1.023-2.657Z'
        fill={props.color ?? '#6F6F6F'}
      />
    </g>
    <defs>
      <clipPath id='recents_icon_svg__a'>
        <path fill={props.color ?? '#6F6F6F'} d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)

export default SvgRecentsIcon
