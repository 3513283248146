import { getIcon } from '../../icons/utils'
import styles from './AddMemberError.module.css'
import { PropsWithChildren } from 'react'

export const AddMemberError: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.button}>
      <span className={styles.iconContainer}>{getIcon('alert')}</span>
      <span className='medium'>{children}</span>
    </div>
  )
}
