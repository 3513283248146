import classNames from 'classnames'
import { useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../common/components/Form/InputFormField/InputFormField'
import { Button } from '../../../../../common/components/button/Button'
import styles from './Profile.module.css'

type FormValues = {
  field: string
}

type EditablePreviewItemProps = {
  label: string
  initialValue: string
  onUpdate: (value: string) => Promise<boolean>
}

export const EditablePreviewItem: React.FC<EditablePreviewItemProps> = ({ label, initialValue, onUpdate }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { t } = useTranslation()

  const onSubmit = async (values: FormValues) => {
    if (values.field === initialValue) {
      setIsEditing(false)
    } else {
      const updateSuccess = await onUpdate(values.field)
      if (updateSuccess) {
        setIsEditing(false)
      }
    }
  }

  const initialValues = {
    field: initialValue,
  }

  return (
    <div className={styles.rowContainer}>
      <label className={styles.firstColumn}>{label}</label>
      {isEditing ? (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={classNames(styles.rowContainer, styles.secondColumn)}>
              <InputFormField
                label={label}
                srOnlyLabel
                name='field'
                className={classNames(styles.secondColumn, styles.inputField)}
              />
              <div className={styles.rowContainer}>
                <Button variant='outline-tiny' onClick={() => setIsEditing(false)} disabled={submitting}>
                  {t('settings_general.profile.buttons_text.cancel', 'Cancel')}
                </Button>
                <Button type='submit' variant='tiny' disabled={submitting}>
                  {t('settings_general.profile.buttons_text.submit_update', 'Ok')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      ) : (
        <>
          <span className={classNames(styles.secondColumn, styles.textContent)}>{initialValue}</span>
          <Button variant='outline-tiny' onClick={() => setIsEditing(true)}>
            {t('settings_general.profile.buttons_text.edit', 'Edit')}
          </Button>
        </>
      )}
    </div>
  )
}
