import { useTranslation } from 'react-i18next'
import { ThreeDots } from '../../../../../../../../common/components/loaders/ThreeDots/ThreeDots'
import styles from './PreviousAnswersLoader.module.css'

export const PreviousAnswersLoader: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.loaderContainer}>
      <ThreeDots remSize={0.75} />
      <span>{t('game_editor.sidebar.evaluate_answer_panel.loading_previous_answers', 'Loading previous answers')}</span>
    </div>
  )
}
