import { useEffect } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { PointsInput } from '../../../../Points/PointsInput'
import { AnswerOption, AnswerOptionRow, AnswerOptionRowWithMenu } from '../components/AnswerOption'
import styles from './MatchPairsAnswerOption.module.css'
import { MatchPairsAnswerSide } from './MatchPairsAnswerSide'

export type MatchPairsAnswerOptionProps = {
  name: string
  index: number
  onDelete: () => void
  hasFixedPoints?: boolean
  fixedPoints?: number
  noPoints?: boolean
  viewOnly: boolean
}

export const MatchPairsAnswerOption: React.FC<MatchPairsAnswerOptionProps> = ({
  name,
  index,
  onDelete,
  hasFixedPoints,
  fixedPoints,
  noPoints,
  viewOnly,
}) => {
  const { t } = useTranslation()
  const { input } = useField(`${name}.points`)

  useEffect(() => {
    if (hasFixedPoints && input.value !== fixedPoints) {
      input.onChange(fixedPoints)
    }
  }, [hasFixedPoints, fixedPoints, input])

  return (
    <AnswerOption>
      <AnswerOptionRowWithMenu onDelete={viewOnly ? undefined : onDelete} actionMenuId={`${name}_actionMenu`}>
        <div className={styles.title}>
          {t('game_editor.tasks.match_pairs.pair_with_index', {
            pair_index: index + 1,
            defaultValue: 'Pair %{pair_index}',
          })}
        </div>
        {!noPoints && <PointsInput name={`${name}.points`} disabled={hasFixedPoints} />}
      </AnswerOptionRowWithMenu>
      <AnswerOptionRow>
        <MatchPairsAnswerSide name={`${name}.left`} viewOnly={viewOnly} />
        <div className={styles.sidesDivider} />
        <MatchPairsAnswerSide name={`${name}.right`} viewOnly={viewOnly} />
      </AnswerOptionRow>
    </AnswerOption>
  )
}
