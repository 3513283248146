import { useTranslation } from 'react-i18next'

import { IconName, getIcon } from '../../../../../../../common/components/icons/utils'

import styles from './CardButton.module.css'

type CardButtonProps = React.HTMLProps<HTMLButtonElement> & {
  label: string
  loading?: boolean
  icon: IconName
}

export const CardButton: React.FC<CardButtonProps> = ({ label, loading, icon, type = 'button', ...rest }) => {
  const { t } = useTranslation()

  return (
    <button
      className={styles.container}
      disabled={loading}
      type={type as 'button' | 'reset' | 'submit' | undefined}
      {...rest}
    >
      <div className={styles.iconContainer}>{getIcon(icon)}</div>
      <span className={styles.labelContainer}>{loading ? t('common.loading', 'Loading...' as string) : label}</span>
    </button>
  )
}
