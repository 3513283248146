import React from 'react'
import styles from './PointsPill.module.css'
import { ScoreIcon } from '../icons'
import classNames from 'classnames'

type PointsPillProps = {
  size: 'small' | 'medium' | 'large'
  label: string
  noIcon?: boolean
  state?: string
}

export const PointsPill: React.FC<PointsPillProps> = ({ size = 'small', label, noIcon, state }) => {
  return (
    <div
      className={classNames(styles.pointsPill, styles[`pointsPill_${size}`], state && styles[`pointsPill_${state}`])}
    >
      {!noIcon && (
        <ScoreIcon
          viewBox={size === 'small' ? '0 -1 12 14' : '0  0 12 13'}
          fontSize={size === 'small' ? '1rem' : '1.5rem'}
        />
      )}
      <label className={styles.text}>{label}</label>
    </div>
  )
}
