import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'
import { checkGameOrPlayerPinExists } from '../../../api/gameApiService'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { Select, SelectOption } from '../../../common/components/Select/Select'
import { Button } from '../../../common/components/button/Button'
import { LANGUAGE_SELECT_DEFAULT_OPTION, LANGUAGE_SELECT_OPTIONS } from '../../../common/constants'
import { useNotification } from '../../../contexts/NotificationContext'
import { DEFAULT_THEME_SETTINGS, useTheme } from '../../../contexts/ThemeContext'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import sharedStyles from '../Login.module.css'
import styles from './InstructorOrPlayer.module.css'
import { LoginCard } from './LoginCard'
import { parseCheckGameResponse } from '../../../api/typeConverters'
import { playerAccountLoginRequest, playerLoginRequest } from '../../../api/userApiService'
import { LoaderBlock } from '../../../common/components/loaders/LoaderBlock/LoaderBlock'
import { safeIsNullOrEmpty } from '../../../util/string'
import seppoBkgImage from '../../../assets/images/seppo_login_bkg.jpg'
import { getIcon } from '../../../common/components/icons/utils'
import { getOldUIRoutes } from '../../../routes'
import { ContinueGameBlockSmall } from './ContinueGameBlockSmall'
import { StatusPill } from '../../../common/components/StatusPill/StatusPill'
import { GameStatus } from '../../../types/commonTypes'

type LoginFormValues = {
  gameCode: string
  playerName?: string
  teamMemberNames?: string
  playerEmail?: string
  email?: string
  password?: string
}

type InstructorOrPlayerProps = {
  autofocusEnterCode: boolean
  shouldMirror: boolean
  onClickContinueWithSignup: () => void
}

enum PlayerLoginState {
  INITIAL = 'INITIAL',
  ENTER_NAME = 'ENTER_NAME',
  CONTINUE = 'CONTINUE',
  LTI = 'LTI',
  NAV_BACK = 'NAV_BACK',
  PLAYER_ACCOUNT = 'PLAYER_ACCOUNT',
}

export type LoginData = {
  gamePin?: string
  gameName?: string
  gameMapUrl?: string
  playerKey?: string
  playerName?: string
  teamMemberNames?: string
  playerEmail?: string
  timeStamp?: string
  gameOpen?: boolean
}

const NEWS_ROOM_BOARD = 'NewsRoomGameBoard'
const PREVIOUS_LOGIN_SAVING_MINUTES = 120

export const InstructorOrPlayer: React.FC<InstructorOrPlayerProps> = ({
  autofocusEnterCode = false,
  shouldMirror = false,
  onClickContinueWithSignup,
}) => {
  const { t, i18n } = useTranslation()
  const {
    logoUrl,
    backgroundImageUrl,
    rawCodeSectionTop,
    rawCodeSectionBottom,
    updateThemeSettings,
    hasCustomColors,
    loginLanguage,
    loginHideLanguageSelection,
    loginHideTeacherArea,
    loginBottomLogoUrl,
  } = useTheme()
  const [themeBackgroundStyle, setThemeBackgroundStyle] = useState<React.CSSProperties>(
    backgroundImageUrl
      ? { backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: 'cover' }
      : window.innerWidth > 500 && !hasCustomColors()
      ? { backgroundImage: `url(${seppoBkgImage})`, backgroundSize: 'cover' }
      : {},
  )

  const { fadeTransition } = useFadeAnimation()
  const { notifyError } = useNotification()
  const [playerLoginState, setPlayerLoginState] = useState(PlayerLoginState.INITIAL)
  const [requireTeamMemberNames, setRequireTeamMemberNames] = useState(false)
  const [requirePlayerEmail, setRequirePlayerEmail] = useState(false)
  const [ssoUrl, setSsoUrl] = useState<string>()
  const [ssoText, setSsoText] = useState<string>()
  const [previousLogin, setPreviousLogin] = useState<LoginData>({})
  const [nextLogin, setNextLogin] = useState<LoginData>({})
  const [loading, setLoading] = useState(false)
  const [topInitialText, setTopInitialText] = useState(t('login.title', 'What would you like to do?'))
  const [topWelcomeText, setWelcomeText] = useState(t('login.top_welcome', 'Welcome!'))
  const [topTextColor, setTopTextColor] = useState('#000000')
  const [topTextBackgroundColor, setTopTextBackgroundColor] = useState<string | null>(null)
  const [topTextFontSize, setTopTextFontSize] = useState<string | null>(null)
  const [QRGameName, setQRGameName] = useState<string | null>(null)
  const [isInitialLangSet, setIsInitialLangSet] = useState(true)
  const gameWasClosed = useRef(false)
  const [hideContinueGameBlockSmall, setHideContinueGameBlockSmall] = useState(false)
  const [playerEmailFromUrl, setPlayerEmailFromUrl] = useState<string | null>(null)
  const [playserSessionIdFromUrl, setPlayerSessionIdFromUrl] = useState<string | null>(null)
  const usesCustomTopText = useRef(false)
  const defaultLanguageOption = useMemo(() => {
    const newLang =
      LANGUAGE_SELECT_OPTIONS.find((option) => option.value === (loginLanguage || i18n.language)) ??
      LANGUAGE_SELECT_DEFAULT_OPTION
    return newLang
  }, [i18n.language, loginLanguage])
  const [currLanguageOption, setCurrLanguageOption] = useState(defaultLanguageOption)

  useEffect(() => {
    setCurrLanguageOption(defaultLanguageOption)
  }, [defaultLanguageOption])

  useEffect(() => {
    if (!usesCustomTopText.current) {
      setTopInitialText(t('login.title', 'What would you like to do?'))
      setWelcomeText(t('login.top_welcome', 'Welcome!'))
    }
  }, [t, i18n.language])

  //Detecting back navigation (From game welcome back to login)
  useEffect(() => {
    const navType = (window.performance.getEntriesByType('navigation')[0] as any).type
    if (navType === 'back_forward') {
      let prevLogin: LoginData = {}
      try {
        prevLogin = JSON.parse(window.localStorage.getItem('seppoPreviousLogin') + '')
        setPreviousLogin(prevLogin)
      } catch (e) {
        console.warn(e)
      }
      if (prevLogin?.playerKey) setPlayerLoginState(PlayerLoginState.NAV_BACK)
      else setPlayerLoginState(PlayerLoginState.INITIAL)
    }
  }, [previousLogin?.playerKey])

  //Detect whitelabel optinos from rawCodeTop
  useEffect(() => {
    //SSO
    const ssoLoginStart = rawCodeSectionTop?.indexOf('https://auth')
    if (ssoLoginStart && ssoLoginStart > -1) {
      setSsoUrl(rawCodeSectionTop?.substring(ssoLoginStart, rawCodeSectionTop.indexOf('"', ssoLoginStart)))
      setSsoText(
        rawCodeSectionTop?.substring(
          rawCodeSectionTop.indexOf('>', ssoLoginStart) + 1,
          rawCodeSectionTop.indexOf('<', ssoLoginStart),
        ),
      )
    } else {
      setSsoUrl(undefined)
    }

    //Custom text for the top message
    let START = 'WELCOME_TEXT="'
    const topTextStart = rawCodeSectionTop?.indexOf(START)
    if (topTextStart && topTextStart > -1) {
      const theText = rawCodeSectionTop?.substring(
        topTextStart + START.length,
        rawCodeSectionTop.indexOf('"', topTextStart + START.length),
      )
      if (theText) {
        usesCustomTopText.current = true
        setTopInitialText(theText)
        setWelcomeText(theText)
      }
    }

    //Custom text color for the top message
    START = 'WELCOME_COLOR="'
    const topColorStart = rawCodeSectionTop?.indexOf(START)
    if (topColorStart && topColorStart > -1) {
      const theColor = rawCodeSectionTop?.substring(
        topColorStart + START.length,
        rawCodeSectionTop.indexOf('"', topColorStart + START.length),
      )
      if (theColor) {
        setTopTextColor(theColor)
      }
    }

    //Custom text background color for the top message
    START = 'WELCOME_BACKGROUND_COLOR="'
    const topBackgroundColorStart = rawCodeSectionTop?.indexOf(START)
    if (topBackgroundColorStart && topBackgroundColorStart > -1) {
      const theBackgroundColor = rawCodeSectionTop?.substring(
        topBackgroundColorStart + START.length,
        rawCodeSectionTop.indexOf('"', topBackgroundColorStart + START.length),
      )
      if (theBackgroundColor) {
        setTopTextBackgroundColor(theBackgroundColor)
      }
    }

    START = 'WELCOME_FONT_SIZE="'
    const topTextFontSizeStart = rawCodeSectionTop?.indexOf(START)
    if (topTextFontSizeStart && topTextFontSizeStart > -1) {
      const theFontSize = rawCodeSectionTop?.substring(
        topTextFontSizeStart + START.length,
        rawCodeSectionTop.indexOf('"', topTextFontSizeStart + START.length),
      )
      if (theFontSize) {
        setTopTextFontSize(theFontSize)
      }
    }
  }, [rawCodeSectionTop])

  useEffect(() => {
    if (backgroundImageUrl)
      setThemeBackgroundStyle(
        backgroundImageUrl
          ? {
              background: `linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 100%), url(${backgroundImageUrl}) lightgray 50%`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          : {},
      )
    else if (window.innerWidth > 500 && !hasCustomColors())
      setThemeBackgroundStyle({ backgroundImage: `url(${seppoBkgImage})`, backgroundSize: 'cover' })
    else setThemeBackgroundStyle({ backgroundColor: 'white' })
  }, [backgroundImageUrl, hasCustomColors])

  //const handleLtiPlayerSubmit = useCallback(
  useEffect(() => {
    const run = async () => {
      const searchParams = new URLSearchParams(window.location.search)
      const ltiPin = searchParams.get('lti_pin')
      if (!ltiPin) return
      setLoading(true)
      const checkStatus = await checkGameOrPlayerPinExists(null, ltiPin)
      if (checkStatus.success && checkStatus.value.msg === 'OK') {
        const response = parseCheckGameResponse(checkStatus.value)
        if (!response.wasGameCode) {
          setPreviousLogin({
            gamePin: response.gamePin,
            playerKey: ltiPin,
            playerName: response.playerName,
            gameName: response.gameName,
            gameMapUrl: response.gameMapUrl,
            timeStamp: new Date().toISOString(),
            gameOpen: response.open,
          })
          setLoading(false)
          setPlayerLoginState(PlayerLoginState.LTI)
          window.history.replaceState(null, 'Seppo', '/login')
        }
      }
    }
    run()
  }, [])

  const handleOnInitialSubmit = useCallback(
    async (values: LoginFormValues) => {
      if ((values.gameCode ?? '').length < 1) {
        return
      }
      const checkStatus = await checkGameOrPlayerPinExists(null, values.gameCode)
      if (checkStatus.success && checkStatus.value.msg === 'OK') {
        const response = parseCheckGameResponse(checkStatus.value)
        if (!response.wasGameCode) {
          //Player code entered
          setPreviousLogin({
            gamePin: response.gamePin,
            playerKey: values.gameCode,
            playerName: response.playerName,
            gameName: response.gameName,
            gameMapUrl: response.gameMapUrl,
            timeStamp: new Date().toISOString(),
          })
          setLoading(true)
          if (response.is3dGame) {
            if (response.gameMapUrl.includes(NEWS_ROOM_BOARD))
              window.location.replace(
                `${process.env.REACT_APP_3D_NEWS_ROOM_BASE_URL}/?pk=${encodeURIComponent(values.gameCode)}`,
              )
            else
              window.location.replace(
                `${process.env.REACT_APP_3D_ESG_CITY_BASE_URL}/?pk=${encodeURIComponent(values.gameCode)}`,
              )
          } else if (response.useNewPlayerUI) {
            window.location.replace(
              `${process.env.REACT_APP_OLD_UI_BASE_URL}/player?pin_code=${encodeURIComponent(
                values.gameCode,
              )}&force_restart=true`,
            )
          } else {
            window.location.replace(
              `${process.env.REACT_APP_OLD_UI_BASE_URL}/bridge_me?user_session=${encodeURIComponent(
                values.gameCode,
              )}&target=%2Fplay%3Fgame_id%3D${response.gameId}`,
            )
          }
        } else {
          //Game code
          // If the user is in a state of continiung a game, then do not accept a gamecode here
          if (playerLoginState === PlayerLoginState.CONTINUE || playerLoginState === PlayerLoginState.NAV_BACK) {
            notifyError({
              title: t('login.login_game_pin_wrong_state.title', 'Invalid code'),
              content: t(
                'login.login_game_pin_wrong_state.content',
                'Please enter your player code (Player1234) to continue your game. Forgot your player code? Contact your instructor.',
              ),
              timeout: 15000,
              allowClose: true,
            })
            return
          }
          if (response.theme) {
            updateThemeSettings(response.theme)
          } else {
            updateThemeSettings(DEFAULT_THEME_SETTINGS)
          }
          if (response.open) {
            setPlayerLoginState(PlayerLoginState.ENTER_NAME)
            setRequirePlayerEmail(response.requirePlayerEmail)
            setRequireTeamMemberNames(response.requireTeamMemberNames)
            setNextLogin({
              gamePin: values.gameCode,
              gameName: response.gameName,
              gameMapUrl: response.gameMapUrl,
            })
          } else {
            if (!gameWasClosed.current) {
              notifyError({
                title: t('login.login_failed_game_not_open.title', 'Game closed'),
                content: t(
                  'login.login_failed_game_not_open.content',
                  'Please wait for the instructor to start the game.',
                ),
                timeout: 5000,
                allowClose: false,
              })
            }
            gameWasClosed.current = true
          }
          setLoading(false)
        }
      } else {
        notifyError({
          title: t('login.login_failed_error_notification.title'),
          content: t('login.login_failed_error_notification.content'),
          timeout: 5000,
          allowClose: false,
        })
        setLoading(false)
      }
    },
    [notifyError, playerLoginState, t, updateThemeSettings],
  )

  const handleOnNameSubmit = useCallback(
    async (values: LoginFormValues) => {
      if (!values.playerName || (values.playerName ?? '').length < 1) {
        return
      }
      //If the name appears to have more than 2 digits, then assume trying to re-login
      const effectiveLength = values.playerName.replace(/\d+$/, '').length
      if (values.playerName.length - effectiveLength > 2) {
        handleOnInitialSubmit({ gameCode: values.playerName })
        return
      }

      const loginResponse = await playerLoginRequest({
        pinCode: nextLogin.gamePin ?? '',
        emailSessionId: playserSessionIdFromUrl ?? undefined,
        teamName: values.playerName,
        teamMemberNames: values.teamMemberNames,
        language: i18n.language,
        email: values.playerEmail,
      })
      if (loginResponse.success) {
        if (loginResponse.value.msg === 'OK' && loginResponse.value.player_key) {
          //Player code received, enter game
          setPreviousLogin((prev) => {
            return {
              ...(nextLogin ? nextLogin : {}),
              playerKey: loginResponse.value.player_key,
              playerName: values.playerName,
              timeStamp: new Date().toISOString(),
            }
          })
          setLoading(true)
          if (loginResponse.value.is_3d_game) {
            if (loginResponse.value.game_map_url.includes(NEWS_ROOM_BOARD))
              window.location.replace(
                `${process.env.REACT_APP_3D_NEWS_ROOM_BASE_URL}/?pk=${encodeURIComponent(
                  loginResponse.value.player_key,
                )}`,
              )
            else
              window.location.replace(
                `${process.env.REACT_APP_3D_ESG_CITY_BASE_URL}/?pk=${encodeURIComponent(
                  loginResponse.value.player_key,
                )}`,
              )
          } else if (loginResponse.value.use_new_player_ui) {
            window.location.replace(
              `${process.env.REACT_APP_OLD_UI_BASE_URL}/player?pin_code=${encodeURIComponent(
                loginResponse.value.player_key,
              )}&force_restart=true`,
            )
          } else {
            window.location.replace(
              `${process.env.REACT_APP_OLD_UI_BASE_URL}/bridge_me?user_session=${encodeURIComponent(
                loginResponse.value.player_key,
              )}&target=%2Fplay%3Fgame_id%3D${loginResponse.value.game_id}`,
            )
          }
        } else {
          notifyError({
            title: t('login.login_failed_error_notification.title'),
            content:
              loginResponse.value.msg === 'DUPLICATE'
                ? t('login.login_failed_duplicate', 'The player name is already in use')
                : loginResponse.value.msg,
            timeout: 5000,
            allowClose: false,
          })
        }
      } else {
        notifyError({
          title: t('login.login_failed_error_notification.title'),
          content: t('login.login_failed_error_notification.content'),
          timeout: 5000,
          allowClose: false,
        })
      }
    },
    [handleOnInitialSubmit, i18n.language, nextLogin, notifyError, playserSessionIdFromUrl, t],
  )

  const handleOnPlayerAccountSubmit = useCallback(
    async (values: LoginFormValues) => {
      if (!values.email || (values.email ?? '').length < 1 || !values.password || (values.password ?? '').length < 1) {
        return
      }
      const loginResponse = await playerAccountLoginRequest({
        language: i18n.language,
        email: values.email,
        password: values.password,
      })
      if (loginResponse.success) {
        if (loginResponse.value.msg === 'OK') {
          localStorage.setItem('sessionKey', (loginResponse.value as any).session_id)
          setLoading(true)
          window.location.replace(getOldUIRoutes.oldDashboard())
        } else {
          notifyError({
            title: t('login.login_failed_error_notification.title'),
            content: loginResponse.value.msg + '',
            timeout: 5000,
            allowClose: false,
          })
        }
      } else {
        notifyError({
          title: t('login.login_failed_error_notification.title'),
          content: t('login.login_failed_error_notification.content'),
          timeout: 5000,
          allowClose: false,
        })
      }
    },
    [i18n.language, notifyError, t],
  )

  const handlePlayerAccountForgotPassword = useCallback(() => {
    window.location.replace(getOldUIRoutes.playerAccountPasswordReset(i18n.language))
  }, [i18n.language])

  const handleSetHideContinueGameBlockSmall = useCallback(() => {
    setHideContinueGameBlockSmall(!hideContinueGameBlockSmall)
  }, [hideContinueGameBlockSmall])

  //Handle direct pin in Url
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const gamePin = searchParams.get('pin')
    setPlayerEmailFromUrl(searchParams.get('player_email'))
    setPlayerSessionIdFromUrl(searchParams.get('session'))
    if (gamePin) {
      const lang = searchParams.get('l')
      if (lang) {
        if (isInitialLangSet) {
          i18n.changeLanguage(lang || 'en')
          setIsInitialLangSet(false)
        }
      } else {
        i18n.changeLanguage(loginLanguage)
      }
      if (nextLogin.gamePin !== gamePin && !gameWasClosed.current) {
        setLoading(true)
        handleOnInitialSubmit({ gameCode: gamePin })
      } else {
        setQRGameName(nextLogin.gameName ?? null)
      }
    }
  }, [handleOnInitialSubmit, i18n, nextLogin, isInitialLangSet, loginLanguage, gameWasClosed])

  //Auto store previousLogin when changed
  useEffect(() => {
    if (previousLogin.playerKey) {
      window.localStorage.setItem('seppoPreviousLogin', JSON.stringify(previousLogin))
    }
  }, [previousLogin])

  const handleResetPreviousLogin = useCallback(() => {
    setPreviousLogin({})
    window.localStorage.setItem('seppoPreviousLogin', JSON.stringify({}))
  }, [])

  const handleContinuePrevious = useCallback(() => {
    if (previousLogin.playerKey) handleOnInitialSubmit({ gameCode: previousLogin.playerKey })
  }, [handleOnInitialSubmit, previousLogin.playerKey])

  const handleClickGoToPlayerLogin = () => {
    setPlayerLoginState(PlayerLoginState.PLAYER_ACCOUNT)
  }

  const handleChangeLanguage = (selectOption: SingleValue<SelectOption>) => {
    i18n.changeLanguage(selectOption?.value)
    setCurrLanguageOption(selectOption || defaultLanguageOption)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const gamePin = searchParams.get('pin')
    if (!gamePin) {
      i18n.changeLanguage(loginLanguage)
    }
  }, [i18n, loginLanguage])

  useEffect(() => {
    try {
      const temp = JSON.parse(window.localStorage.getItem('seppoPreviousLogin') + '') as LoginData
      const currTime = new Date()
      if (temp && new Date(temp.timeStamp + '') > new Date(currTime.getTime() - PREVIOUS_LOGIN_SAVING_MINUTES * 60000))
        setPreviousLogin(temp)
      else handleResetPreviousLogin()
    } catch (e) {
      console.warn(e)
    }
  }, [handleResetPreviousLogin, playerLoginState])

  const handleResetTheme = useCallback(() => {
    updateThemeSettings(DEFAULT_THEME_SETTINGS)
  }, [updateThemeSettings])

  const showContinueGameBlock = useMemo(() => {
    return (
      (playerLoginState === PlayerLoginState.ENTER_NAME &&
        previousLogin?.playerKey &&
        previousLogin.gamePin === nextLogin.gamePin) ||
      ((playerLoginState === PlayerLoginState.CONTINUE || playerLoginState === PlayerLoginState.NAV_BACK) &&
        previousLogin?.playerKey)
    )
  }, [nextLogin.gamePin, playerLoginState, previousLogin.gamePin, previousLogin?.playerKey])

  const showContinueGameBlockSmall = useMemo(() => {
    return playerLoginState === PlayerLoginState.ENTER_NAME && showContinueGameBlock && !hideContinueGameBlockSmall
  }, [hideContinueGameBlockSmall, playerLoginState, showContinueGameBlock])

  const topTitle = useMemo(() => {
    return playerLoginState === PlayerLoginState.INITIAL
      ? topInitialText
      : playerLoginState === PlayerLoginState.LTI
      ? t('login.top_lti', 'Ready to enter the game?')
      : playerLoginState === PlayerLoginState.CONTINUE
      ? t('login.top_continue', 'Ready to continue playing?')
      : playerLoginState === PlayerLoginState.NAV_BACK
      ? t('login.top_nav_back', 'Exited the game by accident?')
      : playerLoginState === PlayerLoginState.PLAYER_ACCOUNT
      ? t('login.top_player_account', 'Start playing with your player account')
      : playerLoginState === PlayerLoginState.ENTER_NAME && showContinueGameBlockSmall
      ? t('login.continue_existing_game.title', 'What would you like to do?')
      : topWelcomeText
  }, [playerLoginState, showContinueGameBlockSmall, t, topInitialText, topWelcomeText])

  const cardTitle = useMemo(() => {
    return playerLoginState === PlayerLoginState.INITIAL
      ? t('login.join_existing_game.title', 'Join an existing game')
      : playerLoginState === PlayerLoginState.CONTINUE
      ? undefined
      : playerLoginState === PlayerLoginState.NAV_BACK
      ? t('login.back_to_game.title', "We've saved your login below")
      : playerLoginState === PlayerLoginState.PLAYER_ACCOUNT
      ? t('login.player_account.title', 'Login with player account')
      : playerLoginState === PlayerLoginState.ENTER_NAME && showContinueGameBlockSmall
      ? t('login.continue_existing_game.new_game.title', 'Start a new game')
      : undefined
  }, [playerLoginState, showContinueGameBlockSmall, t])

  const continueGameBlock = useMemo(() => {
    return (
      <>
        <div className={styles.enterCodeColumn}>
          <div className={styles.previousGameCard}>
            <div
              className={styles.previousGameCardTop}
              style={{ backgroundImage: `url('${previousLogin.gameMapUrl}')` }}
            />
            <div className={styles.previousGameCardBottom}>
              <h5 className={styles.gameTitle}>{previousLogin.gameName}</h5>
              <p style={{ marginBottom: '10px' }}>
                {t('login.continue_player', 'Player:') + ' ' + previousLogin.playerName}
              </p>
              <Button
                variant='outline-tiny'
                type='button'
                //className={styles.loginWithIdButton}
                onClick={handleContinuePrevious}
              >
                {t('login.continue.enter_previous_button', 'Continue this game')}
              </Button>
            </div>
          </div>
          <Button
            variant='borderless-normal'
            type='button'
            //className={styles.loginWithIdButton}
            onClick={handleResetPreviousLogin}
          >
            {t('login.reset_previous_login', 'Reset previous login')}
          </Button>
        </div>
        <div className={classNames(styles.enterCodeColumn, styles.narrowSwitchToRow)} style={{ alignItems: 'center' }}>
          <div className={styles.dividerLineVertical} />
          <div>{t('login.enter_code.or', 'Or').toLocaleUpperCase()}</div>
          <div className={styles.dividerLineVertical} />
        </div>
      </>
    )
  }, [
    handleContinuePrevious,
    handleResetPreviousLogin,
    previousLogin.gameMapUrl,
    previousLogin.gameName,
    previousLogin.playerName,
    t,
  ])

  const onClickSsoLogin = useCallback(() => {
    if (ssoUrl) window.location.replace(ssoUrl)
  }, [ssoUrl])

  if (loading) {
    return (
      <div className={classNames(styles.backgroundContainer, fadeTransition)} style={themeBackgroundStyle}>
        {/*<img src={logoUrl} alt='Seppo' height={26} className={styles.seppoLogo} />*/}
        {loading && <LoaderBlock />}
      </div>
    )
  }

  return (
    <div className={classNames(styles.backgroundContainer, fadeTransition)} style={themeBackgroundStyle}>
      <img
        onClick={handleResetTheme}
        src={logoUrl}
        alt='Seppo'
        height={logoUrl.includes('Seppo_logo') ? 26 : undefined}
        className={styles.seppoLogo}
      />
      {!loginHideLanguageSelection && (
        <Select
          options={LANGUAGE_SELECT_OPTIONS}
          defaultValue={
            loginLanguage
              ? LANGUAGE_SELECT_OPTIONS.find((option) => option.value === loginLanguage) ?? defaultLanguageOption
              : defaultLanguageOption
          }
          value={currLanguageOption}
          onChange={handleChangeLanguage}
          className={classNames(styles.languageSelect, styles.hideOnLargeScreen)}
        />
      )}
      <h1
        className={styles.title}
        style={{
          color: topTextColor,
          ...(topTextFontSize && { fontSize: topTextFontSize }),
          ...(topTextBackgroundColor && { backgroundColor: topTextBackgroundColor, padding: '10px 20px' }),
        }}
      >
        {' '}
        {QRGameName && <div style={{ marginBottom: 20 }}>{QRGameName}</div>}
        {topTitle}
      </h1>
      <div
        className={classNames(
          styles.cardsContainer,
          showContinueGameBlockSmall && styles.cardsContainershowContinueGameBlockSmall,
        )}
      >
        {showContinueGameBlockSmall && (
          <LoginCard
            onBack={() => {
              setPlayerLoginState(PlayerLoginState.INITIAL)
              setHideContinueGameBlockSmall(false)
            }}
            iconLabel={t('common.back')}
            icon='arrowLeft'
            title={t('login.continue_existing_game.continue.title', 'Continue game')}
            variant={'twoCards'}
          >
            <ContinueGameBlockSmall
              variant='small'
              previousLogin={previousLogin}
              handleContinuePrevious={handleContinuePrevious}
              handleResetPreviousLogin={handleResetPreviousLogin}
            />
          </LoginCard>
        )}
        <LoginCard
          big={!ssoUrl && !showContinueGameBlockSmall}
          nonResponsiveBig={playerLoginState !== PlayerLoginState.INITIAL && !showContinueGameBlockSmall}
          removeMinHeight={!!showContinueGameBlockSmall}
          onBack={
            playerLoginState === PlayerLoginState.INITIAL || playerLoginState === PlayerLoginState.LTI
              ? undefined
              : () => {
                  setPlayerLoginState(PlayerLoginState.INITIAL)
                  setHideContinueGameBlockSmall(false)
                }
          }
          iconLabel={
            playerLoginState === PlayerLoginState.INITIAL || playerLoginState === PlayerLoginState.LTI
              ? undefined
              : showContinueGameBlockSmall
              ? undefined
              : t('common.back')
          }
          icon={
            playerLoginState === PlayerLoginState.INITIAL || playerLoginState === PlayerLoginState.LTI
              ? 'door'
              : showContinueGameBlockSmall
              ? 'plus'
              : 'arrowLeft'
          }
          title={cardTitle}
          variant={showContinueGameBlockSmall ? 'twoCards' : 'oneCard'}
          onBackDisabled={showContinueGameBlockSmall ? true : false}
        >
          {playerLoginState === PlayerLoginState.INITIAL && (
            <>
              <p className={classNames(styles.cardBodyText)}>
                {t('login.join_existing_game.input.label', 'Enter your code')}
              </p>
              <div className={styles.layoutAlignmentWrapper}>
                <Form<LoginFormValues> onSubmit={handleOnInitialSubmit}>
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={styles.loginWithGameCode}>
                      <div className={!ssoUrl ? styles.enterCodeRow : styles.enterCodeColumn}>
                        <InputFormField
                          label={t('login.join_existing_game.input.label', 'Enter your code')}
                          placeholder={t('login.join_existing_game.input.placeholder', 'Type code')}
                          srOnlyLabel
                          autoFocus={autofocusEnterCode}
                          name='gameCode'
                          type='text'
                          className={styles.codeInputField}
                          inputContainerClassName={styles.codeInputFieldContainer}
                          onKeyDown={() => {
                            gameWasClosed.current = false
                          }}
                          big
                        />
                        <Button type='submit' className={sharedStyles.bigBlockButton}>
                          {t('login.enter_code.button_enter_game', 'Enter game')}
                        </Button>
                      </div>
                      <div
                        className={classNames(!ssoUrl ? styles.enterCodeRow : styles.enterCodeColumn, styles.alwaysRow)}
                        style={{ alignItems: 'center' }}
                      >
                        <div className={styles.dividerLine} />
                        <div>{t('login.enter_code.or', 'Or').toLocaleUpperCase()}</div>
                        <div className={styles.dividerLine} />
                      </div>
                      <div className={!ssoUrl ? styles.enterCodeRow : styles.enterCodeColumn}>
                        <Button
                          variant='outline-normal'
                          type='button'
                          onClick={() => {
                            setPlayerLoginState(PlayerLoginState.CONTINUE)
                          }}
                        >
                          {t('login.enter_code.continue_existing_button', 'Continue existing game')}
                        </Button>
                        <Button
                          variant='borderless-normal'
                          type='button'
                          //className={styles.loginWithIdButton}
                          onClick={handleClickGoToPlayerLogin}
                        >
                          {t('login.join_existing_game.login_with_player_id', 'Log in with player id')}
                        </Button>
                      </div>
                    </form>
                  )}
                </Form>
              </div>
            </>
          )}

          {playerLoginState === PlayerLoginState.ENTER_NAME && (
            <div className={classNames(styles.layoutAlignmentWrapper, showContinueGameBlock)}>
              {showContinueGameBlockSmall && (
                <>
                  <p className={classNames(styles.cardBodyText, styles.showContinueGameBlockSmallcardBodyText)}>
                    {t('login.continue_existing_game.new_game.body_text', 'Begin new session')}
                  </p>
                  <Button variant='outline-normal' type='button' onClick={handleSetHideContinueGameBlockSmall}>
                    {t('login.continue_existing_game.new_game.button', 'Start new game')}
                  </Button>
                </>
              )}
              {!showContinueGameBlockSmall && (
                <Form<LoginFormValues> onSubmit={handleOnNameSubmit}>
                  {({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit} className={styles.loginWithGameCode}>
                      <div className={styles.enterCodeColumn}>
                        <div className={classNames(styles.cardBodyTextNoMargin)}>
                          {t('login.join_existing_game.welcome_input_name.label', "What's your name?")}
                        </div>
                        <InputFormField
                          label={t('login.join_game.input_name.label', 'Enter your name')}
                          placeholder={t('login.join_game.input_name.placeholder', 'Type here')}
                          srOnlyLabel
                          autoFocus={autofocusEnterCode}
                          name='playerName'
                          type='text'
                          inputContainerClassName={styles.codeInputFieldContainer}
                          maxLength={20}
                          big
                        />
                        {requirePlayerEmail && (
                          <>
                            <div className={classNames(styles.cardBodyTextNoMargin)}>
                              {t('login.join_game.input_player_email.label', 'Enter also your email address')}
                            </div>
                            <InputFormField
                              label={t('login.join_game.input_player_email.label', 'Enter also your email address')}
                              placeholder={t('login.join_game.input_player_email.placeholder', 'Email')}
                              srOnlyLabel
                              autoFocus={autofocusEnterCode}
                              name='playerEmail'
                              type='text'
                              defaultValue={playerEmailFromUrl ?? ''}
                              inputContainerClassName={styles.codeInputFieldContainer}
                              big
                            />
                          </>
                        )}
                        {requireTeamMemberNames && (
                          <>
                            <p className={classNames(styles.cardBodyTextNoMargin)}>
                              {t('login.join_game.input_team_member_names.label', "Who's in the team")}
                            </p>
                            <InputFormField
                              label={t('login.join_game.input_team_member_names.label', "Who's in the team")}
                              placeholder={t(
                                'login.join_game.input_team_member_names.placeholder',
                                'Team member names',
                              )}
                              srOnlyLabel
                              autoFocus={autofocusEnterCode}
                              name='teamMemberNames'
                              type='text'
                              inputContainerClassName={styles.codeInputFieldContainer}
                              big
                            />
                          </>
                        )}
                        <Button
                          type='submit'
                          className={sharedStyles.bigBlockButton}
                          disabled={
                            safeIsNullOrEmpty(values.playerName) ||
                            (requirePlayerEmail &&
                              (safeIsNullOrEmpty(values.playerEmail) || !values.playerEmail?.includes('@'))) ||
                            (requireTeamMemberNames && safeIsNullOrEmpty(values.teamMemberNames))
                          }
                        >
                          {t('login.enter_code.button_start_game', 'Start game')}
                        </Button>
                      </div>
                    </form>
                  )}
                </Form>
              )}
            </div>
          )}

          {(playerLoginState === PlayerLoginState.CONTINUE || playerLoginState === PlayerLoginState.NAV_BACK) && (
            <>
              <div
                className={classNames(
                  styles.layoutAlignmentWrapper,
                  showContinueGameBlock && styles.layoutAlignmentWrapperRowMode,
                )}
              >
                {showContinueGameBlock && continueGameBlock}
                <Form<LoginFormValues> onSubmit={handleOnInitialSubmit}>
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={styles.loginWithGameCode}>
                      <p className={classNames(styles.cardBodyText)} style={{ marginBottom: 0 }}>
                        {t('login.continue.enter_player_code', 'Enter your player code')}
                      </p>
                      <div className={styles.enterCodeColumn}>
                        <InputFormField
                          label={t('login.continue_existing_game.input.label', 'Enter your player code')}
                          placeholder={t('login.continue_existing_game.input.placeholder', 'Type here')}
                          srOnlyLabel
                          autoFocus={autofocusEnterCode}
                          name='gameCode'
                          type='text'
                          /*className={styles.codeInputField}*/
                          inputContainerClassName={styles.codeInputFieldContainer}
                          big
                        />
                        <Button type='submit' className={sharedStyles.bigBlockButton}>
                          {t('login.enter_code.button_start_game', 'Start game')}
                        </Button>
                      </div>
                    </form>
                  )}
                </Form>
              </div>
            </>
          )}

          {playerLoginState === PlayerLoginState.LTI && (
            <>
              <div
                className={classNames(
                  styles.layoutAlignmentWrapper,
                  showContinueGameBlock && styles.layoutAlignmentWrapperRowMode,
                )}
              >
                <div className={styles.enterCodeColumn}>
                  <div className={styles.previousGameCard}>
                    <div
                      className={styles.previousGameCardTop}
                      style={{ backgroundImage: `url('${previousLogin.gameMapUrl}')` }}
                    />
                    <div className={styles.statusPillRow}>
                      <StatusPill
                        status={previousLogin.gameOpen ? GameStatus.IN_PROGRESS : GameStatus.PAUSED}
                        variant={'card'}
                      />
                    </div>
                    <div className={styles.previousGameCardBottom}>
                      <h5 className={styles.gameTitle}>{previousLogin.gameName}</h5>
                      <Button
                        variant='normal'
                        type='button'
                        //className={styles.loginWithIdButton}
                        onClick={handleContinuePrevious}
                      >
                        {t('login.player_lti.start_game', 'Launch the game')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {playerLoginState === PlayerLoginState.PLAYER_ACCOUNT && (
            <>
              <div className={styles.layoutAlignmentWrapper}>
                <Form<LoginFormValues> onSubmit={handleOnPlayerAccountSubmit}>
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={styles.loginWithGameCode}>
                      <div className={styles.enterCodeColumn}>
                        <InputFormField
                          label={t('login.player_account.input_email', 'Email')}
                          placeholder={t('login.player_account.input_email_placeholder', 'Email')}
                          srOnlyLabel
                          autoFocus={autofocusEnterCode}
                          name='email'
                          type='text'
                          /*className={styles.codeInputField}*/
                          inputContainerClassName={styles.codeInputFieldContainer}
                          big
                        />
                        <InputFormField
                          label={t('login.player_account.input_password', 'Password')}
                          placeholder={t('login.player_account.input_password_placeholder', 'Password')}
                          srOnlyLabel
                          autoFocus={autofocusEnterCode}
                          name='password'
                          type='text'
                          /*className={styles.codeInputField}*/
                          inputContainerClassName={styles.codeInputFieldContainer}
                          big
                        />
                        <div className={styles.enterCodeRow}>
                          <Button
                            variant='borderless-normal'
                            type='button'
                            style={{ marginLeft: 'auto', color: 'var(--grey-600)' }}
                            //className={styles.loginWithIdButton}
                            onClick={handlePlayerAccountForgotPassword}
                          >
                            {t('login.player_account.forgot_password_button', 'Forgot password')}
                          </Button>
                          <Button type='submit' style={{ minWidth: '200px' }}>
                            <span className={styles.playerAccountLoginIcon}>{getIcon('profileCircled')}</span>
                            {t('login.player_account.login_button', 'Login')}
                          </Button>
                        </div>
                      </div>
                    </form>
                  )}
                </Form>
              </div>
            </>
          )}
        </LoginCard>
        {playerLoginState === PlayerLoginState.INITIAL && (ssoUrl || !loginHideTeacherArea) && (
          <div className={styles.instructorLogins}>
            {ssoUrl && (
              <LoginCard smallDeviceStyles removeMinHeight icon='lockGradient' title={t('login.sso.title', 'SSO')}>
                <p className={styles.hideOnLargeScreen}>
                  {t(
                    'login.create_games.small_device.body1',
                    'Your window size is too small to use the instructor interface.',
                  )}
                </p>
                <p className={styles.hideOnLargeScreen}>
                  {t(
                    'login.create_games.small_device.body2',
                    'Please enlarge it or switch to a larger device to continue.',
                  )}
                </p>
                <span className={styles.hideOnSmallScreen}>
                  <p className={classNames(styles.minTwoLinesHeight, styles.cardBodyText)}>{ssoText}</p>
                  <div className={styles.layoutAlignmentWrapper}>
                    <Button className={sharedStyles.bigBlockButton} onClick={onClickSsoLogin}>
                      {t('login.sso.button_text', 'Login')}
                    </Button>
                  </div>
                </span>
              </LoginCard>
            )}
            {!loginHideTeacherArea && (
              <LoginCard
                smallDeviceStyles
                removeMinHeight={!!ssoUrl && !loginHideTeacherArea}
                icon='text'
                title={t('login.create_games.title', 'Create games')}
              >
                <p className={classNames(styles.cardBodyText, styles.minTwoLinesHeight, styles.hideOnSmallScreen)}>
                  {t('login.create_games.body', "Let's get signed up and make something awesome")}
                </p>
                <div className={styles.layoutAlignmentWrapper}>
                  <p className={styles.hideOnLargeScreen}>
                    {t(
                      'login.create_games.small_device.body1',
                      'Your window size is too small to use the instructor interface.',
                    )}
                  </p>
                  <p className={styles.hideOnLargeScreen}>
                    {t(
                      'login.create_games.small_device.body2',
                      'Please enlarge it or switch to a larger device to continue.',
                    )}
                  </p>
                  <span className={styles.hideOnSmallScreen}>
                    <Button className={sharedStyles.bigBlockButton} onClick={onClickContinueWithSignup}>
                      {t('login.create_games.button_text', 'Continue')}
                    </Button>
                  </span>
                </div>
              </LoginCard>
            )}
          </div>
        )}
      </div>
      {loginBottomLogoUrl && (
        <div className={styles.bottomCodeArea}>
          <img className={styles.bottomLogo} alt='' src={loginBottomLogoUrl} />
        </div>
      )}
      {!safeIsNullOrEmpty(rawCodeSectionBottom) && (
        <div
          className={styles.bottomCodeArea}
          dangerouslySetInnerHTML={{ __html: rawCodeSectionBottom as TrustedHTML }}
        />
      )}
    </div>
  )
}
