import { useTranslation } from 'react-i18next'
import { safeIsNullOrEmpty } from '../../../../util/string'
import styles from '../BillingSettings.module.css'

type OrganisationDetailsProps = {
  contactPerson: string
  vat?: string
}

export const OrganisationDetails: React.FC<OrganisationDetailsProps> = ({ contactPerson, vat }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.subSection}>
      <h3 className={styles.subSectionTitle}>
        {t('settings_billing.organisation_details_title', 'Organisation details')}
      </h3>
      <div className={styles.detailsList}>
        <p>
          {t('settings_billing.organisation_details_contact_person', {
            defaultValue: 'Contact person: %{contact_name}',
            contact_name: contactPerson,
          })}
        </p>
        {!safeIsNullOrEmpty(vat) && (
          <p>
            {t('settings_billing.organisation_details_vat', {
              defaultValue: 'VAT number: %{vat}',
              vat,
            })}
          </p>
        )}
      </div>
    </div>
  )
}
