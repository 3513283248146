export const openNinchatForm = () => {
  if (document.getElementById('ninchat_seppo_script') == null) {
    var ninchatScript = document.createElement('script')
    ninchatScript.setAttribute('id', 'ninchat_seppo_script')
    ninchatScript.src = 'https://ninchat.com/customer/seppo/init.js'
    document.getElementsByTagName('head')[0].appendChild(ninchatScript)
  }
  const NEEDED_DELAYS = 500
  document.getElementById('ninchat-chat')?.remove()
  setTimeout(() => {
    window.NinchatAsyncInit()
    setTimeout(() => {
      window.Ninchat.start('ninchat-form')
      window.Ninchat.state('open', 'ninchat-form')
    }, NEEDED_DELAYS)
  }, NEEDED_DELAYS)
}

export const openNinchat = (toggleDisplayMenu?: () => void) => {
  if (document.getElementById('ninchat_seppo_script') == null) {
    var ninchatScript = document.createElement('script')
    ninchatScript.setAttribute('id', 'ninchat_seppo_script')
    ninchatScript.src = 'https://ninchat.com/customer/seppo/init.js'
    document.getElementsByTagName('head')[0].appendChild(ninchatScript)
  }
  const NEEDED_DELAYS = 500
  document.getElementById('ninchat-form')?.remove()
  setTimeout(() => {
    window.NinchatAsyncInit()
    setTimeout(() => {
      document.getElementById('ninchat-form')?.remove()
      window.Ninchat.start('ninchat-chat')
      window.Ninchat.state('open', 'ninchat-chat')
    }, NEEDED_DELAYS)
    if (toggleDisplayMenu != null) {
      toggleDisplayMenu()
    }
  }, NEEDED_DELAYS)
}
