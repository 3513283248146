import { AnswerStateEnum, CheckboxAnswerOption, ReceivedAnswer } from '../../../../../../../../../types/commonTypes'
import { isNullOrZero } from '../../../../../../../../../util/number'
import styles from '../AnswerOptionLayout.module.css'
import { TimeExceededInfo } from '../TimeExceededInfo'
import { ChoiceAnswer } from './ChoiceAnswer'

type CheckboxAnswerProps = {
  answer: ReceivedAnswer
  answerOptions: CheckboxAnswerOption[]
  noPointsGame?: boolean
}

export const CheckboxAnswer: React.FC<CheckboxAnswerProps> = ({ answer, answerOptions, noPointsGame }) => {
  if (isNullOrZero(answer.answerIndexes?.length)) {
    return <TimeExceededInfo />
  }
  return (
    <div className={styles.answerOptionsTable}>
      {answerOptions.map((option, index) => (
        <ChoiceAnswer
          key={`${answer.id}_${index}`}
          text={option.text}
          imageUrl={option.imageUrl}
          points={option.points}
          isCorrectAnswer={noPointsGame ? option.isCorrectAnswer : (option.points ?? 0) > 0}
          wasSelected={answer.answerIndexes?.includes(index) ?? false}
          hidePoints={noPointsGame || answer.state === AnswerStateEnum.TIME_FINISHED}
        />
      ))}
    </div>
  )
}
