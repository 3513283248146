import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { AnswerStateEnum, ReceivedAnswer } from '../../../../../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../../../../../util/string'
import sharedStyles from '../AnswerOptionLayout.module.css'
import { TimeExceededInfo } from '../TimeExceededInfo'
import styles from './CreativeAnswer.module.css'

type CreativeAnswerProps = {
  answer: ReceivedAnswer
}

const hasContent = (rawHtml: string) => {
  const element = document.createElement('div')
  element.innerHTML = rawHtml
  return (
    !safeIsNullOrEmpty(element.textContent) ||
    element.querySelector('img') != null ||
    element.querySelector('audio') != null ||
    element.querySelector('video') != null
  )
}

export const CreativeAnswer: React.FC<CreativeAnswerProps> = ({ answer }) => {
  if (answer.state === AnswerStateEnum.TIME_FINISHED && !hasContent(answer.answer)) {
    return <TimeExceededInfo />
  }
  return (
    <div
      className={classNames(
        styles.creativeAnswer,
        'tiny',
        answer.state === AnswerStateEnum.TIME_FINISHED && sharedStyles.timeExceededFadeout,
      )}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(answer.answer),
      }}
    />
  )
}
