import * as React from 'react'
import { SVGProps } from 'react'

const SvgAddFolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.909 4.909A2.25 2.25 0 0 1 6.5 4.25H11a2.25 2.25 0 0 1 2.25 2.25v.75H23a2.25 2.25 0 0 1 2.25 2.25v2.25H26a2.251 2.251 0 0 1 2.175 2.829.75.75 0 0 1-1.45-.386.75.75 0 0 0-.725-.943H12.13a.751.751 0 0 0-.721.542l-2.35 8.15v.002c-.03.105-.067.207-.11.306H12.5a.75.75 0 1 1 0 1.5H6.697a.75.75 0 0 1-.224-.034A2.45 2.45 0 0 1 4.25 21.31V6.5c0-.597.237-1.169.659-1.591ZM23.75 11.75V9.5a.75.75 0 0 0-.75-.75H12.5a.75.75 0 0 1-.75-.75V6.5a.75.75 0 0 0-.75-.75H6.5a.75.75 0 0 0-.75.75v14.794a.95.95 0 0 0 1.864.24l.001-.004 2.351-8.154a2.25 2.25 0 0 1 2.163-1.626H23.75Zm-2.25 3a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Zm-3.712 3.038a5.25 5.25 0 1 1 7.424 7.424 5.25 5.25 0 0 1-7.424-7.424Zm3.712-.038a.75.75 0 0 1 .75.75v2.25h2.25a.75.75 0 0 1 0 1.5h-2.25v2.25a.75.75 0 0 1-1.5 0v-2.25H18.5a.75.75 0 0 1 0-1.5h2.25V18.5a.75.75 0 0 1 .75-.75Z'
      fill={props.color ?? '#A3A3A3'}
    />
  </svg>
)

export default SvgAddFolderIcon
