import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { useField } from 'react-final-form'
import { FormFieldWithError } from '../../../../../../common/components/Form/FormFieldError/FormFieldWithError'
import { ToggleSwitch } from '../../../../../../common/components/ToggleSwitch/ToggleSwitch'
import { IconName, getIcon } from '../../../../../../common/components/icons/utils'
import { safeIsNullOrEmpty } from '../../../../../../util/string'
import styles from './SettingsToggle.module.css'

type SettingsToggleProps = {
  name: string
  title: string
  description: string
  descriptionChecked?: string
  icon: IconName
  disabled?: boolean
}

export const SettingsToggle: React.FC<PropsWithChildren<SettingsToggleProps>> = ({
  name,
  title,
  description,
  descriptionChecked,
  icon,
  children,
  disabled,
}) => {
  const { input } = useField(name, { type: 'checkbox' })

  return (
    <FormFieldWithError
      name={name}
      wrapperClassName={classNames(styles.wrapper, input.checked && children != null && styles.wrapperExpanded)}
    >
      <div className={styles.container}>
        <ToggleSwitch {...input} id={name} disabled={disabled} />
        <label htmlFor={name} className={classNames(styles.label, input.checked && styles.label_checked)}>
          <span className={styles.icon}>{getIcon(icon)}</span>
          <div className={styles.titleAndDescription}>
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>
              {input.checked && !safeIsNullOrEmpty(descriptionChecked) ? descriptionChecked : description}
            </span>
          </div>
        </label>
      </div>
      {input.checked && children}
    </FormFieldWithError>
  )
}
