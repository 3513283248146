import * as React from 'react'
import { SVGProps } from 'react'
const SvgPhotoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.5 5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7ZM7 8.5a1.5 1.5 0 1 1 2.998-.002A1.5 1.5 0 0 1 7 8.5Z'
      fill={props.color ?? '#000'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 32a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3H3ZM2 3a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v19H9l3.416-4.555a1 1 0 0 1 1.5-.114l1.377 1.376A1 1 0 0 0 16.8 18.6l4.4-5.867a1 1 0 0 1 1.4-.2l.002.002a.96.96 0 0 1 .196.196l.002.002 3.56 4.747a1 1 0 0 0 1.6-1.2l-3.558-4.744-.002-.002a3 3 0 0 0-4.8 0l-3.708 4.944-.551-.551a2.896 2.896 0 0 0-.323-.281l-.002-.002a3 3 0 0 0-4.2.601L6.5 22H2V3Zm28 21v5a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-5h28Z'
      fill={props.color ?? '#000'}
    />
  </svg>
)
export default SvgPhotoIcon
