import * as React from 'react'
import { SVGProps } from 'react'

const SvgPinMultipleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m19.547 27.4 6.75-6.75c3.685-3.685 3.685-9.716-.048-13.45a9.534 9.534 0 0 0-13.499 0 9.534 9.534 0 0 0 0 13.499l6.797 6.7Zm-2.92-16.323a4.074 4.074 0 0 1 5.744 0 4.073 4.073 0 0 1 0 5.744c-.143.144-.287.287-.478.383 0-.574-.287-1.149-.718-1.58-.91-.909-2.441-.909-3.35 0-.432.431-.671 1.054-.719 1.58-.072-.072-.155-.131-.24-.191a1.86 1.86 0 0 1-.239-.192 4.074 4.074 0 0 1 0-5.744ZM11.166 4.77a10.125 10.125 0 0 0-5.316 2.63c-1.9 1.8-2.9 4.2-2.9 6.7 0 2.6 1 5 2.9 6.8l7.1 6.7 1.778-1.677a.802.802 0 1 0-1.102-1.164l-.676.642-5.9-5.6c-1.6-1.6-2.5-3.6-2.5-5.7s.9-4.1 2.4-5.6a8.577 8.577 0 0 1 4.592-2.21l.148-.15c.533-.534.22-1.496-.524-1.371Z'
      fill={props.color ?? 'var(--grey-700)'}
    />
  </svg>
)

export default SvgPinMultipleIcon
