import classNames from 'classnames'
import React from 'react'

import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { GameStatus, SecondaryGameStatus } from '../../../types/commonTypes'
import { getEnumKeyByValue } from '../../../util/functional'
import shared from '../../styles/shared.module.css'
import styles from './StatusPill.module.css'

type StatusPillProps = {
  status: GameStatus
  secondaryStatus?: SecondaryGameStatus
  variant?: 'card' | 'listItem'
}

const getStatusLabel = (status: GameStatus, t: TFunction) => {
  switch (status) {
    case GameStatus.ARCHIVED:
      return t('game_status_label.archived', 'Archived')
    case GameStatus.COMMUNITY:
      return t('game_status_label.community', 'Community')
    case GameStatus.DRAFT:
      return t('game_status_label.draft', 'Draft')
    case GameStatus.IN_PROGRESS:
      return t('game_status_label.in_progress', 'Game on')
    case GameStatus.PAUSED:
      return t('game_status_label.paused', 'Paused')
    case GameStatus.LIBRARY:
      return t('game_status_label.library', 'Library')
    case GameStatus.TEMPLATE:
      return t('game_status_label.template', 'Template')
    case GameStatus.SPONSORED:
      return t('game_status_label.sponsored', 'Sponsored')
    case GameStatus.EXPIRED:
      return t('game_status_label.expired', 'Expired')
    default:
      break
  }
}

const getSecondaryStatusLabel = (secondaryStatus: SecondaryGameStatus, t: TFunction) => {
  switch (secondaryStatus) {
    case SecondaryGameStatus.DOWNLOADED:
      return t('game_status_label.downloaded', 'Downloaded')
    case SecondaryGameStatus.PUBLISHED:
      return t('game_status_label.published', 'Published')
    default:
      break
  }
}

export const StatusPill: React.FC<StatusPillProps> = ({ status, secondaryStatus, variant = 'listItem' }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.pillsContainer}>
      <span
        className={classNames(
          styles.statusPill,
          shared.my_0,
          styles[`${variant}StateWrapper`],
          'detailTiny',
          styles[`${getEnumKeyByValue(status, GameStatus)}`],
        )}
      >
        {getStatusLabel(status, t)}
      </span>
      {secondaryStatus && (
        <span
          className={classNames(
            styles.statusPill,
            shared.my_0,
            'detailTiny',
            styles[`${getEnumKeyByValue(secondaryStatus, SecondaryGameStatus)}`],
          )}
        >
          {getSecondaryStatusLabel(secondaryStatus, t)}
        </span>
      )}
    </div>
  )
}
