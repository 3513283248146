import * as React from 'react'
import { SVGProps } from 'react'

const SvgListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.5 0A1.5 1.5 0 0 0 0 1.5v3A1.5 1.5 0 0 0 1.5 6h3A1.5 1.5 0 0 0 6 4.5v-3A1.5 1.5 0 0 0 4.5 0h-3Zm0 1.5h3v3h-3v-3Zm6.75.75a.75.75 0 0 0 0 1.5h15a.75.75 0 0 0 0-1.5h-15Zm0 9a.75.75 0 0 0 0 1.5h15a.75.75 0 0 0 0-1.5h-15ZM7.5 21a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 0 1.5h-15A.75.75 0 0 1 7.5 21ZM0 10.5A1.5 1.5 0 0 1 1.5 9h3A1.5 1.5 0 0 1 6 10.5v3A1.5 1.5 0 0 1 4.5 15h-3A1.5 1.5 0 0 1 0 13.5v-3Zm4.5 0h-3v3h3v-3Zm-3 7.5A1.5 1.5 0 0 0 0 19.5v3A1.5 1.5 0 0 0 1.5 24h3A1.5 1.5 0 0 0 6 22.5v-3A1.5 1.5 0 0 0 4.5 18h-3Zm0 1.5h3v3h-3v-3Z'
      fill={props.color ?? '#A3A3A3'}
    />
  </svg>
)

export default SvgListIcon
