import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getViewer } from 'react-pannellum'
import { MapType } from '../../../../api/gameTypes'
import { ThreeDots } from '../../../../common/components/loaders/ThreeDots/ThreeDots'
import { useConfirmation } from '../../../../contexts/ConfirmationContext'
import { useGame } from '../../../../contexts/GameContext'
import { useNotification } from '../../../../contexts/NotificationContext'
import { DeepPartial, Game, Task } from '../../../../types/commonTypes'
import { getRandomTempId } from '../../../../util/functional'
import { MapInstanceID, useMapApi } from '../../../../util/map'
import { getTaskMoreMenuSelector } from '../../helpers'
import { TabType } from '../../types'
import { TaskModal } from '../Tasks/TaskModal/TaskModal'
import { Build } from './Build/Build'
import styles from './EditorSidebar.module.css'
import { Play } from './Play/Play'
import { TaskHoverHandler } from './components/TaskHoverHandler/TaskHoverHandler'

export const EDITOR_SIDEBAR_ID = 'EDITOR_SIDEBAR_ID'

type EditorSidebarProps = {
  activeTab: TabType
  game: Game | undefined
  tasks: Task[]
  activeBoardIndex: number
  onSetActiveBoard: (id: number) => void
  onClickDefineBoards: () => void
}

export const EditorSidebar: React.FC<EditorSidebarProps> = ({
  activeTab = TabType.BUILD,
  game,
  tasks,
  activeBoardIndex,
  onSetActiveBoard,
  onClickDefineBoards,
}) => {
  const {
    removeTask,
    gradeAnswer,
    revisionForAnswer,
    people: { players },
    loadingGetGame,
    loadingAnswers,
    errorGetGame,
    triggerAnswerRefresh,
    createOrUpdateTask,
    highlightedTaskId,
    updateGameRootTask,
    updateBranchGoalStatus,
    manageConnectionLine,
    taskConnections,
    addExplorationDoor,
    editorPermissions,
  } = useGame()
  const { requestConfirmation } = useConfirmation()
  const { t } = useTranslation()

  const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
  const [editTaskId, setEditTaskId] = useState<number>()
  const shouldOpenCopiedTask = useRef(false)
  const [editingPinnedCopy, setEditingPinnedCopy] = useState(false)
  const [initialTaskModalValues, setInitialTaskModalValues] = useState<Partial<Task>>({})
  const taskCardSelector = useRef<string>()
  const { notifyError, notifySuccess } = useNotification()
  const { map } = useMapApi(MapInstanceID.EDITOR)

  const getNewTaskPosition = useCallback(() => {
    if (game == null || game.gameBoardSettings.gameBoardType === MapType.STATIC) {
      return { x: 50, y: 50 }
    } else if (game.gameBoardSettings.gameBoardType === MapType.LIVE) {
      return { x: map.getCenter().lat, y: map.getCenter().lng }
    } else {
      const currentViewer = getViewer()
      return { x: currentViewer.getYaw(), y: currentViewer.getPitch() }
    }
  }, [game, map])

  const prepareTaskForCopying = useCallback(
    (taskToCopy: Task) => {
      return {
        ...taskToCopy,
        ...getNewTaskPosition(),
        name: `${taskToCopy.name} ${t('game_editor.tasks.a_copy', '(Copy)')}`,
        id: -1,
        subtasks: taskToCopy.subtasks.map((subtask) => {
          return { ...subtask, id: undefined, tempId: getRandomTempId() }
        }),
        order: undefined,
      }
    },
    [getNewTaskPosition, t],
  )

  const handleClickAddTask = useCallback(
    (initialTask?: DeepPartial<Task>) => {
      setInitialTaskModalValues(() => {
        return {
          ...getNewTaskPosition(),
          mapIndex: initialTask?.mapIndex ?? activeBoardIndex,
          advanced: { isFlash: initialTask?.advanced?.isFlash },
          ...(game?.advancedSettings.levelsEnabled && { level: initialTask?.level ?? 1 }),
        }
      })
      setShowTaskModal(true)
    },
    [activeBoardIndex, game?.advancedSettings.levelsEnabled, getNewTaskPosition],
  )

  const handleClickEditTask = useCallback((taskId: number) => {
    taskCardSelector.current = getTaskMoreMenuSelector(taskId)
    setEditTaskId(taskId)
    setShowTaskModal(true)
  }, [])

  const handleClickCloseTaskModal = useCallback(() => {
    setShowTaskModal(false)
    setEditingPinnedCopy(false)
    setEditTaskId(undefined)
    setInitialTaskModalValues({})
    if (taskCardSelector.current) {
      document.querySelector<HTMLLIElement>(taskCardSelector.current)?.focus?.()
      taskCardSelector.current = undefined
    }
  }, [])

  useEffect(() => {
    if (editTaskId != null) {
      setInitialTaskModalValues(tasks.find((task) => task.id === editTaskId) ?? {})
    }
  }, [editTaskId, tasks])

  const handleClickDeleteTask = useCallback(
    (id: number) => {
      requestConfirmation({
        title: t('game_editor.tasks.delete_confirmation.title', 'Are you sure you want to delete?'),
        text: t('game_editor.tasks.delete_confirmation.text', 'This action is irreversible'),
      }).then((response) => {
        if (response) {
          removeTask(id)
          setShowTaskModal(false)
          setEditTaskId(undefined)
          setInitialTaskModalValues({})
        }
      })
    },
    [removeTask, requestConfirmation, t],
  )

  const handleClickCopyFromModal = useCallback(
    async (hasChanges: boolean) => {
      const okToCopy =
        !hasChanges ||
        (await requestConfirmation({
          title: t('game_editor.tasks.copy_confirmation.title', 'Copy the task?'),
          text: t('game_editor.tasks.copy_confirmation.text', 'The last saved version of this task will be copied.'),
        }))

      if (okToCopy) {
        const taskToCopy = tasks.find((task) => task.id === editTaskId)
        if (!taskToCopy) return
        shouldOpenCopiedTask.current = true
        const result = await createOrUpdateTask(prepareTaskForCopying(taskToCopy))
        if (result) {
          notifySuccess({
            title: t('game_editor.tasks.modal_copy_success_notification.title', 'Task copied'),
            content: taskToCopy.advanced.isFlash
              ? t(
                  'game_editor.tasks.modal_copy_success_notification.content_flash_task',
                  'New copy of the flash task was created.',
                )
              : t('game_editor.tasks.modal_copy_success_notification.content', 'New copy of the task was created. '),
          })
        } else {
          notifyError({
            title: t('game_editor.tasks.copy_error_notification.title', 'Failed to copy task'),
            content: t(
              'game_editor.tasks.copy_error_notification.content',
              'An error occurred while copying the task. Please try again or contact us for support.',
            ),
          })
        }
      }
    },
    [createOrUpdateTask, editTaskId, notifyError, notifySuccess, prepareTaskForCopying, requestConfirmation, t, tasks],
  )

  useEffect(() => {
    if (shouldOpenCopiedTask.current && highlightedTaskId) {
      setShowTaskModal(false)
      shouldOpenCopiedTask.current = false
      setEditTaskId(highlightedTaskId)
      setShowTaskModal(true)
      if (!tasks.find((task) => task.id === highlightedTaskId)?.advanced.isFlash) {
        setEditingPinnedCopy(true)
      }
    }
  }, [shouldOpenCopiedTask, highlightedTaskId, tasks])

  const handleClickDirectCopyTask = useCallback(
    async (id: number) => {
      const taskToCopy = tasks.find((task) => task.id === id)
      if (!taskToCopy) return
      const result = await createOrUpdateTask(prepareTaskForCopying(taskToCopy))
      if (result) {
        notifySuccess({
          title: t('game_editor.tasks.copy_success_notification.title', 'Task copied'),
          content: taskToCopy.advanced.isFlash
            ? t(
                'game_editor.tasks.copy_success_notification.content_flash_task',
                'New copy of the flash task was created.',
              )
            : t(
                'game_editor.tasks.copy_success_notification.content',
                'New copy of the task was created. You can now position it on the map.',
              ),
        })
      } else {
        notifyError({
          title: t('game_editor.tasks.copy_error_notification.title', 'Failed to copy task'),
          content: t(
            'game_editor.tasks.copy_error_notification.content',
            'An error occurred while copying the task. Please try again or contact us for support.',
          ),
        })
      }
    },
    [createOrUpdateTask, notifyError, notifySuccess, prepareTaskForCopying, t, tasks],
  )

  const handleDeleteConnectionLine = useCallback(
    async (fromId: number, toId: number) => {
      const result = await manageConnectionLine(fromId, toId, true)
      if (result.success) {
        notifySuccess({
          title: t('game_editor.branching.connection_delete_success_notification.title', 'Success'),
          content: t(
            'game_editor.branching.connection_delete_success_notification.content',
            'The connection was removed.',
          ),
          timeout: 3000,
        })
      } else {
        notifyError({
          title: t('game_editor.branching.connection_delete_error_notification.title', 'Failed to remove connection'),
          content: t(
            'game_editor.branching.connection_delete_error_notification.content',
            'You can try again or contact us for support.',
          ),
        })
      }
    },
    [manageConnectionLine, notifyError, notifySuccess, t],
  )

  const handleAddConnectionLine = useCallback(
    async (fromId: number, toId: number) => {
      const result = await manageConnectionLine(fromId, toId)
      if (result.success) {
        notifySuccess({
          title: t('game_editor.branching.connection_add_success_notification.title', 'Success'),
          content: t('game_editor.branching.connection_add_success_notification.content', 'The connection was added.'),
          timeout: 3000,
        })
      } else {
        notifyError({
          title: t('game_editor.branching.connection_add_error_notification.title', 'Failed to add connection'),
          content:
            result.errorMessage ??
            t(
              'game_editor.branching.connection_add_error_notification.content',
              'You can try again or contact us for support.',
            ),
        })
      }
    },
    [manageConnectionLine, notifyError, notifySuccess, t],
  )

  const handleClearStartingTask = useCallback(async () => {
    const result = await updateGameRootTask('null')
    if (!result) {
      notifyError({
        title: t('game_editor.branching.generic_update_error_notification.title', 'Update failed'),
        content: t(
          'game_editor.branching.generic_update_error_notification.content',
          'You can try again or contact us for support.',
        ),
      })
    }
  }, [notifyError, t, updateGameRootTask])

  const handleSetStartingTask = useCallback(
    async (id: number) => {
      const result = await updateGameRootTask(id)
      if (!result) {
        notifyError({
          title: t('game_editor.branching.generic_update_error_notification.title', 'Update failed'),
          content: t(
            'game_editor.branching.generic_update_error_notification.content',
            'You can try again or contact us for support.',
          ),
        })
      }
    },
    [notifyError, t, updateGameRootTask],
  )

  const handleSetGoalTaskStatus = useCallback(
    async (id: number, isGoalTask: boolean) => {
      updateBranchGoalStatus(id, isGoalTask)
    },
    [updateBranchGoalStatus],
  )

  const handleAddDoor = useCallback(
    async (leadsToBoardIndex?: number) => {
      const pos = getNewTaskPosition()
      const result = await addExplorationDoor(activeBoardIndex, pos.x, pos.y, leadsToBoardIndex)
      if (!result) {
        notifyError({
          title: t('game_editor.exploration_mode.add_door_failed.title', 'Failed to add a new door'),
          content: t(
            'game_editor.exploration_mode.add_door_failed.content',
            'You can try again or contact us for support.',
          ),
        })
      }
    },
    [activeBoardIndex, addExplorationDoor, getNewTaskPosition, notifyError, t],
  )

  if ((game == null && errorGetGame == null) || loadingGetGame || (activeTab === TabType.PLAY && loadingAnswers)) {
    return (
      <div className={styles.editorSidebarContainer} id={EDITOR_SIDEBAR_ID}>
        <div className={classNames(styles.editorSidebarPanelContainer, styles.sidebarLoader)}>
          <ThreeDots />
          <span>{t('game_editor.sidebar.loading_game_info', 'Loading game')}</span>
        </div>
      </div>
    )
  }

  if (game == null) {
    return null
  }

  return (
    <div className={styles.editorSidebarContainer} id={EDITOR_SIDEBAR_ID}>
      {showTaskModal && (
        <TaskModal
          game={game}
          initialValues={initialTaskModalValues}
          onClose={handleClickCloseTaskModal}
          onDelete={editTaskId == null ? undefined : () => handleClickDeleteTask(editTaskId)}
          onCopy={editTaskId == null ? undefined : handleClickCopyFromModal}
          editingPinnedCopy={editingPinnedCopy}
        />
      )}
      {activeTab === TabType.BUILD && (
        <Build
          game={game}
          activeBoardIndex={activeBoardIndex}
          onSetActiveBoard={onSetActiveBoard}
          onOpenGameSettings={onClickDefineBoards}
          onAddTask={handleClickAddTask}
          onEditTask={handleClickEditTask}
          onCopyTask={handleClickDirectCopyTask}
          onDeleteTask={handleClickDeleteTask}
          onAddDoor={handleAddDoor}
          permissions={editorPermissions}
        />
      )}
      {activeTab === TabType.PLAY && (
        <Play
          game={game}
          tasks={tasks}
          onAskForRevisedAnswer={revisionForAnswer}
          onGradeAnswer={gradeAnswer}
          onTriggerAnswerRefresh={triggerAnswerRefresh}
          players={players}
          permissions={editorPermissions}
        />
      )}
      <TaskHoverHandler
        rootTaskId={game?.rootTaskId}
        taskConnections={taskConnections}
        tasks={tasks}
        activeTab={activeTab}
        mapType={game?.gameBoardSettings.gameBoardType}
        isBranching={game?.gameBoardSettings.isBranching}
        viewOnly={!editorPermissions.actions.tasks}
        onCopyTask={handleClickDirectCopyTask}
        onEditTask={handleClickEditTask}
        onDeleteTask={handleClickDeleteTask}
        onAddConnectionLine={handleAddConnectionLine}
        onDeleteConnectionLine={handleDeleteConnectionLine}
        onSetStartingTask={handleSetStartingTask}
        onClearStartingTask={handleClearStartingTask}
        onSetGoalTaskStatus={handleSetGoalTaskStatus}
      />
    </div>
  )
}
