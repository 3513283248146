import { useTranslation } from 'react-i18next'
import { ButtonRadioGroupField } from '../../../../../../../common/components/Form/ButtonRadioGroupField/ButtonRadioGroupField'
import { SelectableIconFormField } from '../../../../../../../common/components/Form/CheckboxGroupField/SelectableIconFormField'
import { EditorFormField } from '../../../../../../../common/components/Form/EditorFormField/EditorFormField'
import { InputFormField } from '../../../../../../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../../../../../../common/components/Form/SelectFormField/SelectFormField'
import { SelectVariant } from '../../../../../../../common/components/Select/Select'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { IconTooltip } from '../../../../../../../common/components/tooltip/IconTooltip'
import { CreativeSubtask, PointsType } from '../../../../../../../types/commonTypes'
import { PointsInput } from '../../../../Points/PointsInput'
import { AutomatedFeedback } from '../components/AutomatedFeedback'
import { ExpandableToggle } from '../components/ExpandableToggle'
import styles from './CreativeSubtask.module.css'
import {
  getTranslatedAnswerOptions,
  getTranslatedAutomaticPointsOptions,
  getTranslatedPointsTypeOptions,
} from './helpers'

type CreativeSubtaskProps = {
  value: CreativeSubtask
  name: string
  noPoints?: boolean
  viewOnly: boolean
}

export const CreativeSubtaskFields: React.FC<CreativeSubtaskProps> = ({ value, name, noPoints, viewOnly }) => {
  const { t } = useTranslation()

  const answerOptions = getTranslatedAnswerOptions(t)
  const pointsTypeOptions = getTranslatedPointsTypeOptions(t)
  const automaticPointsOptions = getTranslatedAutomaticPointsOptions(t)

  return (
    <>
      <h5>{t('game_editor.tasks.answer_options.title', 'Answer options')}</h5>
      <div style={{ minHeight: 50 }}>
        <SelectableIconFormField
          label={t('game_editor.tasks.answer_options.select_answer_option_label', 'Select answer options')}
          srOnlyLabel
          name={`${name}.data.answerOptions`}
          options={answerOptions}
          size='Large'
          iconClassName={styles.answerSelectableIcon}
          disabled={viewOnly}
        />
      </div>
      {!noPoints && (
        <div className={styles.pointsContainer}>
          <div className={styles.pointsTitle}>
            <span className={styles.pointsTitleIcon}>{getIcon('scoreHeader')}</span>
            <h4>{t('game_editor.tasks.creative_task.points_title', 'Points')}</h4>
          </div>
          <ButtonRadioGroupField
            label={t('game_editor.tasks.creative_task.points_type_accessibility_label', 'Points type')}
            srOnlyLabel
            name={`${name}.data.pointsType`}
            options={pointsTypeOptions}
            disabled={viewOnly}
          />
          <p>
            {t(
              'game_editor.tasks.creative_task.points_general_description',
              'Define points to be awarded by writing directly into the points area below',
            )}
          </p>
          <div className={styles.pointsSection}>
            <span className={styles.pointsTitleIcon}>{getIcon('scoreHeader')}</span>
            <div className={styles.pointsSectionDescriptionAndInput}>
              <p>
                {t('game_editor.tasks.creative_task.points_for_player_answer', 'Points for the answer a player sends')}
              </p>
              <PointsInput name={`${name}.data.pointsForAnswer`} disabled={viewOnly} />
            </div>
          </div>
          {value.data.pointsType === PointsType.Automatic && (
            <div className={styles.pointsSection}>
              <span className={styles.pointsTitleIcon}>{getIcon('scoreHeader')}</span>
              <div className={styles.pointsSectionDescriptionAndInput}>
                <p>
                  {t('game_editor.tasks.creative_task.points_auto_score_percentage', 'Automatically awarded points')}
                </p>
                <div className={styles.pointsAutomaticContainer}>
                  <SelectFormField
                    name={`${name}.data.autoScoreOption`}
                    label={t(
                      'game_editor.tasks.creative_task.points_auto_score_percentage_option',
                      'Select automatic points percentage',
                    )}
                    srOnlyLabel
                    options={automaticPointsOptions}
                    fieldContainerClassName={styles.percentageControl}
                    variant={SelectVariant.XLarge}
                    disabled={viewOnly}
                  />
                  {value.data.autoScoreOption === 'other' && (
                    <>
                      <InputFormField
                        name={`${name}.data.autoScorePercentage`}
                        label={t(
                          'game_editor.tasks.creative_task.points_auto_score_percentage_input',
                          'Enter automatic points percentage',
                        )}
                        srOnlyLabel
                        type='number'
                        max={100}
                        min={0}
                        fieldContainerClassName={styles.percentageControl}
                        disabled={viewOnly}
                      />
                      <span className={styles.percentageInputSuffix}>%</span>
                      <IconTooltip
                        tooltipContent={t(
                          'game_editor.tasks.creative_task.points_auto_score_percentage_other_tooltip',
                          'Enter percentage of points (1-100) to be awarded automatically',
                        )}
                        iconColor='var(--grey-600)'
                        tooltipClassName={styles.percentageTooltip}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <h5 style={{ marginTop: 16 }}>
        {t(
          'game_editor.tasks.creative_task.evaluation_guidelines_and_automation',
          'Evaluation guidelines and automation',
        )}
      </h5>
      <ExpandableToggle
        name={`${name}.data.hasEvaluationGuideline`}
        title={t('game_editor.tasks.evaluation_guidelines', 'Evaluation guidelines')}
        description={t(
          'game_editor.tasks.creative_task.evaluation_guidelines_description_disabled',
          'Add evaluation criteria or correct answer to speed up evaluation. Visible only to instructors',
        )}
        descriptionChecked={t(
          'game_editor.tasks.creative_task.evaluation_guidelines_description_enabled',
          'Add evaluation criteria or correct answer to speed up evaluation. Visible only to instructors',
        )}
        disabled={viewOnly}
      >
        <EditorFormField
          name={`${name}.data.evaluationGuideline`}
          label={t('game_editor.tasks.evaluation_guidelines', 'Evaluation guidelines')}
          srOnlyLabel
          onlyBasicOptions
          initProps={{ height: 200 }}
          disabled={viewOnly}
        />
      </ExpandableToggle>
      <AutomatedFeedback name={name} allowRichText viewOnly={viewOnly} />
    </>
  )
}
