import { PlayerAccount } from '../../../../../../types/commonTypes'
import styles from './PlayersAddByPlayerAccountModal.module.css'
import { ListLinePlayerAccount } from './PlayerAccountModalListLines'
import { ThreeDots } from '../../../../../../common/components/loaders/ThreeDots/ThreeDots'
import { MAX_PLAYERS_COLLAPSED_COUNT, MAX_PLAYERS_SEE_ALL_COUNT } from './PlayersAddByPlayerAccountModal'
import { useTranslation } from 'react-i18next'

type PlayerAccountSearchResultsProps = {
  onPlayerAccountSelected: (playerAccount: PlayerAccount, toSelected: boolean) => void
  maxPlayersDisplayCount: number
  searchInputValue: string
  searchMatchingPlayerAccounts: PlayerAccount[]
  searching: boolean
  toAddPlayerAccounts: PlayerAccount[]
  onShowAllPlayers: (show: boolean) => void
  setMaxPlayersDisplayCount: (max: number) => void
  totalMatchingPlayerAccountCount: number
}

export const PlayerAccountSearchResults: React.FC<PlayerAccountSearchResultsProps> = ({
  onPlayerAccountSelected,
  maxPlayersDisplayCount,
  searchInputValue,
  searchMatchingPlayerAccounts,
  searching,
  toAddPlayerAccounts,
  onShowAllPlayers,
  setMaxPlayersDisplayCount,
  totalMatchingPlayerAccountCount,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {searching ? (
        <div className={styles.loadingIndicator}>
          <ThreeDots />
        </div>
      ) : (
        <>
          <div className={styles.titleLine}>
            {t('game_editor.add_people.player_account.results.player_accounts_title', 'Players (%{players_count})', {
              players_count: totalMatchingPlayerAccountCount,
            })}
            {searchMatchingPlayerAccounts.length > MAX_PLAYERS_COLLAPSED_COUNT && (
              <>
                {maxPlayersDisplayCount === MAX_PLAYERS_COLLAPSED_COUNT &&
                searchMatchingPlayerAccounts.length > maxPlayersDisplayCount ? (
                  <div
                    className={styles.seeAll}
                    onClick={() => {
                      setMaxPlayersDisplayCount(MAX_PLAYERS_SEE_ALL_COUNT)
                    }}
                  >
                    {t('game_editor.add_people.player_account.results.see_all', 'See all')}
                  </div>
                ) : (
                  <div className={styles.seeAll} onClick={() => setMaxPlayersDisplayCount(MAX_PLAYERS_COLLAPSED_COUNT)}>
                    {t('game_editor.add_people.player_account.results.show_less', 'Show less')}
                  </div>
                )}
              </>
            )}
          </div>
          {searchMatchingPlayerAccounts.slice(0, maxPlayersDisplayCount).map((playerAccount, index) => {
            return (
              <ListLinePlayerAccount
                key={index}
                showSecondLine
                isSelected={!!toAddPlayerAccounts.find((pa) => pa.id === playerAccount.id)}
                onSelect={onPlayerAccountSelected}
                playerAccount={playerAccount}
                searchTerm={searchInputValue}
              />
            )
          })}
        </>
      )}
    </>
  )
}
