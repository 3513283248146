import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useGame } from './GameContext'
import { deleteChatMessage, sendChatMessage } from '../api/messagingApiService'
import { Player, TMessage } from '../types/commonTypes'

export type ChatRoom = {
  roomMessages: TMessage[]
  lastSeen?: string
  hasUnreadMessages: boolean
  isChatRoomActive: boolean
}

const INITIAL_CHAT_ROOM_VALUES: ChatRoom = {
  hasUnreadMessages: false,
  isChatRoomActive: false,
  roomMessages: [] as TMessage[],
}

export type ChatRoomDictionary = {
  [chatRoomId: number]: ChatRoom
}

type ChatContextType = {
  allMessages: TMessage[] | undefined
  isChatOpen: boolean
  chatRooms: ChatRoomDictionary
  updateChatRoom: (chatRoomId: number, isChatRoomActive: boolean) => void
  sendMessage: (message: string, to?: string) => Promise<boolean>
  toggleChat: () => void
  deleteMessage: (messageId: number) => Promise<boolean>
}

const ChatContext = createContext<ChatContextType | null>(null)

type ChatContextProviderProps = {
  gameId: number | null
  userId: number
}

const isGeneralChatUnread = (messages: TMessage[], senderId: number, lastSeen: string | undefined) => {
  const publicMessages = messages.filter(({ isGeneralMessage }) => isGeneralMessage)
  if (
    publicMessages != null &&
    publicMessages.length > 0 &&
    publicMessages[publicMessages.length - 1].from.id === senderId
  ) {
    // This means that the message was sent from logged-in user and the msg was automatically read
    return false
  }
  if (lastSeen != null && publicMessages[publicMessages.length - 1] != null) {
    const lastSeenTime = new Date(lastSeen).getTime()
    const latestGeneralMessageTime = new Date(publicMessages[publicMessages.length - 1].createdAt).getTime()
    // This means that the latest GENERAL msg is not from the logged-in user and we check if it's newer
    // than the last visit to GENERAL chat
    return latestGeneralMessageTime - lastSeenTime > 0
  }
  // default to no new unread messages
  return false
}

const isDMChatUnread = (messages: TMessage[], senderId: number, lastSeen: string | undefined) => {
  const privateMessages = messages.filter(({ to, from }) => to != null && from.id === senderId)
  if (privateMessages != null && privateMessages.length > 0 && lastSeen != null) {
    const lastSeenTime = new Date(lastSeen).getTime()
    const latestPrivateSenderMessageTime = new Date(privateMessages[privateMessages.length - 1].createdAt).getTime()
    return latestPrivateSenderMessageTime - lastSeenTime > 0
  }
  // default to no new unread messages
  return false
}

export const ChatContextProvider: React.FC<PropsWithChildren<ChatContextProviderProps>> = ({
  gameId,
  userId,
  children,
}) => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false)
  const { allMessages, people } = useGame()

  const [onInitTimestamp] = useState<string>(new Date().toISOString())
  const [chatRooms, setChatRooms] = useState<ChatRoomDictionary>({
    0: { lastSeen: new Date().toISOString(), ...INITIAL_CHAT_ROOM_VALUES },
  })

  useEffect(() => {
    const { players } = people
    if (players != null && players.length > 0) {
      setChatRooms((prev) => ({
        ...prev,
        ...people.players.reduce((acc: ChatRoomDictionary, current: Player) => {
          if (prev[current.id] == null) {
            return {
              ...acc,
              [current.id]: { lastSeen: new Date().toISOString(), ...INITIAL_CHAT_ROOM_VALUES },
            }
          }
          return acc
        }, {} as ChatRoomDictionary),
      }))
    }
  }, [people])

  useEffect(() => {
    const { players } = people
    if (allMessages != null && allMessages[allMessages.length - 1] != null) {
      setChatRooms((prev) => ({
        ...prev,
        ...players.reduce((acc: ChatRoomDictionary, current: Player) => {
          return {
            ...acc,
            0: {
              ...prev[0],
              hasUnreadMessages: isGeneralChatUnread(allMessages, userId, prev[0].lastSeen ?? onInitTimestamp),
              roomMessages: allMessages.filter(({ to }) => to == null),
            },
            [current.id]: {
              ...prev[current.id],
              hasUnreadMessages: isDMChatUnread(
                allMessages,
                current.id,
                prev[current.id] != null ? prev[current.id].lastSeen : onInitTimestamp,
              ),
              roomMessages: allMessages.filter(
                ({ to, from }) => to != null && (to.id === current.id || from.id === current.id),
              ),
            },
          }
        }, {} as ChatRoomDictionary),
      }))
    }
  }, [onInitTimestamp, people, allMessages, userId])

  const updateChatRoom = useCallback((chatRoomId: number, isChatRoomActive: boolean) => {
    setChatRooms((prev) => ({
      ...prev,
      [chatRoomId]: {
        ...prev[chatRoomId],
        lastSeen: new Date().toISOString(),
        hasUnreadMessages: false,
        isChatRoomActive: isChatRoomActive ?? prev[chatRoomId].isChatRoomActive,
      },
    }))
  }, [])

  const sendMessage = useCallback(
    async (messageText: string, to: string = '') => {
      const response = await sendChatMessage({ gameId: gameId || 0, message: messageText, to })
      return response.success
    },
    [gameId],
  )

  const deleteMessage = useCallback(
    async (messageId: number) => {
      if (!gameId) return false
      const response = await deleteChatMessage({ gameId, messageId })
      return response.success
    },
    [gameId],
  )

  const toggleChat = useCallback(() => {
    setIsChatOpen((prev) => !prev)
  }, [])

  return (
    <ChatContext.Provider
      value={{
        isChatOpen,
        allMessages,
        chatRooms,
        updateChatRoom,
        sendMessage,
        toggleChat,
        deleteMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChat = () => {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error('Expected to be wrapped in ChatContextProvider!')
  }
  return context
}
