import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { Tooltip } from '../../../../../../../common/components/tooltip/Tooltip'
import styles from './TaskAnswerCount.module.css'

type AnswerCountTooltipContentProps = {
  total: number
  graded: number
}

const AnswerCountTooltipContent: React.FC<AnswerCountTooltipContentProps> = ({ total, graded }) => {
  const { t } = useTranslation()
  return (
    <div className='tiny'>
      <div>
        {t('game_editor.sidebar.evaluate_answer_panel.task_answer_count_total', {
          defaultValue: 'Total answers: %{answers_count}',
          answers_count: total,
        })}
      </div>
      <div>
        {t('game_editor.sidebar.evaluate_answer_panel.task_answer_count_graded', {
          defaultValue: 'Graded: %{answers_count}',
          answers_count: graded,
        })}
      </div>
    </div>
  )
}

type TaskAnswerCountProps = {
  total: number
  graded: number
  className: string
}

export const TaskAnswerCount: React.FC<TaskAnswerCountProps> = ({ total, graded, className }) => {
  return (
    <Tooltip tooltipContent={<AnswerCountTooltipContent total={total} graded={graded} />}>
      {(tooltipProps) => (
        <div className={classNames(styles.answersCountContainer, className)} {...tooltipProps}>
          <span className='iconWrapperMedium'>{getIcon('userIdle')}</span>
          <span className='tiny bold'>
            {graded}/{total}
          </span>
        </div>
      )}
    </Tooltip>
  )
}
