import { ThreeDots } from '../ThreeDots/ThreeDots'
import { useTranslation } from 'react-i18next'
import styles from './LoaderBlock.module.css'

type LoaderBlockProps = {
  variant?: 'primary' | 'secondary'
  remSize?: number
  title?: string
}

export const LoaderBlock: React.FC<LoaderBlockProps> = ({ variant, remSize, title }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.loaderContainer}>
      <h2>{title ?? t('loading_text', 'Loading, please wait...')}</h2>
      <ThreeDots variant={variant} remSize={remSize} />
    </div>
  )
}
