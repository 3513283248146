import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../AddPerson.module.css'
import classNames from 'classnames'
import { useBusinessSettings } from '../../../../../contexts/BusinessSettingsContext'

type LicenceCountButtonProps = {}

export const LicenceCountButton: React.FC<LicenceCountButtonProps> = () => {
  const { t } = useTranslation()
  const { updateQuota, quota } = useBusinessSettings()

  useEffect(() => {
    updateQuota()
    const interval = setInterval(() => {
      updateQuota()
    }, 30000)

    return () => clearInterval(interval)
  }, [updateQuota])

  if (!quota || quota.totalSeats === null || quota.freeSeats === null || quota.totalQuotaUsage === null) {
    return <></>
  }

  const getButtonClass = () => {
    if (!quota.totalQuotaUsage) return null
    if (quota.totalQuotaUsage <= 0.6) return styles.green
    if (quota.totalQuotaUsage <= 0.9) return styles.yellow
    return styles.red
  }

  return (
    <a href='https://play.seppo.io/newshop/shop' className={classNames(getButtonClass(), styles.licenceCount)}>
      {t('game_editor.add_people.licence_count', {
        defaultValue: '%{licence_count} licences available',
        licence_count: quota.freeSeats,
      })}
    </a>
  )
}
