const READY_BADGE_URL_PREFIX = 'https://smartfeet-assets-production.s3.eu-west-1.amazonaws.com/ready_badges/'

export const READY_BADGE_URLS: string[] = [
  `${READY_BADGE_URL_PREFIX}diploma512.jpg`,
  `${READY_BADGE_URL_PREFIX}diamond512.jpg`,
  `${READY_BADGE_URL_PREFIX}chess512.jpg`,
  `${READY_BADGE_URL_PREFIX}camera512.jpg`,
  `${READY_BADGE_URL_PREFIX}puzzle512.jpg`,
  `${READY_BADGE_URL_PREFIX}lightbulb512.jpg`,
  `${READY_BADGE_URL_PREFIX}trophy2_silver512.jpg`,
  `${READY_BADGE_URL_PREFIX}trophy2_gold512.jpg`,
  `${READY_BADGE_URL_PREFIX}trophy2_bronze512.jpg`,
  `${READY_BADGE_URL_PREFIX}trophy_silver512.jpg`,
  `${READY_BADGE_URL_PREFIX}trophy_gold512.jpg`,
  `${READY_BADGE_URL_PREFIX}trophy_bronze512.jpg`,
  `${READY_BADGE_URL_PREFIX}treasure512.jpg`,
  `${READY_BADGE_URL_PREFIX}stopwatch_silver512.jpg`,
  `${READY_BADGE_URL_PREFIX}stopwatch_gold512.jpg`,
  `${READY_BADGE_URL_PREFIX}stopwatch_bronze512.jpg`,
  `${READY_BADGE_URL_PREFIX}star_silver512.jpg`,
  `${READY_BADGE_URL_PREFIX}star_gold512.jpg`,
  `${READY_BADGE_URL_PREFIX}star_bronze512.jpg`,
  `${READY_BADGE_URL_PREFIX}rocket512.jpg`,
  `${READY_BADGE_URL_PREFIX}paperpen.jpg`,
  `${READY_BADGE_URL_PREFIX}mountain512.jpg`,
  `${READY_BADGE_URL_PREFIX}mic512.jpg`,
  `${READY_BADGE_URL_PREFIX}medal_silver512.jpg`,
  `${READY_BADGE_URL_PREFIX}medal_gold512.jpg`,
  `${READY_BADGE_URL_PREFIX}medal_bronze512.jpg`,
  `${READY_BADGE_URL_PREFIX}magnifyingglass512.jpg`,
  `${READY_BADGE_URL_PREFIX}highfive512.jpg`,
  `${READY_BADGE_URL_PREFIX}hat512.jpg`,
  `${READY_BADGE_URL_PREFIX}handshake512.jpg`,
  `${READY_BADGE_URL_PREFIX}film512.jpg`,
]
