import * as React from 'react'
import { SVGProps } from 'react'

const SvgEmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.667 5.029a1 1 0 0 0-1 1v19.047a1 1 0 0 0 1 1h26.666a1 1 0 0 0 1-1V6.03a1 1 0 0 0-1-1H2.667Zm1 3.398v15.65h24.666V8.426l-9.162 7.047a5.2 5.2 0 0 1-6.342 0L3.667 8.427ZM26.87 7.029H5.129l8.92 6.86a3.201 3.201 0 0 0 3.902 0l8.92-6.86Z'
      fill={props.color ?? 'var(--grey-700)'}
    />
  </svg>
)

export default SvgEmailIcon
