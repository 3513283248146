import { TFunction } from 'i18next'
import { ExerciseType } from '../../../../../../../api/gameTypes'
import { AnswerEvaluation } from '../../../../../../../contexts/GameContextHelper'
import { AnswerStateEnum, Evaluation, FormErrorType } from '../../../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../../../util/string'
import {
  lowerThanOrEqualToValidation,
  requiredValidation,
  zeroOrAboveValidation,
} from '../../../../../../../util/validate'

export type EvaluationItem = AnswerEvaluation & { isCreative: boolean }

export type EvaluationFrom = {
  evaluationData: EvaluationItem[]
}

export const getAnswerStatusText = (status: AnswerStateEnum, isCreative: boolean, t: TFunction) => {
  switch (status) {
    case AnswerStateEnum.READY:
      return t('game_editor.sidebar.evaluate_answer_panel.answer_status.needs_grading', 'Answer needs grading')
    case AnswerStateEnum.REVISION:
      return t('game_editor.sidebar.evaluate_answer_panel.answer_status.in_revision', 'Waiting for revised answer')
    case AnswerStateEnum.IN_PROGRESS:
      return t('game_editor.sidebar.evaluate_answer_panel.answer_status.in_progress', 'Task is being answered')
    case AnswerStateEnum.TIME_FINISHED:
      return t('game_editor.sidebar.evaluate_answer_panel.answer_status.time_exceeded', 'Time exceeded')
    default:
      return isCreative
        ? t('game_editor.sidebar.evaluate_answer_panel.answer_status.graded', 'Graded')
        : t('game_editor.sidebar.evaluate_answer_panel.answer_status.automatically_graded', 'Automatically graded')
  }
}

export const getInitialValues = (playerEvaluation: Evaluation): Partial<EvaluationFrom> => {
  return {
    evaluationData: playerEvaluation.answers.map(({ answer, subtask }) => {
      if (subtask.type === ExerciseType.CreativeExercise) {
        return {
          isCreative: true,
          answerId: answer.id,
          comment: safeIsNullOrEmpty(answer.feedbackHtml)
            ? subtask.data.automatedFeedback ?? ''
            : answer.feedbackHtml ?? '',
          // TODO: might need to take automatic points into account, if answer is not graded but answer has points
          ...(answer.state === AnswerStateEnum.GRADED && { points: answer.points }),
        }
      } else {
        return {
          isCreative: false,
          answerId: answer.id,
        }
      }
    }),
  }
}

export const validatorCreator = (playerEvaluation: Evaluation, t: TFunction, noPointsGame?: boolean) => {
  return (values: Partial<EvaluationFrom>): FormErrorType<EvaluationFrom> => {
    const errors: FormErrorType<EvaluationFrom> = {}
    if (playerEvaluation.state === AnswerStateEnum.REVISION) {
      return errors
    }
    errors.evaluationData = values.evaluationData?.map((ed) => {
      const playerAnswer = playerEvaluation.answers.find((a) => a.answer.id === ed.answerId)
      if (playerAnswer == null || playerAnswer.subtask?.type !== ExerciseType.CreativeExercise) {
        return { answerId: undefined }
      } else {
        const maxPoints = playerAnswer.subtask.data.pointsForAnswer ?? Number.MAX_SAFE_INTEGER
        return {
          ...(noPointsGame && {
            comment: requiredValidation(ed?.comment, t),
          }),
          ...(!noPointsGame && {
            points: zeroOrAboveValidation(ed?.points, t) ?? lowerThanOrEqualToValidation(ed?.points, maxPoints, t),
          }),
        }
      }
    })
    return errors
  }
}
