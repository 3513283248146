import styles from './StepProgress.module.css'

interface StepProgressProps {
  percent: number
}
export const StepProgress: React.FC<StepProgressProps> = ({ percent }) => {
  return (
    <div className={styles.progressContainer}>
      <div style={{ width: `${percent * 100}%` }} className={styles.progress} />
    </div>
  )
}
