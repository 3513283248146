import React from 'react'
import classNames from 'classnames'

import styles from './Card.module.css'
import shared from '../../../../common/styles/shared.module.css'
import skeleton from '../../../../assets/images/skeleton.gif'

export const SkeletonGameCard: React.FC = () => {
  return (
    <button
      type={'button'}
      className={classNames(styles.container, styles.outlined, shared.mAxisC, shared.sAxisC)}
      style={{ minWidth: '17.5rem', overflow: 'hidden' }}
    >
      <div className={styles.skeletonImg}>
        <img src={skeleton} alt='...' />
      </div>
    </button>
  )
}
