import classNames from 'classnames'
import { TFunction } from 'i18next'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import shared from '../../../common/styles/shared.module.css'
import styles from '../OnboardingWizard.module.css'
import { RadioOptionsWithOtherField, RadioOptionsWithOtherFieldProps } from '../components/RadioOptionsWithOtherField'
import {
  BUSINESS_TOPICS_OPTIONS,
  PLAYER_OPTIONS,
  SCHOOL_SUBJECTS_OPTIONS,
  UNIVERSITY_SUBJECTS_OPTIONS,
} from '../constants'
import { OnboardingForm } from '../types'
import { ToggleFormField } from '../../../common/components/Form/ToggleFormField/ToggleFormField'

type CalculatedAnswerStopProps = Pick<
  RadioOptionsWithOtherFieldProps,
  'selectOptions' | 'otherFieldLabel' | 'allowOtherOption'
> & {
  title: string
}

const getCalculatedAnswerStopProps = (type: string, subtype: string, t: TFunction): CalculatedAnswerStopProps => {
  switch (type) {
    case 'Business':
      return {
        allowOtherOption: true,
        otherFieldLabel: t('onboarding_wizard.answer_step.business.add_topic', 'Add a topic'),
        selectOptions: BUSINESS_TOPICS_OPTIONS,
        title: t('onboarding_wizard.answer_step.business.title', 'Choose a topic best suited for your needs'),
      }
    case 'Education':
      return {
        allowOtherOption: true,
        otherFieldLabel: t('onboarding_wizard.answer_step.education.add_subject', 'Add a subject'),
        selectOptions: ['University', 'Vocational School'].includes(subtype)
          ? UNIVERSITY_SUBJECTS_OPTIONS
          : SCHOOL_SUBJECTS_OPTIONS,
        title: t('onboarding_wizard.answer_step.education.title', 'What subject are you primarily interested in?'),
      }
    default:
      return {
        allowOtherOption: false,
        selectOptions: PLAYER_OPTIONS,
        title: t('onboarding_wizard.answer_step.non_profit.title', 'How many players do you expect per game?'),
        otherFieldLabel: '',
      }
  }
}

export const AnswerStep = () => {
  const { t } = useTranslation()
  const { values } = useFormState<OnboardingForm>()

  const calculatedProps = useMemo<CalculatedAnswerStopProps>(() => {
    return getCalculatedAnswerStopProps(values.organisationType, values.organisationSubtype, t)
  }, [values.organisationType, values.organisationSubtype, t])

  return (
    <>
      <div className={classNames(shared.flex, shared.col, shared.sAxisC)}>
        <h2 style={{ marginTop: 40 }}>{calculatedProps.title}</h2>
      </div>
      <div style={{ textAlign: 'center' }}>
        <h5 className={styles.chooseText}>{t('onboarding_wizard.choose', 'Choose one')}</h5>
      </div>
      <RadioOptionsWithOtherField
        name='answer'
        selectOptions={calculatedProps.selectOptions}
        allowOtherOption={calculatedProps.allowOtherOption}
        otherFieldLabel={calculatedProps.otherFieldLabel}
      />
      <div className={styles.tosToggleContainer}>
        <ToggleFormField
          fieldContainerClassName={styles.toggleField}
          controlWithLabelClassName={styles.alignVertically}
          name='termsOfService'
          label={t('onboarding_wizard.tos.label', 'I accept the terms*')}
          description={
            <Trans i18nKey={'onboarding_wizard.tos.label_subtext'}>
              Read our full{' '}
              <a
                aria-label={t('onboarding_wizard.tos.aria_label', 'Terms of use')}
                href='https://play.seppo.io/terms'
                target='_blank'
                rel='noreferrer'
              >
                Terms of Use
              </a>
            </Trans>
          }
        />
      </div>
    </>
  )
}
