import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { IconName, getIcon } from '../../../../../../../../common/components/icons/utils'
import { safeIsNullOrEmpty } from '../../../../../../../../util/string'
import styles from './CollapsibleCardSection.module.css'

type CollapsibleCardSectionProps = {
  icon: IconName
  title: string
  subtitle?: string
  containerClassName?: string
  subtitleClassName?: string
  isExpanded: boolean
  onToggleExpanded: () => void
}

export const CollapsibleCardSection: React.FC<PropsWithChildren<CollapsibleCardSectionProps>> = ({
  icon,
  title,
  subtitle,
  containerClassName,
  subtitleClassName,
  isExpanded,
  onToggleExpanded,
  children,
}) => {
  return (
    <div className={classNames(styles.collapsibleCardContainer, containerClassName)}>
      <div className={styles.collapsibleCardHeader}>
        <span className={styles.collapsibleCardIcon}>{getIcon(icon)}</span>
        <div className={styles.collapsibleCardInfo}>
          <div className={styles.collapsibleCardTitleAndCollapse}>
            <span className={styles.collapsibleCardTitle} onClick={onToggleExpanded}>
              {title}
            </span>
            <button
              className={classNames(styles.toggleCollapseButton, isExpanded && styles.iconExpanded)}
              type='button'
              onClick={onToggleExpanded}
            >
              {getIcon('arrowDown')}
            </button>
          </div>
          {!safeIsNullOrEmpty(subtitle) && (
            <span className={classNames('tiny', 'bold', subtitleClassName)}>{subtitle}</span>
          )}
        </div>
      </div>
      <div className={classNames(styles.collapsibleSection, isExpanded && styles.collapsibleSection_visible)}>
        {children}
      </div>
    </div>
  )
}
