import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../../common/components/Form/InputFormField/InputFormField'
import { safeIsNullOrEmpty } from '../../../../../../util/string'
import styles from './LockCodeSettings.module.css'

type LockCodeSettingsProps = {
  viewOnly: boolean
}

export const LockCodeSettings: React.FC<LockCodeSettingsProps> = ({ viewOnly }) => {
  const { t } = useTranslation()

  const parseCode = (code: string) => {
    return safeIsNullOrEmpty(code) ? code : code.replace(/[^a-z^A-Z^\d]+/g, '').toUpperCase()
  }

  return (
    <div className={styles.container}>
      <InputFormField
        name='advanced.lockCode'
        inputContainerClassName={styles.codeInputContainer}
        className={styles.codeSettingsInput}
        labelClassName={styles.codeSettingsInputLabel}
        errorClassName={styles.codeError}
        label={t(
          'game_editor.tasks.advanced.code_locked_code_label',
          'Set a code (1-10 characters) that opens this task. You can use numbers 0-9 and letters a-z.',
        )}
        placeholder={t('game_editor.tasks.advanced.code_locked_code_placeholder', 'Type code here')}
        parse={parseCode}
        format={parseCode}
        maxLength={10}
        showCharsCount
        disabled={viewOnly}
      />
      <InputFormField
        name='advanced.lockClue'
        className={styles.codeSettingsInput}
        labelClassName={styles.codeSettingsInputLabel}
        label={t('game_editor.tasks.advanced.code_locked_clue_label', 'Clue for the code')}
        placeholder={t('game_editor.tasks.advanced.code_locked_clue_placeholder', 'Type clue here')}
        maxLength={160}
        showCharsCount
        disabled={viewOnly}
      />
    </div>
  )
}
