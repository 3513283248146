import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderBlock } from '../../common/components/loaders/LoaderBlock/LoaderBlock'
import { GamesOverview } from '../../composites/GamesOverview/GamesOverview'
import { WelcomeBlock } from '../../composites/WelcomeBlock/WelcomeBlock'
import { useGameData } from '../../contexts/OwnGamesContextProvider'
import { useUser } from '../../contexts/userContext'
import { useGameEditorNavigation } from '../../hooks/useGameEditorNavigation'
import { GamesFilterSettings } from '../../types/commonTypes'
import { getDashboardEmptyContent } from '../../util/game'
import styles from './Dashboard.module.css'
import { SEPPO_GROUPING_BUSINESS } from '../../api/typeConverters'
import { LtiBanner } from '../../composites/WelcomeBlock/components/LtiBanner'

const DASHBOARD_FILTERS: GamesFilterSettings = {
  age: true,
  language: true,
  subject: true,
  creator: true,
  updatedAtFrom: true,
  updatedAtUntil: true,
  status: true,
}

export const Dashboard: React.FC = () => {
  const { user, updateUser } = useUser()
  const gameData = useGameData()
  const { t } = useTranslation()
  const { goToCreateGame } = useGameEditorNavigation()

  const handleClickCreateGame = async () => {
    await goToCreateGame()
  }

  return (
    <div className={styles.container}>
      {user?.ltiContext && <LtiBanner />}
      {user != null && <WelcomeBlock user={user} updateUser={updateUser} />}
      {!gameData.initialFetchDone ? (
        <LoaderBlock />
      ) : (
        <GamesOverview
          title={t('dashboard.my_games_title', 'My games')}
          onClickCreateGame={handleClickCreateGame}
          showCreateGameCard={!user?.isSponsoredUser && user?.activeBusiness.industry !== SEPPO_GROUPING_BUSINESS}
          gameData={gameData}
          emptyContent={getDashboardEmptyContent(user, t)}
          filterFormSettings={DASHBOARD_FILTERS}
          allowSorting
          ltiContext={user?.ltiContext}
        />
      )}
    </div>
  )
}
