import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import TranslationAR from './locales/ar.json'
import TranslationDE from './locales/de.json'
import TranslationEN from './locales/en.json'
import TranslationES from './locales/es.json'
import TranslationFI from './locales/fi.json'
import TranslationNB from './locales/nb.json'
import TranslationNL from './locales/nl.json'
import TranslationPT from './locales/pt.json'
import TranslationSV from './locales/sv.json'

// Disallow null as a valid translation and prevent type errors such as 'string or null is not assignable to string or undefined'
// https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const resources = {
  de: {
    translation: TranslationDE,
  },
  en: {
    translation: TranslationEN,
  },
  es: {
    translation: TranslationES,
  },
  fi: {
    translation: TranslationFI,
  },
  nb: {
    translation: TranslationNB,
  },
  nl: {
    translation: TranslationNL,
  },
  pt: {
    translation: TranslationPT,
  },
  sv: {
    translation: TranslationSV,
  },
  ar: {
    translation: TranslationAR,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      lookupQuerystring: 'l',
    },

    returnNull: false,
    returnEmptyString: false,
    resources,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: '%{',
      suffix: '}',
    },
  })

export default i18n
