import { Trans, useTranslation } from 'react-i18next'
import { LinkButton } from '../../../../common/components/button/LinkButton'
import { getOldUIRoutes } from '../../../../routes'
import styles from '../BillingSettings.module.css'

export const SubscriptionActions: React.FC = () => {
  const { t } = useTranslation()
  const manageLicenceLink = getOldUIRoutes.manageLicence()

  return (
    <div className={styles.subscriptionActions}>
      <LinkButton
        label={t('settings_billing.update_subscription_button', 'Update subscription')}
        href={manageLicenceLink}
        target='_blank'
        rel='noreferrer'
        anchorStyle={{ letterSpacing: 'normal' }}
      />
      <p className='tiny'>
        <Trans i18nKey='settings_billing.cancel_licence_info_with_email'>
          To cancel your licence please contact
          <a className={styles.infoEmail} href='mailto:info@seppo.io'>
            info@seppo.io
          </a>
        </Trans>
      </p>
    </div>
  )
}
