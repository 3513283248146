import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { cloneLibraryGame, getPrivateLibraryGames, PAGE_SIZE } from '../api/gameApiService'
import { LibrarySource } from '../api/gameTypes'
import { parseLibraryGamesResponseToGameCards } from '../api/typeConverters'
import { PaginationSelectOption } from '../composites/GamesOverview/components/PaginationControls'
import { useGameNotifications } from '../hooks/useGameNotifications'
import { TGameCard } from '../types/commonTypes'
import { areObjectsEqual, noop } from '../util/functional'
import { GameDataProviderInterface, GameFilters, useGameData as useOwnGameData } from './OwnGamesContextProvider'

// TODO: Add last
const OrgLibraryGamesDataContext = createContext<GameDataProviderInterface>({
  currentPage: 1,
  pageSize: PAGE_SIZE,
  pageData: undefined,
  loading: true,
  lastPage: 1,
  allPageOptions: [] as PaginationSelectOption[],
  selectPageSize: noop,
  prev: noop,
  next: noop,
  setPage: noop,
  copyGame: noop,
  deleteGame: noop,
  importGame: noop,
  refreshGames: noop,
})

export const OrgLibraryGamesDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageData, setPageData] = useState<TGameCard[]>()
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)
  const [loading, setLoading] = useState(true)
  const [lastPage, setLastPage] = useState<number>(1)
  const [allPageOptions, setAllPageOptions] = useState<PaginationSelectOption[]>([{ value: 1, label: '1' }])
  const ownGameData = useOwnGameData()
  const [filtersState, setFiltersState] = useState<GameFilters>({})
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false)

  const { notifyGameImportStart, notifyGameImportFinish, notifyFetchingGamesFailed } = useGameNotifications()

  const next = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState + 1)
  }, [loading])

  const prev = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState - 1)
  }, [loading])

  const selectPageSize = useCallback(
    (newPageSize: number) => {
      if (loading) {
        return
      }

      setCurrentPage(1)
      setPageSize(newPageSize)
    },
    [loading],
  )

  const setPage = useCallback(
    (newPage: number) => {
      if (loading) {
        return
      }

      setCurrentPage(newPage)
    },
    [loading],
  )

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    getPrivateLibraryGames({ perPage: pageSize, page: currentPage, filters: filtersState })
      .then((orgLibraryGamesResponse) => {
        if (orgLibraryGamesResponse.success) {
          setLastPage(Math.ceil(orgLibraryGamesResponse.value.count / pageSize))
          setPageData(parseLibraryGamesResponseToGameCards(orgLibraryGamesResponse.value, LibrarySource.ORG))
        } else {
          notifyFetchingGamesFailed()
          console.error(orgLibraryGamesResponse.error)
        }
      })
      .finally(() => {
        setLoading(false)
        setInitialFetchDone(true)
      })
    return () => abortController.abort()
  }, [currentPage, pageSize, notifyFetchingGamesFailed, filtersState])

  useEffect(() => {
    const calculateAllPageOptions = Array.from({ length: lastPage }, (_, i) => ({
      value: i + 1,
      label: `${i + 1}`,
    }))
    setAllPageOptions(calculateAllPageOptions)
  }, [lastPage])

  const refreshGames = useCallback(() => {
    getPrivateLibraryGames({ perPage: pageSize, page: currentPage, filters: filtersState }).then(
      (orgLibraryGamesResponse) => {
        if (orgLibraryGamesResponse.success) {
          setPageData(parseLibraryGamesResponseToGameCards(orgLibraryGamesResponse.value, LibrarySource.ORG))
          setLastPage(Math.ceil(orgLibraryGamesResponse.value.count / pageSize))
        } else {
          console.error(orgLibraryGamesResponse.error)
        }
      },
    )
  }, [currentPage, pageSize, filtersState])

  const importGame = useCallback(
    async (gameId: number, gameName: string) => {
      const notifyId = notifyGameImportStart(gameName)
      const cloneResponse = await cloneLibraryGame({ gameId })
      if (cloneResponse.success) {
        ownGameData.refreshGames()
      } else {
        console.error(cloneResponse.error)
      }
      notifyGameImportFinish(notifyId, gameName, cloneResponse.success)
    },
    [notifyGameImportStart, notifyGameImportFinish, ownGameData],
  )

  const setFilters = useCallback((newFilters: GameFilters | Partial<GameFilters>, isPartial?: boolean) => {
    setFiltersState((prev) => {
      const filters = isPartial ? { ...prev, ...newFilters } : (newFilters as GameFilters)
      return areObjectsEqual(filters, prev) ? prev : filters
    })
  }, [])

  return (
    <OrgLibraryGamesDataContext.Provider
      value={{
        currentPage,
        pageSize,
        pageData,
        lastPage,
        allPageOptions,
        loading,
        initialFetchDone,
        filters: filtersState,
        setPage,
        selectPageSize,
        next,
        prev,
        copyGame: noop,
        deleteGame: noop,
        importGame,
        refreshGames,
        setFilters,
      }}
    >
      {children}
    </OrgLibraryGamesDataContext.Provider>
  )
}

export const useGameData = () => {
  const context = useContext(OrgLibraryGamesDataContext)

  return context
}
