import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getGame } from '../api/gameApiService'
import { parseGameResponseToGameMapsAndExercises } from '../api/typeConverters'
import { useNotification } from '../contexts/NotificationContext'
import { GameMapsAndExercises } from '../types/commonTypes'

export const useGetGameMapsAndExercises = (gameId: number | null, isLibrary?: boolean) => {
  const { t } = useTranslation()
  const { notifyError } = useNotification()

  const [data, setData] = useState<GameMapsAndExercises>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (gameId != null) {
      setLoading(true)
      getGame({ gameId, isLibrary })
        .then((result) => {
          if (result.success) {
            setData(parseGameResponseToGameMapsAndExercises(result.value))
          } else {
            console.error(result.error)
            notifyError({
              title: t('game_preview_modal.fetching_game_error_message.title', 'Failed to fetch game details'),
              content: t(
                'game_preview_modal.fetching_game_error_message.content',
                'An error occurred while loading the game maps and exercises. Please try again or contact us for support.',
              ),
            })
          }
        })
        .catch((e: any) => {
          console.error(e)
          notifyError({
            title: t('game_preview_modal.fetching_game_error_message.title', 'Failed to fetch game details'),
            content: t(
              'game_preview_modal.fetching_game_error_message.content',
              'An error occurred while loading the game maps and exercises. Please try again or contact us for support.',
            ),
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [gameId, isLibrary, notifyError, t])

  return { data, loading }
}
