import { useField } from 'react-final-form'
import styles from './ButtonRadioGroupField.module.css'

export type ButtonRadioItem = {
  value: string
  label: string
  disabled?: boolean
}

type ButtonRadioFieldProps = ButtonRadioItem & {
  name: string
}

export const ButtonRadioField: React.FC<ButtonRadioFieldProps> = ({ name, value, label, disabled }) => {
  const { input } = useField(name, { type: 'radio', value })

  return (
    <label className={styles.radioLabel}>
      <input className={styles.radioInput} {...input} disabled={disabled} />
      <span className={styles.radioContent}>{label}</span>
    </label>
  )
}
