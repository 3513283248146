import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Modal } from '../../../../../common/components/Modal/Modal'
import { Button } from '../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../common/components/icons/utils'
import { useConfirmation } from '../../../../../contexts/ConfirmationContext'
import { useGame } from '../../../../../contexts/GameContext'
import { useDisableBodyScroll } from '../../../../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../../../../hooks/useTrapFocus'
import { Badge } from '../../../../../types/commonTypes'
import { BadgeForm } from '../../../types'
import { BadgeList } from '../BadgeList/BadgeList'
import { BadgeModal } from '../BadgeModal/BadgeModal'
import styles from './BadgeManagerModal.module.css'

type BadgeManagerModalProps = {
  badges: Badge[]
  onClose: () => void
}

export const BadgeManagerModal: React.FC<BadgeManagerModalProps> = ({ badges, onClose }) => {
  const { t } = useTranslation()
  const { requestConfirmation } = useConfirmation()
  const { createOrUpdateBadge, deleteBadge } = useGame()

  const [showBadgeModal, setShowBadgeModal] = useState<boolean>(false)
  const [editBadge, setEditBadge] = useState<Badge>()

  const modalRef = useTrapFocus<HTMLDivElement>(!showBadgeModal)
  useDisableBodyScroll()

  const onOpenBadgeModal = () => setShowBadgeModal(true)

  const onCloseBadgeModal = async (hasUnsavedChanges: boolean) => {
    if (hasUnsavedChanges) {
      const closingConfirmed = await requestConfirmation({
        title: t('common.unsaved_changes_confirmation_title', 'Unsaved changes'),
        text: t(
          'common.unsaved_changes_confirmation_text',
          'Are you sure you want to exit? Unsaved changes will be lost.',
        ),
      })
      if (!closingConfirmed) {
        return
      }
    }
    setShowBadgeModal(false)
    setEditBadge(undefined)
  }

  const onEditBadge = (id: number) => {
    setEditBadge(badges.find((b) => b.id === id))
    onOpenBadgeModal()
  }

  const onDeleteBadge = async (badge: Badge) => {
    const confirmation = await requestConfirmation({
      title: t('game_editor.badges.delete_badge_confirmation_title', 'Removing a badge'),
      text: (
        <Trans
          i18nKey='game_editor.badges.delete_badge_confirmation_text'
          values={{ badge_name: badge.name }}
          components={{ bold: <b /> }}
        >
          {'Are you sure you want to remove <bold>%{badge_name}</bold>?'}
        </Trans>
      ),
    })
    if (confirmation && badge.id) {
      deleteBadge(badge.id)
    }
  }

  const onSubmitBadge = async (values: BadgeForm) => {
    const result = await createOrUpdateBadge(values)
    if (result) {
      onCloseBadgeModal(false)
    }
  }

  return (
    <>
      <Modal>
        <div className={styles.badgeManagerModal} ref={modalRef}>
          <div className={styles.badgeManagerHeader}>
            <div className={styles.badgeManagerHeaderTitle}>
              <span>{getIcon('badgeGradient')}</span>
              <span>{t('game_editor.badges.badge_manager_title', 'Manage badges')}</span>
            </div>
            <CloseButton autoFocus onClick={onClose} />
          </div>
          <div className={styles.badgeManagerDescription}>
            {t(
              'game_editor.badges.badge_manager_description',
              'Below you can see the badges you have active currently in this game.',
            )}
          </div>
          <div className={styles.badgeManagerMainSection}>
            <BadgeList badges={badges} onEditBadge={onEditBadge} onDeleteBadge={onDeleteBadge} />
            <Button className={styles.addBadgeButton} onClick={onOpenBadgeModal} variant='outline-tiny'>
              <span className='iconWrapperMedium'>{getIcon('badgeGradient')}</span>
              <span>{t('game_editor.badges.add_badge_button', 'Add a new badge')}</span>
            </Button>
          </div>
        </div>
      </Modal>
      {showBadgeModal && <BadgeModal editBadge={editBadge} onClose={onCloseBadgeModal} onSubmit={onSubmitBadge} />}
    </>
  )
}
