import * as React from 'react'
import { SVGProps } from 'react'
const SvgReceiptIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.625 2A4.375 4.375 0 0 0 7.25 6.375V21.25h-.875A4.375 4.375 0 0 0 2 25.625v3.5c0 .483.392.875.875.875h12.25a.875.875 0 0 0 .875-.875v-.875h6.125a2.625 2.625 0 0 0 2.625-2.625V10.167h4.375A.875.875 0 0 0 30 9.292V6.375A4.375 4.375 0 0 0 25.625 2h-14ZM24.75 3.75v4.667h3.5V6.375a2.625 2.625 0 0 0-2.625-2.625h-.875Zm-1.75 0H11.625A2.625 2.625 0 0 0 9 6.375V21.25h8.75a3.5 3.5 0 0 1 3.5 3.5v.875a.875.875 0 1 0 1.75 0V3.75ZM19.65 26.5a2.625 2.625 0 0 1-.15-.875v-.875a1.75 1.75 0 0 0-3.5 0v1.75h3.65Zm-5.4.875V24.75a3.5 3.5 0 0 1 .469-1.75H6.375a2.625 2.625 0 0 0-2.625 2.625v2.625h10.5v-.875ZM12.208 8.417a.875.875 0 0 0 0 1.75h7.584a.875.875 0 0 0 0-1.75h-7.584Zm-.875 4.958c0-.483.392-.875.875-.875h7.584a.875.875 0 0 1 0 1.75h-7.584a.875.875 0 0 1-.875-.875Zm.875 3.208a.875.875 0 0 0 0 1.75h7.584a.875.875 0 1 0 0-1.75h-7.584Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)
export default SvgReceiptIcon
