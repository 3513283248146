import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../../../common/components/PopupMenu/PopupActionMenu'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { IconTooltip } from '../../../../../../../common/components/tooltip/IconTooltip'
import { DeepPartial, LevelCriteria, Task } from '../../../../../../../types/commonTypes'
import { getLevelIcon, getLevelTitle } from '../../../../../../../util/game'
import { PreviousLevelsData } from '../../types'
import styles from './LevelHeader.module.css'

type LevelHeaderProps = {
  index: number
  criteria?: LevelCriteria
  previousLevelsData?: PreviousLevelsData
  noPointsGame?: boolean
  viewOnly: boolean
  onClickEdit?: (levelIndex: number) => void
  onClickRemove?: (levelIndex: number) => void
  onAddTask: (initialTask?: DeepPartial<Task>) => void
}

export const LevelHeader: React.FC<LevelHeaderProps> = ({
  index,
  criteria,
  previousLevelsData,
  noPointsGame,
  viewOnly,
  onClickEdit,
  onClickRemove,
  onAddTask,
}) => {
  const { t } = useTranslation()

  const title = getLevelTitle(index, t)
  const subtitle = index === 0 ? t('game_editor.levels.first_tasks_description', 'Played when joining') : criteria?.name

  const onClickEditInternal = () => onClickEdit?.(index)
  const onClickRemoveInternal = () => onClickRemove?.(index)

  const showWarning =
    criteria != null &&
    previousLevelsData != null &&
    (previousLevelsData.tasksCount < criteria.completedTasks ||
      (!noPointsGame && previousLevelsData.pointsSum < criteria.points))

  const onClickAddTask = () => onAddTask({ level: index, mapIndex: criteria?.defaultBoardIndex })

  return (
    <div className={styles.levelHeader}>
      <div>
        <span className={styles.levelHeaderIcon}>{getLevelIcon(index)}</span>
        <div className={styles.levelHeaderTitleContainer}>
          <span className={styles.levelHeaderTitle}>{title}</span>
          {subtitle !== title && <span className={classNames(styles.levelHeaderSubtitle, 'tiny')}>{subtitle}</span>}
        </div>
      </div>
      <div>
        {showWarning && (
          <IconTooltip
            tooltipContent={
              noPointsGame
                ? t(
                    'game_editor.levels.requirements_not_valid_warning_tasks_only',
                    'Level cannot be unlocked because the requirements cannot be met. To avoid issues in player app, lower the level requirements or add enough tasks to previous levels.',
                  )
                : t(
                    'game_editor.levels.requirements_not_valid_warning_tasks_and_points',
                    'Level cannot be unlocked because the requirements cannot be met. To avoid issues in player app, lower the level requirements or add enough tasks and points to previous levels.',
                  )
            }
            tooltipClassName={styles.levelRequirementWarningTooltip}
            iconName='alert'
            iconColor='var(--error-normal)'
          />
        )}
        {criteria && index > 1 && (
          <div className={classNames(styles.levelHeaderCriteria, 'tiny')}>
            <span className={styles.levelHeaderCriteriaIcon}>{getIcon('taskLocationLockedGradient')}</span>{' '}
            {criteria?.completedTasks}
            {!noPointsGame && (
              <>
                {' '}
                / <span className={styles.levelHeaderCriteriaIcon}>{getIcon('scoreGradient')}</span> {criteria?.points}
              </>
            )}
          </div>
        )}
        {!viewOnly && (
          <PopupActionMenu id={`levelCriteriaMenu_${index}`} buttonClassName={styles.levelHeaderMoreMenuButton}>
            {index > 0 && (
              <PopupActionMenuItem
                text={t('game_editor.levels.more_menu_edit_level_settings', 'Edit level settings')}
                onClick={onClickEditInternal}
                icon='text'
              />
            )}
            <PopupActionMenuItem
              text={t('game_editor.levels.more_menu_add_task_to_level', 'Add task to this level')}
              onClick={onClickAddTask}
              icon='pinAdd'
            />
            {index > 1 && (
              <PopupActionMenuItem
                text={t('game_editor.levels.more_menu_remove', 'Remove level')}
                onClick={onClickRemoveInternal}
                icon='trash'
              />
            )}
          </PopupActionMenu>
        )}
      </div>
    </div>
  )
}
