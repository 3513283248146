import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { safeIsNullOrEmpty } from '../../../../../../../../util/string'
import { AnswerContainer } from './AnswerContainer'
import { CollapsibleCardSection } from './CollapsibleCardSection'
import styles from './PictureOrSelfie.module.css'

type PictureOrSelfieProps = {
  imageUrl: string | null
  greeting: string | null
}

export const PictureOrSelfie: React.FC<PictureOrSelfieProps> = ({ imageUrl, greeting }) => {
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState<boolean>(true)
  const toggleExpanded = () => setExpanded((prev) => !prev)

  return (
    <CollapsibleCardSection
      isExpanded={expanded}
      icon='photo'
      onToggleExpanded={toggleExpanded}
      title={t('game_editor.sidebar.evaluate_answer_panel.picture_or_selfie', 'Picture or selfie')}
    >
      <AnswerContainer>
        {imageUrl && <img src={imageUrl} alt='' className={styles.selfieImage} />}
        {!safeIsNullOrEmpty(greeting) && <span className={classNames('tiny', styles.selfieGreeting)}>{greeting}</span>}
      </AnswerContainer>
    </CollapsibleCardSection>
  )
}
