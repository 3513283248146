import { TFunction } from 'i18next'
import { ButtonRadioItem } from '../../../../../../../common/components/Form/ButtonRadioGroupField/ButtonRadioField'
import { SelectableIconItem } from '../../../../../../../common/components/Form/CheckboxGroupField/SelectableIconFormField'
import { CreativeAnswerOption, PointsType } from '../../../../../../../types/commonTypes'
import { SelectOption } from '../../../../../../../common/components/Select/Select'

export const getTranslatedAnswerOptions = (t: TFunction): SelectableIconItem[] => [
  {
    id: CreativeAnswerOption.Text,
    iconName: 'text',
    text: t('game_editor.tasks.creative_task.answer_option_text_text', 'Text'),
    tooltipText: t('game_editor.tasks.creative_task.answer_option_text_tooltip', 'Allow users to type text as answer'),
    alwaysSelected: true,
  },
  {
    id: CreativeAnswerOption.Images,
    iconName: 'camera',
    text: t('game_editor.tasks.creative_task.answer_option_images_text', 'Images'),
    tooltipText: t(
      'game_editor.tasks.creative_task.answer_option_images_tooltip',
      'Allow users to take or upload images as answer',
    ),
  },
  {
    id: CreativeAnswerOption.Video,
    iconName: 'video',
    text: t('game_editor.tasks.creative_task.answer_option_video_text', 'Video'),
    tooltipText: t(
      'game_editor.tasks.creative_task.answer_option_video_tooltip',
      'Allow users to record or upload videos as answer',
    ),
  },
  {
    id: CreativeAnswerOption.Audio,
    iconName: 'audio',
    text: t('game_editor.tasks.creative_task.answer_option_audio_text', 'Audio'),
    tooltipText: t(
      'game_editor.tasks.creative_task.answer_option_audio_tooltip',
      'Allow users to record or upload audio as answer',
    ),
  },
]

export const getTranslatedPointsTypeOptions = (t: TFunction): ButtonRadioItem[] => {
  return [
    {
      value: PointsType.Evaluate,
      label: t('game_editor.tasks.creative_task.points_type_evaluate', 'Evaluate'),
    },
    {
      value: PointsType.Automatic,
      label: t('game_editor.tasks.creative_task.points_type_automatic', 'Automatic'),
    },
  ]
}

export const getTranslatedAutomaticPointsOptions = (t: TFunction): SelectOption[] => {
  return [
    {
      value: '25',
      label: '25%',
    },
    {
      value: '50',
      label: '50%',
    },
    {
      value: '75',
      label: '75%',
    },
    {
      value: '100',
      label: '100%',
    },
    {
      value: 'other',
      label: t('common.other', 'Other'),
    },
  ]
}
