import classNames from 'classnames'
import { AnswerStateEnum, MatchPairsAnswerOption, ReceivedAnswer } from '../../../../../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../../../../../util/string'
import styles from '../AnswerOptionLayout.module.css'
import { AnswerPoints } from '../AnswerPoints'
import { TimeExceededInfo } from '../TimeExceededInfo'
import { MatchPairsCorrect } from './MatchPairsCorrect'
import { MatchPairsIncorrect } from './MatchPairsIncorrect'

type MatchPairsAnswerProps = {
  answer: ReceivedAnswer
  answerOptions: MatchPairsAnswerOption[]
  noPointsGame?: boolean
}

type ParsedMatchPairAnswer = {
  leftIndex: number
  rightIndex: number
}

const getParsedMatchPairsAnswer = (answerRaw: string): ParsedMatchPairAnswer[] => {
  return answerRaw
    .split(';')
    .filter((pair) => !safeIsNullOrEmpty(pair))
    .map((pair) => ({ leftIndex: parseInt(pair.split(',')[0]), rightIndex: parseInt(pair.split(',')[1]) }))
    .sort((pairA, pairB) => (pairA.leftIndex < pairB.leftIndex ? -1 : 1))
}

export const MatchPairsAnswer: React.FC<MatchPairsAnswerProps> = ({ answer, answerOptions, noPointsGame }) => {
  if (safeIsNullOrEmpty(answer.answer)) {
    return <TimeExceededInfo />
  }

  const answerPairs = getParsedMatchPairsAnswer(answer.answer)

  return (
    <div
      className={classNames(
        styles.answerOptionsTable,
        answer.state === AnswerStateEnum.TIME_FINISHED && styles.timeExceededFadeout,
      )}
    >
      {answerOptions.map((option, index) => {
        const currAnswerPair = answerPairs.find((pair) => pair.leftIndex === index)
        const isCorrect = currAnswerPair != null && currAnswerPair.leftIndex === currAnswerPair.rightIndex
        return (
          <div className={styles.answerOptionRow} key={`${answer.id}_matchPairs_${index}`}>
            {isCorrect ? (
              <MatchPairsCorrect answerOption={option} />
            ) : (
              <MatchPairsIncorrect
                answerOption={option}
                selectedWrong={currAnswerPair == null ? undefined : answerOptions[currAnswerPair.rightIndex].right}
              />
            )}
            <AnswerPoints
              points={isCorrect ? answerOptions[index].points ?? 0 : 0}
              hidePoints={noPointsGame || answer.state === AnswerStateEnum.TIME_FINISHED}
            />
          </div>
        )
      })}
    </div>
  )
}
