import * as React from 'react'
import { SVGProps } from 'react'

const SvgCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.774 1.218a1 1 0 0 0-1.414 0L8.996 7.582 2.632 1.218a1 1 0 1 0-1.414 1.414l6.364 6.364-6.364 6.364a1 1 0 1 0 1.414 1.415l6.364-6.364 6.364 6.364a1 1 0 1 0 1.415-1.415L10.41 8.996l6.364-6.364a1 1 0 0 0 0-1.414Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)

export default SvgCloseIcon
