import classNames from 'classnames'
import { PropsWithChildren, useEffect, useId, useState } from 'react'
import {
  PopupActionMenu,
  PopupActionMenuItem,
  PopupActionMenuItemProps,
} from '../../../../../common/components/PopupMenu/PopupActionMenu'
import { ArrowRightIcon } from '../../../../../common/components/icons'
import styles from './TaskModal.module.css'

type TaskFormSectionProps = {
  title: string
  initialIsExpanded?: boolean
  containerClassName?: string
  moreMenuItems?: PopupActionMenuItemProps[]
  forceExpandToken?: number
  hasAnyErrors?: boolean
}

export const TaskFormSection: React.FC<PropsWithChildren<TaskFormSectionProps>> = ({
  title,
  initialIsExpanded,
  containerClassName,
  moreMenuItems,
  children,
  forceExpandToken,
  hasAnyErrors,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(initialIsExpanded ?? true)
  const onToggleExpanded = () => setIsExpanded((prev) => !prev)
  const id = useId()

  useEffect(() => {
    if (forceExpandToken && hasAnyErrors) {
      setIsExpanded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceExpandToken])

  return (
    <div className={classNames(styles.taskFormSection, containerClassName)}>
      <div className={classNames(styles.taskFormSectionHeader, isExpanded && styles.taskFormSectionHeader_expanded)}>
        <button type='button' onClick={onToggleExpanded}>
          <span>
            <ArrowRightIcon color={'currentColor'} />
          </span>
          <h4>{title}</h4>
        </button>
        {moreMenuItems != null && moreMenuItems.length && (
          <PopupActionMenu
            id={`task-more-menu_${id}`}
            position='inline-down'
            buttonClassName={styles.taskMoreMenuButton}
          >
            {moreMenuItems.map((item) => (
              <PopupActionMenuItem
                key={`task-more-menu_${id}_${item.text}`}
                icon={item.icon}
                text={item.text}
                onClick={item.onClick}
              />
            ))}
          </PopupActionMenu>
        )}
      </div>
      <div className={classNames(styles.taskFormSectionChildren, isExpanded && styles.taskFormSectionChildren_visible)}>
        {children}
      </div>
    </div>
  )
}
