import classNames from 'classnames'
import { Badge } from '../../../../../types/commonTypes'
import { BadgeView } from '../BadgeView/BadgeView'
import styles from './BadgeList.module.css'
import { useTranslation } from 'react-i18next'

type BadgeListProps = {
  badges: Badge[]
  onEditBadge?: (badgeId: number) => void
  onDeleteBadge?: (badge: Badge) => void
}

export const BadgeList: React.FC<BadgeListProps> = ({ badges, onEditBadge, onDeleteBadge }) => {
  const { t } = useTranslation()

  return badges.length > 0 ? (
    <ul className={styles.badgesList}>
      {badges.map((badge) => (
        <li className={styles.badgeListItem} key={`badgeView_${badge.id}`}>
          <BadgeView badge={badge} onClickEdit={onEditBadge} onClickDelete={onDeleteBadge} />
        </li>
      ))}
    </ul>
  ) : (
    <span className={classNames('detailGrey700', styles.noBadgesText)}>
      {t('game_editor.badges.no_badges_text', 'No badges currently')}
    </span>
  )
}
