import classNames from 'classnames'
import { MatchPairsAnswerOption } from '../../../../../../../../../types/commonTypes'
import layoutStyles from '../AnswerOptionLayout.module.css'
import { AnswerStatus, AnswerStatusIcon } from '../AnswerStatusIcon'
import styles from './MatchPairsAnswer.module.css'
import { MatchPairsSide } from './MatchPairsSide'

type MatchPairsCorrectProps = {
  answerOption: MatchPairsAnswerOption
}

export const MatchPairsCorrect: React.FC<MatchPairsCorrectProps> = ({ answerOption }) => {
  return (
    <div
      className={classNames(layoutStyles.answerOptionLeftColumn, styles.matchPairsContainer, styles.matchPairsSuccess)}
    >
      <div className={styles.matchPairsCorrectRow}>
        <MatchPairsSide side={answerOption.left} />
        <MatchPairsSide side={answerOption.right} />
      </div>
      <AnswerStatusIcon status={AnswerStatus.SUCCESS} />
    </div>
  )
}
