import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { MatchPairsAnswerOption, MatchPairsSubtask } from '../../../../../../../types/commonTypes'
import { AnswerOptionsList } from '../components/AnswerOptionsList'
import { MatchPairsAnswerOption as MatchPairsAnswerOptionComponent } from './MatchPairsAnswerOption'

type MatchPairsSubtaskProps = {
  value: MatchPairsSubtask
  name: string
  noPoints?: boolean
  viewOnly: boolean
}

const mapFieldToAnswerOption = (
  name: string,
  index: number,
  fields: FieldArrayRenderProps<MatchPairsAnswerOption, any>['fields'],
  task: MatchPairsSubtask,
  viewOnly: boolean,
  noPoints?: boolean,
): JSX.Element => {
  return (
    <MatchPairsAnswerOptionComponent
      index={index}
      key={name}
      name={name}
      hasFixedPoints={task.data.allAnswersHaveSamePoints}
      fixedPoints={task.data.allAnswersPoints}
      onDelete={() => fields.remove(index)}
      noPoints={noPoints}
      viewOnly={viewOnly}
    />
  )
}

export const MatchPairsSubtaskFields: React.FC<MatchPairsSubtaskProps> = ({ value, name, noPoints, viewOnly }) => {
  return (
    <AnswerOptionsList<MatchPairsSubtask>
      name={name}
      task={value}
      noPoints={noPoints}
      mapFieldToAnswerOption={mapFieldToAnswerOption}
      viewOnly={viewOnly}
    />
  )
}
