import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '../../../../common/components/MenuItem/MenuItem'
import { MENU_ITEM_TYPE_SUBMENU, USER_MENU_ACCOUNT, USER_MENU_LANGUAGE } from '../../../../common/constants'
import { Business } from '../../../../types/commonTypes'
import styles from '../../UserMenu.module.css'

export const InitialMenu: React.FC<{
  onMenuItemClick: (menuItemName: string) => void
  userBelongsToSeveralBusinesses: boolean
  activeLanguage: string
  activeBusiness?: Business
  showSettingsLink: boolean
}> = ({ onMenuItemClick, userBelongsToSeveralBusinesses, activeLanguage, activeBusiness, showSettingsLink }) => {
  const onMenuItemClickInternal = (menuItemName: string) => {
    onMenuItemClick(menuItemName)
  }
  const { t } = useTranslation()

  return (
    <>
      {showSettingsLink && (
        <MenuItem
          fontSize='normal'
          icon='settings'
          text={t('user_menu.general_settings', 'General settings')}
          menuItemName='generalSettings'
          isUserMenu
          onMenuItemClick={onMenuItemClickInternal}
        />
      )}
      <MenuItem
        fontSize='normal'
        icon='language'
        text={t('user_menu.language', 'Language')}
        activeLanguage={activeLanguage}
        menuItemName={USER_MENU_LANGUAGE}
        itemType={MENU_ITEM_TYPE_SUBMENU}
        isUserMenu
        onMenuItemClick={onMenuItemClickInternal}
      />
      <div className={styles.rowSeparator} />
      {userBelongsToSeveralBusinesses && activeBusiness && (
        <MenuItem
          fontSize='normal'
          icon='userIdle'
          text={t('user_menu.change_user', 'Change User')}
          activeBusiness={activeBusiness.name}
          menuItemName={USER_MENU_ACCOUNT}
          itemType={MENU_ITEM_TYPE_SUBMENU}
          isUserMenu
          onMenuItemClick={onMenuItemClickInternal}
        />
      )}
      <MenuItem
        fontSize='normal'
        icon='logout'
        text={t('user_menu.log_out', 'Log out')}
        menuItemName='logout'
        isUserMenu
        onMenuItemClick={onMenuItemClickInternal}
      />
      {process.env.REACT_APP_ENV === 'development' && (
        <MenuItem
          fontSize='normal'
          icon='debug'
          text='Show debug options'
          menuItemName='debug'
          isUserMenu
          onMenuItemClick={onMenuItemClickInternal}
        />
      )}
    </>
  )
}
