import { useTranslation } from 'react-i18next'
import styles from '../BillingSettings.module.css'

type CurrentPlanProps = {
  licenceCount: number
  autoRenew: boolean
  renewCycle: number
  nextInvoiceDate: string
  nextInvoicePrice?: number
}

export const CurrentPlan: React.FC<CurrentPlanProps> = ({
  autoRenew,
  licenceCount,
  nextInvoiceDate,
  nextInvoicePrice,
  renewCycle,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.subSection}>
      <h3 className={styles.subSectionTitle}>{t('settings_billing.current_plan_title', 'Current plan')}</h3>
      <h2>
        {t('settings_billing.current_plan_licence_count_info', {
          licence_count: licenceCount,
          defaultValue: '%{licence_count} licences/players',
        })}
      </h2>
      {autoRenew ? (
        <p>
          {t('settings_billing.current_plan_renew_date', {
            plan_month_count: renewCycle,
            next_renewal_date: nextInvoiceDate,
            next_price: nextInvoicePrice,
            defaultValue:
              'Your %{plan_month_count} month plan renews automatically %{next_renewal_date} (%{next_price}€)',
          })}
        </p>
      ) : (
        <p>
          {t('settings_billing.current_plan_expire_date', {
            plan_month_count: renewCycle,
            next_renewal_date: nextInvoiceDate,
            defaultValue: 'Your %{plan_month_count} month plan expires %{next_renewal_date}',
          })}
        </p>
      )}
    </div>
  )
}
