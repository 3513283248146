import * as React from 'react'
import { SVGProps } from 'react'
const SvgUserIdleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15.955 5.081a4.794 4.794 0 0 1 4.814 4.814c0 2.585-2.14 4.724-4.814 4.724-2.585 0-4.724-2.139-4.724-4.724 0-2.674 2.139-4.814 4.724-4.814ZM19.61 16.757c2.852 0 5.081 2.05 5.437 4.814-1.782 3.209-5.259 5.26-9.092 5.26-3.744 0-7.22-2.05-9.093-5.26.357-2.764 2.674-4.814 5.438-4.814h7.31Z'
      fill={props.color ?? '#6F6F6F'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16Zm-2 0c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14Z'
      fill={props.color ?? '#6F6F6F'}
    />
  </svg>
)
export default SvgUserIdleIcon
