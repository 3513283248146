import classNames from 'classnames'
import { useState } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import {
  ERROR_ROUNDED_OUTLINE_CLASS_NAME,
  FormFieldWithError,
} from '../../../common/components/Form/FormFieldError/FormFieldWithError'
import { SelectableOption } from '../../../common/components/selectable/SelectableOption'
import { safeIsNullOrEmpty } from '../../../util/string'
import styles from '../OnboardingWizard.module.css'
import { OTHER_OPTION_VALUE } from '../constants'
import { OtherInput } from './OtherInput'

export type RadioOptionsWithOtherFieldProps = {
  name: string
  selectOptions: { value: string; labelKey: string }[]
  otherFieldLabel: string
  allowOtherOption?: boolean
}

export const RadioOptionsWithOtherField: React.FC<RadioOptionsWithOtherFieldProps> = ({
  name,
  otherFieldLabel,
  selectOptions,
  allowOtherOption = true,
}) => {
  const { t } = useTranslation()
  const { input } = useField(name)
  const { input: otherInput } = useField(`${name}${OTHER_OPTION_VALUE}`)

  const [showOtherField, setShowOtherField] = useState<boolean>(false)

  const openOtherField = () => {
    if (input.value !== OTHER_OPTION_VALUE) {
      input.onChange(OTHER_OPTION_VALUE)
    }
    if (!showOtherField) {
      setShowOtherField(true)
    }
  }

  const onKeyDownOther = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      e.preventDefault()
      openOtherField()
    }
  }

  const onConfirmOtherValue = () => {
    if (safeIsNullOrEmpty(otherInput.value)) {
      input.onChange(null)
    } else {
      input.onChange(OTHER_OPTION_VALUE)
    }
    setShowOtherField(false)
  }

  const updateSelectedOption = (value: string) => {
    input.onChange(value)
  }

  return !showOtherField ? (
    <FormFieldWithError
      name={name}
      errorClassName={styles.selectableOptionsWithOtherError}
      wrapperClassName={classNames(styles.selectableOptionsContainer, ERROR_ROUNDED_OUTLINE_CLASS_NAME)}
    >
      {selectOptions.map(({ value, labelKey }, i) => {
        return (
          <SelectableOption
            key={`${value}:${i}`}
            id={`${value}`}
            groupName={name}
            text={t(labelKey)}
            selectedOption={input.value}
            onChange={updateSelectedOption}
          />
        )
      })}
      {allowOtherOption && (
        <SelectableOption
          text={otherInput.value || t('onboarding_wizard.default_other_label_text', 'Other')}
          id={OTHER_OPTION_VALUE}
          selectedOption={input.value}
          groupName={name}
          onChange={openOtherField}
          onClick={openOtherField}
          onKeyDown={onKeyDownOther}
        />
      )}
    </FormFieldWithError>
  ) : (
    <OtherInput
      name={`${name}${OTHER_OPTION_VALUE}`}
      onConfirmInput={onConfirmOtherValue}
      placeholder={otherFieldLabel}
    />
  )
}
