import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../common/components/icons/utils'
import styles from './Points.module.css'
import { PointsWidgetSizeVariant, pointsWidthByLng } from './PointsInput'

type PointsPreviewProps = {
  points?: number
  isFinal?: boolean
  sizeVariant?: PointsWidgetSizeVariant
}

export const PointsPreview: React.FC<PointsPreviewProps> = ({ points, isFinal, sizeVariant = 'big' }) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        styles[`pointsWidget_${sizeVariant}`],
        styles.pointsPreviewContainer,
        points == null && (isFinal ? styles.pointsPreviewContainer_noPoints : styles.pointsPreviewContainer_addPoints),
      )}
    >
      <span className={styles.pointsPreviewIcon}>{getIcon('scoreHeader')}</span>
      <span className={classNames('tiny', 'bold', styles.pointsPreviewText)}>
        {points == null
          ? isFinal
            ? t('game_editor.points.no_points_preview_label', 'No points')
            : t('game_editor.points.default_input_placeholder', 'Add points')
          : t('game_editor.points.points_preview_label', { defaultValue: '%{count} points', count: points })}
      </span>
    </div>
  )
}

type PointsInputPreviewProps = {
  disabled?: boolean
  sizeVariant?: PointsWidgetSizeVariant
}

export const PointsInputPreview: React.FC<PointsInputPreviewProps> = ({ disabled, sizeVariant = 'big' }) => {
  const { t, i18n } = useTranslation()

  return (
    <div
      className={classNames(
        styles[`pointsWidget_${sizeVariant}`],
        styles.pointsInputContainer,
        styles.pointsInputContainer_preview,
        disabled && styles.pointsInputContainer_disabled,
      )}
    >
      <div className={styles.pointsIcon}>{getIcon('scoreHeader')}</div>
      <input
        disabled
        className={styles.pointsInput}
        placeholder={t('game_editor.points.default_input_placeholder', 'Add points')}
        style={{ width: pointsWidthByLng?.[i18n.language] ?? 120 }}
      />
    </div>
  )
}
