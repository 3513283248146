import { GameBoard } from '../../../types'

export const OVERVIEW_WIDTH = 700
export const OVERVIEW_HEIGHT = 450
export const THUMBNAIL_IMAGE_SIZE = 100
export const THUMBNAIL_BOTTOM = 26

export const getX = (board: GameBoard) => {
  return ((board.worldPosition?.[0] ?? 0) / 100) * OVERVIEW_WIDTH
}

export const getY = (board: GameBoard, yAdjust = 0) => {
  return OVERVIEW_HEIGHT - ((board.worldPosition?.[1] ?? 0) / 100) * OVERVIEW_HEIGHT + yAdjust
}

export const getRelativeX = (ux3X: number) => {
  return (ux3X / OVERVIEW_WIDTH) * 100
}

export const getRelativeY = (ux3Y: number, yAdjust = 0) => {
  return ((OVERVIEW_HEIGHT - ux3Y + yAdjust) / OVERVIEW_HEIGHT) * 100
}
