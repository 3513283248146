import { KeyboardEventHandler, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlayerAccount, Tag, Task } from '../../../../../../types/commonTypes'
import styles from './PlayersAddByPlayerAccountModal.module.css'
import { Button } from '../../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../../common/components/button/CloseButton'
import { useDebounce } from '../../../../../../hooks/useDebounce'
import { getIcon } from '../../../../../../common/components/icons/utils'
import { searchPlayerAccounts } from '../../../../../../api/userApiService'
import { useNotification } from '../../../../../../contexts/NotificationContext'
import { parsePlayerAccountsFromPlayerAccountsSearch } from '../../../../../../api/typeConverters'
import { isEmpty } from '../../../../../../util/functional'
import classNames from 'classnames'
import { Modal } from '../../../../../../common/components/Modal/Modal'
import { StartingTaskSelection } from '../StartingTaskSelection'
import { Form, FormSpy } from 'react-final-form'
import { SearchDropdown } from './SearchDropdown'
import { ActiveTagsArea } from './ActiveTagsArea'
import { PlayerAccountSearchResults } from './PlayerAccountSearchResults'
import { TagSearchResults } from './TagSearchResults'
import { PlayerAccountsSublist } from './PlayerAccountsSublist'
import { ToBeAddedList } from './ToBeAddedList'

type PlayersAddByPlayerAccountModalProps = {
  onAddMultiple: (tagIds: number[] | null, playerAccountIds: number[] | null, startingTask?: number) => void
  onClose: () => void
  tags: Tag[]
  existingPlayerCount: number
  maxPlayerCount?: number | null
  tasks: Task[]
  isOrdered?: boolean
}

export type Search = {
  search: string
  tagIds: string[]
  page: number
  showAll?: boolean
}

type StartingTaskForm = {
  showStartingTaskSelection: boolean
  startingTask?: number
}

export const EMPTY_SEARCH: Search = { search: '', tagIds: [], page: 1 }
export const MAX_PLAYERS_COLLAPSED_COUNT = 5
export const MAX_TAGS_COUNT = 1000
export const MAX_PLAYERS_SEE_ALL_COUNT = 1000

export const PlayersAddByPlayerAccountModal: React.FC<PlayersAddByPlayerAccountModalProps> = ({
  onAddMultiple,
  onClose,
  tags,
  existingPlayerCount,
  maxPlayerCount,
  tasks,
  isOrdered,
}) => {
  const { t } = useTranslation()
  const [showDropdowntTagResults, setShowDropdownTagResults] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const [dropdownMatchingTags, setDropdownMatchingTags] = useState<Tag[]>([])
  const [activeFilterTags, setActiveFilterTags] = useState<Tag[]>([])
  const [search, setSearch] = useState<Search>(EMPTY_SEARCH)
  const [searching, setSearching] = useState(false)
  const debouncedSearch = useDebounce(search, 1000)
  //showAllPlayers is needed to launch search to show all players from seearch dropdown
  const [showAllPlayers, setShowAllPlayers] = useState(false)
  //Changing maxPlayersDisplayCount to MAX_PLAYERS_SEE_ALL_COUNT allows to show all based on current search
  const [maxPlayersDisplayCount, setMaxPlayersDisplayCount] = useState<number>(MAX_PLAYERS_COLLAPSED_COUNT)

  const [searchMatchingTags, setSearchMatchingTags] = useState<Tag[]>([])
  const [searchMatchingPlayerAccounts, setSearchMatchingPlayerAccounts] = useState<PlayerAccount[]>([])
  const [totalMatchingPlayerAccountCount, setTotalMatchingPlayerAccountCount] = useState(0)
  const [resultsOpenedTagPlayerAccounts, setResultsOpenedTagPlayerAccounts] = useState<PlayerAccount[] | null>(null)
  const [resultsOpenedTagTitle, setResultsOpenedTagTitle] = useState('')
  const [resultsSubSearching, setResultsSubSearching] = useState(false)
  const [toAddOpenedTagPlayerAccounts, setToAddOpenedTagPlayerAccounts] = useState<PlayerAccount[] | null>(null)
  const [toAddOpenedTagTitle, setToAddOpenedTagTitle] = useState('')
  const [toAddSubSearching, setToAddSubSearching] = useState(false)

  const [toAddPlayerAccounts, setToAddPlayerAccounts] = useState<PlayerAccount[]>([])
  const [toAddTags, setToAddTags] = useState<Tag[] | []>([])

  const [submitting, setSubmitting] = useState(false)

  const [startingTask, setStartingTask] = useState<number | undefined>()
  const [totalCountToAdd, setTotalCountToAdd] = useState(0)
  const { notifyError } = useNotification()

  const handleKeyDown: KeyboardEventHandler = useCallback(
    (event) => {
      switch (event.key) {
        case 'Enter':
          //Enter changes the search to launch the debounced full search
          if (searchInputValue.length > 0 || !isEmpty(activeFilterTags)) {
            setSearching(true)
            setShowAllPlayers(false)
            setMaxPlayersDisplayCount(MAX_PLAYERS_COLLAPSED_COUNT)
            setShowDropdownTagResults(false)
            setSearch({ search: searchInputValue, tagIds: activeFilterTags.map((tag) => tag.id.toString()), page: 1 })
          }
          if (!searchInputValue.length) {
            setSearchMatchingPlayerAccounts([])
            setTotalMatchingPlayerAccountCount(0)
            setSearchMatchingTags([])
            setSearch(EMPTY_SEARCH)
          }
          event.preventDefault()
          return
        default:
          setShowDropdownTagResults(true)
          return
      }
    },
    [activeFilterTags, searchInputValue],
  )

  //Instant tag results to dropdown
  useEffect(() => {
    if (searchInputValue.length > 0)
      setDropdownMatchingTags(
        tags.filter(
          (t) =>
            t.label.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) &&
            !activeFilterTags.find((t2) => t.id === t2.id),
        ),
      )
    else setDropdownMatchingTags([])
  }, [searchInputValue, tags, activeFilterTags])

  const handleClearInputAndSearch = useCallback(() => {
    setShowAllPlayers(false)
    setSearchMatchingPlayerAccounts([])
    setTotalMatchingPlayerAccountCount(0)
    setMaxPlayersDisplayCount(MAX_PLAYERS_COLLAPSED_COUNT)
    setSearchMatchingTags([])
    setSearch(EMPTY_SEARCH)
    setSearchInputValue('')
  }, [])

  //Debounced searching for Tags to the center column
  useEffect(() => {
    if (debouncedSearch.search.length > 0 && !showAllPlayers) {
      setSearchMatchingTags(
        tags.filter(
          (t) =>
            t.label.toLocaleLowerCase().includes(debouncedSearch.search.toLocaleLowerCase()) &&
            !activeFilterTags.find((t2) => t.id === t2.id),
        ),
      )
    } else {
      setSearchMatchingTags([])
    }
  }, [activeFilterTags, debouncedSearch, tags, showAllPlayers])

  //Debounced searching for the Player account results to center column
  useEffect(() => {
    if (
      debouncedSearch.search.length > 0 ||
      !isEmpty(debouncedSearch.tagIds) ||
      showAllPlayers ||
      maxPlayersDisplayCount === MAX_PLAYERS_SEE_ALL_COUNT
    ) {
      searchPlayerAccounts(showAllPlayers ? { ...EMPTY_SEARCH, showAll: true } : debouncedSearch).then(
        (playerAccountResponse): any => {
          setSearching(false)
          if (playerAccountResponse.success) {
            const playerAccounts: PlayerAccount[] = parsePlayerAccountsFromPlayerAccountsSearch(
              playerAccountResponse.value,
            )
            setTotalMatchingPlayerAccountCount(playerAccountResponse.value.total_players)
            setSearchMatchingPlayerAccounts(playerAccounts)
          } else {
            notifyError({
              title: t('game_editor.add_people.failed_to_search_player_accounts.title', 'Search failed'),
              content: t(
                'game_editor.add_people.failed_to_search_player_accounts.content',
                'An error occurred while searching players. Please try again or contact us for support.',
              ),
              timeout: 3000,
            })
          }
        },
      )
    } else {
      setSearching(false)
      setTotalMatchingPlayerAccountCount(0)
      setSearchMatchingPlayerAccounts([])
    }
  }, [debouncedSearch, notifyError, t, maxPlayersDisplayCount, showAllPlayers])

  useEffect(() => {
    if (showAllPlayers) {
      setSearchInputValue('')
      setActiveFilterTags([])
      setSearch({ ...EMPTY_SEARCH, showAll: true })
    }
  }, [showAllPlayers])

  //Launches the full debounced search when active filters change, or if maxPlayersDisplayCount is changed
  useEffect(() => {
    setSearch((prev) => {
      return {
        ...prev,
        showAll: maxPlayersDisplayCount === MAX_PLAYERS_SEE_ALL_COUNT,
        tagIds: activeFilterTags.map((tag) => tag.id.toString()),
      }
    })
    setSearching(true)
  }, [activeFilterTags, maxPlayersDisplayCount])

  //Subsearch when tag is opened from center or right column
  const handleDoTagSubSearch = useCallback(
    (tag: Tag, isCenterColumn: boolean) => {
      isCenterColumn ? setResultsOpenedTagTitle(tag?.label ?? '') : setToAddOpenedTagTitle(tag?.label ?? '')
      isCenterColumn ? setResultsSubSearching(true) : setToAddSubSearching(true)
      isCenterColumn ? setResultsOpenedTagPlayerAccounts([]) : setToAddOpenedTagPlayerAccounts([])

      const tagSearch: Search = { search: '', tagIds: tag ? [tag.id.toString()] : [], page: 1, showAll: true }

      searchPlayerAccounts(tagSearch).then((playerAccountResponse) => {
        isCenterColumn ? setResultsSubSearching(false) : setToAddSubSearching(false)
        if (playerAccountResponse.success) {
          const playerAccounts: PlayerAccount[] = parsePlayerAccountsFromPlayerAccountsSearch(
            playerAccountResponse.value,
          )
          isCenterColumn
            ? setResultsOpenedTagPlayerAccounts(playerAccounts)
            : setToAddOpenedTagPlayerAccounts(playerAccounts)
        } else {
          notifyError({
            title: t('game_editor.add_people.failed_to_fetch_player_accounts.title', 'Fetch failed'),
            content: t(
              'game_editor.add_people.failed_to_fetch_player_accounts.content',
              'An error occurred while fetching players. Please try again or contact us for support.',
            ),
            timeout: 3000,
          })
        }
      })
    },
    [notifyError, t],
  )

  const handlePlayerAccountSelected = useCallback((playerAccount: PlayerAccount, toSelected: boolean) => {
    if (toSelected) {
      setToAddPlayerAccounts((prev) => prev.filter((pa) => pa.id !== playerAccount.id).concat(playerAccount))
    } else setToAddPlayerAccounts((prev) => prev.filter((pa) => pa.id !== playerAccount.id))
  }, [])

  const handleTagSelected = useCallback((tag: Tag, toSelected: boolean) => {
    if (toSelected) {
      setToAddTags((prev) => prev.filter((t) => t.id !== tag.id).concat(tag))
    } else setToAddTags((prev) => prev.filter((t) => t.id !== tag.id))
  }, [])

  const handleAddActiveTag = useCallback((tag: Tag) => {
    setShowAllPlayers(false)
    setActiveFilterTags((prev) => prev.filter((t) => t.id !== tag.id).concat(tag))
  }, [])

  const handleRemoveActiveTag = useCallback((tag: Tag) => {
    setActiveFilterTags((prev) => prev.filter((t) => t.id !== tag.id))
  }, [])

  useEffect(() => {
    const playersFromTags = toAddTags.map((t) => t.playerCount ?? 0).reduce((acc, c) => acc + c, 0)
    setTotalCountToAdd(toAddPlayerAccounts.length + playersFromTags)
  }, [toAddPlayerAccounts.length, toAddTags])

  const handleAddMultiplePlayerAccountsInternal = useCallback(
    (evt: any) => {
      setSubmitting(true)
      onAddMultiple(
        toAddTags.map((tag) => tag.id),
        toAddPlayerAccounts.map((pa) => pa.id),
        startingTask,
      )
      onClose()
    },
    [onAddMultiple, onClose, toAddPlayerAccounts, toAddTags, startingTask],
  )

  return (
    <Modal>
      <div className={styles.playerAccountModal}>
        <div className={styles.playersAddByPlayerAccountHeader}>
          <div className={styles.playersAddByPlayerAccountHeaderTitle}>
            <span>{getIcon('multipleUsers')}</span>
            <span>
              {t('game_editor.add_people.player_account.modal_title_add_players', 'Add players with Player accounts')}
            </span>
          </div>
          <CloseButton autoFocus onClick={() => onClose()} />
        </div>
        <div className={styles.infoText}>
          {t(
            'game_editor.add_people.player_account_modal.top_info_text',
            'Search and add Player accounts to the game. Number on the tags tells the number of player accounts labeled with the tag. Total number on the Add button may include duplicates.',
          )}
        </div>
        <div className={styles.contentArea}>
          {/* LEFT COLUMN - SEARCH AND ACTIVE TAGS */}
          <div className={styles.contentColumn}>
            <div className={styles.contentTitle}>
              {t('game_editor.add_people.player_account.search_people_or_tag', 'Search people or tags')}
            </div>
            <SearchDropdown
              searchInputValue={searchInputValue}
              onKeyDown={handleKeyDown}
              onSetSearchInputValue={setSearchInputValue}
              onClearInputAndSearch={handleClearInputAndSearch}
              onShowAllPlayers={setShowAllPlayers}
              onShowDropdownTagResults={setShowDropdownTagResults}
              showDropdownTagResults={showDropdowntTagResults}
              tags={tags}
              dropdownMatchingTags={dropdownMatchingTags}
              onAddActiveTag={handleAddActiveTag}
            />
            <ActiveTagsArea
              activeFilterTags={activeFilterTags}
              onSetActiveFilterTags={setActiveFilterTags}
              onRemoveActiveTag={handleRemoveActiveTag}
              onTagSelected={handleTagSelected}
              toAddTags={toAddTags}
            />
          </div>

          {/* MIDDLE COLUMN - SEARCH RESULTS AREA */}
          <div className={styles.contentColumn}>
            <div className={styles.contentTitle}>
              {t('game_editor.add_people.player_account.search_results', 'Search results')}
            </div>
            {!resultsOpenedTagPlayerAccounts && (
              <div className={styles.contentList}>
                <PlayerAccountSearchResults
                  onPlayerAccountSelected={handlePlayerAccountSelected}
                  maxPlayersDisplayCount={maxPlayersDisplayCount}
                  searchInputValue={searchInputValue}
                  searchMatchingPlayerAccounts={searchMatchingPlayerAccounts}
                  searching={searching}
                  toAddPlayerAccounts={toAddPlayerAccounts}
                  onShowAllPlayers={setShowAllPlayers}
                  setMaxPlayersDisplayCount={setMaxPlayersDisplayCount}
                  totalMatchingPlayerAccountCount={totalMatchingPlayerAccountCount}
                />
                <div
                  className={classNames(
                    styles.separator,
                    (searching || searchMatchingPlayerAccounts.length > 0) && styles.separatorMoreSpace,
                  )}
                />
                <TagSearchResults
                  onAddActiveTag={handleAddActiveTag}
                  onDoTagSubSearch={handleDoTagSubSearch}
                  onTagSelected={handleTagSelected}
                  searchInputValue={searchInputValue}
                  searchMatchingTags={searchMatchingTags}
                  toAddTags={toAddTags}
                />
              </div>
            )}
            {resultsOpenedTagPlayerAccounts && (
              <PlayerAccountsSublist
                onPlayerAccountSelected={handlePlayerAccountSelected}
                playerAccounts={resultsOpenedTagPlayerAccounts}
                onBack={() => setResultsOpenedTagPlayerAccounts(null)}
                sublistTitle={resultsOpenedTagTitle}
                searching={resultsSubSearching}
                searchInputValue={searchInputValue}
                toAddPlayerAccounts={toAddPlayerAccounts}
              />
            )}
          </div>

          {/* RIGHT COLUMN - PLAYERS AND TAGS SELECTED FOR ADDING  */}
          <div className={styles.contentColumn}>
            <div className={styles.contentTitle}>
              {t('game_editor.add_people.player_account.players_to_be_added', 'Players to be added')}
            </div>
            {!toAddOpenedTagPlayerAccounts && (
              <ToBeAddedList
                onDoTagSubSearch={handleDoTagSubSearch}
                onPlayerAccountSelected={handlePlayerAccountSelected}
                onTagSelected={handleTagSelected}
                searchInputValue={searchInputValue}
                toAddPlayerAccounts={toAddPlayerAccounts}
                toAddTags={toAddTags}
              />
            )}
            {toAddOpenedTagPlayerAccounts && (
              <PlayerAccountsSublist
                playerAccounts={toAddOpenedTagPlayerAccounts}
                onBack={() => setToAddOpenedTagPlayerAccounts(null)}
                sublistTitle={toAddOpenedTagTitle}
                searching={toAddSubSearching}
                searchInputValue={searchInputValue}
                toAddPlayerAccounts={toAddPlayerAccounts}
              />
            )}
          </div>
        </div>

        {isOrdered && (
          <div className={styles.startingTaskArea}>
            <Form<StartingTaskForm> onSubmit={() => {}}>
              {({ values }) => (
                <>
                  <StartingTaskSelection tasks={tasks} values={values} />
                  <FormSpy
                    onChange={(props) =>
                      setStartingTask(props.values.showStartingTaskSelection ? props.values.startingTask : undefined)
                    }
                  />
                </>
              )}
            </Form>
          </div>
        )}
        <div className={styles.playersAddByPlayerAccountFooter}>
          <Button variant='outline-normal' onClick={() => onClose()}>
            {t('common.cancel', 'Cancel')}
          </Button>
          {maxPlayerCount && totalCountToAdd + existingPlayerCount > maxPlayerCount + 1 ? (
            <span className={styles.maxPlayerValidationText}>
              {t('game_editor.add_people.max_teams_validation', 'Maximum player limit reached')}
            </span>
          ) : (
            <Button type='button' onClick={handleAddMultiplePlayerAccountsInternal} disabled={submitting}>
              {t('game_editor.add_people.player_account.add_players', 'Add players') +
                (totalCountToAdd ? ` (${totalCountToAdd})` : '')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}
