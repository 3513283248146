import React from 'react'
import SvgScoreGradientIcon from '../../../../../../../../common/components/icons/ScoreGradientIcon'
import { PlayerScore } from '../../../../../../../../types/commonTypes'
import styles from '../../Scoreboard/Scoreboard.module.css'

type ListItemProps = {
  playerScore: PlayerScore
}

export const ListItem: React.FC<ListItemProps> = ({ playerScore }) => {
  return (
    <div className={styles.listItemContainer}>
      <div className={styles.positionStyle}>{playerScore.position}</div>
      <div className={styles.nameStyle}>{playerScore.name}</div>
      <div className={styles.score}>
        <span className={styles.scoreIcon}>
          <SvgScoreGradientIcon />
        </span>
        <span className={styles.pointsStyle}>{playerScore.points}</span>
      </div>
    </div>
  )
}
