import { LevelCriteria, Task } from '../../../../../types/commonTypes'

export enum TaskAction {
  COPY = 'COPY',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  MOVE_BOARD = 'MOVE_BOARD',
  MOVE_LEVEL = 'MOVE_LEVEL',
  MOVE_ORDER = 'MOVE_ORDER',
}

export type TaskActionData = {
  task: Task
  action: TaskAction
  moveIndex?: number
}

export type TaskActionFn = (actionData: TaskActionData) => void

export type BoardTasksMap = { [boardIndex: number]: Task[] }

export type LevelTasksMap = {
  [levelIndex: number]: BoardTasksMap
}

export type SortableTaskData = {
  levelIndex: number
  boardIndex: number
}

export type PreviousLevelsData = {
  tasksCount: number
  pointsSum: number
}

export type PreviousLevelsDataMap = {
  [levelIndex: number]: PreviousLevelsData
}

export type LevelCriteriaForm = Omit<LevelCriteria, 'isActive'>
