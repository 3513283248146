import React from 'react'
import { PlayerScore } from '../../../../../../../../types/commonTypes'
import styles from '../../Scoreboard/Scoreboard.module.css'

type TopThreeColumnProps = {
  playerScore: PlayerScore
}

export const TopThreeColumn: React.FC<TopThreeColumnProps> = ({ playerScore }) => {
  return (
    <div className={styles.podiumColumn}>
      <span className={styles.topThreeName}>{playerScore.name}</span>
      <div className={styles.topThreePosition}>{playerScore.position}</div>
      <span className={styles.topThreePoints}>{playerScore.points}</span>
    </div>
  )
}
