import React from 'react'
import styles from './Engine.module.css'
import Image1 from '../../assets/images/engine/LoginStepImage1.png'
import Image2 from '../../assets/images/engine/LoginStepImage2.png'
import Image3 from '../../assets/images/engine/LoginStepImage3.png'
import { t } from 'i18next'
import { EngineForm } from './Engine'
import { Form } from 'react-final-form'
import { ValidationErrors } from 'final-form'
import { InputFormField } from '../../common/components/Form/InputFormField/InputFormField'
import { Button } from '../../common/components/button/Button'

interface Props {
  initialValues: Partial<EngineForm>
  validate: (values: EngineForm) => ValidationErrors
  onSubmit: (values: EngineForm) => void
}

export const LoginStep: React.FC<Props> = ({ initialValues, validate, onSubmit }) => {
  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepTitle}>{t('engine.login_step.welcome', 'Welcome to the Game Engine')}</div>
      <div className={styles.welcomeImages}>
        <div className={styles.welcomeImageWrapper}>
          <img src={Image1} alt='Generate games from files' />
          <div>{t('engine.login_step.image1text', 'Generate games from files')}</div>
        </div>
        <div className={styles.welcomeImageWrapper}>
          <img src={Image2} alt='Generate games from files' />
          <div>{t('engine.login_step.image2text', 'Customize your options')}</div>
        </div>
        <div className={styles.welcomeImageWrapper}>
          <img src={Image3} alt='Generate games from files' />
          <div>{t('engine.login_step.image3text', 'Begin to play in minutes')}</div>
        </div>
      </div>
      <h2>{t('engine.login_step.authentication_title', "Let's begin by authenticating")}</h2>
      <Form<EngineForm> onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
        {({ handleSubmit, submitting, values, errors }) => (
          <form id='ENGINE_FORM' onSubmit={handleSubmit} className={styles.formContainer}>
            <div className={styles.formContainer}>
              <InputFormField name='instructorToAdd' label={t('engine.user_email', 'Your email')} />
              <InputFormField name='generatorPin' label={t('engine.pin_code', 'Pin code')} />
            </div>

            <Button className={styles.continueButton} variant={'big'} disabled={submitting} type='submit'>
              {t('engine.login_button', 'Continue')}
            </Button>
          </form>
        )}
      </Form>
    </div>
  )
}
