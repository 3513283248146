import * as React from 'react'
import { SVGProps } from 'react'
const SvgMultipleUsersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#multiple_users_icon_svg__a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.07 5.904a6.5 6.5 0 1 1 9.193 9.192A6.5 6.5 0 0 1 6.07 5.904ZM10.667 17a10 10 0 0 1 10 10 1 1 0 0 1-2 0 8 8 0 0 0-16 0 1 1 0 1 1-2 0 10 10 0 0 1 10-10Zm3.182-9.682c.51.51.885 1.13 1.102 1.805a8.142 8.142 0 0 1-7.704-1.548 4.501 4.501 0 0 1 6.602-.257ZM6.167 10.5c0-.379.047-.753.14-1.113a10.144 10.144 0 0 0 8.811 1.77 4.5 4.5 0 0 1-8.951-.657Zm16.321-4.497a4.5 4.5 0 0 1 4.461 3.121c-.79.25-1.616.377-2.448.376h-.002a8.076 8.076 0 0 1-4.304-1.235 1 1 0 1 0-1.063 1.694A10.075 10.075 0 0 0 24.5 11.5c.886 0 1.765-.115 2.616-.342a4.502 4.502 0 0 1-6.91 3.111 1 1 0 0 0-1.092 1.675 6.5 6.5 0 1 0 .01-10.894 1 1 0 0 0 1.089 1.677 4.5 4.5 0 0 1 2.275-.724Zm-1.6 13.195a1 1 0 0 1-.442-1.95 10.009 10.009 0 0 1 12.22 9.751 1 1 0 1 1-2 .002 8.009 8.009 0 0 0-9.778-7.803Z'
        fill={props.color ?? '#000'}
      />
    </g>
    <defs>
      <clipPath id='multiple_users_icon_svg__a'>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgMultipleUsersIcon
