import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  addMembersApi,
  changeMemberRoleApi,
  getBusinessSettingsData,
  getQuotaUpdate,
  removeMemberApi,
} from '../api/businessSettingsApiService'
import { parseAddMemberVmToAddMemberData, parseAdminSettingsDataToVm } from '../api/typeConverters'
import { BusinessQuotaInfo, TAddMember, TBusinessSettings } from '../types/commonTypes'
import { useConfirmation } from './ConfirmationContext'
import { useNotification } from './NotificationContext'
import { useUser } from './userContext'
import { AxiosError } from 'axios'

type BusinessSettingsContextValue = {
  isFetchingData: boolean
  data: TBusinessSettings | undefined
  quota: BusinessQuotaInfo | undefined
  addMembers: (members: TAddMember[], message?: string) => Promise<boolean>
  changeMemberRole: (userName: string) => Promise<boolean>
  removeMember: (userName: string) => Promise<boolean>
  updateQuota: () => Promise<boolean>
}

const INITIAL_VALUES: BusinessSettingsContextValue = {
  isFetchingData: true,
  data: undefined,
  quota: undefined,
  addMembers: () => Promise.resolve(false),
  removeMember: () => Promise.resolve(false),
  changeMemberRole: () => Promise.resolve(false),
  updateQuota: () => Promise.resolve(false),
}

const BusinessSettingsContext = createContext<BusinessSettingsContextValue>(INITIAL_VALUES)

export const BusinessSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true)
  const [data, setData] = useState<TBusinessSettings>()
  const [quota, setQuota] = useState<BusinessQuotaInfo>()
  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const { notifyError, notifySuccess } = useNotification()
  const { requestConfirmation } = useConfirmation()
  const { t } = useTranslation()
  const { user } = useUser()

  useEffect(() => {
    if (user == null) {
      return
    }
    setIsFetchingData(true)
    getBusinessSettingsData({ businessId: user.activeBusiness.id })
      .then((response) => {
        if (response.success) {
          setData(parseAdminSettingsDataToVm(response.value))
        } else {
          console.error(response.error)
          notifyError({
            title: t('business_settings.fetch_data_error_notification.title', 'Failed to get data'),
            content: t(
              'business_settings.fetch_data_error_notification.content',
              'An error occurred while getting data. Please try again or contact us for support.',
            ),
          })
        }
      })
      .finally(() => {
        setIsFetchingData(false)
      })
  }, [notifyError, t, user, refreshCounter])

  const updateQuota = useCallback(async () => {
    try {
      if (user?.activeBusiness.id == null) {
        throw new Error('Cannot perform action without active business id')
      }
      const response = await getQuotaUpdate({ businessId: user.activeBusiness.id })
      if (response.success) {
        setQuota({
          totalQuotaUsage: response.value.total_quota_usage || null,
          totalSeats: response.value.total_seats || null,
          usedSeats: response.value.used_seats || null,
          freeSeats:
            response.value.total_seats && response.value.used_seats
              ? response.value.total_seats - response.value.used_seats
              : null,
        })
        return true
      } else {
        throw response.error
      }
    } catch (error) {
      console.error(error)
      notifyError({
        title: t('business_settings.update_member_role_error_notification.title', 'Failed to update member role'),
        content: t(
          'business_settings.update_member_role_error_notification.content',
          'An error occurred while updating member role. Please try again or contact us for support.',
        ),
      })
      return false
    }
  }, [notifyError, t, user])

  const changeMemberRole = useCallback(
    async (userName: string) => {
      try {
        if (user?.activeBusiness.id == null) {
          throw new Error('Cannot perform action without active business id')
        }
        const response = await changeMemberRoleApi({ businessId: user.activeBusiness.id, userName })
        if (response.success) {
          setRefreshCounter((prev) => prev + 1)
          return true
        } else {
          throw response.error
        }
      } catch (error) {
        console.error(error)
        notifyError({
          title: t('business_settings.update_member_role_error_notification.title', 'Failed to update member role'),
          content: t(
            'business_settings.update_member_role_error_notification.content',
            'An error occurred while updating member role. Please try again or contact us for support.',
          ),
        })
        return false
      }
    },
    [notifyError, t, user],
  )

  const removeMember = useCallback(
    async (userName: string) => {
      const confirm = await requestConfirmation({
        title: t('business_settings.remove_member_confirmation_notification.title', 'Confirm remove action'),
        text: (
          <Trans
            i18nKey='business_settings.remove_member_confirmation_notification.text'
            values={{ user_name: userName }}
            components={{ 1: <b /> }}
          >
            {
              'Are you sure you want to remove member <1>%{user_name}</1>? They will not be able to access your organisation.'
            }
          </Trans>
        ),
        confirmActionText: t('business_settings.remove_member_confirmation_notification.confirm_button_text', 'Remove'),
      })
      if (!confirm) {
        return false
      }
      try {
        if (user?.activeBusiness.id == null) {
          throw new Error('Cannot perform action without active business id')
        }
        const response = await removeMemberApi({ businessId: user.activeBusiness.id, userName })
        if (response.success) {
          setRefreshCounter((prev) => prev + 1)
          return true
        } else {
          throw response.error
        }
      } catch (error) {
        console.error(error)
        notifyError({
          title: t('business_settings.remove_member_error_notification.title', 'Failed to remove member'),
          content: t(
            'business_settings.remove_member_error_notification.content',
            'An error occurred while removing member. Please try again or contact us for support.',
          ),
        })
        return false
      }
    },
    [notifyError, t, requestConfirmation, user],
  )

  const addMembers = useCallback(
    async (members: TAddMember[], message?: string) => {
      try {
        if (user?.activeBusiness.id == null) {
          throw new Error('Cannot perform action without active business id')
        }
        const response = await addMembersApi({
          businessId: user.activeBusiness.id,
          members: members.map(parseAddMemberVmToAddMemberData),
          message: message,
        })
        if (response.success) {
          setRefreshCounter((prev) => prev + 1)
          notifySuccess({
            title: t('business_settings.add_members_success_notification.title', {
              count: members.length,
              defaultValue: 'Added members',
            }),
            content: t('business_settings.add_members_success_notification.content', {
              count: members.length,
              defaultValue: 'New members were added to your organisation',
            }),
          })
          return true
        } else {
          throw response.error
        }
      } catch (error) {
        const err = error as AxiosError
        const errorMessage = err.response?.data
        if (typeof errorMessage === 'string') {
          notifyError({
            title: t('business_settings.add_members_error_notification.title', 'Failed to add members'),
            content: t('business_settings.add_members_error_notification_with_message.content', {
              defaultValue:
                'An error occurred while adding members. Please try again or contact us for support. Error message: %{errorMessage}',
              errorMessage: errorMessage,
            }),
          })
        } else {
          notifyError({
            title: t('business_settings.add_members_error_notification.title', 'Failed to add members'),
            content: t(
              'business_settings.add_members_error_notification.content',
              'An error occurred while adding members. Please try again or contact us for support.',
            ),
          })
        }
        return false
      }
    },
    [notifySuccess, notifyError, t, user],
  )

  return (
    <BusinessSettingsContext.Provider
      value={{ addMembers, removeMember, isFetchingData, changeMemberRole, data, updateQuota, quota }}
    >
      {children}
    </BusinessSettingsContext.Provider>
  )
}

export const useBusinessSettings = () => {
  const context = useContext(BusinessSettingsContext)
  if (!context) throw new Error('Expected to be wrapped in a BusinessSettingsContextProvider!')
  return context
}
