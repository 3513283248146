import { useTranslation } from 'react-i18next'
import styles from './TimeExceededInfo.module.css'

type TimeExceededInfoProps = {
  title?: string
  subtitle?: string
}

export const TimeExceededInfo: React.FC<TimeExceededInfoProps> = ({ title, subtitle }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.timeExceededInfoContainer}>
      <span className='bold normal'>
        {title ?? t('game_editor.sidebar.evaluate_answer_panel.time_exceeded_no_answer', 'No answer')}
      </span>
      <span className='tiny'>
        {subtitle ??
          t('game_editor.sidebar.evaluate_answer_panel.time_exceeded_player_out_of_time', 'Player ran out of time')}
      </span>
    </div>
  )
}
