import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { ExerciseType } from '../../../../../../../api/gameTypes'
import { ToggleSwitch } from '../../../../../../../common/components/ToggleSwitch/ToggleSwitch'
import { Button } from '../../../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../../../common/components/button/CloseButton'
import { AnswerEvaluation } from '../../../../../../../contexts/GameContextHelper'
import { useNotification } from '../../../../../../../contexts/NotificationContext'
import { useFormSubmitFailureListener } from '../../../../../../../hooks/useFormSubmitFailureListener'
import { AnswerStateEnum, Evaluation, Game } from '../../../../../../../types/commonTypes'
import { areObjectsEqual, isEmpty, noop, omitProperty } from '../../../../../../../util/functional'
import { isNullOrZero } from '../../../../../../../util/number'
import { safeIsNullOrEmpty } from '../../../../../../../util/string'
import { AddTime } from '../../../../AddTime/AddTime'
import sharedEditorPanelStyles from '../../../EditorSidebar.module.css'
import { PlayTab } from '../../types'
import { PlayerCard } from '../Player/PlayerCard'
import { TaskForPlayer } from '../Task/TaskForPlayer'
import styles from './GradeAnswersPanel.module.css'
import { AwardBadge } from '../AwardBadgePanel/AwardBadge'
import { PictureOrSelfie } from './components/PictureOrSelfie'
import { SubtaskEvaluation } from './components/SubtaskEvaluation'
import { EvaluationFrom, EvaluationItem, getInitialValues, validatorCreator } from './helpers'

type GradeAnswersPanelProps = {
  playTab: PlayTab
  evaluation: Evaluation
  game: Game
  onClose: (hasUnsavedChanges: boolean) => void
  onGradeAnswer: (gameId: number, answerEvaluation: AnswerEvaluation[]) => Promise<string>
  onAskForRevisedAnswer: (
    gameId: number,
    answerEvaluation: AnswerEvaluation[],
    moreTimeSeconds: number,
  ) => Promise<string>
  collapseGradedAnswers: boolean
  onToggleCollapseGradedAnswers: () => void
}

const CLOSE_BUTTON_ID = 'grade_answer_close_button'

export const GradeAnswersPanel: React.FC<GradeAnswersPanelProps> = ({
  playTab,
  evaluation,
  game,
  onClose,
  onGradeAnswer,
  onAskForRevisedAnswer,
  collapseGradedAnswers,
  onToggleCollapseGradedAnswers,
}) => {
  const { t } = useTranslation()
  const { notifyError, notifySuccess } = useNotification()

  const [loadingAnswerRevision, setLoadingAnswerRevision] = useState<boolean>(false)
  const [addAdditionalTime, setAddAdditionalTime] = useState<boolean>(false)
  const [additionalTimeError, setAdditionalTimeError] = useState<string>()
  const [additionalTimeSeconds, setAdditionalTimeSeconds] = useState<number>()
  const [selectedBadgeId, setSelectedBadgeId] = useState<number | undefined>(() => {
    return evaluation.task.advanced.automaticBadgeId != null
      ? undefined
      : evaluation.answers.find((a) => a.answer.badge != null)?.answer.badge?.id
  })

  const onChangeSelectedBadgeId = (id: number) => setSelectedBadgeId((prev) => (prev === id ? undefined : id))

  const toggleAddAdditionalTime = () => setAddAdditionalTime((prev) => !prev)

  useEffect(() => {
    DOMPurify.addHook('beforeSanitizeElements', (currNode: Element) => {
      if (
        currNode.tagName === 'DIV' &&
        (currNode.classList.contains('player-video-delete') || currNode.classList.contains('player-audio-delete'))
      ) {
        currNode.remove()
      }
    })
    return () => DOMPurify.removeAllHooks()
  }, [])

  useEffect(() => {
    document.querySelector<HTMLButtonElement>(`#${CLOSE_BUTTON_ID}`)?.focus?.()
  }, [evaluation.player.id])

  const onSubmit = useCallback(
    async (values: EvaluationFrom) => {
      const submitValues = values.evaluationData
        .filter((item) => item.isCreative)
        .map((item) => omitProperty<EvaluationItem, AnswerEvaluation>(item, 'isCreative'))
      if (selectedBadgeId) {
        submitValues[0].badge = game.badges.find((b) => b.id === selectedBadgeId)
      }
      const result = await onGradeAnswer(game.gameId, submitValues)
      if (result === 'Failed to send grading') {
        notifyError({
          title: t(
            'game_editor.sidebar.evaluate_answer_panel.grade_answer_error_notification.title',
            'Failed to grade answer',
          ),
          content: t(
            'game_editor.sidebar.evaluate_answer_panel.grade_answer_error_notification.content',
            'An error occurred while trying to grade answer. Please try again or contact us for support.',
          ),
        })
      } else {
        notifySuccess({
          title: t(
            'game_editor.sidebar.evaluate_answer_panel.grade_answer_success_notification.title',
            'Answer graded',
          ),
          content: t(
            'game_editor.sidebar.evaluate_answer_panel.grade_answer_success_notification.content',
            'Answer was successfully graded.',
          ),
        })
        onClose(false)
      }
    },
    [notifySuccess, notifyError, t, onGradeAnswer, onClose, game, selectedBadgeId],
  )

  const onAskForRevisedAnswerInternal = async (values: EvaluationFrom) => {
    if (addAdditionalTime && (additionalTimeSeconds ?? 0) < 10) {
      setAdditionalTimeError(
        t('game_editor.add_time.at_least_10_seconds_validation', 'Add at least 10 seconds of additional time'),
      )
    } else {
      setLoadingAnswerRevision(true)
      const additionalTime = addAdditionalTime && additionalTimeSeconds != null ? additionalTimeSeconds : 10
      const result = await onAskForRevisedAnswer(game.gameId, values.evaluationData, additionalTime)
      if (result === 'Failed to send grading') {
        notifyError({
          title: t(
            'game_editor.sidebar.evaluate_answer_panel.request_revised_answer_error_notification.title',
            'Failed to request revision',
          ),
          content: t(
            'game_editor.sidebar.evaluate_answer_panel.request_revised_answer_error_notification.content',
            'An error occurred while requesting answer revision. Please try again or contact us for support.',
          ),
        })
      } else {
        notifySuccess({
          title: t(
            'game_editor.sidebar.evaluate_answer_panel.request_revised_answer_success_notification.title',
            'Revision requested',
          ),
          content: t(
            'game_editor.sidebar.evaluate_answer_panel.request_revised_answer_success_notification.content',
            'Answer revision was successfully requested',
          ),
        })
        onClose(false)
      }
      setLoadingAnswerRevision(false)
      setAdditionalTimeSeconds(undefined)
    }
  }

  const initialValues = useMemo<Partial<EvaluationFrom>>(() => getInitialValues(evaluation), [evaluation])

  const validate = useCallback(
    (values: Partial<EvaluationFrom>) => validatorCreator(evaluation, t, game.advancedSettings.noPointsGame)(values),
    [evaluation, game.advancedSettings.noPointsGame, t],
  )

  const onAddedTimeChange = (seconds: number) => {
    if (!isNullOrZero(additionalTimeSeconds) && additionalTimeSeconds! > 10) {
      setAdditionalTimeError(undefined)
    }
    setAdditionalTimeSeconds(seconds)
  }

  const { submitFailedToken, submitListener } = useFormSubmitFailureListener()

  const showEvaluateButton =
    [AnswerStateEnum.READY, AnswerStateEnum.GRADED].includes(evaluation.state) &&
    evaluation.answers.some((playerAnswer) => playerAnswer.subtask.type === ExerciseType.CreativeExercise)

  const showPictureOrSelfie =
    evaluation.task.advanced.requirePictureOrSelfie &&
    (!safeIsNullOrEmpty(evaluation.answers?.[0].answer.selfieUrl) ||
      !safeIsNullOrEmpty(evaluation.answers?.[0].answer.selfieMessage))

  const showAwardBadge =
    game.advancedSettings.badgesEnabled &&
    game.badges.length > 0 &&
    (evaluation.task.advanced.automaticBadgeId || showEvaluateButton)

  return (
    <Form<EvaluationFrom>
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      decorators={[submitListener]}
    >
      {({ submitting, handleSubmit, values, errors, initialValues }) => (
        <div className={classNames(sharedEditorPanelStyles.editorSidebarPanelContainer, styles.gradeAnswersPanel)}>
          <div className={styles.header}>
            <h1>{t('game_editor.sidebar.evaluate_answer_panel.title', 'Evaluate answer')}</h1>
            <CloseButton
              id={CLOSE_BUTTON_ID}
              onClick={() => onClose(!areObjectsEqual(values, initialValues))}
              aria-label={t(
                'game_editor.sidebar.evaluate_answer_panel.close_panel_accessibility_label',
                'Close evaluation',
              )}
            />
          </div>

          <div className={styles.body}>
            <div className={styles.playerCardContainer}>
              {playTab === PlayTab.TASKS ? (
                <PlayerCard
                  id={evaluation.player.id}
                  onClickPlayer={noop}
                  isSelected
                  playerName={evaluation.player.nickName}
                  points={evaluation.totalPoints}
                  state={evaluation.state}
                  noPointsGame={game.advancedSettings.noPointsGame}
                  isCompact={false}
                />
              ) : (
                <TaskForPlayer
                  evaluation={evaluation}
                  onSelectTask={noop}
                  noPointsGame={game.advancedSettings.noPointsGame}
                  isSelected
                  isCompact={false}
                />
              )}
            </div>

            <div className={styles.listWithGap}>
              <h2 className={styles.taskName}>{evaluation.task.name}</h2>
              <div className={styles.row}>
                <span className={styles.subtaskCount}>
                  {t('game_editor.sidebar.evaluate_answer_panel.subtasks_count', {
                    defaultValue: '%{count} subtasks',
                    count: evaluation.task.subtasksCount,
                  })}
                </span>
                <div className={styles.collapseEvaluatedToggle}>
                  <ToggleSwitch
                    small
                    label={t(
                      'game_editor.sidebar.evaluate_answer_panel.collapse_graded_toggle',
                      'Collapse evaluated tasks',
                    )}
                    checked={collapseGradedAnswers}
                    onChange={onToggleCollapseGradedAnswers}
                    id='collapse-graded-toggle'
                  />
                  <label htmlFor='collapse-graded-toggle' className={classNames('tiny', styles.collapseEvaluatedLabel)}>
                    {t('game_editor.sidebar.evaluate_answer_panel.collapse_graded_toggle', 'Collapse evaluated tasks')}
                  </label>
                </div>
              </div>

              <form className={styles.listWithGap} onSubmit={handleSubmit}>
                <div className={styles.subtasksList}>
                  {evaluation.answers.map(({ answer, subtask }, index) => (
                    <SubtaskEvaluation
                      subtask={subtask}
                      answer={answer}
                      name={`evaluationData[${index}]`}
                      key={`subtask_evaluation_answer_${answer.id}`}
                      noPointsGame={game.advancedSettings.noPointsGame}
                      collapseGraded={collapseGradedAnswers}
                      hasAnyErrors={!isEmpty(errors?.evaluationData?.[index])}
                      forceExpandToken={submitFailedToken}
                    />
                  ))}
                  {showPictureOrSelfie && (
                    <PictureOrSelfie
                      imageUrl={evaluation.answers[0].answer.selfieUrl}
                      greeting={evaluation.answers[0].answer.selfieMessage}
                    />
                  )}
                  {showAwardBadge && (
                    <AwardBadge
                      badges={game.badges}
                      automaticallyAwardedBadgeId={evaluation.task.advanced.automaticBadgeId}
                      automaticallyAwardedBadgePoints={
                        !game.advancedSettings.noPointsGame ? evaluation.task.advanced.automaticBadgePoints : undefined
                      }
                      selectedBadgeId={selectedBadgeId}
                      setSelectedBadgeId={onChangeSelectedBadgeId}
                      title={t('game_editor.sidebar.evaluate_answer_panel.award_badge_title', 'Award badge')}
                    />
                  )}
                </div>

                {evaluation.task.advanced?.hasTimeLimit && (
                  <div className={styles.expandableAddTime}>
                    <ToggleSwitch small onChange={toggleAddAdditionalTime} id='add-time-toggle' />
                    <label htmlFor='add-time-toggle' className={classNames('tiny', styles.addTimeLabel)}>
                      {t(
                        'game_editor.sidebar.evaluate_answer_panel.give_more_time',
                        'Give more time to complete the exercise',
                      )}
                    </label>
                  </div>
                )}
                {addAdditionalTime && (
                  <AddTime
                    onChange={onAddedTimeChange}
                    error={additionalTimeError}
                    className={styles.addTimeContainer}
                  />
                )}
                <Button
                  style={{ maxWidth: 'fit-content', margin: 'auto' }}
                  variant='outline-tiny'
                  disabled={submitting || loadingAnswerRevision}
                  onClick={() => onAskForRevisedAnswerInternal(values)}
                >
                  {t(
                    'game_editor.sidebar.evaluate_answer_panel.ask_for_revised_answer_button',
                    'Ask for a revised answer',
                  )}
                </Button>
                {showEvaluateButton && (
                  <Button
                    type='submit'
                    style={{ maxWidth: 'fit-content', margin: 'auto' }}
                    variant='tiny'
                    disabled={submitting || loadingAnswerRevision}
                  >
                    {evaluation.state === AnswerStateEnum.GRADED
                      ? t('game_editor.sidebar.evaluate_answer_panel.evaluate_again_button', 'Re-Evaluate')
                      : t('game_editor.sidebar.evaluate_answer_panel.evaluate_button', 'Evaluate')}
                  </Button>
                )}
                <button
                  type='button'
                  disabled={submitting || loadingAnswerRevision}
                  className={styles.textButton}
                  onClick={() => onClose(!areObjectsEqual(values, initialValues))}
                >
                  {t('common.cancel', 'Cancel')}
                </button>
              </form>
            </div>
            <div className={styles.scrollPadding} />
          </div>
        </div>
      )}
    </Form>
  )
}
