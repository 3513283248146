import classNames from 'classnames'
import { Evaluation, Game, Task } from '../../../../../../../types/commonTypes'
import sidebarStyles from '../../../EditorSidebar.module.css'
import { EvaluationSortBy } from '../../types'
import { EvaluationHeaderTask } from './components/TasksEvaluation/EvaluationHeaderTask'
import { TaskEvaluationList } from './components/TasksEvaluation/TaskEvaluationList'
import { TaskEvaluationSort } from './components/TasksEvaluation/TaskEvaluationSort'

type TasksEvaluationPanelProps = {
  evaluations: Evaluation[]
  game: Game
  isCompact: boolean
  onClose: () => void
  onSelectPlayer: (playerId: number) => void
  selectedPlayerId?: number
  task: Task
  sortBy: EvaluationSortBy
  onSetSortBy: (sortBy: EvaluationSortBy) => void
}

export const TasksEvaluationPanel: React.FC<TasksEvaluationPanelProps> = ({
  evaluations,
  game,
  isCompact,
  onClose,
  onSelectPlayer,
  selectedPlayerId,
  task,
  sortBy,
  onSetSortBy,
}) => {
  return (
    <div
      className={classNames(
        sidebarStyles.editorSidebarPanelContainer,
        selectedPlayerId != null && sidebarStyles.secondPanelWithThirdOpen,
      )}
    >
      <EvaluationHeaderTask onClose={onClose} task={task} noPointsGame={game.advancedSettings.noPointsGame} />
      <TaskEvaluationSort sortBy={sortBy} onSetSortBy={onSetSortBy} />
      <TaskEvaluationList
        evaluations={evaluations}
        onClickPlayer={onSelectPlayer}
        noPointsGame={game.advancedSettings.noPointsGame}
        selectedPlayerId={selectedPlayerId}
        isCompact={isCompact}
        sortBy={sortBy}
      />
    </div>
  )
}
