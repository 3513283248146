import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Exercise } from '../../../../../../api/gameTypes'
import { searchTaskLibraryApi } from '../../../../../../api/taskApiService'
import { useNotification } from '../../../../../../contexts/NotificationContext'
import { areObjectsEqual } from '../../../../../../util/functional'
import { getTasksMapFromLibrarySearch, parseSearchValues } from './helpers'
import { LibTaskMap, TaskLibrarySearchParams } from './types'

const RESULTS_PER_PAGE = 60

export const useSearchLibraryTasks = (gameId: number) => {
  const { t } = useTranslation()
  const { notifyError } = useNotification()

  const [searchParams, setSearchParams] = useState<TaskLibrarySearchParams>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [libraryTasks, setLibraryTasks] = useState<LibTaskMap>()
  const [searchCount, setSearchCount] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(false)

  const lastSearchValues = useRef<TaskLibrarySearchParams>()
  const lastPage = useRef<number>(1)
  const searchCountRef = useRef<number>()
  const flatExercisesRef = useRef<Exercise[]>([])

  const onSearchChange = useCallback(
    async (values: TaskLibrarySearchParams) => {
      const parsedValues = parseSearchValues(values)
      if (Object.keys(parsedValues).length === 0) {
        setSearchParams(undefined)
        setSearchCount(0)
        setLibraryTasks(undefined)
        setHasMore(false)
        lastSearchValues.current = {}
        lastPage.current = 1
        searchCountRef.current = undefined
        flatExercisesRef.current = []
      } else if (!areObjectsEqual(lastSearchValues.current ?? {}, parsedValues)) {
        setIsLoading(true)
        const result = await searchTaskLibraryApi({
          gameId,
          page: 1,
          perPage: RESULTS_PER_PAGE,
          ...parsedValues,
        })
        if (result.success) {
          setSearchParams(parsedValues)
          setSearchCount(result.value.count)
          setLibraryTasks(getTasksMapFromLibrarySearch(result.value.exercises))
          setHasMore(result.value.count > RESULTS_PER_PAGE)
          lastSearchValues.current = parsedValues
          lastPage.current = 1
          searchCountRef.current = result.value.count
          flatExercisesRef.current = result.value.exercises
        } else {
          notifyError({
            title: t('game_editor.tasks.library_modal.search_failed_notification.title', 'Searching tasks failed'),
            content: t(
              'game_editor.tasks.library_modal.search_failed_notification.content',
              'An error occurred while searching tasks. Please try again by clicking retry or changing any of the search parameters.',
            ),
            customButton: {
              text: t('common.retry', 'Retry'),
              onClick: () => onSearchChange(values),
              closeOnClick: true,
            },
          })
        }
        setIsLoading(false)
      }
    },
    [t, notifyError, gameId],
  )

  const onLoadMore = useCallback(async () => {
    setIsLoading(true)
    const result = await searchTaskLibraryApi({
      gameId,
      page: lastPage.current + 1,
      perPage: RESULTS_PER_PAGE,
      ...lastSearchValues.current,
    })
    if (result.success) {
      lastPage.current = lastPage.current + 1
      setHasMore((searchCountRef.current ?? 0) > RESULTS_PER_PAGE * lastPage.current)
      flatExercisesRef.current = [...flatExercisesRef.current, ...result.value.exercises]
      setLibraryTasks(getTasksMapFromLibrarySearch(flatExercisesRef.current))
    } else {
      notifyError({
        title: t('game_editor.tasks.library_modal.fetch_more_failed_notification.title', 'Fetching more tasks failed'),
        content: t(
          'game_editor.tasks.library_modal.fetch_more_failed_notification.content',
          'An error occurred while fetching more tasks. Please try again by clicking retry or changing any of the search parameters.',
        ),
        customButton: {
          text: t('common.retry', 'Retry'),
          onClick: () => onLoadMore(),
          closeOnClick: true,
        },
      })
    }
    setIsLoading(false)
  }, [gameId, notifyError, t])

  return {
    searchParams,
    searchCount,
    hasMore,
    isLoading,
    libraryTasks,
    onSearchChange,
    onLoadMore,
  }
}
