import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExerciseType } from '../../../../../../../../api/gameTypes'
import { AnswerStateEnum, ReceivedAnswer, Subtask } from '../../../../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../../../../util/string'
import { getIconNameForTaskType, getTitleForTaskType } from '../../../../../Tasks/TaskModal/helpers'
import { getAnswerStatusText } from '../helpers'
import { AnswerContainer } from './AnswerContainer'
import { CheckboxAnswer } from './ChoiceAnswer/CheckboxAnswer'
import { MultipleChoiceAnswer } from './ChoiceAnswer/MultipleChoiceAnswer'
import { CollapsibleCardSection } from './CollapsibleCardSection'
import { CreativeAnswer } from './Creative/CreativeAnswer'
import { CreativeAnswerEvaluate } from './Creative/CreativeAnswerEvaluate'
import { MatchPairsAnswer } from './MatchPairs/MatchPairsAnswer'
import { MissingWordAnswer } from './MissingWord/MissingWordAnswer'
import { SubtaskDescription } from './SubtaskDescription'
import styles from './SubtaskEvaluation.module.css'
import { PreviousAnswersLoader } from './PreviousAnswersLoader'

type SubtaskEvaluationProps = {
  subtask: Subtask
  answer: ReceivedAnswer
  name: string
  noPointsGame?: boolean
  collapseGraded: boolean
  hasAnyErrors: boolean
  forceExpandToken?: number
}

export const SubtaskEvaluation: React.FC<SubtaskEvaluationProps> = ({
  subtask,
  answer,
  name,
  noPointsGame,
  collapseGraded,
  hasAnyErrors,
  forceExpandToken,
}) => {
  const { t } = useTranslation()

  const [isExpanded, setIsExpanded] = useState<boolean>(!(collapseGraded && answer.state === AnswerStateEnum.GRADED))

  const toggleIsExpandedInternal = () => setIsExpanded((prev) => !prev)

  useEffect(() => {
    if (collapseGraded && answer.state === AnswerStateEnum.GRADED) {
      setIsExpanded(false)
    }
  }, [collapseGraded, answer.state])

  useEffect(() => {
    if (forceExpandToken && hasAnyErrors) {
      setIsExpanded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceExpandToken])

  const answerAndPreviousAnswers = useMemo(() => {
    //Sort previous answers, they may come in different order sometimes
    return [answer].concat(answer.previousAnswers.sort((a, b) => (a.completedAt < b.completedAt ? 1 : -1))).reverse()
  }, [answer])

  if (answer == null) {
    return null
  }

  return (
    <CollapsibleCardSection
      icon={getIconNameForTaskType(subtask.type)}
      title={getTitleForTaskType(subtask.type, t)}
      subtitle={getAnswerStatusText(answer.state, subtask.type === ExerciseType.CreativeExercise, t)}
      isExpanded={isExpanded}
      onToggleExpanded={toggleIsExpandedInternal}
      containerClassName={classNames(answer.state === AnswerStateEnum.READY && styles.subtaskContainer_ungraded)}
      subtitleClassName={
        answer.state === AnswerStateEnum.READY ? styles.taskStatus_notGraded : styles.taskStatus_graded
      }
    >
      <SubtaskDescription description={subtask.description} />
      {answer.hasMorePreviousAnswers && <PreviousAnswersLoader />}
      {answerAndPreviousAnswers.map((answer, index) => {
        const isPreviousAnswer = index < answerAndPreviousAnswers.length - 1
        return (
          <AnswerContainer
            time={answer.completedAt}
            points={
              [AnswerStateEnum.GRADED, AnswerStateEnum.REVISION].includes(answer.state) && !noPointsGame
                ? answer.points
                : undefined
            }
            feedbackMessage={
              (subtask.type !== ExerciseType.CreativeExercise || isPreviousAnswer) &&
              !safeIsNullOrEmpty(answer.feedbackMessage)
                ? answer.feedbackMessage!
                : undefined
            }
            isPreviousAnswer={isPreviousAnswer}
            previousAnswerIndex={index}
            key={index}
          >
            {subtask.type === ExerciseType.MultichoiceExercise && (
              <MultipleChoiceAnswer
                maxPoints={subtask.maxPoints}
                answer={answer}
                answerOptions={subtask.data.answers}
                noPointsGame={noPointsGame}
              />
            )}
            {subtask.type === ExerciseType.PollExercise && (
              <CheckboxAnswer answer={answer} answerOptions={subtask.data.answers} noPointsGame={noPointsGame} />
            )}
            {subtask.type === ExerciseType.CombineExercise && (
              <MatchPairsAnswer answer={answer} answerOptions={subtask.data.answers} noPointsGame={noPointsGame} />
            )}
            {subtask.type === ExerciseType.MissingWordExercise && (
              <MissingWordAnswer
                answer={answer}
                answerOptions={subtask.data.answers}
                taskDescription={subtask.description}
                noPointsGame={noPointsGame}
              />
            )}
            {subtask.type === ExerciseType.CreativeExercise && <CreativeAnswer answer={answer} />}
          </AnswerContainer>
        )
      })}
      {subtask.type === ExerciseType.CreativeExercise && (
        <CreativeAnswerEvaluate
          guidelines={subtask.data.evaluationGuideline}
          name={name}
          noPointsGame={noPointsGame}
          maxPoints={subtask.data.pointsForAnswer}
          state={answer.state}
        />
      )}
    </CollapsibleCardSection>
  )
}
