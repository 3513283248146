import { useTranslation } from 'react-i18next'
import { InvoicePaymentInfo } from '../../../../types/commonTypes'
import styles from '../BillingSettings.module.css'

type BillingAddressProps = {
  paymentInfo: InvoicePaymentInfo
}

export const BillingAddress: React.FC<BillingAddressProps> = ({
  paymentInfo: { city, country, einvAddress, einvOperator, email, postalCode, streetAddress },
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.subSection}>
      <h3 className={styles.subSectionTitle}>{t('settings_billing.invoice_title', 'Invoice billing info')}</h3>
      <p>{email}</p>
      <div className={styles.detailsList}>
        <p>{streetAddress}</p>
        <p>{postalCode}</p>
        <p>{city}</p>
        <p>{country}</p>
      </div>
      {(einvOperator || einvAddress) && (
        <div className={styles.detailsList}>
          <p>{einvOperator}</p>
          <p>{einvAddress}</p>
        </div>
      )}
    </div>
  )
}
