import * as React from 'react'
import { SVGProps } from 'react'
const SvgReportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.4 1.6a.8.8 0 0 0-.8.8v20.8a.8.8 0 0 0 .8.8h19.2a.8.8 0 0 0 .8-.8l-.002-15.952h-3.662A1.867 1.867 0 0 1 16.87 5.38V1.6H2.4Zm16.07.003v3.778c0 .147.119.267.266.267h3.662v-.173a.8.8 0 0 0-.24-.57l-.002-.003-3.2-3.075a.8.8 0 0 0-.487-.224ZM.702.703A2.4 2.4 0 0 1 2.4 0h16.001a2.4 2.4 0 0 1 1.67.68l.002.002 3.2 3.075a2.4 2.4 0 0 1 .725 1.717L24 23.2a2.4 2.4 0 0 1-2.4 2.4H2.4A2.4 2.4 0 0 1 0 23.2V2.4A2.4 2.4 0 0 1 .703.703ZM5.6 7.2a.8.8 0 0 1 .8.8v11.2H8v-4.87c0-.491.3-.844.601-1.038a1.86 1.86 0 0 1 .999-.278h1.6c.336 0 .697.084.999.278.3.194.601.547.601 1.037V19.2h1.6v-7.6c0-.448.117-.914.369-1.292.252-.379.678-.708 1.231-.708h1.6c.553 0 .979.329 1.231.708.253.378.369.844.369 1.292v7.6h.8a.8.8 0 0 1 0 1.6H5.6a.8.8 0 0 1-.8-.8V8a.8.8 0 0 1 .8-.8Zm10.4 12h1.6v-7.6a.776.776 0 0 0-.097-.4h-1.406a.776.776 0 0 0-.097.4v7.6Zm-4.8 0H9.6v-4.586h1.6V19.2Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)
export default SvgReportIcon
