import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { DebugModal } from '../composites/debug/DebugDialog'
import { noop } from '../util/functional'

type DebugContextType = {
  allowUseNewEditor: boolean
  toggleAllowUseNewEditor: () => void
  toggleShowDebugModal: () => void
}

const defaultToNewEditor = process.env.REACT_APP_ENV === 'development' && process.env.REACT_APP_DEFAULT_EDITOR === 'NEW'

const DebugContext = createContext<DebugContextType>({
  allowUseNewEditor: defaultToNewEditor,
  toggleAllowUseNewEditor: noop,
  toggleShowDebugModal: noop,
})

export var globalAllowNewEditor = defaultToNewEditor

export const DebugProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [allowUseNewEditor, setAllowUseNewEditor] = useState<boolean>(defaultToNewEditor)
  const toggleAllowUseNewEditor = useCallback(() => {
    setAllowUseNewEditor((prev) => {
      globalAllowNewEditor = !prev
      return !prev
    })
  }, [])
  const [showModal, setShowModal] = useState<boolean>()

  const toggleShowDebugModal = useCallback(() => setShowModal((prev) => !prev), [])

  useEffect(() => {
    localStorage.removeItem('editor-permission-debug-override')
  }, [])

  return (
    <DebugContext.Provider
      value={{
        allowUseNewEditor,
        toggleAllowUseNewEditor,
        toggleShowDebugModal,
      }}
    >
      {process.env.REACT_APP_ENV === 'development' && showModal && <DebugModal onClose={toggleShowDebugModal} />}
      {children}
    </DebugContext.Provider>
  )
}

export const useDebug = () => {
  const context = useContext(DebugContext)
  if (!context) throw new Error('Expected to be wrapped in a DebugContextProvider!')
  return context
}
