import { TFunction } from 'i18next'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PopupActionMenu,
  PopupActionMenuBackItem,
  PopupActionMenuChangeItem,
  PopupActionMenuItem,
} from '../../../../../../../common/components/PopupMenu/PopupActionMenu'
import { Task } from '../../../../../../../types/commonTypes'
import { getTaskCardFocusableElementId } from '../../../../../helpers'
import { TaskAction, TaskActionFn } from '../../types'
import styles from './TaskCard.module.css'

enum MenuView {
  ROOT = 'ROOT',
  BOARDS = 'BOARDS',
  LEVELS = 'LEVELS',
}

type TaskMoreMenuProps = {
  task: Task
  maxBoardIndex: number
  maxLevelIndex: number
  index: number
  maxIndex: number
  viewOnly: boolean
  onAction: TaskActionFn
}

const getLevelLabel = (levelIndex: number, t: TFunction) => {
  return levelIndex === 0
    ? t('game_editor.tasks.level_option_first_tasks', 'First tasks')
    : t('game_editor.levels.level_with_number', {
        defaultValue: 'Level %{level_number}',
        level_number: levelIndex,
      })
}

const getBoardLabel = (boardIndex: number, t: TFunction) => {
  return t('game_editor.game_boards.board', 'Board') + ' ' + (boardIndex + 1)
}

export const TaskMoreMenu: React.FC<TaskMoreMenuProps> = ({
  task,
  onAction,
  index,
  maxBoardIndex,
  maxIndex,
  maxLevelIndex,
  viewOnly,
}) => {
  const { t } = useTranslation()

  const [menuView, setMenuView] = useState<MenuView>(MenuView.ROOT)

  const onEdit = useCallback(() => onAction({ task, action: TaskAction.EDIT }), [task, onAction])
  const onCopy = useCallback(() => onAction({ task, action: TaskAction.COPY }), [task, onAction])
  const onDelete = useCallback(() => onAction({ task, action: TaskAction.DELETE }), [task, onAction])

  const onMoveOrderPrev = useCallback(() => {
    onAction({ task, action: TaskAction.MOVE_ORDER, moveIndex: index - 1 })
  }, [task, index, onAction])

  const onMoveOrderNext = useCallback(() => {
    onAction({ task, action: TaskAction.MOVE_ORDER, moveIndex: index + 1 })
  }, [task, index, onAction])

  const onChangeLevel = useCallback(
    (levelIndex: number) => {
      setMenuView(MenuView.ROOT)
      onAction({ task, action: TaskAction.MOVE_LEVEL, moveIndex: levelIndex })
    },
    [task, onAction],
  )

  const onChangeBoard = useCallback(
    (boardIndex: number) => {
      setMenuView(MenuView.ROOT)
      onAction({ task, action: TaskAction.MOVE_BOARD, moveIndex: boardIndex })
    },
    [task, onAction],
  )

  const boardOptions = useMemo(
    () => (maxBoardIndex ? Array.from(Array(maxBoardIndex + 1).keys()) : []),
    [maxBoardIndex],
  )

  const levelOptions = useMemo(
    () => (maxLevelIndex ? Array.from(Array(maxLevelIndex + 1).keys()) : []),
    [maxLevelIndex],
  )

  return (
    <PopupActionMenu
      id={getTaskCardFocusableElementId(task.id)}
      position='inline-down'
      buttonClassName={styles.moreMenuButton}
      closeOnMenuClick={false}
      menuClassName={styles.moreMenuContent}
    >
      {menuView === MenuView.ROOT ? (
        <>
          <PopupActionMenuItem
            icon={viewOnly ? 'eye' : 'text'}
            text={viewOnly ? t('common.view', 'View') : t('common.edit', 'Edit')}
            onClick={onEdit}
          />
          {!viewOnly && (
            <>
              <PopupActionMenuItem icon='duplicate' text={t('common.duplicate', 'Duplicate')} onClick={onCopy} />
              {maxIndex ? (
                <>
                  {(index > 0 || index < maxIndex) && <div className={styles.moreMenuRowSeparator} />}
                  {index > 0 && (
                    <PopupActionMenuItem
                      icon='longerArrowUp'
                      text={t('common.move_up', 'Move up')}
                      onClick={onMoveOrderPrev}
                    />
                  )}
                  {index < maxIndex && (
                    <PopupActionMenuItem
                      icon='longerArrowDown'
                      text={t('common.move_down', 'Move down')}
                      onClick={onMoveOrderNext}
                    />
                  )}
                </>
              ) : null}
              {boardOptions.length > 1 && (
                <>
                  <div className={styles.moreMenuRowSeparator} />
                  <PopupActionMenuChangeItem
                    icon='photo'
                    text={t('game_editor.tasks.action_change_board', 'Change board')}
                    onClick={() => setMenuView(MenuView.BOARDS)}
                    subText={getBoardLabel(task.mapIndex ?? 0, t)}
                  />
                </>
              )}
              {levelOptions.length > 1 && (
                <>
                  <div className={styles.moreMenuRowSeparator} />
                  <PopupActionMenuChangeItem
                    icon='levelGeneric'
                    text={t('game_editor.tasks.action_change_level', 'Change level')}
                    onClick={() => setMenuView(MenuView.LEVELS)}
                    subText={getLevelLabel(task.level ?? 1, t)}
                  />
                </>
              )}
              <div className={styles.moreMenuRowSeparator} />
              <PopupActionMenuItem icon='trash' text={t('common.delete', 'Delete')} onClick={onDelete} />
            </>
          )}
        </>
      ) : (
        <>
          <PopupActionMenuBackItem onClick={() => setMenuView(MenuView.ROOT)} />
          {menuView === MenuView.BOARDS ? (
            <>
              {boardOptions.map((boardIndex) => (
                <PopupActionMenuItem
                  key={`task_board_menu_${boardIndex}`}
                  text={getBoardLabel(boardIndex, t)}
                  onClick={() => onChangeBoard(boardIndex)}
                  disabled={boardIndex === task.mapIndex}
                />
              ))}
            </>
          ) : (
            <>
              {levelOptions.map((levelIndex) => (
                <PopupActionMenuItem
                  key={`level_board_menu_${levelIndex}`}
                  text={getLevelLabel(levelIndex, t)}
                  onClick={() => onChangeLevel(levelIndex)}
                  disabled={levelIndex === task.level}
                />
              ))}
            </>
          )}
        </>
      )}
    </PopupActionMenu>
  )
}
