import { PlayerAccount, Tag } from '../../../../../../types/commonTypes'
import classNames from 'classnames'
import styles from './PlayersAddByPlayerAccountModal.module.css'
import { ListLinePlayerAccount, ListLineTag } from './PlayerAccountModalListLines'

type ToBeAddedListProps = {
  onDoTagSubSearch: (tag: Tag, isCenterColumn: boolean) => void
  onPlayerAccountSelected: (playerAccount: PlayerAccount, toSelected: boolean) => void
  onTagSelected: (tag: Tag, toSelected: boolean) => void
  searchInputValue: string
  toAddPlayerAccounts: PlayerAccount[]
  toAddTags: Tag[]
}

export const ToBeAddedList: React.FC<ToBeAddedListProps> = ({
  onDoTagSubSearch,
  onPlayerAccountSelected,
  onTagSelected,
  searchInputValue,
  toAddPlayerAccounts,
  toAddTags,
}) => {
  return (
    <div className={classNames(styles.contentList, styles.toBeAddedList)}>
      {toAddTags.map((tag, index) => {
        return (
          <ListLineTag
            key={index}
            isCenterColumn={false}
            isSelected={!!toAddTags.find((t) => t.id === tag.id)}
            onClick={() => {}}
            onSelect={onTagSelected}
            onShowAll={onDoTagSubSearch}
            tag={tag}
            searchTerm={searchInputValue}
          />
        )
      })}
      {toAddPlayerAccounts.map((playerAccount, index) => {
        return (
          <ListLinePlayerAccount
            key={index}
            isSelected={!!toAddPlayerAccounts.find((pa) => pa.id === playerAccount.id)}
            onSelect={onPlayerAccountSelected}
            playerAccount={playerAccount}
            searchTerm={searchInputValue}
          />
        )
      })}
    </div>
  )
}
