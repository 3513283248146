import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { GameBoard } from '../../../../../types'
import styles from './EmptyBoards.module.css'

type EmptyBoardsProps = {
  boards: GameBoard[]
  activeBoardIndex: number
  onSetActiveBoard: (index: number) => void
}

export const EmptyBoards: React.FC<EmptyBoardsProps> = ({ boards, activeBoardIndex, onSetActiveBoard }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.emptyBoardsContainer}>
      <div className={styles.emptyBoardsHeader}>
        <span className={styles.emptyBoardsHeaderIcon}>{getIcon('mapWithPin')}</span>
        <div className={styles.emptyBoardsHeaderTitleContainer}>
          <span className={styles.emptyBoardsHeaderTitle}>
            {t('game_editor.game_boards.empty_boards', 'Empty boards')}
          </span>
          <span className={classNames(styles.emptyBoardsHeaderSubtitle, 'tiny')}>
            {t('game_editor.game_boards.no_tasks_assigned', 'No tasks assigned')}
          </span>
        </div>
      </div>
      <ul className={styles.emptyBoardsList}>
        {boards.map((board, index) => {
          const boardIndex = board.mapIndex ?? 0
          const background = `linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, rgba(255, 255, 255, ${
            boardIndex === activeBoardIndex ? 0.25 : 0.65
          }) 0%, rgba(255, 255, 255, ${boardIndex === activeBoardIndex ? 0.25 : 0.65}) 100%), url('${
            board.url
          }') no-repeat center/100%`
          return (
            <li
              key={`emptyBoard_${index}`}
              style={{ background }}
              className={classNames(
                styles.emptyBoardContainer,
                activeBoardIndex === boardIndex && styles.emptyBoardContainer_active,
              )}
              onClick={() => onSetActiveBoard(boardIndex)}
            >
              <button className={styles.boardIconButton}>{getIcon('photo')}</button>
              <span className={styles.boardTitle}>
                {t('game_editor.game_boards.board', 'Board') + ' ' + (boardIndex + 1)}
              </span>
              {activeBoardIndex === boardIndex && (
                <span className={styles.activePill}>{t('common.active', 'Active')}</span>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
