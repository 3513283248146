import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  addPlayerManual,
  addPlayersWithTagIdsOrPlayerAccountIds,
  postEmail,
  updatePlayerManual,
} from '../../../../../api/gameApiService'
import { Button } from '../../../../../common/components/button/Button'
import { getIcon } from '../../../../../common/components/icons/utils'
import { useBusinessSettings } from '../../../../../contexts/BusinessSettingsContext'
import { useConfirmation } from '../../../../../contexts/ConfirmationContext'
import { useGame } from '../../../../../contexts/GameContext'
import { useNotification } from '../../../../../contexts/NotificationContext'
import { useUser } from '../../../../../contexts/userContext'
import { Player } from '../../../../../types/commonTypes'
import styles from '../AddPerson.module.css'
import { InvitePlayer } from './InvitePlayer'
import { PlayersAddByPlayerAccountModal } from './PlayersAddByPlayerAccountModal/PlayersAddByPlayerAccountModal'
import { AddPlayerForm, PlayerModalMode, PlayersAddManuallyModal } from './PlayersAddManuallyModal'
import { PLAYER_LIST_ID, PlayersList } from './PlayersList'
import { PlayerEmailForm, PlayersMenu } from './PlayersMenu'

type PlayersMenuProps = {
  onManageAnswersClick?: () => void
  onOpenGameSettings: () => void
}

const scrollPlayersToBottom = () => {
  setTimeout(() => {
    const element = document.getElementById(PLAYER_LIST_ID)
    if (element) {
      element.scrollTop = element.scrollHeight
    }
  }, 1000)
}

export const PlayersSelected: React.FC<PlayersMenuProps> = ({ onManageAnswersClick, onOpenGameSettings }) => {
  const { requestConfirmation } = useConfirmation()
  const { notifyError } = useNotification()
  const { t } = useTranslation()
  const { gameData, people, removePlayer, editorPermissions } = useGame()
  const { user } = useUser()
  const { data: businessData } = useBusinessSettings()

  const [addManuallyModalState, setAddManuallyModalState] = useState(PlayerModalMode.CLOSED)
  const [editPlayer, setEditPlayer] = useState<Player | undefined>()
  const [isPlayerAccountModalOpen, setIsPlayerAccountModalOpen] = useState<boolean>(false)

  const maxPlayers = businessData?.businessInfo.maxPlayers

  const handleDeletePlayer = useCallback(
    async (id: number, name: string) => {
      const confirmed = await requestConfirmation({
        title: t('game_editor.add_people.delete_player_confirmation_title', 'Deleting a player'),
        text: (
          <Trans
            i18nKey='game_editor.add_people.delete_person_confirmation_text'
            values={{ name }}
            components={{ bold: <b /> }}
          >
            {'Are you sure you want to remove <bold>%{name}</bold>?'}
          </Trans>
        ),
      })
      if (confirmed) {
        const result = await removePlayer(id)
        if (!result.success) {
          notifyError({
            title: t('game_editor.add_people.delete_player_error_notification_title', 'Failed to remove player'),
            content:
              result.errorMessage ??
              t(
                'game_editor.add_people.delete_player_error_notification_default_content',
                'An error occurred while deleting the player. Please try again or contact us for support.',
              ),
          })
        }
      }
    },
    [removePlayer, requestConfirmation, notifyError, t],
  )

  const openEditPlayerModal = useCallback((player: Player) => {
    setEditPlayer(player)
    setAddManuallyModalState(PlayerModalMode.FULL_EDIT)
  }, [])

  const openEditStartingTaskModal = useCallback((player: Player) => {
    setEditPlayer(player)
    setAddManuallyModalState(PlayerModalMode.STARTING_TASK)
  }, [])

  const togglePlayerAccountArea = () => {
    setIsPlayerAccountModalOpen((prev) => !prev)
  }

  const openAddPlayerModal = () => {
    setAddManuallyModalState(PlayerModalMode.NEW)
  }

  const handleCloseAddManuallyModal = useCallback(() => {
    setEditPlayer(undefined)
    setAddManuallyModalState(PlayerModalMode.CLOSED)
  }, [])

  const handleAddPlayer = async (values: AddPlayerForm) => {
    const result = await addPlayerManual({
      gameId: gameData?.gameId ?? 0,
      nickName: values.nickName,
      teamMemberNames: values.teamMemberNames,
      startTask: values.showStartingTaskSelection ? values.startingTask : undefined,
    })
    if (result.success) {
      if (result.value.error) {
        notifyError({
          title: t('game_editor.add_people.failed_to_add_player_manually_notification.title', 'Failed to add player'),
          content: result.value.msg,
        })
      }
      setEditPlayer(undefined)
      setAddManuallyModalState(PlayerModalMode.CLOSED)
      scrollPlayersToBottom()
    } else {
      notifyError({
        title: t('game_editor.add_people.failed_to_add_player_manually_notification.title', 'Failed to add player'),
        content: t(
          'game_editor.add_people.failed_to_add_player_manually_notification.content',
          'An error occurred while adding player. Please try again or contact us for support.',
        ),
        timeout: 3000,
      })
    }
  }

  const handleUpdatePlayer = async (values: AddPlayerForm) => {
    const result = await updatePlayerManual({
      gameId: gameData?.gameId ?? 0,
      userId: editPlayer?.id ?? 0,
      nickName: values.nickName,
      email: editPlayer && !editPlayer.isEmailTrusted ? editPlayer.email : '', //untrusted email needs to be saved in BE with teamMemberNames
      teamMemberNames: values.teamMemberNames,
      startTask:
        values.showStartingTaskSelection || addManuallyModalState === PlayerModalMode.STARTING_TASK
          ? values.startingTask
          : undefined,
    })

    if (result.success) {
      setEditPlayer(undefined)
      setAddManuallyModalState(PlayerModalMode.CLOSED)
    } else {
      console.error(result.error)
      notifyError({
        title: t(
          'game_editor.add_people.failed_to_update_player_manually_notification.title',
          'Failed to update player',
        ),
        content: t(
          'game_editor.add_people.failed_to_update_player_manually_notification.content',
          'An error occurred while updating player. Please try again or contact us for support.',
        ),
        timeout: 3000,
      })
    }
  }

  const handleAddMultiplePlayersByPlayerAccount = async (
    tagIds: number[] | null,
    playerAccountIds: number[] | null,
    startingTask?: number,
  ) => {
    const response = await addPlayersWithTagIdsOrPlayerAccountIds({
      gameId: gameData?.gameId || 0,
      tagIds,
      playerAccountIds,
      startingTask,
    })
    if (!response.success) {
      console.error(response)
      notifyError({
        title: t('game_editor.add_people.failed_to_add_player_by_tag_notification.title', 'Failed to add players'),
        content: t(
          'game_editor.add_people.failed_to_add_player_by_tag_notification.content',
          'An error occurred while adding players. Please try again or contact us for support.',
        ),
        timeout: 3000,
      })
    } else {
      scrollPlayersToBottom()
    }
  }

  const handleAddByEmail = async (values: PlayerEmailForm) => {
    const result = await postEmail({
      gameId: gameData?.gameId ?? 0,
      added_by_email_users: values.emails,
      startingTaskId: values.startingTask ?? null,
    })
    if (result.success) {
      scrollPlayersToBottom()
    } else {
      console.error(result.error)
      notifyError({
        title: t('game_editor.add_people.failed_to_add_player_by_email_notification.title', 'Failed to add players'),
        content: t(
          'game_editor.add_people.failed_to_add_player_by_email_notification.content',
          'An error occurred while adding players by email. Please try again or contact us for support.',
        ),
        timeout: 3000,
      })
    }
    return result.success
  }

  const filteredPlayers = people.players.filter((player) => !player.deleted)

  if (gameData == null) {
    return null
  }

  return (
    <>
      <div className={styles.playersContainer}>
        {editorPermissions.actions.addPlayers && (
          <div className={classNames(styles.playersContainerColumn, styles.playersInviteContainer)}>
            <PlayersMenu
              game={gameData}
              playersCount={filteredPlayers.length}
              isPlayerAccountModalOpen={isPlayerAccountModalOpen}
              onAddPlayerAccount={togglePlayerAccountArea}
              onAddManually={openAddPlayerModal}
              maxPlayers={maxPlayers}
              onAddByEmail={handleAddByEmail}
            />
            <InvitePlayer game={gameData} />
            <Button onClick={onOpenGameSettings} variant='outline-tiny' className={styles.settingsButton}>
              <span className='iconWrapperBig'>{getIcon('settings')}</span>
              {t('game_editor.add_people.game_settings', 'View game settings')}
            </Button>
          </div>
        )}
        <PlayersList
          players={filteredPlayers}
          isOpen={gameData.open}
          isOrdered={gameData.advancedSettings.orderingEnabled}
          tasks={gameData.tasks}
          onDelete={handleDeletePlayer}
          onManageAnswersClick={onManageAnswersClick}
          onDefineStartingTask={openEditStartingTaskModal}
          onEditPlayerDetails={openEditPlayerModal}
          permissions={editorPermissions}
        />
      </div>
      {isPlayerAccountModalOpen && (
        <PlayersAddByPlayerAccountModal
          onAddMultiple={handleAddMultiplePlayersByPlayerAccount}
          onClose={togglePlayerAccountArea}
          tags={user?.tags ?? []}
          maxPlayerCount={maxPlayers}
          existingPlayerCount={filteredPlayers.length}
          tasks={gameData.tasks}
          isOrdered={gameData.advancedSettings.orderingEnabled}
        />
      )}
      {addManuallyModalState !== PlayerModalMode.CLOSED && (
        <PlayersAddManuallyModal
          mode={addManuallyModalState}
          player={editPlayer}
          tasks={gameData.tasks}
          isOrdered={gameData.advancedSettings.orderingEnabled}
          onClose={handleCloseAddManuallyModal}
          onSubmit={addManuallyModalState === PlayerModalMode.NEW ? handleAddPlayer : handleUpdatePlayer}
        />
      )}
    </>
  )
}
