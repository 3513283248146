import * as React from 'react'
import { SVGProps } from 'react'
const SvgDoorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 25 30' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12.143.786c.5-.143.928-.072 1.357.214.357.286.643.643.714 1.143.072.071.072.214.072.357v25c0 1-.786 1.786-1.786 1.786h-.286L1.5 27.786C.643 27.643 0 26.857 0 26V5.143c0-.857.571-1.572 1.429-1.714zM1.786 26 12.5 27.5v-25L1.786 5.143zm20.5-22.643h-5.357c-.5 0-.858.429-.858.857s.429.857.858.857h5.357c.5 0 .857.429.857.858v17.857c0 .5-.429.857-.857.857h-5.357c-.5 0-.858.428-.858.857 0 .428.429.857.858.857h5.357c1.5 0 2.714-1.214 2.714-2.714V5.786c0-1.215-1.214-2.429-2.714-2.429zM9.07 13.643H8.93c-.286 0-.572.143-.786.357a1.41 1.41 0 0 0-.429 1c0 .714.572 1.357 1.357 1.357.715 0 1.358-.643 1.358-1.357 0-.357-.143-.714-.358-.929-.357-.285-.642-.428-1-.428z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgDoorIcon
