import classNames from 'classnames'
import React from 'react'
import styles from './Selectable.module.css'

export type SelectableOptionProps = {
  text: string
  selectedOption: string | null
  disabled?: boolean
  large?: boolean
  id: string
  groupName: string
  autoFocus?: boolean
  onChange: (id: string) => void
  onClick?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const SelectableOption: React.FC<SelectableOptionProps> = ({
  text,
  onChange,
  id,
  selectedOption,
  disabled = false,
  large = false,
  groupName,
  autoFocus = false,
  onClick,
  onKeyDown,
}) => {
  const onChangeInternal = () => onChange(id)

  return (
    <label
      className={classNames(
        styles.label,
        selectedOption === id && !disabled && styles.labelActive,
        disabled && styles.labelDisabled,
        large && styles.labelLarge,
      )}
    >
      <input
        id={id}
        className={styles.originalCheckbox}
        type='radio'
        name={groupName}
        onChange={onChangeInternal}
        checked={selectedOption === id}
        disabled={disabled}
        autoFocus={autoFocus ?? false}
        onClick={onClick}
        onKeyDown={onKeyDown}
      />
      {text}
    </label>
  )
}
