import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../../../../common/components/loaders/spinner/Spinner'
import { useGame } from '../../../../contexts/GameContext'
import { getOldUIRoutes } from '../../../../routes'
import styles from './GameReport.module.css'

type GameReportProps = {
  gameId: number | null
}

export const GameReport: React.FC<GameReportProps> = ({ gameId }) => {
  const gameReportUrl = getOldUIRoutes.gameReport(gameId)
  const [reportLoaded, setReportLoaded] = useState(false)
  const { tasks, people } = useGame()
  const { t } = useTranslation()

  const handleReportLoaded = useCallback(() => {
    setReportLoaded(true)
    const msg = { exerciseData: tasks, playerData: people.players }
    const reportFrame: HTMLIFrameElement = document.getElementById('reportFrame') as HTMLIFrameElement
    reportFrame.contentWindow?.postMessage(msg, '*')
  }, [people.players, tasks])

  return (
    <div className={styles.mainContainer}>
      <div className={styles.reportsView}>
        {!reportLoaded && (
          <div className={styles.spinnerContainer}>
            {gameId ? (
              <Spinner remSize={4} />
            ) : (
              <h2 className={styles.notAvailableInfo}>
                {t(
                  'game_editor.report.create_game_first',
                  'To see the report, make sure the game is set up in the settings',
                )}
              </h2>
            )}
          </div>
        )}
        {gameId && (
          <iframe
            id={'reportFrame'}
            style={reportLoaded ? {} : { display: 'none' }}
            onLoad={handleReportLoaded}
            title='Reports view'
            src={gameReportUrl}
            className={styles.reportContainer}
          />
        )}
      </div>
    </div>
  )
}
