import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

import { useTranslation } from 'react-i18next'
import { ModalPortal } from '../../common/components/Portal/Portal'
import { Button } from '../../common/components/button/Button'
import { useTheme } from '../../contexts/ThemeContext'
import { useTrapFocus } from '../../hooks/useTrapFocus'
import styles from './Wizard.module.css'
import { StepProgress } from './components/StepProgress'

export type WizardHeaderButton = {
  label: string
  onClick: () => void
}

export type WizardProps = {
  stepIndex: number
  stepsCount: number
  backdropVisible?: boolean
  isLarge?: boolean
  headerButton?: WizardHeaderButton
}

export const Wizard: React.FC<PropsWithChildren<WizardProps>> = ({
  stepIndex,
  stepsCount,
  backdropVisible = true,
  isLarge = false,
  headerButton,
  children,
}) => {
  const { t } = useTranslation()
  const modalRef = useTrapFocus<HTMLDivElement>()
  const { logoUrl } = useTheme()

  return (
    <ModalPortal>
      {backdropVisible && <div className={styles.backdrop} />}
      <div ref={modalRef} className={classNames(styles.container, isLarge && styles.largeContainer)}>
        <div className={styles.content}>
          <div className={styles.header}>
            {headerButton != null && (
              <Button variant='borderless-normal' onClick={headerButton.onClick}>
                {headerButton.label}
              </Button>
            )}
            <img src={logoUrl} width={90} alt='logo' className={styles.seppoLogo} />
          </div>
          <div>{children}</div>
          <div className={classNames('medium', styles.footer)}>
            {t('wizard.step', 'Step')} {stepIndex} / {stepsCount}
          </div>
        </div>
        <StepProgress percent={(1 / stepsCount) * stepIndex} />
      </div>
    </ModalPortal>
  )
}
