import * as React from 'react'
import { SVGProps } from 'react'

const SvgMapWithPinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.152.123a6.125 6.125 0 0 0-6.125 6.125c0 .737.25 1.553.582 2.333.339.797.799 1.637 1.292 2.445.987 1.614 2.15 3.165 2.87 4.087.093.12.196.218.299.3l.005.003a1.75 1.75 0 0 0 2.455-.301c.721-.922 1.885-2.474 2.872-4.09.494-.807.954-1.647 1.293-2.444.331-.78.582-1.596.582-2.333A6.125 6.125 0 0 0 14.152.123ZM9.777 6.248a4.375 4.375 0 0 1 4.375-4.375v3.063a1.313 1.313 0 0 0 0 2.625v6.476c-.704-.9-1.821-2.392-2.758-3.924-.468-.767-.881-1.527-1.175-2.217-.3-.707-.442-1.264-.442-1.648Zm4.378 7.785c.704-.9 1.819-2.39 2.754-3.92.469-.767.882-1.527 1.175-2.217.301-.707.443-1.264.443-1.648a4.375 4.375 0 0 0-4.375-4.375v3.063a1.313 1.313 0 0 1 .001 2.625l.002 6.472Zm0 .006v-.006l-.003.004.003.002Zm0 0v.002-.002Z'
      fill={props.color ?? '#A3A3A3'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.278 11.358a.875.875 0 0 1 1.032-.684 2.626 2.626 0 0 1 1.954 1.7l.006.018 1.332 4.063.013.04 1.704 5.2.02.06.943 2.879a2.623 2.623 0 0 1-2.479 3.49H3.5c-.298 0-.592-.052-.87-.15h-.002a2.625 2.625 0 0 1-1.606-3.34l2.663-8.127a.861.861 0 0 1 .021-.063l1.327-4.05.006-.018a2.622 2.622 0 0 1 1.94-1.698.875.875 0 1 1 .357 1.713.873.873 0 0 0-.643.558l-.958 2.924h4.896a.885.885 0 0 1 .713.34.873.873 0 0 1 .168.696l-.703 4.214h6.685l-.703-4.215a.857.857 0 0 1 .021-.407.873.873 0 0 1 .86-.628h4.897l-.958-2.925a.876.876 0 0 0-.65-.559.875.875 0 0 1-.683-1.031Zm2.865 6.265h-4.458l.583 3.5h5.022l-1.147-3.5Zm-19.129 3.5 1.147-3.5H9.62l-.584 3.5h-5.02Zm20.85 1.75H19.56l.583 3.5h4.66a.876.876 0 0 0 .825-1.166l-.006-.02-.759-2.314Zm-7.078 0h-7.268l-.584 3.5h8.435l-.583-3.5Zm-9.042 0H3.44l-.76 2.316-.006.018a.875.875 0 0 0 .825 1.166h4.66l.584-3.5Z'
      fill={props.color ?? '#A3A3A3'}
    />
  </svg>
)

export default SvgMapWithPinIcon
