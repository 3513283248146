import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../../../../common/components/Modal/Modal'
import { useConfirmation } from '../../../../../contexts/ConfirmationContext'
import { useGame } from '../../../../../contexts/GameContext'
import { useNotification } from '../../../../../contexts/NotificationContext'
import { useDisableBodyScroll } from '../../../../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../../../../hooks/useTrapFocus'
import { Game, Subtask, Task } from '../../../../../types/commonTypes'
import { TaskForm } from './TaskForm'
import styles from './TaskModal.module.css'
import { validateTask } from './helpers'

type TaskModalProps = {
  game: Game
  initialValues: Partial<Task>
  onClose: () => void
  onDelete?: () => void
  onCopy?: (hasChanges: boolean) => void
  editingPinnedCopy: boolean
}

export const TaskModal: React.FC<TaskModalProps> = ({
  game,
  initialValues,
  onClose,
  onDelete,
  onCopy,
  editingPinnedCopy,
}) => {
  const { t } = useTranslation()
  const { notifyError, notifySuccess, notifyPosition } = useNotification()
  const { requestConfirmation } = useConfirmation()
  const { createOrUpdateTask, editorPermissions } = useGame()

  const [showTaskLibraryModal, setShowTaskLibraryModal] = useState<boolean>(false)
  const [showAutomaticBadgeModal, setShowAutomaticBadgeModal] = useState<boolean>(false)

  const modalRef = useTrapFocus<HTMLDivElement>(!(showTaskLibraryModal || showAutomaticBadgeModal))
  useDisableBodyScroll()

  const showPositionNotification = useCallback(() => {
    notifyPosition({
      title: t('game_editor.tasks.copy_modal_exit.title', 'Position the task'),
      content: t('game_editor.tasks.copy_modal_exit.content', 'You can now position the task on the game board'),
    })
  }, [notifyPosition, t])

  const onSubmit = async (values: Task) => {
    const forceClosed = !initialValues.advanced?.isFlash && !!values.advanced.isFlash
    const subtasksToDelete = (initialValues.subtasks || [])?.reduce(
      (ids: number[], { id: initialSubtaskId }: Subtask) => {
        return initialSubtaskId == null || values.subtasks.some((subtask) => subtask.id === initialSubtaskId)
          ? ids
          : [...ids, initialSubtaskId]
      },
      [],
    )
    const result = await createOrUpdateTask(
      {
        ...values,
        ...(forceClosed && { isOpen: !forceClosed }),
      },
      subtasksToDelete,
    )
    if (result) {
      if (editingPinnedCopy) {
        showPositionNotification()
      } else {
        notifySuccess({
          title:
            values.id == null
              ? t('game_editor.tasks.create_success_notification.title', 'New task created')
              : t('game_editor.tasks.update_success_notification.title', 'Task updated'),
          content:
            values.id == null
              ? values.advanced.isFlash
                ? t('game_editor.tasks.create_success_notification.content_flash_task', 'New flash task was created.')
                : t(
                    'game_editor.tasks.create_success_notification.content',
                    'New task was created. You can now position it on the map.',
                  )
              : t('game_editor.tasks.update_success_notification.content', 'Task was successfully updated.'),
        })
      }
      onClose()
    } else {
      notifyError({
        title:
          values.id == null
            ? t('game_editor.tasks.create_error_notification.title', 'Failed to create new task')
            : t('game_editor.tasks.update_error_notification.title', 'Failed to update task'),
        content:
          values.id == null
            ? t(
                'game_editor.tasks.create_error_notification.content',
                'An error occurred while creating the new task. Please try again or contact us for support.',
              )
            : t(
                'game_editor.tasks.update_error_notification.content',
                'An error occurred while updating the task. Please try again or contact us for support.',
              ),
      })
    }
  }

  const handleOnCloseClick = (hasChanges: boolean) => {
    if (hasChanges && editorPermissions.actions.tasks) {
      requestConfirmation({
        title: t('game_editor.tasks.close_confirmation.title', 'Are you sure you want to exit?'),
        text: t('game_editor.tasks.close_confirmation.text', 'Any unsaved changes will be lost'),
      }).then((response) => {
        if (response) {
          onClose()
          if (editingPinnedCopy) showPositionNotification()
        }
      })
    } else {
      onClose()
      if (editingPinnedCopy) showPositionNotification()
    }
  }

  const validate = (values: Partial<Task>) => {
    return validateTask(values, game, t)
  }

  return (
    <Modal>
      <div className={styles.taskModalContainer} ref={modalRef}>
        <TaskForm
          game={game}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onCancel={handleOnCloseClick}
          onDelete={onDelete}
          onCopy={onCopy}
          validate={validate}
          onSetShowTaskLibraryModal={setShowTaskLibraryModal}
          showTaskLibraryModal={showTaskLibraryModal}
          showAutomaticBadgeModal={showAutomaticBadgeModal}
          onSetShowAutomaticBadgeModal={setShowAutomaticBadgeModal}
          viewOnly={!editorPermissions.actions.tasks}
        />
      </div>
    </Modal>
  )
}
