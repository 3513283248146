import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SingleValue } from 'react-select'
import { Select, SelectOption } from '../../common/components/Select/Select'
import { LANGUAGE_SELECT_DEFAULT_OPTION, LANGUAGE_SELECT_OPTIONS } from '../../common/constants'
import { HelpMenu } from '../../composites/HelpMenu/HelpMenu'
import styles from './Login.module.css'
import classNames from 'classnames'
import { PasswordResetForm } from './components/PasswordResetForm'

export const PasswordReset: React.FC = () => {
  const { i18n } = useTranslation()
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), [])

  const [defaultLanguageOption] = useState(() => {
    return LANGUAGE_SELECT_OPTIONS.find((option) => option.value === i18n.language) ?? LANGUAGE_SELECT_DEFAULT_OPTION
  })

  const handleChangeLanguage = (selectOption: SingleValue<SelectOption>) => {
    i18n.changeLanguage(selectOption?.value)
  }

  useEffect(() => {
    const language = queryParams.get('l')
    if (language) i18n.changeLanguage(language)
  }, [i18n, queryParams])

  return (
    <div className={styles.pageContainer}>
      <div className={styles.languageContainer}>
        <Select
          options={LANGUAGE_SELECT_OPTIONS}
          defaultValue={defaultLanguageOption}
          onChange={handleChangeLanguage}
          className={styles.languageSelect}
        />
      </div>
      <div className={styles.loginWizard}>
        <PasswordResetForm />
      </div>
      <p className={styles.learnMoreBottomOffset} />
      <HelpMenu buttonClassName={classNames(styles.invertedHelpButton, styles.hideOnSmallScreen)} />
    </div>
  )
}
