import * as React from 'react'
import { SVGProps } from 'react'

const SvgJsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.208 13.855a5.867 5.867 0 0 1-2.358-.468A4.582 4.582 0 0 1 .104 12.02l1.62-1.944c.684.924 1.464 1.386 2.34 1.386 1.188 0 1.782-.696 1.782-2.088V3.38H1.418V1.04h7.326v8.172c0 1.548-.384 2.712-1.152 3.492-.768.768-1.896 1.152-3.384 1.152Zm11.556 0c-.996 0-1.962-.132-2.898-.396-.924-.276-1.668-.63-2.232-1.062l.99-2.196c.54.396 1.182.714 1.926.954s1.488.36 2.232.36c.828 0 1.44-.12 1.836-.36.396-.252.594-.582.594-.99 0-.3-.12-.546-.36-.738-.228-.204-.528-.366-.9-.486-.36-.12-.852-.252-1.476-.396-.96-.228-1.746-.456-2.358-.684a3.9 3.9 0 0 1-1.584-1.098c-.432-.504-.648-1.176-.648-2.016 0-.732.198-1.392.594-1.98.396-.6.99-1.074 1.782-1.422.804-.348 1.782-.522 2.934-.522.804 0 1.59.096 2.358.288.768.192 1.44.468 2.016.828l-.9 2.214c-1.164-.66-2.328-.99-3.492-.99-.816 0-1.422.132-1.818.396-.384.264-.576.612-.576 1.044 0 .432.222.756.666.972.456.204 1.146.408 2.07.612.96.228 1.746.456 2.358.684a3.835 3.835 0 0 1 1.566 1.08c.444.492.666 1.158.666 1.998 0 .72-.204 1.38-.612 1.98-.396.588-.996 1.056-1.8 1.404-.804.348-1.782.522-2.934.522Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)

export default SvgJsIcon
