import { Player, Task } from '../../../../../types/commonTypes'

export enum PlayTab {
  PLAYERS = 'PLAYERS',
  TASKS = 'TASKS',
}

export type TaskSummary = {
  task: Task
  totalAnswers: number
  gradedAnswers: number
  needsReview: boolean
}

export type PlayerSummary = {
  player: Player
  totalScore: number
  tasksAnswered: number
  tasksUngraded: number
  needsReview: boolean
}

export enum EvaluationSortBy {
  AnswerTimestamp_asc = 'AnswerTimestamp_asc',
  AnswerTimestamp_desc = 'AnswerTimestamp_desc',
  PlayerName_asc = 'PlayerName_asc',
  PlayerName_desc = 'PlayerName_desc',
  Evaluation_asc = 'Evaluation_asc',
  TaskName_asc = 'TaskName_asc',
  TaskName_desc = 'TaskName_desc',
}

export const DEFAULT_EVAL_SORT_BY_TASKS = EvaluationSortBy.AnswerTimestamp_asc
export const DEFAULT_EVAL_SORT_BY_PLAYERS = EvaluationSortBy.Evaluation_asc

export const AWARD_BADGE_BUTTON_ID = 'playersEvalPanel_AwardBadgeButton'
