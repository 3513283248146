import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTripleBarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M6 7.928C6 6.863 6.863 6 7.928 6h16.144a1.928 1.928 0 0 1 0 3.856H7.928A1.928 1.928 0 0 1 6 7.928Zm0 7.807c0-1.037.841-1.879 1.879-1.879H24.12a1.879 1.879 0 1 1 0 3.758H7.879A1.879 1.879 0 0 1 6 15.735Zm1.879 5.88a1.879 1.879 0 1 0 0 3.757H24.12a1.879 1.879 0 1 0 0-3.758H7.879Z'
      clipRule='evenodd'
    />
    <path
      fill='url(#triple_bar_icon_svg__a)'
      fillOpacity={0.25}
      fillRule='evenodd'
      d='M6 7.928C6 6.863 6.863 6 7.928 6h16.144a1.928 1.928 0 0 1 0 3.856H7.928A1.928 1.928 0 0 1 6 7.928Zm0 7.807c0-1.037.841-1.879 1.879-1.879H24.12a1.879 1.879 0 1 1 0 3.758H7.879A1.879 1.879 0 0 1 6 15.735Zm1.879 5.88a1.879 1.879 0 1 0 0 3.757H24.12a1.879 1.879 0 1 0 0-3.758H7.879Z'
      clipRule='evenodd'
    />
    <defs>
      <linearGradient id='triple_bar_icon_svg__a' x1={16} x2={16} y1={6} y2={25.372} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgTripleBarIcon
