import styles from './ThreeDotsWithOverlay.module.css'
import { ThreeDots } from '../ThreeDots/ThreeDots'
import { Spinner } from '../spinner/Spinner'

type ThreeDotsWithOverlayProps = {
  loader?: 'dots' | 'spinner'
}

export const ThreeDotsWithOverlay: React.FC<ThreeDotsWithOverlayProps> = ({ loader = 'dots' }) => {
  return (
    <>
      <div className={styles.loaderBackgroundOverlay} />
      <div className={styles.loaderContainer}>{loader === 'spinner' ? <Spinner /> : <ThreeDots />}</div>
    </>
  )
}
