import * as React from 'react'
import { SVGProps } from 'react'

const SvgSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.01 4.69a2.437 2.437 0 0 1-.82-.595l-1.17-1.292a2.439 2.439 0 0 0-4.24 1.755l.084 1.744a2.437 2.437 0 0 1-2.562 2.564l-1.743-.088a2.437 2.437 0 0 0-1.754 4.24L4.1 14.194a2.44 2.44 0 0 1 0 3.622l-1.296 1.17a2.437 2.437 0 0 0 1.763 4.241l1.742-.088a2.438 2.438 0 0 1 2.56 2.56l-.09 1.742a2.439 2.439 0 0 0 4.24 1.753l1.177-1.296a2.44 2.44 0 0 1 3.62 0l1.171 1.296a2.44 2.44 0 0 0 4.235-1.755l-.088-1.743a2.435 2.435 0 0 1 2.56-2.56l1.742.089a2.438 2.438 0 0 0 1.76-4.245l-1.298-1.17a2.44 2.44 0 0 1 0-3.622l1.297-1.17a2.439 2.439 0 0 0-1.755-4.245l-1.743.088a2.438 2.438 0 0 1-2.553-2.557l.088-1.742a2.439 2.439 0 0 0-4.245-1.76l-1.177 1.293a2.436 2.436 0 0 1-2.801.595Zm-2.97 15.27a5.602 5.602 0 1 1 7.922-7.922 5.602 5.602 0 0 1-7.923 7.922Z'
      fill={props.color ?? 'var(--grey-700)'}
    />
  </svg>
)

export default SvgSettingsIcon
