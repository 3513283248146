import { TFunction } from 'i18next'
import { SelectOption } from '../../../common/components/Select/Select'
import { MemberRole } from '../../../types/commonTypes'

export const getMemberRoleOptions = (t: TFunction): SelectOption[] => [
  { label: t('settings_organization.members.role_label.admin', 'Admin'), value: MemberRole.ADMIN },
  {
    label: t('settings_organization.members.role_label.instructor', 'Instructor'),
    value: MemberRole.INSTRUCTOR,
  },
]
