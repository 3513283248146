import { useTranslation } from 'react-i18next'
import styles from './LoginMethod.module.css'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'

export const TrialEmail: React.FC = () => {
  const { t } = useTranslation()
  const { fadeTransition } = useFadeAnimation()

  return (
    <div className={styles.mainContentWrapper}>
      <h1 className={styles.title}>{t('login.instructor.right.trial.title', 'Start your free 14-day trial')}</h1>
      <div className={fadeTransition}>
        <div className={styles.paragraphContainer}>
          <p>
            {t(
              'login.instructor.right.trial.body',
              'Start by giving your email. This will save your work and allow you to share your games to your players.',
            )}
          </p>
        </div>
        <InputFormField
          label='Email'
          placeholder={t('login.instructor.right.trial.email_placeholder', 'Enter your e-mail address')}
          srOnlyLabel
          name='email'
          type='email'
          big
        />
      </div>
    </div>
  )
}
