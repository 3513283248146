import * as React from 'react'
import { SVGProps } from 'react'
const SvgPinAddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#pin-add_svg__a)' fillRule='evenodd' clipRule='evenodd'>
      <path
        d='M17.47 3.9a9.059 9.059 0 0 1 2.372 8.602 6.563 6.563 0 0 0-6.296 8.1l-2.39 2.4s-6.36-6.292-6.404-6.337a9.04 9.04 0 0 1 0-12.765 8.959 8.959 0 0 1 12.717 0Zm-3.654 3.666a3.828 3.828 0 0 0-5.411 0 3.862 3.862 0 0 0 0 5.432c.067.068.146.125.225.181.079.057.158.114.226.181.045-.497.27-1.086.676-1.493.857-.86 2.3-.86 3.157 0 .406.407.676.95.676 1.493.18-.09.316-.226.451-.362a3.862 3.862 0 0 0 0-5.432Zm2.53 7.919a5.062 5.062 0 1 1 7.16 7.159 5.062 5.062 0 0 1-7.16-7.16Zm3.58-.358a3.938 3.938 0 1 0 0 7.876 3.938 3.938 0 0 0 0-7.876Zm.562 1.687a.563.563 0 0 0-1.125 0v1.688h-1.687a.562.562 0 1 0 0 1.125h1.687v1.687a.562.562 0 1 0 1.125 0v-1.687h1.688a.562.562 0 1 0 0-1.125h-1.688v-1.688Z'
        fill={props.color ?? '#CC3E76'}
      />
      <path
        d='M17.47 3.9a9.059 9.059 0 0 1 2.372 8.602 6.563 6.563 0 0 0-6.296 8.1l-2.39 2.4s-6.36-6.292-6.404-6.337a9.04 9.04 0 0 1 0-12.765 8.959 8.959 0 0 1 12.717 0Zm-3.654 3.666a3.828 3.828 0 0 0-5.411 0 3.862 3.862 0 0 0 0 5.432c.067.068.146.125.225.181.079.057.158.114.226.181.045-.497.27-1.086.676-1.493.857-.86 2.3-.86 3.157 0 .406.407.676.95.676 1.493.18-.09.316-.226.451-.362a3.862 3.862 0 0 0 0-5.432Zm2.53 7.919a5.062 5.062 0 1 1 7.16 7.159 5.062 5.062 0 0 1-7.16-7.16Zm3.58-.358a3.938 3.938 0 1 0 0 7.876 3.938 3.938 0 0 0 0-7.876Zm.562 1.687a.563.563 0 0 0-1.125 0v1.688h-1.687a.562.562 0 1 0 0 1.125h1.687v1.687a.562.562 0 1 0 1.125 0v-1.687h1.688a.562.562 0 1 0 0-1.125h-1.688v-1.688Z'
        fill={props.color ?? 'url(#pin-add_svg__b)'}
        fillOpacity={props.color ? 1 : 0.25}
      />
    </g>
    <defs>
      <linearGradient id='pin-add_svg__b' x1={13.551} y1={1.252} x2={13.551} y2={24.127} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <clipPath id='pin-add_svg__a'>
        <path fill='#fff' transform='translate(.988 .502)' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgPinAddIcon
