import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getScoreboard } from '../../../../../../../api/gameApiService'
import { ThreeDots } from '../../../../../../../common/components/loaders/ThreeDots/ThreeDots'
import { parseScoreboard } from '../../../../../../../contexts/GameContextHelper'
import { useNotification } from '../../../../../../../contexts/NotificationContext'
import { PlayerScore } from '../../../../../../../types/commonTypes'
import styles from './Scoreboard.module.css'
import { PlayerList } from './components/PlayerList'
import { ScoreboardHeader } from './components/ScoreboardHeader'
import { TopThreeRow } from './components/TopThreeRow'

type ScoreboardProps = {
  gameId: number
  onClose: () => void
}
export const Scoreboard: React.FC<ScoreboardProps> = ({ gameId, onClose }) => {
  const { t } = useTranslation()
  const { notifyError } = useNotification()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [topThree, setTopThree] = useState<PlayerScore[]>([])
  const [scoreboardRest, setScoreboardRest] = useState<PlayerScore[]>([])

  const hasFetched = useRef<boolean>(false)

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true
      setIsLoading(true)
      getScoreboard({ gameId })
        .then((scoreResult) => {
          if (scoreResult.success) {
            const scoreTable = parseScoreboard(scoreResult.value)
            setTopThree(() => (scoreTable.length > 2 ? scoreTable.slice(0, 3) : scoreTable))
            setScoreboardRest(() => (scoreTable.length > 3 ? scoreTable.slice(3) : []))
          } else {
            throw scoreResult.error
          }
        })
        .catch((e) => {
          console.error(e)
          notifyError({
            title: t('game_editor.scoreboard.failed_to_get_scoreboard_title', 'Failed to fetch scoreboard'),
            content: t(
              'game_editor.scoreboard.failed_to_get_scoreboard_content',
              'An error occurred while fetching scoreboard. Please try again or contact us for support.',
            ),
          })
        })
        .finally(() => setIsLoading(false))
    }
  }, [gameId, notifyError, t])

  return (
    <div className={styles.outerContainer}>
      <ScoreboardHeader onClose={onClose} />
      <div className={styles.middleContainer}>
        {topThree.length > 0 ? (
          <div className={styles.scoreboardContainer}>
            <TopThreeRow topThree={topThree} />
            <PlayerList scoreTable={scoreboardRest} />
          </div>
        ) : (
          <div className={styles.emptyCentredContainer}>
            {isLoading ? (
              <ThreeDots variant='secondary' />
            ) : (
              t('game_editor.scoreboard.no_player_scores_info', 'No player scores to show')
            )}
          </div>
        )}
      </div>
    </div>
  )
}
