import * as React from 'react'
import { SVGProps } from 'react'
const SvgMinusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <path
      d='M1.328 15.995c0-.736.597-1.333 1.334-1.333h26.666a1.333 1.333 0 1 1 0 2.666H2.652a1.333 1.333 0 0 1-1.324-1.333Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)
export default SvgMinusIcon
