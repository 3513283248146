import React, { useCallback, useState } from 'react'
import styles from './Engine.module.css'

interface Props {
  initialOpen?: boolean
}

export const Instructions: React.FC<Props> = ({ initialOpen = true }) => {
  const [instructionsOpen, setInstructionsOpen] = useState(initialOpen)

  const toggleInstructions = useCallback(() => {
    setInstructionsOpen(!instructionsOpen)
  }, [instructionsOpen])

  return (
    <div id='instructions_area' className={styles.instructionsArea}>
      <div style={{ display: 'flex', fontWeight: 'bold', alignItems: 'center' }}>
        <div>Instructions</div>
        {instructionsOpen ? (
          <div onClick={toggleInstructions} className={styles.collapseToggler}>
            -
          </div>
        ) : (
          <div onClick={toggleInstructions} className={styles.collapseToggler}>
            +
          </div>
        )}
      </div>
      <div style={{ display: instructionsOpen ? '' : 'none' }} className={styles.instructionText}>
        <ol>
          <li>Engine creates two games. One with 40 'raw' tasks and one with ~12 tasks. </li>
          <li>You can add a storyline to the 12 tasks game, selecting from:</li>
          <ul>
            <li>
              <strong>News Desk:</strong> A news office themed game
            </li>
            <li>
              <strong>Tapas Fiesta:</strong> A fiesta themed game
            </li>
            <li>
              <strong>Delivery agents:</strong> Learning while doing a parcel delivery
            </li>
            <li>
              <strong>Company Triathlon:</strong> Sports themed game mostly for strategy & values learning
            </li>
            <li>
              <strong>Fully AI generated:</strong> Seppo-Engine studies the material and provides three game options to
              choose from
            </li>
          </ul>
        </ol>
        <ol start={3}>
          <li>You can also choose to not bundle a story to the 12 task game.</li>
          <li>
            The generated game will be shared to your Seppo account. <strong>(Using STAGING now)</strong>
          </li>
          <li>You will have three gameboard pictures to choose from.</li>
          <li>Game generation takes about 10 minutes.</li>
        </ol>
      </div>
    </div>
  )
}
