import * as React from 'react'
import { SVGProps } from 'react'
const SvgViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#view_icon_svg__a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.685 7.937C14.01 6.095 11.29 4.22 8.575 4.262c-2.716-.042-5.437 1.833-7.11 3.675l-.001.001a1.508 1.508 0 0 0 0 2.019l.001.001c1.635 1.801 4.35 3.72 7.11 3.676 2.759.043 5.474-1.875 7.11-3.676l.002-.001a1.508 1.508 0 0 0 0-2.019l-.002-.001ZM2.16 9.329a.57.57 0 0 1 0-.763C3.76 6.804 6.246 5.161 8.566 5.2h.016c2.32-.04 4.805 1.604 6.409 3.366a.57.57 0 0 1 0 .763c-1.57 1.726-4.047 3.408-6.409 3.368h-.016c-2.361.04-4.839-1.642-6.406-3.368Zm6.414-2.256c1.036 0 1.874.838 1.875 1.874v.002a1.875 1.875 0 1 1-1.875-1.876Zm2.812 1.874a2.811 2.811 0 0 0-5.624 0 2.812 2.812 0 1 0 5.624.001v-.001Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='view_icon_svg__a'>
        <path fill='#fff' transform='translate(.574 .762)' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgViewIcon
