import * as React from 'react'
import { SVGProps } from 'react'

const SvgCommunity = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.808 7.308a13 13 0 0 1 18.928.578c.264.083.488.26.636.494a14.07 14.07 0 0 1 2.399 5.689c.151.795.229 1.61.229 2.431v.06a14.247 14.247 0 0 1-1.537 6.513 1.19 1.19 0 0 1-.594.56 13.002 13.002 0 0 1-21.22.732 12.039 12.039 0 0 1-1.899-3.33 11.837 11.837 0 0 1-.748-4.336 13 13 0 0 1 3.806-9.392ZM26.473 18.7c-.188.895-.49 1.762-.897 2.578H24.36a.429.429 0 0 1-.284-.108.465.465 0 0 1-.15-.275l-.848-5.32c-.095-.589-.583-1.02-1.156-1.02h-1.696a.424.424 0 0 1-.346-.177.468.468 0 0 1-.08-.393l.806-3.36a.46.46 0 0 1 .156-.25.428.428 0 0 1 .27-.098h3.674c.397.555.738 1.144 1.02 1.76.494 1.16.798 2.405.896 3.683.077 1 .026 2.002-.149 2.98ZM22.278 7.832h-1.246a2.71 2.71 0 0 0-1.712.615 2.917 2.917 0 0 0-.987 1.583l-.805 3.36c-.103.429-.11.875-.023 1.307.088.431.268.836.529 1.184.26.347.592.629.972.822.38.193.798.294 1.22.294h.704l.686 4.3c.108.678.444 1.293.946 1.737.392.346.865.57 1.367.652A10.701 10.701 0 0 1 9.604 25.08l.704-3.426h.187c.397 0 .79-.088 1.147-.258s.67-.416.915-.721c.245-.306.415-.66.497-1.04a2.48 2.48 0 0 0-.021-1.146l-.758-2.949a2.542 2.542 0 0 0-.928-1.39 2.664 2.664 0 0 0-1.61-.539H5.696a10.701 10.701 0 0 1 16.582-5.778ZM5.298 16.5c0 2.485.865 4.881 2.427 6.787l.6-2.915a1.095 1.095 0 0 1 1.08-.862h1.09a.424.424 0 0 0 .325-.155.396.396 0 0 0 .075-.345l-.758-2.95a.402.402 0 0 0-.146-.219.42.42 0 0 0-.254-.085H5.324c-.017.247-.026.495-.026.744Z'
      fill={props.color ?? '#CC3E76'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.808 7.308a13 13 0 0 1 18.928.578c.264.083.488.26.636.494a14.07 14.07 0 0 1 2.399 5.689c.151.795.229 1.61.229 2.431v.06a14.247 14.247 0 0 1-1.537 6.513 1.19 1.19 0 0 1-.594.56 13.002 13.002 0 0 1-21.22.732 12.039 12.039 0 0 1-1.899-3.33 11.837 11.837 0 0 1-.748-4.336 13 13 0 0 1 3.806-9.392ZM26.473 18.7c-.188.895-.49 1.762-.897 2.578H24.36a.429.429 0 0 1-.284-.108.465.465 0 0 1-.15-.275l-.848-5.32c-.095-.589-.583-1.02-1.156-1.02h-1.696a.424.424 0 0 1-.346-.177.468.468 0 0 1-.08-.393l.806-3.36a.46.46 0 0 1 .156-.25.428.428 0 0 1 .27-.098h3.674c.397.555.738 1.144 1.02 1.76.494 1.16.798 2.405.896 3.683.077 1 .026 2.002-.149 2.98ZM22.278 7.832h-1.246a2.71 2.71 0 0 0-1.712.615 2.917 2.917 0 0 0-.987 1.583l-.805 3.36c-.103.429-.11.875-.023 1.307.088.431.268.836.529 1.184.26.347.592.629.972.822.38.193.798.294 1.22.294h.704l.686 4.3c.108.678.444 1.293.946 1.737.392.346.865.57 1.367.652A10.701 10.701 0 0 1 9.604 25.08l.704-3.426h.187c.397 0 .79-.088 1.147-.258s.67-.416.915-.721c.245-.306.415-.66.497-1.04a2.48 2.48 0 0 0-.021-1.146l-.758-2.949a2.542 2.542 0 0 0-.928-1.39 2.664 2.664 0 0 0-1.61-.539H5.696a10.701 10.701 0 0 1 16.582-5.778ZM5.298 16.5c0 2.485.865 4.881 2.427 6.787l.6-2.915a1.095 1.095 0 0 1 1.08-.862h1.09a.424.424 0 0 0 .325-.155.396.396 0 0 0 .075-.345l-.758-2.95a.402.402 0 0 0-.146-.219.42.42 0 0 0-.254-.085H5.324c-.017.247-.026.495-.026.744Z'
      fill={props.color ?? 'url(#community_svg__a)'}
      fillOpacity={props.color ? 1 : 0.25}
    />
    <defs>
      <linearGradient id='community_svg__a' x1={16} y1={3.5} x2={16} y2={29.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgCommunity
