import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FormFieldSpy } from '../../../common/components/Form/FormFieldSpy/FormFieldSpy'
import styles from '../OnboardingWizard.module.css'
import { RadioOptionsWithOtherField } from '../components/RadioOptionsWithOtherField'
import { ALL_ORG_TYPES, ORGANISATION_SUBTYPE_OPTIONS, ORGANISATION_TYPE_OPTIONS } from '../constants'

export const OrganisationStep = () => {
  const { t } = useTranslation()
  const { input: typeInput } = useField('organisationType')

  return (
    <div className={styles.organisationStepContainer}>
      <h2>{t('onboarding_wizard.org_step.title', 'What is your organisation like?')}</h2>
      <h5>{t('onboarding_wizard.choose', 'Choose one')}</h5>
      <RadioOptionsWithOtherField
        name='organisationType'
        otherFieldLabel={t('onboarding_wizard.org_step.other_type_label', 'Tell us about your organisation')}
        selectOptions={ORGANISATION_TYPE_OPTIONS}
      />
      {typeInput.value && ALL_ORG_TYPES.includes(typeInput.value) && (
        <>
          <h4>{t('onboarding_wizard.sub_type.title', 'What kind of organization do you have?')}</h4>
          <RadioOptionsWithOtherField
            name='organisationSubtype'
            otherFieldLabel={t('onboarding_wizard.org_step.other_sub_type_label', 'Describe your organisation')}
            selectOptions={ORGANISATION_SUBTYPE_OPTIONS[typeInput.value]}
          />
        </>
      )}
      <FormFieldSpy spyFieldName='organisationType' dependantFieldName='organisationSubtype' />
      <FormFieldSpy spyFieldName='organisationType' dependantFieldName='organisationSubtypeOther' />
      <FormFieldSpy spyFieldName='organisationType' dependantFieldName='answer' />
      <FormFieldSpy spyFieldName='organisationType' dependantFieldName='answerOther' />
      <FormFieldSpy spyFieldName='organisationSubtype' dependantFieldName='answer' />
      <FormFieldSpy spyFieldName='organisationSubtype' dependantFieldName='answerOther' />
    </div>
  )
}
