import { CSSProperties } from 'react'
import { NavLink } from 'react-router-dom'
import { IconName, getIcon } from '../icons/utils'

import styles from './LinkButton.module.css'

type LinkButtonProps = React.HTMLProps<HTMLAnchorElement> & {
  label: string
  iconName?: IconName
  anchorStyle?: CSSProperties
  labelStyle?: CSSProperties
}

export const LinkButton: React.FC<LinkButtonProps> = ({ iconName, label, anchorStyle, labelStyle, ...restProps }) => {
  return (
    <a className={styles.buttonLink} {...restProps} style={anchorStyle}>
      {iconName != null && <span className={styles.iconWrapper}>{getIcon(iconName)}</span>}
      <span className={styles.label} style={labelStyle}>
        {label}
      </span>
    </a>
  )
}

type NavLinkButtonProps = {
  route: string
  label: string
  iconName?: IconName
}

export const NavLinkButton: React.FC<NavLinkButtonProps> = ({ route, label, iconName }) => {
  return (
    <NavLink to={route} role='link' className={styles.buttonLink}>
      {iconName && <span className={styles.iconWrapper}>{getIcon(iconName)}</span>}
      <span className={styles.label}>{label}</span>
    </NavLink>
  )
}
