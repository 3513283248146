import * as React from 'react'
import { SVGProps } from 'react'

const SvgProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 60 54' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.488 27.703c-1.005-1.58-2.878-3.751-2.878-5.63a3.024 3.024 0 0 1 2.03-2.745 82.062 82.062 0 0 1-.157-4.786c0-.941 0-1.892.054-2.824a9.222 9.222 0 0 1 .317-1.751 10.068 10.068 0 0 1 4.493-5.707c.77-.471 1.588-.862 2.44-1.166 1.555-.561 2.45-2.465 4.142-2.5 3.956-.102 8.794 2.822 11.33 5.568a10.038 10.038 0 0 1 2.591 6.507l-.16 6.927a2.274 2.274 0 0 1 1.663 1.42c.541 2.19-1.727 4.907-2.78 6.648-.977 1.605-4.694 6.795-4.694 6.829.02.297.137.58.332.805C42.98 43.229 60 38.223 60 53.998H30.25L0 54c0-15.78 16.966-10.766 22.732-18.697.283-.415.415-.644.41-.825 0-.102-4.273-6.146-4.654-6.756v-.019Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)

export default SvgProfileIcon
