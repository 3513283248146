import classNames from 'classnames'
import React from 'react'
import { getIcon } from '../icons/utils'
import styles from './CloseButton.module.css'

export const CloseButton: React.FC<React.HTMLProps<HTMLButtonElement>> = ({ className, ...props }) => {
  return (
    <button className={classNames(styles.closeButton, className)} {...props} type='button'>
      {getIcon('close')}
    </button>
  )
}
