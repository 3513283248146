import * as React from 'react'
import { SVGProps } from 'react'
const SvgRadarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 0C7.164 0 0 7.164 0 16s7.164 16 16 16 16-7.164 16-16S24.836 0 16 0ZM3.856 9.03C6.272 4.83 10.806 2 16 2c.381 0 .76.015 1.133.045a1.5 1.5 0 1 0 1.064.126A13.949 13.949 0 0 1 25.259 5.5l-2.874 2.805A9.96 9.96 0 0 0 16 6C10.477 6 6 10.477 6 16s4.477 10 10 10 10-4.477 10-10a9.958 9.958 0 0 0-2.21-6.272l2.871-2.802A13.944 13.944 0 0 1 30 16c0 2.654-.738 5.135-2.02 7.25a1.5 1.5 0 1 0-1.24 1.73A13.97 13.97 0 0 1 16 30C8.268 30 2 23.732 2 16c0-2.321.565-4.51 1.565-6.438a1.5 1.5 0 1 0 .29-.532Zm17.088.68a8 8 0 0 0-12.75 8.05 1.5 1.5 0 1 1 .833 2.164 8 8 0 1 0 13.323-8.79l-2.904 2.833A4 4 0 1 1 12 16a4 4 0 0 1 6.03-3.447l2.914-2.843Zm-3.564 4.842a2 2 0 1 0 .016.017l-.016-.017Z'
      fill={props.color ?? '#000'}
    />
  </svg>
)
export default SvgRadarIcon
