import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Header.module.css'
import { RadioItem } from '../../../../../../common/components/radio/RadioItem'
import { useChat } from '../../../../../../contexts/ChatContextProvider'
import { areThereNewMessages, newCombinedDMCount, newMessagesCount } from '../../helpers'

export enum ChatTypeEnum {
  GENERAL = 'GENERAL',
  DIRECT = 'DIRECT',
}

type HeaderProps = {
  checkedChatTypeId: string
  onSelectChatType: (id: ChatTypeEnum) => void
}

export const Header: React.FC<HeaderProps> = ({ checkedChatTypeId, onSelectChatType }) => {
  const { t } = useTranslation()
  const { chatRooms } = useChat()

  const onSelectChatTypeInternal = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget
    onSelectChatType(id as ChatTypeEnum)
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleRow}>
        <h4>{t('game_editor.chat.title', 'Chat')}</h4>
      </div>
      <div className={styles.contextSwitchRow}>
        <RadioItem
          id={ChatTypeEnum.GENERAL}
          name='chatContextStyle'
          label={t('game_editor.chat.general', 'General')}
          iconName='exercisePin'
          centered
          isChecked={checkedChatTypeId}
          onSelect={onSelectChatTypeInternal}
          showBadge={areThereNewMessages(chatRooms, ChatTypeEnum.GENERAL)}
          newMessagesCount={newMessagesCount(chatRooms[0])}
        />
        <RadioItem
          id={ChatTypeEnum.DIRECT}
          name='chatContextStyle'
          label={t('game_editor.chat.direct', 'Direct')}
          iconName='chatNormal'
          centered
          isChecked={checkedChatTypeId}
          onSelect={onSelectChatTypeInternal}
          showBadge={areThereNewMessages(chatRooms, ChatTypeEnum.DIRECT)}
          newMessagesCount={newCombinedDMCount(chatRooms)}
        />
      </div>
    </div>
  )
}
