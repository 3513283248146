import * as React from 'react'
import { SVGProps } from 'react'

const SvgArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15.36 1.182a1 1 0 1 1 1.415 1.414L10.41 8.96l-1.415 1.415L7.582 8.96 1.218 2.596a1 1 0 0 1 1.414-1.414l6.364 6.364 6.364-6.364Z'
      fill={props.color ?? '#A3A3A3'}
    />
  </svg>
)

export default SvgArrowDownIcon
