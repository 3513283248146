import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import WomanWithGlasses from '../../assets/images/skirt_1.png'
import { Select, SelectOption } from '../../common/components/Select/Select'
import { Button } from '../../common/components/button/Button'
import { getIcon } from '../../common/components/icons/utils'
import { LANGUAGE_SELECT_DEFAULT_OPTION, LANGUAGE_SELECT_OPTIONS } from '../../common/constants'
import { useNotification } from '../../contexts/NotificationContext'
import { useTheme } from '../../contexts/ThemeContext'
import { useUser } from '../../contexts/userContext'
import { externalRoutes, routes } from '../../routes'
import sharedStyles from './Login.module.css'
import styles from './TrialLogin.module.css'
import { LoginFormValues } from './components/LoginForm'
import { TrialEmail } from './components/TrialEmail'
import { TrialPassword } from './components/TrialPassword'
import { emailStepValidate, passwordStepValidate } from './helpers'

export enum TRIAL_RESPONSE_MESSAGES {
  Pending = 'PENDING_TRIAL_EMAIL_CONFIRM',
  Reserved = 'RESERVED_EMAIL',
}

export const TrialLogin: React.FC = () => {
  const [trialStepIndex, setTrialStepIndex] = useState(0)
  const [disableResend, setDisableResend] = useState(false)
  const { t, i18n } = useTranslation()
  const { logoUrl } = useTheme()
  const { requestPassword, resendEmail, startOnboardingProcess } = useUser()
  const { notifyError, notifyInfo } = useNotification()
  const navigate = useNavigate()

  const [defaultLanguageOption] = useState(() => {
    return LANGUAGE_SELECT_OPTIONS.find((option) => option.value === i18n.language) ?? LANGUAGE_SELECT_DEFAULT_OPTION
  })

  const onClickBackInternal = useCallback(() => {
    window.location.href = externalRoutes.website
  }, [])

  const onClickGoToLoginInternal = useCallback(() => {
    navigate(routes.login, { replace: true, state: { stepIndex: 1 } })
  }, [navigate])

  const handleChangeLanguage = useCallback(
    (selectOption: SingleValue<SelectOption>) => {
      i18n.changeLanguage(selectOption?.value)
    },
    [i18n],
  )

  const onSubmit = useCallback(
    async (values: LoginFormValues) => {
      if (values.password == null) {
        const result = await requestPassword(values.email, i18n.language)
        if (result === TRIAL_RESPONSE_MESSAGES.Pending) {
          setTrialStepIndex(1)
        } else if (result === TRIAL_RESPONSE_MESSAGES.Reserved) {
          notifyError({
            title: t('login.instructor.trial_notification.email_taken_error.title', 'Email reserved'),
            content: t(
              'login.instructor.trial_notification.email_taken_error.content',
              'An account already exists with this email address, please try a different one.',
            ),
          })
        }
      } else if (values.password != null && values.password !== 'NEWTRIAL') {
        const result = await startOnboardingProcess(values.email, values.password, true)
        setTrialStepIndex(1)
        if (result) {
          navigate(routes.onboardingWizard)
        } else {
          notifyError({
            title: t('login.instructor.trial_notification.start_onboarding_failure.title', 'Something went wrong'),
            content: t(
              'login.instructor.trial_notification.start_onboarding_failure.content',
              'Please try again later or contact support for further assistance',
            ),
          })
        }
      }
    },
    [i18n.language, navigate, notifyError, requestPassword, startOnboardingProcess, t],
  )

  const resendEmailInternal = useCallback(
    (email: string) => async () => {
      setDisableResend(true)
      const result = await resendEmail(email, true)
      if (result) {
        setDisableResend(false)
        notifyInfo({
          title: t('login.instructor.trial_notification.request_password_info.title', 'Requesting new password'),
          content: t(
            'login.instructor.trial_notification.request_password_info.content',
            'New password has been sent to provided email.',
          ),
        })
      } else {
        setDisableResend(false)
        notifyError({
          title: t('login.instructor.trial_notification.request_password_failure.title', 'Requesting password failed'),
          content: t(
            'login.instructor.trial_notification.request_password_failure.content',
            'Please try again later or contact support for further assistance.',
          ),
        })
      }
    },
    [notifyError, notifyInfo, resendEmail, t],
  )

  const validate = useCallback(
    (values: Partial<LoginFormValues>) => {
      if (trialStepIndex === 1) {
        return passwordStepValidate(t)(values)
      }
      return emailStepValidate(t)(values)
    },
    [t, trialStepIndex],
  )

  const handleClickBackToEmail = () => {
    setTrialStepIndex(0)
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.languageContainer}>
        <Select
          options={LANGUAGE_SELECT_OPTIONS}
          defaultValue={defaultLanguageOption}
          onChange={handleChangeLanguage}
          className={styles.languageSelect}
        />
      </div>
      <div className={styles.trialLoginWizard}>
        <div className={styles.container}>
          <div className={styles.leftSideContainer}>
            <div className={styles.firstRow}>
              <button className={sharedStyles.backButton} onClick={onClickBackInternal}>
                <span>{getIcon('arrowRight')}</span>
                {t('login.instructor.left.back_button', 'Back')}
              </button>
            </div>
            <div className={styles.imageWrapper}>
              <img src={WomanWithGlasses} alt='Woman with glasses in a long skirt' />
            </div>
          </div>
          <div className={styles.rightSideContainer}>
            <div className={styles.logoContainer}>
              <img src={logoUrl} alt='Seppo' height={26} className={styles.seppoLogo} />
            </div>
            <Form<LoginFormValues> onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit, submitting, submitError, modifiedSinceLastSubmit, values }) => (
                <form
                  onSubmit={handleSubmit}
                  className={styles.loginForm}
                  style={{ paddingTop: trialStepIndex === 0 ? '60px' : 0 }}
                >
                  {trialStepIndex === 0 && <TrialEmail />}
                  {trialStepIndex === 1 && (
                    <TrialPassword
                      email={values.email}
                      disabled={submitting || disableResend}
                      onClickBackToEmail={handleClickBackToEmail}
                      onClickResendEmail={resendEmailInternal(values.email)}
                    />
                  )}
                  <div className={styles.formSubmitButtonContainer}>
                    <Button type='submit' disabled={submitting} className={sharedStyles.bigBlockButton}>
                      {submitting
                        ? t('loading', 'Loading...')
                        : t('login.instructor.right.trial.continue_button', 'Continue')}
                    </Button>
                  </div>
                  {submitError && !modifiedSinceLastSubmit && <div className={styles.submitError}>{submitError}</div>}
                </form>
              )}
            </Form>
            <div className={styles.goToLoginContainer}>
              <p className={styles.goToLoginFont}>
                {t('login.login_trial_switch.already_user_question', 'Already a user?')}
              </p>
              <button
                className={classNames(styles.linkLikeButton, styles.goToLoginFont)}
                onClick={onClickGoToLoginInternal}
              >
                {t('login.login_trial_switch.log_in_link', 'Login here')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
