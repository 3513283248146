import styles from './BoardsOverview.module.css'
import { getIcon } from '../../../../../common/components/icons/utils'
import { useState } from 'react'
import { BoardsOverviewModal } from './BoardsOverviewModal'
import { useGame } from '../../../../../contexts/GameContext'

type BoardsOverviewProps = {
  onActivateBoardToEditor: (boardIndex: number) => void
}

export const BoardsOverview: React.FC<BoardsOverviewProps> = ({ onActivateBoardToEditor }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { gameData } = useGame()

  const onActivateBoardToEditorInternal = (boardIndex: number) => {
    setModalOpen(false)
    onActivateBoardToEditor(boardIndex)
  }
  return (
    <>
      <div onClick={() => setModalOpen((prev) => !prev)} className={styles.boardsOverviewButton}>
        <span>{getIcon('mapWithPin')}</span>
      </div>
      {modalOpen && (
        <BoardsOverviewModal
          tasks={gameData?.tasks}
          gameBoardSettings={gameData?.gameBoardSettings}
          onClose={() => setModalOpen(false)}
          onActivateBoardToEditor={onActivateBoardToEditorInternal}
        />
      )}
    </>
  )
}
