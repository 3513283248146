import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { MatchPairsAnswerOption, MatchPairsAnswerSide } from '../../../../../../../../../types/commonTypes'
import layoutStyles from '../AnswerOptionLayout.module.css'
import { AnswerStatus, AnswerStatusIcon } from '../AnswerStatusIcon'
import styles from './MatchPairsAnswer.module.css'
import { MatchPairsSide } from './MatchPairsSide'

type MatchPairsIncorrectProps = {
  answerOption: MatchPairsAnswerOption
  selectedWrong?: MatchPairsAnswerSide
}

export const MatchPairsIncorrect: React.FC<MatchPairsIncorrectProps> = ({ answerOption, selectedWrong }) => {
  const { t } = useTranslation()

  return (
    <div className={layoutStyles.answerOptionLeftColumn}>
      <div className={styles.matchPairsIncorrectContent}>
        <div className={classNames(styles.matchPairsIncorrectColumn, styles.matchPairsError)}>
          <MatchPairsSide side={answerOption.left} isSmall />
          {selectedWrong == null ? (
            <div
              className={classNames(
                styles.matchPairsCell,
                styles.smallMatchPairsCell,
                styles.textOnly,
                styles.matchPairsNoAnswer,
              )}
            >
              <span>{t('game_editor.sidebar.evaluate_answer_panel.time_exceeded_no_answer', 'No answer')}</span>
            </div>
          ) : (
            <MatchPairsSide side={selectedWrong} isSmall />
          )}
          <AnswerStatusIcon status={AnswerStatus.ERROR} />
        </div>
        <div className={classNames(styles.matchPairsIncorrectColumn, styles.matchPairsWarning)}>
          <MatchPairsSide side={answerOption.left} isSmall />
          <MatchPairsSide side={answerOption.right} isSmall />
          <AnswerStatusIcon status={AnswerStatus.WARNING} />
        </div>
      </div>
    </div>
  )
}
