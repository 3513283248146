import { SortableContext } from '@dnd-kit/sortable'
import classNames from 'classnames'
import { Task } from '../../../../../../../types/commonTypes'
import { TaskActionFn } from '../../types'
import { SortableItemPlaceholder } from '../TaskCard/SortableItemPlaceholder'
import { SortableTask } from '../TaskCard/SortableTask'
import styles from './BoardContainer.module.css'
import { BoardHeader } from './BoardHeader'

type BoardContainerProps = {
  tasks: Task[]
  isActive: boolean
  boardIndex: number
  boardUrl: string
  maxBoardIndex: number
  title: string
  isCompact?: boolean
  noPointsGame?: boolean
  isOrderEnabled?: boolean
  isDragging: boolean
  viewOnly: boolean
  onSetActiveBoard: (id: number) => void
  onTaskAction: TaskActionFn
}

export const BoardContainer: React.FC<BoardContainerProps> = ({
  tasks,
  isActive,
  title,
  boardIndex,
  boardUrl,
  maxBoardIndex,
  isCompact,
  noPointsGame = false,
  isDragging,
  isOrderEnabled,
  viewOnly,
  onSetActiveBoard,
  onTaskAction,
}) => {
  return (
    <li
      className={classNames(styles.container, isCompact && styles.containerCompact, isActive && styles.containerActive)}
    >
      <BoardHeader
        boardUrl={boardUrl}
        boardIndex={boardIndex}
        isActive={isActive}
        onSetActiveBoard={onSetActiveBoard}
        title={title}
        compact={isCompact}
      />
      {tasks.length > 0 ? (
        <SortableContext id={`boardTasks_${boardIndex}`} items={tasks} disabled={viewOnly}>
          <ul className={styles.innerList}>
            {tasks.map((task, index) => {
              return (
                <SortableTask
                  key={`taskCard_${task.id}`}
                  task={task}
                  maxBoardIndex={maxBoardIndex}
                  compact={isCompact}
                  noPointsGame={noPointsGame}
                  onAction={onTaskAction}
                  index={index}
                  maxIndex={tasks.length - 1}
                  showOrder={isOrderEnabled}
                  viewOnly={viewOnly}
                />
              )
            })}
          </ul>
        </SortableContext>
      ) : isDragging ? (
        <SortableItemPlaceholder levelIndex={1} boardIndex={boardIndex} />
      ) : null}
    </li>
  )
}
