import * as React from 'react'
import { SVGProps } from 'react'

const SvgInfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 2.5C8.544 2.5 2.5 8.545 2.5 16c0 7.456 6.044 13.5 13.5 13.5S29.5 23.456 29.5 16c0-7.455-6.044-13.5-13.5-13.5ZM4.656 16C4.656 9.735 9.736 4.657 16 4.657c6.265 0 11.344 5.078 11.344 11.343S22.264 27.344 16 27.344C9.735 27.344 4.656 22.265 4.656 16Zm10.93-6.875a1.492 1.492 0 1 0 0 2.985 1.492 1.492 0 0 0 0-2.985Zm-1.242 4.141a1.078 1.078 0 0 0 0 2.156h.578v3.89a2.735 2.735 0 0 0 2.734 2.735h.828a1.078 1.078 0 0 0 0-2.156h-.828a.578.578 0 0 1-.578-.578v-4.14a1.906 1.906 0 0 0-1.906-1.906h-.828Z'
      fill={props.color ?? 'var(--grey-900)'}
    />
  </svg>
)

export default SvgInfoIcon
