import * as React from 'react'
import { SVGProps } from 'react'
const SvgCircleWithIcons = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 224 240'
    {...props}
  >
    <linearGradient id='circle_with_icons_svg__a'>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <linearGradient
      xlinkHref='#circle_with_icons_svg__a'
      id='circle_with_icons_svg__b'
      x1={120.545}
      x2={300.083}
      y1={-62.776}
      y2={158.594}
      gradientUnits='userSpaceOnUse'
    />
    <linearGradient
      xlinkHref='#circle_with_icons_svg__a'
      id='circle_with_icons_svg__d'
      x1={112.272}
      x2={126.729}
      y1={152.442}
      y2={232.142}
      gradientUnits='userSpaceOnUse'
    />
    <linearGradient
      xlinkHref='#circle_with_icons_svg__a'
      id='circle_with_icons_svg__f'
      x1={85.906}
      x2={77.108}
      y1={7.572}
      y2={67.632}
      gradientUnits='userSpaceOnUse'
    />
    <linearGradient
      xlinkHref='#circle_with_icons_svg__a'
      id='circle_with_icons_svg__h'
      x1={23.809}
      x2={25.125}
      y1={80.959}
      y2={125.799}
      gradientUnits='userSpaceOnUse'
    />
    <linearGradient
      xlinkHref='#circle_with_icons_svg__a'
      id='circle_with_icons_svg__i'
      x1={179.926}
      x2={187.078}
      y1={44.132}
      y2={101.644}
      gradientUnits='userSpaceOnUse'
    />
    <clipPath id='circle_with_icons_svg__c'>
      <path d='m71.93 159.76 80.684-14.635 14.457 79.7-80.684 14.635z' />
    </clipPath>
    <clipPath id='circle_with_icons_svg__e'>
      <path d='m52.804.713 63.791 9.344-9.23 63.014-63.792-9.345z' />
    </clipPath>
    <clipPath id='circle_with_icons_svg__g'>
      <path d='m.867 81.63 45.394-1.33 1.315 44.84-45.394 1.33z' />
    </clipPath>
    <circle cx={120.545} cy={109.895} r={102.916} fill='white' />
    <circle cx={120.545} cy={109.895} r={102.916} fill='currentColor' fillOpacity={0.25} />
    <g fillRule='evenodd' clipRule='evenodd'>
      <g clipPath='url(#circle_with_icons_svg__c)'>
        <path
          fill='currentColor'
          d='M95.621 168.325c-4.875.885-8.117 5.502-7.244 10.318.874 4.816 5.531 8.001 10.406 7.117 4.876-.885 8.117-5.502 7.244-10.318-.874-4.816-5.531-8.001-10.406-7.117zm-2.2 9.404c-.375-2.065 1.013-4.043 3.103-4.422 2.09-.38 4.086.985 4.46 3.05.375 2.064-1.014 4.042-3.104 4.421-2.09.38-4.085-.985-4.46-3.049z'
        />
        <path
          fill='url(#circle_with_icons_svg__d)'
          fillOpacity={0.25}
          d='M95.621 168.325c-4.875.885-8.117 5.502-7.244 10.318.874 4.816 5.531 8.001 10.406 7.117 4.876-.885 8.117-5.502 7.244-10.318-.874-4.816-5.531-8.001-10.406-7.117zm-2.2 9.404c-.375-2.065 1.013-4.043 3.103-4.422 2.09-.38 4.086.985 4.46 3.05.375 2.064-1.014 4.042-3.104 4.421-2.09.38-4.085-.985-4.46-3.049z'
        />
        <path
          fill='currentColor'
          d='M93.951 238.087c-4.176.758-8.171-1.974-8.92-6.1l-11.745-64.755c-.748-4.126 2.033-8.087 6.209-8.844l65.555-11.891c4.176-.758 8.171 1.975 8.919 6.1l11.746 64.756c.748 4.125-2.032 8.086-6.209 8.843zm-15.622-71.77c-.25-1.374.678-2.696 2.07-2.948l65.555-11.891c1.391-.252 2.723.659 2.973 2.033l8.583 47.322-52.948 9.604 6.555-12.907a2.584 2.584 0 0 1 3.732-.971l4.092 2.799a2.576 2.576 0 0 0 2.092.367 2.57 2.57 0 0 0 1.659-1.323l8.444-16.625a2.583 2.583 0 0 1 3.44-1.137l.006.003c.213.104.409.238.583.398l.005.005 11.12 10.194a2.585 2.585 0 0 0 3.621-.142 2.511 2.511 0 0 0-.128-3.578l-11.116-10.189-.003-.004a7.553 7.553 0 0 0-1.789-1.223c-3.79-1.866-8.408-.339-10.316 3.417l-7.115 14.01-1.639-1.12a7.271 7.271 0 0 0-.94-.552l-.007-.004c-3.793-1.869-8.411-.336-10.318 3.42l-8.282 16.305-11.346 2.059zm80.085 39.497 2.259 12.453c.249 1.374-.679 2.696-2.07 2.948l-65.555 11.891c-1.392.252-2.724-.659-2.974-2.033l-2.258-12.453z'
        />
        <path
          fill='url(#circle_with_icons_svg__d)'
          fillOpacity={0.25}
          d='M93.951 238.087c-4.176.758-8.171-1.974-8.92-6.1l-11.745-64.755c-.748-4.126 2.033-8.087 6.209-8.844l65.555-11.891c4.176-.758 8.171 1.975 8.919 6.1l11.746 64.756c.748 4.125-2.032 8.086-6.209 8.843zm-15.622-71.77c-.25-1.374.678-2.696 2.07-2.948l65.555-11.891c1.391-.252 2.723.659 2.973 2.033l8.583 47.322-52.948 9.604 6.555-12.907a2.584 2.584 0 0 1 3.732-.971l4.092 2.799a2.576 2.576 0 0 0 2.092.367 2.57 2.57 0 0 0 1.659-1.323l8.444-16.625a2.583 2.583 0 0 1 3.44-1.137l.006.003c.213.104.409.238.583.398l.005.005 11.12 10.194a2.585 2.585 0 0 0 3.621-.142 2.511 2.511 0 0 0-.128-3.578l-11.116-10.189-.003-.004a7.553 7.553 0 0 0-1.789-1.223c-3.79-1.866-8.408-.339-10.316 3.417l-7.115 14.01-1.639-1.12a7.271 7.271 0 0 0-.94-.552l-.007-.004c-3.793-1.869-8.411-.336-10.318 3.42l-8.282 16.305-11.346 2.059zm80.085 39.497 2.259 12.453c.249 1.374-.679 2.696-2.07 2.948l-65.555 11.891c-1.392.252-2.724-.659-2.974-2.033l-2.258-12.453z'
        />
      </g>
      <g clipPath='url(#circle_with_icons_svg__e)'>
        <path
          fill='currentColor'
          d='M95.303 16.05A23.84 23.84 0 0 1 98.3 39.56a17.737 17.737 0 0 0-12.85 3.272 17.307 17.307 0 0 0-6.893 11.435c-.201 1.372-.234 2.75-.106 4.11l-7.277 5.369S56.692 44.75 56.59 44.614a23.78 23.78 0 0 1 4.91-33.515c10.707-7.9 25.81-5.688 33.803 4.952zm-11.12 8.205c-3.381-4.502-9.854-5.45-14.384-2.107s-5.47 9.76-2.09 14.261c.154.205.342.384.53.564.188.179.376.358.53.563.311-1.29 1.137-2.747 2.373-3.659 2.608-1.924 6.444-1.362 8.39 1.23.923 1.227 1.433 2.758 1.224 4.185.514-.168.926-.472 1.338-.776 4.53-3.342 5.47-9.76 2.09-14.261zm3.679 21.775a13.685 13.685 0 0 1 10.085-2.5 13.683 13.683 0 0 1 8.944 5.287 13.35 13.35 0 0 1 2.565 9.976 13.352 13.352 0 0 1-5.318 8.822 13.686 13.686 0 0 1-10.085 2.499 13.685 13.685 0 0 1-8.945-5.287 13.35 13.35 0 0 1-2.564-9.976 13.35 13.35 0 0 1 5.318-8.821zm9.652.454c-2.776-.407-5.597.293-7.844 1.944s-3.734 4.12-4.136 6.861c-.402 2.742.316 5.533 1.995 7.76s4.18 3.705 6.957 4.111 5.597-.292 7.843-1.944a10.386 10.386 0 0 0 4.137-6.86 10.386 10.386 0 0 0-1.995-7.76 10.644 10.644 0 0 0-6.957-4.112zm.846 4.65c.12-.816-.453-1.575-1.279-1.696s-1.592.442-1.71 1.258l-.65 4.43-4.485-.657a1.502 1.502 0 0 0-1.712 1.258 1.502 1.502 0 0 0 1.28 1.696l4.484.657-.649 4.43a1.502 1.502 0 0 0 1.28 1.697 1.502 1.502 0 0 0 1.71-1.258l.65-4.431 4.485.657a1.501 1.501 0 0 0 1.711-1.258 1.502 1.502 0 0 0-1.278-1.696l-4.486-.657z'
        />
        <path
          fill='url(#circle_with_icons_svg__f)'
          fillOpacity={0.25}
          d='M95.303 16.05A23.84 23.84 0 0 1 98.3 39.56a17.737 17.737 0 0 0-12.85 3.272 17.307 17.307 0 0 0-6.893 11.435c-.201 1.372-.234 2.75-.106 4.11l-7.277 5.369S56.692 44.75 56.59 44.614a23.78 23.78 0 0 1 4.91-33.515c10.707-7.9 25.81-5.688 33.803 4.952zm-11.12 8.205c-3.381-4.502-9.854-5.45-14.384-2.107s-5.47 9.76-2.09 14.261c.154.205.342.384.53.564.188.179.376.358.53.563.311-1.29 1.137-2.747 2.373-3.659 2.608-1.924 6.444-1.362 8.39 1.23.923 1.227 1.433 2.758 1.224 4.185.514-.168.926-.472 1.338-.776 4.53-3.342 5.47-9.76 2.09-14.261zm3.679 21.775a13.685 13.685 0 0 1 10.085-2.5 13.683 13.683 0 0 1 8.944 5.287 13.35 13.35 0 0 1 2.565 9.976 13.352 13.352 0 0 1-5.318 8.822 13.686 13.686 0 0 1-10.085 2.499 13.685 13.685 0 0 1-8.945-5.287 13.35 13.35 0 0 1-2.564-9.976 13.35 13.35 0 0 1 5.318-8.821zm9.652.454c-2.776-.407-5.597.293-7.844 1.944s-3.734 4.12-4.136 6.861c-.402 2.742.316 5.533 1.995 7.76s4.18 3.705 6.957 4.111 5.597-.292 7.843-1.944a10.386 10.386 0 0 0 4.137-6.86 10.386 10.386 0 0 0-1.995-7.76 10.644 10.644 0 0 0-6.957-4.112zm.846 4.65c.12-.816-.453-1.575-1.279-1.696s-1.592.442-1.71 1.258l-.65 4.43-4.485-.657a1.502 1.502 0 0 0-1.712 1.258 1.502 1.502 0 0 0 1.28 1.696l4.484.657-.649 4.43a1.502 1.502 0 0 0 1.28 1.697 1.502 1.502 0 0 0 1.71-1.258l.65-4.431 4.485.657a1.501 1.501 0 0 0 1.711-1.258 1.502 1.502 0 0 0-1.278-1.696l-4.486-.657z'
        />
      </g>
      <g clipPath='url(#circle_with_icons_svg__g)'>
        <path
          fill='currentColor'
          d='M23.81 80.959c-5.484.16-9.801 4.683-9.642 10.1.034 1.18.478 2.475 1.053 3.708.587 1.26 1.372 2.584 2.21 3.853 1.675 2.539 3.633 4.967 4.845 6.409.156.187.328.341.497.466l.009.006a2.863 2.863 0 0 0 3.967-.6c1.125-1.511 2.939-4.051 4.464-6.685.762-1.317 1.468-2.684 1.98-3.976.501-1.265.869-2.584.834-3.764-.159-5.417-4.734-9.678-10.217-9.517zm-6.805 10.016c-.114-3.869 2.97-7.1 6.887-7.214l.144 4.904c-1.175.035-2.1 1.004-2.066 2.165.034 1.16 1.014 2.074 2.189 2.04l.305 10.37h-.002c-1.183-1.407-3.063-3.742-4.653-6.152-.796-1.206-1.501-2.403-2.01-3.494-.52-1.118-.776-2.004-.794-2.619zm7.463 12.259c1.1-1.476 2.837-3.914 4.281-6.409.724-1.25 1.358-2.487 1.801-3.606.455-1.146.658-2.045.64-2.66-.113-3.869-3.381-6.913-7.298-6.798l.144 4.904c1.174-.033 2.155.88 2.19 2.04.033 1.16-.89 2.128-2.065 2.164zm0 .01v-.01l-.004.006zm0 0v.003l.002-.001z'
        />
        <path
          fill='url(#circle_with_icons_svg__h)'
          fillOpacity={0.25}
          d='M23.81 80.959c-5.484.16-9.801 4.683-9.642 10.1.034 1.18.478 2.475 1.053 3.708.587 1.26 1.372 2.584 2.21 3.853 1.675 2.539 3.633 4.967 4.845 6.409.156.187.328.341.497.466l.009.006a2.863 2.863 0 0 0 3.967-.6c1.125-1.511 2.939-4.051 4.464-6.685.762-1.317 1.468-2.684 1.98-3.976.501-1.265.869-2.584.834-3.764-.159-5.417-4.734-9.678-10.217-9.517zm-6.805 10.016c-.114-3.869 2.97-7.1 6.887-7.214l.144 4.904c-1.175.035-2.1 1.004-2.066 2.165.034 1.16 1.014 2.074 2.189 2.04l.305 10.37h-.002c-1.183-1.407-3.063-3.742-4.653-6.152-.796-1.206-1.501-2.403-2.01-3.494-.52-1.118-.776-2.004-.794-2.619zm7.463 12.259c1.1-1.476 2.837-3.914 4.281-6.409.724-1.25 1.358-2.487 1.801-3.606.455-1.146.658-2.045.64-2.66-.113-3.869-3.381-6.913-7.298-6.798l.144 4.904c1.174-.033 2.155.88 2.19 2.04.033 1.16-.89 2.128-2.065 2.164zm0 .01v-.01l-.004.006zm0 0v.003l.002-.001z'
        />
        <path
          fill='currentColor'
          d='M34.27 98.659a1.419 1.419 0 0 1 1.64-1.143 4.243 4.243 0 0 1 3.247 2.627l.012.03 2.349 6.443.023.065 3.007 8.246c.013.031.024.063.035.095l1.664 4.564c.17.441.265.907.279 1.378.068 2.32-1.783 4.259-4.133 4.328l-34.537 1.013a4.314 4.314 0 0 1-1.417-.196l-.004-.001c-2.234-.706-3.468-3.063-2.76-5.271l3.936-13.143.03-.102 1.962-6.55.01-.028a4.237 4.237 0 0 1 3.064-2.812 1.419 1.419 0 0 1 1.71 1.037 1.402 1.402 0 0 1-1.05 1.69 1.41 1.41 0 0 0-1.017.924l-1.416 4.728 7.938-.233a1.461 1.461 0 0 1 .539.075c.253.086.47.24.633.436a1.386 1.386 0 0 1 .304 1.107l-.94 6.781 10.836-.317-1.337-6.717a1.377 1.377 0 0 1 .222-1.097 1.413 1.413 0 0 1 1.158-.602l7.939-.233-1.691-4.638a1.415 1.415 0 0 0-1.08-.864 1.403 1.403 0 0 1-1.156-1.62zm4.938 9.897-7.228.212 1.11 5.577 8.142-.239zM8.36 115.071l1.695-5.66 7.227-.212-.782 5.633zm33.883 1.811-8.598.252 1.11 5.577 7.555-.221c.783-.023 1.4-.67 1.378-1.443a1.38 1.38 0 0 0-.095-.464l-.012-.031zm-11.474.336-11.783.346-.781 5.633 13.674-.401zm-14.66.43-8.596.252-1.122 3.744-.01.031c-.239.736.17 1.525.919 1.762.155.048.314.07.473.066l7.555-.222z'
        />
        <path
          fill='url(#circle_with_icons_svg__h)'
          fillOpacity={0.25}
          d='M34.27 98.659a1.419 1.419 0 0 1 1.64-1.143 4.243 4.243 0 0 1 3.247 2.627l.012.03 2.349 6.443.023.065 3.007 8.246c.013.031.024.063.035.095l1.664 4.564c.17.441.265.907.279 1.378.068 2.32-1.783 4.259-4.133 4.328l-34.537 1.013a4.314 4.314 0 0 1-1.417-.196l-.004-.001c-2.234-.706-3.468-3.063-2.76-5.271l3.936-13.143.03-.102 1.962-6.55.01-.028a4.237 4.237 0 0 1 3.064-2.812 1.419 1.419 0 0 1 1.71 1.037 1.402 1.402 0 0 1-1.05 1.69 1.41 1.41 0 0 0-1.017.924l-1.416 4.728 7.938-.233a1.461 1.461 0 0 1 .539.075c.253.086.47.24.633.436a1.386 1.386 0 0 1 .304 1.107l-.94 6.781 10.836-.317-1.337-6.717a1.377 1.377 0 0 1 .222-1.097 1.413 1.413 0 0 1 1.158-.602l7.939-.233-1.691-4.638a1.415 1.415 0 0 0-1.08-.864 1.403 1.403 0 0 1-1.156-1.62zm4.938 9.897-7.228.212 1.11 5.577 8.142-.239zM8.36 115.071l1.695-5.66 7.227-.212-.782 5.633zm33.883 1.811-8.598.252 1.11 5.577 7.555-.221c.783-.023 1.4-.67 1.378-1.443a1.38 1.38 0 0 0-.095-.464l-.012-.031zm-11.474.336-11.783.346-.781 5.633 13.674-.401zm-14.66.43-8.596.252-1.122 3.744-.01.031c-.239.736.17 1.525.919 1.762.155.048.314.07.473.066l7.555-.222z'
        />
      </g>
    </g>
    <path
      fill='currentColor'
      d='M156.72 50.668a1.824 1.824 0 0 0-1.596 2.023l1.118 8.986a1.823 1.823 0 0 1-1.596 2.024 1.822 1.822 0 0 1-2.043-1.571l-1.118-8.986c-.37-2.977 1.775-5.696 4.788-6.07l9.097-1.132a1.822 1.822 0 0 1 2.043 1.57 1.822 1.822 0 0 1-1.595 2.024z'
    />
    <path
      fill='url(#circle_with_icons_svg__i)'
      fillOpacity={0.25}
      d='M156.72 50.668a1.824 1.824 0 0 0-1.596 2.023l1.118 8.986a1.823 1.823 0 0 1-1.596 2.024 1.822 1.822 0 0 1-2.043-1.571l-1.118-8.986c-.37-2.977 1.775-5.696 4.788-6.07l9.097-1.132a1.822 1.822 0 0 1 2.043 1.57 1.822 1.822 0 0 1-1.595 2.024z'
    />
    <g fillRule='evenodd' clipRule='evenodd'>
      <path
        fill='currentColor'
        d='M164.892 56.952a1.823 1.823 0 0 0-1.596 2.023l.894 7.19a1.823 1.823 0 0 0 2.043 1.57l7.278-.905a1.823 1.823 0 0 0 1.596-2.024l-.894-7.189a1.823 1.823 0 0 0-2.043-1.57zm2.714 6.962-.447-3.594 3.638-.453.447 3.595z'
      />
      <path
        fill='url(#circle_with_icons_svg__i)'
        fillOpacity={0.25}
        d='M164.892 56.952a1.823 1.823 0 0 0-1.596 2.023l.894 7.19a1.823 1.823 0 0 0 2.043 1.57l7.278-.905a1.823 1.823 0 0 0 1.596-2.024l-.894-7.189a1.823 1.823 0 0 0-2.043-1.57zm2.714 6.962-.447-3.594 3.638-.453.447 3.595z'
      />
      <path
        fill='currentColor'
        d='M168.021 82.113a1.823 1.823 0 0 0-1.596 2.024l.894 7.189a1.823 1.823 0 0 0 2.043 1.57l7.278-.904a1.823 1.823 0 0 0 1.596-2.024l-.894-7.189a1.822 1.822 0 0 0-2.043-1.57zm2.714 6.963-.447-3.595 3.639-.452.447 3.594z'
      />
      <path
        fill='url(#circle_with_icons_svg__i)'
        fillOpacity={0.25}
        d='M168.021 82.113a1.823 1.823 0 0 0-1.596 2.024l.894 7.189a1.823 1.823 0 0 0 2.043 1.57l7.278-.904a1.823 1.823 0 0 0 1.596-2.024l-.894-7.189a1.822 1.822 0 0 0-2.043-1.57zm2.714 6.963-.447-3.595 3.639-.452.447 3.594z'
      />
      <path
        fill='currentColor'
        d='M188.768 55.807a1.823 1.823 0 0 1 1.596-2.023l7.278-.905a1.822 1.822 0 0 1 2.043 1.57l.894 7.19a1.822 1.822 0 0 1-1.596 2.023l-7.278.905a1.823 1.823 0 0 1-2.043-1.57zm4.31 4.94 3.639-.453-.447-3.595-3.639.453z'
      />
      <path
        fill='url(#circle_with_icons_svg__i)'
        fillOpacity={0.25}
        d='M188.768 55.807a1.823 1.823 0 0 1 1.596-2.023l7.278-.905a1.822 1.822 0 0 1 2.043 1.57l.894 7.19a1.822 1.822 0 0 1-1.596 2.023l-7.278.905a1.823 1.823 0 0 1-2.043-1.57zm4.31 4.94 3.639-.453-.447-3.595-3.639.453z'
      />
    </g>
    <path
      fill='currentColor'
      d='M167.127 74.924a1.823 1.823 0 0 0-1.596 2.024 1.823 1.823 0 0 0 2.043 1.57l12.736-1.583.224 1.797c.123.993 1.038 1.696 2.043 1.571s1.719-1.03 1.596-2.023l-.447-3.595a1.823 1.823 0 0 0-2.043-1.571z'
    />
    <path
      fill='url(#circle_with_icons_svg__i)'
      fillOpacity={0.25}
      d='M167.127 74.924a1.823 1.823 0 0 0-1.596 2.024 1.823 1.823 0 0 0 2.043 1.57l12.736-1.583.224 1.797c.123.993 1.038 1.696 2.043 1.571s1.719-1.03 1.596-2.023l-.447-3.595a1.823 1.823 0 0 0-2.043-1.571z'
    />
    <path
      fill='currentColor'
      d='M189.408 75.803a1.823 1.823 0 0 1 2.043 1.571l1.117 8.987 7.278-.905-.894-7.19-1.82.227a1.823 1.823 0 0 1-2.043-1.571 1.823 1.823 0 0 1 1.596-2.024l3.639-.452a1.822 1.822 0 0 1 2.043 1.57l1.341 10.784a1.822 1.822 0 0 1-1.596 2.024l-10.916 1.357a1.822 1.822 0 0 1-2.043-1.57l-1.341-10.784a1.822 1.822 0 0 1 1.596-2.024z'
    />
    <path
      fill='url(#circle_with_icons_svg__i)'
      fillOpacity={0.25}
      d='M189.408 75.803a1.823 1.823 0 0 1 2.043 1.571l1.117 8.987 7.278-.905-.894-7.19-1.82.227a1.823 1.823 0 0 1-2.043-1.571 1.823 1.823 0 0 1 1.596-2.024l3.639-.452a1.822 1.822 0 0 1 2.043 1.57l1.341 10.784a1.822 1.822 0 0 1-1.596 2.024l-10.916 1.357a1.822 1.822 0 0 1-2.043-1.57l-1.341-10.784a1.822 1.822 0 0 1 1.596-2.024z'
    />
    <path
      fill='currentColor'
      d='M185.067 85.469c-.124-.993-1.038-1.696-2.043-1.571s-1.72 1.03-1.596 2.023l.447 3.595a1.822 1.822 0 0 0 2.043 1.57 1.823 1.823 0 0 0 1.596-2.023z'
    />
    <path
      fill='url(#circle_with_icons_svg__i)'
      fillOpacity={0.25}
      d='M185.067 85.469c-.124-.993-1.038-1.696-2.043-1.571s-1.72 1.03-1.596 2.023l.447 3.595a1.822 1.822 0 0 0 2.043 1.57 1.823 1.823 0 0 0 1.596-2.023z'
    />
    <path
      fill='currentColor'
      d='M179.448 55.141a1.823 1.823 0 0 1 2.043 1.571l1.117 8.987 1.82-.227a1.823 1.823 0 0 1 2.043 1.571 1.823 1.823 0 0 1-1.596 2.024l-3.639.453a1.822 1.822 0 0 1-2.043-1.571l-1.341-10.784a1.822 1.822 0 0 1 1.596-2.024z'
    />
    <path
      fill='url(#circle_with_icons_svg__i)'
      fillOpacity={0.25}
      d='M179.448 55.141a1.823 1.823 0 0 1 2.043 1.571l1.117 8.987 1.82-.227a1.823 1.823 0 0 1 2.043 1.571 1.823 1.823 0 0 1-1.596 2.024l-3.639.453a1.822 1.822 0 0 1-2.043-1.571l-1.341-10.784a1.822 1.822 0 0 1 1.596-2.024z'
    />
    <path
      fill='currentColor'
      d='M192.152 68.162a1.823 1.823 0 0 0-1.596 2.023 1.823 1.823 0 0 0 2.043 1.571l7.278-.905a1.822 1.822 0 0 0 1.596-2.023 1.822 1.822 0 0 0-2.043-1.571z'
    />
    <path
      fill='url(#circle_with_icons_svg__i)'
      fillOpacity={0.25}
      d='M192.152 68.162a1.823 1.823 0 0 0-1.596 2.023 1.823 1.823 0 0 0 2.043 1.571l7.278-.905a1.822 1.822 0 0 0 1.596-2.023 1.822 1.822 0 0 0-2.043-1.571z'
    />
    <path
      fill='currentColor'
      d='M194.482 42.322a1.822 1.822 0 0 0-1.596 2.023 1.822 1.822 0 0 0 2.043 1.571l9.097-1.131a1.824 1.824 0 0 1 2.043 1.57l1.117 8.987a1.823 1.823 0 0 0 2.043 1.57 1.822 1.822 0 0 0 1.596-2.023l-1.117-8.986c-.371-2.977-3.116-5.088-6.129-4.713z'
    />
    <path
      fill='url(#circle_with_icons_svg__i)'
      fillOpacity={0.25}
      d='M194.482 42.322a1.822 1.822 0 0 0-1.596 2.023 1.822 1.822 0 0 0 2.043 1.571l9.097-1.131a1.824 1.824 0 0 1 2.043 1.57l1.117 8.987a1.823 1.823 0 0 0 2.043 1.57 1.822 1.822 0 0 0 1.596-2.023l-1.117-8.986c-.371-2.977-3.116-5.088-6.129-4.713z'
    />
    <path
      fill='currentColor'
      d='M212.358 82.074a1.822 1.822 0 0 1 2.043 1.571l1.118 8.987c.37 2.976-1.774 5.695-4.788 6.07l-9.097 1.132c-1.005.125-1.92-.579-2.043-1.571s.591-1.899 1.596-2.024l9.097-1.131a1.824 1.824 0 0 0 1.596-2.024l-1.117-8.986a1.822 1.822 0 0 1 1.595-2.024z'
    />
    <path
      fill='url(#circle_with_icons_svg__i)'
      fillOpacity={0.25}
      d='M212.358 82.074a1.822 1.822 0 0 1 2.043 1.571l1.118 8.987c.37 2.976-1.774 5.695-4.788 6.07l-9.097 1.132c-1.005.125-1.92-.579-2.043-1.571s.591-1.899 1.596-2.024l9.097-1.131a1.824 1.824 0 0 0 1.596-2.024l-1.117-8.986a1.822 1.822 0 0 1 1.595-2.024z'
    />
    <path
      fill='currentColor'
      d='M159.818 90.433a1.822 1.822 0 0 0-2.043-1.57 1.823 1.823 0 0 0-1.596 2.023l1.118 8.986c.37 2.977 3.115 5.088 6.129 4.713l9.097-1.131a1.823 1.823 0 0 0 1.596-2.024 1.823 1.823 0 0 0-2.043-1.57l-9.097 1.131a1.824 1.824 0 0 1-2.043-1.571z'
    />
    <path
      fill='url(#circle_with_icons_svg__i)'
      fillOpacity={0.25}
      d='M159.818 90.433a1.822 1.822 0 0 0-2.043-1.57 1.823 1.823 0 0 0-1.596 2.023l1.118 8.986c.37 2.977 3.115 5.088 6.129 4.713l9.097-1.131a1.823 1.823 0 0 0 1.596-2.024 1.823 1.823 0 0 0-2.043-1.57l-9.097 1.131a1.824 1.824 0 0 1-2.043-1.571z'
    />
  </svg>
)
export default SvgCircleWithIcons
