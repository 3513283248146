import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../common/components/Form/InputFormField/InputFormField'
import { EditorPermissions, GameAdvancedSettings } from '../../../../../types/commonTypes'
import { GameBoardSettings, GameForm } from '../../../types'
import { GameBoardPicker } from '../../GameBoardsPicker/GameBoardsPicker'
import styles from '../GameSettingsModal.module.css'

const shouldDisableField = (permissions: EditorPermissions, generalField: keyof GameForm) => {
  if (typeof permissions.gameSettings === 'boolean') {
    return !permissions.gameSettings
  }
  return !permissions.gameSettings?.[generalField]
}

type GeneralProps = {
  initialGameBoardSettings: GameBoardSettings
  advancedSettings: Partial<GameAdvancedSettings>
  moveTasks?: boolean
  permissions: EditorPermissions
  onToggleMoveTasks?: () => void
}

export const General: React.FC<GeneralProps> = ({
  initialGameBoardSettings,
  advancedSettings,
  moveTasks,
  permissions,
  onToggleMoveTasks,
}) => {
  const { t } = useTranslation()
  const { input: gameBoardSettingsInput } = useField('gameBoardSettings')

  return (
    <div className={styles.generalSection}>
      <InputFormField
        name='name'
        label={t('game_editor.game_settings.general.game_name_label', 'Game name')}
        disabled={shouldDisableField(permissions, 'name')}
      />
      <div className={styles.separator} />
      <GameBoardPicker
        isBranching={advancedSettings.allowBranching}
        initialValues={initialGameBoardSettings}
        onChange={gameBoardSettingsInput.onChange}
        isExploration={advancedSettings.explorationMode}
        moveTasks={moveTasks}
        onToggleMoveTasks={onToggleMoveTasks}
        viewOnly={shouldDisableField(permissions, 'gameBoardSettings')}
      />
    </div>
  )
}
