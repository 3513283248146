//This is only for development since we need https for handleCopy to work.
const unsecuredCopyToClipboard = (copyText: string) => {
  const textArea = document.createElement('textarea')
  textArea.value = copyText
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    return document.execCommand('copy')
  } catch (err) {
    throw err
  } finally {
    document.body.removeChild(textArea)
  }
}

export const copyToClipboard = async (copyText: string) => {
  try {
    if (window.isSecureContext && navigator.clipboard) {
      await navigator.clipboard.writeText(copyText)
      return true
    } else {
      return unsecuredCopyToClipboard(copyText)
    }
  } catch (error) {
    console.error('Unable to copy to clipboard', error)
  }
}
