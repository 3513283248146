import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Game } from '../../../../../../../types/commonTypes'
import { compareStrings } from '../../../../../../../util/string'
import sharedSidebarStyles from '../../../EditorSidebar.module.css'
import { PlayerSummary } from '../../types'
import { PlayerSummaryCard } from '../Player/PlayerSummaryCard'
import styles from './ManageGamePanel.module.css'

type PlayersListProps = {
  playerSummaries: PlayerSummary[]
  game: Game
  isCompact: boolean
  onSelectPlayer: (playerId: number) => void
  selectedPlayerId?: number
  onClickAwardBadge: (playerId: number) => void
}

export const PlayersList: React.FC<PlayersListProps> = ({
  playerSummaries,
  game,
  isCompact,
  onSelectPlayer,
  selectedPlayerId,
  onClickAwardBadge,
}) => {
  const { t } = useTranslation()

  const sortedPlayerSummaries = useMemo(
    () => [...playerSummaries].sort((a, b) => compareStrings(a.player.nickName, b.player.nickName)),
    [playerSummaries],
  )

  return (
    <ul className={styles.itemsList}>
      {playerSummaries.length === 0 && (
        <p className={sharedSidebarStyles.emptyStateInfo}>
          {t('game_editor.sidebar.manage_game_panel.no_players_info', 'Add players from the player menu in the header')}
        </p>
      )}
      {sortedPlayerSummaries.map((summary) => (
        <PlayerSummaryCard
          key={`playerTask_${summary.player.id}`}
          playerSummary={summary}
          // positionIndex={index + 1} -> currently not sorted by points, so no position index
          tasksTotal={game.tasks.length}
          onClick={onSelectPlayer}
          isCompact={isCompact}
          isSelected={selectedPlayerId === summary.player.id}
          noPointsGame={game.advancedSettings.noPointsGame}
          badgesEnabled={game.advancedSettings.badgesEnabled}
          onClickAwardBadge={onClickAwardBadge}
        />
      ))}
    </ul>
  )
}
