import * as React from 'react'
import { SVGProps } from 'react'

const SvgPlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.996.996a1 1 0 0 0-1 1v9h-9a1 1 0 0 0 0 2h9v9a1 1 0 0 0 2 0v-9h9a1 1 0 1 0 0-2h-9v-9a1 1 0 0 0-1-1Z'
      fill={props.color ?? '#fff'}
    />
  </svg>
)

export default SvgPlusIcon
