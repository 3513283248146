import { getIcon, IconName } from '../../../../common/components/icons/utils'
import styles from './HelpMenuItem.module.css'

type HelpMenuItemProps = {
  icon: IconName
  label: string
  subLabel?: string
  onClickAction: () => void
}

export const HelpMenuItem = ({ icon, label, subLabel, onClickAction }: HelpMenuItemProps) => {
  return (
    <div className={styles.helpMenuItem} onClick={onClickAction} tabIndex={0}>
      <div className={styles.icon}>{getIcon(icon)}</div>
      <div className={styles.label}>{label}</div>
      <div className={styles.subLabel}>{subLabel}</div>
    </div>
  )
}
